/* eslint-disable react/jsx-curly-newline */
import useElastic from 'hooks/useElastic';
import React from 'react';
import { useHistory } from 'react-router-dom';

const ClearRefinementsButton = () => {
  const { resetFilters } = useElastic();
  const history = useHistory();

  return (
    <button
      type="button"
      onClick={() => {
        history.push('/products?sort=asc&page={"size":16,"current":1}');
        resetFilters();
      }}
      className="btn btn-sm btn-block btn-secondary transition-3d-hover"
    >
      Rénitialiser
    </button>
  );
};

export default ClearRefinementsButton;
