import React from 'react';
import PropTypes from 'prop-types';
import FactureItem from 'components/FactureItem';

const ListFactures = ({ factures }) => {
  return (
    <div className="col-12 mb-7 mb-lg-0">
      <div className="d-flex justify-content-between align-items-end border-bottom pb-3 mb-7">
        <h1 className="h3 mb-0">Liste des facturees</h1>
      </div>
      <div className="row mb-2">
        <span className="col-4 text-dark">No de la Facture</span>
        <span className="col-4 text-dark">Date de la Facture</span>
        <span className="col-4 text-right text-dark">Montant de la Facture</span>
      </div>
      <div>
        {factures &&
          factures?.map((facture, index) => {
            return <FactureItem key={`${index + 1}`} facture={facture} />;
          })}
      </div>
    </div>
  );
};

ListFactures.propTypes = {
  factures: PropTypes.array,
};

export default ListFactures;
