import React from 'react';
import PropTypes from 'prop-types';
import CartItem from 'components/CartItem';
import { Link } from 'react-router-dom';

const CartProducts = ({ userCart }) => {
  return (
    <div className="col-lg-8 mb-7 mb-lg-0">
      <div className="d-flex justify-content-between align-items-end border-bottom pb-3 mb-7">
        <h1 className="h3 mb-0">Panier</h1>
      </div>
      <div>
        {userCart &&
          userCart?.map((product, index) => {
            return <CartItem key={`${index + 1}`} product={product} />;
          })}

        <div className="d-sm-flex justify-content-end">
          <Link className="font-weight-bold" to="/products">
            <i className="fas fa-angle-left fa-xs mr-1" />
            Continuez votre shopping
          </Link>
        </div>
      </div>
    </div>
  );
};

CartProducts.propTypes = {
  userCart: PropTypes.array,
};

export default CartProducts;
