export const totalOfCartsAfterPromo = (carts, promotion = 15) => {
  let sumPrice = 0;

  if (!carts) return 0;
  carts.forEach(product => {
    if (!product?.product?.hasNoDiscount) {
      sumPrice += (product.product.price - (product.product.price * promotion) / 100) * product.quantity;
    } else {
      sumPrice += product.product.price * product.quantity;
    }
  });

  return sumPrice;
};
