import { FETCH_PRODUCT_BY_ID } from 'constants/types/products';

const initialState = {
  article: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PRODUCT_BY_ID:
      return { ...state, ...payload };

    default:
      return state;
  }
};
