/* eslint-disable no-extra-parens */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

const FactureItem = ({ facture }) => {
  return (
    facture && (
      <>
        <Link className="card card-frame py-3 px-4 mb-2 " to={`/facture/${facture.id}`}>
          <div className="row align-items-sm-center">
            <span className="col-4 text-dark">{facture.pino}</span>
            <span className="col-4 text-body">{moment(facture.creation).format('L')}</span>
            <span className="col-4 text-right">
              {`${facture.ttcmt} TND`}
              <i className="fas fa-angle-right fa-sm ml-1" />
            </span>
          </div>
        </Link>
      </>
    )
  );
};

FactureItem.propTypes = {
  facture: PropTypes.object.isRequired,
};
export default FactureItem;
