const getImage = (path, maxWidth = 200) => {
  // eslint-disable-next-line no-nested-ternary
  return path
    ? path.trim().endsWith('.gif')
      ? `https://api.moncefjouets.com/api/v1/${path}`
      : `https://imgproxy.moncefjouets.com/pr:default/rs:fit:${maxWidth}:0:0/g:sm/plain/api.moncefjouets.com/api/v1/${path}`
    : 'https://moncefjouets.com/assets/images/logo1.png';
};

export default getImage;
