import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { FILTER_ASC, FILTER_DSC } from 'constants/types/products';
import { useDispatch } from 'react-redux';
import { useCreateUrl } from 'hooks/useCreateUrl';
import { useHistory } from 'react-router-dom';

const SortBy = ({ items }) => {
  const dispatch = useDispatch();
  const createUrl = useCreateUrl();
  const history = useHistory();

  return (
    <>
      <Select
        className="basic-single "
        classNamePrefix="select"
        defaultValue={items[0]}
        name="Depot"
        options={items}
        onChange={e => {
          if (e.value === 'asc') {
            history.push(createUrl({ sort: 'asc' }));
            dispatch({ type: FILTER_ASC });
          } else {
            dispatch({ type: FILTER_DSC });
            history.push(createUrl({ sort: 'desc' }));
          }
        }}
      />
    </>
  );
};

SortBy.propTypes = {
  items: PropTypes.array,
};

export default SortBy;
