export const totalOfCarts = carts => {
  let sumPrice = 0;

  if (!carts) return 0;
  carts.forEach(product => {
    sumPrice += product.product.price * product.quantity;
  });

  return sumPrice;
};
