/* eslint-disable react/void-dom-elements-no-children */
import React, { useEffect } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { SET_SHOP, CHANGE_DELIVERY } from 'constants/types/checkout';

const CheckoutDelivery = () => {
  const dispatch = useDispatch();
  const isDelivery = useSelector(({ checkout }) => checkout.isDelivery);

  useEffect(() => {}, []);
  const magasin = [
    {
      label: 'Moncef Jouets',
      value: 'Moncef Jouets',
    },
    {
      label: 'Moncef Manar',
      value: 'Moncef Manar',
    },
  ];
  const handleDepot = type => {
    dispatch({ type: SET_SHOP, payload: type.value });
  };

  return (
    <div>
      <div className="media align-items-center mb-3">
        <span className="d-block  mr-3">Livraison :</span>
        <div className="media-body text-right">
          <span className="text-dark font-weight-bold" />
        </div>
      </div>
      <div className="card shadow-none mb-3">
        <div className="card-body p-0">
          <div className="custom-control custom-radio d-flex align-items-center small">
            <input
              style={{ cursor: 'pointer' }}
              type="radio"
              className="custom-control-input"
              id="deliveryRadio1"
              name="deliveryRadio"
              checked={isDelivery}
              onClick={() => {
                dispatch({ type: CHANGE_DELIVERY, payload: true });
                dispatch({ type: SET_SHOP, payload: '' });
              }}
            />
            <label className="custom-control-label ml-1" htmlFor="deliveryRadio1">
              <span className="d-block  font-weight-bold mb-1">Avec Livraison (7 Dinars)</span>
            </label>
          </div>
        </div>
      </div>
      <div className="card shadow-none mb-3">
        <div className="card-body p-0">
          <div className="custom-control custom-radio d-flex align-items-center small">
            <input
              type="radio"
              className="custom-control-input"
              id="deliveryRadio2"
              name="deliveryRadio"
              checked={!isDelivery}
              onClick={() => {
                dispatch({ type: CHANGE_DELIVERY, payload: false });
                dispatch({ type: SET_SHOP, payload: 'Moncef Jouets' });
              }}
            />
            <label className="custom-control-label ml-1" htmlFor="deliveryRadio2">
              <span className="d-block  font-weight-bold mb-1">Récuperer dans le magasin (Gratuit)</span>
            </label>
          </div>
        </div>
      </div>
      {!isDelivery && (
        <Select
          className="basic-single m-5"
          classNamePrefix="select"
          defaultValue={magasin[0]}
          name="Depot"
          options={magasin}
          onChange={handleDepot}
        />
      )}
    </div>
  );
};

export default CheckoutDelivery;
