import { clientApi, serverApi } from 'api';

export const login = (isServer = false) => async ({ email, password }) => {
  const api = isServer ? serverApi : clientApi;

  const response = await api.post('/users/sign-in', { email, password });

  return response;
};
export const loginFacebook = (isServer = false) => async values => {
  const api = isServer ? serverApi : clientApi;

  const response = await api.post('/users/sign-in-facebook', { ...values });

  return response;
};
export const loginGoogle = (isServer = false) => async values => {
  const api = isServer ? serverApi : clientApi;

  const response = await api.post('/users/sign-in-google', { ...values });

  return response;
};
