import React from 'react';
import { SET_GIFT } from 'constants/types/checkout';
import { useDispatch } from 'react-redux';

const CheckoutGift = () => {
  const dispatch = useDispatch();

  return (
    <div className="d-flex align-items-center   mb-2">
      <i className="fas fa-gift pt-1 mr-2 text-primary" />
      <div className="custom-control custom-checkbox d-flex align-items-center text-muted">
        <input
          type="checkbox"
          className="custom-control-input"
          id="termsCheckbox"
          name="termsCheckbox"
          onChange={e => dispatch({ type: SET_GIFT, payload: e.target.checked })}
        />
        <label className="custom-control-label text-primary" htmlFor="termsCheckbox">
          <b>Cocher si c&apos;est un cadeau</b>
        </label>
      </div>
    </div>
  );
};

export default CheckoutGift;
