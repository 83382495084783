import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ProductCard from 'components/ProductCard';
import { getImageUrl } from 'helpers/getImageUrl';
import { toTitleCase } from 'helpers/toTitleCase';
import Pagination from 'components/Pagination';
import { addItemToCart } from 'actions/user/cart';
import { RED_TAG, GREEN_TAG } from 'constants/tags';
import Chips from 'containers/Chips';
import useElastic from 'hooks/useElastic';
import { slugifyString } from 'helpers/slugifyString';
import { openLoginModal } from 'actions/loginModal';
import ProductsListHeader from './ProductsListHeader';

const ProductsContainer = () => {
  const { isGrid } = useSelector(({ products }) => products);
  const { user } = useSelector(({ userReducer }) => userReducer);
  const { isLogged } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const tableOfRef = [];
  const { hits } = useElastic();

  if (user) {
    // eslint-disable-next-line no-unused-expressions
    user?.user?.carts?.forEach(cart => {
      tableOfRef.push(cart.productEanId.trim());
    });
  }

  const handleAddItemToCart = (id, ref, title) => () => {
    if (isLogged) {
      dispatch(addItemToCart(id, ref, 1, title));
    } else {
      dispatch(openLoginModal(true));
    }
  };

  return (
    <>
      <ProductsListHeader />
      <Chips />
      <div className={isGrid ? 'row mx-n2 mb-5' : 'list-unstyled'}>
        {hits &&
          hits.map(article => {
            return (
              <ProductCard
                key={article?.id?.raw}
                price={article?.price?.raw}
                unit="TND"
                discount={!article?.has_no_discount?.raw ? 10 : undefined}
                redTag={RED_TAG}
                greenTag={GREEN_TAG}
                quantity={article?.quantity?.raw}
                productLink={`/products/${decodeURI(slugifyString(toTitleCase(article?.title?.raw)))
                  .replace('<em>', '')
                  .replace('</em>', '')}`}
                buttonText="Acheter"
                buttonExistText=" 🛒 "
                title={toTitleCase(article?.title?.snippet)}
                description={article?.description?.snippet}
                isAddedToCart={tableOfRef.includes(article?.id?.raw)}
                onClick={handleAddItemToCart(article?.id?.raw, article?.id?.raw)}
                image={`${getImageUrl(article?.id?.raw, 0, 200)}`}
              />
            );
          })}
      </div>

      <Pagination />
    </>
  );
};

export default ProductsContainer;
