/* eslint-disable react/void-dom-elements-no-children */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_PAYMENT } from 'constants/types/checkout';

const CheckoutPayment = () => {
  const dispatch = useDispatch();
  const isPaymentOnline = useSelector(({ checkout }) => checkout.isPaymentOnline);

  return (
    <div>
      <div className="media align-items-center mb-3">
        <span className="d-block  mr-3">Type de paiement :</span>
        <div className="media-body text-right">
          <span className="text-dark font-weight-bold" />
        </div>
      </div>
      <div className="card shadow-none mb-3">
        <div className="card-body p-0">
          <div className="custom-control custom-radio d-flex align-items-center small">
            <input
              type="radio"
              className="custom-control-input"
              id="paymentRadio1"
              name="paymentRadio"
              onClick={() => {
                dispatch({ type: CHANGE_PAYMENT, payload: false });
              }}
              checked={!isPaymentOnline}
            />
            <label className="custom-control-label ml-1" htmlFor="paymentRadio1">
              <span className="d-block  font-weight-bold mb-1">Payer à la livraison</span>
            </label>
          </div>
        </div>
      </div>
      <div className="card shadow-none mb-3">
        <div className="card-body p-0">
          <div className="custom-control custom-radio d-flex align-items-center small">
            <input
              type="radio"
              className="custom-control-input"
              id="paymentRadio2"
              name="paymentRadio"
              checked={isPaymentOnline}
              onClick={() => {
                dispatch({ type: CHANGE_PAYMENT, payload: true });
              }}
            />
            <label className="custom-control-label ml-1" htmlFor="paymentRadio2">
              <span className="d-block  font-weight-bold mb-1">Payer en ligne</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPayment;
