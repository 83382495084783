import { useSelector } from 'react-redux';
import { searchObjectToQuery } from 'helpers/searchObjectToQuery';

export const useCreateUrl = () => {
  const { elasticSearchState, ages, price, hero, brand, category0, category1, category2, sort, query } = useSelector(
    ({ products }) => products,
  );

  const makeOldUrl = ({
    query: _query,
    sort: _sort,
    ages: _ages,
    price: _price,
    category0: _category0,
    category1: _category1,
    category2: _category2,
    page: _page,
    hero: _hero,
    brand: _brand,
  }) => {
    const page = elasticSearchState.page && JSON.stringify(elasticSearchState.page);
    const age = ages && JSON.stringify(ages);
    const __price = price && JSON.stringify(price);

    return `/products${searchObjectToQuery({
      query: _query === 'zero' ? '' : _query || query,
      sort: _sort || sort,
      ages: _ages === 'zero' ? undefined : JSON.stringify(_ages) || age,
      price: _price === 'zero' ? undefined : JSON.stringify(_price) || __price,
      page: _page === 'zero' ? '{"current": 1, "size": 16}' : _page || page,
      category0: _category0 === 'zero' ? undefined : _category0 || category0,
      category1: _category1 === 'zero' ? undefined : _category1 || category1,
      category2: _category2 === 'zero' ? undefined : _category2 || category2,
      hero: _hero === 'zero' ? undefined : _hero || hero,
      brand: _brand === 'zero' ? undefined : _brand || brand,
    })}`;
  };

  return makeOldUrl;
};
