import InputField from 'components/InputField';
import PropTypes from 'prop-types';
import React from 'react';
import Validators from 'helpers/formValidator';
import { reduxForm } from 'redux-form';
import PhoneAndroidTwoToneIcon from '@material-ui/icons/PhoneAndroidTwoTone';

const UpdatePhoneForm = ({ handleSubmit }) => {
  return (
    <form className="mt-2" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-12">
          <span className="text-primary font-weight-bold ">Indiquer votre numéro de téléphone</span>
          <p className="text-dark">pour bénéficiez encore et toujours de notre avantage client exclusif</p>
        </div>
        <div className="d-flex w-100 col-md-12 justify-content-between">
          <div className="my-auto">
            <InputField
              leftIcon={<PhoneAndroidTwoToneIcon />}
              type="phone"
              name="phone"
              placeholder="99 999 999"
              validate={[Validators.required, Validators.min8, Validators.numeric]}
            />
          </div>
          <div className="">
            <button className="btn btn-outline-primary mt-2" type="submit">
              {' Enregistrer'}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

UpdatePhoneForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'UpdatePhoneForm' })(UpdatePhoneForm);
