/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const HomeLoader = () => {
  return (
    <>
      <>
        <br />
        <div className="container">
          <Skeleton variant="rect" width="70vw" height="50vh" style={{ marginBottom: '50%' }} />
        </div>
      </>
    </>
  );
};

export default HomeLoader;
