import React from 'react';
import SliderPrice from 'components/SliderPrice';

const FilterPrice = () => {
  return (
    <>
      <h4> Prix </h4>
      <SliderPrice name="price" label="Prix" unit="DT" min={0} max={1000} />
    </>
  );
};

export default FilterPrice;
