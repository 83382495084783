/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import SEO from 'components/SEO';
import FacebookTypes from 'constants/facebookTypes';
import SafeAreaView from 'components/SafeAreaView';
import { useHeader } from 'hooks/useHeader';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBestSellers } from 'actions/products';
import { fetchHeroes } from 'actions/heroes';
import { fetchBrands } from 'actions/brands';
import { fetchHeroSection } from 'actions/heroSection';
import { URL_LOGO } from 'constants/logoUrl';
import AsyncHome from './AsyncHome';

const Home = () => {
  const { ages, heroes, brands } = useHeader();
  const { user } = useSelector(({ userReducer }) => userReducer);
  const tableOfRef = [];

  if (user) {
    // eslint-disable-next-line no-unused-expressions
    user?.user?.carts?.forEach(cart => {
      tableOfRef.push(cart.productEanId.trim());
    });
  }
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBestSellers());
    dispatch(fetchHeroes());
    dispatch(fetchBrands());
    dispatch(fetchHeroSection());
  }, []);

  const { bestSellers } = useSelector(({ bestSellersReducer }) => bestSellersReducer);

  const bestHeroes = heroes.slice(0, 4);
  const bestBrands = brands.slice(0, 6);

  return (
    <>
      <SafeAreaView>
        <SEO
          title="Moncef Jouets || Page d'accueil"
          description="Moncef Jouets || Une histoire de générosité"
          type={FacebookTypes.article}
          image={URL_LOGO}
        />
        <AsyncHome
          bestBrands={bestBrands}
          bestHeroes={bestHeroes}
          tableOfRef={tableOfRef}
          ages={ages}
          bestSellers={bestSellers}
        />
      </SafeAreaView>
    </>
  );
};

export default Home;
