export const formatMouvsArray = command => {
  const tabForbidden = ['ZZ_TIMBRE', 'FRAITRANSPORT', '2005000003936'];

  command.mouvs = [
    ...command.mouvs.filter(el => !tabForbidden.includes(el.reference)),
    ...command.mouvs
      .filter(el => tabForbidden.includes(el.reference))
      .sort((a, b) => (a.reference > b.reference ? 1 : -1)),
  ];

  return command;
};
