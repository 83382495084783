import { FETCH_PRODUCT_BY_ID } from 'constants/types/products';
import { startLoading, stopLoading } from 'actions/loading';
import { fetchProductById } from 'services/products';

export const fetchProduct = (slug, onFailure) => async (dispatch, getState) => {
  const rootState = getState();
  const { isPreloaded, slug: _slug } = rootState.product;

  if (isPreloaded && slug === _slug) return;
  dispatch(startLoading());
  try {
    const response = await fetchProductById()({ slug });

    dispatch({ type: FETCH_PRODUCT_BY_ID, payload: response });
  } catch (error) {
    onFailure();
  }
  dispatch(stopLoading());
};
