import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import React from 'react';
import getCurrentLocation from 'helpers/getCurrentLocation';
import { useLocation } from 'react-router-dom';

const SEO = ({
  title = 'Moncef Jouets || Une histoire de générosité',
  description = 'Moncef Jouets || Une histoire de générosité',
  image = 'https://moncefjouets.com/assets/images/logo1.png',
  type = 'article',
  siteName = 'Moncef Jouets',
  author = 'eDonec',
  twitterUrl = 'https://moncefjouets.com',
}) => {
  const location = useLocation();
  const url = getCurrentLocation(location.pathname);

  return (
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      <title>{title}</title>

      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="robots" content="index,follow" />

      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={image} />
      <meta itemProp="picture" content={image} />

      <meta property="fb:app_id" content="1259613714399379" />
      <meta property="og:title" content={title} />
      <meta property="og:type" content={type} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:locale" content="fr_TN" />
      <meta property="article:author" content={author} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={twitterUrl} />
      <meta name="twitter:creator" content={twitterUrl} />

      <meta httpEquiv="Content-Type" content="text/html; charset=Windows-1256" />
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  type: PropTypes.string,
  siteName: PropTypes.string,
  author: PropTypes.string,
  twitterUrl: PropTypes.string,
};

export default SEO;
