/* eslint-disable max-lines */
// import 'styles/scss/index.scss'
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';
import Home from 'pages/Home';
import Login from 'pages/Login';
import NotFound from 'pages/NotFound';
import ProductsListPage from 'pages/ProductsListPage';
import SingleProductPage from 'pages/SingleProductPage';
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Header } from 'containers/Header';
import HeroesListPage from 'pages/HeroesListPage';
import AgeListPage from 'pages/AgeListPage';
import BrandsListPage from 'pages/BrandsListPage';
import CategoriesListPage from 'pages/CategoriesListPage';
import SignUp from 'pages/SignUp';
import ShopSignUp from 'pages/ShopSignUp';
import UserProfilePage from 'pages/UserProfilePage';
import { useDispatch, useSelector } from 'react-redux';
import CartPage from 'pages/CartPage';
import PaymentNotOnline from 'pages/PaymentNotOnline';
import { resetAuthToken } from 'api';
import CheckoutPage from 'pages/CheckoutPage';
import Payment from 'pages/Payment';
import EmptyCardPage from 'pages/EmptyCardPage';
import ContactPage from 'pages/ContactsPage';
import PrivacyPolicyPage from 'pages/PrivacyPolicy';
import TermsPage from 'pages/TermsPage';
import ArchivePage from 'pages/ArchivePage';
import Footer from 'components/Footer';
import ReactGA from 'react-ga';
import CommandDetailPage from 'pages/CommandDetailPage';
import FacturePage from 'pages/FacturePage';
import FactureDetailPage from 'pages/FactureDetailPage';
import AddPhoneModal from 'components/AddPhoneModal';
import LoginModal from 'containers/LoginModal';
import { verifyToken } from 'services/auth';
import { logout } from 'actions/auth';

const HeadedRouter = () => {
  const { isLogged } = useSelector(({ auth }) => auth);
  const { user } = useSelector(({ userReducer }) => userReducer);
  const location = useLocation();
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    verifyToken()().then(isValid => {
      if (!isValid) dispatch(logout());
    });

    ReactGA.set({
      userId: user?.user?.tiers || undefined,
      page: location.pathname,
      // any data that is relevant to the user session
      // that you would like to track with google analytics
    });
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
    user?.user?.phone?.trim() === '' && setOpenModalAdd(true);
  }, [user?.user?.tiers, isLogged, location.pathname, user?.user?.phone, dispatch]);

  return (
    <>
      <Header isLoggedIn={isLogged} />
      <AddPhoneModal openModalAdd={openModalAdd} setOpenModalAdd={setOpenModalAdd} />
      <LoginModal />
      <Switch>
        <Route exact path="/products" component={ProductsListPage} />
        <Route exact path="/products/:slug" component={SingleProductPage} />
        <Route exact path="/command/:id" component={CommandDetailPage} />
        <Route exact path="/facture/:id" component={FactureDetailPage} />
        <Route exact path="/facture" component={FacturePage} />
        <Route exact path="/heroes" component={HeroesListPage} />
        <Route exact path="/ages" component={AgeListPage} />
        <Route exact path="/contacts" component={ContactPage} />
        <Route exact path="/brands" component={BrandsListPage} />
        <Route exact path="/categories" component={CategoriesListPage} />
        <Route exact path="/profile" component={UserProfilePage} />
        <Route exact path="/cart-page" component={CartPage} />
        <Route exact path="/empty-cart" component={EmptyCardPage} />
        <Route exact path="/" component={Home} />
        <Route exact path="/verify-payment" component={Payment} />
        <Route exact path="/checkout" component={CheckoutPage} />
        <Route exact path="/paymentNotOnline" component={PaymentNotOnline} />
        <Route exact path="/terms" component={TermsPage} />
        <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
        <Route exact path="/archive" component={ArchivePage} />
        <Route component={NotFound} />
      </Switch>
      <Footer />
    </>
  );
};

const LOGGED_IN_ROUTES = ['/profile', '/cart-page', '/checkout', '/archive'];

const Root = props => {
  const { isLogged, token } = useSelector(({ auth }) => auth);

  if (token && token.accessToken) resetAuthToken(token.accessToken);
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location.pathname]);

  if (isLogged) {
    if (['/login', '/sign-up'].includes(location.pathname)) {
      return <Redirect to="/" />;
    }
  } else if (LOGGED_IN_ROUTES.includes(location.pathname)) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        pauseOnHover
        draggable
        className="toast-container"
      />
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/sign-up" component={SignUp} />
        <Route exact path="/logout" component={Login} />
        <Route exact path="/sign-up-shop" component={ShopSignUp} />
        <Route component={HeadedRouter} />
      </Switch>
    </>
  );
};

export default Root;
