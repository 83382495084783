import { clientApi, serverApi } from 'api';

export const verifyToken = (isServer = false) => async () => {
  const api = isServer ? serverApi : clientApi;

  try {
    const response = await api.get('/users/verify-token');

    return response.data.token.isValid;
  } catch (error) {
    return false;
  }
};

export const refreshToken = (isServer = false) => async () => {
  const api = isServer ? serverApi : clientApi;

  try {
    const response = await api.get('/users/refresh-token');

    return response.data;
  } catch (error) {
    return false;
  }
};

export const getUserByToken = (isServer = false) => async () => {
  const api = isServer ? serverApi : clientApi;

  try {
    const response = await api.get('/users/by-token');

    return response.data;
  } catch (error) {
    return false;
  }
};
