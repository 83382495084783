/* eslint-disable no-cond-assign */
const formatPathTitle = title => {
  switch (title) {
    case 'products':
      return 'Produits';
    case '/':
      return 'Accueil';
    case 'heroes':
      return 'Héros';
    case 'ages':
      return 'ages';
    case 'brands':
      return 'marques';
    default:
      return title;
  }
};

export default formatPathTitle;
