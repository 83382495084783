import { startLoading, stopLoading } from 'actions/loading';
import { errorHandler } from 'helpers/errorHandler';
import { addItemToCartService, deleteCartItem } from 'services/user';
import {
  ADD_PRODUCT_TO_CART_SUCCESS,
  ADD_PRODUCT_TO_CART_FAILURE,
  DELETE_PRODUCT_FROM_CART,
} from 'constants/types/user';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';

export const addItemToCart = (eanId, ref, quantity, name) => async (dispatch, getState) => {
  dispatch(startLoading());
  ReactGA.plugin.execute('ec', 'addProduct', {
    id: eanId,
    name,
    quantity,
  });
  ReactGA.plugin.execute('ec', 'setAction', 'add');
  ReactGA.send('event', 'UX', 'click', 'add to cart');
  try {
    const response = await addItemToCartService()(eanId, ref, quantity);

    dispatch({ type: ADD_PRODUCT_TO_CART_SUCCESS, payload: response });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: ADD_PRODUCT_TO_CART_FAILURE, error });
  }

  dispatch(stopLoading());
};

export const deleteCart = (id, history) => async (dispatch, getState) => {
  dispatch(startLoading());
  ReactGA.plugin.execute('ec', 'addProduct', {
    id,
  });
  ReactGA.plugin.execute('ec', 'setAction', 'remove');
  ReactGA.send('event', 'UX', 'click', 'add to cart');
  try {
    const payload = await deleteCartItem()(id);

    dispatch({ type: DELETE_PRODUCT_FROM_CART, payload });

    toast('Supprimé de la carte', { className: 'toast-success' });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
