import React, { useEffect } from 'react';
import SafeAreaView from 'components/SafeAreaView';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ListFactures from 'containers/ListFactures';
import { fetchFacturesUser } from 'actions/user';

const FacturePage = () => {
  const { factures, user } = useSelector(({ userReducer }) => userReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    user && dispatch(fetchFacturesUser(user.user.tiers));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SafeAreaView>
      <>
        <div className="container space-1 space-md-2">
          <div className="row">
            <ListFactures factures={factures} />
          </div>
        </div>

        <div className="d-sm-flex justify-content-start m-10">
          <Link className="font-weight-bold" to="/profile">
            <i className="fas fa-angle-left fa-xs mr-1" />
            Retour à vorte profil
          </Link>
        </div>
      </>
    </SafeAreaView>
  );
};

export default FacturePage;
