/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import SafeAreaView from 'components/SafeAreaView';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { Link, useRouteMatch } from 'react-router-dom';
import DashboardFacturePage from 'pages/DashboardFacturePage';
import { fetchFacturesUser } from 'actions/user';

const FactureDetailPage = () => {
  const { params } = useRouteMatch();
  const { factures, user } = useSelector(({ userReducer }) => userReducer);
  const dispatch = useDispatch();
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (!factures) {
      dispatch(fetchFacturesUser(user.user.tiers));
    }
  }, [factures]);

  // eslint-disable-next-line prefer-destructuring
  const facture = factures?.filter(el => el.id.toString() === params.id)[0];

  return (
    <SafeAreaView>
      {facture && (
        <div className="container space-1 space-md-2">
          <button className="btn btn-soft-dark mt-2 align-self-center ml-3" onClick={handlePrint} type="button">
            Imprimer la facture ↓
          </button>
          <br />
          <DashboardFacturePage facture={facture} factureRef={componentRef} />
        </div>
      )}
      <div className="d-sm-flex justify-content-start m-10">
        <Link className="font-weight-bold" to="/profile">
          <i className="fas fa-angle-left fa-xs mr-1" />
          Retour à vorte profil
        </Link>
      </div>
    </SafeAreaView>
  );
};

export default FactureDetailPage;
