/* eslint-disable max-lines */
import InputField from 'components/InputField';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Validators from 'helpers/formValidator';
import { reduxForm, FieldArray, change } from 'redux-form';
import FieldArrayKids from 'components/FieldArrayKids';
import SelectField from 'components/SelectField';
import { optionsGov } from 'constants/signUp';
import { Tunisia } from 'constants/Tunisia';
import { useSelector, useDispatch } from 'react-redux';

const SignUpForm = ({ handleSubmit }) => {
  const [optionsTown, setOptionsTown] = useState([]);
  const [searchedTown, setSearchedTown] = useState([]);
  const [optionsCity, setOptionsCity] = useState([]);
  const [searchedCity, setSearchedCity] = useState([]);
  const [zipValue, setZipValue] = useState('0000');
  const _loading = useSelector(({ loading }) => loading.loading);
  const dispatch = useDispatch();
  const onChangeEtat = value => {
    const tmpTowns = Tunisia.find(element => element.govId === value);

    setSearchedTown(tmpTowns);
    setOptionsTown(
      tmpTowns?.villes?.map(town => {
        return { value: town.ville, label: town.villeName };
      }),
    );
  };
  const onChangeTown = value => {
    const citys = searchedTown.villes.find(element => element.ville === value);

    setSearchedCity(citys);
    setOptionsCity(
      citys.cites.map(city => {
        return { value: city.IDN3, label: city.LIBELLEN3 };
      }),
    );
  };
  const onChangeCity = value => {
    const cityInformation = searchedCity.cites.find(element => element.IDN3 === value);

    setZipValue(cityInformation.CODEP);
    dispatch(change('signUpForm', 'postal', cityInformation.CODEP));
  };

  return (
    <form className="mt-5" onSubmit={handleSubmit}>
      <Link className="top-0 navbar-brand navbar-nav-wrap-brand z-index-2" to="/" aria-label="Front">
        <img src="/assets/images/logo1.png" className="logo-moncef" alt="Logo" />
      </Link>
      <div className="mb-7">
        <h1 className="h3 text-primary font-weight-normal mb-2">
          Borne d&apos;inscription, programme de fidélité Moncef Jouets.
        </h1>
        <p>veuillez remplir le formulaire suivant pour nous rejoindre</p>
      </div>
      <InputField
        type="email"
        name="email"
        label="Adresse Email *"
        placeholder="Adresse Email"
        validate={[Validators.email, Validators.required]}
      />
      <InputField
        type="text"
        name="firstName"
        label="Prénom *"
        placeholder="Prénom"
        validate={[Validators.required, Validators.alpha_space]}
      />
      <InputField
        type="text"
        name="lastName"
        label="Nom *"
        placeholder="Nom"
        validate={[Validators.required, Validators.alpha_space]}
      />
      <InputField
        type="password"
        name="password"
        label="Mot de passe *"
        placeholder="********"
        validate={[Validators.required, Validators.min8]}
      />
      <InputField
        type="password"
        name="confirmPassword"
        label="Confirmez votre mot de passe *"
        placeholder="********"
        validate={[Validators.required, Validators.min8, Validators.passwordConfirm]}
      />
      <InputField
        type="textarea"
        name="address"
        label="Votre adresse *"
        placeholder="Adresse"
        validate={[Validators.required, Validators.max80]}
      />
      <InputField
        type="text"
        name="phone"
        label="Numero de téléphone *"
        placeholder="Telephone"
        validate={[Validators.required, Validators.numeric, Validators.max8]}
      />
      <br />
      <hr />
      <h3 className="space-bottom-1"> Informations suplémentaires :</h3>
      <InputField type="text" name="phone2" label="2em numero de téléphone " placeholder="Telephone2" />
      <SelectField
        name="gov"
        id="gov"
        required
        label="Choisissez votre gouvernorat :"
        className="form-control"
        options={optionsGov}
        onChange={e => onChangeEtat(e)}
      />
      <SelectField
        name="town"
        id="town"
        label="Choisissez votre ville :"
        required
        className="form-control"
        options={optionsTown}
        onChange={onChangeTown}
      />
      <SelectField
        name="city"
        id="city"
        label="Choisissez votre cité :"
        required
        className="form-control"
        options={optionsCity}
        onChange={onChangeCity}
      />
      <InputField type="text" name="postal" label="Code postal" placeholder={zipValue} disabled Values={zipValue} />
      <br />
      <h3 className="text-center  border-top">Ajouter vos enfants et gagner plein de cadeaux</h3>
      <FieldArray name="Enfants" component={FieldArrayKids} />
      <br />
      <div className="row align-items-center mb-5 mt-2 border-top">
        <div className="col-8">
          <span className="small text-muted">Vous avez un compte?</span>
          <Link className="small" to="/login">
            connectez vous
          </Link>
        </div>
        <div className="col-4 text-right">
          <button className="btn btn-primary mb-1 pr-2" type="submit" disabled={_loading}>
            S&apos;inscrire
          </button>
        </div>
      </div>
    </form>
  );
};

SignUpForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};
export default reduxForm({ form: 'signUpForm' })(SignUpForm);
