import React from 'react';
import CheckoutCartItem from 'components/CheckoutCartItem';
import CheckoutDelivery from 'components/CheckoutDelivery';
import CheckoutPricing from 'components/CheckoutPricing';
import PropTypes from 'prop-types';
import CheckoutPayment from 'components/CheckoutPayment';
import CheckoutGift from 'components/CheckoutGift';

const CheckoutSummary = ({ carts }) => {
  const _carts = carts || {};

  return (
    <div className="col-lg-4 order-lg-2 mb-7 mb-lg-0">
      <div className="pl-lg-4">
        <div className="card shadow-soft p-4 mb-4">
          <div className="border-bottom pb-4 mb-4">
            <h2 className="h3 mb-0">Information sur la commande</h2>
          </div>
          <CheckoutDelivery />
          <CheckoutPayment />
          <CheckoutGift />
          {carts &&
            carts?.map(product => {
              return <CheckoutCartItem product={product} key={product.id} />;
            })}
          <CheckoutPricing carts={_carts} />
        </div>
        <div id="shopCartAccordion" className="accordion card shadow-soft mb-4" />
      </div>
    </div>
  );
};

CheckoutSummary.propTypes = {
  carts: PropTypes.object.isRequired,
};

export default CheckoutSummary;
