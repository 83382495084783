/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import { fetchEntsUser } from 'actions/user';
import SafeAreaView from 'components/SafeAreaView';
import SingleCommandContainer from 'containers/SingleCommandContainer';
import { formatMouvsArray } from 'helpers/formatMouvsArray';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';

const CommandDetailPage = () => {
  const { params } = useRouteMatch();
  const { commands } = useSelector(({ userReducer }) => userReducer);
  const { user } = useSelector(({ userReducer }) => userReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!commands) {
      dispatch(fetchEntsUser(user.user.tiers));
    }
  }, [commands]);
  const command = commands?.filter(el => el.id.toString() === params.id)[0];

  const _command = formatMouvsArray(command);

  return (
    <SafeAreaView>
      {_command && (
        <div className="container space-1 space-md-2">
          <div className="row">
            <SingleCommandContainer command={_command} />
          </div>
        </div>
      )}
      <div className="d-sm-flex justify-content-start m-10">
        <Link className="font-weight-bold" to="/profile">
          <i className="fas fa-angle-left fa-xs mr-1" />
          Retour à vorte profil
        </Link>
      </div>
    </SafeAreaView>
  );
};

export default CommandDetailPage;
