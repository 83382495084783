import { clientApi, esApi, serverApi } from 'api';
// import { searchObjectToQuery } from 'helpers/searchObjectToQuery';

export const fetchAllProducts = (isServer = false) => async search => {
  const api = isServer ? serverApi : clientApi;

  const response = await api.get(`/articles/${search}`);

  return response.data;
};

export const fetchProductById = (isServer = false) => async ({ slug }) => {
  const api = isServer ? serverApi : clientApi;

  const response = await api.get(`/articles/${slug.indexOf('?') > 0 ? slug.slice(0, slug.indexOf('?')) : slug}`);

  return {
    article: response.data.article,
    similarArticles: response.data.similarArticles,
    slug: decodeURI(slug.indexOf('?') > 0 ? slug.slice(0, slug.indexOf('?')) : slug),
  };
};

export const fetchAllBestSellers = (isServer = false) => async () => {
  const api = isServer ? serverApi : clientApi;

  const response = await api.get(`/best-sellers`);

  return response.data;
};

export const getAlHits = (isServer = false) => async query => {
  const response = await esApi.post(`/search.json`, query);

  return response.data;
};
