/* eslint-disable no-unused-expressions */
import React from 'react';
import useElastic from 'hooks/useElastic';
import useAge from 'hooks/useAge';

const FilterAgeRange = () => {
  const { facets } = useElastic();
  const { refineAge, currentRefinmentAges } = useAge();

  const addAgeAndRefine = age => {
    let localRefinement = [];
    const isAgeSelected = currentRefinmentAges?.find(o => o?.ages === age);

    if (isAgeSelected) {
      localRefinement = currentRefinmentAges?.filter(o => o?.ages !== age);
    } else {
      // eslint-disable-next-line no-extra-parens
      localRefinement = [...(currentRefinmentAges || []), { ages: age }];
    }

    refineAge(localRefinement);
  };

  return (
    <>
      {facets?.ages['0'].data.map(item => {
        return (
          <div
            key={item.value}
            className="col-6 form-group d-flex align-items-center justify-content-between font-size-1 text-lh-lg text-body mb-1"
          >
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                checked={currentRefinmentAges?.map(element => element.ages)?.includes(item.value)}
                className="custom-control-input"
                id={item.value}
                name={item.value}
                onChange={() => addAgeAndRefine(item.value)}
              />
              <label className="custom-control-label" htmlFor={item.value}>
                {item.value}
              </label>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default FilterAgeRange;
