import { LOGIN_SUCCESS } from 'constants/types/auth';
import { OPEN_LOGIN_MODAL, CLOSE_LOGIN_MODAL } from 'constants/types/loginModal';

const initialState = {
  isLoginModalOpen: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_LOGIN_MODAL:
      return { ...state, isLoginModalOpen: true };
    case CLOSE_LOGIN_MODAL:
      return { ...state, isLoginModalOpen: false };
    case LOGIN_SUCCESS:
      return { ...state, isLoginModalOpen: false };
    default:
      return state;
  }
};
