import { LOADING_START, LOADING_FINISHED } from 'constants/types/loading';
import {
  UPDATE_PROFILE_INFORMATION_SUCCESS,
  UPDATE_PROFILE_INFORMATION_FAILURE,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  FETCH_ENTS_BY_USER,
  FETCH_FACTURES_BY_USER,
} from 'constants/types/user';
import {
  updateUserProfile,
  updateUserChildrenProfile,
  updatePassword as updatePasswordService,
  fetchEntsUserService,
  fetchFacturesUserService,
} from 'services/user';
import { toast } from 'react-toastify';
import { SET_USER } from 'constants/types/auth';

export * from './cart';

export const updateUser = data => async dispatch => {
  try {
    dispatch({ type: LOADING_START });
    const payload = await updateUserProfile()(data);

    dispatch({ type: SET_USER, payload });
    // dispatch({ type: UPDATE_PROFILE_INFORMATION_SUCCESS });
    // dispatch({ type: LOADING_FINISHED });
    // toast('Vos informations utilisateur on été changé', { className: 'toast-success' });
  } catch (error) {
    dispatch({ type: UPDATE_PROFILE_INFORMATION_FAILURE, error });
    dispatch({ type: LOADING_FINISHED });
  }
};

export const updateUserChildren = data => async dispatch => {
  try {
    dispatch({ type: LOADING_START });
    const payload = await updateUserChildrenProfile()(data);

    dispatch({ type: SET_USER, payload });
    dispatch({ type: UPDATE_PROFILE_INFORMATION_SUCCESS });
    dispatch({ type: LOADING_FINISHED });
    toast('Vos informations sur vos enfants on été changé', { className: 'toast-success' });
  } catch (error) {
    dispatch({ type: UPDATE_PROFILE_INFORMATION_FAILURE, error });
    dispatch({ type: LOADING_FINISHED });
  }
};

export const updatePassword = data => async dispatch => {
  try {
    dispatch({ type: LOADING_START });
    await updatePasswordService()(data);
    toast('Votre mot de passe a été modifier avec succès', { className: 'toast-success' });
    dispatch({ type: UPDATE_PASSWORD_SUCCESS });
    dispatch({ type: LOADING_FINISHED });
  } catch (error) {
    dispatch({ type: UPDATE_PASSWORD_FAILURE, error });
    dispatch({ type: LOADING_FINISHED });
  }
};
export const fetchEntsUser = tiers => async dispatch => {
  try {
    dispatch({ type: LOADING_START });
    const commands = await fetchEntsUserService()(tiers);

    dispatch({ type: FETCH_ENTS_BY_USER, payload: commands });
    dispatch({ type: LOADING_FINISHED });
  } catch (error) {
    dispatch({ type: LOADING_FINISHED });
  }
};
export const fetchFacturesUser = tiers => async dispatch => {
  try {
    dispatch({ type: LOADING_START });
    const commands = await fetchFacturesUserService()(tiers);

    dispatch({ type: FETCH_FACTURES_BY_USER, payload: commands });
    dispatch({ type: LOADING_FINISHED });
  } catch (error) {
    dispatch({ type: LOADING_FINISHED });
  }
};
