export const queryToSearchObject = query => {
  if (!query || query === '?') return {};
  const _query = query.replace('?', '').split('&');

  let res = {};

  _query.forEach(o => {
    res = { ...res, [o.split('=')[0]]: o.split('=')[1] };
  });

  return res;
};
