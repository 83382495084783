import React from 'react';
import SafeAreaView from 'components/SafeAreaView';
import CartProducts from 'containers/CartProducts';
import CheckoutContainer from 'containers/CheckoutContainer';
import { useSelector } from 'react-redux';
import { numberItemInCarts } from 'helpers/numberItemInCart';
import { Link } from 'react-router-dom';

const CartPage = () => {
  const { user } = useSelector(({ userReducer }) => userReducer);

  const carts = user?.user?.carts;

  return (
    <SafeAreaView>
      {numberItemInCarts(carts) !== 0 ? (
        <div className="container space-1 space-md-2">
          <div className="row">
            {carts && <CartProducts userCart={carts} />}
            {carts && <CheckoutContainer userCart={carts} />}
          </div>
        </div>
      ) : (
        <>
          <div className="container space-1 space-md-2">VOTRE PANIER EST VIDE . . .</div>
          <div className="d-sm-flex justify-content-start m-10">
            <Link className="font-weight-bold" to="/products">
              <i className="fas fa-angle-left fa-xs mr-1" />
              Continuez votre shopping
            </Link>
          </div>
        </>
      )}
    </SafeAreaView>
  );
};

export default CartPage;
