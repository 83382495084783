import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toTitleCase } from 'helpers/toTitleCase';

const BreadCrumb = ({ paths, col = 6 }) => {
  return (
    <div className={`col-sm-${col}`}>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb breadcrumb-no-gutter justify-content-sm-end mb-0">
          {paths.map((p, i) =>
            paths.length - 1 === i ? (
              <li key={p.title} className="breadcrumb-item active">
                {toTitleCase(p.title.replace(/-/g, ' '))}
              </li>
            ) : (
              <li key={p.title} className="breadcrumb-item">
                <Link to={p.link}>{toTitleCase(p.title)}</Link>
              </li>
            ),
          )}
        </ol>
      </nav>
    </div>
  );
};

BreadCrumb.propTypes = {
  paths: PropTypes.array,
  col: PropTypes.string,
};

export default BreadCrumb;
