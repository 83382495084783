/* eslint-disable no-unused-expressions */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import HeroCardImage from 'components/HeroCardImage';
import useElastic from 'hooks/useElastic';
import useBrandHeroes from 'hooks/useBrandHeroes';
import { useCreateUrl } from 'hooks/useCreateUrl';

const FilterHero = () => {
  const [isCollapse, setIsCollapse] = useState(true);
  const toggleCollapse = () => setIsCollapse(!isCollapse);
  const { facets } = useElastic();
  const { refineHero, currentRefinementHero: _hero } = useBrandHeroes();
  const heroesAr = [];
  const createUrl = useCreateUrl();

  facets?.hero?.['0'].data.forEach((hero, index) => {
    hero.value;
    JSON.parse(hero.value);
    heroesAr.push({
      image: JSON.parse(hero.value).image,
      id: JSON.parse(hero.value).name,
      value: hero.value,
    });
  });

  return (
    <div>
      <h4>Choisir votre hero</h4>
      <div className="row justify-content-center">
        {heroesAr
          .filter((e, index) => index < 4)
          .map(h => (
            <HeroCardImage
              link={createUrl({ hero: h.value === _hero ? 'zero' : h.value, page: '{"size":16,"current":1}' })}
              // currentrefineHeroment={currentrefineHeroment}
              key={h.id}
              model={h}
              refineHero={() => {
                refineHero(h.value);
              }}
            />
          ))}
      </div>
      <Collapse isOpen={!isCollapse} className="row justify-content-center">
        {heroesAr
          .filter((e, index) => index >= 4)
          .map(h => (
            <HeroCardImage
              link={createUrl({ hero: h.value === _hero ? 'zero' : h.value, page: '{"size":16,"current":1}' })}
              // currentrefineHeroment={currentrefineHeroment}
              key={h.id}
              model={h}
              refineHero={() => {
                refineHero(h.value);
              }}
            />
          ))}
      </Collapse>

      <div onClick={toggleCollapse} className="btn btn-soft-indigo btn-block">
        {isCollapse ? 'Afficher +' : 'Afficher -'}
      </div>
    </div>
  );
};

export default FilterHero;
