/* eslint-disable max-lines */
/* eslint-disable no-restricted-globals */
// import { queryToSearchObject } from 'helpers/queryToSearchObject';
// import { searchObjectToQuery } from 'helpers/searchObjectToQuery';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { useHeader } from 'hooks/useHeader';
// import SearchBar from 'containers/SearchBar';
// import CustomSearchBox from 'containers/SearchBar';
// import { connectRefinementList } from 'react-instantsearch-dom';
import SearchBar from 'containers/SearchBar';
import HeaderLinks from './HeaderLinks';
import HeaderTopBar from './HeaderTopBar';

export const Header = ({ isLoggedIn }) => {
  // const { pathname, search } = useLocation();
  const { pathname } = useLocation();
  // const searchObject = queryToSearchObject(search) || {};
  const { brands, heroes, categories, ages } = useHeader();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isNavBarOpen, setIsNavBarOpen] = useState(false);
  const handleToggleNavBar = () => {
    setIsSearchOpen(isSearchOpen && !isNavBarOpen && false);
    setIsNavBarOpen(!isNavBarOpen);
  };

  return (
    <header id="header" className="header shadow-sm  header-sticky-top-lg left-aligned-navbar">
      <div className="header-section">
        <div className="container">
          <HeaderTopBar isLoggedIn={isLoggedIn} />
          <Link
            className="position-absolute  top-0 navbar-brand navbar-nav-wrap-brand z-index-2"
            to={pathname === '/' ? '/products?sort=asc&page={"size":16,"current":1}' : '/'}
            aria-label="Front"
          >
            <img src="/assets/images/logo1.png" className="logo-moncef" alt="Logo" />
          </Link>
          <div id="logoAndNav" className=" mt-lg-n2">
            <nav className="js-mega-menu navbar navbar-expand-lg">
              <div className="navbar-nav-wrap">
                <div className="navbar-brand navbar-nav-wrap-brand" style={{ opacity: 0 }} aria-label="Front">
                  <img height={10} src="/assets/images/logo1.png" alt="Logo" />
                </div>
                <div className="navbar-nav-wrap-content">
                  <div className="hs-unfold d-lg-none d-inline-block position-static">
                    <div
                      onClick={() => setIsSearchOpen(!isSearchOpen)}
                      className="js-hs-unfold-invoker btn btn-icon btn-icon rounded-circle mr-1"
                    >
                      <i className="fas fa-search" />
                    </div>
                    <div
                      id="searchClassic"
                      className={`hs-unfold-content dropdown-menu w-100 shadow border-0 rounded-0 px-3 mt-0 ${
                        isSearchOpen ? ' hs-unfold-content-initialized hs-unfold-css-animation animated slideInUp' : ''
                      }`}
                    >
                      <SearchBar />
                    </div>
                  </div>
                </div>
                <button
                  onClick={handleToggleNavBar}
                  type="button"
                  className="navbar-toggler navbar-nav-wrap-navbar-toggler btn btn-icon btn-sm rounded-circle "
                >
                  {!isNavBarOpen ? (
                    <span className="navbar-toggler-default">
                      <svg width={14} height={14} viewBox="0 0 18 18" xmlns="https://www.w3.org/2000/svg">
                        <path
                          fill="currentColor"
                          d="M17.4,6.2H0.6C0.3,6.2,0,5.9,0,5.5V4.1c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,5.9,17.7,6.2,17.4,6.2z M17.4,14.1H0.6c-0.3,0-0.6-0.3-0.6-0.7V12c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,13.7,17.7,14.1,17.4,14.1z"
                        />
                      </svg>
                    </span>
                  ) : (
                    <span className="navbar-toggler-default">
                      <svg width={14} height={14} viewBox="0 0 18 18" xmlns="https://www.w3.org/2000/svg">
                        <path
                          fill="currentColor"
                          d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"
                        />
                      </svg>
                    </span>
                  )}
                </button>
                <div
                  id="navBar"
                  className={`navbar-nav-wrap-navbar collapse navbar-collapse ${
                    isNavBarOpen ? 'show animated slideInUp' : ''
                  }`}
                >
                  <ul className="navbar-nav">
                    <li className="d-none d-lg-inline-block navbar-nav-item flex-grow-1 mr-2">
                      {/* <SearchBox onSubmit={algoliaSearchArticles} /> */}

                      <SearchBar />

                      {/* <SearchBar
                        className="search-bar"
                        onSubmit={searchArticles}
                        initialValues={{ search: decodeURI(searchObject.search || '') }}
                      /> */}
                    </li>
                    <HeaderLinks content={heroes} to="/heroes" label="Héros" icon="fa fa-bolt" />
                    <HeaderLinks content={categories} to="/categories" label="Catégories" icon="fa fa-th " />
                    <HeaderLinks content={brands} to="/brands" label="Marques" icon="fa fa-flag" />
                    <HeaderLinks content={ages} to="/ages" label="Ages" icon="fas fa-baby" />
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};
