/* eslint-disable react/button-has-type */
import React from 'react';
import LoaderComponent from 'components/Loader';
import CustomClearRefinementsBtutton from 'components/ClearRefinementsButton';
import { useSelector } from 'react-redux';
import FilterPrice from './FilterPrice';
import FilterAge from './FilterAge';
import FilterBrand from './FilterBrand';
import FilterCategory from './FilterCategory';
import FilterHero from './FilterHero';

export const FilterContainer = () => {
  const _loading = useSelector(({ loading }) => loading.loading);

  return (
    <div>
      {_loading && (
        <div style={{ position: 'absolute', left: '30%', top: '10%', zIndex: '99' }}>
          <LoaderComponent />
        </div>
      )}
      {_loading && (
        <div style={{ position: 'absolute', background: 'transparent', height: '100%', width: '100%', zIndex: '98' }} />
      )}
      <div style={_loading ? { filter: 'blur(4px)' } : undefined}>
        <CustomClearRefinementsBtutton />
        <FilterPrice />
        <div className="border-bottom pb-4 mb-4" />
        <FilterCategory />
        <div className="border-bottom pb-4 mb-4" />
        <FilterHero />
        <div className="border-bottom pb-4 mb-4" />
        <FilterAge />
        <div className="border-bottom pb-4 mb-4" />
        <FilterBrand />
        <div className="border-bottom pb-4 mb-4" />
        <CustomClearRefinementsBtutton />
      </div>
    </div>
  );
};

export default FilterContainer;
