import { Link } from 'react-router-dom';

import React from 'react';
import SafeAreaView from 'components/SafeAreaView';

const PaymentNotOnline = () => {
  return (
    <SafeAreaView>
      <div className="container space-2">
        <div className="w-md-80 w-lg-50 text-center mx-md-auto">
          <i className="fas  fa-exclamation-circle text-warning fa-5x mb-3" />
          <div className="mb-5">
            <h1 className="h2">
              Votre commande a été passé on vous contactera par téléphone pour la validation de l&apos;achat
            </h1>
          </div>

          <Link to="/">
            <div className="btn btn-primary btn-pill transition-3d-hover px-5">Retouner à l&apos;accueil</div>
          </Link>
        </div>
      </div>
    </SafeAreaView>
  );
};

export default PaymentNotOnline;
