import { FETCH_CATEGORIES } from 'constants/types/categories';
import { startLoading, stopLoading } from 'actions/loading';
import { fetchAllCategories } from 'services/categories';
import { errorHandler } from 'helpers/errorHandler';

export const fetchCategories = search => async (dispatch, getState) => {
  const rootState = getState();
  const { isPreloaded } = rootState.categoriesReducer;

  if (isPreloaded) return;

  dispatch(startLoading());

  try {
    const response = await fetchAllCategories()();

    dispatch({ type: FETCH_CATEGORIES, payload: response });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
