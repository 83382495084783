import { FETCH_HERO_SECTION } from 'constants/types/heroSection';

const initialState = {
  heroSection: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_HERO_SECTION:
      return { ...state, heroSection: payload };
    default:
      return state;
  }
};
