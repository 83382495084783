/* eslint-disable no-extra-parens */
import React from 'react';
import ImageAsyncComponent from 'components/ImageAsyncComponent';
import { getImageUrl } from 'helpers/getImageUrl';
import PropTypes from 'prop-types';
import { slugifyString } from 'helpers/slugifyString';
import { Link } from 'react-router-dom';

const CheckoutCartItem = ({ product }) => {
  return (
    <div className="d-flex align-items-center my-2">
      <div className=" avatar avatar-lg mr-3">
        <ImageAsyncComponent image={getImageUrl(product?.product?.eanId)}> </ImageAsyncComponent>
        <sup className="avatar-status avatar-primary">{product.quantity}</sup>
      </div>
      <div className="media-body">
        {product?.product?.hasNoDiscount ? (
          <span className="badge badge-warning badge-pill text-white">Hors promo</span>
        ) : (
          ''
        )}

        <Link className="h6 d-block" to={`/products/${slugifyString(product.product.title)}`}>
          {product?.product?.title}
        </Link>
      </div>
    </div>
  );
};

CheckoutCartItem.propTypes = {
  product: PropTypes.object.isRequired,
};
export default CheckoutCartItem;
