/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchCategories } from 'actions/categories';
import { fetchHeroes } from 'actions/heroes';
import SafeAreaView from 'components/SafeAreaView';
import formatPaths from 'helpers/formatPaths';
import FacebookTypes from 'constants/facebookTypes';
import { URL_LOGO } from 'constants/logoUrl';
import SEO from 'components/SEO';
import { queryToSearchObject } from 'helpers/queryToSearchObject';
import { useElasticHits } from 'hooks/useElasticHits';
import AsyncProductsListPage from './AsyncProductsListPage';

const ProductsListPage = () => {
  const { search, pathname } = useLocation();
  const paths = formatPaths(pathname);
  const dispatch = useDispatch();

  useElasticHits();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [search]);
  let descriptionSEO =
    'Dans le Site Moncef Jouets vous trouverez une panoplie de produits puzzle, jeux vidéos etc. Par exemple un filtre où';
  const filterArray = [];
  const searchObject = queryToSearchObject(search);

  Object.keys(searchObject).forEach(key => {
    if (key === 'brand') filterArray.push({ brand: 'marque', brandValue: searchObject[key] });
    if (key === 'hero') filterArray.push({ hero: 'héro', heroValue: searchObject[key] });
    if (key === 'category') filterArray.push({ category: 'catégorie', categoryValue: searchObject[key] });
  });

  filterArray.forEach(e => {
    if (e.hero) descriptionSEO += ` le ${e.hero} est ${e.heroValue}`;
    if (e.brand) descriptionSEO += ` la ${e.brand} est ${e.brandValue}`;
    if (e.category) descriptionSEO += ` la ${e.category} est ${e.categoryValue}`;
  });
  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchHeroes());
  }, []);

  return (
    <SafeAreaView>
      <SEO
        title="Moncef Jouets || tous les produits"
        description={descriptionSEO}
        type={FacebookTypes.article}
        image={URL_LOGO}
      />
      <AsyncProductsListPage paths={paths} />
    </SafeAreaView>
  );
};

export default React.memo(ProductsListPage);
