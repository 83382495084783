/* eslint-disable max-len */
import AuthSidebar from 'components/AuthSidebar';
import LoginForm from 'containers/LoginForm';
import React from 'react';
import SEO from 'components/SEO';
import { useDispatch } from 'react-redux';
import { login, loginFacebook, loginGoogle } from 'actions/auth';
import { useHistory } from 'react-router-dom';

const Login = () => {
  const history = useHistory();
  const disptach = useDispatch();
  const responseFacebook = e => {
    const values = {
      name: e.name,
      email: e.email,
    };

    disptach(loginFacebook(values, history));
  };
  const responseGoogle = e => {
    const values = {
      firstName: e.profileObj.familyName,
      lastName: e.profileObj.givenName,
      email: e.profileObj.email,
    };

    disptach(loginGoogle(values, history));
  };
  const handleSubmit = values => {
    disptach(login(values, history));
  };

  return (
    <>
      <SEO title="Login page" description="Se connecter à Moncef Jouets" />
      <main id="content" role="main">
        <div className="d-flex align-items-center position-relative height-lg-100vh">
          <AuthSidebar />
          <div className="container">
            <div className="row no-gutters">
              <div className="col-md-8  col-lg-7 col-xl-6 offset-md-2 offset-lg-6 offset-xl-5 space-0 space-md-4">
                <LoginForm
                  onSubmit={handleSubmit}
                  responseFacebook={responseFacebook}
                  responseGoogle={responseGoogle}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Login;
// export default Login;
