/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
import { FILTER_BRAND, FILTER_HERO } from 'constants/types/products';
import { useDispatch, useSelector } from 'react-redux';

const useBrandHeroes = () => {
  const dispatch = useDispatch();
  const { hero, brand } = useSelector(({ products }) => products);
  const refineHero = _hero => {
    if (hero !== _hero) {
      dispatch({ type: FILTER_HERO, payload: _hero });
    } else {
      dispatch({ type: FILTER_HERO, payload: undefined });
    }
  };

  const refineBrand = _brand => {
    if (brand !== _brand) {
      dispatch({ type: FILTER_BRAND, payload: _brand });
    } else {
      dispatch({ type: FILTER_BRAND, payload: undefined });
    }
  };

  return {
    refineBrand,
    refineHero,
    currentRefinementBrand: brand,
    currentRefinementHero: hero,
  };
};

export default useBrandHeroes;
