import { FETCH_CATEGORIES, CATEGORY_STATE } from 'constants/types/categories';

const initialState = {
  categories: [],
  categoryState: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CATEGORIES:
      return { ...state, categories: payload };
    case CATEGORY_STATE:
      return { ...state, categoryState: payload };
    default:
      return state;
  }
};
