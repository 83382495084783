import React from 'react';
import SafeAreaView from 'components/SafeAreaView';
import FacebookTypes from 'constants/facebookTypes';
import { URL_LOGO } from 'constants/logoUrl';
import SEO from 'components/SEO';

const EmptyCardPage = () => {
  return (
    <>
      <SEO title="Page produit vide" description="Page produit vide" type={FacebookTypes.article} image={URL_LOGO} />
      <SafeAreaView>
        <div className="container space-1 space-md-2">VOTRE PANIER EST VIDE . . .</div>
      </SafeAreaView>
    </>
  );
};

export default EmptyCardPage;
