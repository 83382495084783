/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
import { ELASTIC_STATE_CHANGE, FILTER_QUERY, RESET_FILTERS } from 'constants/types/products';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useCreateUrl } from './useCreateUrl';

const useElastic = () => {
  const { articles, query } = useSelector(({ products }) => products);
  const history = useHistory();
  const dispatch = useDispatch();
  const { elasticSearchState, facets, meta } = useSelector(({ products }) => products);
  const createUrl = useCreateUrl();
  const refineSearch = text => {
    dispatch({ type: FILTER_QUERY, payload: text });
    const url = createUrl({ query: text || 'zero', sort: 'asc', page: '{"size":16,"current":1}' });

    history.push(url);
  };

  const refineInitial = initialState => {
    dispatch({ type: ELASTIC_STATE_CHANGE, payload: initialState });
  };

  const resetFilters = initialState => {
    dispatch({ type: RESET_FILTERS });
  };

  return {
    elasticSearchState,
    hits: articles,
    facets,
    meta,
    query,
    refineSearch,
    refineInitial,
    resetFilters,
  };
};

export default useElastic;
