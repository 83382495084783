import { FETCH_BRANDS } from 'constants/types/brands';
import { startLoading, stopLoading } from 'actions/loading';
import { fetchAllBrands } from 'services/brands';
import { errorHandler } from 'helpers/errorHandler';

export const fetchBrands = query => async (dispatch, getState) => {
  const rootState = getState();
  const { isPreloaded } = rootState.brandsReducer;

  if (isPreloaded) return;

  dispatch(startLoading());

  try {
    const response = await fetchAllBrands()(query);

    dispatch({ type: FETCH_BRANDS, payload: response });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
