import React from 'react';
import { useDispatch } from 'react-redux';
import { updateUser } from 'actions/user';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import UpdatePhoneForm from 'containers/UpdatePhoneForm';
import Modal from 'components/Modal';

export const AddPhoneModal = ({ openModalAdd, setOpenModalAdd }) => {
  const history = useHistory();
  const disptach = useDispatch();
  const handleSubmit = values => {
    disptach(updateUser(values, history));
    setOpenModalAdd(!openModalAdd);
  };

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={openModalAdd}
      closeModal={() => setOpenModalAdd(false)}
      title={<p className="h3 text-primary font-weight-normal mb-0">Soyez les premiers à recevoir vos codes promo</p>}
    >
      <UpdatePhoneForm onSubmit={handleSubmit} setOpenModalAdd={setOpenModalAdd} openModalAdd={openModalAdd} />
    </Modal>
  );
};
AddPhoneModal.propTypes = {
  openModalAdd: PropTypes.bool.isRequired,
  setOpenModalAdd: PropTypes.func.isRequired,
};
export default AddPhoneModal;
