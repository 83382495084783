import NotFoundHeader from 'components/NotFoundHeader';
import NotFoundSVG from 'components/SVG/NotFoundSVG';
import React from 'react';
import SEO from 'components/SEO';

const NotFound = () => {
  return (
    <>
      <SEO title="Not Found page" description="Vous êtes perdus?" />
      <NotFoundHeader />
      <NotFoundSVG className="not-found-svg z-index-2" />
    </>
  );
};

export default NotFound;
