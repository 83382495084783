import FILTER_AGE from 'constants/filterAge';
import { useSelector } from 'react-redux';
import { toTitleCase } from 'helpers/toTitleCase';
import getImage from 'helpers/getImage';

const displayAge = (ageMin, ageMax) => {
  const isMonth = ageMin < 12;
  const maxAgeCheck = ageMax > 1180;

  if (!isMonth) {
    ageMin /= 12;
    ageMax /= 12;
  }
  if (!maxAgeCheck) {
    return `${ageMin}-${ageMax} ${isMonth ? '(mois)' : '(ans)'}`;
  }

  return `${ageMin}+ ${isMonth ? '(mois)' : '(ans)'}`;
};

export const useHeader = () => {
  const { brands } = useSelector(({ brandsReducer }) => brandsReducer);
  const _brands = brands.map(o => ({
    name: toTitleCase(o.name),
    image: getImage(o.image),
    brandId: o.brandId,
    searchKey: `?page={"size":16,"current":1}&brand=${decodeURI(
      JSON.stringify({ id: o.brandId, name: o.name, image: o.image }),
    )}`,
  }));
  const { categories } = useSelector(({ categoriesReducer }) => categoriesReducer);
  const { heroes } = useSelector(({ heroesReducer }) => heroesReducer);
  const ages = FILTER_AGE.map((o, index) => ({
    name: toTitleCase(displayAge(o.min, o.max)),
    image: `/assets/images/ages/${index}.jpg`,
    searchKey: `?ages=[${JSON.stringify({
      ages: toTitleCase(displayAge(o.min, o.max)),
    })}]&page={"size":16,"current":1}`,
  }));

  return {
    brands: _brands,
    heroes: heroes.map(o => ({
      name: toTitleCase(o.title1),
      image: getImage(o.image),
      searchKey: `?page={"size":16,"current":1}&hero=${decodeURI(
        JSON.stringify({ name: o.title1.toLowerCase(), image: o.image }),
      )}`,
    })),
    categories: categories.map(o => ({
      name: toTitleCase(o.title),
      image: getImage(o.image),
      searchKey: `?category0=${JSON.stringify({ label: decodeURI(o.title) })}&page={"size":16,"current":1}`,
    })),
    ages,
  };
};
