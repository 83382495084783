import {
  CHANGE_DELIVERY,
  CHANGE_PAYMENT,
  SET_SHOP,
  SET_GIFT,
  SET_TOTAL,
  SET_PROMOTION,
  PROMOTION_SUCCESS,
} from 'constants/types/checkout';
import { toast } from 'react-toastify';
import { id } from 'api';

const initialState = {
  isDelivery: true,
  isPaymentOnline: false,
  shop: '',
  promotionCode: 'COMPTE_PROMO',
  promotion: {
    promotion: {
      id,
      CE4: '2',
      promotionCode: 'COMPTE_PROMO',
      codeTitle: 'COMPTE_PROMO',
      discountType: '%',
      discountPerc: 10,
      discountAmount: '0',
      startDate: '2020-08-01',
      endDate: '2200-01-01',
    },
  },
  totalToPay: 0,
  isGift: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PROMOTION_SUCCESS:
      toast('Promotion appliquée', { className: 'toast-success' });

      return { ...state, promotion: payload };
    case CHANGE_DELIVERY:
      return { ...state, isDelivery: payload };
    case CHANGE_PAYMENT:
      return { ...state, isPaymentOnline: payload };
    case SET_SHOP:
      return { ...state, shop: payload };
    case SET_PROMOTION:
      return { ...state, promotionCode: payload };
    case SET_TOTAL:
      return { ...state, totalToPay: payload };
    case SET_GIFT:
      return { ...state, isGift: payload };
    default:
      return state;
  }
};
