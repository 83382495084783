import { applyMiddleware, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'js-cookie';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from 'reducers';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  whitelist: ['auth'],
  storage: new CookieStorage(Cookies, {
    expiration: {
      default: 365 * 86400, // Cookies expire after one year
    },
  }),
};

const rootReducer = persistReducer(persistConfig, reducers);

export const createReduxPersistor = (store, cb) => persistStore(store, {}, cb);

const configureStore = (preloadedState = {}) => {
  const store = createStore(rootReducer, preloadedState, composeWithDevTools(applyMiddleware(thunk)));

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextRootReducer = rootReducer;

      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export default configureStore;
