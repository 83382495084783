import { FETCH_BEST_SELLERS, FETCH_PRODUCTS } from 'constants/types/products';
import { startLoading, stopLoading } from 'actions/loading';
import { errorHandler } from 'helpers/errorHandler';
import { fetchAllBestSellers, getAlHits } from 'services/products';

export const fetchBestSellers = query => async (dispatch, getState) => {
  const rootState = getState();
  const { isPreloaded } = rootState.bestSellersReducer;

  if (isPreloaded) return;

  dispatch(startLoading());

  try {
    const response = await fetchAllBestSellers()();

    dispatch({ type: FETCH_BEST_SELLERS, payload: response });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
export const fetchProducts = query => async (dispatch, getState) => {
  // const rootState = getState();
  // const { isPreloaded } = rootState.bestSellersReducer;

  // if (isPreloaded) return;

  // dispatch(startLoading());
  try {
    const response = await getAlHits()(query);

    dispatch({ type: FETCH_PRODUCTS, payload: response });
  } catch (error) {
    errorHandler(error);
  }

  // dispatch(stopLoading());
};
