import { SET_QUANTITY } from 'constants/types/carts';
import { RESET_USER, SET_USER } from 'constants/types/auth';
import {
  UPDATE_PROFILE_INFORMATION_FAILURE,
  UPDATE_PROFILE_INFORMATION_SUCCESS,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  DELETE_PRODUCT_FROM_CART,
  ADD_PRODUCT_TO_CART_SUCCESS,
  FETCH_ENTS_BY_USER,
  FETCH_FACTURES_BY_USER,
} from 'constants/types/user';

const initialState = {
  user: null,
  isPreloaded: false,
  error: null,
};

export default (state = initialState, { type, payload, error }) => {
  let newCart = [];

  switch (type) {
    case SET_USER:
      return { ...state, user: payload };
    case RESET_USER:
      return { user: null };
    case UPDATE_PROFILE_INFORMATION_SUCCESS:
    case UPDATE_PASSWORD_SUCCESS:
      return { ...state, loaded: true };
    case FETCH_ENTS_BY_USER:
      return { ...state, commands: payload };
    case FETCH_FACTURES_BY_USER:
      return { ...state, factures: payload };
    case UPDATE_PASSWORD_FAILURE:
    case UPDATE_PROFILE_INFORMATION_FAILURE:
      return { ...state, loaded: true, error };
    case DELETE_PRODUCT_FROM_CART:
    case ADD_PRODUCT_TO_CART_SUCCESS:
      return { ...state, user: { ...state.user, user: { ...state.user.user, carts: payload } } };
    case SET_QUANTITY:
      newCart = state.user.user?.carts?.map(o => {
        if (o.id === payload.id) return { ...o, quantity: payload.quantity };

        return o;
      });

      return { ...state, user: { ...state.user, user: { ...state.user.user, carts: newCart } } };
    default:
      return state;
  }
};
