/* eslint-disable max-len */
import AuthSidebar from 'components/AuthSidebar';
import React from 'react';
import SEO from 'components/SEO';
import { useDispatch } from 'react-redux';
import { signUp } from 'actions/auth';
import { useHistory } from 'react-router-dom';
import SignUpForm from 'containers/SignUpForm';

const SignUp = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = values => {
    dispatch(signUp(values, history));
  };

  return (
    <>
      <SEO title="SignUp page" description="la page d'inscription" />
      <main id="content" role="main">
        <div className="d-flex align-items-center position-relative height-lg-100vh">
          <AuthSidebar />
          <div className="container">
            <div className="row no-gutters">
              <div className="col-md-8 col-lg-7 col-xl-6 offset-md-2 offset-lg-6 offset-xl-5 space-top-3 space-top-xl-0">
                <SignUpForm onSubmit={handleSubmit} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default SignUp;
