import { startLoading, stopLoading } from 'actions/loading';
import { CHECKOUT_SUCCESS, PROMOTION_SUCCESS } from 'constants/types/checkout';
import { checkoutService, promotionService } from 'services/checkout';
import { errorHandler } from 'helpers/errorHandler';
import { getUserByToken } from 'services/auth';
import { SET_USER } from 'constants/types/auth';
import { toast } from 'react-toastify';

export const checkoutAction = (values, history) => async dispatch => {
  dispatch(startLoading());

  try {
    const response = await checkoutService()(values);

    if (values.isPaymentOnline) {
      window.location.replace(response.data);
    } else {
      history.push('/paymentNotOnline');
    }

    dispatch({ type: CHECKOUT_SUCCESS, payload: response });
    const payload = await getUserByToken()();

    dispatch({ type: SET_USER, payload });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};

export const promotionAction = (values, history) => async dispatch => {
  dispatch(startLoading());
  if (values === '') values = 'undefined';

  try {
    const response = await promotionService()(values);

    const startDate = new Date(response?.data?.promotion?.startDate);
    const endDate = new Date(response?.data?.promotion?.endDate);

    if (response.data.promotion === null || new Date() < startDate || new Date() > endDate) {
      toast('Promotion inexistante ou date invalide !', { className: 'toast-info' });
    } else if (response.data.promotion.numberOfUse === 0) {
      toast('Promotion épuisé', { className: 'toast-warning' });
    } else {
      dispatch({ type: PROMOTION_SUCCESS, payload: response.data });
    }
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
