/* eslint-disable react/no-danger */
/* eslint-disable no-extra-parens */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ImageAsyncComponent from 'components/ImageAsyncComponent';
import { useSelector } from 'react-redux';
import formatPrice from 'helpers/formatPrice';

const ProductListCard = ({
  onClick,
  buttonText,
  title,
  category,
  image,
  greenTag,
  quantity,
  description,
  redTag,
  price,
  unit,
  isAddedToCart,
  buttonExistText,
  discount,
  productLink = '#',
}) => {
  const priceAfterDiscount = (discount ? ((100 - discount) / 100) * price : price).toFixed(2);
  const _loading = useSelector(({ loading }) => loading.loading);

  return (
    <li className="card border shadow-none mb-3 mb-md-5">
      <div className="row no-gutters">
        <div className="col-md-4">
          {quantity < 3 && <span className="badge badge-danger badge-pill">{redTag}</span>}
          <Link className="position-relative" to={productLink}>
            <ImageAsyncComponent image={image} />
          </Link>
        </div>
        <div className="col-md-8">
          <div className="card-body">
            <div className="mb-2  d-flex flex-column text-left">
              <span className="d-block font-size-1">
                <Link className="text-inherit" to={productLink}>
                  <div className="Container" dangerouslySetInnerHTML={{ __html: title }} />
                </Link>
              </span>
              <div className="d-flex flex-column text-left justify-content-start">
                <span className="font-weight-bold" style={{ color: '#444385' }}>
                  {formatPrice(priceAfterDiscount, unit)}
                </span>
                {discount && (
                  <span className="text-body ml-1">
                    <del>{formatPrice(price, unit)}</del>
                  </span>
                )}
              </div>
            </div>
            <p
              style={{ color: '#aaaaaacf', fontSize: '0.3 rem' }}
              className="text-gray"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            {isAddedToCart ? (
              <div className="border-0 pt-0 pb-4 px-4 text-center">
                <button type="button" className="btn btn-sm btn-outline-secondary btn-pill disabled">
                  Dans le panier
                </button>
              </div>
            ) : (
              buttonText && (
                <div className="border-0 pt-0 pb-4 px-4 text-center">
                  <button
                    onClick={onClick}
                    type="button"
                    disabled={_loading}
                    className="btn btn-sm btn-outline-primary transition-3d-hover  btn-pill"
                  >
                    Ajouter au panier
                  </button>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

ProductListCard.propTypes = {
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  isAddedToCart: PropTypes.bool.isRequired,
  buttonExistText: PropTypes.string,
  unit: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  quantity: PropTypes.string.isRequired,
  productLink: PropTypes.string.isRequired,
  category: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  greenTag: PropTypes.string,
  redTag: PropTypes.string,
  price: PropTypes.number.isRequired,
  discount: PropTypes.number,
};

export default ProductListCard;
