const initialState = {
  home: '',
  isPredloaded: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'ALOHA':
      return { ...state, ...payload };

    default:
      return state;
  }
};
