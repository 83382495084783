import React from 'react';
import FilterAgeRange from 'components/FilterAgeRange';

const FilterAge = () => {
  return (
    <div>
      <h4>Age</h4>
      <div className="row">
        <FilterAgeRange />
      </div>
    </div>
  );
};

export default FilterAge;
