import PropTypes from 'prop-types';
import React from 'react';
import InputField from 'components/InputField';
import DatePickerField from 'components/DatePickerField';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Validators from 'helpers/formValidator';

const FieldArrayKids = ({ fields }) => {
  return (
    <ul className="d-flex flex-column align-items-center pr-5">
      {fields.map((enfant, index) => {
        return (
          <li className="w-100" key={`signUpFieldArray-${index + 1}`}>
            <div className="d-flex">
              <h4>
                {`Enfant ${index + 1}`}
                <button
                  type="button"
                  className="btn btn-bg-transparent text-white"
                  onClick={() => fields.remove(index)}
                >
                  <DeleteForeverIcon color="secondary" />
                </button>
              </h4>
            </div>
            <div className="d-flex justify-content-between">
              <InputField name={`${enfant}.firstName`} type="text" label="prénom *" validate={[Validators.required]} />
              <InputField name={`${enfant}.lastName`} type="text" label="nom *" validate={[Validators.required]} />
            </div>
            <DatePickerField name={`${enfant}.birthday`} label="date de naissance *" validate={[Validators.required]} />
          </li>
        );
      })}
      {fields.length < 7 && (
        <div className="btn btn-soft-indigo " onClick={() => fields.push({})}>
          Ajouter un Enfant
        </div>
      )}
    </ul>
  );
};

FieldArrayKids.propTypes = {
  fields: PropTypes.object,
};
export default FieldArrayKids;
