/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, change, FormSection } from 'redux-form';
import InputField from 'components/InputField';
import Validators from 'helpers/formValidator';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SelectField from 'components/SelectField';
import { Tunisia } from 'constants/Tunisia';
import { optionsGov } from 'constants/signUp';
import { trimSpacesFromObject } from 'helpers/trimSpacesFromObject';

const CheckoutFormContainer = ({ handleSubmit, initialValues }) => {
  trimSpacesFromObject(initialValues?.addressF);
  const dispatch = useDispatch();
  const _loading = useSelector(({ loading }) => loading.loading);
  const [optionsTown, setOptionsTown] = useState([]);
  const [searchedTown, setSearchedTown] = useState([]);
  const [optionsCity, setOptionsCity] = useState([]);
  const [searchedCity, setSearchedCity] = useState(initialValues?.addressF?.city);
  const [zipValue, setZipValue] = useState('0000');
  const [isGovSet, setIsGovSet] = useState(false);

  useEffect(() => {
    onChangeEtat(parseInt(initialValues?.addressF?.gouv, 10));
    setIsGovSet(true);
  }, [initialValues]);

  useEffect(() => {
    if (optionsTown?.length && initialValues?.addressF?.ville) {
      onChangeTown(parseInt(initialValues?.addressF?.ville, 10));
    }
  }, [isGovSet]);

  const onChangeEtat = value => {
    const tmpTowns = Tunisia.find(element => element.govId === value);

    setSearchedTown(tmpTowns);
    setOptionsTown(
      tmpTowns?.villes?.map(town => {
        return { value: town.ville, label: town.villeName };
      }),
    );
  };
  const onChangeTown = value => {
    const citys = searchedTown.villes.find(element => element.ville === value);

    setSearchedCity(citys);
    setOptionsCity(
      citys?.cites?.map(city => {
        return { value: city.IDN3, label: city.LIBELLEN3 };
      }),
    );
  };
  const onChangeCity = value => {
    const cityInformation = searchedCity?.cites?.find(element => element.IDN3 === value);

    setZipValue(cityInformation.CODEP);
    dispatch(change('CheckoutFormContainer', 'addressF.zipCode', cityInformation.CODEP));
  };

  const [anotherAddress, setanotherAddress] = useState(false);

  useEffect(() => {
    if (anotherAddress) return;
    dispatch(change('CheckoutFormContainer', 'address', initialValues?.address));
  }, [anotherAddress]);

  return (
    <div className="col-lg-8 order-lg-1 ">
      <form className="mt-5" onSubmit={handleSubmit}>
        <div className="border-bottom">
          <div className="mb-4">
            <h2 className="h3">Information de livraison</h2>
          </div>
          <div className="row">
            <div className="col-md-6 mb-3 mb-sm-6">
              <InputField
                type="firstName"
                name="firstName"
                label="Prénom *"
                placeholder="Prenom"
                validate={[Validators.required, Validators.max20]}
              />
            </div>
            <div className="col-md-6 mb-3 mb-sm-6">
              <InputField
                type="lastName"
                name="lastName"
                label="Nom *"
                placeholder="Nom"
                validate={[Validators.required, Validators.max20]}
              />
            </div>
            <div className="w-100" />
            <div className="col-md-6 mb-3 mb-sm-6">
              <InputField
                type="email"
                name="email"
                label="Email *"
                placeholder="Email"
                validate={[Validators.email, Validators.required]}
              />
            </div>
            <div className="col-md-6 mb-3 mb-sm-6">
              <InputField
                type="phone"
                name="phone"
                label="Téléphone *"
                placeholder="Téléphone"
                validate={[Validators.required, Validators.max8]}
              />
            </div>
            <div className="col-12 mb-1">
              <div className="custom-control custom-checkbox d-flex align-items-center text-muted">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="adressCheckbox"
                  name="adressCheckbox"
                  onChange={() => setanotherAddress(!anotherAddress)}
                />
                <label className="custom-control-label" htmlFor="adressCheckbox">
                  <small>je souhaite être livré dans une autre adresse</small>
                </label>
              </div>
            </div>
            <div className="col-md-8 mb-3 mb-sm-6">
              <InputField
                type="address"
                name="address"
                label="Adresse *"
                placeholder="Adresse"
                validate={[Validators.required, Validators.max80]}
                disabled={!anotherAddress}
              />
            </div>
            <div className="col-md-4 mb-3 mb-sm-6">
              <InputField
                type="moreInformations"
                name="moreInformations"
                label="Plus d'infos"
                placeholder="Aprtmnt 1, 2em étage, Bloc D . . ."
                validate={[Validators.max60]}
              />
            </div>
            <FormSection className="col-12" name="addressF">
              <InputField type="text" name="secondNumber" label="2em numero de téléphone " placeholder="Telephone2" />
              <SelectField
                name="gouv"
                id="gouv"
                required
                label="Choisissez votre gouvernorat :"
                className="form-control"
                options={optionsGov}
                validate={[Validators.required]}
                onChange={e => onChangeEtat(e)}
              />
              <SelectField
                name="ville"
                id="ville"
                label="Choisissez votre ville :"
                required
                className="form-control"
                options={optionsTown}
                validate={[Validators.required]}
                onChange={onChangeTown}
              />
              <SelectField
                name="city"
                id="city"
                label="Choisissez votre cité :"
                required
                className="form-control"
                options={optionsCity}
                validate={[Validators.required]}
                onChange={onChangeCity}
              />
              <InputField
                type="text"
                name="zipCode"
                label="Code postal *"
                placeholder={zipValue}
                disabled
                Values={zipValue}
              />
            </FormSection>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-2 align-items-center">
          <Link className="font-weight-bold" to="/cart-page">
            <i className="fas fa-angle-left fa-xs mr-1" />
            Retour
          </Link>
          <button disabled={_loading} type="submit" className="btn btn-primary btn-pill transition-3d-hover">
            Commander
          </button>
        </div>
      </form>
    </div>
  );
};

CheckoutFormContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default reduxForm({ form: 'CheckoutFormContainer' })(CheckoutFormContainer);
