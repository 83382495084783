import React from 'react';
import { toTitleCase } from 'helpers/toTitleCase';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const BrandCardImage = ({ model, refine, currentRefinement, link }) => {
  return (
    <Link
      to={link}
      onClick={refine}
      className="col-6 card-deck card-sm-gutters-1 d-sm-flex "
      style={{ cursor: 'pointer' }}
      value={model.name}
      key={model.name}
    >
      <div
        className="card-img-top mb-2 d-flex flex-column align-items-center"
        style={{ cursor: 'pointer', width: '100%', height: '100%', position: 'relative' }}
      >
        <div
          className="card-img-top"
          style={{
            backgroundImage: `url(https://api.moncefjouets.com/api/v1/${model.image})`,
            cursor: 'pointer',
            width: '6em',
            height: '6em',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'relative',
            backgroundSize: 'contain',
            border: currentRefinement && (model.value === currentRefinement[0] ? 'solid' : ''),
          }}
        />
        <div className="card-body">
          <h5 className="card-title text-center align-content-align-content-around">{toTitleCase(model.name)}</h5>
        </div>
      </div>
    </Link>
  );
};

BrandCardImage.propTypes = {
  model: PropTypes.object.isRequired,
  refine: PropTypes.func,
  link: PropTypes.string,
  currentRefinement: PropTypes.array,
};
export default BrandCardImage;
