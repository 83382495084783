/* eslint-disable no-nested-ternary */
import React from 'react';
import CustomizedBreadcrumbs from 'components/CustomizedBreadcrumbs';
import usePrice from 'hooks/usePrice';
import useAge from 'hooks/useAge';
import useBrandHeroes from 'hooks/useBrandHeroes';
import useCategories from 'hooks/useCategories';
import { useHistory } from 'react-router-dom';
import { useCreateUrl } from 'hooks/useCreateUrl';

const safeParse = el => {
  try {
    return JSON.parse(el);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(el);

    return { name: el, label: el };
  }
};
const Chips = () => {
  const { price, refinePrice } = usePrice();
  const { currentRefinmentAges, refineAge } = useAge();
  const createUrl = useCreateUrl();
  const history = useHistory();
  const { currentRefinementBrand, currentRefinementHero, refineBrand, refineHero } = useBrandHeroes();
  const { currentRefineCat0, currentRefineCat1, currentRefineCat2, refineCategory } = useCategories();

  return (
    <div className="d-flex flex-wrap">
      {price && <CustomizedBreadcrumbs textInfo={`Prix (${price.from}-${price.to})`} onClick={() => refinePrice()} />}
      {currentRefinmentAges &&
        currentRefinmentAges.map(age => (
          <CustomizedBreadcrumbs
            textInfo={`Age (${age.ages})`}
            onClick={() => {
              history.push(createUrl({ ages: 'zero' }));

              refineAge(currentRefinmentAges?.filter(o => o?.ages !== age.ages));
            }}
          />
        ))}
      {currentRefinementBrand && (
        <CustomizedBreadcrumbs
          textInfo={`Marque : ${safeParse(currentRefinementBrand).name}`}
          onClick={() => {
            history.push(createUrl({ brand: 'zero' }));
            refineBrand();
          }}
        />
      )}
      {currentRefinementHero && (
        <CustomizedBreadcrumbs
          textInfo={`Héro : ${safeParse(currentRefinementHero).name}`}
          onClick={() => {
            history.push(createUrl({ hero: 'zero' }));

            refineHero();
          }}
        />
      )}
      {currentRefineCat0 && (
        <CustomizedBreadcrumbs
          textInfo={`Catégorie : ${safeParse(currentRefineCat0).label}`}
          onClick={() => {
            history.push(createUrl({ category0: 'zero' }));
            refineCategory(undefined, 0);
          }}
        />
      )}
      {currentRefineCat1 && (
        <CustomizedBreadcrumbs
          textInfo={`Catégorie : ${safeParse(currentRefineCat1).label}`}
          onClick={() => {
            history.push(createUrl({ category1: 'zero' }));
            refineCategory(undefined, 1);
          }}
        />
      )}
      {currentRefineCat2 && (
        <CustomizedBreadcrumbs
          textInfo={`Catégorie : ${safeParse(currentRefineCat2).label}`}
          onClick={() => {
            history.push(createUrl({ category2: 'zero' }));
            refineCategory(undefined, 2);
          }}
        />
      )}
    </div>
  );
};

export default Chips;
