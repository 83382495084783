import * as LoginServices from 'services/auth/login';
import * as SignUpServices from 'services/auth/signUp';
import { LOGIN_SUCCESS, SET_USER, UPDATE_TOKEN, SIGN_UP_SUCCESS, LOGOUT, RESET_USER } from 'constants/types/auth';
import { startLoading, stopLoading } from 'actions/loading';
import { errorHandler } from 'helpers/errorHandler';
import { resetAuthToken } from 'api';
import { toast } from 'react-toastify';

export const login = ({ email, password }, history) => async dispatch => {
  dispatch(startLoading());

  try {
    const signInData = await LoginServices.login()({ email, password });

    const { token, ...userData } = signInData.data;

    resetAuthToken(token.accessToken);

    delete userData.emailActivationToken;
    delete userData.password;

    dispatch({ type: SET_USER, payload: userData });
    dispatch({ type: LOGIN_SUCCESS, payload: { token } });
    history.push('/');
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const loginFacebook = (values, history) => async dispatch => {
  dispatch(startLoading());
  try {
    const signInData = await LoginServices.loginFacebook()({ email: values.email, name: values.name });

    const { token, ...userData } = signInData.data;

    resetAuthToken(token.accessToken);

    delete userData.emailActivationToken;
    delete userData.password;

    dispatch({ type: SET_USER, payload: userData });
    dispatch({ type: LOGIN_SUCCESS, payload: { token } });
    history.push('/');
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const loginGoogle = (values, history) => async dispatch => {
  dispatch(startLoading());
  try {
    const signInData = await LoginServices.loginGoogle()({
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
    });

    const { token, ...userData } = signInData.data;

    resetAuthToken(token.accessToken);

    delete userData.emailActivationToken;
    delete userData.password;

    dispatch({ type: SET_USER, payload: userData });
    dispatch({ type: LOGIN_SUCCESS, payload: { token } });
    history.push('/');
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const signUp = (user, history) => async dispatch => {
  dispatch(startLoading());
  try {
    const response = await SignUpServices.signUp()(user);

    const { token, ...userData } = response.data;

    resetAuthToken(response.data.token.accessToken);
    dispatch({ type: SIGN_UP_SUCCESS, payload: response.data.token });
    dispatch({ type: SET_USER, payload: userData });

    history.push('/');
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const updateToken = token => ({ type: UPDATE_TOKEN, payload: token });

export const logout = () => async dispatch => {
  resetAuthToken(null);

  dispatch({ type: LOGOUT });
  dispatch({ type: RESET_USER });
};

export const shopSignUp = (user, history) => async dispatch => {
  dispatch(startLoading());
  try {
    const response = await SignUpServices.signUp()(user);

    const { token, ...userData } = response.data;

    resetAuthToken(token.accessToken);

    dispatch({ type: SIGN_UP_SUCCESS, payload: { token } });
    dispatch({ type: SET_USER, payload: userData });

    toast('Inscription effectué !', { className: 'toast-success' });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const loginModal = () => async dispatch => {
  dispatch(startLoading());

  dispatch(stopLoading());
};
