import React from 'react';
import PropTypes from 'prop-types';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';

import { Link } from 'react-router-dom';
import { logout } from 'actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { numberItemInCarts } from 'helpers/numberItemInCart';
import { openLoginModal } from 'actions/loginModal';

const HeaderTopBar = ({ isLoggedIn }) => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };
  const { user } = useSelector(({ userReducer }) => userReducer);

  let _carts = [];

  if (user) {
    _carts = user?.user?.carts;
  }
  const handleOpenLoginModal = () => {
    dispatch(openLoginModal(true));
  };

  return (
    <div className="header-hide-content pt-2">
      <div className="d-flex ">
        <div className="ml-auto">
          <div className="hs-unfold d-sm-none mr-2">
            <div id="jumpToDropdown" className="hs-unfold-content dropdown-menu">
              <Link to="/contacts" className="dropdown-item">
                Contacts
              </Link>
            </div>
          </div>
          <div className="nav nav-sm nav-y-0 d-none d-sm-flex ml-sm-auto">
            <Link to="/contacts" className="nav-link">
              Contacts
              <ContactSupportIcon />
            </Link>
          </div>
        </div>
        <ul className="list-inline ml-2 mb-0">
          {isLoggedIn ? (
            <>
              <li className="list-inline-item">
                <div className="hs-unfold">
                  <Link
                    title="panier"
                    to="/cart-page"
                    className="js-hs-unfold-invoker btn  btn-icon btn-ghost-secondary"
                  >
                    <i className="fas fa-shopping-cart" />
                    {numberItemInCarts(_carts) > 0 && (
                      <span className="btn-status btn-primary top-0 right-0">
                        <span className="btn-status-initials">{numberItemInCarts(_carts)}</span>
                      </span>
                    )}
                  </Link>
                </div>
              </li>
              <li className="list-inline-item">
                <Link title="profile" to="/profile" className="btn btn-icon h-0 btn-ghost-secondary">
                  <i className="fas fa-user-circle" />
                </Link>
                <div title="déconnection" onClick={handleLogout} className="btn btn-icon h-0 btn-ghost-secondary">
                  <i className="fa fa-power-off fa-hand-pointer" />
                </div>
              </li>
            </>
          ) : (
            <li className="list-inline-item nav nav-sm" style={{ cursor: 'pointer' }}>
              <div onClick={handleOpenLoginModal} className="nav-link">
                Se connecter
              </div>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

HeaderTopBar.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

export default HeaderTopBar;
