/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import SEO from 'components/SEO';
import { URL_LOGO } from 'constants/logoUrl';
import FacebookTypes from 'constants/facebookTypes';
import Skeleton from '@material-ui/lab/Skeleton';
import SafeAreaView from 'components/SafeAreaView';

const AgeListPage = () => {
  return (
    <SafeAreaView>
      <SEO
        title="Liste des ages"
        description="En cliquant vous accédez à la liste des produits d'une tranche d'age spécifique"
        type={FacebookTypes.article}
        image={URL_LOGO}
      />
      <div className="container space-top-3 mt-9 pt-9 space-top-md-2 space-bottom-2 space-bottom-lg-3">
        <div className="row justify-content-center">
          <div className="col-lg-2 col-sm-6 col-6 justify-content-center">
            <Skeleton variant="rect" width="10vw" height="10vh" />
          </div>
          <div className="col-lg-2 col-sm-6 col-6 justify-content-center">
            <Skeleton variant="rect" width="10vw" height="10vh" />
          </div>
          <div className="col-lg-2 col-sm-6 col-6 justify-content-center">
            <Skeleton variant="rect" width="10vw" height="10vh" />
          </div>
          <div className="col-lg-2 col-sm-6 col-6 justify-content-center">
            <Skeleton variant="rect" width="10vw" height="10vh" />
          </div>
          <div className="col-lg-2 col-sm-6 col-6 justify-content-center">
            <Skeleton variant="rect" width="10vw" height="10vh" />
          </div>
        </div>
      </div>
    </SafeAreaView>
  );
};

export default AgeListPage;
