import axios from 'axios';

export const CLIENT_API = 'https://api.moncefjouets.com/api/v1';
export const SERVER_API = 'http://localhost:3348/api/v1';
export const ES_APP_SEARCH = 'https://es.moncefjouets.com/api/as/v1/engines/moncef-jouets-data-set/';

export const FPARCEL_API = 'https://www.fparcel.net/';

export const clientApi = axios.create({
  baseURL: CLIENT_API,
});
export const fParcelApi = axios.create({
  baseURL: FPARCEL_API,
});

export const serverApi = axios.create({
  baseURL: SERVER_API,
  timeout: 3000,
});

export const esApi = axios.create({
  baseURL: `${ES_APP_SEARCH}`,
  timeout: 300000,
  headers: { Authorization: 'Bearer search-c2yr28d6a6zf7cka5hjiffyc' },
});
export const id = 3;

export const resetAuthToken = async token => {
  if (token) {
    clientApi.defaults.headers.Authorization = `bearer ${token.trim()}`;
    serverApi.defaults.headers.Authorization = `bearer ${token.trim()}`;
  } else {
    clientApi.defaults.headers.Authorization = null;
    serverApi.defaults.headers.Authorization = null;
  }
};

