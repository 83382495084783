/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import 'moment/locale/fr';

import configureStore, { createReduxPersistor } from 'store/configureStore';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import React from 'react';
import Root from 'containers/AppRouter';
import { hydrate } from 'react-dom';
import Loadable from 'react-loadable';
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
import ReactGA from 'react-ga';

const trackingId = 'UA-116311780-3';

ReactGA.initialize(trackingId);
ReactGA.plugin.require('ecommerce');
ReactGA.plugin.require('ec');
// Sentry.init({
//   dsn: 'https://6d2babf2fa2b4ed581ddb14c2a132342@o465518.ingest.sentry.io/5478269',
//   integrations: [new Integrations.BrowserTracing()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });

// import combinedReducers from 'reducers';
const preloadedState = window.__PRELOADED_STATE__;
const initialState = window.__APP_INITIAL_STATE__;

delete window.__PRELOADED_STATE__;
const store = configureStore(preloadedState);

const reduxPersistor = createReduxPersistor(store);

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
}
Loadable.preloadReady().then(() => {
  hydrate(
    <PersistGate persistor={reduxPersistor}>
      <Provider store={store}>
        <BrowserRouter>
          <Root {...initialState} />
        </BrowserRouter>
      </Provider>
    </PersistGate>,
    document.getElementById('root'),
  );
});

if (module.hot) {
  module.hot.accept();
}
