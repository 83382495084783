import { SIGN_UP_SUCCESS } from 'constants/types/auth';

const initialState = {
  loaded: false,
  error: false,
  isPredloaded: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        loaded: true,
        error: true,
        ...payload,
      };

    default:
      return state;
  }
};
