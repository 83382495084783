import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import formatPrice from 'helpers/formatPrice';
import { Link } from 'react-router-dom';
import { numberItemInCarts } from 'helpers/numberItemInCart';
import { totalOfCarts } from 'helpers/totalOfCarts';
import { SET_PROMOTION } from 'constants/types/checkout';
import { useDispatch, useSelector } from 'react-redux';
import { promotionAction } from 'actions/checkout';
import _ from 'lodash';
import { totalOfCartsAfterPromo } from 'helpers/totalOfCardsAfterPromo';

const CheckoutContainer = ({ userCart }) => {
  const disptach = useDispatch();

  const { promotion } = useSelector(({ checkout }) => checkout);

  const discountDisplay = '%';

  // if (promotion.promotion.discountType.trim() === '%') {
  //   discountDisplay = `${promotion.promotion.discountPerc} ${promotion.promotion.discountType}`;
  // } else {
  //   discountDisplay = `${promotion.promotion.discountAmount} ${promotion.promotion.discountType.toUpperCase()}`;
  // }

  const promoCode = useRef(null);

  return (
    <div className="col-lg-4">
      <div className="pl-lg-4">
        <div className="card shadow-soft p-4 mb-4">
          <div className="border-bottom pb-4 mb-4">
            <h2 className="h3 mb-0">Votre commande</h2>
          </div>
          <div className="border-bottom pb-4 mb-4">
            <div className="media align-items-center mb-3">
              <span className="d-block font-size-1 mr-3">
                {`Total avant réduction (${numberItemInCarts(userCart)})`}
              </span>
              <div className="media-body text-right">
                <span className="text-dark font-weight-bold">{formatPrice(totalOfCarts(userCart), 'TND')}</span>
              </div>
            </div>
            {/* <div className="media align-items-center mb-3">
              <span className="d-block font-size-1 mr-3">Livraison</span>
              <div className="media-body text-right">
                <span className="text-dark font-weight-bold">Gratuite</span>
              </div>
            </div> */}
          </div>
          <div className="media d-flex justify-content-between mb-3">
            <div className="d-flex flex-column">
              <span className="d-block font-size-1 mr-3">Total à payer</span>
              <span className="d-block font-size-1 ">{`${promotion.promotion.codeTitle} ( -${promotion.promotion.discountPerc}${discountDisplay} )`}</span>
            </div>
            <div className="d-flex flex-column">
              <span className="text-body ml-1">
                <del>{formatPrice(totalOfCarts(userCart), 'TND')}</del>
              </span>
              <span className="text-body ml-1">
                -
                {
                  // promotion.promotion.discountType.trim() === '%'?
                  formatPrice(
                    totalOfCarts(userCart) - totalOfCartsAfterPromo(userCart, promotion.promotion.discountPerc),
                    'TND',
                  )
                  // : formatPrice(promotion.promotion.discountAmount, 'TND')
                }
              </span>
              <span className="text-dark font-weight-bold">
                {formatPrice(totalOfCartsAfterPromo(userCart, promotion.promotion.discountPerc), 'TND')}
              </span>
            </div>
          </div>
          <div className="row mx-1">
            <div className="col px-1 my-1">
              <Link to="/checkout" className="btn btn-primary btn-block btn-pill transition-3d-hover text-white">
                Commander
              </Link>
            </div>
          </div>
        </div>
        <div id="shopCartAccordion" className="accordion card shadow-soft mb-4">
          <div className="card rounded">
            <div className="input-group input-group-pill mb-3">
              <input
                ref={promoCode}
                type="text"
                className={`form-control ${_.isEmpty(promotion) ? `` : `disabled`} `}
                name="name"
                placeholder="COMPTE_PROMO (10%)"
                aria-label="Promo code"
              />
              <div className="input-group-append">
                <button
                  onClick={() => {
                    disptach(promotionAction(promoCode.current?.value));
                    disptach({ type: SET_PROMOTION, payload: promoCode.current?.value });
                  }}
                  type="button"
                  className="btn btn-block btn-primary btn-pill"
                >
                  Appliquer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CheckoutContainer.propTypes = {
  userCart: PropTypes.array,
};
export default CheckoutContainer;
