/* eslint-disable max-lines */
import {
  TOGGLE_IS_GRID,
  FETCH_PRODUCTS,
  FETCH_BEST_SELLERS,
  FETCH_PRODUCT_BY_REF,
  ELASTIC_STATE_CHANGE,
  FILTER_HERO,
  FILTER_PRICE,
  RESET_FILTERS,
  FILTER_BRAND,
  FILTER_AGE,
  FILTER_CATEGORY0,
  FILTER_CATEGORY1,
  FILTER_CATEGORY2,
  FILTER_PAGE,
  FILTER_ASC,
  FILTER_DSC,
  FILTER_QUERY,
  ADAPT_QUERY_TO_FILTER,
  intialSearchState,
} from 'constants/types/products';
import { createFilters } from 'helpers/createFilters';

const initialState = {
  isGrid: true,
  articleByRef: {},
  searchedArticles: [],
  bestSellers: [],
  searchResult: {},
  searchQuery: '',
  elasticSearchState: intialSearchState,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_IS_GRID:
      return { ...state, isGrid: !state.isGrid };
    case FETCH_PRODUCTS:
      return { ...state, articles: payload.results, facets: payload.facets, meta: payload.meta };
    case FETCH_PRODUCT_BY_REF:
      return { ...state, articleByRef: payload };
    case FETCH_BEST_SELLERS:
      return { ...state, bestSellers: [...payload] };
    case FILTER_HERO:
      return {
        ...state,
        hero: payload,
        elasticSearchState: {
          ...state.elasticSearchState,
          ...createFilters({
            ...state,
            hero: payload,
          }),
        },
      };
    case FILTER_BRAND:
      return {
        ...state,
        brand: payload,
        elasticSearchState: {
          ...state.elasticSearchState,
          ...createFilters({
            ...state,
            brand: payload,
          }),
        },
      };
    case FILTER_PRICE:
      return {
        ...state,
        price: payload,
        elasticSearchState: {
          ...state.elasticSearchState,
          ...createFilters({
            ...state,
            price: payload,
          }),
        },
      };
    case FILTER_AGE:
      return {
        ...state,
        ages: payload,
        elasticSearchState: {
          ...state.elasticSearchState,
          ...createFilters({
            ...state,
            ages: payload,
          }),
        },
      };
    case FILTER_CATEGORY0:
      return {
        ...state,
        category0: state.category0 === payload ? undefined : payload,
        category1: undefined,
        category2: undefined,
        elasticSearchState: {
          ...state.elasticSearchState,
          ...createFilters({
            ...state,
            category0: state.category0 === payload ? undefined : payload,
            category1: undefined,
            category2: undefined,
          }),
        },
      };
    case FILTER_CATEGORY1:
      return {
        ...state,
        category1: state.category1 === payload ? undefined : payload,
        category2: undefined,
        elasticSearchState: {
          ...state.elasticSearchState,
          ...createFilters({
            ...state,
            category1: state.category1 === payload ? undefined : payload,
            category2: undefined,
          }),
        },
      };
    case FILTER_CATEGORY2:
      return {
        ...state,
        category2: state.category2 === payload ? undefined : payload,
        elasticSearchState: {
          ...state.elasticSearchState,
          ...createFilters({
            ...state,
            category2: state.category2 === payload ? undefined : payload,
          }),
        },
      };
    case FILTER_QUERY:
      return {
        ...state,
        query: state.query === payload ? '' : payload,
        elasticSearchState: {
          ...state.elasticSearchState,
          ...createFilters({
            ...state,
            query: state.query === payload ? '' : payload,
          }),
        },
      };
    case FILTER_PAGE:
      return {
        ...state,
        page: state.page === payload ? '' : payload,
        elasticSearchState: {
          ...state.elasticSearchState,
          ...createFilters({
            ...state,
            page: state.page === payload ? '' : payload,
          }),
        },
      };
    case FILTER_ASC:
      return {
        ...state,
        elasticSearchState: { ...state.elasticSearchState, sort: [{ _score: 'desc' }, { price: 'asc' }] },
      };
    case FILTER_DSC:
      return {
        ...state,
        elasticSearchState: { ...state.elasticSearchState, sort: [{ _score: 'desc' }, { price: 'desc' }] },
      };
    case ADAPT_QUERY_TO_FILTER:
      return {
        ...state,
        searchQuery: payload?.searchQuery,
        ages: payload?.ages,
        price: payload?.price,
        hero: payload?.hero,
        brand: payload?.brand,
        category0: payload?.category0,
        category1: payload?.category1,
        category2: payload?.category2,
        sort: payload?.sort,
        elasticSearchState: {
          ...intialSearchState,
          ...createFilters({ ...state, ...payload }),
        },
      };
    case RESET_FILTERS:
      return {
        ...state,
        query: undefined,
        ages: undefined,
        price: undefined,
        hero: undefined,
        brand: undefined,
        category0: undefined,
        category1: undefined,
        category2: undefined,
        sort: undefined,
        elasticSearchState: {
          ...state.elasticSearchState,
          ...createFilters({}),
        },
      };

    case ELASTIC_STATE_CHANGE:
      return { ...state, elasticSearchState: payload };
    default:
      return state;
  }
};
