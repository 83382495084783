import React from 'react';
import { useSelector } from 'react-redux';
import ProductGridCard from 'components/ProductGridCard';
import ProductListCard from 'components/ProductListCard';
import PropTypes from 'prop-types';

const ProductCard = ({
  onClick,
  buttonText,
  title,
  brand,
  description,
  image,
  greenTag,
  redTag,
  price,
  quantity,
  buttonExistText,
  unit,
  discount,
  isAddedToCart,
  productLink = '#',
}) => {
  const { isGrid } = useSelector(({ products }) => products);

  if (isGrid) {
    return (
      <div className="col-6 col-md-4 col-lg-3 px-1 mb-1 mb-sm-2">
        <ProductGridCard
          isAddedToCart={isAddedToCart}
          price={price || 0}
          unit={unit}
          discount={discount}
          category={brand}
          description={description}
          productLink={productLink}
          redTag={redTag}
          quantity={quantity}
          buttonText={buttonText}
          buttonExistText={buttonExistText}
          greenTag={greenTag}
          title={title}
          image={image}
          onClick={onClick}
        />
      </div>
    );
  }

  return (
    <ProductListCard
      isAddedToCart={isAddedToCart}
      price={price || 0}
      unit={unit}
      discount={discount}
      category={brand}
      description={description}
      productLink={productLink}
      redTag={redTag}
      quantity={quantity}
      buttonText={buttonText}
      greenTag={greenTag}
      title={title}
      image={image}
      onClick={onClick}
    />
  );
};

ProductCard.propTypes = {
  onClick: PropTypes.func,
  isAddedToCart: PropTypes.bool.isRequired,
  buttonText: PropTypes.string,
  buttonExistText: PropTypes.string,
  unit: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  productLink: PropTypes.string.isRequired,
  brand: PropTypes.string,
  image: PropTypes.string.isRequired,
  greenTag: PropTypes.string,
  description: PropTypes.string,
  redTag: PropTypes.string,
  price: PropTypes.number.isRequired,
  discount: PropTypes.number,
  quantity: PropTypes.number.isRequired,
};

export default ProductCard;
