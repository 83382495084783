const FILTER_AGE = [
  { min: 0, max: 6 },
  { min: 6, max: 18 },
  { min: 12, max: 36 },
  { min: 36, max: 72 },
  { min: 72, max: 144 },
  { min: 144, max: 1188 },
];

export default FILTER_AGE;
