/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';

const ProductCartQuantity = ({ quantity, maxQuantity, onChange }) => {
  return (
    <>
      <div className="border rounded py-2 px-3 mt-2">
        <div className="js-quantity-counter row align-items-center">
          <div className="col-7"> {quantity} </div>
          <div className="col-5 text-right">
            {quantity < maxQuantity ? (
              <div onClick={() => onChange(quantity + 1)} style={{ cursor: 'pointer' }}>
                <i className="fas fa-plus " />
              </div>
            ) : (
              <div style={{ color: '#f2eee9' }}>
                <i className="fas fa-plus " />
              </div>
            )}
            {quantity > 1 ? (
              <div style={{ cursor: 'pointer' }} onClick={() => onChange(quantity - 1)}>
                <i className="fas fa-minus pointer" />
              </div>
            ) : (
              <div style={{ color: '#f2eee9' }}>
                <i className="disabled fas fa-minus" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

ProductCartQuantity.propTypes = {
  quantity: PropTypes.number.isRequired,
  maxQuantity: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default ProductCartQuantity;
