/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import SafeAreaView from 'components/SafeAreaView';
import { useLocation } from 'react-router-dom';
import formatPaths from 'helpers/formatPaths';
import { useSelector, useDispatch } from 'react-redux';
import { trimSpacesFromObject } from 'helpers/trimSpacesFromObject';
import { updateUser, updateUserChildren, updatePassword } from 'actions/user';
import SEO from 'components/SEO';
import AsyncUserProfilePage from './AsyncUserProfilePage';

const UserProfilePage = () => {
  const { pathname } = useLocation();
  const paths = formatPaths(pathname);
  const disptach = useDispatch();

  const handleSubmitUser = values => {
    disptach(updateUser(values));
  };
  const handleSubmitChildren = values => {
    disptach(updateUserChildren(values));
  };
  const handlePasswordUpdate = values => {
    const { oldPassword, password } = values;

    disptach(updatePassword({ password: oldPassword, newPassword: password }));
  };

  const { user } = useSelector(({ userReducer }) => userReducer);

  const _user = trimSpacesFromObject(user);

  return (
    <SafeAreaView>
      <SEO title="Profile Page" description={`Profil moncefjouets de ${{ ..._user?.user?.firstName }}`} />
      <AsyncUserProfilePage
        paths={paths}
        handleSubmitUser={handleSubmitUser}
        handleSubmitChildren={handleSubmitChildren}
        handlePasswordUpdate={handlePasswordUpdate}
        _user={_user}
      />
    </SafeAreaView>
  );
};

export default UserProfilePage;
