import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import { Link } from 'react-router-dom';

const UserProfileLoader = () => {
  return (
    <div className="container space-top-1 space-top-md-2 space-bottom-2 space-bottom-lg-3">
      Détail du produit
      <div className="container space-top-1 space-top-sm-2 space-bottom-4">
        <div className="row">
          <div className="col-lg-6  mb-lg-0" style={{ marginBottom: '50%' }}>
            <Skeleton variant="rect" width="70%" height="50%" style={{ marginBottom: '50%' }} />
          </div>
          <div className="col-lg-5">
            <div>
              <Skeleton animation="wave" />
            </div>
            <hr />
            <Skeleton animation="wave" />
          </div>
        </div>
      </div>
      <div className="d-sm-flex justify-content-start m-10">
        <Link className="font-weight-bold" to="/products">
          <i className="fas fa-angle-left fa-xs mr-1" />
          Continuez votre shopping
        </Link>
      </div>
    </div>
  );
};

export default UserProfileLoader;
