import InputField from 'components/InputField';
import PropTypes from 'prop-types';
import React from 'react';
import Validators from 'helpers/formValidator';
import { reduxForm } from 'redux-form';

const SecondLoginForm = ({ handleSubmit, isNameOnly }) => {
  return (
    <form className="mt-5" onSubmit={handleSubmit}>
      <div className="mb-7">
        <p>Veuillez remplir vos information en lettres latines</p>
      </div>
      <InputField
        type="email"
        name="email"
        label="Adresse Email"
        placeholder="Adresse Email"
        validate={[Validators.email, Validators.required]}
      />
      {isNameOnly ? (
        <InputField
          type="text"
          name="name"
          label="Nom"
          placeholder="Nom"
          validate={[Validators.alpha_space, Validators.required]}
        />
      ) : (
        <>
          <InputField
            type="text"
            name="firstName"
            label="Nom"
            placeholder="Nom"
            validate={[Validators.alpha_space, Validators.required]}
          />
          <InputField
            type="text"
            name="lastName"
            label="Prenom"
            placeholder="Prenom"
            validate={[Validators.alpha_space, Validators.required]}
          />
        </>
      )}

      <div className="row align-items-center mb-5">
        <div className="col-4 text-right">
          <button className="btn btn-primary mb-1" type="submit">
            Commencer
          </button>
        </div>
      </div>
    </form>
  );
};

SecondLoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isNameOnly: PropTypes.bool,
};

export default reduxForm({ form: 'secondLoginForm' })(SecondLoginForm);
