import { FETCH_HEROES } from 'constants/types/heroes';

const initialState = {
  heroes: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_HEROES:
      return { ...state, heroes: payload };
    default:
      return state;
  }
};
