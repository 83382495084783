/* eslint-disable no-nested-ternary */
/* eslint-disable no-extra-parens */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import formatPrice from 'helpers/formatPrice';
import PropTypes from 'prop-types';
import { numberItemInCarts } from 'helpers/numberItemInCart';
import { totalOfCarts } from 'helpers/totalOfCarts';
import { useSelector } from 'react-redux';
import { totalOfCartsAfterPromo } from 'helpers/totalOfCardsAfterPromo';

const CheckoutPricing = ({ carts }) => {
  const { isDelivery, isPaymentOnline } = useSelector(({ checkout }) => checkout);
  const { promotion } = useSelector(({ checkout }) => checkout);

  return (
    <>
      <div className="border-bottom pb-4 mb-4">
        <div className="media mt- mb-3">
          <div className="d-flex flex-column w-100">
            <span className="d-block mr-3">{`Résumé des produits (${numberItemInCarts(carts)})`}</span>
            <br />
            <div className="media-body text-right d-flex flex-column justify-content-end">
              {carts?.map(product => {
                return (
                  <span className="text-dark d-flex justify-content-between">
                    {product?.product?.hasNoDiscount ? (
                      <>
                        <div className="text-left" style={{ fontSize: '0.7rem' }}>
                          {product?.product.title}
                        </div>
                        <div>
                          {formatPrice(product?.product?.price, 'TND')} x {product?.quantity}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="text-left" style={{ fontSize: '0.7rem' }}>
                          {product?.product?.title.trim()} :
                        </div>
                        <del>
                          {formatPrice(product?.product?.price, 'TND')} x {product?.quantity}{' '}
                        </del>
                        <span className="ml-2">
                          {formatPrice(
                            product?.product?.price -
                              product?.product?.price * (promotion.promotion.discountPerc / 100),
                            'TND',
                          )}{' '}
                          x {product?.quantity}
                        </span>
                      </>
                    )}
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column ">
        <div className="d-flex justify-content-between align-items-center">
          <span className="d-block  mr-3">Total avant réduction :</span>
          <span className="text-body ml-1">
            <del>{formatPrice(totalOfCarts(carts), 'TND')}</del>
          </span>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <span className="d-block  mr-3">Total de la commande:</span>
          <span className="text-body ml-1">
            {formatPrice(totalOfCartsAfterPromo(carts, promotion.promotion.discountPerc), 'TND')}
          </span>
        </div>
        <br />
        {isDelivery && (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <span className="d-block  mr-3">frais Livraison</span>
              <span className="text-body ml-1">+ 7.000 TND</span>
            </div>
          </>
        )}
        {(isDelivery || isPaymentOnline) && (
          <div className="d-flex justify-content-between align-items-center">
            <span className="d-block  mr-3">frais timbre fiscal</span>
            <span className="text-body ml-1">+ 1.000 TND</span>
          </div>
        )}
        <div className="d-flex justify-content-between align-items-center">
          <span className="d-block mt-1 mr-3 font-weight-bold">Total à payer :</span>
          <span className="text-dark font-weight-bold">
            {isDelivery
              ? formatPrice(totalOfCartsAfterPromo(carts, promotion.promotion.discountPerc) + 8, 'TND')
              : isPaymentOnline
              ? formatPrice(totalOfCartsAfterPromo(carts, promotion.promotion.discountPerc) + 1, 'TND')
              : formatPrice(totalOfCartsAfterPromo(carts, promotion.promotion.discountPerc), 'TND')}
          </span>
        </div>
      </div>
    </>
  );
};

CheckoutPricing.propTypes = {
  carts: PropTypes.object.isRequired,
};
export default CheckoutPricing;
