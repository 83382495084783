import { clientApi, serverApi } from 'api';
import { trimSpacesFromObject } from 'helpers/trimSpacesFromObject';

export const addItemToCartService = (isServer = false) => async (eanId = 0, ref, quantity = 1) => {
  const api = isServer ? serverApi : clientApi;
  const response = await api.post(`/carts`, {
    isBought: '0',
    productEanId: eanId,
    productRef: ref,
    quantity,
  });

  return response.data;
};

export const updateUserProfile = (isServer = false) => async userData => {
  const api = isServer ? serverApi : clientApi;

  const response = await api.put('/users', userData);

  return response.data;
};

export const updateUserChildrenProfile = (isServer = false) => async userData => {
  const api = isServer ? serverApi : clientApi;

  const response = await api.put('/users/update-children', userData);

  return response.data;
};

export const updatePassword = (isServer = false) => async userData => {
  const api = isServer ? serverApi : clientApi;

  const response = await api.put('/users/update-password', userData);

  return response.data;
};
export const fetchEntsUserService = (isServer = false) => async tiers => {
  const api = isServer ? serverApi : clientApi;

  const response = await api.get(`/users/get-ent-by-user/${tiers}`);

  return trimSpacesFromObject(response.data);
};
export const fetchFacturesUserService = (isServer = false) => async tiers => {
  const api = isServer ? serverApi : clientApi;

  // TODO: change to ${tiers} or CST01017 to see bill
  const response = await api.get(`/ent-divalto/${tiers}`);

  return trimSpacesFromObject(response.data);
};

export const deleteCartItem = (isServer = false) => async id => {
  const api = isServer ? serverApi : clientApi;

  const response = await api.delete(`/carts/${id}`);

  return response.data;
};
