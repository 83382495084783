import {
  LOGIN_FAILED,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGOUT,
  SIGN_UP_FAILED,
  SIGN_UP_START,
  SIGN_UP_SUCCESS,
  UPDATE_TOKEN,
} from 'constants/types/auth';

export const authInitialState = {
  loading: false,
  loaded: false,
  error: false,
  isLogged: false,
  token: false,
  isPredloaded: false,
};

export default (state = authInitialState, { type, payload }) => {
  switch (type) {
    case LOGIN_START:
      return { ...state, ...payload, loaded: false, loading: true, error: false, isLogged: false };
    case LOGIN_SUCCESS:
      return { ...state, ...payload, isLogged: true, loaded: true, loading: false, error: false };
    case LOGIN_FAILED:
      return { ...state, ...payload, isLogged: false, loaded: false, loading: false };
    case SIGN_UP_START:
      return { ...state, loaded: false, loading: true, error: true };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        isLogged: true,
        error: true,
        token: payload,
      };
    case SIGN_UP_FAILED:
      return { ...state, loaded: false, loading: false, error: false };
    case LOGOUT:
      return { ...state, loaded: false, loading: false, error: false, user: false, token: false, isLogged: false };
    case UPDATE_TOKEN:
      return { ...state, token: { ...state.token, accessToken: payload } };
    default:
      return state;
  }
};
