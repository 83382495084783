export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const SIGN_UP_START = 'SIGN_UP_START';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILED = 'SIGN_UP_FAILED';

export const LOGOUT = 'LOGOUT';

export const UPDATE_TOKEN = 'UPDATE_TOKEN';

export const SET_USER = 'SET_USER';
export const RESET_USER = 'RESET_USER';
