import InputField from 'components/InputField';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import Validators from 'helpers/formValidator';
import { reduxForm } from 'redux-form';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';

const LoginForm = ({ handleSubmit, responseFacebook, responseGoogle }) => {
  return (
    <form className="mt-5" onSubmit={handleSubmit}>
      <div className="mb-7">
        <h1 className="h3 text-primary font-weight-normal mb-0">
          Bienvenue sur
          <span className="font-weight-semi-bold"> Moncef Jouets</span>
        </h1>
        <p>Remplissez le formulaire pour commencer</p>
      </div>

      <FacebookLogin
        disableMobileRedirect
        cssClass="facebook fbconnect border-0 w-100"
        appId="1259613714399379"
        fields="name,email"
        textButton="Se connecter avec Facebook"
        callback={responseFacebook}
      />
      <br />
      <br />
      <GoogleLogin
        className="rounded googleButton text-center w-100 d-flex justify-content-center align-items-center"
        clientId="949423313931-8sn24h1neja9bncsajnch4v22c96qh1f.apps.googleusercontent.com"
        buttonText="Se connecter avec Google"
        onSuccess={responseGoogle}
      />
      <br />
      <br />
      <InputField
        type="email"
        name="email"
        label="Adresse Email"
        placeholder="Adresse Email"
        validate={[Validators.email, Validators.required]}
      />

      <InputField
        type="password"
        name="password"
        label="Mot de passe"
        placeholder="********"
        forgotPasswordLink="/"
        validate={[Validators.required, Validators.min8]}
      />

      <div className="row align-items-center mb-5">
        <div className="col-8">
          <span className="small text-muted">Vous n&apos;avez pas de compte?</span>
          <Link className="small" to="/sign-up">
            {' '}
            Inscrivez vous
          </Link>
        </div>
        <div className="col-4 text-right">
          <button className="btn btn-primary mb-1" type="submit">
            Commencer
          </button>
        </div>
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  responseFacebook: PropTypes.func.isRequired,
  responseGoogle: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'loginForm' })(LoginForm);
