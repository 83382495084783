/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';

import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

const Collapsible = ({ open = false, children, title }) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(open);
  const handleFilterOpening = () => {
    setIsOpen(!isOpen);
  };

  React.useEffect(() => {
    setIsOpen(false);
  }, [location.search]);

  return (
    <>
      <div onClick={handleFilterOpening} style={{ cursor: 'pointer' }} className=" position-relative">
        <h2 className="h5  font-weight-bold ">{title}</h2>
        <p>(tappez ici pour ouvrir la liste des filtres)</p>
        <IconButton className="position-absolute top-0 right-0 mt-n3 pl-3" onClick={handleFilterOpening}>
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </div>
      <Collapse isOpened={isOpen}>{children}</Collapse>

      <hr />
    </>
  );
};

Collapsible.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Collapsible;
