import { FETCH_BRANDS } from 'constants/types/brands';

const initialState = {
  brands: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_BRANDS:
      return { ...state, brands: payload };
    default:
      return state;
  }
};
