/* eslint-disable no-unused-expressions */
import { slugifyString } from 'helpers/slugifyString';

/* eslint-disable no-cond-assign */
const formatPathsCategory = (categories, category) => {
  const formatedPaths = [];

  const preLink = '/products?category=';

  let link = '';

  let cat = '';

  let subCat = '';

  let subSubCat = '';

  categories?.forEach(catElement => {
    if (catElement?.categoryId?.substring(0, 2) === category?.substring(0, 2)) {
      cat = catElement?.title;
      link = slugifyString(preLink + cat);
      formatedPaths?.push({ title: cat, link });
      catElement?.subCategories?.forEach(subCatElement => {
        if (subCatElement?.categoryId?.substring(2, 4) === category?.substring(2, 4)) {
          subCat = subCatElement?.title;
          link = slugifyString(preLink + subCat);
          formatedPaths?.push({ title: subCat, link });
          subCatElement?.subSubCategories?.forEach(subSubCatElement => {
            if (subSubCatElement?.categoryId?.substring(4, 6) === category?.substring(4, 6)) {
              subSubCat = subSubCatElement?.title;
              link = slugifyString(preLink + subSubCat);
              formatedPaths?.push({ title: subSubCat, link });
            }
          });
        }
      });
    }
  });

  return formatedPaths;
};

export default formatPathsCategory;
