export const searchObjectToQuery = o => {
  let query = '?';

  Object.keys(o).forEach(e => {
    const subQuery = o[e] ? `${e}=${o[e]}&` : '';

    query = `${query}${subQuery}`;
  });

  if (query.length === 1) query = '';
  if (query.length > 1) query = query.substring(0, query.length - 1);

  return query;
};
