export const createFilters = ({ hero, brand, price, ages, category0, category1, category2, query, page, sort }) => {
  const filters = {
    all: [],
  };

  if (hero) filters.all.push({ any: [{ hero }] });
  if (brand) filters.all.push({ any: [{ brand }] });
  if (price) filters.all.push({ any: [{ price }] });
  if (ages) filters.all.push({ any: ages });
  if (category0) filters.all.push({ any: [{ category0 }] });
  if (category1) filters.all.push({ any: [{ category1 }] });
  if (category2) filters.all.push({ any: [{ category2 }] });

  return {
    filters,
    sort: sort ? [{ _score: 'desc' }, { price: sort }] : undefined,
    query: query || '',
    page: page
      ? JSON.parse(page)
      : {
          size: 16,
          current: 1,
        },
  };
};
