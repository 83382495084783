import { FETCH_HEROES } from 'constants/types/heroes';
import { startLoading, stopLoading } from 'actions/loading';
import { fetchAllHeroes } from 'services/heroes';
import { errorHandler } from 'helpers/errorHandler';

export const fetchHeroes = query => async (dispatch, getState) => {
  const rootState = getState();
  const { isPreloaded } = rootState.heroesReducer;

  if (isPreloaded) return;

  dispatch(startLoading());

  try {
    const response = await fetchAllHeroes()(query);

    dispatch({ type: FETCH_HEROES, payload: response });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
