import { FETCH_BEST_SELLERS } from 'constants/types/products';

const initialState = {
  bestSellers: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_BEST_SELLERS:
      return { ...state, bestSellers: [...payload] };

    default:
      return state;
  }
};
