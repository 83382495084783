import React from 'react';
import HierarchicalMenu from 'components/HierarchicalMenu';
import useCategories from 'hooks/useCategories';

const FilterCategory = () => {
  const { categories, refineCategory, currentRefineCat0, currentRefineCat1 } = useCategories();

  return (
    <div>
      <h4>Les catégories</h4>

      <div className="list-group">
        {categories && (
          <HierarchicalMenu
            items={categories}
            refineCategory={refineCategory}
            currentRefineCat0={currentRefineCat0}
            currentRefineCat1={currentRefineCat1}
          />
        )}
      </div>
    </div>
  );
};

export default FilterCategory;
