/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import ProductsContainer from 'containers/ProductsContainer';
import FiltersContainer from 'containers/FilterContainer';
import SafeAreaView from 'components/SafeAreaView';
import PropTypes from 'prop-types';
import 'instantsearch.css/themes/algolia.css';
import BreadCrumb from 'components/Breadcrumb';
import Collapse from 'components/Collapsible/Filtre';
import useMobileDetect from 'use-mobile-detect-hook';

const ProductsListPage = ({ paths }) => {
  const isMobile = useMobileDetect().isMobile();

  return (
    <>
      {/* <HeaderProduct isLoggedIn /> */}
      <SafeAreaView>
        <div className="bg-light">
          <div className="container py-5">
            <div className="row align-items-sm-center">
              <div className="col-sm-6 mb-3 mb-sm-0">
                <h1 className="h4 mb-0">Liste des produits</h1>
              </div>
              <BreadCrumb paths={paths} />
            </div>
          </div>
        </div>
        <div className="container space-top-1 space-top-md-2 space-bottom-2 space-bottom-lg-3">
          <div className="row">
            <div className="col-lg-3">
              {isMobile ? (
                <Collapse title="Filtres">
                  <FiltersContainer />
                </Collapse>
              ) : (
                <FiltersContainer />
              )}
            </div>
            <div className="col-lg-9 pl-lg-7">
              <ProductsContainer />
            </div>
          </div>
        </div>
      </SafeAreaView>
    </>
  );
};

ProductsListPage.propTypes = {
  paths: PropTypes.arrayOf(PropTypes.object),
};

export default React.memo(ProductsListPage);
