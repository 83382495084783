import React from 'react';
import SafeAreaView from 'components/SafeAreaView';
import HomeIcon from '@material-ui/icons/Home';
import PhoneIcon from '@material-ui/icons/Phone';
import FacebookIcon from '@material-ui/icons/Facebook';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LanguageIcon from '@material-ui/icons/Language';
import SEO from 'components/SEO';
import FacebookTypes from 'constants/facebookTypes';
import InstagramIcon from '@material-ui/icons/Instagram';
import { URL_LOGO } from 'constants/logoUrl';
import { Link } from 'react-router-dom';

const ContactPage = () => {
  const src = '/assets/images/logo1.png';

  return (
    <>
      <SEO
        title="Page contact Moncef Jouets"
        description="Les informations sur les cordonnées de moncef jouets"
        type={FacebookTypes.article}
        image={URL_LOGO}
      />
      <SafeAreaView>
        <div className="container space-1 space-md-2 space-top1 shadow-lg border-dark mt-7 mb-6 d-flex align-items-center justify-content-center flex-column ">
          <div
            className="image-preview"
            style={{
              backgroundImage: `url(${src})`,
              backgroundSize: 'contain',
              width: '100px',
              height: '100px',
            }}
          />
          <div className=" d-flex align-items-center justify-content-center">
            Adresse
            <HomeIcon />
            : Espace Perle Bleu, 5, Km5 Route du Gammarth, Marsa 2078
            <br />
          </div>
          <br />
          <div className=" d-flex align-items-center justify-content-center">
            Téléphone
            <PhoneIcon />
            :26 284 898
          </div>
          <br />
          <div className=" d-flex align-items-center justify-content-center">
            Facebook
            <FacebookIcon />
            <a href="https://www.facebook.com/moncefjouets/" target="_blank">
              {' '}
              https://www.facebook.com/moncefjouets/
            </a>
          </div>
          <br />
          <div className=" d-flex align-items-center justify-content-center">
            Instagram
            <InstagramIcon />
            <a href="https://www.instagram.com/moncefjouets/" target="_blank">
              {' '}
              https://www.instagram.com/moncefjouets/
            </a>
          </div>
          <br />
          <div className=" d-flex align-items-center justify-content-center">
            E-mail
            <MailOutlineIcon />
            moncefjouets@gmail.com
          </div>
          <br />
          <div className=" d-flex align-items-center justify-content-center">
            Site Web
            <LanguageIcon />
            <Link to="/"> https://www.moncefjouets.com/</Link>
          </div>
        </div>
      </SafeAreaView>
    </>
  );
};

export default ContactPage;
