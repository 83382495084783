/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-extra-parens */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import calculateAmount from 'helpers/calculateAmount';
import moment from 'moment';

const CommandItem = ({ command }) => {
  return (
    command && (
      <>
        <Link className="card card-frame py-3 px-4 mb-2 " to={`/command/${command.id}`}>
          <div className="row ">
            <span className="col-3 text-dark">{command.id}</span>
            <span className="col-3 text-body">{moment(command.creation).format('L')}</span>
            <span className="col-4 text-body">
              {command.deliveryMode === 'false' ? 'magasin ' : 'livraison '}/
              {command.buyingType === 'false' ? ' surplace' : ' en ligne'}
            </span>
            <span className="col-2 text-right">
              {`${calculateAmount(command)} TND`}
              <i className="fas fa-angle-right fa-sm ml-1" />
            </span>
          </div>
        </Link>
      </>
    )
  );
};

CommandItem.propTypes = {
  command: PropTypes.object.isRequired,
};
export default CommandItem;
