/* eslint-disable no-extra-parens */
/* eslint-disable no-nested-ternary */
import React from 'react';
import ImageAsyncComponent from 'components/ImageAsyncComponent';
import { getImageUrl } from 'helpers/getImageUrl';
import formatPrice from 'helpers/formatPrice';
import PropTypes from 'prop-types';
import calculateAmount from 'helpers/calculateAmount';
import { formatMouvsArray } from 'helpers/formatMouvsArray';
import { slugifyString } from 'helpers/slugifyString';
import { Link } from 'react-router-dom';

const SingleCommandContainer = ({ command }) => {
  const _command = formatMouvsArray(command);

  return (
    <>
      <h3>Détail de la commande</h3>
      <div className="w-lg-100 row d-flex justify-content-center">
        <table className="table col-10">
          <thead>
            <tr>
              <th>image</th>
              <th scope="col"> Title</th>
              <th scope="col">Reference</th>
              <th scope="col">Marque</th>
              <th scope="col">quantite</th>
              <th scope="col">prix</th>
            </tr>
          </thead>
          <tbody>
            {_command?.mouvs?.map(
              (mouv, index) =>
                mouv?.product && (
                  <>
                    <tr>
                      <td>
                        <div>
                          {mouv.reference.trim() === '2005000003936' ? (
                            <ImageAsyncComponent image="/assets/images/papergift.jpg" />
                          ) : mouv.reference.trim() === 'FRAITRANSPORT' ? (
                            <ImageAsyncComponent image="/assets/images/livraison.png" />
                          ) : mouv.reference.trim() === 'ZZ_TIMBRE' ? (
                            <ImageAsyncComponent image="/assets/images/timbre.jpg" />
                          ) : (
                            <ImageAsyncComponent image={getImageUrl(mouv.product.eanId)}> </ImageAsyncComponent>
                          )}
                        </div>
                      </td>
                      <td>
                        {' '}
                        {mouv?.product?.hasNoDiscount ? (
                          <span className="badge badge-warning badge-pill text-white">Hors promo</span>
                        ) : (
                          ''
                        )}
                        <Link className="h5 d-block" to={`/products/${slugifyString(mouv.product.title)}`}>
                          {mouv?.product?.title || ''}
                        </Link>
                      </td>
                      <td>{mouv.reference}</td>
                      <td>{mouv.product.brand}</td>
                      <td>{`${mouv.commandQuantity} *`}</td>
                      <td>
                        {' '}
                        {!mouv?.product?.hasNoDiscount &&
                        mouv?.product?.reference !== 'ZZ_TIMBRE' &&
                        mouv?.product?.reference !== 'FRAITRANSPORT' &&
                        mouv?.product?.reference !== '2005000003936' ? (
                          <>
                            <del>{formatPrice(mouv?.product?.price, 'TND')}</del>
                            <span className="h5 d-block m-1">
                              {formatPrice(
                                mouv?.product?.price - mouv?.product?.price * (_command.discountPerc / 100),
                                'TND',
                              )}
                            </span>
                          </>
                        ) : (
                          <span className="h5 d-block mb-1 mr-2">{formatPrice(mouv?.product?.price, 'TND')}</span>
                        )}
                      </td>
                    </tr>
                  </>
                ),
            )}
            {/* <tr>
              <td>Remise en pourcentage :</td>
              <td />
              <td />
              <td>{`- ${_command.discountPerc} % `}</td>
              <td />
            </tr> */}
            <tr>
              <td>Somme total de la commande :</td>
              <td />
              <td />
              <td />
              <td />
              <td className="h5 d-block mb-1 mr-2">{`${formatPrice(calculateAmount(_command), 'TND')}`}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

SingleCommandContainer.propTypes = {
  command: PropTypes.object.isRequired,
};
export default SingleCommandContainer;
