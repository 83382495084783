const FacebookTypes = {
  apps: {
    saves: 'apps.saves', // An action representing someone saving an app to try later.
  },
  article: 'article', // This object represents an article on a website. It is the preferred type for blog posts and news stories.
  book: {
    book: 'book', // This object type represents a book or publication. This is an appropriate type for ebooks,
    // as well as traditional paperback or hardback books. Do not use this type to represent magazines
    author: 'books.author', // This object type represents a single author of a book.
    books: 'books.book', // This object type represents a book or publication.
    // This is an appropriate type for ebooks, as well as traditional paperback or hardback books
    genre: 'books.genre', // This object type represents the genre of a book or publication.
  },
  business: {
    business: 'business.business', // This object type represents a place of business that has a location, operating hours and
    // contact information.
  },
  fitness: {
    course: 'fitness.course', // This object type represents the user's activity contributing to a particular run, walk, or bike course.
  },
  game: {
    achievement: 'game.achievement',
    points: 'game:points',
    /**
    This object type represents a specific achievement in a game. 
    An app must be in the 'Games' category in App Dashboard 
    to be able to use this object type. Every achievement 
    has a game:points value associate with it. This is not related to the points the user 
    has scored in the game, but is a way for the app to indicate the relative importance 
    and scarcity of different achievements: * Each game gets a total of 1,000 points 
    to distribute across its achievements * Each game gets a maximum of 1,000 achievements * 
    Achievements which are scarcer and have higher point values will receive more distribution 
    in Facebook's social channels. For example, achievements which have point values of less than 10 
    will get almost no distribution. Apps should aim for between 50-100 achievements consisting of 
    a mix of 50 (difficult), 25 (medium), and 10 (easy) point value achievements */
    achieves: 'games.achieves', //  An action representing someone reaching a game achievement.
    celebrate: 'games.celebrate', // An action representing someone celebrating a victory in a game.
    plays: 'games.plays', // An action representing someone playing a game. Stories for this action will only appear in the activity log.
    saves: 'games.saves', // An action representing someone saving a game.
  },
  music: {
    album: 'music.album', // This object type represents a music album; in other words,
    // an ordered collection of songs from an artist or a collection of artists. An album can comprise multiple discs.
    playlist: 'music.playlist', // This object type represents a music playlist,
    // an ordered collection of songs from a collection of artists.
    song: 'music.song', // This object type represents a single song.
  },
  news: {
    publishes: 'news.publishes', // An action representing someone publishing a news article.
  },
  place: 'place', // This object type represents a place - such as a venue, a business,
  // a landmark, or any other location which can be identified by longitude and latitude.
  product: {
    product: 'product', // This object type represents a product.
    // This includes both virtual and physical products, but it typically represents items that are available in an online store.
    group: 'product.group', // This object type represents a group of product items.
    item: 'product.item', // This object type represents a product item.
  },
  profile: 'profile', // This object type represents a person.
  // While appropriate for celebrities, artists, or musicians,
  // this object type can be used for the profile of any individual. The fb:profile_id field associates the object with a Facebook user.
  restaurant: {
    menu: 'restaurant.menu',
    menu_item: 'restaurant.menu_item',
    menu_section: 'restaurant.menu_section', // This object type represents a section in a restaurant's menu.
    // A section contains multiple menu items.
    restaurant: 'restaurant.restaurant',
  },
  video: {
    episode: 'video.episode', // This object type represents an episode of a TV show and contains
    // references to the actors and other professionals involved in its production.
    // An episode is defined by us as a full-length episode that is part of a series.
    // This type must reference the series this it is part of.
    movie: 'video.movie', // This object type represents a movie, and contains references
    // to the actors and other professionals involved in its production. A movie is defined by us as a full-length
    // feature or short film. Do not use this type to represent movie trailers, movie clips, user-generated video content, etc.
    other: 'video.other', // This object type represents a generic video, and contains references to
    // the actors and other professionals involved in its production. For specific types of video content,
    // use the video.movie or video.tv_show object types. This type is for any other type of video content not
    // represented elsewhere (eg. trailers, music videos, clips, news segments etc.)
    tv_show: 'video.tv_show', // This object type represents a TV show, and contains references to the actors
    // and other professionals involved in its production. For individual episodes of a series,
    // use the video.episode object type. A TV show is defined by us as a series or set of
    // episodes that are produced under the same title (eg. a television or online series)
  },
};

export default FacebookTypes;
