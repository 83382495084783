import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ImageAsyncComponent from 'components/ImageAsyncComponent';

const HeaderLinks = ({ to, label, icon, content }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <li
      className="hs-has-sub-menu navbar-nav-item mr-lg-auto"
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      <Link className="hs-mega-menu-invoker nav-link" to={to} onClick={() => setIsHovered(false)}>
        <i className={`${icon} font-size-2 mr-1`} />
        {label}
      </Link>
      <div
        className="hs-mega-menu w-100 dropdown-menu slideInUp"
        style={{
          display: isHovered ? 'block' : 'none',
          animationDuration: '300ms',
        }}
      >
        <div className="row mega-menu-body">
          {content.slice(0, 8).map((o, index) => {
            if (index < 7) {
              return (
                <Link
                  to={`/products${o.searchKey}`}
                  key={o.name}
                  onClick={() => setIsHovered(false)}
                  className="text-muted d-flex flex-column justify-content-center col-sm-3 mb-3 col-6"
                >
                  <div className="mb-3 m-auto " style={{ width: '150px', height: '150px' }}>
                    <ImageAsyncComponent image={o.image} />
                  </div>
                  <span className=" h5 text-center">{o.name}</span>
                </Link>
              );
            }
            if (index === 7) {
              return (
                <Link
                  to={to}
                  key={o.name}
                  onClick={() => setIsHovered(false)}
                  className="text-muted  d-flex flex-column justify-content-center h3 col-sm-3 mb-3 mb-sm-0"
                >
                  <div className="mb-3 m-auto" style={{ width: '150px', height: '150px' }}>
                    <div className="dark-overlay">
                      <div
                        className="image-preview"
                        style={{
                          backgroundImage: `url(/assets/images/regarder.PNG)`,
                          backgroundSize: 'contain',
                        }}
                      />
                    </div>
                  </div>
                </Link>
              );
            }

            return <div key={o.name} />;
          })}
        </div>
      </div>
    </li>
  );
};

HeaderLinks.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  content: PropTypes.array,
};
export default HeaderLinks;
