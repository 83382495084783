import React from 'react';
import { emphasize, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';

const StyledBreadcrumb = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);

const CustomizedBreadcrumbs = ({ textInfo, onClick }) => {
  return <StyledBreadcrumb className="m-1" component="a" label={`${textInfo} X`} onClick={onClick} />;
};

CustomizedBreadcrumbs.propTypes = {
  textInfo: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
export default CustomizedBreadcrumbs;
