/* eslint-disable react/no-danger */
/* eslint-disable no-extra-parens */
/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ImageAsyncComponent from 'components/ImageAsyncComponent';
import formatPrice from 'helpers/formatPrice';
import { useSelector } from 'react-redux';

const ProductGridCard = ({
  onClick,
  buttonText,
  title,
  category,
  image,
  greenTag,
  quantity,
  redTag,
  description,
  price,
  unit,
  isAddedToCart,
  buttonExistText,
  discount,
  productLink = '#',
}) => {
  const priceAfterDiscount = (discount ? ((100 - discount) / 100) * price : price).toFixed(2);
  const _loading = useSelector(({ loading }) => loading.loading);

  return (
    <>
      <div className="card border shadow-none  h-100 ">
        <Link className="position-relative" to={productLink}>
          <ImageAsyncComponent image={image} />
          {quantity < 3 && (
            <span className="m-1 badge position-absolute bottom-0 left-0 badge-danger badge-pill">{redTag}</span>
          )}
        </Link>
        <div className="card-body text-left px-3 pt-4 pb-0">
          <div className="mb-2">
            <span className="d-block font-size-1">
              <Link className="text-inherit" to={productLink}>
                <div className="Container" dangerouslySetInnerHTML={{ __html: title }} />
              </Link>
            </span>
            <hr className="d-dm-block d-none" />
            <p
              style={{ color: '#aaaaaacf', fontSize: '0.3 rem' }}
              className="text-gray d-md-block d-none"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>
        <div className="card-footer border-0 pt-0 pb-3 px-4 text-center">
          <div className="d-block text-center pb-1 d-flex flex-column">
            <span className="font-weight-bold" style={{ color: '#444385' }}>
              {formatPrice(priceAfterDiscount, unit)}
            </span>
            {discount && (
              <span className="text-body ml-1">
                <del>{formatPrice(price, unit)}</del>
              </span>
            )}
          </div>
          {isAddedToCart ? (
            <button disabled={_loading} type="button" className="btn btn-sm btn-soft-secondary disabled">
              {buttonExistText}
            </button>
          ) : (
            buttonText && (
              <button
                onClick={onClick}
                type="button"
                disabled={_loading}
                className={`btn btn-sm btn-soft-primary transition-3d-hover ${_loading ? 'disabled' : ''}`}
              >
                {buttonText}
              </button>
            )
          )}
        </div>
      </div>
      <div />
    </>
  );
};

ProductGridCard.propTypes = {
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  description: PropTypes.string,
  isAddedToCart: PropTypes.bool.isRequired,
  buttonExistText: PropTypes.string,
  unit: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  productLink: PropTypes.string.isRequired,
  category: PropTypes.string,
  image: PropTypes.string.isRequired,
  greenTag: PropTypes.string,
  quantity: PropTypes.number,
  redTag: PropTypes.string,
  price: PropTypes.number.isRequired,
  discount: PropTypes.number,
};

export default ProductGridCard;
