import { FETCH_HERO_SECTION } from 'constants/types/heroSection';
import { startLoading, stopLoading } from 'actions/loading';
import { fetchAllHeroSection } from 'services/heroSection';
import { errorHandler } from 'helpers/errorHandler';

export const fetchHeroSection = query => async (dispatch, getState) => {
  const rootState = getState();
  const { isPreloaded } = rootState.brandsReducer;

  if (isPreloaded) return;

  dispatch(startLoading());

  try {
    const response = await fetchAllHeroSection()(query);

    dispatch({ type: FETCH_HERO_SECTION, payload: response });
  } catch (error) {
    errorHandler(error);
  }

  dispatch(stopLoading());
};
