/* eslint-disable no-unused-expressions */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import BrandCardImage from 'components/BrandCardImage';
import { Collapse } from 'reactstrap';
import useElastic from 'hooks/useElastic';
import { useCreateUrl } from 'hooks/useCreateUrl';
import useBrandHeroes from 'hooks/useBrandHeroes';

const FilterBrand = createURL => {
  const [isCollapse, setIsCollapse] = useState(true);
  const toggleCollapse = () => setIsCollapse(!isCollapse);
  const { facets } = useElastic();
  const { refineBrand, currentRefinementBrand: _brand } = useBrandHeroes();
  const createUrl = useCreateUrl();

  const brandsAr = [];

  facets?.brand['0'].data.forEach(brand => {
    try {
      const { id, image, name } = JSON.parse(brand.value);

      brandsAr.push({
        id,
        image,
        name,
        value: brand.value,
      });
      // eslint-disable-next-line no-empty
    } catch {}
  });

  return (
    <div>
      <h4>Choisir votre marque</h4>
      <div className="row justify-content-center">
        {brandsAr
          .filter((e, index) => index < 4)
          .map(b => (
            <BrandCardImage
              link={createUrl({ brand: b.value === _brand ? 'zero' : b.value, page: '{"size":16,"current":1}' })}
              // currentrefineBrandment={currentrefineBrandment}
              key={b.id}
              model={b}
              refine={() => {
                refineBrand(b.value);
              }}
            />
          ))}
      </div>
      <Collapse isOpen={!isCollapse} className="row justify-content-center">
        {brandsAr
          .filter((e, index) => index >= 4)
          .map(b => (
            <BrandCardImage
              key={b.id}
              link={createUrl({ brand: b.value === _brand ? 'zero' : b.value, page: '{"size":16,"current":1}' })}
              // link={createURL(b.value)}
              model={b}
              refine={() => {
                refineBrand(b.value);
              }}
            />
          ))}
      </Collapse>

      <div onClick={toggleCollapse} className="btn btn-soft-indigo btn-block">
        {isCollapse ? 'Afficher +' : 'Afficher -'}
      </div>
    </div>
  );
};

export default FilterBrand;
