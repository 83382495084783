import _ from 'lodash';

export const numberItemInCarts = carts => {
  let nbCardItems = 0;

  if (!carts || _.isEmpty(carts)) return 0;
  carts.forEach(product => {
    nbCardItems += product.quantity;
  });

  return nbCardItems;
};
