/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SafeAreaView from 'components/SafeAreaView';
import { fetchProduct } from 'actions/products';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import formatPaths from 'helpers/formatPaths';
import SEO from 'components/SEO';
import TextConverter from 'helpers/rtfConverter';
import FacebookTypes from 'constants/facebookTypes';
import { formatStringToHtml } from 'helpers/formatStringToHtml';
import { getImageUrl } from 'helpers/getImageUrl';
import _ from 'lodash';
import formatPathsCategory from 'helpers/formatPathsCategory';
import { fetchCategories } from 'actions/categories';
import AsyncSingleProductPage from './AsyncSingleProductPage';

const SingleProductPage = () => {
  const history = useHistory();
  const convert = new TextConverter();
  const { params } = useRouteMatch();
  const onFailure = () => {
    history.push('/article-introuvable');
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchProduct(params.slug, onFailure));
  }, []);
  useEffect(() => {
    dispatch(fetchProduct(params.slug, onFailure));
  }, [params.slug]);
  const { pathname } = useLocation();
  const paths = formatPaths(pathname);
  const { categories } = useSelector(({ categoriesReducer }) => categoriesReducer);
  const { article } = useSelector(({ product }) => product);
  const image = getImageUrl(article?.eanId);

  const pathsCategory = article ? formatPathsCategory(categories, article.category_1) : undefined;

  let description;

  if (!_.isEmpty(article)) {
    if (article?.description) {
      description = formatStringToHtml(
        convert.convert(
          article?.description
            ?.replace(/\\'e9/g, 'é')
            ?.replace(/\\'e2/g, 'â')
            ?.replace(/\\'e8/g, 'è')
            ?.replace(/\\'c8/g, 'è')
            ?.replace(/\\'8c/g, 'Œ')
            ?.replace(/\\'ef/g, 'ï')
            ?.replace(/\\'ea/g, 'ê')
            ?.replace(/\\'fb/g, 'û')
            ?.replace(/\\'e0/g, 'à')
            ?.replace(/\\'e7/g, 'ç'),
        ),
      );
    }
  }

  return (
    <SafeAreaView>
      <SEO title={article?.title} description={description} type={FacebookTypes?.article} image={image} />
      <AsyncSingleProductPage paths={paths} pathsCategory={article ? pathsCategory : undefined} />
    </SafeAreaView>
  );
};

export default SingleProductPage;
