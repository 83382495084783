export const trimSpacesFromObject = objectOrArray => {
  if (typeof objectOrArray === 'string') return objectOrArray.replace(/^\s+|\s+$/g, '');
  if (!objectOrArray || typeof objectOrArray === 'undefined') return undefined;
  if (Array.isArray(objectOrArray)) return objectOrArray.map(_o => trimSpacesFromObject(_o));
  const res = {};

  Object.keys(objectOrArray).map(i => {
    if (
      // eslint-disable-next-line operator-linebreak
      (typeof objectOrArray[i] === 'object' || Array.isArray(objectOrArray[i])) &&
      !(Object.prototype.toString.call(objectOrArray[i]) === '[object Date]')
    ) {
      return (res[i] = trimSpacesFromObject(objectOrArray[i]));
    }
    if (typeof objectOrArray[i] === 'string') return (res[i] = objectOrArray[i].replace(/^\s+|\s+$/g, ''));

    return (res[i] = objectOrArray[i]);
  });

  return res;
};
