import { Link, useLocation } from 'react-router-dom';

import React from 'react';
import { queryToSearchObject } from 'helpers/queryToSearchObject';
import DisplayData from 'components/DisplayData';
import SafeAreaView from 'components/SafeAreaView';

const Payment = () => {
  const { search } = useLocation();

  const query = queryToSearchObject(search);

  return (
    <SafeAreaView>
      <div className="container space-2">
        <div className="w-md-80 w-lg-50 text-center mx-md-auto">
          {query && query.orderStatus === 'success' ? (
            <>
              <i className="fas fa-check-circle text-success fa-5x mb-3" />
              <div className="mb-5">
                <h1 className="h2 mb-5">Paiement effectué avec succée !!</h1>
                <DisplayData title="Reference" value={query.orderId} />
                <DisplayData title="Type d'achat" value="achat d'un produit" />
                <DisplayData title="Prix" value={`${query.amount} millimes`} />
              </div>
            </>
          ) : (
            <>
              <i className="fas fa-times-circle text-danger fa-5x mb-3" />
              <div className="mb-5">
                <h1 className="h2">Echec du paiment</h1>
              </div>
            </>
          )}
          <Link to="/">
            <div className="btn btn-primary btn-pill transition-3d-hover px-5">Retouner à l&apos;accueil</div>
          </Link>
        </div>
      </div>
    </SafeAreaView>
  );
};

export default Payment;
