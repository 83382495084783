import { useDispatch, useSelector } from 'react-redux';
import { FILTER_AGE } from 'constants/types/products';
import { useHistory } from 'react-router-dom';
import { useCreateUrl } from './useCreateUrl';

const useAge = () => {
  const dispatch = useDispatch();
  const { ages } = useSelector(({ products }) => products);
  const history = useHistory();
  const createUrl = useCreateUrl();
  const refineAge = arrayOfAges => {
    dispatch({ type: FILTER_AGE, payload: arrayOfAges });
    history.push(createUrl({ ages: arrayOfAges, page: '{"size":16,"current":1}' }));
  };

  return {
    refineAge,
    currentRefinmentAges: ages,
  };
};

export default useAge;
