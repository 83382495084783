/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import MuiSlider from '@material-ui/core/Slider';
import PropTypes from 'prop-types';
import formatPrice from 'helpers/formatPrice';
import usePrice from 'hooks/usePrice';

const SliderPrice = ({ min, max, label, id, unit }) => {
  const [value, setValue] = useState(typeof min === 'number' && max ? [min, max] : min || max);
  const [localValue, setLocalValue] = useState(value);

  const { refinePrice, price } = usePrice();

  const onChange = (e, _value) => {
    setValue(_value);
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    if (price) {
      setLocalValue([price.from, price.to]);
    } else {
      setLocalValue([min, max]);
    }
  }, [price]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    refinePrice(newValue[0], newValue[1]);
  };

  return (
    <>
      <label className="form-label" htmlFor={id}>
        <span className="d-flex justify-content-between align-items-center">
          {`${label}:  (${formatPrice(localValue[0], unit)} - ${formatPrice(localValue[1], unit)}) `}
        </span>
      </label>
      <MuiSlider
        value={localValue}
        onChange={onChange}
        onChangeCommitted={handleChange}
        min={min}
        max={max}
        className="text-primary"
      />
    </>
  );
};

SliderPrice.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  label: PropTypes.string,
  id: PropTypes.string,
  unit: PropTypes.string,
};

export default SliderPrice;
