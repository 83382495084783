import { useDispatch, useSelector } from 'react-redux';
import { FILTER_PRICE } from 'constants/types/products';
import { useHistory } from 'react-router-dom';
import { useCreateUrl } from './useCreateUrl';

const usePrice = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const createUrl = useCreateUrl();
  const { price } = useSelector(({ products }) => products);
  const refinePrice = (from, to) => {
    const val = { from, to };

    if ((from || to) && !(from === 0 && to === 1000)) {
      history.push(createUrl({ price: val, page: '{"size":16,"current":1}' }));
      dispatch({ type: FILTER_PRICE, payload: val });
    } else {
      history.push(createUrl({ price: 'zero' }));
      dispatch({ type: FILTER_PRICE });
    }
  };

  return {
    refinePrice,
    price,
  };
};

export default usePrice;
