import React from 'react';
import PropTypes from 'prop-types';
import CommandItem from 'components/CommandItem';

const ListCommands = ({ commands }) => {
  return (
    <div className="col-12 mb-7 mb-lg-0">
      <div className="d-flex justify-content-between align-items-end border-bottom pb-3 mb-7">
        <h1 className="h3 mb-0">Liste des commandes</h1>
      </div>
      <div>
        <div className="row mb-2">
          <span className="col-3 text-dark">Id de la commande</span>
          <span className="col-3 text-dark">Date de la commande</span>
          <span className="col-4 text-dark">Mode de Livraison/Paiement</span>
          <span className="col-2 text-dark">Somme de la commande</span>
        </div>
        {commands &&
          commands?.map((command, index) => {
            return <CommandItem key={`${index + 1}`} command={command} />;
          })}
      </div>
    </div>
  );
};

ListCommands.propTypes = {
  commands: PropTypes.array,
};

export default ListCommands;
