/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import SafeAreaView from 'components/SafeAreaView';
import { useSelector, useDispatch } from 'react-redux';
import CheckoutSummary from 'containers/CheckoutSummary';
import CheckoutFormContainer from 'containers/CheckoutFormContainer';
import { trimSpacesFromObject } from 'helpers/trimSpacesFromObject';
import { checkoutAction } from 'actions/checkout';
import { totalOfCarts } from 'helpers/totalOfCarts';
import { useHistory } from 'react-router-dom';
import { SET_TOTAL } from 'constants/types/checkout';
import LoaderComponent from 'components/Loader';

const CheckoutPage = () => {
  const isLoading = useSelector(({ loading }) => loading.loading);

  const { user } = useSelector(({ userReducer }) => userReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const carts = user?.user?.carts;
  const userForForm = trimSpacesFromObject(user?.user) || {};
  const { isDelivery, isPaymentOnline, shop, promotion, isGift } = useSelector(({ checkout }) => checkout);

  const totalToPay = totalOfCarts(carts) - totalOfCarts(carts) / 10;

  useEffect(() => {
    if (userForForm.addressF) {
      userForForm.addressF.gouv = parseInt(userForForm.addressF.gouv, 10);
      userForForm.addressF.ville = parseInt(userForForm.addressF.ville, 10);
      userForForm.addressF.city = parseInt(userForForm.addressF.city, 10);
    }
    dispatch({ type: SET_TOTAL, payload: totalToPay });
  }, []);
  const handleSubmit = values => {
    dispatch(checkoutAction({ ...values, carts, isDelivery, totalToPay, isPaymentOnline, shop, isGift }, history));
  };

  return (
    <SafeAreaView>
      {!isLoading ? (
        <div className="container space-1 space-md-2">
          <div className="row">
            <CheckoutSummary carts={carts} />
            <CheckoutFormContainer
              initialValues={{
                lastName: userForForm.lastName,
                firstName: userForForm.firstName,
                email: userForForm.email,
                phone: userForForm.phone,
                address: userForForm.address + userForForm?.address2,
                tiers: userForForm.tiers,
                moreInformations: "pas d'informations suplémentaire sur l'adresse",
                promotionId: promotion.promotion.id,
                addressF: userForForm.addressF,
              }}
              onSubmit={handleSubmit}
            />
          </div>
        </div>
      ) : (
        <div className="w-50 h-50" style={{ marginLeft: '45%', marginTop: '10%', marginBottom: '20%' }}>
          <LoaderComponent />
        </div>
      )}
    </SafeAreaView>
  );
};

export default CheckoutPage;
