/* eslint-disable max-lines */
/* eslint-disable react/no-unescaped-entities */
import SafeAreaView from 'components/SafeAreaView';
import SEO from 'components/SEO';
import React from 'react';

const TermsPage = () => {
  return (
    <div>
      <SEO
        title="Conditions générales d'utilisation"
        description="Le présent site Web  est destiné à la vente de divers produits de consommation en Tunisie"
      />
      <SafeAreaView>
        <div className="container mt-9">
          <h1>Conditions générales d'utilisation:</h1>
          <p className="m-3 p-3 h4">Le présent site internet est exploité par:</p>
          <p className="m-3 p-3">
            MONCEF JOUETS SARL, société à responsabilité limitée, au capital de 20000Dinars, titulaire de l’identifiant
            unique numéro 1471111/VBM/000 , dont le siège social est sis à 5 espace la perle bleu, route de Gammarth
            KM5, la Marsa, Tunis, Tunisie.
          </p>
          <p className="m-3 p-3">
            Le présent site Web (ci-après désigné par le terme "Marketplace" et/ou "site Web") est destiné à la vente de
            divers produits de consommation en Tunisie («Territoire »).
          </p>
          <p className="m-3 p-3">
            Les présentes Conditions Générales s'appliquent aux acheteurs sur la Marketplace et régissent leur
            utilisation de la Marketplace et de tous les services associés.
          </p>
          <p className="m-3 p-3">
            En utilisant le présent site Web, le Client accepte ces conditions générales dans leur intégralité. Si le
            Client n'est pas d'accord avec ces conditions générales ou une partie de ces conditions générales, dans ce
            cas, il ne devra pas utiliser notre Marketplace.
          </p>
          <p className="m-3 p-3">
            L'inscription au Site Web est réserve aux personnes âgées de 18 ans et plus; en utilisant notre Marketplace
            ou en acceptant les présentes conditions générales, le Client garantit et déclare avoir au moins 18 ans
            ainsi que l'exactitude et la complétude toutes les informations fournies dans le formulaire d'inscription.
          </p>
          <p className="m-3 p-3">
            Il sera demandé au Client, lors de l'inscription une adresse e-mail / ID (nom d’utilisateur) et mot de passe
            et d'accepter :
          </p>
          <p className="m-3 p-3">• de garder son mot de passe confidentiel; et</p>
          <p className="m-3 p-3">
            • d'informer le gestionnaire du site Web, immédiatement et par écrit de toute éventuelle divulgation de son
            mot de passe; et
          </p>
          <p className="m-3 p-3">
            • d'être responsable de toute activité sur notre Marketplace résultant d'un manquement à la confidentialité
            de son mot de passé; le Client engageant sa responsabilité au titre de toute perte résultant d'un tel
            manquement.
          </p>
          <p className="m-3 p-3">
            Le compte du Client doit être utilisé exclusivement par lui même et il ne devra en aucun cas transférer son
            compte à un tiers. S'il autorise un tiers à gérer son compte en son nom, cela se fera aux propres risqué et
            périls du Client.
          </p>
          <p className="m-3 p-3">
            Le gestionnaire du site Web se réserve le droit de suspendre ou annuler le compte du Client et / ou modifier
            les détails de son compte, à tout moment, sans préavis ni explication; Moncef Jouets s'engageant, dans ce
            cas, à rembourser le Client au titre de tout éventuels produits ou des services que le Client aurait déjà
            payés mais qu'il n'aurait pas encore reçus.
          </p>
          <p className="m-3 p-3">
            Le Client peut à tout moment, se désinscrire et supprimer son compte sur notre Marketplace, en contactant le
            gestionnaire du site Web.
          </p>
          <p className="m-3 p-3 h4">Conditions Générales de Vente :</p>
          <p className="m-3 p-3">
            Le prix de tout produit sera celui indiqué dans la liste et le descriptif du produit concerné et doit
            inclure toutes les taxes et être conforme aux lois applicables en vigueur dans le Territoire.
          </p>
          <p className="m-3 p-3">
            Les frais de livraison, les frais d'emballage, les frais de manutention, les frais administratifs, les frais
            d'assurance, les autres frais et charges accessoires ne seront à la charge du Client que si cela est
            expressément et clairement indiqué dans la liste et le descriptif des produits concernés.
          </p>
          <p className="m-3 p-3">
            Les produits concernés doivent être de bonne qualité, adaptés et sûrs pour tout usage spécifié dans le
            descriptif des produits et conformes à tous les aspects matériels, ainsi que toute autre description des
            produits fournie ou mis à disposition de l'acheteur par le vendeur.
          </p>
          <p className="m-3 p-3">
            Les retours de produits par le Client et l'acceptation desdits produits retournés par Moncef Jouets se fera
            conformément aux usages et lois en vigueur et au cas par cas, à la discrétion de Moncef Jouets, sous réserve
            du respect des lois applicables dans le Territoire.
          </p>
          <p className="m-3 p-3">
            Les remboursements concernant les produits retournés seront gérés, au cas par cas, conformément aux usages
            et lois en vigueur.
          </p>
          <p className="m-3 p-3">
            Le Client devra effectuer les paiements dus en vertu des présentes conditions générales conformément aux
            Informations et Directives sur les Paiements disponibles sur notre Marketplace.
          </p>
          <p className="m-3 p-3">
            Moncef Jouets, avec ses concédants de licence, détiennent et contrôlent tous les droits d'auteur et autres
            droits de propriété intellectuelle sur le site Web ainsi; lesdits droits étant réservés.
          </p>
          <p className="m-3 p-3">
            Les logos de Moncef Jouets et des autres marques déposées et non déposées sont des marques protégées par les
            lois en vigueur. Aucune autorisation n'est accordée en la matière.
          </p>
          <p className="m-3 p-3">
            Les marques commerciales enregistrées et non enregistrées par des tiers sur le site Web appartiennent à
            leurs propriétaires respectifs.
          </p>
          <p className="m-3 p-3">
            Moncef Jouets ne peut être tenu responsable envers le Client de toute perte ou dommage de quelque nature que
            ce soit, y compris, notamment, en ce qui concerne:
          </p>
          <p className="m-3 p-3">• toute perte occasionnée par une interruption ou un dysfonctionnement du site Web;</p>
          <p className="m-3 p-3">
            • toute perte découlant d'un événement ou de plusieurs événements hors du contrôle raisonnable de Moncef
            Jouets;
          </p>
          <p className="m-3 p-3">
            • toute perte ou corruption de toute donnée, base de données ou logiciel ou toute perte ou dommage spécial,
            indirect ou consécutif.
          </p>
          <p className="m-3 p-3">
            Le Client accepte de limiter la responsabilité personnelle des dirigeants et des employés de Moncef Jouets;
            le Client reconnait que Moncef Jouets est une entité à responsabilité limitée et accepte de ne jamais porter
            plainte personnellement contre les dirigeantes ou employés pour toute perte qu'il aurait subi en rapport
            avec l’utilisation de notre Marketplace ou les présentes conditions générales.
          </p>
          <p className="m-3 p-3">
            Si la Marketplace comprendrait des liens hypertexte vers d'autres sites Web détenus et exploités par des
            tiers; ces liens hypertexte ne sont pas des recommandations; Moncef Jouets n'ayant aucun contrôle sur les
            sites Web tiers et leur contenu, et déclinant toute responsabilité à leur égard ou pour toute perte ou
            dommage pouvant résulter de leur utilisation.
          </p>
          <p className="m-3 p-3">
            Les présentes conditions générales peuvent être révisées de temps à autre. Elles s'appliquent à compter de
            la date de leur publication sur notre Marketplace.
          </p>
          <p className="m-3 p-3">
            Si une disposition des présentes conditions générales est jugée par un tribunal ou une autre autorité
            compétente comme étant illégale et/ou inapplicable, les autres dispositions resteront en vigueur. Si une
            disposition illégale et/ou inapplicable des présentes conditions générales deviendrait légale ou applicable
            si une partie de celle-ci était supprimée, cette partie sera considérée comme supprimée, et le reste de la
            disposition continuera à produire ses effets.
          </p>
          <p className="m-3 p-3">
            Le Client accepte, par les présentes que le propriétaire du site Web puisse céder, transférer, sous-traiter
            ou traiter de toute autre manière nos droits et/ou obligations en vertu des présentes conditions générales.
          </p>
          <p className="m-3 p-3">
            Les présentes conditions générales sont régies et interprétées conformément au droit du Territoire.
          </p>
          <p className="m-3 p-3">
            Tout litige relatif aux présentes conditions générales est soumis à la compétence exclusive des tribunaux
            compétents du Territoire (tribunaux de Tunis1).
          </p>
          <p className="m-3 p-3 h4">Traitement et protection des données à caractère personnel :</p>
          <p className="m-3 p-3">
            Le Client donne son accord à Moncef Jouets pour le traitement de ses données à caractère personnel. Soit
            directement, soit à travers son réseau de distribution ainsi que ses sous-traitants, établis en Tunisie, qui
            sont soumis aux mêmes obligations qui incombent à Moncef Jouets en matière de traitement des données
            personnelles et assument leurs responsabilités en cas de non-respect de la loi en vigueur.
          </p>
          <p className="m-3 p-3">
            Moncef Jouets s’oblige à ce que les données à caractère personnel du Client ne soient utilisées que pour les
            besoins exclusifs de l’exercice de son activité conformément aux lois en vigueur.
          </p>
          <p className="m-3 p-3">
            Moncef Jouets s’oblige en outre, à prendre toutes les mesures nécessaires pour assurer la confidentialité du
            traitement des données à caractère personnel du Client et leur sécurité, et s’engage à ce que les données à
            caractère personnel du Client soient traitées loyalement et dans la limite nécessaire au regard des
            finalités pour lesquelles elles ont été collectées.
          </p>
          <p className="m-3 p-3">
            Le Client reconnait avoir été dûment informé de son droit d’accéder aux données à caractère personnel le
            concernant, de les modifier de s’opposer à leur traitement au sens de la loi organique n°2004-63 du 27
            juillet 2004, portant sur le traitement des données à caractère personnel. Le Client pourra saisir
            l’Instance nationale de protection des données personnelles pour tout litige afférent à l’exercice de ses
            droits conformément aux dispositions de la loi précitée.
          </p>
        </div>
      </SafeAreaView>
    </div>
  );
};

export default TermsPage;
