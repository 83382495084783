import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import userReducer from 'reducers/user';
import home from 'reducers/home';
import login from 'reducers/login';
import loading from 'reducers/loading';
import signUp from 'reducers/signUp';
import products from 'reducers/products';
import product from 'reducers/product';
import heroesReducer from 'reducers/heroes';
import heroSectionReducer from 'reducers/heroSection';
import categoriesReducer from 'reducers/categories';
import brandsReducer from 'reducers/brands';
import bestSellersReducer from 'reducers/bestSellers';
import auth from 'reducers/auth';
import checkout from 'reducers/checkout';
import loginModal from 'reducers/loginModal';

export default combineReducers({
  loginModal,
  loading,
  home,
  login,
  form,
  signUp,
  userReducer,
  products,
  product,
  categoriesReducer,
  heroesReducer,
  brandsReducer,
  auth,
  bestSellersReducer,
  checkout,
  heroSectionReducer,
});
