import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Modal from 'components/Modal';
import { closeLoginModal } from 'actions/loginModal';
import { login, loginFacebook, loginGoogle } from 'actions/auth';
import { isValidName, validateFullName } from 'helpers/validateName';
import SecondLoginForm from 'containers/SecondLoginForm';
import LoginForm from '../LoginForm';

export const LoginModal = () => {
  const { isLoginModalOpen, isLogged } = useSelector(({ loginModal, auth }) => ({ ...loginModal, ...auth }));
  const [initialValues, setInitialValues] = useState({});
  const [isFacebookLogin, setIsFacebookLogin] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isSecondStepOpen, setIsSecondStepOpen] = useState(false);
  const handleSubmit = values => {
    dispatch(login(values, history));
  };
  const responseFacebook = (e = {}) => {
    setIsFacebookLogin(true);
    const values = {
      name: e.name,
      email: e.email,
    };

    if (!isValidName) {
      setInitialValues(values);
      setIsSecondStepOpen(true);
    } else dispatch(loginFacebook(values, history));
  };
  const responseGoogle = e => {
    const values = {
      firstName: e.profileObj.familyName,
      lastName: e.profileObj.givenName,
      email: e.profileObj.email,
    };

    if (!validateFullName(values)) {
      setInitialValues(values);
      setIsSecondStepOpen(true);
    }
    dispatch(loginGoogle(values, history));
  };
  const handleSecondLogin = values => {
    if (isFacebookLogin) dispatch(loginFacebook(values, history));
    else {
      dispatch(loginGoogle(values, history));
    }
  };

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={isSecondStepOpen && !isLogged}
        closeModal={() => setIsSecondStepOpen(false)}
      >
        <SecondLoginForm
          initialValues={initialValues}
          isNameOnly={!initialValues.firstName}
          onSubmit={handleSecondLogin}
        />
      </Modal>
      <Modal
        className="modal-dialog-centered"
        isOpen={isLoginModalOpen && !isLogged && !isSecondStepOpen}
        closeModal={() => dispatch(closeLoginModal())}
      >
        <LoginForm onSubmit={handleSubmit} responseFacebook={responseFacebook} responseGoogle={responseGoogle} />
      </Modal>
    </>
  );
};
export default LoginModal;
