/* eslint-disable no-extra-parens */
import React from 'react';
import ImageAsyncComponent from 'components/ImageAsyncComponent';
import { getImageUrl } from 'helpers/getImageUrl';
import ProductCartQuantity from 'components/ProductCartQuantity';
import formatPrice from 'helpers/formatPrice';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCart } from 'actions/user';
import PropTypes from 'prop-types';
import { SET_QUANTITY } from 'constants/types/carts';
import { clientApi } from 'api';
import { slugifyString } from 'helpers/slugifyString';
import { Link } from 'react-router-dom';

const CartItem = ({ product }) => {
  const _loading = useSelector(({ loading }) => loading.loading);
  const disptach = useDispatch();
  const { promotion } = useSelector(({ checkout }) => checkout);
  const handleQuanityChange = value => {
    disptach({ type: SET_QUANTITY, payload: { id: product.id, quantity: value } });
    clientApi.put(`/carts/${product.id}`, { quantity: value });
  };
  const deleteFromCart = () => {
    disptach(deleteCart(product.id));
  };

  return (
    product.product && (
      <div className="media mb-5 mb-md-10">
        <div className="max-w-15rem w-100 mr-3">
          <ImageAsyncComponent image={getImageUrl(product?.product?.eanId.trim())} />
        </div>
        <div className="media-body">
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0 m-auto">
              {product?.product?.hasNoDiscount ? (
                <span className="badge badge-warning badge-pill text-white">Hors promo</span>
              ) : (
                ''
              )}
              <Link className="h5 d-block" to={`/products/${slugifyString(product.product.title)}`}>
                {product?.product?.title || ''}
              </Link>
            </div>
            <div className="col-md-3">
              <div className="row">
                <div className="col-auto m-auto">
                  <ProductCartQuantity
                    onChange={handleQuanityChange}
                    quantity={product.quantity}
                    maxQuantity={product?.product?.quantity}
                  />
                </div>
              </div>
            </div>
            <div className="col-4 col-md-2 d-none d-flex flex-row m-auto">
              {!product?.product?.hasNoDiscount ? (
                <>
                  <del>{formatPrice(product?.product?.price * product.quantity, 'TND')}</del>
                  <span className="h5 d-block m-1">
                    {formatPrice(
                      product?.product?.price * product.quantity -
                        product?.product?.price * (promotion.promotion.discountPerc / 100) * product.quantity,
                      'TND',
                    )}
                  </span>
                </>
              ) : (
                <span className="h5 d-block mb-1 mr-2">
                  {formatPrice(product?.product?.price * product.quantity, 'TND')}
                </span>
              )}
            </div>
            <div className="col-1 d-flex align-items-center justify-content-center">
              <button
                type="button"
                disabled={_loading}
                onClick={deleteFromCart}
                className="btn d-block text-body font-size-1 mb-1"
                href="/"
              >
                <div className="col-auto pl-0">
                  <i className="far fa-trash-alt text-hover-primary mr-1" />
                  <span className="font-size-1 text-hover-primary" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

CartItem.propTypes = {
  product: PropTypes.object.isRequired,
};
export default CartItem;
