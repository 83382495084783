import formatPathTitle from './formatpathTitle';

/* eslint-disable no-cond-assign */
const formatPaths = pathName => {
  const pathNameArray = pathName.split('/');

  pathNameArray[0] = '/';
  const formatedPaths = [];

  let link = '';

  pathNameArray.forEach(element => {
    link += element;
    formatedPaths.push({ title: formatPathTitle(element), link });
  });

  return formatedPaths;
};

export default formatPaths;
