import { clientApi, serverApi } from 'api';

export const checkoutService = (isServer = false) => async values => {
  const api = isServer ? serverApi : clientApi;

  const res = await api.post('/transactions', values);

  return res;
};

export const promotionService = (isServer = false) => async (code = 'undefined') => {
  const api = isServer ? serverApi : clientApi;

  const res = await api.get(`/promotions/${code}`);

  return res;
};
