/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-lines */
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import googlePlayLogo from 'styles/svg/mobileapp/google-play-badge.svg';
import appStoreLogo from 'styles/svg/mobileapp/app-store-badge.svg';

const Footer = () => {
  return (
    <>
      <footer className="bg-navy">
        <div className="container">
          <div className="space-top-2 space-bottom-1 space-bottom-lg-2">
            <div className="row justify-content-lg-between">
              <div className="col-lg-3 ml-lg-auto mb-5 mb-lg-0 d-flex justify-content-center align-items-center">
                <div className="m-4">
                  <Link to="/" aria-label="Front">
                    <img className="brand" src="/assets/images/logo1.png" alt="Logo" />
                  </Link>
                </div>

                <ul className="nav nav-sm nav-x-0 nav-white flex-column">
                  <li className="nav-item">
                    <a
                      className="nav-link media"
                      href="https://www.google.com/maps/place/Moncef+Jouets/@36.8753169,10.3081337,15z/data=!4m5!3m4!1s0x0:0x674b95b7bcc95fcb!8m2!3d36.8753169!4d10.3081337"
                    >
                      <span className="media">
                        <span className="fas fa-location-arrow mt-1 mr-2" />
                        <span className="media-body"> Espace Perle Bleu, 5, Km5 Route du Gammarth, Marsa 2078</span>
                      </span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link media"
                      href="https://www.google.com/maps/place/Moncef+Jouets/@36.8753169,10.3081337,15z/data=!4m5!3m4!1s0x0:0x674b95b7bcc95fcb!8m2!3d36.8753169!4d10.3081337"
                    >
                      <span className="media">
                        <span className="fas fa-phone-alt mt-1 mr-2" />
                        <span className="media-body">(+216) 26284898</span>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-6 col-md-3 col-lg mb-5 mb-lg-0 d-flex justify-content-center align-items-center">
                <Link to="/products">
                  <h5 className="text-white">Liste des produits</h5>
                </Link>
              </div>
              <div className="col-6 col-md-3 col-lg mb-5 mb-lg-0 d-flex justify-content-center align-items-center">
                <Link to="/categories">
                  <h5 className="text-white">Les catégories</h5>
                </Link>
              </div>

              <div className="col-6 col-md-3 col-lg d-flex justify-content-center align-items-center">
                <Link to="/heroes">
                  <h5 className="text-white">Les héros</h5>
                </Link>
              </div>
              <div className="col-6 col-md-3 col-lg d-flex justify-content-center align-items-center">
                <Link to="/brands">
                  <h5 className="text-white">Les marques</h5>
                </Link>
              </div>
            </div>
          </div>
          <hr className="opacity-xs my-0" />
          <div className="space-1">
            <div className="row align-items-md-center mb-7">
              <div className="col-md-4 mb-4 mb-md-0">
                <ul className="nav nav-sm nav-white nav-x-sm align-items-center">
                  <Link to="/privacy-policy">
                    <li className="nav-item">
                      <div className="nav-link" href="#">
                        Privacy &amp; Policy
                      </div>
                    </li>
                  </Link>
                  <li className="nav-item opacity mx-3">/</li>
                  <Link to="/terms">
                    <li className="nav-item">
                      <div className="nav-link" href="#">
                        Terms
                      </div>
                    </li>
                  </Link>
                </ul>
              </div>
              <div className="col-md-4 ">
                <div className="center-block text-center">
                  <h5 className="text-white">Téléchargez notre application gratuite</h5>
                  <a href="https://apps.apple.com/tn/app/moncef-jouets/id1552190219" target="_blank">
                    <img src={appStoreLogo} alt="AppStore Logo" width="150" className="mr-2" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.moncefjouets" target="_blank">
                    <img src={googlePlayLogo} alt="GooglePlay Logo" width="150" />
                  </a>
                </div>
              </div>
              <div className="col-md-4 text-md-right">
                <ul className="list-inline mb-0">
                  <li className="list-inline-item">
                    <a
                      className="btn btn-xs btn-icon btn-soft-light"
                      href="https://www.facebook.com/moncefjouets"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      className="btn btn-xs btn-icon btn-soft-light"
                      href="https://www.instagram.com/moncefjouets"
                      target="_blank"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <a href="https://www.edonec.com/" target="_blank">
              <div className="w-md-75 text-lg-center mx-lg-auto">
                <p className="text-white opacity-sm small">© eDonec {moment().format('YYYY')}.</p>
                <p className="text-white opacity-sm small">Made with love by eDonec.</p>
              </div>
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
