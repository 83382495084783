/* eslint-disable max-lines */
export const Tunisia = [
  {
    govLabel: 'ARIANA',
    govId: 1,
    villes: [
      {
        ville: 5,
        villeName: 'ARIANA VILLE',
        cites: [
          {
            IDN3: 134,
            LIBELLEN3: 'ARIANA',
            IDN2: 5,
            CODEP: '2080',
          },
          {
            IDN3: 109,
            LIBELLEN3: 'BORJ EL BACCOUCHE',
            IDN2: 5,
            CODEP: '2027',
          },
          {
            IDN3: 110,
            LIBELLEN3: 'CENTRE COMMERCIAL IKRAM',
            IDN2: 5,
            CODEP: '2037',
          },
          {
            IDN3: 117,
            LIBELLEN3: 'CITE BELVEDERE 2',
            IDN2: 5,
            CODEP: '2091',
          },
          {
            IDN3: 114,
            LIBELLEN3: 'CITE BORJ TURKI 1',
            IDN2: 5,
            CODEP: '2058',
          },
          {
            IDN3: 126,
            LIBELLEN3: 'CITE BORJ TURKI 2',
            IDN2: 5,
            CODEP: '2058',
          },
          {
            IDN3: 130,
            LIBELLEN3: 'CITE DE LA SANTE',
            IDN2: 5,
            CODEP: '2058',
          },
          {
            IDN3: 135,
            LIBELLEN3: 'CITE DES ROSES',
            IDN2: 5,
            CODEP: '2080',
          },
          {
            IDN3: 136,
            LIBELLEN3: 'CITE DU JARDIN',
            IDN2: 5,
            CODEP: '2080',
          },
          {
            IDN3: 137,
            LIBELLEN3: 'CITE DU PRINTEMPS',
            IDN2: 5,
            CODEP: '2080',
          },
          {
            IDN3: 118,
            LIBELLEN3: 'CITE DU SOLEIL',
            IDN2: 5,
            CODEP: '2091',
          },
          {
            IDN3: 119,
            LIBELLEN3: 'CITE EL INTISSAR 1',
            IDN2: 5,
            CODEP: '2091',
          },
          {
            IDN3: 120,
            LIBELLEN3: 'CITE EL INTISSAR 2',
            IDN2: 5,
            CODEP: '2091',
          },
          {
            IDN3: 113,
            LIBELLEN3: 'CITE ENNASR 1',
            IDN2: 5,
            CODEP: '2037',
          },
          {
            IDN3: 141,
            LIBELLEN3: 'CITE ENNASR 2',
            IDN2: 5,
            CODEP: '2037',
          },
          {
            IDN3: 138,
            LIBELLEN3: 'CITE ENNOUZHA',
            IDN2: 5,
            CODEP: '2080',
          },
          {
            IDN3: 139,
            LIBELLEN3: 'CITE ESSAADA (ARIANA)',
            IDN2: 5,
            CODEP: null,
          },
          {
            IDN3: 132,
            LIBELLEN3: 'CITE ESSAADA (RIADH ANDALOUS)',
            IDN2: 5,
            CODEP: null,
          },
          {
            IDN3: 140,
            LIBELLEN3: 'CITE JAAFAR',
            IDN2: 5,
            CODEP: '2080',
          },
          {
            IDN3: 121,
            LIBELLEN3: 'CITE JAMIL',
            IDN2: 5,
            CODEP: '2091',
          },
          {
            IDN3: 124,
            LIBELLEN3: 'CITE KARIM',
            IDN2: 5,
            CODEP: '2058',
          },
          {
            IDN3: 125,
            LIBELLEN3: 'CITE MEHRZIA 1',
            IDN2: 5,
            CODEP: '2058',
          },
          {
            IDN3: 122,
            LIBELLEN3: 'EL MENZAH 5',
            IDN2: 5,
            CODEP: '2091',
          },
          {
            IDN3: 123,
            LIBELLEN3: 'EL MENZAH 6',
            IDN2: 5,
            CODEP: '2091',
          },
          {
            IDN3: 142,
            LIBELLEN3: 'EL MENZAH 7',
            IDN2: 5,
            CODEP: '2037',
          },
          {
            IDN3: 143,
            LIBELLEN3: 'EL MENZAH 8',
            IDN2: 5,
            CODEP: '2037',
          },
          {
            IDN3: 115,
            LIBELLEN3: 'NOUVELLE ARIANA',
            IDN2: 5,
            CODEP: '2080',
          },
          {
            IDN3: 127,
            LIBELLEN3: 'RESIDENCE EL OUNS',
            IDN2: 5,
            CODEP: '2058',
          },
          {
            IDN3: 112,
            LIBELLEN3: 'RESIDENCE ENNOUR ( NASER 1)',
            IDN2: 5,
            CODEP: null,
          },
          {
            IDN3: 111,
            LIBELLEN3: 'RESIDENCE ENNOUR ( NASER 2)',
            IDN2: 5,
            CODEP: null,
          },
          {
            IDN3: 116,
            LIBELLEN3: 'RESIDENCE ENNOUR (ARIANA)',
            IDN2: 5,
            CODEP: null,
          },
          {
            IDN3: 128,
            LIBELLEN3: 'RESIDENCE IBN ZEIDOUN',
            IDN2: 5,
            CODEP: '2058',
          },
          {
            IDN3: 129,
            LIBELLEN3: 'RESIDENCE ICHBILIA',
            IDN2: 5,
            CODEP: '2058',
          },
          {
            IDN3: 131,
            LIBELLEN3: 'RESIDENCE KORTOBA',
            IDN2: 5,
            CODEP: '2058',
          },
          {
            IDN3: 133,
            LIBELLEN3: 'RIADH LANDLOUS',
            IDN2: 5,
            CODEP: '2058',
          },
        ],
      },
      {
        ville: 8,
        villeName: 'ETTADHAMEN',
        cites: [
          {
            IDN3: 219,
            LIBELLEN3: 'CITE 18 JANVIER',
            IDN2: 8,
            CODEP: '2041',
          },
          {
            IDN3: 220,
            LIBELLEN3: 'CITE BEN YERFES',
            IDN2: 8,
            CODEP: '2041',
          },
          {
            IDN3: 224,
            LIBELLEN3: 'CITE EL HOUDA',
            IDN2: 8,
            CODEP: '2041',
          },
          {
            IDN3: 227,
            LIBELLEN3: 'CITE ENNASSER',
            IDN2: 8,
            CODEP: '2041',
          },
          {
            IDN3: 221,
            LIBELLEN3: 'CITE ETTADHAMEN',
            IDN2: 8,
            CODEP: '2041',
          },
          {
            IDN3: 222,
            LIBELLEN3: 'CITE ETTAYARENE',
            IDN2: 8,
            CODEP: '2041',
          },
          {
            IDN3: 223,
            LIBELLEN3: 'CITE SFAR',
            IDN2: 8,
            CODEP: '2041',
          },
          {
            IDN3: 225,
            LIBELLEN3: 'CITE SNIT 2',
            IDN2: 8,
            CODEP: '2041',
          },
          {
            IDN3: 226,
            LIBELLEN3: 'CITE SNIT NAGRA',
            IDN2: 8,
            CODEP: '2041',
          },
        ],
      },
      {
        ville: 7,
        villeName: 'KALAAT LANDLOUS',
        cites: [
          {
            IDN3: 212,
            LIBELLEN3: 'BORJ EL HADJ',
            IDN2: 7,
            CODEP: '2061',
          },
          {
            IDN3: 216,
            LIBELLEN3: 'CITE BIR EL ARAIES',
            IDN2: 7,
            CODEP: '2022',
          },
          {
            IDN3: 218,
            LIBELLEN3: 'CITE DES MARTYRS',
            IDN2: 7,
            CODEP: '2022',
          },
          {
            IDN3: 205,
            LIBELLEN3: 'CITE EL FEJJA',
            IDN2: 7,
            CODEP: '2022',
          },
          {
            IDN3: 206,
            LIBELLEN3: 'CITE EL MOUROUJ',
            IDN2: 7,
            CODEP: '2022',
          },
          {
            IDN3: 207,
            LIBELLEN3: 'CITE EL OULJA',
            IDN2: 7,
            CODEP: '2022',
          },
          {
            IDN3: 208,
            LIBELLEN3: 'CITE ESSIBOUS',
            IDN2: 7,
            CODEP: '2022',
          },
          {
            IDN3: 209,
            LIBELLEN3: 'CITE TAREK IBN ZIED',
            IDN2: 7,
            CODEP: '2022',
          },
          {
            IDN3: 213,
            LIBELLEN3: 'EL MISRA',
            IDN2: 7,
            CODEP: '2061',
          },
          {
            IDN3: 211,
            LIBELLEN3: 'EL NAHLI',
            IDN2: 7,
            CODEP: '2032',
          },
          {
            IDN3: 214,
            LIBELLEN3: 'FERME HABABOU',
            IDN2: 7,
            CODEP: '2061',
          },
          {
            IDN3: 204,
            LIBELLEN3: 'FERME MUSTAPHA',
            IDN2: 7,
            CODEP: '2061',
          },
          {
            IDN3: 215,
            LIBELLEN3: 'HENCHIR TOUBA',
            IDN2: 7,
            CODEP: '2061',
          },
          {
            IDN3: 217,
            LIBELLEN3: 'HENCHIR TOUBIAS',
            IDN2: 7,
            CODEP: '2061',
          },
          {
            IDN3: 210,
            LIBELLEN3: 'KALAAT LANDLOUS',
            IDN2: 7,
            CODEP: '2022',
          },
          {
            IDN3: 203,
            LIBELLEN3: 'PONT DE BIZERTE',
            IDN2: 7,
            CODEP: '2061',
          },
        ],
      },
      {
        ville: 6,
        villeName: 'LA SOUKRA',
        cites: [
          {
            IDN3: 156,
            LIBELLEN3: 'AEROPORT TUNIS CARTHAGE',
            IDN2: 6,
            CODEP: '2035',
          },
          {
            IDN3: 185,
            LIBELLEN3: 'BORJ LOUZIR',
            IDN2: 6,
            CODEP: '2073',
          },
          {
            IDN3: 161,
            LIBELLEN3: 'CHARGUIA 1',
            IDN2: 6,
            CODEP: '2035',
          },
          {
            IDN3: 162,
            LIBELLEN3: 'CHARGUIA 2',
            IDN2: 6,
            CODEP: '2035',
          },
          {
            IDN3: 164,
            LIBELLEN3: 'CHOTRANA 1',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 166,
            LIBELLEN3: 'CHOTRANA 2',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 147,
            LIBELLEN3: 'CHOTRANA 3',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 167,
            LIBELLEN3: 'CITE ASTREE',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 168,
            LIBELLEN3: 'CITE BELABLA',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 157,
            LIBELLEN3: 'CITE BEN HESSINE',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 186,
            LIBELLEN3: 'CITE BEN KILANI',
            IDN2: 6,
            CODEP: '2073',
          },
          {
            IDN3: 158,
            LIBELLEN3: 'CITE BOU FARES',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 165,
            LIBELLEN3: 'CITE CHOUACHIA',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 160,
            LIBELLEN3: 'CITE CHOUCHENE',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 187,
            LIBELLEN3: 'CITE DE LA SANTE',
            IDN2: 6,
            CODEP: '2073',
          },
          {
            IDN3: 144,
            LIBELLEN3: 'CITE DE LA TERRE LIBRE',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 145,
            LIBELLEN3: "CITE DE L'AEROPORT",
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 148,
            LIBELLEN3: 'CITE DES JUGES',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 146,
            LIBELLEN3: 'CITE EL AZZEF',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 188,
            LIBELLEN3: 'CITE EL BEHI LADGHEM',
            IDN2: 6,
            CODEP: '2073',
          },
          {
            IDN3: 149,
            LIBELLEN3: 'CITE EL BOUSTENE 1',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 150,
            LIBELLEN3: 'CITE EL BOUSTENE 2',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 189,
            LIBELLEN3: 'CITE EL FETH',
            IDN2: 6,
            CODEP: '2073',
          },
          {
            IDN3: 190,
            LIBELLEN3: 'CITE EL HANA',
            IDN2: 6,
            CODEP: '2073',
          },
          {
            IDN3: 151,
            LIBELLEN3: 'CITE EL HENAYA',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 152,
            LIBELLEN3: 'CITE EL MAID',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 191,
            LIBELLEN3: 'CITE EL MANSOURA',
            IDN2: 6,
            CODEP: '2073',
          },
          {
            IDN3: 192,
            LIBELLEN3: 'CITE EL OUROUD',
            IDN2: 6,
            CODEP: '2073',
          },
          {
            IDN3: 153,
            LIBELLEN3: 'CITE ELYES',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 193,
            LIBELLEN3: 'CITE ENNACIM',
            IDN2: 6,
            CODEP: '2073',
          },
          {
            IDN3: 194,
            LIBELLEN3: 'CITE ENNOUZHA 3',
            IDN2: 6,
            CODEP: '2073',
          },
          {
            IDN3: 154,
            LIBELLEN3: 'CITE ETTAAMIR',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 195,
            LIBELLEN3: 'CITE ETTAAMIR 5',
            IDN2: 6,
            CODEP: '2073',
          },
          {
            IDN3: 155,
            LIBELLEN3: 'CITE EZZITOUN 1',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 169,
            LIBELLEN3: 'CITE EZZITOUN 2',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 170,
            LIBELLEN3: 'CITE EZZOUAIDIA',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 171,
            LIBELLEN3: 'CITE FARHAT',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 172,
            LIBELLEN3: 'CITE FATEH',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 173,
            LIBELLEN3: 'CITE FONCIERE',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 196,
            LIBELLEN3: 'CITE GHOUZAILA',
            IDN2: 6,
            CODEP: '2073',
          },
          {
            IDN3: 197,
            LIBELLEN3: 'CITE HEDI NOUIRA',
            IDN2: 6,
            CODEP: '2073',
          },
          {
            IDN3: 198,
            LIBELLEN3: 'CITE JEBIRA',
            IDN2: 6,
            CODEP: '2073',
          },
          {
            IDN3: 174,
            LIBELLEN3: 'CITE SNIT',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 199,
            LIBELLEN3: 'CITE SNIT',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 200,
            LIBELLEN3: 'CITE STAR',
            IDN2: 6,
            CODEP: '2073',
          },
          {
            IDN3: 175,
            LIBELLEN3: 'CITE TOUILIA',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 176,
            LIBELLEN3: 'DAR FADHAL',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 159,
            LIBELLEN3: 'LA SOUKRA',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 177,
            LIBELLEN3: 'MOSQUE ERRAOUDHA',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 178,
            LIBELLEN3: 'RESIDENCE 26-26',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 179,
            LIBELLEN3: 'RESIDENCE AHLEM',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 201,
            LIBELLEN3: 'RESIDENCE EL OUNS',
            IDN2: 6,
            CODEP: '2073',
          },
          {
            IDN3: 202,
            LIBELLEN3: 'RESIDENCE ENNESRINE',
            IDN2: 6,
            CODEP: '2073',
          },
          {
            IDN3: 180,
            LIBELLEN3: 'RESIDENCE MERIEM',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 181,
            LIBELLEN3: 'SIDI FREJ',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 182,
            LIBELLEN3: 'SIDI SALAH',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 183,
            LIBELLEN3: 'SIDI SOUFIENE',
            IDN2: 6,
            CODEP: '2036',
          },
          {
            IDN3: 163,
            LIBELLEN3: 'STATION MARCHANDISES FRET',
            IDN2: 6,
            CODEP: '2035',
          },
          {
            IDN3: 184,
            LIBELLEN3: 'VILLAGE ESSAHLI',
            IDN2: 6,
            CODEP: '2036',
          },
        ],
      },
      {
        ville: 9,
        villeName: 'MNIHLA',
        cites: [
          {
            IDN3: 4883,
            LIBELLEN3: 'CITE ALI BOURGUIBA',
            IDN2: 9,
            CODEP: '2094',
          },
          {
            IDN3: 4884,
            LIBELLEN3: 'CITE DE LA REPUBLIQUE',
            IDN2: 9,
            CODEP: '2094',
          },
          {
            IDN3: 4885,
            LIBELLEN3: 'CITE EL BASSATINE1',
            IDN2: 9,
            CODEP: '2094',
          },
          {
            IDN3: 4886,
            LIBELLEN3: 'CITE EL BASSATINE2',
            IDN2: 9,
            CODEP: '2094',
          },
          {
            IDN3: 4887,
            LIBELLEN3: 'CITE EL BASSATINE3',
            IDN2: 9,
            CODEP: '2094',
          },
          {
            IDN3: 4888,
            LIBELLEN3: 'CITE EL GOUABSIA',
            IDN2: 9,
            CODEP: '2094',
          },
          {
            IDN3: 4889,
            LIBELLEN3: 'CITE EL OUARD',
            IDN2: 9,
            CODEP: '2094',
          },
          {
            IDN3: 4890,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 9,
            CODEP: '2094',
          },
          {
            IDN3: 4891,
            LIBELLEN3: 'CITE ESSAADA',
            IDN2: 9,
            CODEP: '2094',
          },
          {
            IDN3: 4892,
            LIBELLEN3: 'CITE RAFAHA',
            IDN2: 9,
            CODEP: '2094',
          },
          {
            IDN3: 4893,
            LIBELLEN3: 'CITE ROUS EL HARAYEK',
            IDN2: 9,
            CODEP: '2094',
          },
          {
            IDN3: 4894,
            LIBELLEN3: 'ESSANHAJI',
            IDN2: 9,
            CODEP: '2094',
          },
          {
            IDN3: 4895,
            LIBELLEN3: 'MNIHLA',
            IDN2: 9,
            CODEP: '2094',
          },
          {
            IDN3: 4896,
            LIBELLEN3: 'RESIDENCE ENNASR',
            IDN2: 9,
            CODEP: '2094',
          },
        ],
      },
      {
        ville: 3,
        villeName: 'RAOUED',
        cites: [
          {
            IDN3: 57,
            LIBELLEN3: 'ARIANA ESSOUGHRA',
            IDN2: 3,
            CODEP: '2081',
          },
          {
            IDN3: 59,
            LIBELLEN3: 'BORJ TOUIL',
            IDN2: 3,
            CODEP: '2081',
          },
          {
            IDN3: 32,
            LIBELLEN3: 'BOU HNECH',
            IDN2: 3,
            CODEP: '2081',
          },
          {
            IDN3: 75,
            LIBELLEN3: 'CITE 7 NOVEMBRE',
            IDN2: 3,
            CODEP: '2056',
          },
          {
            IDN3: 76,
            LIBELLEN3: 'CITE AEROPORT',
            IDN2: 3,
            CODEP: '2056',
          },
          {
            IDN3: 33,
            LIBELLEN3: 'CITE CHAKER',
            IDN2: 3,
            CODEP: '2081',
          },
          {
            IDN3: 49,
            LIBELLEN3: 'CITE CHARGUI',
            IDN2: 3,
            CODEP: '2056',
          },
          {
            IDN3: 34,
            LIBELLEN3: 'CITE DE LA MOSQUE',
            IDN2: 3,
            CODEP: '2081',
          },
          {
            IDN3: 63,
            LIBELLEN3: 'CITE DE LA RTT 2',
            IDN2: 3,
            CODEP: '2083',
          },
          {
            IDN3: 50,
            LIBELLEN3: 'CITE DES JUGES',
            IDN2: 3,
            CODEP: '2056',
          },
          {
            IDN3: 64,
            LIBELLEN3: 'CITE EL ABADLA',
            IDN2: 3,
            CODEP: '2083',
          },
          {
            IDN3: 65,
            LIBELLEN3: 'CITE EL AMEL',
            IDN2: 3,
            CODEP: '2083',
          },
          {
            IDN3: 66,
            LIBELLEN3: 'CITE EL GHAZALA 1',
            IDN2: 3,
            CODEP: '2083',
          },
          {
            IDN3: 67,
            LIBELLEN3: 'CITE EL GHAZALA 2',
            IDN2: 3,
            CODEP: '2083',
          },
          {
            IDN3: 35,
            LIBELLEN3: 'CITE EL MOUAOUIET',
            IDN2: 3,
            CODEP: '2081',
          },
          {
            IDN3: 36,
            LIBELLEN3: 'CITE EL MOUNTAZEH',
            IDN2: 3,
            CODEP: '2081',
          },
          {
            IDN3: 68,
            LIBELLEN3: 'CITE EL WAFA',
            IDN2: 3,
            CODEP: '2083',
          },
          {
            IDN3: 51,
            LIBELLEN3: 'CITE EL YAMAMA',
            IDN2: 3,
            CODEP: '2056',
          },
          {
            IDN3: 69,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 3,
            CODEP: '2083',
          },
          {
            IDN3: 70,
            LIBELLEN3: 'CITE ENNKHILET',
            IDN2: 3,
            CODEP: '2083',
          },
          {
            IDN3: 37,
            LIBELLEN3: 'CITE ENNOUR JAAFAR ',
            IDN2: 3,
            CODEP: '2081',
          },
          {
            IDN3: 38,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 3,
            CODEP: '2081',
          },
          {
            IDN3: 39,
            LIBELLEN3: 'CITE ESSAADA',
            IDN2: 3,
            CODEP: '2081',
          },
          {
            IDN3: 71,
            LIBELLEN3: 'CITE ESSAHAFA',
            IDN2: 3,
            CODEP: '2083',
          },
          {
            IDN3: 72,
            LIBELLEN3: 'CITE IBN RACHIK',
            IDN2: 3,
            CODEP: '2083',
          },
          {
            IDN3: 73,
            LIBELLEN3: 'CITE MEHRZIA 2',
            IDN2: 3,
            CODEP: '2083',
          },
          {
            IDN3: 52,
            LIBELLEN3: 'CITE SIDI SALAH',
            IDN2: 3,
            CODEP: '2056',
          },
          {
            IDN3: 40,
            LIBELLEN3: 'CITE SIDI SLIMENE',
            IDN2: 3,
            CODEP: '2081',
          },
          {
            IDN3: 41,
            LIBELLEN3: 'CITE SNIT',
            IDN2: 3,
            CODEP: '2081',
          },
          {
            IDN3: 53,
            LIBELLEN3: 'CITE ZGHAB',
            IDN2: 3,
            CODEP: '2056',
          },
          {
            IDN3: 58,
            LIBELLEN3: 'COMPLEXE TECHNOLOGIQUE',
            IDN2: 3,
            CODEP: '2088',
          },
          {
            IDN3: 42,
            LIBELLEN3: 'DOUAR EL HENDI',
            IDN2: 3,
            CODEP: '2081',
          },
          {
            IDN3: 54,
            LIBELLEN3: 'EL BRARJA',
            IDN2: 3,
            CODEP: '2056',
          },
          {
            IDN3: 43,
            LIBELLEN3: 'EL HESSIENE',
            IDN2: 3,
            CODEP: '2081',
          },
          {
            IDN3: 56,
            LIBELLEN3: 'JAAFAR 1',
            IDN2: 3,
            CODEP: '2083',
          },
          {
            IDN3: 74,
            LIBELLEN3: 'JAAFAR 2',
            IDN2: 3,
            CODEP: '2083',
          },
          {
            IDN3: 44,
            LIBELLEN3: 'OUED EL KHAYAT',
            IDN2: 3,
            CODEP: '2081',
          },
          {
            IDN3: 45,
            LIBELLEN3: 'OUED EL MAKHZEN',
            IDN2: 3,
            CODEP: '2081',
          },
          {
            IDN3: 55,
            LIBELLEN3: 'RAOUED',
            IDN2: 3,
            CODEP: '2056',
          },
          {
            IDN3: 46,
            LIBELLEN3: 'RESIDENCE EL AYECH',
            IDN2: 3,
            CODEP: '2081',
          },
          {
            IDN3: 47,
            LIBELLEN3: 'RESIDENCE EL OUNS',
            IDN2: 3,
            CODEP: '2081',
          },
          {
            IDN3: 48,
            LIBELLEN3: 'RESIDENCE ENNARJES',
            IDN2: 3,
            CODEP: '2081',
          },
          {
            IDN3: 60,
            LIBELLEN3: 'RESIDENCE ESSALEM',
            IDN2: 3,
            CODEP: '2081',
          },
          {
            IDN3: 61,
            LIBELLEN3: 'RESIDENCE RIM',
            IDN2: 3,
            CODEP: '2081',
          },
          {
            IDN3: 62,
            LIBELLEN3: 'ROUTE EZZAOUIA',
            IDN2: 3,
            CODEP: '2081',
          },
        ],
      },
      {
        ville: 4,
        villeName: 'SIDI THABET',
        cites: [
          {
            IDN3: 106,
            LIBELLEN3: 'BEJAOUA 2',
            IDN2: 4,
            CODEP: '2020',
          },
          {
            IDN3: 86,
            LIBELLEN3: 'BORJ EL KHOUKHA',
            IDN2: 4,
            CODEP: '2032',
          },
          {
            IDN3: 87,
            LIBELLEN3: 'BORJ YOUSSEF',
            IDN2: 4,
            CODEP: '2032',
          },
          {
            IDN3: 88,
            LIBELLEN3: 'CEBALET BEN AMMAR',
            IDN2: 4,
            CODEP: '2032',
          },
          {
            IDN3: 101,
            LIBELLEN3: 'CHORFECH',
            IDN2: 4,
            CODEP: '2057',
          },
          {
            IDN3: 89,
            LIBELLEN3: 'CHORFECH 12',
            IDN2: 4,
            CODEP: '2032',
          },
          {
            IDN3: 102,
            LIBELLEN3: 'CHORFECH 2',
            IDN2: 4,
            CODEP: '2057',
          },
          {
            IDN3: 103,
            LIBELLEN3: 'CHORFECH 24',
            IDN2: 4,
            CODEP: '2057',
          },
          {
            IDN3: 104,
            LIBELLEN3: 'CHORFECH 8',
            IDN2: 4,
            CODEP: '2057',
          },
          {
            IDN3: 107,
            LIBELLEN3: 'CITE 18 JANVIER',
            IDN2: 4,
            CODEP: '2020',
          },
          {
            IDN3: 108,
            LIBELLEN3: 'CITE AFH',
            IDN2: 4,
            CODEP: '2020',
          },
          {
            IDN3: 105,
            LIBELLEN3: 'CITE DES AGRICULTEURS',
            IDN2: 4,
            CODEP: '2020',
          },
          {
            IDN3: 77,
            LIBELLEN3: 'CITE DES ORANGES',
            IDN2: 4,
            CODEP: '2020',
          },
          {
            IDN3: 90,
            LIBELLEN3: 'CITE DRIDI',
            IDN2: 4,
            CODEP: '2032',
          },
          {
            IDN3: 91,
            LIBELLEN3: 'CITE EL BOKRI',
            IDN2: 4,
            CODEP: '2032',
          },
          {
            IDN3: 92,
            LIBELLEN3: 'CITE EL FRACHICH',
            IDN2: 4,
            CODEP: '2032',
          },
          {
            IDN3: 93,
            LIBELLEN3: 'CITE EL GHRIBI',
            IDN2: 4,
            CODEP: '2032',
          },
          {
            IDN3: 78,
            LIBELLEN3: 'CITE EL GOUAZINE',
            IDN2: 4,
            CODEP: '2020',
          },
          {
            IDN3: 79,
            LIBELLEN3: 'CITE EL MBARKA',
            IDN2: 4,
            CODEP: '2020',
          },
          {
            IDN3: 94,
            LIBELLEN3: 'CITE EL METHALITHE',
            IDN2: 4,
            CODEP: '2032',
          },
          {
            IDN3: 80,
            LIBELLEN3: 'CITE EL MREZGUA',
            IDN2: 4,
            CODEP: '2020',
          },
          {
            IDN3: 81,
            LIBELLEN3: 'CITE ESSAADA',
            IDN2: 4,
            CODEP: '2020',
          },
          {
            IDN3: 82,
            LIBELLEN3: 'CITE ETTBAIEB',
            IDN2: 4,
            CODEP: '2020',
          },
          {
            IDN3: 83,
            LIBELLEN3: 'CITE JARDINS',
            IDN2: 4,
            CODEP: '2020',
          },
          {
            IDN3: 95,
            LIBELLEN3: 'CITE MESSOUDA',
            IDN2: 4,
            CODEP: '2032',
          },
          {
            IDN3: 84,
            LIBELLEN3: 'CITE MONGI SLIM',
            IDN2: 4,
            CODEP: '2020',
          },
          {
            IDN3: 96,
            LIBELLEN3: 'CITE OUVRIERE',
            IDN2: 4,
            CODEP: '2032',
          },
          {
            IDN3: 97,
            LIBELLEN3: 'CITE SIDI MAROUANE',
            IDN2: 4,
            CODEP: '2032',
          },
          {
            IDN3: 98,
            LIBELLEN3: 'EZZAOUIA',
            IDN2: 4,
            CODEP: '2032',
          },
          {
            IDN3: 99,
            LIBELLEN3: 'JABBES',
            IDN2: 4,
            CODEP: '2032',
          },
          {
            IDN3: 100,
            LIBELLEN3: 'JABBES EL BORJ',
            IDN2: 4,
            CODEP: '2032',
          },
          {
            IDN3: 85,
            LIBELLEN3: 'SIDI THABET',
            IDN2: 4,
            CODEP: '2020',
          },
        ],
      },
    ],
  },
  {
    govLabel: 'Beja',
    govId: 2,
    villes: [
      {
        ville: 90,
        villeName: 'AMDOUN',
        cites: [
          {
            IDN3: 410,
            LIBELLEN3: 'ADAILIA',
            IDN2: 90,
            CODEP: '9030',
          },
          {
            IDN3: 411,
            LIBELLEN3: 'AIN EL GOUSSA',
            IDN2: 90,
            CODEP: '9030',
          },
          {
            IDN3: 412,
            LIBELLEN3: 'AIN GHENEM',
            IDN2: 90,
            CODEP: '9030',
          },
          {
            IDN3: 405,
            LIBELLEN3: 'BOURIS',
            IDN2: 90,
            CODEP: '9030',
          },
          {
            IDN3: 406,
            LIBELLEN3: 'CITE DE LA POSTE',
            IDN2: 90,
            CODEP: '9030',
          },
          {
            IDN3: 407,
            LIBELLEN3: 'CITE ERRAOUDHA',
            IDN2: 90,
            CODEP: '9030',
          },
          {
            IDN3: 408,
            LIBELLEN3: 'CITE HACHED',
            IDN2: 90,
            CODEP: '9030',
          },
          {
            IDN3: 409,
            LIBELLEN3: 'CITE TAIEB MHIRI',
            IDN2: 90,
            CODEP: '9030',
          },
          {
            IDN3: 413,
            LIBELLEN3: 'EDDGHABJIA',
            IDN2: 90,
            CODEP: '9030',
          },
          {
            IDN3: 414,
            LIBELLEN3: 'EDHHIRAT',
            IDN2: 90,
            CODEP: '9030',
          },
          {
            IDN3: 430,
            LIBELLEN3: 'EL AYAYDA',
            IDN2: 90,
            CODEP: '9084',
          },
          {
            IDN3: 415,
            LIBELLEN3: 'EL FRAIJIA',
            IDN2: 90,
            CODEP: '9030',
          },
          {
            IDN3: 416,
            LIBELLEN3: 'EL GHORFA',
            IDN2: 90,
            CODEP: '9030',
          },
          {
            IDN3: 417,
            LIBELLEN3: 'EL GRAIA',
            IDN2: 90,
            CODEP: '9030',
          },
          {
            IDN3: 418,
            LIBELLEN3: 'EL HLIKAT',
            IDN2: 90,
            CODEP: '9030',
          },
          {
            IDN3: 431,
            LIBELLEN3: 'EL HOMR',
            IDN2: 90,
            CODEP: '9084',
          },
          {
            IDN3: 419,
            LIBELLEN3: 'EL JOUZA',
            IDN2: 90,
            CODEP: '9030',
          },
          {
            IDN3: 420,
            LIBELLEN3: 'EL MJALES',
            IDN2: 90,
            CODEP: '9030',
          },
          {
            IDN3: 421,
            LIBELLEN3: 'GAAT EL MOUHDI',
            IDN2: 90,
            CODEP: '9030',
          },
          {
            IDN3: 422,
            LIBELLEN3: 'GHOUZIA',
            IDN2: 90,
            CODEP: '9030',
          },
          {
            IDN3: 423,
            LIBELLEN3: 'MAGHRAOUA',
            IDN2: 90,
            CODEP: '9030',
          },
          {
            IDN3: 432,
            LIBELLEN3: 'MENZEL EL GOURCHI',
            IDN2: 90,
            CODEP: '9084',
          },
          {
            IDN3: 424,
            LIBELLEN3: 'MENZEL HAMMED',
            IDN2: 90,
            CODEP: '9030',
          },
          {
            IDN3: 425,
            LIBELLEN3: 'REMADHNIA',
            IDN2: 90,
            CODEP: '9030',
          },
          {
            IDN3: 426,
            LIBELLEN3: 'SIDI MBAREK',
            IDN2: 90,
            CODEP: '9030',
          },
          {
            IDN3: 427,
            LIBELLEN3: 'SIDI SAADOUN',
            IDN2: 90,
            CODEP: '9030',
          },
          {
            IDN3: 428,
            LIBELLEN3: 'SOBAH',
            IDN2: 90,
            CODEP: '9030',
          },
          {
            IDN3: 429,
            LIBELLEN3: 'ZAHRET MEDIENE',
            IDN2: 90,
            CODEP: '9030',
          },
        ],
      },
      {
        ville: 86,
        villeName: 'BEJA NORD',
        cites: [
          {
            IDN3: 249,
            LIBELLEN3: 'AIN KSAR HADID',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 250,
            LIBELLEN3: 'BEJA',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 251,
            LIBELLEN3: 'BOU KESLANE',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 252,
            LIBELLEN3: 'CHARCHARA',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 253,
            LIBELLEN3: 'CITE AFH',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 255,
            LIBELLEN3: 'CITE AIN BAZAZ',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 256,
            LIBELLEN3: 'CITE AIN CHEMES',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 262,
            LIBELLEN3: 'CITE AIN EL GHOULA',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 300,
            LIBELLEN3: 'CITE BEJA EL JADIDA',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 301,
            LIBELLEN3: 'CITE BELLE VUE',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 302,
            LIBELLEN3: 'CITE BIR HOFRA',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 248,
            LIBELLEN3: 'CITE BOUTRAA',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 244,
            LIBELLEN3: 'CITE CNRPS',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 245,
            LIBELLEN3: 'CITE DE LA POLICE',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 228,
            LIBELLEN3: 'CITE DE LA SANTE 1',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 229,
            LIBELLEN3: 'CITE DE LA SANTE 2',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 230,
            LIBELLEN3: 'CITE DES MIMOSAS',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 231,
            LIBELLEN3: 'CITE DES PROFFESSEURS',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 263,
            LIBELLEN3: 'CITE DES ROSES',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 264,
            LIBELLEN3: 'CITE DHAMENE',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 265,
            LIBELLEN3: 'CITE DU GOUVERNORAT',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 266,
            LIBELLEN3: 'CITE ECHIFA',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 267,
            LIBELLEN3: 'CITE ECONOMIE DU NORD',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 268,
            LIBELLEN3: 'CITE EDHAHBIA 1',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 269,
            LIBELLEN3: 'CITE EDHAHBIA 2',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 270,
            LIBELLEN3: 'CITE EL BARAKA',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 271,
            LIBELLEN3: 'CITE EL BIADHA',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 272,
            LIBELLEN3: 'CITE EL BOUKHARI',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 273,
            LIBELLEN3: 'CITE EL BOUSTENE',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 274,
            LIBELLEN3: 'CITE EL HABIB',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 275,
            LIBELLEN3: 'CITE EL HABIBIA',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 276,
            LIBELLEN3: 'CITE EL HAOUARI',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 277,
            LIBELLEN3: 'CITE EL KHADHRA',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 278,
            LIBELLEN3: 'CITE EL KHADHRA SUPERIEUR',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 279,
            LIBELLEN3: 'CITE EL MANAR 1',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 280,
            LIBELLEN3: 'CITE EL MANAR 2',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 281,
            LIBELLEN3: 'CITE EL MANAR 3',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 282,
            LIBELLEN3: 'CITE EL MANAR 4',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 283,
            LIBELLEN3: 'CITE EL MANAR 5',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 284,
            LIBELLEN3: 'CITE EL MEKMDA',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 285,
            LIBELLEN3: 'CITE EL MHELLA',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 286,
            LIBELLEN3: 'CITE EL MOUSTAKBEL',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 287,
            LIBELLEN3: 'CITE EL MZARA',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 288,
            LIBELLEN3: 'CITE EL OLFA',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 289,
            LIBELLEN3: 'CITE ENNOUZHA',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 290,
            LIBELLEN3: 'CITE ERRIADH 2',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 291,
            LIBELLEN3: 'CITE ERRIADHI 1',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 292,
            LIBELLEN3: 'CITE ESSALAMA',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 293,
            LIBELLEN3: 'CITE ESSALEM',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 294,
            LIBELLEN3: 'CITE ESSANAOUBER',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 295,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 296,
            LIBELLEN3: 'CITE GARDE NATIONALE',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 297,
            LIBELLEN3: 'CITE IBN KHALDOUN',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 298,
            LIBELLEN3: 'CITE JARDINS',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 299,
            LIBELLEN3: 'CITE JEBEL EL AKHDHAR',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 258,
            LIBELLEN3: 'CITE KSAR BARDO',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 259,
            LIBELLEN3: 'CITE PONT TRAGEON',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 260,
            LIBELLEN3: 'CITE S.R.TRANSPORT',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 261,
            LIBELLEN3: 'CITE SALAH EL MAGHRAOUI',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 232,
            LIBELLEN3: 'CITE SALAMBO',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 233,
            LIBELLEN3: 'CITE SIDI FREJ',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 234,
            LIBELLEN3: 'CITE SNIT',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 235,
            LIBELLEN3: 'CITE TAIEB MHIRI',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 236,
            LIBELLEN3: 'CITE USINE DU SUCRE1',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 237,
            LIBELLEN3: 'CITE USINE DU SUCRE2',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 238,
            LIBELLEN3: 'EL HAMROUNIA',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 239,
            LIBELLEN3: 'EL MOUNCHAR',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 246,
            LIBELLEN3: 'EL MZARA',
            IDN2: 86,
            CODEP: '9031',
          },
          {
            IDN3: 240,
            LIBELLEN3: 'EL YOUSRI',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 241,
            LIBELLEN3: 'FATNASSA',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 242,
            LIBELLEN3: 'HAJAR AMOR',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 243,
            LIBELLEN3: 'KASSEB',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 247,
            LIBELLEN3: 'LAFARKE',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 254,
            LIBELLEN3: 'NAGACHIA',
            IDN2: 86,
            CODEP: '9000',
          },
          {
            IDN3: 257,
            LIBELLEN3: 'TARHOUNI',
            IDN2: 86,
            CODEP: '9000',
          },
        ],
      },
      {
        ville: 94,
        villeName: 'BEJA SUD',
        cites: [
          {
            IDN3: 534,
            LIBELLEN3: 'CITE EL AMEL',
            IDN2: 94,
            CODEP: '9023',
          },
          {
            IDN3: 535,
            LIBELLEN3: 'EL MAAGOULA',
            IDN2: 94,
            CODEP: '9023',
          },
          {
            IDN3: 537,
            LIBELLEN3: 'HAMMAM SAYALA',
            IDN2: 94,
            CODEP: '9052',
          },
          {
            IDN3: 531,
            LIBELLEN3: 'MASTOUTA',
            IDN2: 94,
            CODEP: '9021',
          },
          {
            IDN3: 532,
            LIBELLEN3: 'MASTOUTA GARE',
            IDN2: 94,
            CODEP: '9021',
          },
          {
            IDN3: 536,
            LIBELLEN3: 'SIDI FREJ',
            IDN2: 94,
            CODEP: '9029',
          },
          {
            IDN3: 533,
            LIBELLEN3: 'SIDI SMAIL',
            IDN2: 94,
            CODEP: '9021',
          },
        ],
      },
      {
        ville: 88,
        villeName: 'GOUBELLAT',
        cites: [
          {
            IDN3: 362,
            LIBELLEN3: 'BIR EL EUCH',
            IDN2: 88,
            CODEP: '9080',
          },
          {
            IDN3: 341,
            LIBELLEN3: 'BRIOUIG',
            IDN2: 88,
            CODEP: '9080',
          },
          {
            IDN3: 342,
            LIBELLEN3: 'CHEIK EL OUEDIANE EL GUEBLI',
            IDN2: 88,
            CODEP: '9080',
          },
          {
            IDN3: 343,
            LIBELLEN3: 'CITE DES FONCTIONNAIRES',
            IDN2: 88,
            CODEP: '9080',
          },
          {
            IDN3: 344,
            LIBELLEN3: 'CITE EL HANA',
            IDN2: 88,
            CODEP: '9080',
          },
          {
            IDN3: 345,
            LIBELLEN3: 'CITE EL IZDIHAR',
            IDN2: 88,
            CODEP: '9080',
          },
          {
            IDN3: 346,
            LIBELLEN3: 'CITE EL KAMH',
            IDN2: 88,
            CODEP: '9080',
          },
          {
            IDN3: 347,
            LIBELLEN3: 'CITE ESSAADA',
            IDN2: 88,
            CODEP: '9080',
          },
          {
            IDN3: 348,
            LIBELLEN3: 'CITE ESSANABEL',
            IDN2: 88,
            CODEP: '9080',
          },
          {
            IDN3: 349,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 88,
            CODEP: '9080',
          },
          {
            IDN3: 350,
            LIBELLEN3: 'CITE POPULAIRE',
            IDN2: 88,
            CODEP: '9080',
          },
          {
            IDN3: 351,
            LIBELLEN3: 'DOUR ISMAIL',
            IDN2: 88,
            CODEP: '9080',
          },
          {
            IDN3: 352,
            LIBELLEN3: 'EL FEDHAILIA',
            IDN2: 88,
            CODEP: '9080',
          },
          {
            IDN3: 353,
            LIBELLEN3: 'EL GAMMARTI',
            IDN2: 88,
            CODEP: '9080',
          },
          {
            IDN3: 354,
            LIBELLEN3: 'EL OGLA',
            IDN2: 88,
            CODEP: '9080',
          },
          {
            IDN3: 355,
            LIBELLEN3: 'ETTRAIFA',
            IDN2: 88,
            CODEP: '9080',
          },
          {
            IDN3: 356,
            LIBELLEN3: 'GOUBELLAT',
            IDN2: 88,
            CODEP: '9080',
          },
          {
            IDN3: 361,
            LIBELLEN3: 'GRAME',
            IDN2: 88,
            CODEP: '9083',
          },
          {
            IDN3: 357,
            LIBELLEN3: 'KHACHEB',
            IDN2: 88,
            CODEP: '9080',
          },
          {
            IDN3: 358,
            LIBELLEN3: 'KHENGUET EDDIHENNE',
            IDN2: 88,
            CODEP: '9080',
          },
          {
            IDN3: 359,
            LIBELLEN3: 'TELLA',
            IDN2: 88,
            CODEP: '9080',
          },
          {
            IDN3: 360,
            LIBELLEN3: 'ZONE INDUSTRIELLE',
            IDN2: 88,
            CODEP: '9080',
          },
        ],
      },
      {
        ville: 89,
        villeName: 'MEJEZ EL BAB',
        cites: [
          {
            IDN3: 373,
            LIBELLEN3: 'ARGOUB EZZAATAR',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 402,
            LIBELLEN3: 'CHAOUECH',
            IDN2: 89,
            CODEP: '9072',
          },
          {
            IDN3: 397,
            LIBELLEN3: 'CITE 02 MARS',
            IDN2: 89,
            CODEP: '9071',
          },
          {
            IDN3: 374,
            LIBELLEN3: 'CITE BACCAR',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 363,
            LIBELLEN3: 'CITE DE LA POSTE',
            IDN2: 89,
            CODEP: '9013',
          },
          {
            IDN3: 375,
            LIBELLEN3: 'CITE DE LA SANTE',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 376,
            LIBELLEN3: 'CITE DES PROFFESSEURS',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 377,
            LIBELLEN3: 'CITE DHAMENE',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 378,
            LIBELLEN3: 'CITE DU GOUVERNORAT',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 364,
            LIBELLEN3: 'CITE EDUCATION',
            IDN2: 89,
            CODEP: '9013',
          },
          {
            IDN3: 379,
            LIBELLEN3: 'CITE EL AZIMA 1',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 380,
            LIBELLEN3: 'CITE EL AZIMA 2',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 381,
            LIBELLEN3: 'CITE EL AZIMA 3',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 382,
            LIBELLEN3: 'CITE EL BAHRINE',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 383,
            LIBELLEN3: 'CITE EL BEHI',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 384,
            LIBELLEN3: 'CITE EL FATEH',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 385,
            LIBELLEN3: 'CITE EL FERDAOUS 1',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 386,
            LIBELLEN3: 'CITE EL HANA',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 387,
            LIBELLEN3: 'CITE EL MANAR',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 388,
            LIBELLEN3: 'CITE EL MENCHARI',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 365,
            LIBELLEN3: 'CITE ENFANCE',
            IDN2: 89,
            CODEP: '9013',
          },
          {
            IDN3: 389,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 391,
            LIBELLEN3: 'CITE ESSEDK',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 399,
            LIBELLEN3: 'CITE ETTADHAMEN',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 400,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 401,
            LIBELLEN3: 'CITE JALLAZIA',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 390,
            LIBELLEN3: 'CITE MAJERDA',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 398,
            LIBELLEN3: 'EL GRIAAT',
            IDN2: 89,
            CODEP: '9071',
          },
          {
            IDN3: 403,
            LIBELLEN3: 'EL HERRI',
            IDN2: 89,
            CODEP: '9075',
          },
          {
            IDN3: 369,
            LIBELLEN3: 'EL KSAR',
            IDN2: 89,
            CODEP: '9015',
          },
          {
            IDN3: 392,
            LIBELLEN3: 'GUERICHE EL OUED',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 393,
            LIBELLEN3: 'HIDOUS',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 394,
            LIBELLEN3: 'IDEN',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 366,
            LIBELLEN3: 'LOUATIA',
            IDN2: 89,
            CODEP: '9013',
          },
          {
            IDN3: 395,
            LIBELLEN3: 'MEJEZ EL BAB',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 367,
            LIBELLEN3: 'OUED EZZITOUN',
            IDN2: 89,
            CODEP: '9013',
          },
          {
            IDN3: 368,
            LIBELLEN3: 'OUED ZARGA',
            IDN2: 89,
            CODEP: '9013',
          },
          {
            IDN3: 396,
            LIBELLEN3: 'SIDI AHMED JEDIDI',
            IDN2: 89,
            CODEP: '9070',
          },
          {
            IDN3: 371,
            LIBELLEN3: 'SIDI MEDIENE',
            IDN2: 89,
            CODEP: '9034',
          },
          {
            IDN3: 372,
            LIBELLEN3: 'SIDI NASSER',
            IDN2: 89,
            CODEP: '9044',
          },
          {
            IDN3: 370,
            LIBELLEN3: 'TOUKABEUR',
            IDN2: 89,
            CODEP: '9024',
          },
        ],
      },
      {
        ville: 87,
        villeName: 'NEFZA',
        cites: [
          {
            IDN3: 313,
            LIBELLEN3: 'AIN ZAKKAR',
            IDN2: 87,
            CODEP: '9012',
          },
          {
            IDN3: 326,
            LIBELLEN3: 'BENI SAID',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 314,
            LIBELLEN3: 'BOU GARNOUNA',
            IDN2: 87,
            CODEP: '9012',
          },
          {
            IDN3: 328,
            LIBELLEN3: 'CITE DE LA LIBERTE',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 329,
            LIBELLEN3: 'CITE DE LA POSTE',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 331,
            LIBELLEN3: 'CITE DES CARRIERES',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 332,
            LIBELLEN3: 'CITE DES ROSES',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 336,
            LIBELLEN3: 'CITE EL MHIRI',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 337,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 338,
            LIBELLEN3: 'CITE ESSAADA',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 339,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 340,
            LIBELLEN3: 'CITE INDEPENDANCE',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 324,
            LIBELLEN3: 'CITE MIXTE',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 327,
            LIBELLEN3: 'CITE MOHSEN LIMAM',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 330,
            LIBELLEN3: 'CITE RIM',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 333,
            LIBELLEN3: 'CITE SIDI SAAD',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 334,
            LIBELLEN3: 'DAR EL HIT',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 335,
            LIBELLEN3: 'EL AMAMRIA',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 303,
            LIBELLEN3: 'EL AYAYADA JAMILA',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 315,
            LIBELLEN3: 'EL HEMAIDIA',
            IDN2: 87,
            CODEP: '9012',
          },
          {
            IDN3: 323,
            LIBELLEN3: 'EL MAZDOUR',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 316,
            LIBELLEN3: 'EL MECHERGA',
            IDN2: 87,
            CODEP: '9012',
          },
          {
            IDN3: 317,
            LIBELLEN3: 'EL OULIJA',
            IDN2: 87,
            CODEP: '9012',
          },
          {
            IDN3: 318,
            LIBELLEN3: 'ETTAREF',
            IDN2: 87,
            CODEP: '9012',
          },
          {
            IDN3: 319,
            LIBELLEN3: 'EZZOUARAA',
            IDN2: 87,
            CODEP: '9012',
          },
          {
            IDN3: 325,
            LIBELLEN3: 'FATNASSA',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 304,
            LIBELLEN3: 'JBEL DISS',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 305,
            LIBELLEN3: 'JEMIL',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 306,
            LIBELLEN3: 'NEFZA',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 320,
            LIBELLEN3: 'OUECHTATA',
            IDN2: 87,
            CODEP: '9012',
          },
          {
            IDN3: 307,
            LIBELLEN3: 'OUED BOUZENNA',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 308,
            LIBELLEN3: 'OUED DAMOUS',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 309,
            LIBELLEN3: 'OULED HOUIMEL',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 310,
            LIBELLEN3: 'OULED KACEM',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 321,
            LIBELLEN3: 'OULED SALEM',
            IDN2: 87,
            CODEP: '9012',
          },
          {
            IDN3: 311,
            LIBELLEN3: 'OUM LAABID',
            IDN2: 87,
            CODEP: '9010',
          },
          {
            IDN3: 312,
            LIBELLEN3: 'TABABA',
            IDN2: 87,
            CODEP: '9011',
          },
          {
            IDN3: 322,
            LIBELLEN3: 'ZAGA',
            IDN2: 87,
            CODEP: '9012',
          },
        ],
      },
      {
        ville: 91,
        villeName: 'TEBOURSOUK',
        cites: [
          {
            IDN3: 434,
            LIBELLEN3: 'AIN EL HAMMAM',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 459,
            LIBELLEN3: 'AIN EL KEHOUL',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 460,
            LIBELLEN3: 'AIN EL MELLITI',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 461,
            LIBELLEN3: 'AIN JEMMALA',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 462,
            LIBELLEN3: 'BIR ETTOUTA',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 472,
            LIBELLEN3: 'BORJ BRAHIM',
            IDN2: 91,
            CODEP: '9032',
          },
          {
            IDN3: 463,
            LIBELLEN3: 'BOUZAIDA',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 464,
            LIBELLEN3: 'CITE AIN EL KARMA',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 465,
            LIBELLEN3: 'CITE AIN MRAD',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 466,
            LIBELLEN3: 'CITE DE LA REPUBLIQUE',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 467,
            LIBELLEN3: 'CITE DES MARTYRS',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 468,
            LIBELLEN3: 'CITE DU STADE',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 469,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 470,
            LIBELLEN3: 'CITE EL GLAI',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 435,
            LIBELLEN3: 'CITE EL GOURJANI',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 433,
            LIBELLEN3: 'CITE EL HADJ ABDALLAH',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 436,
            LIBELLEN3: 'CITE EL KSAR',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 437,
            LIBELLEN3: 'CITE ENNACIM',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 438,
            LIBELLEN3: 'CITE ENNACIM 1',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 439,
            LIBELLEN3: 'CITE ENNACIM 2',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 440,
            LIBELLEN3: 'CITE EZZAYATINE 1',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 441,
            LIBELLEN3: 'CITE EZZAYATINE 2',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 442,
            LIBELLEN3: 'CITE IBN KHALDOUN',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 443,
            LIBELLEN3: 'CITE IBN SINA',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 444,
            LIBELLEN3: 'CITE INDUSTRIELLE',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 445,
            LIBELLEN3: 'CITE JARDINS',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 447,
            LIBELLEN3: 'CITE MESKA',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 448,
            LIBELLEN3: 'CITE OUED ESSAH',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 449,
            LIBELLEN3: 'CITE SIDI BAGGA',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 471,
            LIBELLEN3: 'DOUGA',
            IDN2: 91,
            CODEP: '9032',
          },
          {
            IDN3: 450,
            LIBELLEN3: 'DOUGGA ROUINES',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 451,
            LIBELLEN3: 'EL AYEDH',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 452,
            LIBELLEN3: 'EL FAOUAR',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 453,
            LIBELLEN3: 'EL MATRIA',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 458,
            LIBELLEN3: 'FADDEN ESSOUK',
            IDN2: 91,
            CODEP: '9046',
          },
          {
            IDN3: 446,
            LIBELLEN3: 'KHALLED',
            IDN2: 91,
            CODEP: '9032',
          },
          {
            IDN3: 454,
            LIBELLEN3: 'NAFFET',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 455,
            LIBELLEN3: 'OULED MARAI',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 456,
            LIBELLEN3: 'RIHANA',
            IDN2: 91,
            CODEP: '9040',
          },
          {
            IDN3: 457,
            LIBELLEN3: 'TEBOURSOUK',
            IDN2: 91,
            CODEP: '9040',
          },
        ],
      },
      {
        ville: 92,
        villeName: 'TESTOUR',
        cites: [
          {
            IDN3: 514,
            LIBELLEN3: 'AIN TOUNGA',
            IDN2: 92,
            CODEP: '9062',
          },
          {
            IDN3: 479,
            LIBELLEN3: 'AIN YOUNES',
            IDN2: 92,
            CODEP: '9060\r\n',
          },
          {
            IDN3: 488,
            LIBELLEN3: 'BAB BLED',
            IDN2: 92,
            CODEP: '9014',
          },
          {
            IDN3: 480,
            LIBELLEN3: 'CITE 20 MARS',
            IDN2: 92,
            CODEP: '9060\r\n',
          },
          {
            IDN3: 481,
            LIBELLEN3: 'CITE 26 FEVRIER',
            IDN2: 92,
            CODEP: '9060\r\n',
          },
          {
            IDN3: 489,
            LIBELLEN3: 'CITE BAHRNIA',
            IDN2: 92,
            CODEP: '9014',
          },
          {
            IDN3: 490,
            LIBELLEN3: 'CITE BEHARNIA',
            IDN2: 92,
            CODEP: '9014',
          },
          {
            IDN3: 491,
            LIBELLEN3: 'CITE BEN KAROUIA',
            IDN2: 92,
            CODEP: '9014',
          },
          {
            IDN3: 492,
            LIBELLEN3: 'CITE BEN OTHMAN',
            IDN2: 92,
            CODEP: '9014',
          },
          {
            IDN3: 486,
            LIBELLEN3: 'CITE DAR ECHABEB',
            IDN2: 92,
            CODEP: '9014',
          },
          {
            IDN3: 484,
            LIBELLEN3: 'CITE DES ANDALOUS',
            IDN2: 92,
            CODEP: '9060\r\n',
          },
          {
            IDN3: 494,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 92,
            CODEP: '9060',
          },
          {
            IDN3: 483,
            LIBELLEN3: 'CITE EL FOLLA',
            IDN2: 92,
            CODEP: '9060\r\n',
          },
          {
            IDN3: 474,
            LIBELLEN3: 'CITE EL GADHOUM',
            IDN2: 92,
            CODEP: '9014',
          },
          {
            IDN3: 482,
            LIBELLEN3: 'CITE EL IZDIHAR',
            IDN2: 92,
            CODEP: '9060\r\n',
          },
          {
            IDN3: 493,
            LIBELLEN3: 'CITE EL MALOUF',
            IDN2: 92,
            CODEP: '9060',
          },
          {
            IDN3: 515,
            LIBELLEN3: 'CITE EL MENDRA',
            IDN2: 92,
            CODEP: '9014',
          },
          {
            IDN3: 495,
            LIBELLEN3: 'CITE ENNACIM',
            IDN2: 92,
            CODEP: '9060',
          },
          {
            IDN3: 487,
            LIBELLEN3: 'CITE ERRAOUDHA',
            IDN2: 92,
            CODEP: '9014',
          },
          {
            IDN3: 499,
            LIBELLEN3: 'CITE ESSAYADINE',
            IDN2: 92,
            CODEP: '9014',
          },
          {
            IDN3: 497,
            LIBELLEN3: 'CITE FEDDEN JEMAA',
            IDN2: 92,
            CODEP: '9014',
          },
          {
            IDN3: 500,
            LIBELLEN3: 'CITE GHARNATA 1',
            IDN2: 92,
            CODEP: '9060',
          },
          {
            IDN3: 501,
            LIBELLEN3: 'CITE GHARNATA 2',
            IDN2: 92,
            CODEP: '9060',
          },
          {
            IDN3: 502,
            LIBELLEN3: 'CITE IBN ZEIDOUN',
            IDN2: 92,
            CODEP: '9060',
          },
          {
            IDN3: 516,
            LIBELLEN3: 'CITE JABBESS',
            IDN2: 92,
            CODEP: '9014',
          },
          {
            IDN3: 485,
            LIBELLEN3: 'CITE KICHTILOU',
            IDN2: 92,
            CODEP: '9014',
          },
          {
            IDN3: 498,
            LIBELLEN3: 'CITE OULED SLIMANE',
            IDN2: 92,
            CODEP: '9014',
          },
          {
            IDN3: 473,
            LIBELLEN3: 'CITE SIDI KHOUILI',
            IDN2: 92,
            CODEP: '9014',
          },
          {
            IDN3: 503,
            LIBELLEN3: 'CITE SPINIAIR',
            IDN2: 92,
            CODEP: '9060',
          },
          {
            IDN3: 517,
            LIBELLEN3: 'CITE TAFERCHA',
            IDN2: 92,
            CODEP: '9014',
          },
          {
            IDN3: 496,
            LIBELLEN3: 'CITE TIJANI',
            IDN2: 92,
            CODEP: '9014',
          },
          {
            IDN3: 504,
            LIBELLEN3: 'EL GLAA',
            IDN2: 92,
            CODEP: '9060',
          },
          {
            IDN3: 475,
            LIBELLEN3: 'EL MELLAH',
            IDN2: 92,
            CODEP: '9014',
          },
          {
            IDN3: 512,
            LIBELLEN3: 'ESSKHIRA',
            IDN2: 92,
            CODEP: '9061',
          },
          {
            IDN3: 476,
            LIBELLEN3: 'ESSLOUGUIA',
            IDN2: 92,
            CODEP: '9014',
          },
          {
            IDN3: 513,
            LIBELLEN3: 'HENCHIR BOU SOFRA',
            IDN2: 92,
            CODEP: '9061',
          },
          {
            IDN3: 477,
            LIBELLEN3: 'JEBEL FELLOUS',
            IDN2: 92,
            CODEP: '9014',
          },
          {
            IDN3: 505,
            LIBELLEN3: 'JLASS BOU ISSA',
            IDN2: 92,
            CODEP: '9060',
          },
          {
            IDN3: 506,
            LIBELLEN3: 'MEZOUGHA',
            IDN2: 92,
            CODEP: '9060',
          },
          {
            IDN3: 507,
            LIBELLEN3: 'OUED JEDRA',
            IDN2: 92,
            CODEP: '9060',
          },
          {
            IDN3: 508,
            LIBELLEN3: 'OULED SLAMA',
            IDN2: 92,
            CODEP: '9060',
          },
          {
            IDN3: 478,
            LIBELLEN3: 'OULJET DOUKHANE',
            IDN2: 92,
            CODEP: '9014',
          },
          {
            IDN3: 509,
            LIBELLEN3: 'RHIBA',
            IDN2: 92,
            CODEP: '9060',
          },
          {
            IDN3: 510,
            LIBELLEN3: 'TESTOUR',
            IDN2: 92,
            CODEP: '9060',
          },
          {
            IDN3: 511,
            LIBELLEN3: 'ZELDOU',
            IDN2: 92,
            CODEP: '9060',
          },
        ],
      },
      {
        ville: 93,
        villeName: 'THIBAR',
        cites: [
          {
            IDN3: 527,
            LIBELLEN3: 'AIN EDDEFALI',
            IDN2: 93,
            CODEP: '9042',
          },
          {
            IDN3: 519,
            LIBELLEN3: 'CITE 2 MARS',
            IDN2: 93,
            CODEP: '9022',
          },
          {
            IDN3: 520,
            LIBELLEN3: 'CITE COMMERCIALE',
            IDN2: 93,
            CODEP: '9022',
          },
          {
            IDN3: 521,
            LIBELLEN3: 'CITE DES OUVRIERS',
            IDN2: 93,
            CODEP: '9022',
          },
          {
            IDN3: 523,
            LIBELLEN3: 'CITE EL OLFA',
            IDN2: 93,
            CODEP: '9022',
          },
          {
            IDN3: 524,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 93,
            CODEP: '9022',
          },
          {
            IDN3: 525,
            LIBELLEN3: 'CITE ESSALAMA',
            IDN2: 93,
            CODEP: '9022',
          },
          {
            IDN3: 526,
            LIBELLEN3: 'CITE HACHED',
            IDN2: 93,
            CODEP: '9022',
          },
          {
            IDN3: 528,
            LIBELLEN3: 'CITE IBN KHALDOUN',
            IDN2: 93,
            CODEP: '9022',
          },
          {
            IDN3: 518,
            LIBELLEN3: 'DJEBBA',
            IDN2: 93,
            CODEP: '9042',
          },
          {
            IDN3: 530,
            LIBELLEN3: 'EL HENANCHA',
            IDN2: 93,
            CODEP: '9042',
          },
          {
            IDN3: 529,
            LIBELLEN3: 'ENNECHIMA',
            IDN2: 93,
            CODEP: '9022',
          },
          {
            IDN3: 522,
            LIBELLEN3: 'THIBAR',
            IDN2: 93,
            CODEP: '9022',
          },
        ],
      },
    ],
  },
  {
    govLabel: 'BEN AROUS',
    govId: 3,
    villes: [
      {
        ville: 106,
        villeName: 'BEN AROUS',
        cites: [
          {
            IDN3: 850,
            LIBELLEN3: 'BEN AROUS',
            IDN2: 106,
            CODEP: '2013',
          },
          {
            IDN3: 849,
            LIBELLEN3: 'BEN AROUS SUD',
            IDN2: 106,
            CODEP: '2043',
          },
        ],
      },
      {
        ville: 105,
        villeName: 'BOU MHEL EL BASSATINE',
        cites: [
          {
            IDN3: 842,
            LIBELLEN3: 'BOU MHEL',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 822,
            LIBELLEN3: 'CITE BEN GAMRA',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 824,
            LIBELLEN3: 'CITE BEN JOUD',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 825,
            LIBELLEN3: 'CITE BOU MHEL',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 826,
            LIBELLEN3: 'CITE BOUTOURIA',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 827,
            LIBELLEN3: 'CITE CHEBIL',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 828,
            LIBELLEN3: 'CITE DE L INTERIEUR',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 829,
            LIBELLEN3: 'CITE DES JUGES',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 830,
            LIBELLEN3: 'CITE DIAR TOUNES',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 831,
            LIBELLEN3: 'CITE EL AHMADI',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 832,
            LIBELLEN3: 'CITE EL AMEN',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 833,
            LIBELLEN3: 'CITE EL ASSIL',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 834,
            LIBELLEN3: 'CITE EL BASSATINE ANCIEN',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 835,
            LIBELLEN3: 'CITE EL FERCHICHI',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 836,
            LIBELLEN3: 'CITE EL IKLIL',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 837,
            LIBELLEN3: 'CITE EL KENANI',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 838,
            LIBELLEN3: 'CITE EL KHALIJ',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 839,
            LIBELLEN3: 'CITE EL KHALLEDI',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 841,
            LIBELLEN3: 'CITE EL MAHRAJENE 1',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 823,
            LIBELLEN3: 'CITE EL MAHRAJENE 2',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 840,
            LIBELLEN3: 'CITE EL MOUNA',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 810,
            LIBELLEN3: 'CITE EL WAFA',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 813,
            LIBELLEN3: 'CITE ENNACIM 1',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 814,
            LIBELLEN3: 'CITE ENNACIM 2',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 815,
            LIBELLEN3: 'CITE ESSAFA',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 816,
            LIBELLEN3: 'CITE ESSALEM',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 817,
            LIBELLEN3: 'CITE HADRICH',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 818,
            LIBELLEN3: 'CITE JLASSI',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 819,
            LIBELLEN3: 'CITE MEZRIOU',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 812,
            LIBELLEN3: 'CITE PLEIN AIR',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 811,
            LIBELLEN3: 'CITE RABIAA',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 821,
            LIBELLEN3: 'CITE SNIT',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 820,
            LIBELLEN3: 'CITE SPROLS',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 843,
            LIBELLEN3: 'RESIDENCE DU MEDITERRANE',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 845,
            LIBELLEN3: 'RESIDENCE EL IMARA',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 846,
            LIBELLEN3: 'RESIDENCE ENNACIM',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 847,
            LIBELLEN3: 'RESIDENCE EZZOUHOUR',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 848,
            LIBELLEN3: 'RESIDENCE SARRA',
            IDN2: 105,
            CODEP: '2097',
          },
          {
            IDN3: 844,
            LIBELLEN3: 'RESIDENCE SIRINE',
            IDN2: 105,
            CODEP: '2097',
          },
        ],
      },
      {
        ville: 98,
        villeName: 'EL MOUROUJ',
        cites: [
          {
            IDN3: 700,
            LIBELLEN3: 'BIR EL KASSAA',
            IDN2: 98,
            CODEP: '2059',
          },
          {
            IDN3: 671,
            LIBELLEN3: 'CITE ALYSSA 1',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 670,
            LIBELLEN3: 'CITE ALYSSA 2',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 669,
            LIBELLEN3: 'CITE AZZA 1',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 659,
            LIBELLEN3: 'CITE AZZA 2',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 660,
            LIBELLEN3: 'CITE COMMERCIALE ABOU SOFIANE',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 661,
            LIBELLEN3: 'CITE COMMERCIALE BOUKHRISS',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 662,
            LIBELLEN3: 'CITE DES OASIS',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 663,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 694,
            LIBELLEN3: 'CITE EL BOCHRA',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 695,
            LIBELLEN3: 'CITE EL FAOUZ',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 696,
            LIBELLEN3: 'CITE EL FOLL',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 689,
            LIBELLEN3: 'CITE EL MOUROUJ 3 BIS',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 690,
            LIBELLEN3: 'CITE EL WIFAK',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 691,
            LIBELLEN3: 'CITE ENNAKHIL',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 692,
            LIBELLEN3: 'CITE ENNARJES 1',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 693,
            LIBELLEN3: 'CITE ENNARJES 2',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 697,
            LIBELLEN3: 'CITE ENNASR 1',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 698,
            LIBELLEN3: 'CITE ENNASR 2',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 699,
            LIBELLEN3: 'CITE ERRACHID 1',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 664,
            LIBELLEN3: 'CITE ERRACHID 2',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 665,
            LIBELLEN3: 'CITE ERRAOUDHA',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 666,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 667,
            LIBELLEN3: 'CITE ERRIADH 2',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 668,
            LIBELLEN3: 'CITE ESSALEM',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 646,
            LIBELLEN3: 'CITE IBN JAZZAR 2',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 647,
            LIBELLEN3: 'CITE IBN MANDHOUR',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 648,
            LIBELLEN3: 'CITE JARDINS',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 649,
            LIBELLEN3: 'CITE MONCEF BEY',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 650,
            LIBELLEN3: 'CITE TAHER HADDAD',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 651,
            LIBELLEN3: 'CITE YARGOULA',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 652,
            LIBELLEN3: 'EL ALIA 1',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 653,
            LIBELLEN3: 'EL ALIA 2',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 654,
            LIBELLEN3: 'EL ALIA 3',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 655,
            LIBELLEN3: 'EL MOUROUJ',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 656,
            LIBELLEN3: 'LA BELLE RESIDENCE',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 657,
            LIBELLEN3: 'RESIDENCE CHEBBI',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 658,
            LIBELLEN3: 'RESIDENCE CHOUROUK 1',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 672,
            LIBELLEN3: 'RESIDENCE CHOUROUK 2',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 673,
            LIBELLEN3: 'RESIDENCE CHOUROUK 3',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 675,
            LIBELLEN3: 'RESIDENCE DE L HORIZON',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 674,
            LIBELLEN3: 'RESIDENCE DE LA VERDURE',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 676,
            LIBELLEN3: 'RESIDENCE DU PRINTEMPS',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 677,
            LIBELLEN3: 'RESIDENCE EL FOLL',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 678,
            LIBELLEN3: 'RESIDENCE EL KHALIL',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 679,
            LIBELLEN3: 'RESIDENCE EL MOURJENE',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 680,
            LIBELLEN3: 'RESIDENCE EL OUARD',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 681,
            LIBELLEN3: 'RESIDENCE EL WAFA',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 682,
            LIBELLEN3: 'RESIDENCE ENNACIM',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 683,
            LIBELLEN3: 'RESIDENCE ENNAOURES',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 684,
            LIBELLEN3: 'RESIDENCE ENNESRINE',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 685,
            LIBELLEN3: 'RESIDENCE ERRABOUA',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 686,
            LIBELLEN3: 'RESIDENCE ESSAAD',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 687,
            LIBELLEN3: 'RESIDENCE ESSAADA',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 688,
            LIBELLEN3: 'RESIDENCE ESSRAYA',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 638,
            LIBELLEN3: 'RESIDENCE IBN KHALDOUN',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 639,
            LIBELLEN3: 'RESIDENCE IBN ZEIDOUN',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 640,
            LIBELLEN3: 'RESIDENCE INES',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 641,
            LIBELLEN3: 'RESIDENCE JINENE EL MOUROUJ',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 642,
            LIBELLEN3: 'RESIDENCE MERIEM',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 643,
            LIBELLEN3: 'RESIDENCE SIDI BOU SAID',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 644,
            LIBELLEN3: 'RESIDENCE TYPE',
            IDN2: 98,
            CODEP: '2074',
          },
          {
            IDN3: 645,
            LIBELLEN3: 'RESIDENCE ZAHRET EL MOUROUJ',
            IDN2: 98,
            CODEP: '2074',
          },
        ],
      },
      {
        ville: 95,
        villeName: 'EZZAHRA',
        cites: [
          {
            IDN3: 559,
            LIBELLEN3: 'CITE 18 JANVIER',
            IDN2: 95,
            CODEP: '2034',
          },
          {
            IDN3: 542,
            LIBELLEN3: 'CITE BEN MILED',
            IDN2: 95,
            CODEP: '2065',
          },
          {
            IDN3: 560,
            LIBELLEN3: 'CITE BORJ LOUZIR',
            IDN2: 95,
            CODEP: '2034',
          },
          {
            IDN3: 561,
            LIBELLEN3: 'CITE DU PORT',
            IDN2: 95,
            CODEP: '2034',
          },
          {
            IDN3: 549,
            LIBELLEN3: 'CITE DU WAGON',
            IDN2: 95,
            CODEP: '2034',
          },
          {
            IDN3: 550,
            LIBELLEN3: 'CITE EL AHLEM',
            IDN2: 95,
            CODEP: '2034',
          },
          {
            IDN3: 557,
            LIBELLEN3: 'CITE EL AKACIA',
            IDN2: 95,
            CODEP: '2034',
          },
          {
            IDN3: 558,
            LIBELLEN3: 'CITE EL AMEN',
            IDN2: 95,
            CODEP: '2034',
          },
          {
            IDN3: 543,
            LIBELLEN3: 'CITE EL HABIB',
            IDN2: 95,
            CODEP: '2065',
          },
          {
            IDN3: 544,
            LIBELLEN3: 'CITE EL HOUL',
            IDN2: 95,
            CODEP: '2065',
          },
          {
            IDN3: 551,
            LIBELLEN3: 'CITE EL OULIJA',
            IDN2: 95,
            CODEP: '2034',
          },
          {
            IDN3: 552,
            LIBELLEN3: 'CITE EL OUNS',
            IDN2: 95,
            CODEP: '2034',
          },
          {
            IDN3: 545,
            LIBELLEN3: 'CITE EL WARD',
            IDN2: 95,
            CODEP: '2065',
          },
          {
            IDN3: 546,
            LIBELLEN3: 'CITE EL YASMINE',
            IDN2: 95,
            CODEP: '2065',
          },
          {
            IDN3: 553,
            LIBELLEN3: 'CITE ENNAKHIL 1',
            IDN2: 95,
            CODEP: '2034',
          },
          {
            IDN3: 554,
            LIBELLEN3: 'CITE ENNAKHIL 2',
            IDN2: 95,
            CODEP: '2034',
          },
          {
            IDN3: 547,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 95,
            CODEP: '2065',
          },
          {
            IDN3: 555,
            LIBELLEN3: 'CITE ERRACHID',
            IDN2: 95,
            CODEP: '2034',
          },
          {
            IDN3: 556,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 95,
            CODEP: '2034',
          },
          {
            IDN3: 4898,
            LIBELLEN3: 'CITE INDUSTRIELLE',
            IDN2: 95,
            CODEP: '2034',
          },
          {
            IDN3: 538,
            LIBELLEN3: 'CITE MRAD 1',
            IDN2: 95,
            CODEP: '2034',
          },
          {
            IDN3: 539,
            LIBELLEN3: 'CITE MRAD 2',
            IDN2: 95,
            CODEP: '2034',
          },
          {
            IDN3: 540,
            LIBELLEN3: 'CITE PANORAMA',
            IDN2: 95,
            CODEP: '2034',
          },
          {
            IDN3: 541,
            LIBELLEN3: 'EZZAHRA',
            IDN2: 95,
            CODEP: '2034',
          },
          {
            IDN3: 548,
            LIBELLEN3: 'EZZAHRA EL HABIB',
            IDN2: 95,
            CODEP: '2065',
          },
        ],
      },
      {
        ville: 99,
        villeName: 'FOUCHANA',
        cites: [
          {
            IDN3: 705,
            LIBELLEN3: 'BIR ZENDALA',
            IDN2: 99,
            CODEP: '1135',
          },
          {
            IDN3: 727,
            LIBELLEN3: 'CHEBEDDA',
            IDN2: 99,
            CODEP: '1135',
          },
          {
            IDN3: 730,
            LIBELLEN3: 'CITE 20 MARS',
            IDN2: 99,
            CODEP: '2082',
          },
          {
            IDN3: 706,
            LIBELLEN3: 'CITE BRIM',
            IDN2: 99,
            CODEP: '2082',
          },
          {
            IDN3: 707,
            LIBELLEN3: 'CITE CHEBBI',
            IDN2: 99,
            CODEP: '2082',
          },
          {
            IDN3: 708,
            LIBELLEN3: 'CITE EL AMEL',
            IDN2: 99,
            CODEP: '2082',
          },
          {
            IDN3: 709,
            LIBELLEN3: 'CITE EL AMEL 2',
            IDN2: 99,
            CODEP: '2082',
          },
          {
            IDN3: 710,
            LIBELLEN3: 'CITE EL BANEFSEJ',
            IDN2: 99,
            CODEP: '2082',
          },
          {
            IDN3: 711,
            LIBELLEN3: 'CITE EL FATEH',
            IDN2: 99,
            CODEP: '2082',
          },
          {
            IDN3: 712,
            LIBELLEN3: 'CITE EL HANA',
            IDN2: 99,
            CODEP: '2082',
          },
          {
            IDN3: 713,
            LIBELLEN3: 'CITE EL HIDHAB',
            IDN2: 99,
            CODEP: '2082',
          },
          {
            IDN3: 714,
            LIBELLEN3: 'CITE EL IZDIHAR',
            IDN2: 99,
            CODEP: '2082',
          },
          {
            IDN3: 715,
            LIBELLEN3: 'CITE EL MISK',
            IDN2: 99,
            CODEP: '2082',
          },
          {
            IDN3: 716,
            LIBELLEN3: 'CITE EL MOUSTAKBEL',
            IDN2: 99,
            CODEP: '2082',
          },
          {
            IDN3: 717,
            LIBELLEN3: 'CITE EL WARD',
            IDN2: 99,
            CODEP: '2082',
          },
          {
            IDN3: 718,
            LIBELLEN3: 'CITE EL WIFAK',
            IDN2: 99,
            CODEP: '2082',
          },
          {
            IDN3: 719,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 99,
            CODEP: '2082',
          },
          {
            IDN3: 720,
            LIBELLEN3: 'CITE ENNOUZHA',
            IDN2: 99,
            CODEP: '2082',
          },
          {
            IDN3: 721,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 99,
            CODEP: '2082',
          },
          {
            IDN3: 722,
            LIBELLEN3: 'CITE EZZITOUN 1',
            IDN2: 99,
            CODEP: '2082',
          },
          {
            IDN3: 723,
            LIBELLEN3: 'CITE KHOUAJA',
            IDN2: 99,
            CODEP: '2082',
          },
          {
            IDN3: 724,
            LIBELLEN3: 'CITE SNIT',
            IDN2: 99,
            CODEP: '2082',
          },
          {
            IDN3: 725,
            LIBELLEN3: 'CITE TRABELSI',
            IDN2: 99,
            CODEP: '2082',
          },
          {
            IDN3: 728,
            LIBELLEN3: 'DOUAR EL HOUCH',
            IDN2: 99,
            CODEP: '1135',
          },
          {
            IDN3: 726,
            LIBELLEN3: 'EL MENAYCHIA',
            IDN2: 99,
            CODEP: '2082',
          },
          {
            IDN3: 701,
            LIBELLEN3: 'FOUCHANA',
            IDN2: 99,
            CODEP: '2082',
          },
          {
            IDN3: 702,
            LIBELLEN3: 'MEGHIRA CENTRE',
            IDN2: 99,
            CODEP: '2082',
          },
          {
            IDN3: 703,
            LIBELLEN3: 'MEGHIRA INZEL',
            IDN2: 99,
            CODEP: '2082',
          },
          {
            IDN3: 729,
            LIBELLEN3: 'NAASSEN',
            IDN2: 99,
            CODEP: '1135',
          },
          {
            IDN3: 704,
            LIBELLEN3: 'ZONE INDUSTRIELLE EL MEGHIRA',
            IDN2: 99,
            CODEP: '2082',
          },
        ],
      },
      {
        ville: 100,
        villeName: 'HAMMAM CHATT',
        cites: [
          {
            IDN3: 731,
            LIBELLEN3: 'BIR EL BEY',
            IDN2: 100,
            CODEP: '2055',
          },
          {
            IDN3: 732,
            LIBELLEN3: 'BORJ CEDRIA',
            IDN2: 100,
            CODEP: '2084',
          },
          {
            IDN3: 733,
            LIBELLEN3: 'HAMMAM CHATT',
            IDN2: 100,
            CODEP: '1164',
          },
        ],
      },
      {
        ville: 101,
        villeName: 'HAMMAM LIF',
        cites: [
          {
            IDN3: 750,
            LIBELLEN3: 'BOU KORNINE',
            IDN2: 101,
            CODEP: '2050',
          },
          {
            IDN3: 734,
            LIBELLEN3: 'CITE 7 NOVEMBRE',
            IDN2: 101,
            CODEP: '2050',
          },
          {
            IDN3: 751,
            LIBELLEN3: 'CITE BOU KORNINE 2',
            IDN2: 101,
            CODEP: '2093',
          },
          {
            IDN3: 752,
            LIBELLEN3: 'CITE BOUSSOUFFARA',
            IDN2: 101,
            CODEP: '2099',
          },
          {
            IDN3: 735,
            LIBELLEN3: 'CITE CASINO',
            IDN2: 101,
            CODEP: '2050',
          },
          {
            IDN3: 736,
            LIBELLEN3: 'CITE DAR EL BEY',
            IDN2: 101,
            CODEP: '2050',
          },
          {
            IDN3: 737,
            LIBELLEN3: 'CITE DE LA MUNICIPALITE',
            IDN2: 101,
            CODEP: '2050',
          },
          {
            IDN3: 738,
            LIBELLEN3: 'CITE DES INSTITUTEURS',
            IDN2: 101,
            CODEP: '2050',
          },
          {
            IDN3: 739,
            LIBELLEN3: 'CITE DES OUVRIERS',
            IDN2: 101,
            CODEP: '2050',
          },
          {
            IDN3: 740,
            LIBELLEN3: 'CITE ENNAHDHA',
            IDN2: 101,
            CODEP: '2050',
          },
          {
            IDN3: 741,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 101,
            CODEP: '2050',
          },
          {
            IDN3: 742,
            LIBELLEN3: 'CITE ENNOUZHA',
            IDN2: 101,
            CODEP: '2050',
          },
          {
            IDN3: 743,
            LIBELLEN3: 'CITE ESSANAOUBRIA',
            IDN2: 101,
            CODEP: '2050',
          },
          {
            IDN3: 744,
            LIBELLEN3: 'CITE HACHED',
            IDN2: 101,
            CODEP: '2050',
          },
          {
            IDN3: 745,
            LIBELLEN3: 'CITE KORTOBA',
            IDN2: 101,
            CODEP: '2050',
          },
          {
            IDN3: 746,
            LIBELLEN3: 'CITE MONGI SLIM',
            IDN2: 101,
            CODEP: '2050',
          },
          {
            IDN3: 747,
            LIBELLEN3: 'CITE OFFICE CEREALE',
            IDN2: 101,
            CODEP: '2050',
          },
          {
            IDN3: 748,
            LIBELLEN3: 'CITE THERMALE',
            IDN2: 101,
            CODEP: '2050',
          },
          {
            IDN3: 749,
            LIBELLEN3: 'HAMMAM LIF',
            IDN2: 101,
            CODEP: '2050',
          },
        ],
      },
      {
        ville: 102,
        villeName: 'MEGRINE',
        cites: [
          {
            IDN3: 753,
            LIBELLEN3: 'CITE FOUNIZ',
            IDN2: 102,
            CODEP: '2014',
          },
          {
            IDN3: 759,
            LIBELLEN3: 'MEGRINE',
            IDN2: 102,
            CODEP: '2033',
          },
          {
            IDN3: 757,
            LIBELLEN3: 'MEGRINE CHAKER',
            IDN2: 102,
            CODEP: '2014',
          },
          {
            IDN3: 760,
            LIBELLEN3: 'MEGRINE COTEAU',
            IDN2: 102,
            CODEP: '2033',
          },
          {
            IDN3: 758,
            LIBELLEN3: 'MEGRINE JAOUHARA',
            IDN2: 102,
            CODEP: '2014',
          },
          {
            IDN3: 754,
            LIBELLEN3: 'MEGRINE RIADH',
            IDN2: 102,
            CODEP: '2014',
          },
          {
            IDN3: 761,
            LIBELLEN3: 'MEGRINE SUPERIEUR',
            IDN2: 102,
            CODEP: '2033',
          },
          {
            IDN3: 755,
            LIBELLEN3: 'SAINT GOBIN',
            IDN2: 102,
            CODEP: '2014',
          },
          {
            IDN3: 762,
            LIBELLEN3: 'SIDI REZIG',
            IDN2: 102,
            CODEP: '2033',
          },
          {
            IDN3: 756,
            LIBELLEN3: 'ZONE INDUSTRIELLE SAINT GOBIN',
            IDN2: 102,
            CODEP: '2014',
          },
        ],
      },
      {
        ville: 96,
        villeName: 'MOHAMADIA',
        cites: [
          {
            IDN3: 578,
            LIBELLEN3: 'CITE BOU AKROUCHA',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 579,
            LIBELLEN3: 'CITE BOURBAI',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 580,
            LIBELLEN3: 'CITE CEBBALET CHIKH',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 581,
            LIBELLEN3: 'CITE CHAABANE',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 582,
            LIBELLEN3: 'CITE CHEBBI',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 583,
            LIBELLEN3: 'CITE DE LA CARRIERE 1',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 563,
            LIBELLEN3: 'CITE DU LYCEE',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 564,
            LIBELLEN3: 'CITE EL AMEN',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 565,
            LIBELLEN3: 'CITE EL ATIQ',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 566,
            LIBELLEN3: 'CITE EL BACCOUCH',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 567,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 568,
            LIBELLEN3: 'CITE EL ELMI 1',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 587,
            LIBELLEN3: 'CITE EL ELMI 2',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 588,
            LIBELLEN3: 'CITE EL HADDAD',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 589,
            LIBELLEN3: 'CITE EL HANA',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 590,
            LIBELLEN3: 'CITE EL KSAR',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 591,
            LIBELLEN3: 'CITE EL OMRI 1',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 592,
            LIBELLEN3: 'CITE EL OMRI 2',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 593,
            LIBELLEN3: 'CITE ELLOUATA',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 594,
            LIBELLEN3: 'CITE ENNACIM 1',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 595,
            LIBELLEN3: 'CITE ENNACIM 2',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 596,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 569,
            LIBELLEN3: 'CITE ENNOUZHA',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 570,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 571,
            LIBELLEN3: 'CITE ESSAADA 1',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 572,
            LIBELLEN3: 'CITE ESSAADA 2',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 573,
            LIBELLEN3: 'CITE ESSAADA 3',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 574,
            LIBELLEN3: 'CITE ESSALEM',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 575,
            LIBELLEN3: 'CITE ETTAYARI',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 576,
            LIBELLEN3: 'CITE ETTOUAHRIA',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 577,
            LIBELLEN3: 'CITE EZZITOUN 2',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 597,
            LIBELLEN3: 'CITE FATTOUMA BOURGUIBA',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 598,
            LIBELLEN3: 'CITE HACHED 1',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 599,
            LIBELLEN3: 'CITE HACHED 2',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 600,
            LIBELLEN3: 'CITE JAOUHARA',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 601,
            LIBELLEN3: 'CITE JARDINS',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 602,
            LIBELLEN3: 'CITE MONGI SLIM 1',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 603,
            LIBELLEN3: 'CITE MONGI SLIM 2',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 584,
            LIBELLEN3: 'CITE OUDHNA',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 586,
            LIBELLEN3: 'CITE TAIEB MHIRI',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 606,
            LIBELLEN3: 'CITE TNICH',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 608,
            LIBELLEN3: 'IMMEUBLES DE L INTERIEUR',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 604,
            LIBELLEN3: 'MOHAMADIA',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 605,
            LIBELLEN3: 'RESIDENCE EL FERDAOUS',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 607,
            LIBELLEN3: 'RESIDENCE EL MOUSTAKBEL',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 609,
            LIBELLEN3: 'RESIDENCE EL OUNS',
            IDN2: 96,
            CODEP: '1145',
          },
          {
            IDN3: 585,
            LIBELLEN3: 'RESIDENCE ETTAOUFIK',
            IDN2: 96,
            CODEP: '1145',
          },
        ],
      },
      {
        ville: 104,
        villeName: 'MORNAG',
        cites: [
          {
            IDN3: 796,
            LIBELLEN3: 'AHMED ZAIED',
            IDN2: 104,
            CODEP: '2090',
          },
          {
            IDN3: 766,
            LIBELLEN3: 'AIN REGAD',
            IDN2: 104,
            CODEP: '2054',
          },
          {
            IDN3: 767,
            LIBELLEN3: 'BARROUTA',
            IDN2: 104,
            CODEP: '2054',
          },
          {
            IDN3: 797,
            LIBELLEN3: 'BEN NOUIJI',
            IDN2: 104,
            CODEP: '2090',
          },
          {
            IDN3: 768,
            LIBELLEN3: 'BIR JEDID',
            IDN2: 104,
            CODEP: '2054',
          },
          {
            IDN3: 791,
            LIBELLEN3: 'BORJ ESSOUFI',
            IDN2: 104,
            CODEP: '2090',
          },
          {
            IDN3: 798,
            LIBELLEN3: 'BOU JERDGA',
            IDN2: 104,
            CODEP: '2090',
          },
          {
            IDN3: 805,
            LIBELLEN3: 'CHALA',
            IDN2: 104,
            CODEP: '2090',
          },
          {
            IDN3: 806,
            LIBELLEN3: 'CHAMINE',
            IDN2: 104,
            CODEP: '2090',
          },
          {
            IDN3: 776,
            LIBELLEN3: 'CITE AIR NOUVELLE',
            IDN2: 104,
            CODEP: '2064',
          },
          {
            IDN3: 807,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 104,
            CODEP: '2090',
          },
          {
            IDN3: 777,
            LIBELLEN3: 'CITE ETTAYARANE',
            IDN2: 104,
            CODEP: '2064',
          },
          {
            IDN3: 808,
            LIBELLEN3: 'DOUAR LA PORTE',
            IDN2: 104,
            CODEP: '2090',
          },
          {
            IDN3: 809,
            LIBELLEN3: 'DOUAR SALEM',
            IDN2: 104,
            CODEP: '2090',
          },
          {
            IDN3: 778,
            LIBELLEN3: 'EL ABEBSA',
            IDN2: 104,
            CODEP: '2064',
          },
          {
            IDN3: 799,
            LIBELLEN3: 'EL ALELGUA',
            IDN2: 104,
            CODEP: '2090',
          },
          {
            IDN3: 800,
            LIBELLEN3: 'EL ARABA',
            IDN2: 104,
            CODEP: '2090',
          },
          {
            IDN3: 779,
            LIBELLEN3: 'EL FENDRIA',
            IDN2: 104,
            CODEP: '2064',
          },
          {
            IDN3: 769,
            LIBELLEN3: 'EL GOUNNA',
            IDN2: 104,
            CODEP: '2054',
          },
          {
            IDN3: 801,
            LIBELLEN3: 'EL HAOUAMED',
            IDN2: 104,
            CODEP: '2090',
          },
          {
            IDN3: 790,
            LIBELLEN3: 'EL KABOUTI',
            IDN2: 104,
            CODEP: '2064',
          },
          {
            IDN3: 802,
            LIBELLEN3: 'EL KSIBI',
            IDN2: 104,
            CODEP: '2090',
          },
          {
            IDN3: 765,
            LIBELLEN3: 'ERRISSALA',
            IDN2: 104,
            CODEP: '2044',
          },
          {
            IDN3: 803,
            LIBELLEN3: 'ETTIAYRA',
            IDN2: 104,
            CODEP: '2090',
          },
          {
            IDN3: 804,
            LIBELLEN3: 'FERME MARQUEE',
            IDN2: 104,
            CODEP: '2090',
          },
          {
            IDN3: 780,
            LIBELLEN3: 'HENCHIR BOUCHTA',
            IDN2: 104,
            CODEP: '2090',
          },
          {
            IDN3: 781,
            LIBELLEN3: 'HENCHIR EL GHOBAR',
            IDN2: 104,
            CODEP: '2090',
          },
          {
            IDN3: 770,
            LIBELLEN3: 'HENCHIR KHARROUBA',
            IDN2: 104,
            CODEP: '2054',
          },
          {
            IDN3: 782,
            LIBELLEN3: 'HENCHIR KHELIL',
            IDN2: 104,
            CODEP: '2090',
          },
          {
            IDN3: 783,
            LIBELLEN3: 'HUILERIE BAYREM',
            IDN2: 104,
            CODEP: '2090',
          },
          {
            IDN3: 792,
            LIBELLEN3: 'JEBEL ERSASS',
            IDN2: 104,
            CODEP: '2064',
          },
          {
            IDN3: 793,
            LIBELLEN3: 'JOURJOU',
            IDN2: 104,
            CODEP: '2064',
          },
          {
            IDN3: 771,
            LIBELLEN3: 'KHELIDIA',
            IDN2: 104,
            CODEP: '2054',
          },
          {
            IDN3: 772,
            LIBELLEN3: 'KOUENJEL',
            IDN2: 104,
            CODEP: '2054',
          },
          {
            IDN3: 794,
            LIBELLEN3: 'MIRGHNA',
            IDN2: 104,
            CODEP: '2064',
          },
          {
            IDN3: 784,
            LIBELLEN3: 'MORNAG',
            IDN2: 104,
            CODEP: '2090',
          },
          {
            IDN3: 785,
            LIBELLEN3: 'OUZRA',
            IDN2: 104,
            CODEP: '2090',
          },
          {
            IDN3: 773,
            LIBELLEN3: 'SIDI MESSAOUD',
            IDN2: 104,
            CODEP: '2054',
          },
          {
            IDN3: 774,
            LIBELLEN3: 'SIDI OTHMAN LANDARI',
            IDN2: 104,
            CODEP: '2054',
          },
          {
            IDN3: 786,
            LIBELLEN3: 'SIDI SAAD',
            IDN2: 104,
            CODEP: '2090',
          },
          {
            IDN3: 795,
            LIBELLEN3: 'SIDI SALEM EL GARCI',
            IDN2: 104,
            CODEP: '2064',
          },
          {
            IDN3: 775,
            LIBELLEN3: 'SONDAGE',
            IDN2: 104,
            CODEP: '2054',
          },
          {
            IDN3: 787,
            LIBELLEN3: 'TARHOUNA 1',
            IDN2: 104,
            CODEP: '2090',
          },
          {
            IDN3: 788,
            LIBELLEN3: 'TARHOUNA 2',
            IDN2: 104,
            CODEP: '2090',
          },
          {
            IDN3: 789,
            LIBELLEN3: 'ZAOUIT MORNAG',
            IDN2: 104,
            CODEP: '2090',
          },
        ],
      },
      {
        ville: 103,
        villeName: 'NOUVELLE MEDINA',
        cites: [
          {
            IDN3: 763,
            LIBELLEN3: 'EL YASMINETTE',
            IDN2: 103,
            CODEP: '2096',
          },
          {
            IDN3: 764,
            LIBELLEN3: 'NOUVELLE MEDINA',
            IDN2: 103,
            CODEP: '2063',
          },
        ],
      },
      {
        ville: 97,
        villeName: 'RADES',
        cites: [
          {
            IDN3: 632,
            LIBELLEN3: 'CHOUCHET RADES',
            IDN2: 97,
            CODEP: '2040',
          },
          {
            IDN3: 621,
            LIBELLEN3: 'CITE 20 MARS',
            IDN2: 97,
            CODEP: '1125',
          },
          {
            IDN3: 619,
            LIBELLEN3: 'CITE EL EZZ',
            IDN2: 97,
            CODEP: '2098',
          },
          {
            IDN3: 622,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 97,
            CODEP: '1125',
          },
          {
            IDN3: 610,
            LIBELLEN3: 'CITE MOHAMED ALI',
            IDN2: 97,
            CODEP: '2040',
          },
          {
            IDN3: 620,
            LIBELLEN3: 'CITE NOUVELLE',
            IDN2: 97,
            CODEP: '2098',
          },
          {
            IDN3: 611,
            LIBELLEN3: 'CITE RADES 2',
            IDN2: 97,
            CODEP: '2040',
          },
          {
            IDN3: 612,
            LIBELLEN3: 'CITE SFAXI',
            IDN2: 97,
            CODEP: '2040',
          },
          {
            IDN3: 613,
            LIBELLEN3: 'CITE SNCFT',
            IDN2: 97,
            CODEP: '2040',
          },
          {
            IDN3: 627,
            LIBELLEN3: 'CITE SNIT',
            IDN2: 97,
            CODEP: '2018',
          },
          {
            IDN3: 614,
            LIBELLEN3: 'CITE TAIEB MHIRI 1',
            IDN2: 97,
            CODEP: '2040',
          },
          {
            IDN3: 615,
            LIBELLEN3: 'CITE TAIEB MHIRI 2',
            IDN2: 97,
            CODEP: '2040',
          },
          {
            IDN3: 616,
            LIBELLEN3: 'CITE TAIEB MHIRI 3',
            IDN2: 97,
            CODEP: '2040',
          },
          {
            IDN3: 628,
            LIBELLEN3: 'DECOUPPAGE HAJ ALEYA',
            IDN2: 97,
            CODEP: '2018',
          },
          {
            IDN3: 617,
            LIBELLEN3: 'RADES',
            IDN2: 97,
            CODEP: '2040',
          },
          {
            IDN3: 629,
            LIBELLEN3: 'RADES 7 NOVEMBRE',
            IDN2: 97,
            CODEP: '2018',
          },
          {
            IDN3: 633,
            LIBELLEN3: 'RADES EL MRAH',
            IDN2: 97,
            CODEP: '2098',
          },
          {
            IDN3: 634,
            LIBELLEN3: 'RADES FORET',
            IDN2: 97,
            CODEP: '2098',
          },
          {
            IDN3: 635,
            LIBELLEN3: 'RADES MEDINA',
            IDN2: 97,
            CODEP: '2098',
          },
          {
            IDN3: 636,
            LIBELLEN3: 'RADES MELIANE',
            IDN2: 97,
            CODEP: '2098',
          },
          {
            IDN3: 637,
            LIBELLEN3: 'RADES MONGIL',
            IDN2: 97,
            CODEP: '2098',
          },
          {
            IDN3: 618,
            LIBELLEN3: 'RADES PLAGE',
            IDN2: 97,
            CODEP: '2040',
          },
          {
            IDN3: 623,
            LIBELLEN3: 'RADES SALINE',
            IDN2: 97,
            CODEP: '1125',
          },
          {
            IDN3: 630,
            LIBELLEN3: 'VILLAGE MEDITERRANEE',
            IDN2: 97,
            CODEP: '2018',
          },
          {
            IDN3: 631,
            LIBELLEN3: 'VILLE SPORTIVE',
            IDN2: 97,
            CODEP: '2018',
          },
          {
            IDN3: 624,
            LIBELLEN3: 'ZONE INDUSTRIELLE 1',
            IDN2: 97,
            CODEP: '1125',
          },
          {
            IDN3: 625,
            LIBELLEN3: 'ZONE INDUSTRIELLE 2',
            IDN2: 97,
            CODEP: '1125',
          },
          {
            IDN3: 626,
            LIBELLEN3: 'ZONE PETROLIERE',
            IDN2: 97,
            CODEP: '1125',
          },
        ],
      },
    ],
  },
  {
    govLabel: 'BIZERTE',
    govId: 4,
    villes: [
      {
        ville: 13,
        villeName: 'BIZERTE NORD',
        cites: [
          {
            IDN3: 943,
            LIBELLEN3: 'BIZERTE',
            IDN2: 13,
            CODEP: '7000',
          },
          {
            IDN3: 920,
            LIBELLEN3: 'BIZERTE BAB MATEUR',
            IDN2: 13,
            CODEP: '7061',
          },
          {
            IDN3: 922,
            LIBELLEN3: 'BIZERTE BOUGATFA',
            IDN2: 13,
            CODEP: '7053',
          },
          {
            IDN3: 923,
            LIBELLEN3: 'CITE AFH',
            IDN2: 13,
            CODEP: '7053',
          },
          {
            IDN3: 944,
            LIBELLEN3: 'CITE AIN MARIEM',
            IDN2: 13,
            CODEP: '7000',
          },
          {
            IDN3: 924,
            LIBELLEN3: 'CITE AIN MERIEM',
            IDN2: 13,
            CODEP: '7053',
          },
          {
            IDN3: 910,
            LIBELLEN3: 'CITE BELVEDERE',
            IDN2: 13,
            CODEP: '7000',
          },
          {
            IDN3: 911,
            LIBELLEN3: 'CITE BOUGATFA',
            IDN2: 13,
            CODEP: '7001',
          },
          {
            IDN3: 925,
            LIBELLEN3: 'CITE BOUGATFA 2',
            IDN2: 13,
            CODEP: '7053',
          },
          {
            IDN3: 941,
            LIBELLEN3: 'CITE CHOUROUK SIDI SALEM',
            IDN2: 13,
            CODEP: '7003',
          },
          {
            IDN3: 912,
            LIBELLEN3: 'CITE DE LA MUNICIPALITE',
            IDN2: 13,
            CODEP: '7001',
          },
          {
            IDN3: 926,
            LIBELLEN3: 'CITE DE LA POLICE',
            IDN2: 13,
            CODEP: '7053',
          },
          {
            IDN3: 937,
            LIBELLEN3: 'CITE DE LA SANTE',
            IDN2: 13,
            CODEP: '7003',
          },
          {
            IDN3: 928,
            LIBELLEN3: 'CITE DE PARIS',
            IDN2: 13,
            CODEP: '7003',
          },
          {
            IDN3: 929,
            LIBELLEN3: 'CITE EL HANA',
            IDN2: 13,
            CODEP: '7003',
          },
          {
            IDN3: 927,
            LIBELLEN3: 'CITE EL KORNICHE 1',
            IDN2: 13,
            CODEP: '7053',
          },
          {
            IDN3: 939,
            LIBELLEN3: 'CITE EL KORNICHE 2',
            IDN2: 13,
            CODEP: '7053',
          },
          {
            IDN3: 938,
            LIBELLEN3: 'CITE EL KORNICHE 3',
            IDN2: 13,
            CODEP: '\r\n7053',
          },
          {
            IDN3: 942,
            LIBELLEN3: 'CITE EL MORJENE 3',
            IDN2: 13,
            CODEP: '7053',
          },
          {
            IDN3: 917,
            LIBELLEN3: 'CITE EL MORJENE 4',
            IDN2: 13,
            CODEP: '7053',
          },
          {
            IDN3: 930,
            LIBELLEN3: 'CITE ENNACIM',
            IDN2: 13,
            CODEP: '7003',
          },
          {
            IDN3: 931,
            LIBELLEN3: 'CITE ERRAOUABI',
            IDN2: 13,
            CODEP: '7003',
          },
          {
            IDN3: 913,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 13,
            CODEP: '7001',
          },
          {
            IDN3: 914,
            LIBELLEN3: 'CITE HASSEN NOURI',
            IDN2: 13,
            CODEP: '7001',
          },
          {
            IDN3: 940,
            LIBELLEN3: 'CITE MILITAIRE',
            IDN2: 13,
            CODEP: '7002',
          },
          {
            IDN3: 915,
            LIBELLEN3: 'CITE NOUVELLE PLAGE',
            IDN2: 13,
            CODEP: '7001',
          },
          {
            IDN3: 918,
            LIBELLEN3: 'CITE RAFAHA 1',
            IDN2: 13,
            CODEP: '7053',
          },
          {
            IDN3: 919,
            LIBELLEN3: 'CITE RAFAHA 2',
            IDN2: 13,
            CODEP: '7053',
          },
          {
            IDN3: 933,
            LIBELLEN3: 'ENNADHOUR',
            IDN2: 13,
            CODEP: '7029',
          },
          {
            IDN3: 934,
            LIBELLEN3: 'FEJJET ERRIH',
            IDN2: 13,
            CODEP: '7029',
          },
          {
            IDN3: 935,
            LIBELLEN3: 'KSAR LAHMAR',
            IDN2: 13,
            CODEP: '7029',
          },
          {
            IDN3: 916,
            LIBELLEN3: 'KSIBA',
            IDN2: 13,
            CODEP: '7001',
          },
          {
            IDN3: 936,
            LIBELLEN3: 'MARNISSA',
            IDN2: 13,
            CODEP: '7029',
          },
          {
            IDN3: 932,
            LIBELLEN3: 'OUED EL MARJ',
            IDN2: 13,
            CODEP: '7003',
          },
          {
            IDN3: 921,
            LIBELLEN3: 'SIDI AHMED',
            IDN2: 13,
            CODEP: '7029',
          },
        ],
      },
      {
        ville: 14,
        villeName: 'BIZERTE SUD',
        cites: [
          {
            IDN3: 949,
            LIBELLEN3: 'AIN EL BERDA',
            IDN2: 14,
            CODEP: '7029',
          },
          {
            IDN3: 950,
            LIBELLEN3: 'AIN JENANE',
            IDN2: 14,
            CODEP: '7029',
          },
          {
            IDN3: 955,
            LIBELLEN3: 'BIZERTE HACHED',
            IDN2: 14,
            CODEP: '7071',
          },
          {
            IDN3: 956,
            LIBELLEN3: 'BORJ CHALLOUF',
            IDN2: 14,
            CODEP: '7094',
          },
          {
            IDN3: 954,
            LIBELLEN3: 'BOU ZARIA',
            IDN2: 14,
            CODEP: '7064',
          },
          {
            IDN3: 945,
            LIBELLEN3: 'CITE EL MOUROUJ',
            IDN2: 14,
            CODEP: '7011',
          },
          {
            IDN3: 946,
            LIBELLEN3: 'CITE MILITAIRE',
            IDN2: 14,
            CODEP: '7011',
          },
          {
            IDN3: 951,
            LIBELLEN3: 'EL MRAZIG',
            IDN2: 14,
            CODEP: '7029',
          },
          {
            IDN3: 947,
            LIBELLEN3: 'LA PECHERIE',
            IDN2: 14,
            CODEP: '7011',
          },
          {
            IDN3: 953,
            LIBELLEN3: 'LOUATA',
            IDN2: 14,
            CODEP: '7041',
          },
          {
            IDN3: 948,
            LIBELLEN3: 'METHLINE',
            IDN2: 14,
            CODEP: '7027',
          },
          {
            IDN3: 952,
            LIBELLEN3: 'TESKRAYA',
            IDN2: 14,
            CODEP: '7036',
          },
        ],
      },
      {
        ville: 15,
        villeName: 'EL ALIA',
        cites: [
          {
            IDN3: 957,
            LIBELLEN3: 'CITE INDEPENDANCE',
            IDN2: 15,
            CODEP: '7016',
          },
          {
            IDN3: 958,
            LIBELLEN3: 'EL ALIA',
            IDN2: 15,
            CODEP: '7016',
          },
          {
            IDN3: 959,
            LIBELLEN3: 'EL HARIZA',
            IDN2: 15,
            CODEP: '7016',
          },
          {
            IDN3: 961,
            LIBELLEN3: 'EZZITOUNE',
            IDN2: 15,
            CODEP: '7081',
          },
          {
            IDN3: 960,
            LIBELLEN3: 'IMPASSE EZZOUHOUR',
            IDN2: 15,
            CODEP: '7016',
          },
          {
            IDN3: 962,
            LIBELLEN3: 'KHETMINE',
            IDN2: 15,
            CODEP: '7081',
          },
          {
            IDN3: 963,
            LIBELLEN3: 'SIDI ALI CHEBAB',
            IDN2: 15,
            CODEP: '7093',
          },
        ],
      },
      {
        ville: 17,
        villeName: 'GHAR EL MELH',
        cites: [
          {
            IDN3: 1001,
            LIBELLEN3: 'BAJOU',
            IDN2: 17,
            CODEP: '7033',
          },
          {
            IDN3: 992,
            LIBELLEN3: 'CITE 7 NOVEMBRE',
            IDN2: 17,
            CODEP: '7014',
          },
          {
            IDN3: 993,
            LIBELLEN3: 'CITE 9 AVRIL',
            IDN2: 17,
            CODEP: '7014',
          },
          {
            IDN3: 994,
            LIBELLEN3: 'CITE EL AMEN',
            IDN2: 17,
            CODEP: '7014',
          },
          {
            IDN3: 995,
            LIBELLEN3: 'CITE EL MOUROUJ',
            IDN2: 17,
            CODEP: '7014',
          },
          {
            IDN3: 996,
            LIBELLEN3: 'CITE KHALED IBN EL WALID',
            IDN2: 17,
            CODEP: '7014',
          },
          {
            IDN3: 997,
            LIBELLEN3: 'CITE NOUVELLE',
            IDN2: 17,
            CODEP: '7014',
          },
          {
            IDN3: 998,
            LIBELLEN3: 'EL AOUSJA',
            IDN2: 17,
            CODEP: '7014',
          },
          {
            IDN3: 1002,
            LIBELLEN3: 'GHAR EL MELH',
            IDN2: 17,
            CODEP: '7033',
          },
          {
            IDN3: 999,
            LIBELLEN3: 'TOUIBIA',
            IDN2: 17,
            CODEP: '7024',
          },
          {
            IDN3: 1000,
            LIBELLEN3: 'ZOUAOUINE',
            IDN2: 17,
            CODEP: '7024',
          },
        ],
      },
      {
        ville: 18,
        villeName: 'GHEZALA',
        cites: [
          {
            IDN3: 1003,
            LIBELLEN3: 'AIN REKOUB',
            IDN2: 18,
            CODEP: '7040',
          },
          {
            IDN3: 1004,
            LIBELLEN3: 'BENI BRAHIM',
            IDN2: 18,
            CODEP: '7040',
          },
          {
            IDN3: 1005,
            LIBELLEN3: 'BOU JRIR',
            IDN2: 18,
            CODEP: '7040',
          },
          {
            IDN3: 1006,
            LIBELLEN3: 'CITE AFH',
            IDN2: 18,
            CODEP: '7040',
          },
          {
            IDN3: 1007,
            LIBELLEN3: 'CITE BORJ DEHEB',
            IDN2: 18,
            CODEP: '7040',
          },
          {
            IDN3: 1008,
            LIBELLEN3: 'CITE RURALE',
            IDN2: 18,
            CODEP: '7040',
          },
          {
            IDN3: 1009,
            LIBELLEN3: 'CITE SNIT',
            IDN2: 18,
            CODEP: '7040',
          },
          {
            IDN3: 1010,
            LIBELLEN3: 'EL ATIBA',
            IDN2: 18,
            CODEP: '7040',
          },
          {
            IDN3: 1011,
            LIBELLEN3: 'EL GARIA',
            IDN2: 18,
            CODEP: '7040',
          },
          {
            IDN3: 1012,
            LIBELLEN3: 'EL JAFNA',
            IDN2: 18,
            CODEP: '7040',
          },
          {
            IDN3: 1013,
            LIBELLEN3: 'GHEZALA',
            IDN2: 18,
            CODEP: '7040',
          },
          {
            IDN3: 1014,
            LIBELLEN3: 'HENCHIR ETTOUILA',
            IDN2: 18,
            CODEP: '7040',
          },
          {
            IDN3: 1015,
            LIBELLEN3: 'LAOUILIA',
            IDN2: 18,
            CODEP: '7040',
          },
          {
            IDN3: 1016,
            LIBELLEN3: 'OUED EZZITOUN',
            IDN2: 18,
            CODEP: '7040',
          },
          {
            IDN3: 1017,
            LIBELLEN3: 'OULED EL MAY',
            IDN2: 18,
            CODEP: '7040',
          },
        ],
      },
      {
        ville: 19,
        villeName: 'JARZOUNA',
        cites: [
          {
            IDN3: 1018,
            LIBELLEN3: 'CITE BEN AROUS',
            IDN2: 19,
            CODEP: '7021',
          },
          {
            IDN3: 1019,
            LIBELLEN3: 'CITE BOUCHOUCHA 2',
            IDN2: 19,
            CODEP: '7021',
          },
          {
            IDN3: 1020,
            LIBELLEN3: 'CITE CALYPTUS',
            IDN2: 19,
            CODEP: '7021',
          },
          {
            IDN3: 1021,
            LIBELLEN3: 'CITE DU SOLEIL',
            IDN2: 19,
            CODEP: '7021',
          },
          {
            IDN3: 1022,
            LIBELLEN3: 'CITE ENNACIM',
            IDN2: 19,
            CODEP: '7021',
          },
          {
            IDN3: 1023,
            LIBELLEN3: 'CITE ERRAJA 1',
            IDN2: 19,
            CODEP: '7021',
          },
          {
            IDN3: 1024,
            LIBELLEN3: 'CITE ERRAJA 2',
            IDN2: 19,
            CODEP: '7021',
          },
          {
            IDN3: 1025,
            LIBELLEN3: 'CITE ERRAJA 3',
            IDN2: 19,
            CODEP: '7021',
          },
          {
            IDN3: 1026,
            LIBELLEN3: 'CITE ERRIMEL',
            IDN2: 19,
            CODEP: '7021',
          },
          {
            IDN3: 1027,
            LIBELLEN3: 'CITE ERRSASS',
            IDN2: 19,
            CODEP: '7021',
          },
          {
            IDN3: 1028,
            LIBELLEN3: 'CITE ESCALIERS',
            IDN2: 19,
            CODEP: '7021',
          },
          {
            IDN3: 1029,
            LIBELLEN3: 'CITE ESSADAKA',
            IDN2: 19,
            CODEP: '7021',
          },
          {
            IDN3: 1030,
            LIBELLEN3: 'CITE GABSI',
            IDN2: 19,
            CODEP: '7021',
          },
          {
            IDN3: 1031,
            LIBELLEN3: 'CITE HAMDOUN',
            IDN2: 19,
            CODEP: '7021',
          },
          {
            IDN3: 1032,
            LIBELLEN3: 'CITE JAZIRI',
            IDN2: 19,
            CODEP: '7021',
          },
          {
            IDN3: 1033,
            LIBELLEN3: 'CITE LUNE',
            IDN2: 19,
            CODEP: '7021',
          },
          {
            IDN3: 1034,
            LIBELLEN3: 'CITE MOHAMED CHEHIMI',
            IDN2: 19,
            CODEP: '7021',
          },
          {
            IDN3: 1035,
            LIBELLEN3: 'CITE NOUVELLE',
            IDN2: 19,
            CODEP: '7021',
          },
          {
            IDN3: 1036,
            LIBELLEN3: 'JARZOUNA',
            IDN2: 19,
            CODEP: '7021',
          },
        ],
      },
      {
        ville: 20,
        villeName: 'JOUMINE',
        cites: [
          {
            IDN3: 1037,
            LIBELLEN3: 'BAZINA',
            IDN2: 20,
            CODEP: '7012',
          },
          {
            IDN3: 1038,
            LIBELLEN3: 'JOUMINE',
            IDN2: 20,
            CODEP: '7020',
          },
          {
            IDN3: 1039,
            LIBELLEN3: 'SAYAH',
            IDN2: 20,
            CODEP: '7020',
          },
          {
            IDN3: 1040,
            LIBELLEN3: 'SMADAH',
            IDN2: 20,
            CODEP: '7020',
          },
        ],
      },
      {
        ville: 21,
        villeName: 'MATEUR',
        cites: [
          {
            IDN3: 1041,
            LIBELLEN3: 'BORJ EL ADOUANI',
            IDN2: 21,
            CODEP: '7022',
          },
          {
            IDN3: 1049,
            LIBELLEN3: 'CITE DE LA FRATERNITE',
            IDN2: 21,
            CODEP: '7030',
          },
          {
            IDN3: 1052,
            LIBELLEN3: 'CITE DE LA GARE',
            IDN2: 21,
            CODEP: '7030',
          },
          {
            IDN3: 1053,
            LIBELLEN3: 'CITE EL MANAR',
            IDN2: 21,
            CODEP: '7030',
          },
          {
            IDN3: 1054,
            LIBELLEN3: 'CITE EL OMRANE 1',
            IDN2: 21,
            CODEP: '7030',
          },
          {
            IDN3: 1055,
            LIBELLEN3: 'CITE EL OMRANE 2',
            IDN2: 21,
            CODEP: '7030',
          },
          {
            IDN3: 1056,
            LIBELLEN3: 'CITE EL OMRANE 3',
            IDN2: 21,
            CODEP: '7030',
          },
          {
            IDN3: 1060,
            LIBELLEN3: 'CITE EL OMRANE 4',
            IDN2: 21,
            CODEP: '7030',
          },
          {
            IDN3: 1062,
            LIBELLEN3: 'CITE ENNAHDHA',
            IDN2: 21,
            CODEP: '7030',
          },
          {
            IDN3: 1066,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 21,
            CODEP: '7030',
          },
          {
            IDN3: 1067,
            LIBELLEN3: 'CITE ERRAJA 1',
            IDN2: 21,
            CODEP: '7030',
          },
          {
            IDN3: 1068,
            LIBELLEN3: 'CITE ERRAJA 2',
            IDN2: 21,
            CODEP: '7030',
          },
          {
            IDN3: 1069,
            LIBELLEN3: 'CITE ERRAJA 3',
            IDN2: 21,
            CODEP: '7030',
          },
          {
            IDN3: 1073,
            LIBELLEN3: 'CITE ESSADAKA',
            IDN2: 21,
            CODEP: '7030',
          },
          {
            IDN3: 1050,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 21,
            CODEP: '7030',
          },
          {
            IDN3: 1042,
            LIBELLEN3: 'CITE HACHED',
            IDN2: 21,
            CODEP: '7022',
          },
          {
            IDN3: 1051,
            LIBELLEN3: 'CITE KHEMOUMA',
            IDN2: 21,
            CODEP: '7030',
          },
          {
            IDN3: 1057,
            LIBELLEN3: 'CITE MONTAZAH',
            IDN2: 21,
            CODEP: '7030',
          },
          {
            IDN3: 1058,
            LIBELLEN3: 'CITE SNIT',
            IDN2: 21,
            CODEP: '7030',
          },
          {
            IDN3: 1059,
            LIBELLEN3: 'CITE ZARROUK',
            IDN2: 21,
            CODEP: '7030',
          },
          {
            IDN3: 1061,
            LIBELLEN3: 'EL AOUANA',
            IDN2: 21,
            CODEP: '7030',
          },
          {
            IDN3: 1043,
            LIBELLEN3: 'ETTAREF',
            IDN2: 21,
            CODEP: '7022',
          },
          {
            IDN3: 1074,
            LIBELLEN3: 'HENCHIR EL BERNA',
            IDN2: 21,
            CODEP: '7050',
          },
          {
            IDN3: 1044,
            LIBELLEN3: 'HENCHIR EL KAHLA',
            IDN2: 21,
            CODEP: '7022',
          },
          {
            IDN3: 1045,
            LIBELLEN3: 'JABOUZ',
            IDN2: 21,
            CODEP: '7022',
          },
          {
            IDN3: 1063,
            LIBELLEN3: 'JEBEL ICHKEUL',
            IDN2: 21,
            CODEP: '7030',
          },
          {
            IDN3: 1064,
            LIBELLEN3: 'MATEUR',
            IDN2: 21,
            CODEP: '7030',
          },
          {
            IDN3: 1046,
            LIBELLEN3: 'MATEUR HACHED',
            IDN2: 21,
            CODEP: '7022',
          },
          {
            IDN3: 1047,
            LIBELLEN3: 'OUM JENNA',
            IDN2: 21,
            CODEP: '7022',
          },
          {
            IDN3: 1065,
            LIBELLEN3: 'SIDI MANSOUR',
            IDN2: 21,
            CODEP: '7030',
          },
          {
            IDN3: 1070,
            LIBELLEN3: 'SIDI NSIR',
            IDN2: 21,
            CODEP: '7030',
          },
          {
            IDN3: 1048,
            LIBELLEN3: 'SIDI SALEM',
            IDN2: 21,
            CODEP: '7022',
          },
          {
            IDN3: 1071,
            LIBELLEN3: 'SOUIDIA',
            IDN2: 21,
            CODEP: '7030',
          },
          {
            IDN3: 1072,
            LIBELLEN3: 'TEHENT',
            IDN2: 21,
            CODEP: '7030',
          },
        ],
      },
      {
        ville: 10,
        villeName: 'MENZEL BOURGUIBA',
        cites: [
          {
            IDN3: 3,
            LIBELLEN3: 'CITE BEN ALEYA',
            IDN2: 10,
            CODEP: '7050',
          },
          {
            IDN3: 4,
            LIBELLEN3: 'CITE DE LA DOUANE',
            IDN2: 10,
            CODEP: '7050',
          },
          {
            IDN3: 5,
            LIBELLEN3: 'CITE LA RUCHE',
            IDN2: 10,
            CODEP: '7050',
          },
          {
            IDN3: 6,
            LIBELLEN3: 'CITE NOUVELLE',
            IDN2: 10,
            CODEP: '7050',
          },
          {
            IDN3: 7,
            LIBELLEN3: 'CITE SIDI YAHYA',
            IDN2: 10,
            CODEP: '7050',
          },
          {
            IDN3: 2,
            LIBELLEN3: 'JOUAOUDA',
            IDN2: 10,
            CODEP: '7042',
          },
          {
            IDN3: 8,
            LIBELLEN3: 'MENZEL BOURGUIBA',
            IDN2: 10,
            CODEP: '7050',
          },
          {
            IDN3: 9,
            LIBELLEN3: 'MENZEL BOURGUIBA ENNAJAH',
            IDN2: 10,
            CODEP: '7072',
          },
        ],
      },
      {
        ville: 12,
        villeName: 'MENZEL JEMIL',
        cites: [
          {
            IDN3: 881,
            LIBELLEN3: 'CITE 7 NOVEMBRE',
            IDN2: 12,
            CODEP: '7026',
          },
          {
            IDN3: 889,
            LIBELLEN3: 'CITE BIR HMEM',
            IDN2: 12,
            CODEP: '7035',
          },
          {
            IDN3: 898,
            LIBELLEN3: 'CITE BIR REMEL',
            IDN2: 12,
            CODEP: '7080',
          },
          {
            IDN3: 890,
            LIBELLEN3: 'CITE CHAMELLE',
            IDN2: 12,
            CODEP: '7035',
          },
          {
            IDN3: 882,
            LIBELLEN3: 'CITE DES ORANGES',
            IDN2: 12,
            CODEP: '7026',
          },
          {
            IDN3: 891,
            LIBELLEN3: 'CITE DRISS BEN FREJ',
            IDN2: 12,
            CODEP: '7035',
          },
          {
            IDN3: 899,
            LIBELLEN3: 'CITE EL AHLEM',
            IDN2: 12,
            CODEP: '7080',
          },
          {
            IDN3: 900,
            LIBELLEN3: 'CITE EL BAALI',
            IDN2: 12,
            CODEP: '7080',
          },
          {
            IDN3: 901,
            LIBELLEN3: 'CITE EL BATAN',
            IDN2: 12,
            CODEP: '7080',
          },
          {
            IDN3: 883,
            LIBELLEN3: 'CITE EL HABIB (EL AZIB)',
            IDN2: 12,
            CODEP: '7026',
          },
          {
            IDN3: 902,
            LIBELLEN3: 'CITE EL HABIB (MENZEL JEMIL)',
            IDN2: 12,
            CODEP: '7080',
          },
          {
            IDN3: 903,
            LIBELLEN3: 'CITE EL HADDADIA',
            IDN2: 12,
            CODEP: '7080',
          },
          {
            IDN3: 904,
            LIBELLEN3: 'CITE EL HBESS',
            IDN2: 12,
            CODEP: '7080',
          },
          {
            IDN3: 892,
            LIBELLEN3: 'CITE EL MAKAREM',
            IDN2: 12,
            CODEP: '7035',
          },
          {
            IDN3: 905,
            LIBELLEN3: 'CITE ENNOUZHA',
            IDN2: 12,
            CODEP: '7080',
          },
          {
            IDN3: 906,
            LIBELLEN3: 'CITE ETTAFFALA',
            IDN2: 12,
            CODEP: '7080',
          },
          {
            IDN3: 893,
            LIBELLEN3: 'CITE ETTAHRIR',
            IDN2: 12,
            CODEP: '7035',
          },
          {
            IDN3: 894,
            LIBELLEN3: 'CITE ETTAKADDOUM',
            IDN2: 12,
            CODEP: '7035',
          },
          {
            IDN3: 907,
            LIBELLEN3: 'CITE HABIB ARIFA',
            IDN2: 12,
            CODEP: '7080',
          },
          {
            IDN3: 895,
            LIBELLEN3: 'CITE INDEPENDANCE',
            IDN2: 12,
            CODEP: '7035',
          },
          {
            IDN3: 908,
            LIBELLEN3: 'CITE NOUVELLE',
            IDN2: 12,
            CODEP: '7080',
          },
          {
            IDN3: 884,
            LIBELLEN3: 'CITE RURALE 1',
            IDN2: 12,
            CODEP: '7026',
          },
          {
            IDN3: 885,
            LIBELLEN3: 'CITE RURALE 2',
            IDN2: 12,
            CODEP: '7026',
          },
          {
            IDN3: 896,
            LIBELLEN3: 'EL AIN EL KEBIRA',
            IDN2: 12,
            CODEP: '7035',
          },
          {
            IDN3: 886,
            LIBELLEN3: 'EL AZIB',
            IDN2: 12,
            CODEP: '7026',
          },
          {
            IDN3: 887,
            LIBELLEN3: 'HENCHIR EL MOUKAOUIMINE',
            IDN2: 12,
            CODEP: '7026',
          },
          {
            IDN3: 888,
            LIBELLEN3: 'MAGHRAOUA',
            IDN2: 12,
            CODEP: '7026',
          },
          {
            IDN3: 897,
            LIBELLEN3: 'MENZEL ABDERRAHMAN',
            IDN2: 12,
            CODEP: '7035',
          },
          {
            IDN3: 909,
            LIBELLEN3: 'MENZEL JEMIL',
            IDN2: 12,
            CODEP: '7080',
          },
        ],
      },
      {
        ville: 22,
        villeName: 'RAS JEBEL',
        cites: [
          {
            IDN3: 1088,
            LIBELLEN3: 'BENI ATTA',
            IDN2: 22,
            CODEP: '7075',
          },
          {
            IDN3: 1079,
            LIBELLEN3: 'CAP ZBIB',
            IDN2: 22,
            CODEP: '7034',
          },
          {
            IDN3: 1082,
            LIBELLEN3: 'CITE AIN CHARCHARA',
            IDN2: 22,
            CODEP: '7070',
          },
          {
            IDN3: 1083,
            LIBELLEN3: 'CITE BIR EZZAAROUR',
            IDN2: 22,
            CODEP: '7070',
          },
          {
            IDN3: 1075,
            LIBELLEN3: 'CITE EL AMEL',
            IDN2: 22,
            CODEP: '7015',
          },
          {
            IDN3: 1084,
            LIBELLEN3: 'CITE EL BENZARTI',
            IDN2: 22,
            CODEP: '7070',
          },
          {
            IDN3: 1085,
            LIBELLEN3: 'CITE EZZAYATINE',
            IDN2: 22,
            CODEP: '7070',
          },
          {
            IDN3: 1086,
            LIBELLEN3: 'CITE KAA EL BALLOUT',
            IDN2: 22,
            CODEP: '7070',
          },
          {
            IDN3: 1076,
            LIBELLEN3: 'CITE TAIEB MHIRI',
            IDN2: 22,
            CODEP: '7015',
          },
          {
            IDN3: 1089,
            LIBELLEN3: 'EL GARIA RAS JEBAL',
            IDN2: 22,
            CODEP: '7098',
          },
          {
            IDN3: 1080,
            LIBELLEN3: 'METLINE',
            IDN2: 22,
            CODEP: '7034',
          },
          {
            IDN3: 1077,
            LIBELLEN3: 'RAFRAF',
            IDN2: 22,
            CODEP: '7015',
          },
          {
            IDN3: 1081,
            LIBELLEN3: 'RAFRAF PLAGE',
            IDN2: 22,
            CODEP: '7045',
          },
          {
            IDN3: 1087,
            LIBELLEN3: 'RAS JEBEL',
            IDN2: 22,
            CODEP: '7070',
          },
          {
            IDN3: 1078,
            LIBELLEN3: 'SOUNINE',
            IDN2: 22,
            CODEP: '7025',
          },
        ],
      },
      {
        ville: 16,
        villeName: 'SEJNANE',
        cites: [
          {
            IDN3: 978,
            LIBELLEN3: 'CITE AIR NOUVELLE',
            IDN2: 16,
            CODEP: '7010',
          },
          {
            IDN3: 979,
            LIBELLEN3: 'CITE AOUASSIA',
            IDN2: 16,
            CODEP: '7010',
          },
          {
            IDN3: 980,
            LIBELLEN3: 'CITE COMMERCIALE',
            IDN2: 16,
            CODEP: '7010',
          },
          {
            IDN3: 981,
            LIBELLEN3: 'CITE DES MINES',
            IDN2: 16,
            CODEP: '7010',
          },
          {
            IDN3: 982,
            LIBELLEN3: 'CITE DES OUVRIERS',
            IDN2: 16,
            CODEP: '7010',
          },
          {
            IDN3: 964,
            LIBELLEN3: 'CITE DU PROJET',
            IDN2: 16,
            CODEP: '7010',
          },
          {
            IDN3: 965,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 16,
            CODEP: '7010',
          },
          {
            IDN3: 966,
            LIBELLEN3: 'CITE EL GHABETTE',
            IDN2: 16,
            CODEP: '7010',
          },
          {
            IDN3: 967,
            LIBELLEN3: 'CITE EL MOUASKER',
            IDN2: 16,
            CODEP: '7010',
          },
          {
            IDN3: 968,
            LIBELLEN3: 'CITE ESSAADA',
            IDN2: 16,
            CODEP: '7010',
          },
          {
            IDN3: 969,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 16,
            CODEP: '7010',
          },
          {
            IDN3: 970,
            LIBELLEN3: 'CITE EZZOUHOUR 2',
            IDN2: 16,
            CODEP: '7010',
          },
          {
            IDN3: 971,
            LIBELLEN3: 'CITE IBN KHALDOUN',
            IDN2: 16,
            CODEP: '7010',
          },
          {
            IDN3: 972,
            LIBELLEN3: 'CITE KHALED IBN EL WALID',
            IDN2: 16,
            CODEP: '7010',
          },
          {
            IDN3: 973,
            LIBELLEN3: 'CITE MOUGAAD',
            IDN2: 16,
            CODEP: '7010',
          },
          {
            IDN3: 974,
            LIBELLEN3: 'CITE SIDI MANSOUR',
            IDN2: 16,
            CODEP: '7010',
          },
          {
            IDN3: 976,
            LIBELLEN3: 'CITE TAIEB MHIRI',
            IDN2: 16,
            CODEP: '7010',
          },
          {
            IDN3: 977,
            LIBELLEN3: 'DHOUAHRIA',
            IDN2: 16,
            CODEP: '7010',
          },
          {
            IDN3: 983,
            LIBELLEN3: 'EL ABEBSA',
            IDN2: 16,
            CODEP: '7010',
          },
          {
            IDN3: 991,
            LIBELLEN3: 'EL GUETMA',
            IDN2: 16,
            CODEP: '7097',
          },
          {
            IDN3: 986,
            LIBELLEN3: 'EL HANIA',
            IDN2: 16,
            CODEP: '7010',
          },
          {
            IDN3: 984,
            LIBELLEN3: 'EL MRIFEG',
            IDN2: 16,
            CODEP: '7010',
          },
          {
            IDN3: 985,
            LIBELLEN3: 'ESSMAYRIA',
            IDN2: 16,
            CODEP: '7010',
          },
          {
            IDN3: 987,
            LIBELLEN3: 'HADDADA',
            IDN2: 16,
            CODEP: '7010',
          },
          {
            IDN3: 988,
            LIBELLEN3: 'SEJNANE',
            IDN2: 16,
            CODEP: '7010',
          },
          {
            IDN3: 989,
            LIBELLEN3: 'SIDI MACHREG',
            IDN2: 16,
            CODEP: '7010',
          },
          {
            IDN3: 990,
            LIBELLEN3: 'TAMRA',
            IDN2: 16,
            CODEP: '7031',
          },
          {
            IDN3: 975,
            LIBELLEN3: 'TOUAJNIA',
            IDN2: 16,
            CODEP: '7010',
          },
        ],
      },
      {
        ville: 23,
        villeName: 'TINJA',
        cites: [
          {
            IDN3: 1100,
            LIBELLEN3: 'AIN FAROUA',
            IDN2: 23,
            CODEP: '7032',
          },
          {
            IDN3: 1101,
            LIBELLEN3: 'CITE DE LE LIBERTE',
            IDN2: 23,
            CODEP: '7032',
          },
          {
            IDN3: 1090,
            LIBELLEN3: 'CITE DOUAR CHATT',
            IDN2: 23,
            CODEP: '7032',
          },
          {
            IDN3: 1091,
            LIBELLEN3: 'CITE EL FATEH',
            IDN2: 23,
            CODEP: '7032',
          },
          {
            IDN3: 1092,
            LIBELLEN3: 'CITE EL FOULADH',
            IDN2: 23,
            CODEP: '7032',
          },
          {
            IDN3: 1093,
            LIBELLEN3: 'CITE EL KHADHRAOUI',
            IDN2: 23,
            CODEP: '7032',
          },
          {
            IDN3: 1094,
            LIBELLEN3: 'CITE IKBAL',
            IDN2: 23,
            CODEP: '7032',
          },
          {
            IDN3: 1095,
            LIBELLEN3: 'CITE SIDI REZIG',
            IDN2: 23,
            CODEP: '7032',
          },
          {
            IDN3: 1096,
            LIBELLEN3: 'CITE SPROLS',
            IDN2: 23,
            CODEP: '7032',
          },
          {
            IDN3: 1097,
            LIBELLEN3: 'CITE ZGHABA',
            IDN2: 23,
            CODEP: '7032',
          },
          {
            IDN3: 1098,
            LIBELLEN3: 'KHARRATA',
            IDN2: 23,
            CODEP: '7032',
          },
          {
            IDN3: 1099,
            LIBELLEN3: 'TINJA',
            IDN2: 23,
            CODEP: '7032',
          },
        ],
      },
      {
        ville: 11,
        villeName: 'UTIQUE',
        cites: [
          {
            IDN3: 17,
            LIBELLEN3: 'AIN GHELAL',
            IDN2: 11,
            CODEP: '7013',
          },
          {
            IDN3: 18,
            LIBELLEN3: 'AIN SMARA',
            IDN2: 11,
            CODEP: '7013',
          },
          {
            IDN3: 27,
            LIBELLEN3: 'BACH HAMBA',
            IDN2: 11,
            CODEP: '7043',
          },
          {
            IDN3: 28,
            LIBELLEN3: 'CITE BOURGUIBA',
            IDN2: 11,
            CODEP: '7060',
          },
          {
            IDN3: 29,
            LIBELLEN3: 'CITE CHATEAU D EAU',
            IDN2: 11,
            CODEP: '7060',
          },
          {
            IDN3: 30,
            LIBELLEN3: 'CITE DE LA LAITERIE',
            IDN2: 11,
            CODEP: '7060',
          },
          {
            IDN3: 31,
            LIBELLEN3: 'CITE DE LA POSTE',
            IDN2: 11,
            CODEP: '7060',
          },
          {
            IDN3: 10,
            LIBELLEN3: 'CITE EJJAMAA',
            IDN2: 11,
            CODEP: '7060',
          },
          {
            IDN3: 11,
            LIBELLEN3: 'CITE EZZAYATINE',
            IDN2: 11,
            CODEP: '7060',
          },
          {
            IDN3: 12,
            LIBELLEN3: 'CITE HOUMET SOUK',
            IDN2: 11,
            CODEP: '7060',
          },
          {
            IDN3: 23,
            LIBELLEN3: 'DEGMA',
            IDN2: 11,
            CODEP: '7023',
          },
          {
            IDN3: 19,
            LIBELLEN3: 'EL BESBASSIA',
            IDN2: 11,
            CODEP: '7013',
          },
          {
            IDN3: 20,
            LIBELLEN3: 'EL BHALIL CHARGUI',
            IDN2: 11,
            CODEP: '7013',
          },
          {
            IDN3: 21,
            LIBELLEN3: 'EL BRIJ',
            IDN2: 11,
            CODEP: '7013',
          },
          {
            IDN3: 22,
            LIBELLEN3: 'EL FEJJA',
            IDN2: 11,
            CODEP: '7013',
          },
          {
            IDN3: 24,
            LIBELLEN3: 'EL MABTOUH',
            IDN2: 11,
            CODEP: '7023',
          },
          {
            IDN3: 25,
            LIBELLEN3: 'ESSAKAK',
            IDN2: 11,
            CODEP: '7023',
          },
          {
            IDN3: 13,
            LIBELLEN3: 'GHORNATA',
            IDN2: 11,
            CODEP: '7060',
          },
          {
            IDN3: 14,
            LIBELLEN3: 'LAHOUIDH',
            IDN2: 11,
            CODEP: '7060',
          },
          {
            IDN3: 26,
            LIBELLEN3: 'SIDI OTHMAN',
            IDN2: 11,
            CODEP: '7023',
          },
          {
            IDN3: 15,
            LIBELLEN3: 'UTIQUE',
            IDN2: 11,
            CODEP: '7060',
          },
          {
            IDN3: 16,
            LIBELLEN3: 'UTIQUE NOUVELLE',
            IDN2: 11,
            CODEP: '7063',
          },
        ],
      },
    ],
  },
  {
    govLabel: 'GABES',
    govId: 5,
    villes: [
      {
        ville: 111,
        villeName: 'EL HAMMA',
        cites: [
          {
            IDN3: 1198,
            LIBELLEN3: 'BECHIMA',
            IDN2: 111,
            CODEP: '6092',
          },
          {
            IDN3: 1204,
            LIBELLEN3: 'BENI GHILOUF',
            IDN2: 111,
            CODEP: '6062',
          },
          {
            IDN3: 1202,
            LIBELLEN3: 'BOU ATTOUCHE',
            IDN2: 111,
            CODEP: '6067',
          },
          {
            IDN3: 1199,
            LIBELLEN3: 'CHENCHOU',
            IDN2: 111,
            CODEP: '6095',
          },
          {
            IDN3: 1201,
            LIBELLEN3: 'EL HAMMA',
            IDN2: 111,
            CODEP: '6020',
          },
          {
            IDN3: 1203,
            LIBELLEN3: 'EL HAMMA SUD',
            IDN2: 111,
            CODEP: '6060',
          },
          {
            IDN3: 1200,
            LIBELLEN3: 'SOMBAT',
            IDN2: 111,
            CODEP: '6013',
          },
        ],
      },
      {
        ville: 107,
        villeName: 'EL METOUIA',
        cites: [
          {
            IDN3: 1102,
            LIBELLEN3: 'CITE EL FANKAR',
            IDN2: 107,
            CODEP: '6010',
          },
          {
            IDN3: 1103,
            LIBELLEN3: 'CITE EL MAYA',
            IDN2: 107,
            CODEP: '6010',
          },
          {
            IDN3: 1104,
            LIBELLEN3: 'CITE EL MRACHDA',
            IDN2: 107,
            CODEP: '6010',
          },
          {
            IDN3: 1105,
            LIBELLEN3: 'CITE ENNACIM',
            IDN2: 107,
            CODEP: '6010',
          },
          {
            IDN3: 1114,
            LIBELLEN3: 'CITE ENNACIM',
            IDN2: 107,
            CODEP: '6010',
          },
          {
            IDN3: 1115,
            LIBELLEN3: 'CITE ESSALEM',
            IDN2: 107,
            CODEP: '6052',
          },
          {
            IDN3: 1106,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 107,
            CODEP: '6010',
          },
          {
            IDN3: 1120,
            LIBELLEN3: 'CITE KHEIREDDINE',
            IDN2: 107,
            CODEP: '6052',
          },
          {
            IDN3: 1107,
            LIBELLEN3: 'CITE NEHILA FARHAT',
            IDN2: 107,
            CODEP: '6010',
          },
          {
            IDN3: 1112,
            LIBELLEN3: 'EL AKARIT',
            IDN2: 107,
            CODEP: '6042',
          },
          {
            IDN3: 1108,
            LIBELLEN3: 'EL AOUINETTE',
            IDN2: 107,
            CODEP: '6010',
          },
          {
            IDN3: 1113,
            LIBELLEN3: 'EL HICHA',
            IDN2: 107,
            CODEP: '6042',
          },
          {
            IDN3: 1109,
            LIBELLEN3: 'EL IMARAT',
            IDN2: 107,
            CODEP: '6010',
          },
          {
            IDN3: 1110,
            LIBELLEN3: 'EL METOUIA',
            IDN2: 107,
            CODEP: '6010',
          },
          {
            IDN3: 1116,
            LIBELLEN3: 'EL MIDA',
            IDN2: 107,
            CODEP: '6052',
          },
          {
            IDN3: 1119,
            LIBELLEN3: 'METOUIA EL MAYA',
            IDN2: 107,
            CODEP: '6089',
          },
          {
            IDN3: 1117,
            LIBELLEN3: 'OUALI',
            IDN2: 107,
            CODEP: '6052',
          },
          {
            IDN3: 1118,
            LIBELLEN3: 'OUDHREF',
            IDN2: 107,
            CODEP: '6052',
          },
          {
            IDN3: 1111,
            LIBELLEN3: 'OUED EL GHRAM',
            IDN2: 107,
            CODEP: '6010',
          },
        ],
      },
      {
        ville: 108,
        villeName: 'GABES MEDINA',
        cites: [
          {
            IDN3: 1137,
            LIBELLEN3: 'CHATT ESSALEM',
            IDN2: 108,
            CODEP: '6061',
          },
          {
            IDN3: 1138,
            LIBELLEN3: 'CITE 26-26',
            IDN2: 108,
            CODEP: '6061',
          },
          {
            IDN3: 1121,
            LIBELLEN3: 'CITE BEN KILANI',
            IDN2: 108,
            CODEP: '6000',
          },
          {
            IDN3: 1122,
            LIBELLEN3: 'CITE COMMERCIALE',
            IDN2: 108,
            CODEP: '6000',
          },
          {
            IDN3: 1123,
            LIBELLEN3: 'CITE DES OASIS',
            IDN2: 108,
            CODEP: '6000',
          },
          {
            IDN3: 1139,
            LIBELLEN3: 'CITE EL BLED',
            IDN2: 108,
            CODEP: '6061',
          },
          {
            IDN3: 1140,
            LIBELLEN3: 'CITE EL FTARCHA',
            IDN2: 108,
            CODEP: '6061',
          },
          {
            IDN3: 1124,
            LIBELLEN3: 'CITE EL HANA',
            IDN2: 108,
            CODEP: '6000',
          },
          {
            IDN3: 1125,
            LIBELLEN3: 'CITE EL KHALIJ',
            IDN2: 108,
            CODEP: '6000',
          },
          {
            IDN3: 1126,
            LIBELLEN3: 'CITE EL KORNICHE',
            IDN2: 108,
            CODEP: '6000',
          },
          {
            IDN3: 1127,
            LIBELLEN3: 'CITE EL MANSOURA',
            IDN2: 108,
            CODEP: '6000',
          },
          {
            IDN3: 1128,
            LIBELLEN3: 'CITE EL OUNS',
            IDN2: 108,
            CODEP: '6000',
          },
          {
            IDN3: 1129,
            LIBELLEN3: 'CITE MOHAMED ALI',
            IDN2: 108,
            CODEP: '6000',
          },
          {
            IDN3: 1130,
            LIBELLEN3: 'EL MOUAZIR',
            IDN2: 108,
            CODEP: '6000',
          },
          {
            IDN3: 1131,
            LIBELLEN3: 'GABES',
            IDN2: 108,
            CODEP: '6000',
          },
          {
            IDN3: 1142,
            LIBELLEN3: 'GABES EL HIDAYA',
            IDN2: 108,
            CODEP: '6099',
          },
          {
            IDN3: 1135,
            LIBELLEN3: 'GABES HACHED',
            IDN2: 108,
            CODEP: '6001',
          },
          {
            IDN3: 1141,
            LIBELLEN3: 'GABES PORT',
            IDN2: 108,
            CODEP: '6071',
          },
          {
            IDN3: 1136,
            LIBELLEN3: 'GABES REPUBLIQUE',
            IDN2: 108,
            CODEP: '6040',
          },
          {
            IDN3: 1132,
            LIBELLEN3: 'LIMAOUA',
            IDN2: 108,
            CODEP: '6000',
          },
          {
            IDN3: 1133,
            LIBELLEN3: 'OUED KHELIFA MAHMOUD',
            IDN2: 108,
            CODEP: '6000',
          },
          {
            IDN3: 1134,
            LIBELLEN3: 'REMATHI',
            IDN2: 108,
            CODEP: '6000',
          },
        ],
      },
      {
        ville: 109,
        villeName: 'GABES OUEST',
        cites: [
          {
            IDN3: 1146,
            LIBELLEN3: 'BARGUIA',
            IDN2: 109,
            CODEP: '6041',
          },
          {
            IDN3: 1143,
            LIBELLEN3: 'BOU CHEMMA',
            IDN2: 109,
            CODEP: '6031',
          },
          {
            IDN3: 1147,
            LIBELLEN3: 'CHENINI GABES',
            IDN2: 109,
            CODEP: '6041',
          },
          {
            IDN3: 1144,
            LIBELLEN3: 'CITE 2 MARS',
            IDN2: 109,
            CODEP: '6031',
          },
          {
            IDN3: 1145,
            LIBELLEN3: 'CITE ETTAHRIR',
            IDN2: 109,
            CODEP: '6031',
          },
          {
            IDN3: 1148,
            LIBELLEN3: 'MAITA',
            IDN2: 109,
            CODEP: '6041',
          },
          {
            IDN3: 1149,
            LIBELLEN3: 'NAHAL',
            IDN2: 109,
            CODEP: '6051',
          },
        ],
      },
      {
        ville: 110,
        villeName: 'GABES SUD',
        cites: [
          {
            IDN3: 1182,
            LIBELLEN3: 'CITE 7 NOVEMBRE',
            IDN2: 110,
            CODEP: '6032',
          },
          {
            IDN3: 1197,
            LIBELLEN3: 'CITE AFH',
            IDN2: 110,
            CODEP: '6012',
          },
          {
            IDN3: 1154,
            LIBELLEN3: 'CITE AFH',
            IDN2: 110,
            CODEP: '6012',
          },
          {
            IDN3: 1155,
            LIBELLEN3: 'CITE ANCIEN MILITAIRE',
            IDN2: 110,
            CODEP: '6012',
          },
          {
            IDN3: 1163,
            LIBELLEN3: 'CITE AZAIEZ',
            IDN2: 110,
            CODEP: '6014',
          },
          {
            IDN3: 1183,
            LIBELLEN3: 'CITE BEDIS',
            IDN2: 110,
            CODEP: '6032',
          },
          {
            IDN3: 1157,
            LIBELLEN3: 'CITE BIDANI',
            IDN2: 110,
            CODEP: '6012',
          },
          {
            IDN3: 1156,
            LIBELLEN3: 'CITE DE LA FAMILLE',
            IDN2: 110,
            CODEP: '6014',
          },
          {
            IDN3: 1169,
            LIBELLEN3: 'CITE DES ENSEIGNANTS',
            IDN2: 110,
            CODEP: '6014',
          },
          {
            IDN3: 1170,
            LIBELLEN3: 'CITE DES INFIRMIERS',
            IDN2: 110,
            CODEP: '6014',
          },
          {
            IDN3: 1164,
            LIBELLEN3: 'CITE EL AHRACH',
            IDN2: 110,
            CODEP: '6072',
          },
          {
            IDN3: 1187,
            LIBELLEN3: 'CITE EL AMEL',
            IDN2: 110,
            CODEP: '6033',
          },
          {
            IDN3: 1188,
            LIBELLEN3: 'CITE EL AMEL 1',
            IDN2: 110,
            CODEP: '6033',
          },
          {
            IDN3: 1189,
            LIBELLEN3: 'CITE EL AMEL 2',
            IDN2: 110,
            CODEP: '6033',
          },
          {
            IDN3: 1190,
            LIBELLEN3: 'CITE EL AMEL 3',
            IDN2: 110,
            CODEP: '6033',
          },
          {
            IDN3: 1191,
            LIBELLEN3: 'CITE EL AMEL 4',
            IDN2: 110,
            CODEP: '6033',
          },
          {
            IDN3: 1192,
            LIBELLEN3: 'CITE EL AMEL 5',
            IDN2: 110,
            CODEP: '6033',
          },
          {
            IDN3: 1193,
            LIBELLEN3: 'CITE EL AMEL 6',
            IDN2: 110,
            CODEP: '6033',
          },
          {
            IDN3: 1165,
            LIBELLEN3: 'CITE EL GUEFERCHA',
            IDN2: 110,
            CODEP: '6072',
          },
          {
            IDN3: 1158,
            LIBELLEN3: 'CITE EL HANA BOUDOURA',
            IDN2: 110,
            CODEP: '6012',
          },
          {
            IDN3: 1150,
            LIBELLEN3: 'CITE EL IZDIHAR',
            IDN2: 110,
            CODEP: '6011',
          },
          {
            IDN3: 1194,
            LIBELLEN3: 'CITE EL MAARIFA',
            IDN2: 110,
            CODEP: '6033',
          },
          {
            IDN3: 1151,
            LIBELLEN3: 'CITE EL MEDINA',
            IDN2: 110,
            CODEP: '6011',
          },
          {
            IDN3: 1159,
            LIBELLEN3: 'CITE EL MISK',
            IDN2: 110,
            CODEP: '6012',
          },
          {
            IDN3: 1171,
            LIBELLEN3: 'CITE EL MZAHID',
            IDN2: 110,
            CODEP: '6014',
          },
          {
            IDN3: 1184,
            LIBELLEN3: 'CITE EL WAFA',
            IDN2: 110,
            CODEP: '6032',
          },
          {
            IDN3: 1172,
            LIBELLEN3: 'CITE EL YASMINE',
            IDN2: 110,
            CODEP: '6014',
          },
          {
            IDN3: 1195,
            LIBELLEN3: 'CITE ENNAKHIL',
            IDN2: 110,
            CODEP: '6033',
          },
          {
            IDN3: 1196,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 110,
            CODEP: '6033',
          },
          {
            IDN3: 1173,
            LIBELLEN3: 'CITE ENNOZH',
            IDN2: 110,
            CODEP: '6014',
          },
          {
            IDN3: 1174,
            LIBELLEN3: 'CITE ESSEPTIA',
            IDN2: 110,
            CODEP: '6014',
          },
          {
            IDN3: 1175,
            LIBELLEN3: 'CITE EZZOUHOUR 1',
            IDN2: 110,
            CODEP: '6014',
          },
          {
            IDN3: 1176,
            LIBELLEN3: 'CITE EZZOUHOUR 2',
            IDN2: 110,
            CODEP: '6014',
          },
          {
            IDN3: 1185,
            LIBELLEN3: 'CITE HACHED',
            IDN2: 110,
            CODEP: '6032',
          },
          {
            IDN3: 1177,
            LIBELLEN3: 'CITE HACHED 1',
            IDN2: 110,
            CODEP: '6014',
          },
          {
            IDN3: 1178,
            LIBELLEN3: 'CITE HACHED 2',
            IDN2: 110,
            CODEP: '6014',
          },
          {
            IDN3: 1179,
            LIBELLEN3: 'CITE IBN KHALDOUN',
            IDN2: 110,
            CODEP: '6014',
          },
          {
            IDN3: 1152,
            LIBELLEN3: 'CITE MEDENINE',
            IDN2: 110,
            CODEP: '6011',
          },
          {
            IDN3: 1160,
            LIBELLEN3: 'CITE MILITAIRE DIMASSI',
            IDN2: 110,
            CODEP: '6012',
          },
          {
            IDN3: 1161,
            LIBELLEN3: 'CITE RHOUMA',
            IDN2: 110,
            CODEP: '6012',
          },
          {
            IDN3: 1166,
            LIBELLEN3: 'CITE SNIT',
            IDN2: 110,
            CODEP: '6072',
          },
          {
            IDN3: 1167,
            LIBELLEN3: 'CITE SPROLS',
            IDN2: 110,
            CODEP: '6072',
          },
          {
            IDN3: 1181,
            LIBELLEN3: 'EL MDOU',
            IDN2: 110,
            CODEP: '6022',
          },
          {
            IDN3: 1153,
            LIBELLEN3: 'GABES EL MENARA',
            IDN2: 110,
            CODEP: '6011',
          },
          {
            IDN3: 1180,
            LIBELLEN3: 'MTORRECH',
            IDN2: 110,
            CODEP: '6014',
          },
          {
            IDN3: 1162,
            LIBELLEN3: 'SIDI BOULBABA',
            IDN2: 110,
            CODEP: '6012',
          },
          {
            IDN3: 1186,
            LIBELLEN3: 'TEBOULBOU',
            IDN2: 110,
            CODEP: '6032',
          },
          {
            IDN3: 1168,
            LIBELLEN3: 'ZRIG',
            IDN2: 110,
            CODEP: '6072',
          },
        ],
      },
      {
        ville: 114,
        villeName: 'GHANNOUCHE',
        cites: [
          {
            IDN3: 1270,
            LIBELLEN3: 'CITE EL IZDIHAR',
            IDN2: 114,
            CODEP: '6021',
          },
          {
            IDN3: 1271,
            LIBELLEN3: 'CITE ERRIMEL',
            IDN2: 114,
            CODEP: '6021',
          },
          {
            IDN3: 1272,
            LIBELLEN3: 'CITE ETTAHRIR',
            IDN2: 114,
            CODEP: '6021',
          },
          {
            IDN3: 1273,
            LIBELLEN3: 'GHANNOUCHE',
            IDN2: 114,
            CODEP: '6021',
          },
        ],
      },
      {
        ville: 113,
        villeName: 'MARETH',
        cites: [
          {
            IDN3: 1238,
            LIBELLEN3: 'AIN TOUNINE',
            IDN2: 113,
            CODEP: '6035',
          },
          {
            IDN3: 1226,
            LIBELLEN3: 'ANCIEN BLED',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1235,
            LIBELLEN3: 'ARRAM',
            IDN2: 113,
            CODEP: '6016',
          },
          {
            IDN3: 1234,
            LIBELLEN3: 'AYOUNE EZZERKINE',
            IDN2: 113,
            CODEP: '6015',
          },
          {
            IDN3: 1227,
            LIBELLEN3: 'AZEIZA',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1248,
            LIBELLEN3: 'BETAHRA',
            IDN2: 113,
            CODEP: '6081',
          },
          {
            IDN3: 1228,
            LIBELLEN3: 'BETAHRA SUD',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1229,
            LIBELLEN3: 'BRAOUKA',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1230,
            LIBELLEN3: 'CITE COMMERCIALE',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1231,
            LIBELLEN3: 'CITE DEBABA',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1232,
            LIBELLEN3: 'CITE EL AMEL',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1266,
            LIBELLEN3: 'CITE EL ARGOUB',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1267,
            LIBELLEN3: 'CITE EL CHARFIA',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1268,
            LIBELLEN3: 'CITE EL GHARDEK1',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1269,
            LIBELLEN3: 'CITE EL GHARDEK2',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1252,
            LIBELLEN3: 'CITE EL GHARDEK3',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1253,
            LIBELLEN3: 'CITE EL HAFAYA',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1254,
            LIBELLEN3: 'CITE EL KRIBIA',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1255,
            LIBELLEN3: 'CITE EL MASLA',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1256,
            LIBELLEN3: 'CITE EL MELAYHA',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1257,
            LIBELLEN3: 'CITE ENNOUZHA',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1258,
            LIBELLEN3: 'CITE GARDE NATIONALE',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1239,
            LIBELLEN3: 'CITE POPULAIRE',
            IDN2: 113,
            CODEP: '6036',
          },
          {
            IDN3: 1259,
            LIBELLEN3: 'CITE SNIT',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1251,
            LIBELLEN3: 'DEKHILET TOUJANE',
            IDN2: 113,
            CODEP: '6055',
          },
          {
            IDN3: 1260,
            LIBELLEN3: 'EL AKHMES',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1236,
            LIBELLEN3: 'EL ALAYA',
            IDN2: 113,
            CODEP: '6023',
          },
          {
            IDN3: 1261,
            LIBELLEN3: 'EL FRADA',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1262,
            LIBELLEN3: 'EL KAOUAKBA',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1225,
            LIBELLEN3: 'EZZERKINE',
            IDN2: 113,
            CODEP: '6056',
          },
          {
            IDN3: 1263,
            LIBELLEN3: 'GRAA BEIRI',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1240,
            LIBELLEN3: 'KETTANA',
            IDN2: 113,
            CODEP: '6036',
          },
          {
            IDN3: 1264,
            LIBELLEN3: 'MARETH',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1242,
            LIBELLEN3: 'MAZRAA BEN SLAMA',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1265,
            LIBELLEN3: 'MEJNI',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1246,
            LIBELLEN3: 'OUED EL GHIRANE',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1241,
            LIBELLEN3: 'OUED EL HAJEL',
            IDN2: 113,
            CODEP: '6036',
          },
          {
            IDN3: 1245,
            LIBELLEN3: 'OURIFEN',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1243,
            LIBELLEN3: 'SIDI SLAM',
            IDN2: 113,
            CODEP: '6036',
          },
          {
            IDN3: 1250,
            LIBELLEN3: 'SIDI TOUATI',
            IDN2: 113,
            CODEP: '6046',
          },
          {
            IDN3: 1249,
            LIBELLEN3: 'TOUJANE',
            IDN2: 113,
            CODEP: '6045',
          },
          {
            IDN3: 1237,
            LIBELLEN3: 'ZARAT',
            IDN2: 113,
            CODEP: '6026\r\n',
          },
          {
            IDN3: 1233,
            LIBELLEN3: 'ZMORTEN',
            IDN2: 113,
            CODEP: '6080',
          },
          {
            IDN3: 1244,
            LIBELLEN3: 'ZRIG EL BARRANIA',
            IDN2: 113,
            CODEP: '6036',
          },
          {
            IDN3: 1247,
            LIBELLEN3: 'ZRIG EL GHANDRI',
            IDN2: 113,
            CODEP: '6036',
          },
        ],
      },
      {
        ville: 115,
        villeName: 'MATMATA',
        cites: [
          {
            IDN3: 1276,
            LIBELLEN3: 'BOU DHAFER',
            IDN2: 115,
            CODEP: '6070',
          },
          {
            IDN3: 1277,
            LIBELLEN3: 'CHOUABET ESMAALA',
            IDN2: 115,
            CODEP: '6070',
          },
          {
            IDN3: 1278,
            LIBELLEN3: 'CITE EL AZEZNA',
            IDN2: 115,
            CODEP: '6070',
          },
          {
            IDN3: 1279,
            LIBELLEN3: 'CITE HAFI RASSA',
            IDN2: 115,
            CODEP: '6070',
          },
          {
            IDN3: 1280,
            LIBELLEN3: 'CITE HEDI CHAKER',
            IDN2: 115,
            CODEP: '6070',
          },
          {
            IDN3: 1281,
            LIBELLEN3: 'CITE INDEPENDANCE',
            IDN2: 115,
            CODEP: '6070',
          },
          {
            IDN3: 1282,
            LIBELLEN3: 'CITE MOHAMED ALI',
            IDN2: 115,
            CODEP: '6070',
          },
          {
            IDN3: 1283,
            LIBELLEN3: 'CITE RAS EL OUED',
            IDN2: 115,
            CODEP: '6070',
          },
          {
            IDN3: 1284,
            LIBELLEN3: 'HADDEJ',
            IDN2: 115,
            CODEP: '6070',
          },
          {
            IDN3: 1285,
            LIBELLEN3: 'KSAR BENI AISSA',
            IDN2: 115,
            CODEP: '6070',
          },
          {
            IDN3: 1286,
            LIBELLEN3: 'MATMATA',
            IDN2: 115,
            CODEP: '6070',
          },
          {
            IDN3: 1275,
            LIBELLEN3: 'TAMEZRAT',
            IDN2: 115,
            CODEP: '6054',
          },
          {
            IDN3: 1287,
            LIBELLEN3: 'TAOUJOUT',
            IDN2: 115,
            CODEP: '6070',
          },
          {
            IDN3: 1274,
            LIBELLEN3: 'TECHINE',
            IDN2: 115,
            CODEP: '6034',
          },
          {
            IDN3: 1288,
            LIBELLEN3: 'TIJMA',
            IDN2: 115,
            CODEP: '6070',
          },
          {
            IDN3: 1289,
            LIBELLEN3: 'ZRIBA',
            IDN2: 115,
            CODEP: '6070',
          },
        ],
      },
      {
        ville: 116,
        villeName: 'MENZEL HABIB',
        cites: [
          {
            IDN3: 1290,
            LIBELLEN3: 'EL FEJIJ',
            IDN2: 116,
            CODEP: '6030',
          },
          {
            IDN3: 1291,
            LIBELLEN3: 'EL MIDA',
            IDN2: 116,
            CODEP: '6030',
          },
          {
            IDN3: 1292,
            LIBELLEN3: 'HEJRI',
            IDN2: 116,
            CODEP: '6030',
          },
          {
            IDN3: 1293,
            LIBELLEN3: 'MEHAMLA',
            IDN2: 116,
            CODEP: '6030',
          },
          {
            IDN3: 1294,
            LIBELLEN3: 'MENZEL HABIB',
            IDN2: 116,
            CODEP: '6030',
          },
          {
            IDN3: 1295,
            LIBELLEN3: 'OUALI',
            IDN2: 116,
            CODEP: '6030',
          },
          {
            IDN3: 1296,
            LIBELLEN3: 'OUED EZZITOUN',
            IDN2: 116,
            CODEP: '6030',
          },
          {
            IDN3: 1297,
            LIBELLEN3: 'OULED DHAOU',
            IDN2: 116,
            CODEP: '6030',
          },
          {
            IDN3: 1298,
            LIBELLEN3: 'ZOGRATA',
            IDN2: 116,
            CODEP: '6030',
          },
        ],
      },
      {
        ville: 112,
        villeName: 'NOUVELLE MATMATA',
        cites: [
          {
            IDN3: 1222,
            LIBELLEN3: 'ANCIENNE ZRAOUA',
            IDN2: 112,
            CODEP: '6024',
          },
          {
            IDN3: 1216,
            LIBELLEN3: 'BENI ZELTEN',
            IDN2: 112,
            CODEP: '6025',
          },
          {
            IDN3: 1207,
            LIBELLEN3: 'BOU SBAH',
            IDN2: 112,
            CODEP: '6044',
          },
          {
            IDN3: 1211,
            LIBELLEN3: 'CHOBET CHEMLALI',
            IDN2: 112,
            CODEP: '6044',
          },
          {
            IDN3: 1213,
            LIBELLEN3: 'CITE EDECHAICHA',
            IDN2: 112,
            CODEP: '6044',
          },
          {
            IDN3: 1214,
            LIBELLEN3: 'CITE EL IZDIHAR',
            IDN2: 112,
            CODEP: '6044',
          },
          {
            IDN3: 1208,
            LIBELLEN3: 'CITE GUEDOUALA',
            IDN2: 112,
            CODEP: '6044',
          },
          {
            IDN3: 1209,
            LIBELLEN3: 'CITE OULED SLAMA',
            IDN2: 112,
            CODEP: '6044',
          },
          {
            IDN3: 1210,
            LIBELLEN3: 'DHOKARA',
            IDN2: 112,
            CODEP: '6044',
          },
          {
            IDN3: 1212,
            LIBELLEN3: 'DOUIOUIRA',
            IDN2: 112,
            CODEP: '6044',
          },
          {
            IDN3: 1217,
            LIBELLEN3: 'EL AMRA',
            IDN2: 112,
            CODEP: '6025',
          },
          {
            IDN3: 1219,
            LIBELLEN3: 'EZZAHRA',
            IDN2: 112,
            CODEP: '6025',
          },
          {
            IDN3: 1218,
            LIBELLEN3: 'LAFFAM',
            IDN2: 112,
            CODEP: '6043',
          },
          {
            IDN3: 1215,
            LIBELLEN3: 'MZATEN',
            IDN2: 112,
            CODEP: '6025',
          },
          {
            IDN3: 1221,
            LIBELLEN3: 'NOUVELLE MATMATA',
            IDN2: 112,
            CODEP: '6044',
          },
          {
            IDN3: 1223,
            LIBELLEN3: 'NOUVELLE ZRAOUA',
            IDN2: 112,
            CODEP: '6024',
          },
          {
            IDN3: 1220,
            LIBELLEN3: 'OUED EL HARIKA',
            IDN2: 112,
            CODEP: '6043',
          },
          {
            IDN3: 1224,
            LIBELLEN3: 'SIDI GNAOU',
            IDN2: 112,
            CODEP: '6024',
          },
          {
            IDN3: 1205,
            LIBELLEN3: 'ZATEN BENI ZELTEN',
            IDN2: 112,
            CODEP: '6043',
          },
          {
            IDN3: 1206,
            LIBELLEN3: 'ZATEN HADDEJ',
            IDN2: 112,
            CODEP: '6043',
          },
        ],
      },
    ],
  },
  {
    govLabel: 'GAFSA',
    govId: 6,
    villes: [
      {
        ville: 117,
        villeName: 'BELKHIR',
        cites: [
          {
            IDN3: 1305,
            LIBELLEN3: 'BATEN ZAMMOUR',
            IDN2: 117,
            CODEP: '2135',
          },
          {
            IDN3: 1312,
            LIBELLEN3: 'BELKHIR',
            IDN2: 117,
            CODEP: '2115',
          },
          {
            IDN3: 1314,
            LIBELLEN3: 'BORJ EL HAFFEY',
            IDN2: 117,
            CODEP: '2115',
          },
          {
            IDN3: 1306,
            LIBELLEN3: 'BOU BLEL',
            IDN2: 117,
            CODEP: '2135',
          },
          {
            IDN3: 1307,
            LIBELLEN3: 'BOUKOU',
            IDN2: 117,
            CODEP: '2135',
          },
          {
            IDN3: 1315,
            LIBELLEN3: 'CITE OULED BEL HASSEN',
            IDN2: 117,
            CODEP: '2115',
          },
          {
            IDN3: 1313,
            LIBELLEN3: 'CITE OULED BEN JEDDOU',
            IDN2: 117,
            CODEP: '2115',
          },
          {
            IDN3: 1301,
            LIBELLEN3: 'CITE OULED HAJ ALI',
            IDN2: 117,
            CODEP: '2115',
          },
          {
            IDN3: 1303,
            LIBELLEN3: 'CITE OULED HMIDA',
            IDN2: 117,
            CODEP: '2115',
          },
          {
            IDN3: 1316,
            LIBELLEN3: 'CITE OULED SAAD',
            IDN2: 117,
            CODEP: '2115',
          },
          {
            IDN3: 1311,
            LIBELLEN3: 'EL AKTEB',
            IDN2: 117,
            CODEP: '2139',
          },
          {
            IDN3: 1304,
            LIBELLEN3: 'EL AYAICHA',
            IDN2: 117,
            CODEP: '2139',
          },
          {
            IDN3: 1299,
            LIBELLEN3: 'EZZITOUNA',
            IDN2: 117,
            CODEP: '2139',
          },
          {
            IDN3: 1308,
            LIBELLEN3: 'HAOUEL EL OUED',
            IDN2: 117,
            CODEP: '2135',
          },
          {
            IDN3: 1300,
            LIBELLEN3: 'JEBEL AYAICHA',
            IDN2: 117,
            CODEP: '2139',
          },
          {
            IDN3: 1309,
            LIBELLEN3: 'JEBILET EL OUST',
            IDN2: 117,
            CODEP: '2135',
          },
          {
            IDN3: 1302,
            LIBELLEN3: 'OULED EL HAJ',
            IDN2: 117,
            CODEP: '2115',
          },
          {
            IDN3: 1310,
            LIBELLEN3: 'OULED ZAIED',
            IDN2: 117,
            CODEP: '2135',
          },
        ],
      },
      {
        ville: 118,
        villeName: 'EL GUETTAR',
        cites: [
          {
            IDN3: 1319,
            LIBELLEN3: 'BIR SAAD',
            IDN2: 118,
            CODEP: '2180',
          },
          {
            IDN3: 1317,
            LIBELLEN3: 'BOU OMRANE',
            IDN2: 118,
            CODEP: '2125',
          },
          {
            IDN3: 1320,
            LIBELLEN3: 'CITE OKBA',
            IDN2: 118,
            CODEP: '2180',
          },
          {
            IDN3: 1321,
            LIBELLEN3: 'CITE ORBATA EST',
            IDN2: 118,
            CODEP: '2180',
          },
          {
            IDN3: 1322,
            LIBELLEN3: 'CITE ORBATA OUEST',
            IDN2: 118,
            CODEP: '2180',
          },
          {
            IDN3: 1323,
            LIBELLEN3: 'CITE POPULAIRE',
            IDN2: 118,
            CODEP: '2180',
          },
          {
            IDN3: 1325,
            LIBELLEN3: 'CITE POPULAIRE ROUTE GABES',
            IDN2: 118,
            CODEP: '2180',
          },
          {
            IDN3: 1326,
            LIBELLEN3: 'CITE SPROLS',
            IDN2: 118,
            CODEP: '2180',
          },
          {
            IDN3: 1324,
            LIBELLEN3: 'EL AMAIEM',
            IDN2: 118,
            CODEP: '2180',
          },
          {
            IDN3: 1327,
            LIBELLEN3: 'EL GUETTAR',
            IDN2: 118,
            CODEP: '2180',
          },
          {
            IDN3: 1330,
            LIBELLEN3: 'LORTES',
            IDN2: 118,
            CODEP: '2183',
          },
          {
            IDN3: 1328,
            LIBELLEN3: 'MAJNI',
            IDN2: 118,
            CODEP: '2180',
          },
          {
            IDN3: 1329,
            LIBELLEN3: 'NCHIOU',
            IDN2: 118,
            CODEP: '2181',
          },
          {
            IDN3: 1318,
            LIBELLEN3: 'OULED BOU SAAD',
            IDN2: 118,
            CODEP: '2145',
          },
        ],
      },
      {
        ville: 119,
        villeName: 'EL KSAR',
        cites: [
          {
            IDN3: 1334,
            LIBELLEN3: 'CITE BAB ETTOUB',
            IDN2: 119,
            CODEP: '2111',
          },
          {
            IDN3: 1335,
            LIBELLEN3: 'CITE BATIMENTS',
            IDN2: 119,
            CODEP: '2111',
          },
          {
            IDN3: 1336,
            LIBELLEN3: 'CITE BEN DOULA',
            IDN2: 119,
            CODEP: '2111',
          },
          {
            IDN3: 1337,
            LIBELLEN3: 'CITE BEN ISSAOUI',
            IDN2: 119,
            CODEP: '2111',
          },
          {
            IDN3: 1338,
            LIBELLEN3: 'CITE BEN SLIMENE',
            IDN2: 119,
            CODEP: '2111',
          },
          {
            IDN3: 1339,
            LIBELLEN3: 'CITE BOURANENE',
            IDN2: 119,
            CODEP: '2111',
          },
          {
            IDN3: 1340,
            LIBELLEN3: 'CITE DE LA GARE',
            IDN2: 119,
            CODEP: '2111',
          },
          {
            IDN3: 1341,
            LIBELLEN3: 'CITE DES CADRES',
            IDN2: 119,
            CODEP: '2111',
          },
          {
            IDN3: 1342,
            LIBELLEN3: 'CITE ECOLE',
            IDN2: 119,
            CODEP: '2111',
          },
          {
            IDN3: 1343,
            LIBELLEN3: 'CITE EL AJAMA',
            IDN2: 119,
            CODEP: '2111',
          },
          {
            IDN3: 1345,
            LIBELLEN3: 'CITE EL KAOUAFEL',
            IDN2: 119,
            CODEP: '2111',
          },
          {
            IDN3: 1346,
            LIBELLEN3: 'CITE EL KHIL',
            IDN2: 119,
            CODEP: '2111',
          },
          {
            IDN3: 1348,
            LIBELLEN3: 'CITE EL MANCHER',
            IDN2: 119,
            CODEP: '2111',
          },
          {
            IDN3: 1351,
            LIBELLEN3: 'CITE ESSOUANI',
            IDN2: 119,
            CODEP: '2111',
          },
          {
            IDN3: 1352,
            LIBELLEN3: 'CITE RAGOUBA',
            IDN2: 119,
            CODEP: '2111',
          },
          {
            IDN3: 1349,
            LIBELLEN3: 'EL KSAR',
            IDN2: 119,
            CODEP: '2111',
          },
          {
            IDN3: 1331,
            LIBELLEN3: 'ERRAGOUBA',
            IDN2: 119,
            CODEP: '2169',
          },
          {
            IDN3: 1333,
            LIBELLEN3: 'GAFSA AEROPORT',
            IDN2: 119,
            CODEP: '2151',
          },
          {
            IDN3: 1350,
            LIBELLEN3: 'GAFSA GARE',
            IDN2: 119,
            CODEP: '2111',
          },
          {
            IDN3: 1347,
            LIBELLEN3: 'LALA',
            IDN2: 119,
            CODEP: '2121',
          },
          {
            IDN3: 1332,
            LIBELLEN3: 'OULED CHRAIT',
            IDN2: 119,
            CODEP: '2111',
          },
          {
            IDN3: 1344,
            LIBELLEN3: 'SBAT',
            IDN2: 119,
            CODEP: '2111',
          },
        ],
      },
      {
        ville: 120,
        villeName: 'EL MDHILLA',
        cites: [
          {
            IDN3: 1359,
            LIBELLEN3: 'BORJ EL MDHILLA',
            IDN2: 120,
            CODEP: '2173',
          },
          {
            IDN3: 1353,
            LIBELLEN3: 'CITE DES JEUNES',
            IDN2: 120,
            CODEP: '2170',
          },
          {
            IDN3: 1356,
            LIBELLEN3: 'CITE ENNACIM',
            IDN2: 120,
            CODEP: '2170',
          },
          {
            IDN3: 1360,
            LIBELLEN3: 'CITE ESSAADA',
            IDN2: 120,
            CODEP: '2170',
          },
          {
            IDN3: 1354,
            LIBELLEN3: 'CITE OUEST',
            IDN2: 120,
            CODEP: '2170',
          },
          {
            IDN3: 1355,
            LIBELLEN3: 'CITE OUVRIERE 2',
            IDN2: 120,
            CODEP: '2170',
          },
          {
            IDN3: 1357,
            LIBELLEN3: 'CITE STADE OUVRIERE EST',
            IDN2: 120,
            CODEP: '2170',
          },
          {
            IDN3: 1358,
            LIBELLEN3: 'JEBEL MDHILLA',
            IDN2: 120,
            CODEP: '2170',
          },
        ],
      },
      {
        ville: 127,
        villeName: 'GAFSA NORD',
        cites: [
          {
            IDN3: 1480,
            LIBELLEN3: 'ECHABIBA',
            IDN2: 127,
            CODEP: '2196',
          },
          {
            IDN3: 1479,
            LIBELLEN3: 'MENZEL MIMOUN',
            IDN2: 127,
            CODEP: '2141',
          },
          {
            IDN3: 1481,
            LIBELLEN3: 'OULED ZID',
            IDN2: 127,
            CODEP: '2196',
          },
        ],
      },
      {
        ville: 125,
        villeName: 'GAFSA SUD',
        cites: [
          {
            IDN3: 1443,
            LIBELLEN3: 'CITE BAYECH',
            IDN2: 125,
            CODEP: '2100',
          },
          {
            IDN3: 1444,
            LIBELLEN3: 'CITE CHERIF',
            IDN2: 125,
            CODEP: '2100',
          },
          {
            IDN3: 1445,
            LIBELLEN3: 'CITE DES JEUNES',
            IDN2: 125,
            CODEP: '2100',
          },
          {
            IDN3: 1446,
            LIBELLEN3: 'CITE DES JEUNES 1',
            IDN2: 125,
            CODEP: '2100',
          },
          {
            IDN3: 1447,
            LIBELLEN3: 'CITE DES JEUNES 2',
            IDN2: 125,
            CODEP: '2100',
          },
          {
            IDN3: 1448,
            LIBELLEN3: 'CITE EL AFRANE',
            IDN2: 125,
            CODEP: '2100',
          },
          {
            IDN3: 1450,
            LIBELLEN3: 'CITE EL KAOUAFEL 1',
            IDN2: 125,
            CODEP: '2100',
          },
          {
            IDN3: 1451,
            LIBELLEN3: 'CITE EL KAOUAFEL 2',
            IDN2: 125,
            CODEP: '2100',
          },
          {
            IDN3: 1436,
            LIBELLEN3: 'CITE EL KITNA',
            IDN2: 125,
            CODEP: '2100',
          },
          {
            IDN3: 1433,
            LIBELLEN3: 'CITE EL MOULA',
            IDN2: 125,
            CODEP: '2100',
          },
          {
            IDN3: 1434,
            LIBELLEN3: 'CITE ENNOUHOUDH',
            IDN2: 125,
            CODEP: '2100',
          },
          {
            IDN3: 1442,
            LIBELLEN3: 'CITE ESSOUROUR',
            IDN2: 125,
            CODEP: '2124',
          },
          {
            IDN3: 1435,
            LIBELLEN3: 'CITE GARDE NATIONALE',
            IDN2: 125,
            CODEP: '2100',
          },
          {
            IDN3: 1437,
            LIBELLEN3: 'CITE INDUSTRIELLE',
            IDN2: 125,
            CODEP: '2100',
          },
          {
            IDN3: 1432,
            LIBELLEN3: 'DOUALY GAFSA',
            IDN2: 125,
            CODEP: '2143',
          },
          {
            IDN3: 1438,
            LIBELLEN3: 'EL GARIA',
            IDN2: 125,
            CODEP: '2100',
          },
          {
            IDN3: 1430,
            LIBELLEN3: 'ENNADHOUR',
            IDN2: 125,
            CODEP: '2142',
          },
          {
            IDN3: 1439,
            LIBELLEN3: 'GAFSA',
            IDN2: 125,
            CODEP: '2100',
          },
          {
            IDN3: 1449,
            LIBELLEN3: 'GAFSA CITE DES JEUNES',
            IDN2: 125,
            CODEP: '2133',
          },
          {
            IDN3: 1441,
            LIBELLEN3: 'GAFSA CITE ENNOUR',
            IDN2: 125,
            CODEP: '2123',
          },
          {
            IDN3: 1431,
            LIBELLEN3: 'KEF DERBI',
            IDN2: 125,
            CODEP: '2142',
          },
          {
            IDN3: 1440,
            LIBELLEN3: 'SIDI AHMED ZARROUK',
            IDN2: 125,
            CODEP: '2112',
          },
        ],
      },
      {
        ville: 126,
        villeName: 'METLAOUI',
        cites: [
          {
            IDN3: 1469,
            LIBELLEN3: 'CITE AOULED RADHOUANE',
            IDN2: 126,
            CODEP: '2130',
          },
          {
            IDN3: 1470,
            LIBELLEN3: 'CITE CENTRALE',
            IDN2: 126,
            CODEP: '2130',
          },
          {
            IDN3: 1471,
            LIBELLEN3: 'CITE DU LABORATOIRE',
            IDN2: 126,
            CODEP: '2130',
          },
          {
            IDN3: 1476,
            LIBELLEN3: 'CITE EL AMEL',
            IDN2: 126,
            CODEP: '2130',
          },
          {
            IDN3: 1453,
            LIBELLEN3: 'CITE EL MAGROUN',
            IDN2: 126,
            CODEP: '2132',
          },
          {
            IDN3: 1477,
            LIBELLEN3: 'CITE ENNACIM',
            IDN2: 126,
            CODEP: '2130',
          },
          {
            IDN3: 1473,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 126,
            CODEP: '2130',
          },
          {
            IDN3: 1460,
            LIBELLEN3: 'CITE ESSAADA 1',
            IDN2: 126,
            CODEP: '2130',
          },
          {
            IDN3: 1461,
            LIBELLEN3: 'CITE ESSAADA 2',
            IDN2: 126,
            CODEP: '2130',
          },
          {
            IDN3: 1462,
            LIBELLEN3: 'CITE ESSAADA OUEST',
            IDN2: 126,
            CODEP: '2130',
          },
          {
            IDN3: 1463,
            LIBELLEN3: 'CITE EUROPEEN',
            IDN2: 126,
            CODEP: '2130',
          },
          {
            IDN3: 1475,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 126,
            CODEP: '2130',
          },
          {
            IDN3: 1478,
            LIBELLEN3: 'CITE EZZOUHOUR 1',
            IDN2: 126,
            CODEP: '2130',
          },
          {
            IDN3: 1472,
            LIBELLEN3: 'CITE EZZOUHOUR 2',
            IDN2: 126,
            CODEP: '2130',
          },
          {
            IDN3: 1474,
            LIBELLEN3: 'CITE EZZOUHOUR 3',
            IDN2: 126,
            CODEP: '2130',
          },
          {
            IDN3: 1459,
            LIBELLEN3: 'CITE EZZOUHOUR 4',
            IDN2: 126,
            CODEP: '2130',
          },
          {
            IDN3: 1464,
            LIBELLEN3: 'CITE IBN KHALDOUN',
            IDN2: 126,
            CODEP: '2130',
          },
          {
            IDN3: 1465,
            LIBELLEN3: 'CITE MODERNE',
            IDN2: 126,
            CODEP: '2130',
          },
          {
            IDN3: 1466,
            LIBELLEN3: 'CITE PRESIDENTIELLE',
            IDN2: 126,
            CODEP: '2130',
          },
          {
            IDN3: 1467,
            LIBELLEN3: 'CITE THELJA',
            IDN2: 126,
            CODEP: '2130',
          },
          {
            IDN3: 1454,
            LIBELLEN3: 'EL KAINA',
            IDN2: 126,
            CODEP: '2132',
          },
          {
            IDN3: 1456,
            LIBELLEN3: 'EL MZIRAA',
            IDN2: 126,
            CODEP: '2134',
          },
          {
            IDN3: 1468,
            LIBELLEN3: 'METLAOUI',
            IDN2: 126,
            CODEP: '2130',
          },
          {
            IDN3: 1458,
            LIBELLEN3: 'METLAOUI GARE',
            IDN2: 126,
            CODEP: '2113',
          },
          {
            IDN3: 1455,
            LIBELLEN3: 'METLAOUI MINES',
            IDN2: 126,
            CODEP: '2132',
          },
          {
            IDN3: 1457,
            LIBELLEN3: 'METLAOUI THALJA',
            IDN2: 126,
            CODEP: '2134',
          },
          {
            IDN3: 1452,
            LIBELLEN3: 'RICHET ENNAAM',
            IDN2: 126,
            CODEP: '2130',
          },
        ],
      },
      {
        ville: 122,
        villeName: 'MOULARES',
        cites: [
          {
            IDN3: 1400,
            LIBELLEN3: 'CITE DE LA FRATERNITE',
            IDN2: 122,
            CODEP: '2110',
          },
          {
            IDN3: 1401,
            LIBELLEN3: 'CITE DE LA GARE',
            IDN2: 122,
            CODEP: '2110',
          },
          {
            IDN3: 1402,
            LIBELLEN3: 'CITE DE LE LIBERTE',
            IDN2: 122,
            CODEP: '2110',
          },
          {
            IDN3: 1404,
            LIBELLEN3: 'CITE DES JEUNES',
            IDN2: 122,
            CODEP: '2110',
          },
          {
            IDN3: 1405,
            LIBELLEN3: 'CITE DES OUVRIERS',
            IDN2: 122,
            CODEP: '2110',
          },
          {
            IDN3: 1406,
            LIBELLEN3: 'CITE EL AMEL',
            IDN2: 122,
            CODEP: '2110',
          },
          {
            IDN3: 1403,
            LIBELLEN3: 'CITE ESSOUALMIA',
            IDN2: 122,
            CODEP: '2110',
          },
          {
            IDN3: 1398,
            LIBELLEN3: 'CITE KOUCEILA',
            IDN2: 122,
            CODEP: '2110',
          },
          {
            IDN3: 1399,
            LIBELLEN3: 'CITE MAROCAIN',
            IDN2: 122,
            CODEP: '2110',
          },
          {
            IDN3: 1390,
            LIBELLEN3: 'CITE MODERNE',
            IDN2: 122,
            CODEP: '2110',
          },
          {
            IDN3: 1391,
            LIBELLEN3: 'CITE OKBA IBN NAFAA',
            IDN2: 122,
            CODEP: '2110',
          },
          {
            IDN3: 1392,
            LIBELLEN3: 'CITE SPROLS',
            IDN2: 122,
            CODEP: '2110',
          },
          {
            IDN3: 1393,
            LIBELLEN3: 'MOULARES',
            IDN2: 122,
            CODEP: '2110',
          },
          {
            IDN3: 1397,
            LIBELLEN3: 'MOULARES GARE',
            IDN2: 122,
            CODEP: '2161',
          },
          {
            IDN3: 1395,
            LIBELLEN3: 'OUGLET AHMED',
            IDN2: 122,
            CODEP: '2114',
          },
          {
            IDN3: 1396,
            LIBELLEN3: 'SIDI BOUBAKER',
            IDN2: 122,
            CODEP: '2114',
          },
          {
            IDN3: 1394,
            LIBELLEN3: 'TABEDIT',
            IDN2: 122,
            CODEP: '2110',
          },
        ],
      },
      {
        ville: 123,
        villeName: 'REDEYEF',
        cites: [
          {
            IDN3: 1407,
            LIBELLEN3: 'CITE DE LA GARE',
            IDN2: 123,
            CODEP: '2120',
          },
          {
            IDN3: 1408,
            LIBELLEN3: 'CITE DE LA POSTE',
            IDN2: 123,
            CODEP: '2120',
          },
          {
            IDN3: 1409,
            LIBELLEN3: 'CITE DU SOUK',
            IDN2: 123,
            CODEP: '2120',
          },
          {
            IDN3: 1410,
            LIBELLEN3: 'CITE ESSOUAFA',
            IDN2: 123,
            CODEP: '2120',
          },
          {
            IDN3: 1411,
            LIBELLEN3: 'CITE ETTAHRIR',
            IDN2: 123,
            CODEP: '2120',
          },
          {
            IDN3: 1412,
            LIBELLEN3: 'CITE HSOUNA BEN TAHAR',
            IDN2: 123,
            CODEP: '2120',
          },
          {
            IDN3: 1413,
            LIBELLEN3: 'CITE OULED BOU OUNE',
            IDN2: 123,
            CODEP: '2120',
          },
          {
            IDN3: 1414,
            LIBELLEN3: 'CITE OULED MAJED',
            IDN2: 123,
            CODEP: '2120',
          },
          {
            IDN3: 1415,
            LIBELLEN3: 'CITE OUVRIERE',
            IDN2: 123,
            CODEP: '2120',
          },
          {
            IDN3: 1416,
            LIBELLEN3: 'CITE SIDI ABDELKADER',
            IDN2: 123,
            CODEP: '2120',
          },
          {
            IDN3: 1417,
            LIBELLEN3: 'CITE SPROLS',
            IDN2: 123,
            CODEP: '2120',
          },
          {
            IDN3: 1418,
            LIBELLEN3: 'CITE TRABELSIA',
            IDN2: 123,
            CODEP: '2120',
          },
          {
            IDN3: 1419,
            LIBELLEN3: 'REDEYEF',
            IDN2: 123,
            CODEP: '2120',
          },
          {
            IDN3: 1421,
            LIBELLEN3: 'REDEYEF GARE',
            IDN2: 123,
            CODEP: '2140',
          },
          {
            IDN3: 1420,
            LIBELLEN3: 'ZOMRA REDEYEF',
            IDN2: 123,
            CODEP: '2122',
          },
        ],
      },
      {
        ville: 124,
        villeName: 'SIDI AICH',
        cites: [
          {
            IDN3: 1422,
            LIBELLEN3: 'CITE DHEBEBNIA 1',
            IDN2: 124,
            CODEP: '2131',
          },
          {
            IDN3: 1423,
            LIBELLEN3: 'CITE DHEBEBNIA 2',
            IDN2: 124,
            CODEP: '2131',
          },
          {
            IDN3: 1424,
            LIBELLEN3: 'CITE DU LYCEE',
            IDN2: 124,
            CODEP: '2131',
          },
          {
            IDN3: 1425,
            LIBELLEN3: 'CITE ECOLE',
            IDN2: 124,
            CODEP: '2131',
          },
          {
            IDN3: 1426,
            LIBELLEN3: 'CITE LOGEMENT POPULAIRE',
            IDN2: 124,
            CODEP: '2131',
          },
          {
            IDN3: 1427,
            LIBELLEN3: 'EL GARIA',
            IDN2: 124,
            CODEP: '2131',
          },
          {
            IDN3: 1428,
            LIBELLEN3: 'SIDI AICH',
            IDN2: 124,
            CODEP: '2131',
          },
          {
            IDN3: 1429,
            LIBELLEN3: 'SIDI AICH EST',
            IDN2: 124,
            CODEP: '2131',
          },
        ],
      },
      {
        ville: 121,
        villeName: 'SNED',
        cites: [
          {
            IDN3: 1378,
            LIBELLEN3: 'ABDESSADOK',
            IDN2: 121,
            CODEP: '2193',
          },
          {
            IDN3: 1381,
            LIBELLEN3: 'ALIM',
            IDN2: 121,
            CODEP: '2195',
          },
          {
            IDN3: 1386,
            LIBELLEN3: 'BAHLOULA',
            IDN2: 121,
            CODEP: '2190',
          },
          {
            IDN3: 1387,
            LIBELLEN3: 'CITE DE LA GOUT',
            IDN2: 121,
            CODEP: '2190',
          },
          {
            IDN3: 1388,
            LIBELLEN3: 'CITE DES MARTYRS',
            IDN2: 121,
            CODEP: '2190',
          },
          {
            IDN3: 1389,
            LIBELLEN3: 'CITE ENNAJAH',
            IDN2: 121,
            CODEP: '2190',
          },
          {
            IDN3: 1361,
            LIBELLEN3: 'CITE ENNASR 1',
            IDN2: 121,
            CODEP: '2190',
          },
          {
            IDN3: 1362,
            LIBELLEN3: 'CITE ENNASR 2',
            IDN2: 121,
            CODEP: '2190',
          },
          {
            IDN3: 1363,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 121,
            CODEP: '2190',
          },
          {
            IDN3: 1364,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 121,
            CODEP: '2190',
          },
          {
            IDN3: 1365,
            LIBELLEN3: 'CITE EZZITOUNA 1',
            IDN2: 121,
            CODEP: '2190',
          },
          {
            IDN3: 1366,
            LIBELLEN3: 'CITE EZZITOUNA 2',
            IDN2: 121,
            CODEP: '2190',
          },
          {
            IDN3: 1367,
            LIBELLEN3: 'CITE EZZOUHOUR 1',
            IDN2: 121,
            CODEP: '2190',
          },
          {
            IDN3: 1368,
            LIBELLEN3: 'CITE EZZOUHOUR 2',
            IDN2: 121,
            CODEP: '2190',
          },
          {
            IDN3: 1369,
            LIBELLEN3: 'CITE TAIEB MHIRI',
            IDN2: 121,
            CODEP: '2190',
          },
          {
            IDN3: 1370,
            LIBELLEN3: 'DHOUKARA',
            IDN2: 121,
            CODEP: '2190',
          },
          {
            IDN3: 1382,
            LIBELLEN3: 'EL ARARIA',
            IDN2: 121,
            CODEP: '2116',
          },
          {
            IDN3: 1379,
            LIBELLEN3: 'EL BIADHA',
            IDN2: 121,
            CODEP: '2193',
          },
          {
            IDN3: 1371,
            LIBELLEN3: 'EL GOUSSA',
            IDN2: 121,
            CODEP: '2190',
          },
          {
            IDN3: 1372,
            LIBELLEN3: 'ESSMAYRIA',
            IDN2: 121,
            CODEP: '2190',
          },
          {
            IDN3: 1380,
            LIBELLEN3: 'HENCHIR EL AFRAH',
            IDN2: 121,
            CODEP: '2193',
          },
          {
            IDN3: 1373,
            LIBELLEN3: 'JEBEL SNED',
            IDN2: 121,
            CODEP: '2190',
          },
          {
            IDN3: 1383,
            LIBELLEN3: 'JEDIDA',
            IDN2: 121,
            CODEP: '2116',
          },
          {
            IDN3: 1376,
            LIBELLEN3: 'MAJOURA',
            IDN2: 121,
            CODEP: '2192',
          },
          {
            IDN3: 1377,
            LIBELLEN3: 'MAJOURAT EDDAKHLA',
            IDN2: 121,
            CODEP: '2192',
          },
          {
            IDN3: 1374,
            LIBELLEN3: 'MENZEL CHIHAOUI',
            IDN2: 121,
            CODEP: '2190',
          },
          {
            IDN3: 1384,
            LIBELLEN3: 'OUM EL ALEG',
            IDN2: 121,
            CODEP: '2116',
          },
          {
            IDN3: 1375,
            LIBELLEN3: 'SNED',
            IDN2: 121,
            CODEP: '2190',
          },
          {
            IDN3: 1385,
            LIBELLEN3: 'ZANNOUCHE',
            IDN2: 121,
            CODEP: '2116',
          },
        ],
      },
    ],
  },
  {
    govLabel: 'JENDOUBA',
    govId: 7,
    villes: [
      {
        ville: 133,
        villeName: 'AIN DRAHAM',
        cites: [
          {
            IDN3: 1614,
            LIBELLEN3: 'ADISSA',
            IDN2: 133,
            CODEP: '8136',
          },
          {
            IDN3: 1605,
            LIBELLEN3: 'AIN DRAHAM',
            IDN2: 133,
            CODEP: '8130',
          },
          {
            IDN3: 1609,
            LIBELLEN3: 'AIN EL HAMRAYA',
            IDN2: 133,
            CODEP: '8134',
          },
          {
            IDN3: 1604,
            LIBELLEN3: 'BABOUCH',
            IDN2: 133,
            CODEP: '8121',
          },
          {
            IDN3: 1606,
            LIBELLEN3: 'EL ATATFA',
            IDN2: 133,
            CODEP: '8130',
          },
          {
            IDN3: 1610,
            LIBELLEN3: 'EL HOUMRANE',
            IDN2: 133,
            CODEP: '8134',
          },
          {
            IDN3: 1613,
            LIBELLEN3: 'ESSELOUL',
            IDN2: 133,
            CODEP: '8135',
          },
          {
            IDN3: 1615,
            LIBELLEN3: 'HAMMAM BOURGUIBA',
            IDN2: 133,
            CODEP: '8136',
          },
          {
            IDN3: 1611,
            LIBELLEN3: 'OUED EZZENE',
            IDN2: 133,
            CODEP: '8134',
          },
          {
            IDN3: 1618,
            LIBELLEN3: 'OULED HELAL',
            IDN2: 133,
            CODEP: '8135',
          },
          {
            IDN3: 1616,
            LIBELLEN3: 'ROUAI',
            IDN2: 133,
            CODEP: '8136',
          },
          {
            IDN3: 1607,
            LIBELLEN3: 'SIDI ABDALLAH',
            IDN2: 133,
            CODEP: '8130',
          },
          {
            IDN3: 1608,
            LIBELLEN3: 'SIDI MHAMED',
            IDN2: 133,
            CODEP: '8130',
          },
          {
            IDN3: 1612,
            LIBELLEN3: 'TEBAINIA',
            IDN2: 133,
            CODEP: '8134',
          },
          {
            IDN3: 1617,
            LIBELLEN3: 'TEGMA',
            IDN2: 133,
            CODEP: '8136',
          },
        ],
      },
      {
        ville: 136,
        villeName: 'BALTA BOU AOUENE',
        cites: [
          {
            IDN3: 1655,
            LIBELLEN3: 'BALTA',
            IDN2: 136,
            CODEP: '8126',
          },
          {
            IDN3: 1656,
            LIBELLEN3: 'BOU AOUENE',
            IDN2: 136,
            CODEP: '8116',
          },
          {
            IDN3: 1653,
            LIBELLEN3: 'CITE JEBIL',
            IDN2: 136,
            CODEP: '8116',
          },
          {
            IDN3: 1654,
            LIBELLEN3: 'OULED HASSEN',
            IDN2: 136,
            CODEP: '8116',
          },
        ],
      },
      {
        ville: 135,
        villeName: 'BOU SALEM',
        cites: [
          {
            IDN3: 1629,
            LIBELLEN3: 'BADROUNA',
            IDN2: 135,
            CODEP: '8143',
          },
          {
            IDN3: 1630,
            LIBELLEN3: 'BENI MHAMED',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1631,
            LIBELLEN3: 'BOU LAABA',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1632,
            LIBELLEN3: 'BOU SALEM',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1633,
            LIBELLEN3: 'CITE 7 NOVEMBRE 1',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1634,
            LIBELLEN3: 'CITE 7 NOVEMBRE 2',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1635,
            LIBELLEN3: 'CITE 7 NOVEMBRE 3',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1636,
            LIBELLEN3: 'CITE DE LA MUNICIPALITE',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1637,
            LIBELLEN3: 'CITE DIAMONTA',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1638,
            LIBELLEN3: 'CITE EL BHAIRIA',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1639,
            LIBELLEN3: 'CITE EL HAFSIA',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1640,
            LIBELLEN3: 'CITE EL HANA',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1641,
            LIBELLEN3: 'CITE EL KHALIJ',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1642,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1643,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1644,
            LIBELLEN3: 'CITE ESSOUROUR',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1645,
            LIBELLEN3: 'CITE FATOUMA BOURGUIBA',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1646,
            LIBELLEN3: 'CITE HACHED',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1647,
            LIBELLEN3: 'CITE HEDI KHELIL',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1648,
            LIBELLEN3: 'CITE HOPITAL',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1649,
            LIBELLEN3: 'CITE KHZAMA 1',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1650,
            LIBELLEN3: 'CITE KHZAMA 2',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1652,
            LIBELLEN3: 'CITE LAMINE',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1651,
            LIBELLEN3: 'CITE MARCHE MUNICIPAL',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1624,
            LIBELLEN3: 'CITE SPROLS',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1625,
            LIBELLEN3: 'CITE ZONE INDUSTRIELLE',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1626,
            LIBELLEN3: 'EL AOUAOUDHA',
            IDN2: 135,
            CODEP: '8170',
          },
          {
            IDN3: 1628,
            LIBELLEN3: 'EL MARJA',
            IDN2: 135,
            CODEP: '8124',
          },
          {
            IDN3: 1627,
            LIBELLEN3: 'ESSOUMRANE',
            IDN2: 135,
            CODEP: '8173',
          },
        ],
      },
      {
        ville: 128,
        villeName: 'FERNANA',
        cites: [
          {
            IDN3: 1505,
            LIBELLEN3: 'AIN CHARCHARA',
            IDN2: 128,
            CODEP: '8142',
          },
          {
            IDN3: 1506,
            LIBELLEN3: 'AIN EL BEYA',
            IDN2: 128,
            CODEP: '8142',
          },
          {
            IDN3: 1517,
            LIBELLEN3: 'ARGOUB RIHANE',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1515,
            LIBELLEN3: 'BENI MTIR',
            IDN2: 128,
            CODEP: '8114',
          },
          {
            IDN3: 1522,
            LIBELLEN3: 'BETAHA',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1507,
            LIBELLEN3: 'BOU HALLEB',
            IDN2: 128,
            CODEP: '8142',
          },
          {
            IDN3: 1503,
            LIBELLEN3: 'BOU HERTMA',
            IDN2: 128,
            CODEP: '8141',
          },
          {
            IDN3: 1518,
            LIBELLEN3: 'CITE BENI MAZEN',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1519,
            LIBELLEN3: 'CITE DE LA RNTA',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1520,
            LIBELLEN3: 'CITE EL FRAJNIA',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1525,
            LIBELLEN3: 'CITE EL INTILAKA',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1508,
            LIBELLEN3: 'CITE EL MARJA',
            IDN2: 128,
            CODEP: '8142',
          },
          {
            IDN3: 1482,
            LIBELLEN3: 'CITE EL MOUFTAREK',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1483,
            LIBELLEN3: 'CITE EL MOUROUJ',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1484,
            LIBELLEN3: 'CITE ENNACIM',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1485,
            LIBELLEN3: 'CITE ESSAADA',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1516,
            LIBELLEN3: 'CITE ESSALAH',
            IDN2: 128,
            CODEP: '8114',
          },
          {
            IDN3: 1486,
            LIBELLEN3: 'CITE ESSANAOUBER',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1487,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1488,
            LIBELLEN3: 'CITE OUM KETHIR',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1489,
            LIBELLEN3: 'CITE SPROLS',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1490,
            LIBELLEN3: 'ECHTAIBIA',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1491,
            LIBELLEN3: 'EJOUABLIA',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1492,
            LIBELLEN3: 'EL ALAYEG',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1493,
            LIBELLEN3: 'EL FAZZEN',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1494,
            LIBELLEN3: 'EL FEJOUJ',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1495,
            LIBELLEN3: 'EL HAOUAMD',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1496,
            LIBELLEN3: 'EL HOUJJAJ',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1497,
            LIBELLEN3: 'EL MAGROUN',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1498,
            LIBELLEN3: 'EROOUABA',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1499,
            LIBELLEN3: 'ESSMAIBIA',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1500,
            LIBELLEN3: 'FERNANA',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1523,
            LIBELLEN3: 'GRIOUA',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1524,
            LIBELLEN3: 'HALIMA',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1510,
            LIBELLEN3: 'HEDHIL',
            IDN2: 128,
            CODEP: '8145',
          },
          {
            IDN3: 1504,
            LIBELLEN3: 'JENTOURA',
            IDN2: 128,
            CODEP: '8141',
          },
          {
            IDN3: 1509,
            LIBELLEN3: 'JOUAOUDA',
            IDN2: 128,
            CODEP: '8142',
          },
          {
            IDN3: 1511,
            LIBELLEN3: 'OUED GHERIB',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1512,
            LIBELLEN3: 'OULED MFEDDA',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1513,
            LIBELLEN3: 'RABIA',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1514,
            LIBELLEN3: 'RMILA',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1521,
            LIBELLEN3: 'SIDI AMMAR',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1501,
            LIBELLEN3: 'SIDI SAID',
            IDN2: 128,
            CODEP: '8140',
          },
          {
            IDN3: 1502,
            LIBELLEN3: 'ZAAROURA',
            IDN2: 128,
            CODEP: '8140',
          },
        ],
      },
      {
        ville: 129,
        villeName: 'GHARDIMAOU',
        cites: [
          {
            IDN3: 1526,
            LIBELLEN3: 'AIN SOLTANE',
            IDN2: 129,
            CODEP: '8160',
          },
          {
            IDN3: 1551,
            LIBELLEN3: 'CITE DES COMBATTANTS',
            IDN2: 129,
            CODEP: '8160',
          },
          {
            IDN3: 1527,
            LIBELLEN3: 'CITE DES JARDINS',
            IDN2: 129,
            CODEP: '8160',
          },
          {
            IDN3: 1528,
            LIBELLEN3: 'CITE EL MAYARA',
            IDN2: 129,
            CODEP: '8160',
          },
          {
            IDN3: 1529,
            LIBELLEN3: 'CITE ENNOUHOUDH 1',
            IDN2: 129,
            CODEP: '8160',
          },
          {
            IDN3: 1530,
            LIBELLEN3: 'CITE ENNOUHOUDH 2',
            IDN2: 129,
            CODEP: '8160',
          },
          {
            IDN3: 1531,
            LIBELLEN3: 'CITE ENNOUHOUDH 3',
            IDN2: 129,
            CODEP: '8160',
          },
          {
            IDN3: 1532,
            LIBELLEN3: 'CITE ENNOUHOUDH 4',
            IDN2: 129,
            CODEP: '8160',
          },
          {
            IDN3: 1533,
            LIBELLEN3: 'CITE ENNOUHOUDH 5',
            IDN2: 129,
            CODEP: '8160',
          },
          {
            IDN3: 1534,
            LIBELLEN3: 'CITE ENNOUHOUDH 6',
            IDN2: 129,
            CODEP: '8160',
          },
          {
            IDN3: 1535,
            LIBELLEN3: 'CITE ENNOUHOUDH 7',
            IDN2: 129,
            CODEP: '8160',
          },
          {
            IDN3: 1536,
            LIBELLEN3: 'CITE ERRAJA',
            IDN2: 129,
            CODEP: '8160',
          },
          {
            IDN3: 1537,
            LIBELLEN3: 'CITE ERRAOUDHA',
            IDN2: 129,
            CODEP: '8160',
          },
          {
            IDN3: 1538,
            LIBELLEN3: 'CITE ESSAADA 1',
            IDN2: 129,
            CODEP: '8160',
          },
          {
            IDN3: 1539,
            LIBELLEN3: 'CITE ESSAADA 2',
            IDN2: 129,
            CODEP: '8160',
          },
          {
            IDN3: 1540,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 129,
            CODEP: '8160',
          },
          {
            IDN3: 1541,
            LIBELLEN3: 'CITE INES',
            IDN2: 129,
            CODEP: '8160',
          },
          {
            IDN3: 1542,
            LIBELLEN3: 'EL BIADHA',
            IDN2: 129,
            CODEP: '8160',
          },
          {
            IDN3: 1543,
            LIBELLEN3: 'EL FEIJA',
            IDN2: 129,
            CODEP: '8160',
          },
          {
            IDN3: 1544,
            LIBELLEN3: 'ESRAYA',
            IDN2: 129,
            CODEP: '8160',
          },
          {
            IDN3: 1545,
            LIBELLEN3: 'EZZRAIBIA',
            IDN2: 129,
            CODEP: '8160',
          },
          {
            IDN3: 1546,
            LIBELLEN3: 'GHARDIMAOU',
            IDN2: 129,
            CODEP: '8160',
          },
          {
            IDN3: 1547,
            LIBELLEN3: 'OUECHTATA',
            IDN2: 129,
            CODEP: '8160',
          },
          {
            IDN3: 1550,
            LIBELLEN3: 'OUED EL MAADEN',
            IDN2: 129,
            CODEP: '8162',
          },
          {
            IDN3: 1549,
            LIBELLEN3: 'OUERGUECH',
            IDN2: 129,
            CODEP: '8161',
          },
          {
            IDN3: 1548,
            LIBELLEN3: 'ZITOUNET OUM LAHNECHE',
            IDN2: 129,
            CODEP: '8160',
          },
        ],
      },
      {
        ville: 131,
        villeName: 'JENDOUBA',
        cites: [
          {
            IDN3: 1593,
            LIBELLEN3: 'AIN EL KHARARIB',
            IDN2: 131,
            CODEP: '8100',
          },
          {
            IDN3: 1594,
            LIBELLEN3: 'AIN EL KSIR',
            IDN2: 131,
            CODEP: '8100',
          },
          {
            IDN3: 1595,
            LIBELLEN3: 'CITE ADMINISTRATIVE',
            IDN2: 131,
            CODEP: '8100',
          },
          {
            IDN3: 1586,
            LIBELLEN3: 'CITE CNRPS',
            IDN2: 131,
            CODEP: '8122',
          },
          {
            IDN3: 1580,
            LIBELLEN3: 'CITE ENNOUR 1',
            IDN2: 131,
            CODEP: '8100',
          },
          {
            IDN3: 1581,
            LIBELLEN3: 'CITE ENNOUR 2',
            IDN2: 131,
            CODEP: '8100',
          },
          {
            IDN3: 1579,
            LIBELLEN3: 'CITE ETTATAOUER',
            IDN2: 131,
            CODEP: '8196',
          },
          {
            IDN3: 1585,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 131,
            CODEP: '8111',
          },
          {
            IDN3: 1582,
            LIBELLEN3: 'CITE SNIT',
            IDN2: 131,
            CODEP: '8100',
          },
          {
            IDN3: 1589,
            LIBELLEN3: 'EL AZIMA',
            IDN2: 131,
            CODEP: '8153',
          },
          {
            IDN3: 1583,
            LIBELLEN3: 'EL GANARA',
            IDN2: 131,
            CODEP: '8100',
          },
          {
            IDN3: 1591,
            LIBELLEN3: 'EL MELGA',
            IDN2: 131,
            CODEP: '8153',
          },
          {
            IDN3: 1592,
            LIBELLEN3: 'ESSAADA',
            IDN2: 131,
            CODEP: '8195',
          },
          {
            IDN3: 1587,
            LIBELLEN3: 'ESSANABEL',
            IDN2: 131,
            CODEP: '8122',
          },
          {
            IDN3: 1584,
            LIBELLEN3: 'JENDOUBA',
            IDN2: 131,
            CODEP: '8100',
          },
          {
            IDN3: 1590,
            LIBELLEN3: 'MITIL',
            IDN2: 131,
            CODEP: '8195',
          },
          {
            IDN3: 1588,
            LIBELLEN3: 'SOUK ESSEBT',
            IDN2: 131,
            CODEP: '8131',
          },
        ],
      },
      {
        ville: 132,
        villeName: 'JENDOUBA NORD',
        cites: [
          {
            IDN3: 1603,
            LIBELLEN3: 'BELLARIGIA',
            IDN2: 132,
            CODEP: '8117',
          },
          {
            IDN3: 1601,
            LIBELLEN3: 'BEN BECHIR',
            IDN2: 132,
            CODEP: '8111',
          },
          {
            IDN3: 1597,
            LIBELLEN3: 'CITE EL OUNS',
            IDN2: 132,
            CODEP: '8189',
          },
          {
            IDN3: 1598,
            LIBELLEN3: 'CITE EZZAHOUA',
            IDN2: 132,
            CODEP: '8189',
          },
          {
            IDN3: 1599,
            LIBELLEN3: 'CITE HEDI BEN HASSINE',
            IDN2: 132,
            CODEP: '8189',
          },
          {
            IDN3: 1600,
            LIBELLEN3: 'JENDOUBA NORD',
            IDN2: 132,
            CODEP: '8189',
          },
          {
            IDN3: 1596,
            LIBELLEN3: 'JERIF',
            IDN2: 132,
            CODEP: '8100',
          },
          {
            IDN3: 1602,
            LIBELLEN3: 'SOUK JEMAA',
            IDN2: 132,
            CODEP: '8132',
          },
        ],
      },
      {
        ville: 134,
        villeName: 'OUED MLIZ',
        cites: [
          {
            IDN3: 1619,
            LIBELLEN3: 'CITE EL AYACHI',
            IDN2: 134,
            CODEP: '8115',
          },
          {
            IDN3: 1621,
            LIBELLEN3: 'EDKHAILIA',
            IDN2: 134,
            CODEP: '8185',
          },
          {
            IDN3: 1623,
            LIBELLEN3: 'HAKIM',
            IDN2: 134,
            CODEP: '8194',
          },
          {
            IDN3: 1620,
            LIBELLEN3: 'OUED MLIZ',
            IDN2: 134,
            CODEP: '8115',
          },
          {
            IDN3: 1622,
            LIBELLEN3: 'SIDI MESKINE',
            IDN2: 134,
            CODEP: '8193',
          },
        ],
      },
      {
        ville: 130,
        villeName: 'TABARKA',
        cites: [
          {
            IDN3: 1554,
            LIBELLEN3: 'AIN ESSNOUSSI',
            IDN2: 130,
            CODEP: '8110',
          },
          {
            IDN3: 1575,
            LIBELLEN3: 'AIN ESSOBH',
            IDN2: 130,
            CODEP: '8112',
          },
          {
            IDN3: 1576,
            LIBELLEN3: 'BOU TERFES',
            IDN2: 130,
            CODEP: '8112',
          },
          {
            IDN3: 1563,
            LIBELLEN3: 'BRIREM',
            IDN2: 130,
            CODEP: '8127',
          },
          {
            IDN3: 1555,
            LIBELLEN3: 'CITE CHAAOUANIA',
            IDN2: 130,
            CODEP: '8110',
          },
          {
            IDN3: 1556,
            LIBELLEN3: 'CITE DES ARTS',
            IDN2: 130,
            CODEP: '8110',
          },
          {
            IDN3: 1557,
            LIBELLEN3: 'CITE DU SOLEIL',
            IDN2: 130,
            CODEP: '8110',
          },
          {
            IDN3: 1558,
            LIBELLEN3: 'CITE EDDACHRA',
            IDN2: 130,
            CODEP: '8110',
          },
          {
            IDN3: 1559,
            LIBELLEN3: 'CITE EL MOURJENE',
            IDN2: 130,
            CODEP: '8110',
          },
          {
            IDN3: 1578,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 130,
            CODEP: '8110',
          },
          {
            IDN3: 1570,
            LIBELLEN3: 'CITE KRAIMIA',
            IDN2: 130,
            CODEP: '8110',
          },
          {
            IDN3: 1553,
            LIBELLEN3: 'CITE POPULAIRE 1',
            IDN2: 130,
            CODEP: '8112',
          },
          {
            IDN3: 1568,
            LIBELLEN3: 'CITE POPULAIRE 2',
            IDN2: 130,
            CODEP: '8112',
          },
          {
            IDN3: 1569,
            LIBELLEN3: 'CITE POPULAIRE 3',
            IDN2: 130,
            CODEP: '8112',
          },
          {
            IDN3: 1571,
            LIBELLEN3: 'CITE TAIEB MHIRI',
            IDN2: 130,
            CODEP: '8110',
          },
          {
            IDN3: 1561,
            LIBELLEN3: 'COOPERATIVE CENTRALE DES GRANDES CULTURES',
            IDN2: 130,
            CODEP: '8112',
          },
          {
            IDN3: 1572,
            LIBELLEN3: 'EL FARECH',
            IDN2: 130,
            CODEP: '8110',
          },
          {
            IDN3: 1567,
            LIBELLEN3: 'EL HAOUAMDIA',
            IDN2: 130,
            CODEP: '8192',
          },
          {
            IDN3: 1564,
            LIBELLEN3: 'EL KHEDHAIRIA',
            IDN2: 130,
            CODEP: '8128',
          },
          {
            IDN3: 1565,
            LIBELLEN3: 'JABALLAH',
            IDN2: 130,
            CODEP: '8128',
          },
          {
            IDN3: 1560,
            LIBELLEN3: 'OULED YAHYA',
            IDN2: 130,
            CODEP: '8112',
          },
          {
            IDN3: 1552,
            LIBELLEN3: 'RAS ERRAJEL',
            IDN2: 130,
            CODEP: '8112',
          },
          {
            IDN3: 1577,
            LIBELLEN3: 'SIDI ASKER',
            IDN2: 130,
            CODEP: '8112',
          },
          {
            IDN3: 1573,
            LIBELLEN3: 'SIDI ROUINE',
            IDN2: 130,
            CODEP: '8110',
          },
          {
            IDN3: 1574,
            LIBELLEN3: 'TABARKA',
            IDN2: 130,
            CODEP: '8110',
          },
          {
            IDN3: 1566,
            LIBELLEN3: 'TABARKA AEROPORT',
            IDN2: 130,
            CODEP: '8181',
          },
          {
            IDN3: 1562,
            LIBELLEN3: 'TOUAJNIA',
            IDN2: 130,
            CODEP: '8112',
          },
        ],
      },
    ],
  },
  {
    govLabel: 'KAIROUAN',
    govId: 8,
    villes: [
      {
        ville: 147,
        villeName: 'BOU HAJLA',
        cites: [
          {
            IDN3: 1803,
            LIBELLEN3: 'BIR AHMED',
            IDN2: 147,
            CODEP: '3152',
          },
          {
            IDN3: 1817,
            LIBELLEN3: 'BIR EDDAOULA',
            IDN2: 147,
            CODEP: '3181',
          },
          {
            IDN3: 1804,
            LIBELLEN3: 'BIR LAHJAR',
            IDN2: 147,
            CODEP: '3180',
          },
          {
            IDN3: 1805,
            LIBELLEN3: 'BOU HAJLA',
            IDN2: 147,
            CODEP: '3180',
          },
          {
            IDN3: 1806,
            LIBELLEN3: 'CITE 7 NOVEMBRE',
            IDN2: 147,
            CODEP: '3180',
          },
          {
            IDN3: 1807,
            LIBELLEN3: 'CITE DE LA REPUBLIQUE',
            IDN2: 147,
            CODEP: '3180',
          },
          {
            IDN3: 1808,
            LIBELLEN3: 'CITE EL MOEZ',
            IDN2: 147,
            CODEP: '3180',
          },
          {
            IDN3: 1809,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 147,
            CODEP: '3180',
          },
          {
            IDN3: 1810,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 147,
            CODEP: '3180',
          },
          {
            IDN3: 1811,
            LIBELLEN3: 'CITE ESSAADA',
            IDN2: 147,
            CODEP: '3180',
          },
          {
            IDN3: 1812,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 147,
            CODEP: '3180',
          },
          {
            IDN3: 1813,
            LIBELLEN3: 'CITE NOUVELLE',
            IDN2: 147,
            CODEP: '3180',
          },
          {
            IDN3: 1815,
            LIBELLEN3: 'CITE OKBA',
            IDN2: 147,
            CODEP: '3180',
          },
          {
            IDN3: 1818,
            LIBELLEN3: 'EL FATEH',
            IDN2: 147,
            CODEP: '3180',
          },
          {
            IDN3: 1814,
            LIBELLEN3: 'ENNASR',
            IDN2: 147,
            CODEP: '3180',
          },
          {
            IDN3: 1802,
            LIBELLEN3: 'JEHINA',
            IDN2: 147,
            CODEP: '3126',
          },
          {
            IDN3: 1816,
            LIBELLEN3: 'OULED ACHOUR',
            IDN2: 147,
            CODEP: '3180',
          },
        ],
      },
      {
        ville: 144,
        villeName: 'CHEBIKA',
        cites: [
          {
            IDN3: 1780,
            LIBELLEN3: 'ABIDA CHERGUIA',
            IDN2: 144,
            CODEP: '3183',
          },
          {
            IDN3: 1781,
            LIBELLEN3: 'AIN EL BEYDHA',
            IDN2: 144,
            CODEP: '3133',
          },
          {
            IDN3: 1782,
            LIBELLEN3: 'BEN SALEM',
            IDN2: 144,
            CODEP: '3112',
          },
          {
            IDN3: 1786,
            LIBELLEN3: 'CHEBIKA',
            IDN2: 144,
            CODEP: '3121',
          },
          {
            IDN3: 1773,
            LIBELLEN3: 'CITE SABRINE',
            IDN2: 144,
            CODEP: '3121',
          },
          {
            IDN3: 1779,
            LIBELLEN3: 'EL AOUAMRIA',
            IDN2: 144,
            CODEP: '3161',
          },
          {
            IDN3: 1785,
            LIBELLEN3: 'EL KARMA',
            IDN2: 144,
            CODEP: '3133',
          },
          {
            IDN3: 1783,
            LIBELLEN3: 'GRAGAYA',
            IDN2: 144,
            CODEP: '3112',
          },
          {
            IDN3: 1784,
            LIBELLEN3: 'HAMMED',
            IDN2: 144,
            CODEP: '3112',
          },
          {
            IDN3: 1776,
            LIBELLEN3: 'OULED KHALFALLAH',
            IDN2: 144,
            CODEP: '3133',
          },
          {
            IDN3: 1777,
            LIBELLEN3: 'OULED KHELIF',
            IDN2: 144,
            CODEP: '3133',
          },
          {
            IDN3: 1778,
            LIBELLEN3: 'OULED NASSER',
            IDN2: 144,
            CODEP: '3133',
          },
          {
            IDN3: 1774,
            LIBELLEN3: 'ROUISSETTE',
            IDN2: 144,
            CODEP: '3121',
          },
          {
            IDN3: 1775,
            LIBELLEN3: 'SIDI ABDALLAH  BELHAJ',
            IDN2: 144,
            CODEP: '3121',
          },
        ],
      },
      {
        ville: 146,
        villeName: 'CHERARDA',
        cites: [
          {
            IDN3: 1795,
            LIBELLEN3: 'CHERAITIA',
            IDN2: 146,
            CODEP: '3116',
          },
          {
            IDN3: 1796,
            LIBELLEN3: 'CHERARDA',
            IDN2: 146,
            CODEP: '3116',
          },
          {
            IDN3: 1797,
            LIBELLEN3: 'CITE EL  MACHED',
            IDN2: 146,
            CODEP: '3116',
          },
          {
            IDN3: 1798,
            LIBELLEN3: 'CITE EL KOUCHA',
            IDN2: 146,
            CODEP: '3116',
          },
          {
            IDN3: 1799,
            LIBELLEN3: 'EL AOUYED',
            IDN2: 146,
            CODEP: '3116',
          },
          {
            IDN3: 1800,
            LIBELLEN3: 'EL KSOUR',
            IDN2: 146,
            CODEP: '3116',
          },
          {
            IDN3: 1801,
            LIBELLEN3: 'OULED FARJALLAH',
            IDN2: 146,
            CODEP: '3145',
          },
        ],
      },
      {
        ville: 142,
        villeName: 'EL ALA',
        cites: [
          {
            IDN3: 1741,
            LIBELLEN3: 'AIN SAYADA',
            IDN2: 142,
            CODEP: '3150',
          },
          {
            IDN3: 1742,
            LIBELLEN3: 'CITE ESSANAOUBER',
            IDN2: 142,
            CODEP: '3150',
          },
          {
            IDN3: 1743,
            LIBELLEN3: 'CITE NOUVELLE',
            IDN2: 142,
            CODEP: '3150',
          },
          {
            IDN3: 1744,
            LIBELLEN3: 'CITE OULED AHMED',
            IDN2: 142,
            CODEP: '3150',
          },
          {
            IDN3: 1745,
            LIBELLEN3: 'CITE POPULAIRE',
            IDN2: 142,
            CODEP: '3150',
          },
          {
            IDN3: 1746,
            LIBELLEN3: 'CITE REZAGNIA',
            IDN2: 142,
            CODEP: '3150',
          },
          {
            IDN3: 1747,
            LIBELLEN3: 'CITE RURALE',
            IDN2: 142,
            CODEP: '3150',
          },
          {
            IDN3: 1748,
            LIBELLEN3: 'ECOLE ETTOUAL',
            IDN2: 142,
            CODEP: '3150',
          },
          {
            IDN3: 1750,
            LIBELLEN3: 'ECOLE JOUAMIA',
            IDN2: 142,
            CODEP: '3150',
          },
          {
            IDN3: 1749,
            LIBELLEN3: 'ECOLE TRAZZA',
            IDN2: 142,
            CODEP: '3150',
          },
          {
            IDN3: 1736,
            LIBELLEN3: 'EL ALA',
            IDN2: 142,
            CODEP: '3150',
          },
          {
            IDN3: 1751,
            LIBELLEN3: 'EL FJEJRIA',
            IDN2: 142,
            CODEP: '3150',
          },
          {
            IDN3: 1738,
            LIBELLEN3: 'EL MSAID',
            IDN2: 142,
            CODEP: '3153',
          },
          {
            IDN3: 1735,
            LIBELLEN3: 'GHIDHIFETTE',
            IDN2: 142,
            CODEP: '3150',
          },
          {
            IDN3: 1739,
            LIBELLEN3: 'MESSIOUTA',
            IDN2: 142,
            CODEP: '3154',
          },
          {
            IDN3: 1752,
            LIBELLEN3: 'OUED EL JABBES',
            IDN2: 142,
            CODEP: '3150',
          },
          {
            IDN3: 1754,
            LIBELLEN3: 'OULED ALI',
            IDN2: 142,
            CODEP: '3150',
          },
          {
            IDN3: 1740,
            LIBELLEN3: 'OULED ENNAGUEZ',
            IDN2: 142,
            CODEP: '3150',
          },
          {
            IDN3: 1753,
            LIBELLEN3: 'OULED MEHREZ',
            IDN2: 142,
            CODEP: '3150',
          },
          {
            IDN3: 1755,
            LIBELLEN3: 'SAYADA NORD',
            IDN2: 142,
            CODEP: '3150',
          },
          {
            IDN3: 1737,
            LIBELLEN3: 'SAYADA SUD',
            IDN2: 142,
            CODEP: '3150',
          },
        ],
      },
      {
        ville: 145,
        villeName: 'HAFFOUZ',
        cites: [
          {
            IDN3: 1787,
            LIBELLEN3: 'AIN EL GHRAB',
            IDN2: 145,
            CODEP: '3130',
          },
          {
            IDN3: 1788,
            LIBELLEN3: 'CHERICHIRA',
            IDN2: 145,
            CODEP: '3130',
          },
          {
            IDN3: 1789,
            LIBELLEN3: 'CITE LE MOUROUJ',
            IDN2: 145,
            CODEP: '3130',
          },
          {
            IDN3: 1790,
            LIBELLEN3: 'EL ALIA',
            IDN2: 145,
            CODEP: '3130',
          },
          {
            IDN3: 1791,
            LIBELLEN3: 'EL AOUDID',
            IDN2: 145,
            CODEP: '3130',
          },
          {
            IDN3: 1792,
            LIBELLEN3: 'EZZORG',
            IDN2: 145,
            CODEP: '3130',
          },
          {
            IDN3: 1793,
            LIBELLEN3: 'HAFFOUZ',
            IDN2: 145,
            CODEP: '3130',
          },
          {
            IDN3: 1794,
            LIBELLEN3: 'KHIT EL OUED',
            IDN2: 145,
            CODEP: '3134',
          },
        ],
      },
      {
        ville: 143,
        villeName: 'HAJEB EL AYOUN',
        cites: [
          {
            IDN3: 1757,
            LIBELLEN3: 'AIN MAJOUNA',
            IDN2: 143,
            CODEP: '3160',
          },
          {
            IDN3: 1758,
            LIBELLEN3: 'CITE 7 NOVEMBRE',
            IDN2: 143,
            CODEP: '3160',
          },
          {
            IDN3: 1759,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 143,
            CODEP: '3160',
          },
          {
            IDN3: 1760,
            LIBELLEN3: 'CITE ENNOUHOUDH',
            IDN2: 143,
            CODEP: '3160',
          },
          {
            IDN3: 1761,
            LIBELLEN3: 'CITE ERROUKI',
            IDN2: 143,
            CODEP: '3160',
          },
          {
            IDN3: 1762,
            LIBELLEN3: 'CITE ESSAADA',
            IDN2: 143,
            CODEP: '3160',
          },
          {
            IDN3: 1756,
            LIBELLEN3: 'CITE EZZAYATINE',
            IDN2: 143,
            CODEP: '3160',
          },
          {
            IDN3: 1763,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 143,
            CODEP: '3160',
          },
          {
            IDN3: 1764,
            LIBELLEN3: 'CITE INDEPENDANCE',
            IDN2: 143,
            CODEP: '3160',
          },
          {
            IDN3: 1765,
            LIBELLEN3: 'EL GHOUIBA',
            IDN2: 143,
            CODEP: '3160',
          },
          {
            IDN3: 1766,
            LIBELLEN3: 'EL MANAR',
            IDN2: 143,
            CODEP: '3160',
          },
          {
            IDN3: 1767,
            LIBELLEN3: 'EL MENASSA',
            IDN2: 143,
            CODEP: '3160',
          },
          {
            IDN3: 1768,
            LIBELLEN3: 'HAJEB EL AYOUN',
            IDN2: 143,
            CODEP: '3160',
          },
          {
            IDN3: 1769,
            LIBELLEN3: 'HAJEB EL AYOUN GARE',
            IDN2: 143,
            CODEP: '3160',
          },
          {
            IDN3: 1772,
            LIBELLEN3: 'OULED ABBES',
            IDN2: 143,
            CODEP: '3160',
          },
          {
            IDN3: 1770,
            LIBELLEN3: 'OULED AMEUR',
            IDN2: 143,
            CODEP: '3160',
          },
          {
            IDN3: 1771,
            LIBELLEN3: 'THALLAJA',
            IDN2: 143,
            CODEP: '3160',
          },
        ],
      },
      {
        ville: 141,
        villeName: 'KAIROUAN NORD',
        cites: [
          {
            IDN3: 1727,
            LIBELLEN3: 'CITE DAR EL AMEN',
            IDN2: 141,
            CODEP: '3140',
          },
          {
            IDN3: 1724,
            LIBELLEN3: 'CITE EL BALOUI 1',
            IDN2: 141,
            CODEP: '3140',
          },
          {
            IDN3: 1734,
            LIBELLEN3: 'CITE EL BALOUI 2',
            IDN2: 141,
            CODEP: '3140',
          },
          {
            IDN3: 1730,
            LIBELLEN3: 'CITE EL BALOUI 3',
            IDN2: 141,
            CODEP: '3140',
          },
          {
            IDN3: 1722,
            LIBELLEN3: 'CITE EL HAJJAM',
            IDN2: 141,
            CODEP: '3193',
          },
          {
            IDN3: 1720,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 141,
            CODEP: '3142',
          },
          {
            IDN3: 1728,
            LIBELLEN3: 'CITE ICHBILIA',
            IDN2: 141,
            CODEP: '3140',
          },
          {
            IDN3: 1731,
            LIBELLEN3: 'CITE OKBA',
            IDN2: 141,
            CODEP: '3140',
          },
          {
            IDN3: 1729,
            LIBELLEN3: 'CITE OULED MANAA',
            IDN2: 141,
            CODEP: '3140',
          },
          {
            IDN3: 1725,
            LIBELLEN3: 'CITE SAHBI 3',
            IDN2: 141,
            CODEP: '3140',
          },
          {
            IDN3: 1726,
            LIBELLEN3: 'CITE SAHBI 4',
            IDN2: 141,
            CODEP: '3140',
          },
          {
            IDN3: 1723,
            LIBELLEN3: 'CITE SAHBI 5',
            IDN2: 141,
            CODEP: '3129',
          },
          {
            IDN3: 1733,
            LIBELLEN3: 'CITE SAHNOUN',
            IDN2: 141,
            CODEP: '3140',
          },
          {
            IDN3: 1715,
            LIBELLEN3: 'CITE SNIT',
            IDN2: 141,
            CODEP: '3140',
          },
          {
            IDN3: 1716,
            LIBELLEN3: 'DHRAA ETTAMMAR',
            IDN2: 141,
            CODEP: '3140',
          },
          {
            IDN3: 1719,
            LIBELLEN3: 'EL BATEN',
            IDN2: 141,
            CODEP: '3140',
          },
          {
            IDN3: 1721,
            LIBELLEN3: 'EL GHABETTE',
            IDN2: 141,
            CODEP: '3182',
          },
          {
            IDN3: 1732,
            LIBELLEN3: 'EL MOUTBASTA',
            IDN2: 141,
            CODEP: '3111',
          },
          {
            IDN3: 1717,
            LIBELLEN3: 'KAIROUAN OKBA',
            IDN2: 141,
            CODEP: '3140',
          },
          {
            IDN3: 1718,
            LIBELLEN3: 'SIDI DHAMENI',
            IDN2: 141,
            CODEP: '3140',
          },
        ],
      },
      {
        ville: 138,
        villeName: 'KAIROUAN SUD',
        cites: [
          {
            IDN3: 1691,
            LIBELLEN3: 'AIN EL KHAZZAZIA',
            IDN2: 138,
            CODEP: '3143',
          },
          {
            IDN3: 1676,
            LIBELLEN3: 'ARGOUB EL KHADHRA',
            IDN2: 138,
            CODEP: '3192',
          },
          {
            IDN3: 1680,
            LIBELLEN3: 'CITE EL MOEZ',
            IDN2: 138,
            CODEP: '3100',
          },
          {
            IDN3: 1681,
            LIBELLEN3: 'CITE IBN EL JAZZAR',
            IDN2: 138,
            CODEP: '3199',
          },
          {
            IDN3: 1683,
            LIBELLEN3: 'CITE MILITAIRE ANCIEN',
            IDN2: 138,
            CODEP: '3100',
          },
          {
            IDN3: 1684,
            LIBELLEN3: 'CITE RIM',
            IDN2: 138,
            CODEP: '3100',
          },
          {
            IDN3: 1685,
            LIBELLEN3: 'CITE SABRI',
            IDN2: 138,
            CODEP: '3100',
          },
          {
            IDN3: 1678,
            LIBELLEN3: 'CITE TABENE',
            IDN2: 138,
            CODEP: '3198',
          },
          {
            IDN3: 1686,
            LIBELLEN3: 'EL ARGOUB LAHRECH',
            IDN2: 138,
            CODEP: '3100',
          },
          {
            IDN3: 1682,
            LIBELLEN3: 'EL BORJI',
            IDN2: 138,
            CODEP: '3198',
          },
          {
            IDN3: 1677,
            LIBELLEN3: 'EL KHADHRA',
            IDN2: 138,
            CODEP: '3192',
          },
          {
            IDN3: 1687,
            LIBELLEN3: 'EL MAKHSOUMA',
            IDN2: 138,
            CODEP: '3100',
          },
          {
            IDN3: 1688,
            LIBELLEN3: 'EL MRAZIG',
            IDN2: 138,
            CODEP: '3100',
          },
          {
            IDN3: 1689,
            LIBELLEN3: 'KAIROUAN',
            IDN2: 138,
            CODEP: '3100',
          },
          {
            IDN3: 1690,
            LIBELLEN3: 'KAIROUAN SUD',
            IDN2: 138,
            CODEP: '3131',
          },
          {
            IDN3: 1679,
            LIBELLEN3: 'RACCADA',
            IDN2: 138,
            CODEP: '3191',
          },
          {
            IDN3: 1692,
            LIBELLEN3: 'ZAAFRANA',
            IDN2: 138,
            CODEP: '3151',
          },
        ],
      },
      {
        ville: 140,
        villeName: 'NASRALLAH',
        cites: [
          {
            IDN3: 1707,
            LIBELLEN3: 'EL AJABNA',
            IDN2: 140,
            CODEP: '3170',
          },
          {
            IDN3: 1712,
            LIBELLEN3: 'EL HAMIDETTE',
            IDN2: 140,
            CODEP: '3114',
          },
          {
            IDN3: 1710,
            LIBELLEN3: 'EL KABBARA',
            IDN2: 140,
            CODEP: '3171',
          },
          {
            IDN3: 1708,
            LIBELLEN3: 'EL KSOUR',
            IDN2: 140,
            CODEP: '3170',
          },
          {
            IDN3: 1711,
            LIBELLEN3: 'ERRAGOUBA EL HAMRA',
            IDN2: 140,
            CODEP: '3173',
          },
          {
            IDN3: 1714,
            LIBELLEN3: 'MENZEL MHIRI',
            IDN2: 140,
            CODEP: '3114',
          },
          {
            IDN3: 1709,
            LIBELLEN3: 'NASRALLAH',
            IDN2: 140,
            CODEP: '3170',
          },
          {
            IDN3: 1706,
            LIBELLEN3: 'SIDI SAAD',
            IDN2: 140,
            CODEP: '3115',
          },
          {
            IDN3: 1713,
            LIBELLEN3: 'TOUILA',
            IDN2: 140,
            CODEP: '3114',
          },
        ],
      },
      {
        ville: 139,
        villeName: 'OUESLATIA',
        cites: [
          {
            IDN3: 1695,
            LIBELLEN3: 'AIN JELLOULA',
            IDN2: 139,
            CODEP: '3113',
          },
          {
            IDN3: 1696,
            LIBELLEN3: 'AIN MASTOUR',
            IDN2: 139,
            CODEP: '3120',
          },
          {
            IDN3: 1697,
            LIBELLEN3: 'CITE BOU ASSIDA',
            IDN2: 139,
            CODEP: '3120',
          },
          {
            IDN3: 1698,
            LIBELLEN3: 'CITE CHEBBI',
            IDN2: 139,
            CODEP: '3120',
          },
          {
            IDN3: 1699,
            LIBELLEN3: 'CITE COMMERCIALE',
            IDN2: 139,
            CODEP: '3120',
          },
          {
            IDN3: 1700,
            LIBELLEN3: 'CITE DES OUVRIERS',
            IDN2: 139,
            CODEP: '3120',
          },
          {
            IDN3: 1701,
            LIBELLEN3: 'CITE DU STADE',
            IDN2: 139,
            CODEP: '3120',
          },
          {
            IDN3: 1702,
            LIBELLEN3: 'CITE EJJAMAA',
            IDN2: 139,
            CODEP: '3120',
          },
          {
            IDN3: 1703,
            LIBELLEN3: 'CITE ESSANAOUBER',
            IDN2: 139,
            CODEP: '3120',
          },
          {
            IDN3: 1704,
            LIBELLEN3: 'CITE ETTAHRIR',
            IDN2: 139,
            CODEP: '3120',
          },
          {
            IDN3: 1694,
            LIBELLEN3: 'KSAR LAMSA',
            IDN2: 139,
            CODEP: '3124',
          },
          {
            IDN3: 1705,
            LIBELLEN3: 'OUED EL GUESSAB',
            IDN2: 139,
            CODEP: '3120',
          },
          {
            IDN3: 1693,
            LIBELLEN3: 'OUESLATIA',
            IDN2: 139,
            CODEP: '3120',
          },
        ],
      },
      {
        ville: 137,
        villeName: 'SBIKHA',
        cites: [
          {
            IDN3: 1672,
            LIBELLEN3: 'AIN BOUMOURRA',
            IDN2: 137,
            CODEP: '3135',
          },
          {
            IDN3: 1659,
            LIBELLEN3: 'CHORFA',
            IDN2: 137,
            CODEP: '3110',
          },
          {
            IDN3: 1660,
            LIBELLEN3: 'CHOUGAFIA',
            IDN2: 137,
            CODEP: '3110',
          },
          {
            IDN3: 1670,
            LIBELLEN3: 'DALOUSSI',
            IDN2: 137,
            CODEP: '3110',
          },
          {
            IDN3: 1666,
            LIBELLEN3: 'DAR BEN AICHA',
            IDN2: 137,
            CODEP: '3132',
          },
          {
            IDN3: 1667,
            LIBELLEN3: 'DAR JAMIYA',
            IDN2: 137,
            CODEP: '3132',
          },
          {
            IDN3: 1674,
            LIBELLEN3: 'DHRIAAT',
            IDN2: 137,
            CODEP: '3194',
          },
          {
            IDN3: 1673,
            LIBELLEN3: 'EDDIKHILA',
            IDN2: 137,
            CODEP: '3110',
          },
          {
            IDN3: 1665,
            LIBELLEN3: 'EL ALEM',
            IDN2: 137,
            CODEP: '3125',
          },
          {
            IDN3: 1669,
            LIBELLEN3: 'EL AOUITHA',
            IDN2: 137,
            CODEP: '3132',
          },
          {
            IDN3: 1657,
            LIBELLEN3: 'EL FRIOUETTE',
            IDN2: 137,
            CODEP: '3110',
          },
          {
            IDN3: 1668,
            LIBELLEN3: 'EL GATIFA',
            IDN2: 137,
            CODEP: '3110',
          },
          {
            IDN3: 1661,
            LIBELLEN3: 'EL GFEY',
            IDN2: 137,
            CODEP: '3110',
          },
          {
            IDN3: 1662,
            LIBELLEN3: 'EL KHODHER',
            IDN2: 137,
            CODEP: '3110',
          },
          {
            IDN3: 1658,
            LIBELLEN3: 'HENDI ZITOUNA',
            IDN2: 137,
            CODEP: '3196',
          },
          {
            IDN3: 1663,
            LIBELLEN3: 'OUED NEBHANA',
            IDN2: 137,
            CODEP: '3110',
          },
          {
            IDN3: 1664,
            LIBELLEN3: 'SBIKHA',
            IDN2: 137,
            CODEP: '3110',
          },
          {
            IDN3: 1675,
            LIBELLEN3: 'SIDI MESSAOUD',
            IDN2: 137,
            CODEP: '3195',
          },
          {
            IDN3: 1671,
            LIBELLEN3: 'SISSEB',
            IDN2: 137,
            CODEP: '3132',
          },
        ],
      },
    ],
  },
  {
    govLabel: 'KASSERINE',
    govId: 9,
    villes: [
      {
        ville: 152,
        villeName: 'EL AYOUN',
        cites: [
          {
            IDN3: 1885,
            LIBELLEN3: 'EL AYOUN',
            IDN2: 152,
            CODEP: '1216',
          },
          {
            IDN3: 1886,
            LIBELLEN3: 'EL GRINE',
            IDN2: 152,
            CODEP: '1234',
          },
        ],
      },
      {
        ville: 153,
        villeName: 'EZZOUHOUR',
        cites: [
          {
            IDN3: 1887,
            LIBELLEN3: 'KASSERNIE EZZOUHOUR',
            IDN2: 153,
            CODEP: '1279',
          },
        ],
      },
      {
        ville: 154,
        villeName: 'FERIANA',
        cites: [
          {
            IDN3: 1888,
            LIBELLEN3: 'BOU CHEBKA',
            IDN2: 154,
            CODEP: '1213',
          },
          {
            IDN3: 1900,
            LIBELLEN3: 'CEKHIRATE',
            IDN2: 154,
            CODEP: '1223',
          },
          {
            IDN3: 1890,
            LIBELLEN3: 'CITE CHEAB',
            IDN2: 154,
            CODEP: '1240',
          },
          {
            IDN3: 1891,
            LIBELLEN3: 'CITE DU BATTOIRE',
            IDN2: 154,
            CODEP: '1240',
          },
          {
            IDN3: 1892,
            LIBELLEN3: 'CITE EL IZDIHAR',
            IDN2: 154,
            CODEP: '1240',
          },
          {
            IDN3: 1893,
            LIBELLEN3: 'CITE HACHED',
            IDN2: 154,
            CODEP: '1240',
          },
          {
            IDN3: 1894,
            LIBELLEN3: 'CITE HARDOUB',
            IDN2: 154,
            CODEP: '1240',
          },
          {
            IDN3: 1895,
            LIBELLEN3: 'CITE PIERRE NOIRE',
            IDN2: 154,
            CODEP: '1240',
          },
          {
            IDN3: 1896,
            LIBELLEN3: 'FERIANA',
            IDN2: 154,
            CODEP: '1240',
          },
          {
            IDN3: 1899,
            LIBELLEN3: 'GARAAT ENNAAM',
            IDN2: 154,
            CODEP: '1243',
          },
          {
            IDN3: 1898,
            LIBELLEN3: 'LAHOUACHE',
            IDN2: 154,
            CODEP: '1242',
          },
          {
            IDN3: 1897,
            LIBELLEN3: 'OUM ALI',
            IDN2: 154,
            CODEP: '1240',
          },
          {
            IDN3: 1889,
            LIBELLEN3: 'THELEPTE',
            IDN2: 154,
            CODEP: '1215',
          },
        ],
      },
      {
        ville: 155,
        villeName: 'FOUSSANA',
        cites: [
          {
            IDN3: 1912,
            LIBELLEN3: 'AIN JANAN',
            IDN2: 155,
            CODEP: '1220',
          },
          {
            IDN3: 1910,
            LIBELLEN3: 'BOU DERYES',
            IDN2: 155,
            CODEP: '1232',
          },
          {
            IDN3: 1913,
            LIBELLEN3: 'CITE BOU LILA',
            IDN2: 155,
            CODEP: '1220',
          },
          {
            IDN3: 1915,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 155,
            CODEP: '1220',
          },
          {
            IDN3: 1916,
            LIBELLEN3: 'CITE EL FATEH',
            IDN2: 155,
            CODEP: '1220',
          },
          {
            IDN3: 1914,
            LIBELLEN3: 'CITE EL INTILAKA',
            IDN2: 155,
            CODEP: '1220',
          },
          {
            IDN3: 1917,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 155,
            CODEP: '1220',
          },
          {
            IDN3: 1901,
            LIBELLEN3: 'EL ADHIRA',
            IDN2: 155,
            CODEP: '1220',
          },
          {
            IDN3: 1902,
            LIBELLEN3: 'EL BRIKA',
            IDN2: 155,
            CODEP: '1220',
          },
          {
            IDN3: 1903,
            LIBELLEN3: 'ESSATOUR',
            IDN2: 155,
            CODEP: '1220',
          },
          {
            IDN3: 1904,
            LIBELLEN3: 'FOUSSANA',
            IDN2: 155,
            CODEP: '1220',
          },
          {
            IDN3: 1911,
            LIBELLEN3: 'KHEMOUDA',
            IDN2: 155,
            CODEP: '1212',
          },
          {
            IDN3: 1905,
            LIBELLEN3: 'OULED BOUGHANEM',
            IDN2: 155,
            CODEP: '1220',
          },
          {
            IDN3: 1906,
            LIBELLEN3: 'OULED MANSOUR',
            IDN2: 155,
            CODEP: '1220',
          },
          {
            IDN3: 1907,
            LIBELLEN3: 'OULED ZID',
            IDN2: 155,
            CODEP: '1220',
          },
          {
            IDN3: 1908,
            LIBELLEN3: 'OUM LAHOUADH',
            IDN2: 155,
            CODEP: '1220',
          },
          {
            IDN3: 1909,
            LIBELLEN3: 'SAHRAOUI',
            IDN2: 155,
            CODEP: '1222',
          },
        ],
      },
      {
        ville: 148,
        villeName: 'HAIDRA',
        cites: [
          {
            IDN3: 1832,
            LIBELLEN3: 'AIN EDDEFLA',
            IDN2: 148,
            CODEP: '1285',
          },
          {
            IDN3: 1820,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 148,
            CODEP: '1221',
          },
          {
            IDN3: 1821,
            LIBELLEN3: 'CITE EL FATEH',
            IDN2: 148,
            CODEP: '1221',
          },
          {
            IDN3: 1822,
            LIBELLEN3: 'CITE EL INTILAKA',
            IDN2: 148,
            CODEP: '1221',
          },
          {
            IDN3: 1824,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 148,
            CODEP: '1221',
          },
          {
            IDN3: 1826,
            LIBELLEN3: 'CITE ETTADHAMEN',
            IDN2: 148,
            CODEP: '1221',
          },
          {
            IDN3: 1827,
            LIBELLEN3: 'CITE IBN CHAREF',
            IDN2: 148,
            CODEP: '1221',
          },
          {
            IDN3: 1828,
            LIBELLEN3: 'CITE OULED OUNALLAH',
            IDN2: 148,
            CODEP: '1221',
          },
          {
            IDN3: 1825,
            LIBELLEN3: 'EL AJRED',
            IDN2: 148,
            CODEP: '1225',
          },
          {
            IDN3: 1829,
            LIBELLEN3: 'EL MRIRA',
            IDN2: 148,
            CODEP: '1221',
          },
          {
            IDN3: 1830,
            LIBELLEN3: 'ERRMILA',
            IDN2: 148,
            CODEP: '1221',
          },
          {
            IDN3: 1831,
            LIBELLEN3: 'ESSRI',
            IDN2: 148,
            CODEP: '1221',
          },
          {
            IDN3: 1833,
            LIBELLEN3: 'GARGARA',
            IDN2: 148,
            CODEP: '1221',
          },
          {
            IDN3: 1819,
            LIBELLEN3: 'HAIDRA',
            IDN2: 148,
            CODEP: '1221',
          },
          {
            IDN3: 1823,
            LIBELLEN3: 'TEBAGA',
            IDN2: 148,
            CODEP: '1221',
          },
        ],
      },
      {
        ville: 156,
        villeName: 'HASSI EL FRID',
        cites: [
          {
            IDN3: 1919,
            LIBELLEN3: 'EL KAMOUR',
            IDN2: 156,
            CODEP: '1245',
          },
          {
            IDN3: 1918,
            LIBELLEN3: 'HASSI EL FRID',
            IDN2: 156,
            CODEP: '1241',
          },
          {
            IDN3: 1920,
            LIBELLEN3: 'KHANGUET EL JAZIA',
            IDN2: 156,
            CODEP: '1247',
          },
        ],
      },
      {
        ville: 157,
        villeName: 'JEDILIANE',
        cites: [
          {
            IDN3: 1928,
            LIBELLEN3: 'AIN EL HMADNA',
            IDN2: 157,
            CODEP: '1281',
          },
          {
            IDN3: 1921,
            LIBELLEN3: 'AIN OUM JDOUR',
            IDN2: 157,
            CODEP: '1280',
          },
          {
            IDN3: 1922,
            LIBELLEN3: 'CITE 7 NOVEMBRE',
            IDN2: 157,
            CODEP: '1280',
          },
          {
            IDN3: 1923,
            LIBELLEN3: 'CITE EL INTILAKA',
            IDN2: 157,
            CODEP: '1280',
          },
          {
            IDN3: 1924,
            LIBELLEN3: 'CITE ETTAAMIR',
            IDN2: 157,
            CODEP: '1280',
          },
          {
            IDN3: 1925,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 157,
            CODEP: '1280',
          },
          {
            IDN3: 1926,
            LIBELLEN3: 'FREJ TERBAH',
            IDN2: 157,
            CODEP: '1280',
          },
          {
            IDN3: 1927,
            LIBELLEN3: 'JEDILIANE',
            IDN2: 157,
            CODEP: '1280',
          },
        ],
      },
      {
        ville: 151,
        villeName: 'KASSERINE NORD',
        cites: [
          {
            IDN3: 1879,
            LIBELLEN3: 'BOU LAABA',
            IDN2: 151,
            CODEP: '1253',
          },
          {
            IDN3: 1884,
            LIBELLEN3: 'CITE OUVRIERE',
            IDN2: 151,
            CODEP: '1237',
          },
          {
            IDN3: 1880,
            LIBELLEN3: 'DOUGHRA',
            IDN2: 151,
            CODEP: '1200',
          },
          {
            IDN3: 1881,
            LIBELLEN3: 'KASSERINE',
            IDN2: 151,
            CODEP: '1200',
          },
          {
            IDN3: 1883,
            LIBELLEN3: 'KASSERINE NOUR',
            IDN2: 151,
            CODEP: '1230',
          },
          {
            IDN3: 1882,
            LIBELLEN3: 'MAGDOUDECH',
            IDN2: 151,
            CODEP: '1200',
          },
        ],
      },
      {
        ville: 158,
        villeName: 'KASSERINE SUD',
        cites: [
          {
            IDN3: 1929,
            LIBELLEN3: 'BOU ZGUEM',
            IDN2: 158,
            CODEP: '1233',
          },
        ],
      },
      {
        ville: 150,
        villeName: 'MEJEL BEL ABBES',
        cites: [
          {
            IDN3: 1872,
            LIBELLEN3: 'BARROUKA',
            IDN2: 150,
            CODEP: '1226',
          },
          {
            IDN3: 1876,
            LIBELLEN3: 'CITE COMMERCIALE',
            IDN2: 150,
            CODEP: '1214',
          },
          {
            IDN3: 1878,
            LIBELLEN3: 'CITE DES MARTYRS',
            IDN2: 150,
            CODEP: '1214',
          },
          {
            IDN3: 1875,
            LIBELLEN3: 'CITE DU LYCEE',
            IDN2: 150,
            CODEP: '1214',
          },
          {
            IDN3: 1877,
            LIBELLEN3: 'CITE EDDACHRA 1',
            IDN2: 150,
            CODEP: '1214',
          },
          {
            IDN3: 1864,
            LIBELLEN3: 'CITE EDDACHRA 2',
            IDN2: 150,
            CODEP: '1214',
          },
          {
            IDN3: 1865,
            LIBELLEN3: 'CITE EL GUERAYRIA',
            IDN2: 150,
            CODEP: '1214',
          },
          {
            IDN3: 1866,
            LIBELLEN3: 'CITE NOUVELLE 1',
            IDN2: 150,
            CODEP: '1214',
          },
          {
            IDN3: 1867,
            LIBELLEN3: 'CITE NOUVELLE 2',
            IDN2: 150,
            CODEP: '1214',
          },
          {
            IDN3: 1868,
            LIBELLEN3: 'CITE POPULAIRE',
            IDN2: 150,
            CODEP: '1214',
          },
          {
            IDN3: 1874,
            LIBELLEN3: 'EL FEKKA',
            IDN2: 150,
            CODEP: '1294',
          },
          {
            IDN3: 1873,
            LIBELLEN3: 'GARAAT ENNADHOUR',
            IDN2: 150,
            CODEP: '1293',
          },
          {
            IDN3: 1869,
            LIBELLEN3: 'HENCHIR OUM EL KHIR',
            IDN2: 150,
            CODEP: '1214',
          },
          {
            IDN3: 1870,
            LIBELLEN3: 'MEJEL BEL ABBES',
            IDN2: 150,
            CODEP: '1214',
          },
          {
            IDN3: 1871,
            LIBELLEN3: 'OULED MARZOUG',
            IDN2: 150,
            CODEP: '1214',
          },
        ],
      },
      {
        ville: 149,
        villeName: 'SBEITLA',
        cites: [
          {
            IDN3: 1835,
            LIBELLEN3: 'CITE DES ENSEIGNANTS',
            IDN2: 149,
            CODEP: '1250',
          },
          {
            IDN3: 1836,
            LIBELLEN3: 'CITE DHRAA BOU OUAJ',
            IDN2: 149,
            CODEP: '1250',
          },
          {
            IDN3: 1837,
            LIBELLEN3: 'CITE DU LYCEE',
            IDN2: 149,
            CODEP: '1250',
          },
          {
            IDN3: 1834,
            LIBELLEN3: 'CITE DU STADE',
            IDN2: 149,
            CODEP: '1250',
          },
          {
            IDN3: 1839,
            LIBELLEN3: 'CITE EL FATEH',
            IDN2: 149,
            CODEP: '1250',
          },
          {
            IDN3: 1838,
            LIBELLEN3: 'CITE EL GHABETTE',
            IDN2: 149,
            CODEP: '1250',
          },
          {
            IDN3: 1846,
            LIBELLEN3: 'CITE EL KHADHRA',
            IDN2: 149,
            CODEP: '1250',
          },
          {
            IDN3: 1841,
            LIBELLEN3: 'CITE EL OULJA',
            IDN2: 149,
            CODEP: '1250',
          },
          {
            IDN3: 1845,
            LIBELLEN3: 'CITE ERRAHBA',
            IDN2: 149,
            CODEP: '1250',
          },
          {
            IDN3: 1863,
            LIBELLEN3: 'CITE ESSOUROUR',
            IDN2: 149,
            CODEP: '1263',
          },
          {
            IDN3: 1840,
            LIBELLEN3: 'CITE ESSOUROUR ECHERGUI',
            IDN2: 149,
            CODEP: '1250',
          },
          {
            IDN3: 1847,
            LIBELLEN3: 'CITE ESSOUROUR EL GHARBI',
            IDN2: 149,
            CODEP: '1250',
          },
          {
            IDN3: 1849,
            LIBELLEN3: 'CITE EZZAYATINE',
            IDN2: 149,
            CODEP: '1250',
          },
          {
            IDN3: 1848,
            LIBELLEN3: 'CITE HOPITAL',
            IDN2: 149,
            CODEP: '1250',
          },
          {
            IDN3: 1843,
            LIBELLEN3: 'CITE HUILLERIE',
            IDN2: 149,
            CODEP: '1250',
          },
          {
            IDN3: 1844,
            LIBELLEN3: 'CITE INDUSTRIELLE',
            IDN2: 149,
            CODEP: '1250',
          },
          {
            IDN3: 1842,
            LIBELLEN3: 'CITE MILITAIRE 1',
            IDN2: 149,
            CODEP: '1250',
          },
          {
            IDN3: 1850,
            LIBELLEN3: 'CITE MILITAIRE 2',
            IDN2: 149,
            CODEP: '1250',
          },
          {
            IDN3: 1851,
            LIBELLEN3: 'CITE MILITAIRE 3',
            IDN2: 149,
            CODEP: '1250',
          },
          {
            IDN3: 1858,
            LIBELLEN3: 'ECHRAYA',
            IDN2: 149,
            CODEP: '1251',
          },
          {
            IDN3: 1852,
            LIBELLEN3: 'EL GARAA',
            IDN2: 149,
            CODEP: '1250',
          },
          {
            IDN3: 1853,
            LIBELLEN3: 'EL GHARADEK',
            IDN2: 149,
            CODEP: '1250',
          },
          {
            IDN3: 1854,
            LIBELLEN3: 'EL GOUNNA',
            IDN2: 149,
            CODEP: '1250',
          },
          {
            IDN3: 1855,
            LIBELLEN3: 'EL HAMMAR',
            IDN2: 149,
            CODEP: '1250',
          },
          {
            IDN3: 1859,
            LIBELLEN3: 'ERRAHAYET',
            IDN2: 149,
            CODEP: '1252',
          },
          {
            IDN3: 1862,
            LIBELLEN3: 'HENCHIR EL ASSAL',
            IDN2: 149,
            CODEP: '1256',
          },
          {
            IDN3: 1856,
            LIBELLEN3: 'MGHILA',
            IDN2: 149,
            CODEP: '1250',
          },
          {
            IDN3: 1861,
            LIBELLEN3: 'OUED MIOU',
            IDN2: 149,
            CODEP: '1255',
          },
          {
            IDN3: 1857,
            LIBELLEN3: 'SBEITLA',
            IDN2: 149,
            CODEP: '1250',
          },
          {
            IDN3: 1860,
            LIBELLEN3: 'ZAOUIET BEN AMMAR',
            IDN2: 149,
            CODEP: '1254',
          },
        ],
      },
      {
        ville: 159,
        villeName: 'SBIBA',
        cites: [
          {
            IDN3: 1939,
            LIBELLEN3: 'AIN KHEMAISSIA',
            IDN2: 159,
            CODEP: '1271',
          },
          {
            IDN3: 1941,
            LIBELLEN3: 'CITE AIR NOUVELLE',
            IDN2: 159,
            CODEP: '1270',
          },
          {
            IDN3: 1942,
            LIBELLEN3: 'CITE DES ROUINES ROMAINES',
            IDN2: 159,
            CODEP: '1270',
          },
          {
            IDN3: 1943,
            LIBELLEN3: 'CITE DU LYCEE',
            IDN2: 159,
            CODEP: '1270',
          },
          {
            IDN3: 1944,
            LIBELLEN3: 'CITE EDDAMOUS',
            IDN2: 159,
            CODEP: '1270',
          },
          {
            IDN3: 1945,
            LIBELLEN3: 'CITE EL FATEH',
            IDN2: 159,
            CODEP: '1270',
          },
          {
            IDN3: 1946,
            LIBELLEN3: 'CITE EL KHADHRA 1',
            IDN2: 159,
            CODEP: '1270',
          },
          {
            IDN3: 1947,
            LIBELLEN3: 'CITE EL KHADHRA 2',
            IDN2: 159,
            CODEP: '1270',
          },
          {
            IDN3: 1948,
            LIBELLEN3: 'CITE ENNAKHLA',
            IDN2: 159,
            CODEP: '1270',
          },
          {
            IDN3: 1930,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 159,
            CODEP: '1270',
          },
          {
            IDN3: 1931,
            LIBELLEN3: 'CITE ESSALAMA',
            IDN2: 159,
            CODEP: '1270',
          },
          {
            IDN3: 1932,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 159,
            CODEP: '1270',
          },
          {
            IDN3: 1933,
            LIBELLEN3: 'DHRAA',
            IDN2: 159,
            CODEP: '1270',
          },
          {
            IDN3: 1934,
            LIBELLEN3: 'EL HASNAOUI',
            IDN2: 159,
            CODEP: '1270',
          },
          {
            IDN3: 1935,
            LIBELLEN3: 'KOUNBITRA',
            IDN2: 159,
            CODEP: '1270',
          },
          {
            IDN3: 1936,
            LIBELLEN3: 'OULJET AGUIL',
            IDN2: 159,
            CODEP: '1270',
          },
          {
            IDN3: 1937,
            LIBELLEN3: 'SBIBA',
            IDN2: 159,
            CODEP: '1270',
          },
          {
            IDN3: 1940,
            LIBELLEN3: 'SIDI BRAHIM EZZAHER',
            IDN2: 159,
            CODEP: '1273',
          },
          {
            IDN3: 1938,
            LIBELLEN3: 'THEMED',
            IDN2: 159,
            CODEP: '1270',
          },
        ],
      },
      {
        ville: 160,
        villeName: 'THALA',
        cites: [
          {
            IDN3: 1961,
            LIBELLEN3: 'BOU LAHNECH',
            IDN2: 160,
            CODEP: '1211',
          },
          {
            IDN3: 1966,
            LIBELLEN3: 'CHAFAI',
            IDN2: 160,
            CODEP: '1210',
          },
          {
            IDN3: 1969,
            LIBELLEN3: 'CITE AIN AHMED',
            IDN2: 160,
            CODEP: '1210',
          },
          {
            IDN3: 1970,
            LIBELLEN3: 'CITE BAIN MAURE BEN AZOUZ',
            IDN2: 160,
            CODEP: '1210',
          },
          {
            IDN3: 1967,
            LIBELLEN3: 'CITE BEN CHERIF',
            IDN2: 160,
            CODEP: '1210',
          },
          {
            IDN3: 1968,
            LIBELLEN3: 'CITE DES OUVRIERS',
            IDN2: 160,
            CODEP: '1210',
          },
          {
            IDN3: 1949,
            LIBELLEN3: 'CITE DU BATTOIRE',
            IDN2: 160,
            CODEP: '1210',
          },
          {
            IDN3: 1950,
            LIBELLEN3: 'CITE EL BORNI',
            IDN2: 160,
            CODEP: '1210',
          },
          {
            IDN3: 1951,
            LIBELLEN3: 'CITE EL MHIRI',
            IDN2: 160,
            CODEP: '1210',
          },
          {
            IDN3: 1952,
            LIBELLEN3: 'CITE ENNADHOUR',
            IDN2: 160,
            CODEP: '1210',
          },
          {
            IDN3: 1953,
            LIBELLEN3: 'CITE ERRAHBA',
            IDN2: 160,
            CODEP: '1210',
          },
          {
            IDN3: 1954,
            LIBELLEN3: 'CITE GARDE NATIONALE',
            IDN2: 160,
            CODEP: '1210',
          },
          {
            IDN3: 1955,
            LIBELLEN3: 'CITE KHAZNA JEDIDA',
            IDN2: 160,
            CODEP: '1210',
          },
          {
            IDN3: 1956,
            LIBELLEN3: 'CITE MONGI SLIM',
            IDN2: 160,
            CODEP: '1210',
          },
          {
            IDN3: 1957,
            LIBELLEN3: 'CITE SNIT',
            IDN2: 160,
            CODEP: '1210',
          },
          {
            IDN3: 1962,
            LIBELLEN3: 'EDDACHRA',
            IDN2: 160,
            CODEP: '1224',
          },
          {
            IDN3: 1958,
            LIBELLEN3: 'HENCHIR GOUMRIA',
            IDN2: 160,
            CODEP: '1210',
          },
          {
            IDN3: 1959,
            LIBELLEN3: 'OULED GHIDA',
            IDN2: 160,
            CODEP: '1210',
          },
          {
            IDN3: 1963,
            LIBELLEN3: 'SIDI SHIL',
            IDN2: 160,
            CODEP: '1231',
          },
          {
            IDN3: 1960,
            LIBELLEN3: 'THALA',
            IDN2: 160,
            CODEP: '1210',
          },
          {
            IDN3: 1965,
            LIBELLEN3: 'THALA SUD',
            IDN2: 160,
            CODEP: '1261',
          },
          {
            IDN3: 1964,
            LIBELLEN3: 'ZELFANE',
            IDN2: 160,
            CODEP: '1235',
          },
        ],
      },
    ],
  },
  {
    govLabel: 'KEBILI',
    govId: 10,
    villes: [
      {
        ville: 164,
        villeName: 'DOUZ',
        cites: [
          {
            IDN3: 2040,
            LIBELLEN3: 'BECHNI',
            IDN2: 164,
            CODEP: '4222',
          },
          {
            IDN3: 2027,
            LIBELLEN3: 'CITE 7 NOVEMBRE',
            IDN2: 164,
            CODEP: '4234',
          },
          {
            IDN3: 2018,
            LIBELLEN3: 'CITE BEN HAMROUN',
            IDN2: 164,
            CODEP: '4216',
          },
          {
            IDN3: 2028,
            LIBELLEN3: 'CITE COMMERCIALE',
            IDN2: 164,
            CODEP: '4234',
          },
          {
            IDN3: 2029,
            LIBELLEN3: 'CITE DES OASIS',
            IDN2: 164,
            CODEP: '4234',
          },
          {
            IDN3: 2014,
            LIBELLEN3: 'CITE DOUZ CHARGUI',
            IDN2: 164,
            CODEP: '4215',
          },
          {
            IDN3: 2015,
            LIBELLEN3: 'CITE EL ABADLA',
            IDN2: 164,
            CODEP: '4215',
          },
          {
            IDN3: 2019,
            LIBELLEN3: 'CITE EL ATHEMNA',
            IDN2: 164,
            CODEP: '4216',
          },
          {
            IDN3: 2030,
            LIBELLEN3: 'CITE EL IZDIHAR',
            IDN2: 164,
            CODEP: '4234',
          },
          {
            IDN3: 2031,
            LIBELLEN3: 'CITE EL OUROUD',
            IDN2: 164,
            CODEP: '4234',
          },
          {
            IDN3: 2033,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 164,
            CODEP: '4234',
          },
          {
            IDN3: 2034,
            LIBELLEN3: 'CITE ESSAADA',
            IDN2: 164,
            CODEP: '4234',
          },
          {
            IDN3: 2035,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 164,
            CODEP: '4234',
          },
          {
            IDN3: 2036,
            LIBELLEN3: 'CITE IBN KHALDOUN',
            IDN2: 164,
            CODEP: '4234',
          },
          {
            IDN3: 2038,
            LIBELLEN3: 'CITE JELAILA',
            IDN2: 164,
            CODEP: '4260',
          },
          {
            IDN3: 2020,
            LIBELLEN3: 'CITE OULED ABDALLAH',
            IDN2: 164,
            CODEP: '4216',
          },
          {
            IDN3: 2022,
            LIBELLEN3: 'CITE OULED AMOR',
            IDN2: 164,
            CODEP: '4216',
          },
          {
            IDN3: 2021,
            LIBELLEN3: 'CITE OULED OUN',
            IDN2: 164,
            CODEP: '4260',
          },
          {
            IDN3: 2016,
            LIBELLEN3: 'CITE POPULAIRE',
            IDN2: 164,
            CODEP: '4215',
          },
          {
            IDN3: 2032,
            LIBELLEN3: 'DOUZ',
            IDN2: 164,
            CODEP: '4260',
          },
          {
            IDN3: 2023,
            LIBELLEN3: 'DOUZ AOUINA',
            IDN2: 164,
            CODEP: '4216',
          },
          {
            IDN3: 2017,
            LIBELLEN3: 'DOUZ CHARGUI',
            IDN2: 164,
            CODEP: '4215',
          },
          {
            IDN3: 2025,
            LIBELLEN3: 'EDDORGINE',
            IDN2: 164,
            CODEP: '4222',
          },
          {
            IDN3: 2042,
            LIBELLEN3: 'EL AOUINA',
            IDN2: 164,
            CODEP: '4216',
          },
          {
            IDN3: 2041,
            LIBELLEN3: 'GHELISSIA',
            IDN2: 164,
            CODEP: '4260',
          },
          {
            IDN3: 2037,
            LIBELLEN3: 'GOLAA',
            IDN2: 164,
            CODEP: '4234',
          },
          {
            IDN3: 2039,
            LIBELLEN3: 'KSAR GHILENE',
            IDN2: 164,
            CODEP: '4260',
          },
          {
            IDN3: 2026,
            LIBELLEN3: 'NOUAIEL',
            IDN2: 164,
            CODEP: '4222',
          },
          {
            IDN3: 2024,
            LIBELLEN3: 'ZAAFRANE',
            IDN2: 164,
            CODEP: '4261',
          },
        ],
      },
      {
        ville: 165,
        villeName: 'EL FAOUAR',
        cites: [
          {
            IDN3: 2045,
            LIBELLEN3: 'CITE EL HOUDA',
            IDN2: 165,
            CODEP: '4264',
          },
          {
            IDN3: 2046,
            LIBELLEN3: 'CITE EL MOUSTAKBEL',
            IDN2: 165,
            CODEP: '4264',
          },
          {
            IDN3: 2047,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 165,
            CODEP: '4264',
          },
          {
            IDN3: 2048,
            LIBELLEN3: 'CITE ESSALEM',
            IDN2: 165,
            CODEP: '4264',
          },
          {
            IDN3: 2049,
            LIBELLEN3: 'EL FAOUAR',
            IDN2: 165,
            CODEP: '4264',
          },
          {
            IDN3: 2050,
            LIBELLEN3: 'GHERIB',
            IDN2: 165,
            CODEP: '4264',
          },
          {
            IDN3: 2043,
            LIBELLEN3: 'GHIDMA',
            IDN2: 165,
            CODEP: '4264',
          },
          {
            IDN3: 2044,
            LIBELLEN3: 'REJIME MAATOUG',
            IDN2: 165,
            CODEP: '4210',
          },
          {
            IDN3: 2051,
            LIBELLEN3: 'SABRIA',
            IDN2: 165,
            CODEP: '4264',
          },
        ],
      },
      {
        ville: 163,
        villeName: 'KEBILI NORD',
        cites: [
          {
            IDN3: 2004,
            LIBELLEN3: 'EL MANSOURA',
            IDN2: 163,
            CODEP: '4293',
          },
          {
            IDN3: 2005,
            LIBELLEN3: 'JEDIDA',
            IDN2: 163,
            CODEP: '4293',
          },
          {
            IDN3: 2003,
            LIBELLEN3: 'KEBILI BEYEZ',
            IDN2: 163,
            CODEP: '4280',
          },
          {
            IDN3: 2013,
            LIBELLEN3: 'LIMAGUEZ',
            IDN2: 163,
            CODEP: '4274',
          },
          {
            IDN3: 2012,
            LIBELLEN3: 'RABTA',
            IDN2: 163,
            CODEP: '4233',
          },
          {
            IDN3: 2007,
            LIBELLEN3: 'SAIDANE',
            IDN2: 163,
            CODEP: '4294',
          },
          {
            IDN3: 2008,
            LIBELLEN3: 'STAFTIMI',
            IDN2: 163,
            CODEP: '4273',
          },
          {
            IDN3: 2010,
            LIBELLEN3: 'TELMINE',
            IDN2: 163,
            CODEP: '4237',
          },
          {
            IDN3: 2011,
            LIBELLEN3: 'TENBIB',
            IDN2: 163,
            CODEP: '4232',
          },
          {
            IDN3: 2009,
            LIBELLEN3: 'TOMBAR',
            IDN2: 163,
            CODEP: '4235',
          },
          {
            IDN3: 2006,
            LIBELLEN3: 'TOURA MANSOURA',
            IDN2: 163,
            CODEP: '4293',
          },
        ],
      },
      {
        ville: 162,
        villeName: 'KEBILI SUD',
        cites: [
          {
            IDN3: 1999,
            LIBELLEN3: 'BARGHOUTHIA',
            IDN2: 162,
            CODEP: '4253',
          },
          {
            IDN3: 1996,
            LIBELLEN3: 'BAZMA',
            IDN2: 162,
            CODEP: '4224',
          },
          {
            IDN3: 1994,
            LIBELLEN3: 'BECHELLI',
            IDN2: 162,
            CODEP: '4253',
          },
          {
            IDN3: 1985,
            LIBELLEN3: 'BENI MHAMED',
            IDN2: 162,
            CODEP: '4200',
          },
          {
            IDN3: 1997,
            LIBELLEN3: 'BLIDETTE',
            IDN2: 162,
            CODEP: '4243',
          },
          {
            IDN3: 1986,
            LIBELLEN3: 'CITE AFH',
            IDN2: 162,
            CODEP: '4200',
          },
          {
            IDN3: 1987,
            LIBELLEN3: 'CITE DU LYCEE',
            IDN2: 162,
            CODEP: '4200',
          },
          {
            IDN3: 1988,
            LIBELLEN3: 'CITE MILITAIRE',
            IDN2: 162,
            CODEP: '4200',
          },
          {
            IDN3: 1989,
            LIBELLEN3: 'EL GATAYA',
            IDN2: 162,
            CODEP: '4200',
          },
          {
            IDN3: 1990,
            LIBELLEN3: 'EL MSAID',
            IDN2: 162,
            CODEP: '4200',
          },
          {
            IDN3: 2000,
            LIBELLEN3: 'JANAOURA',
            IDN2: 162,
            CODEP: '4242',
          },
          {
            IDN3: 1992,
            LIBELLEN3: 'JEMNA',
            IDN2: 162,
            CODEP: '4214',
          },
          {
            IDN3: 1984,
            LIBELLEN3: 'JERSINE',
            IDN2: 162,
            CODEP: '4263',
          },
          {
            IDN3: 1991,
            LIBELLEN3: 'KEBILI',
            IDN2: 162,
            CODEP: '4200',
          },
          {
            IDN3: 2002,
            LIBELLEN3: 'KELOUEMEN',
            IDN2: 162,
            CODEP: '4253',
          },
          {
            IDN3: 1993,
            LIBELLEN3: 'MAZRAA NEJI',
            IDN2: 162,
            CODEP: '4200',
          },
          {
            IDN3: 1995,
            LIBELLEN3: 'OUM EL FARTH',
            IDN2: 162,
            CODEP: '4200',
          },
          {
            IDN3: 1998,
            LIBELLEN3: 'RADHOUANE',
            IDN2: 162,
            CODEP: '4200',
          },
          {
            IDN3: 2001,
            LIBELLEN3: 'RAHMET',
            IDN2: 162,
            CODEP: '4211',
          },
        ],
      },
      {
        ville: 161,
        villeName: 'SOUK EL AHAD',
        cites: [
          {
            IDN3: 1982,
            LIBELLEN3: 'BECHRI',
            IDN2: 161,
            CODEP: '4231',
          },
          {
            IDN3: 1983,
            LIBELLEN3: 'BOU ABDALLAH',
            IDN2: 161,
            CODEP: '4236',
          },
          {
            IDN3: 1971,
            LIBELLEN3: 'CHOUCHET NEGGA',
            IDN2: 161,
            CODEP: '4283',
          },
          {
            IDN3: 1975,
            LIBELLEN3: 'FATNASSA',
            IDN2: 161,
            CODEP: '4223',
          },
          {
            IDN3: 1976,
            LIBELLEN3: 'GLIAA',
            IDN2: 161,
            CODEP: '4230',
          },
          {
            IDN3: 1977,
            LIBELLEN3: 'JAZIRA',
            IDN2: 161,
            CODEP: '4230',
          },
          {
            IDN3: 1978,
            LIBELLEN3: 'JAZIRA EL BAIIDA',
            IDN2: 161,
            CODEP: '4230',
          },
          {
            IDN3: 1979,
            LIBELLEN3: 'MENCHIA',
            IDN2: 161,
            CODEP: '4230',
          },
          {
            IDN3: 1972,
            LIBELLEN3: 'NEGGA',
            IDN2: 161,
            CODEP: '4283',
          },
          {
            IDN3: 1973,
            LIBELLEN3: 'OUM SOMAA',
            IDN2: 161,
            CODEP: '4212',
          },
          {
            IDN3: 1980,
            LIBELLEN3: 'SOUK EL AHAD',
            IDN2: 161,
            CODEP: '4230',
          },
          {
            IDN3: 1974,
            LIBELLEN3: 'ZAOUIET EL ANES',
            IDN2: 161,
            CODEP: '4213',
          },
          {
            IDN3: 1981,
            LIBELLEN3: 'ZAOUIET EL HARTH',
            IDN2: 161,
            CODEP: '4230',
          },
        ],
      },
    ],
  },
  {
    govLabel: 'KEF',
    govId: 11,
    villes: [
      {
        ville: 173,
        villeName: 'DAHMANI',
        cites: [
          {
            IDN3: 2223,
            LIBELLEN3: 'ABIDA',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2224,
            LIBELLEN3: 'ADISSI',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2225,
            LIBELLEN3: 'AIN MESKHIA',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2226,
            LIBELLEN3: 'CHARKET ESSABOUN',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2227,
            LIBELLEN3: 'CITE 1 MAI 1',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2228,
            LIBELLEN3: 'CITE 1 MAI 2',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2229,
            LIBELLEN3: 'CITE 2 MARS 1',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2230,
            LIBELLEN3: 'CITE 2 MARS 2',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2231,
            LIBELLEN3: 'CITE BEN AMMAR 1',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2232,
            LIBELLEN3: 'CITE BEN AMMAR 2',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2233,
            LIBELLEN3: 'CITE BEN HAFDHALLAH',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2234,
            LIBELLEN3: 'CITE EL AZIMA',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2235,
            LIBELLEN3: 'CITE EL INTILAK',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2236,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2237,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2251,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2240,
            LIBELLEN3: 'CITE ESSANABEL',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2250,
            LIBELLEN3: 'CITE ESSANAOUBER 1',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2239,
            LIBELLEN3: 'CITE ESSANAOUBER 2',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2241,
            LIBELLEN3: 'CITE ETTENMIA',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2242,
            LIBELLEN3: 'CITE HOPITAL',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2243,
            LIBELLEN3: 'CITE IBN KHALDOUN',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2244,
            LIBELLEN3: 'CITE MOHAMED ALI',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2245,
            LIBELLEN3: 'CITE SIDI DAHMANI',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2246,
            LIBELLEN3: 'DAHMANI',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2247,
            LIBELLEN3: 'SIDI ASKER',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2248,
            LIBELLEN3: 'SIDI BARAKET NORD',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2249,
            LIBELLEN3: 'SIDI BARAKET SUD',
            IDN2: 173,
            CODEP: '7170',
          },
          {
            IDN3: 2238,
            LIBELLEN3: 'ZOUARINE',
            IDN2: 173,
            CODEP: '7170',
          },
        ],
      },
      {
        ville: 171,
        villeName: 'EL KSOUR',
        cites: [
          {
            IDN3: 2148,
            LIBELLEN3: 'AIN EL FEDHIL',
            IDN2: 171,
            CODEP: '7160',
          },
          {
            IDN3: 2149,
            LIBELLEN3: 'AIN KSIBA',
            IDN2: 171,
            CODEP: '7160',
          },
          {
            IDN3: 2150,
            LIBELLEN3: 'BANOU',
            IDN2: 171,
            CODEP: '7160',
          },
          {
            IDN3: 2151,
            LIBELLEN3: 'CITE ALI EL BAHLOUL',
            IDN2: 171,
            CODEP: '7160',
          },
          {
            IDN3: 2152,
            LIBELLEN3: 'CITE ATTOUCHE',
            IDN2: 171,
            CODEP: '7160',
          },
          {
            IDN3: 2160,
            LIBELLEN3: 'CITE CENTRE VILLE',
            IDN2: 171,
            CODEP: '7160',
          },
          {
            IDN3: 2161,
            LIBELLEN3: 'CITE EL BORJ',
            IDN2: 171,
            CODEP: '7160',
          },
          {
            IDN3: 2162,
            LIBELLEN3: 'CITE EL HELLA',
            IDN2: 171,
            CODEP: '7160',
          },
          {
            IDN3: 2163,
            LIBELLEN3: 'CITE ESSAADA',
            IDN2: 171,
            CODEP: '7160',
          },
          {
            IDN3: 2164,
            LIBELLEN3: 'CITE MOHAMED ALI',
            IDN2: 171,
            CODEP: '7160',
          },
          {
            IDN3: 2165,
            LIBELLEN3: 'CITE SAFIA 1',
            IDN2: 171,
            CODEP: '7160',
          },
          {
            IDN3: 2166,
            LIBELLEN3: 'CITE SAFIA 2',
            IDN2: 171,
            CODEP: '7160',
          },
          {
            IDN3: 2153,
            LIBELLEN3: 'CITE SIDI ALI MAHJOUB',
            IDN2: 171,
            CODEP: '7160',
          },
          {
            IDN3: 2154,
            LIBELLEN3: 'CITE SIDI MANSOUR',
            IDN2: 171,
            CODEP: '7160',
          },
          {
            IDN3: 2155,
            LIBELLEN3: 'ECHAABNA',
            IDN2: 171,
            CODEP: '7160',
          },
          {
            IDN3: 2156,
            LIBELLEN3: 'EDDHILA',
            IDN2: 171,
            CODEP: '7160',
          },
          {
            IDN3: 2157,
            LIBELLEN3: 'EL AFSET',
            IDN2: 171,
            CODEP: '7160',
          },
          {
            IDN3: 2158,
            LIBELLEN3: 'EL HMARNA',
            IDN2: 171,
            CODEP: '7160',
          },
          {
            IDN3: 2159,
            LIBELLEN3: 'EL HOUILAT',
            IDN2: 171,
            CODEP: '7160',
          },
          {
            IDN3: 2146,
            LIBELLEN3: 'EL KSOUR',
            IDN2: 171,
            CODEP: '7160',
          },
          {
            IDN3: 2147,
            LIBELLEN3: 'EZZITOUNA',
            IDN2: 171,
            CODEP: '7115',
          },
          {
            IDN3: 2171,
            LIBELLEN3: 'KIRATA',
            IDN2: 171,
            CODEP: '7160',
          },
          {
            IDN3: 2167,
            LIBELLEN3: 'LOUATA',
            IDN2: 171,
            CODEP: '7160',
          },
          {
            IDN3: 2168,
            LIBELLEN3: 'OULED BOURAOUI',
            IDN2: 171,
            CODEP: '7160',
          },
          {
            IDN3: 2169,
            LIBELLEN3: 'OULED GHANA',
            IDN2: 171,
            CODEP: '7160',
          },
          {
            IDN3: 2170,
            LIBELLEN3: 'OULED ZID',
            IDN2: 171,
            CODEP: '7160',
          },
        ],
      },
      {
        ville: 175,
        villeName: 'JERISSA',
        cites: [
          {
            IDN3: 2299,
            LIBELLEN3: 'AIN EL GARSA',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2300,
            LIBELLEN3: 'CITE AHMED TLILI',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2301,
            LIBELLEN3: 'CITE ALI BEN GHEDHAHOM',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2302,
            LIBELLEN3: 'CITE ALI BEN KHLIFA',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2303,
            LIBELLEN3: 'CITE BOURGUIBA',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2304,
            LIBELLEN3: 'CITE CHAHID EL AID 1',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2305,
            LIBELLEN3: 'CITE CHAHID EL AID 2',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2297,
            LIBELLEN3: 'CITE DE LA GARE',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2298,
            LIBELLEN3: 'CITE DES INGENIEURS',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2269,
            LIBELLEN3: 'CITE DES INSTITUTEURS',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2270,
            LIBELLEN3: 'CITE DES MARTYRS',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2271,
            LIBELLEN3: 'CITE DES OUVRIERS',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2272,
            LIBELLEN3: 'CITE DU SOUK',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2273,
            LIBELLEN3: 'CITE ECHAHID BELGACEM',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2274,
            LIBELLEN3: 'CITE ECHAHID SADOK',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2275,
            LIBELLEN3: 'CITE ERROUKI',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2276,
            LIBELLEN3: 'CITE ESSAADA',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2277,
            LIBELLEN3: 'CITE ESSANAOUBER ANCIENNE',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2278,
            LIBELLEN3: 'CITE ESSANAOUBER NOUVELLE',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2279,
            LIBELLEN3: 'CITE ESSAROUEL',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2280,
            LIBELLEN3: 'CITE ETTAHRIR',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2281,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2282,
            LIBELLEN3: 'CITE HACHED NORD',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2283,
            LIBELLEN3: 'CITE HACHED SUD',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2284,
            LIBELLEN3: 'CITE MOHAMED ALI',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2285,
            LIBELLEN3: 'CITE MOHAMED ALI Nlle',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2286,
            LIBELLEN3: 'CITE SIDI YAHYA',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2287,
            LIBELLEN3: 'CITE TAIEB MHIRI',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2288,
            LIBELLEN3: 'EL GORRAIA',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2289,
            LIBELLEN3: 'EL HAYADRA',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2290,
            LIBELLEN3: 'ENNAIEM',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2291,
            LIBELLEN3: 'ESBIAAT',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2292,
            LIBELLEN3: 'FEJ ETTAMR',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2293,
            LIBELLEN3: 'HENCHIR SARRAT',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2294,
            LIBELLEN3: 'JERISSA',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2295,
            LIBELLEN3: 'JERISSA GARE',
            IDN2: 175,
            CODEP: '7114',
          },
          {
            IDN3: 2296,
            LIBELLEN3: 'OUM EL KELIL',
            IDN2: 175,
            CODEP: '7114',
          },
        ],
      },
      {
        ville: 176,
        villeName: 'KALAA EL KHASBA',
        cites: [
          {
            IDN3: 2307,
            LIBELLEN3: 'CITE EL MELLESSINE',
            IDN2: 176,
            CODEP: '7113',
          },
          {
            IDN3: 2308,
            LIBELLEN3: 'CITE ERROUKA',
            IDN2: 176,
            CODEP: '7113',
          },
          {
            IDN3: 2310,
            LIBELLEN3: 'CITE ESSOUITIR',
            IDN2: 176,
            CODEP: '7113',
          },
          {
            IDN3: 2306,
            LIBELLEN3: 'KALAA EL KHASBA',
            IDN2: 176,
            CODEP: '7113',
          },
          {
            IDN3: 2309,
            LIBELLEN3: 'SIDI AHMED ESSALAH',
            IDN2: 176,
            CODEP: '7123',
          },
        ],
      },
      {
        ville: 174,
        villeName: 'KALAAT SINANE',
        cites: [
          {
            IDN3: 2267,
            LIBELLEN3: 'AIN SINAN',
            IDN2: 174,
            CODEP: '7135',
          },
          {
            IDN3: 2268,
            LIBELLEN3: 'BOU JABEUR',
            IDN2: 174,
            CODEP: '7136',
          },
          {
            IDN3: 2253,
            LIBELLEN3: 'CITE BOURGUIBA',
            IDN2: 174,
            CODEP: '7130',
          },
          {
            IDN3: 2252,
            LIBELLEN3: 'CITE DU JARDIN',
            IDN2: 174,
            CODEP: '7130',
          },
          {
            IDN3: 2254,
            LIBELLEN3: 'CITE DU STADE',
            IDN2: 174,
            CODEP: '7130',
          },
          {
            IDN3: 2255,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 174,
            CODEP: '7130',
          },
          {
            IDN3: 2256,
            LIBELLEN3: 'CITE ENNACIM',
            IDN2: 174,
            CODEP: '7130',
          },
          {
            IDN3: 2257,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 174,
            CODEP: '7130',
          },
          {
            IDN3: 2258,
            LIBELLEN3: 'CITE ESSANABEL',
            IDN2: 174,
            CODEP: '7130',
          },
          {
            IDN3: 2259,
            LIBELLEN3: 'CITE ESSANAOUBER BOU ARARA',
            IDN2: 174,
            CODEP: '7130',
          },
          {
            IDN3: 2260,
            LIBELLEN3: 'CITE EZZOUHOUR BOU ARARA',
            IDN2: 174,
            CODEP: '7130',
          },
          {
            IDN3: 2261,
            LIBELLEN3: 'CITE MONGI SLIM',
            IDN2: 174,
            CODEP: '7130',
          },
          {
            IDN3: 2262,
            LIBELLEN3: 'CITE YOUGHARTA',
            IDN2: 174,
            CODEP: '7130',
          },
          {
            IDN3: 2263,
            LIBELLEN3: 'EL FELTA',
            IDN2: 174,
            CODEP: '7130',
          },
          {
            IDN3: 2264,
            LIBELLEN3: 'ERREBIBA',
            IDN2: 174,
            CODEP: '7130',
          },
          {
            IDN3: 2265,
            LIBELLEN3: 'KALAAT SINANE',
            IDN2: 174,
            CODEP: '7130',
          },
          {
            IDN3: 2266,
            LIBELLEN3: 'SAFSAF',
            IDN2: 174,
            CODEP: '7130',
          },
        ],
      },
      {
        ville: 172,
        villeName: 'LE KEF EST',
        cites: [
          {
            IDN3: 2183,
            LIBELLEN3: 'BAHRA',
            IDN2: 172,
            CODEP: '7116',
          },
          {
            IDN3: 2172,
            LIBELLEN3: 'BARNOUSSA',
            IDN2: 172,
            CODEP: '7100\r\n',
          },
          {
            IDN3: 2184,
            LIBELLEN3: 'BORJ EL AIFA',
            IDN2: 172,
            CODEP: '7122',
          },
          {
            IDN3: 2173,
            LIBELLEN3: 'BOU MEFTAH',
            IDN2: 172,
            CODEP: '7100\r\n',
          },
          {
            IDN3: 2174,
            LIBELLEN3: 'CITE 1ER MAI',
            IDN2: 172,
            CODEP: '7100\r\n',
          },
          {
            IDN3: 2175,
            LIBELLEN3: 'CITE 2 MARS',
            IDN2: 172,
            CODEP: '7100\r\n',
          },
          {
            IDN3: 2176,
            LIBELLEN3: 'CITE 3 AOUT',
            IDN2: 172,
            CODEP: '7100\r\n',
          },
          {
            IDN3: 2177,
            LIBELLEN3: 'CITE 7 NOVEMBRE',
            IDN2: 172,
            CODEP: '7100\r\n',
          },
          {
            IDN3: 2208,
            LIBELLEN3: 'CITE AIN MNEKH',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2207,
            LIBELLEN3: 'CITE AMMAR AYARI',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2212,
            LIBELLEN3: 'CITE BAHRI BARBOUCHE',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2216,
            LIBELLEN3: 'CITE BAYEDH',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2220,
            LIBELLEN3: 'CITE BIR THELJ',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2214,
            LIBELLEN3: 'CITE BOURAYOU',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2218,
            LIBELLEN3: 'CITE CHERICHI',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2222,
            LIBELLEN3: 'CITE DE LA GARE',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2185,
            LIBELLEN3: 'CITE DE LA LIBERTE',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2186,
            LIBELLEN3: 'CITE DES ENSEIGNANTS',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2188,
            LIBELLEN3: 'CITE EDDYR',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2189,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2190,
            LIBELLEN3: 'CITE EL FARAH',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2191,
            LIBELLEN3: 'CITE EL HANA',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2192,
            LIBELLEN3: 'CITE EL IZDIHAR',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2193,
            LIBELLEN3: 'CITE EL OUNS',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2194,
            LIBELLEN3: 'CITE ENNACIM',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2195,
            LIBELLEN3: 'CITE ENNOUHOUDH 1',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2196,
            LIBELLEN3: 'CITE ENNOUHOUDH 2',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2197,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2198,
            LIBELLEN3: 'CITE ESSAADA 1',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2199,
            LIBELLEN3: 'CITE ESSAADA 2',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2200,
            LIBELLEN3: 'CITE ESSAKHRA',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2201,
            LIBELLEN3: 'CITE EZZITOUNA',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2202,
            LIBELLEN3: 'CITE FOURATI',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2203,
            LIBELLEN3: 'CITE HACHED',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2205,
            LIBELLEN3: 'CITE HARROUCH',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2209,
            LIBELLEN3: 'CITE IBN ABI DHIAF',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2210,
            LIBELLEN3: 'CITE IBN KHALDOUN',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2211,
            LIBELLEN3: 'CITE SOUANI LAANEB 1',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2213,
            LIBELLEN3: 'CITE SOUANI LAANEB 2',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2215,
            LIBELLEN3: 'CITE TAIEB MHIRI',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2219,
            LIBELLEN3: 'CITE TOUNSI ARNOUS',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2204,
            LIBELLEN3: 'ENNAIMA',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2187,
            LIBELLEN3: 'LE KEF',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2206,
            LIBELLEN3: 'OUED ERRAML',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2180,
            LIBELLEN3: 'OUED SOUANI',
            IDN2: 172,
            CODEP: '7194',
          },
          {
            IDN3: 2217,
            LIBELLEN3: 'OUED TESSA',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2221,
            LIBELLEN3: 'SALAH EL BAHRI',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2179,
            LIBELLEN3: 'SEMANA',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2181,
            LIBELLEN3: 'SIDI MANSOUR',
            IDN2: 172,
            CODEP: '7100',
          },
          {
            IDN3: 2178,
            LIBELLEN3: 'ZAAFRANE',
            IDN2: 172,
            CODEP: '7134',
          },
          {
            IDN3: 2182,
            LIBELLEN3: 'ZONE INDUSTRIELLE',
            IDN2: 172,
            CODEP: '7100',
          },
        ],
      },
      {
        ville: 177,
        villeName: 'LE KEF OUEST',
        cites: [
          {
            IDN3: 2311,
            LIBELLEN3: 'KEF OUEST',
            IDN2: 177,
            CODEP: '7117',
          },
        ],
      },
      {
        ville: 170,
        villeName: 'LE SERS',
        cites: [
          {
            IDN3: 2125,
            LIBELLEN3: 'BIR HEDDI',
            IDN2: 170,
            CODEP: '7180',
          },
          {
            IDN3: 2126,
            LIBELLEN3: 'BOU SLIAA',
            IDN2: 170,
            CODEP: '7180',
          },
          {
            IDN3: 2127,
            LIBELLEN3: 'CITE DU PONT',
            IDN2: 170,
            CODEP: '7180',
          },
          {
            IDN3: 2128,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 170,
            CODEP: '7180',
          },
          {
            IDN3: 2129,
            LIBELLEN3: 'CITE ENNACIM',
            IDN2: 170,
            CODEP: '7180',
          },
          {
            IDN3: 2130,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 170,
            CODEP: '7180',
          },
          {
            IDN3: 2131,
            LIBELLEN3: 'CITE ENNOUR 2',
            IDN2: 170,
            CODEP: '7180',
          },
          {
            IDN3: 2132,
            LIBELLEN3: 'CITE ENNOUZHA',
            IDN2: 170,
            CODEP: '7180',
          },
          {
            IDN3: 2133,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 170,
            CODEP: '7180',
          },
          {
            IDN3: 2134,
            LIBELLEN3: 'CITE ESSANAOUBER',
            IDN2: 170,
            CODEP: '7180',
          },
          {
            IDN3: 2135,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 170,
            CODEP: '7180',
          },
          {
            IDN3: 2136,
            LIBELLEN3: 'EL ARGOUB',
            IDN2: 170,
            CODEP: '7180',
          },
          {
            IDN3: 2137,
            LIBELLEN3: 'EL MELLAHA',
            IDN2: 170,
            CODEP: '7180',
          },
          {
            IDN3: 2138,
            LIBELLEN3: 'ELLES',
            IDN2: 170,
            CODEP: '7180',
          },
          {
            IDN3: 2139,
            LIBELLEN3: 'LABAR',
            IDN2: 170,
            CODEP: '7180',
          },
          {
            IDN3: 2140,
            LIBELLEN3: 'LE SERS',
            IDN2: 170,
            CODEP: '7180',
          },
          {
            IDN3: 2141,
            LIBELLEN3: 'LE VIEUX SERS',
            IDN2: 170,
            CODEP: '7180',
          },
          {
            IDN3: 2142,
            LIBELLEN3: 'LORBOUS',
            IDN2: 170,
            CODEP: '7180',
          },
          {
            IDN3: 2143,
            LIBELLEN3: 'LORBOUS GARE',
            IDN2: 170,
            CODEP: '7180',
          },
          {
            IDN3: 2144,
            LIBELLEN3: 'SIDI NASSER',
            IDN2: 170,
            CODEP: '7180',
          },
          {
            IDN3: 2145,
            LIBELLEN3: 'TRICHA',
            IDN2: 170,
            CODEP: '7180',
          },
        ],
      },
      {
        ville: 168,
        villeName: 'NEBEUR',
        cites: [
          {
            IDN3: 2103,
            LIBELLEN3: 'AIN EL HENCHIR',
            IDN2: 168,
            CODEP: '7110',
          },
          {
            IDN3: 2104,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 168,
            CODEP: '7110',
          },
          {
            IDN3: 2096,
            LIBELLEN3: 'CITE HADJ AHMED',
            IDN2: 168,
            CODEP: '7110',
          },
          {
            IDN3: 2097,
            LIBELLEN3: 'CITE NOUVELLE',
            IDN2: 168,
            CODEP: '7110',
          },
          {
            IDN3: 2098,
            LIBELLEN3: 'NEBEUR',
            IDN2: 168,
            CODEP: '7110',
          },
          {
            IDN3: 2100,
            LIBELLEN3: 'OUED MELLEGUE',
            IDN2: 168,
            CODEP: '7111',
          },
          {
            IDN3: 2101,
            LIBELLEN3: 'SIDI KHIAR',
            IDN2: 168,
            CODEP: '7131',
          },
          {
            IDN3: 2102,
            LIBELLEN3: 'TELL GHOUZLANE',
            IDN2: 168,
            CODEP: '7141',
          },
          {
            IDN3: 2099,
            LIBELLEN3: 'ZONE INDUSTRIELLE',
            IDN2: 168,
            CODEP: '7110',
          },
        ],
      },
      {
        ville: 169,
        villeName: 'SAKIET SIDI YOUSSEF',
        cites: [
          {
            IDN3: 2112,
            LIBELLEN3: 'AIN EL KARMA',
            IDN2: 169,
            CODEP: '7125',
          },
          {
            IDN3: 2105,
            LIBELLEN3: 'CITE 8 FEVRIER 1',
            IDN2: 169,
            CODEP: '7120',
          },
          {
            IDN3: 2106,
            LIBELLEN3: 'CITE 8 FEVRIER 2',
            IDN2: 169,
            CODEP: '7120',
          },
          {
            IDN3: 2107,
            LIBELLEN3: 'CITE 8 FEVRIER 3',
            IDN2: 169,
            CODEP: '7120',
          },
          {
            IDN3: 2109,
            LIBELLEN3: 'CITE DES OUVRIERS',
            IDN2: 169,
            CODEP: '7120',
          },
          {
            IDN3: 2113,
            LIBELLEN3: 'CITE EL INTILAKA',
            IDN2: 169,
            CODEP: '7120',
          },
          {
            IDN3: 2108,
            LIBELLEN3: 'CITE EL KERFEH',
            IDN2: 169,
            CODEP: '7120',
          },
          {
            IDN3: 2119,
            LIBELLEN3: 'CITE ENNOUR 1',
            IDN2: 169,
            CODEP: '7120',
          },
          {
            IDN3: 2124,
            LIBELLEN3: 'CITE ENNOUR 2',
            IDN2: 169,
            CODEP: '7120',
          },
          {
            IDN3: 2111,
            LIBELLEN3: 'CITE ETTAHRIR',
            IDN2: 169,
            CODEP: '7120',
          },
          {
            IDN3: 2114,
            LIBELLEN3: 'CITE MONIMENTS',
            IDN2: 169,
            CODEP: '7120',
          },
          {
            IDN3: 2115,
            LIBELLEN3: 'CITE SAKMO',
            IDN2: 169,
            CODEP: '7120',
          },
          {
            IDN3: 2116,
            LIBELLEN3: 'CITE SPROLS',
            IDN2: 169,
            CODEP: '7120',
          },
          {
            IDN3: 2117,
            LIBELLEN3: 'ESSAKIA',
            IDN2: 169,
            CODEP: '7120',
          },
          {
            IDN3: 2118,
            LIBELLEN3: 'FARCHEN',
            IDN2: 169,
            CODEP: '7120',
          },
          {
            IDN3: 2120,
            LIBELLEN3: 'JERADOU',
            IDN2: 169,
            CODEP: '7120',
          },
          {
            IDN3: 2121,
            LIBELLEN3: 'LA MINE',
            IDN2: 169,
            CODEP: '7120',
          },
          {
            IDN3: 2122,
            LIBELLEN3: 'PLACE DE LA REPUBLIQUE',
            IDN2: 169,
            CODEP: '7120',
          },
          {
            IDN3: 2123,
            LIBELLEN3: 'SIDI RABEH',
            IDN2: 169,
            CODEP: '7120',
          },
          {
            IDN3: 2110,
            LIBELLEN3: 'TABIA',
            IDN2: 169,
            CODEP: '7120',
          },
        ],
      },
      {
        ville: 166,
        villeName: 'TAJEROUINE',
        cites: [
          {
            IDN3: 2083,
            LIBELLEN3: 'CITE 2 MARS',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2056,
            LIBELLEN3: 'CITE 7 NOVEMBRE',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2061,
            LIBELLEN3: 'CITE AFH',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2074,
            LIBELLEN3: 'CITE AIN EL BAR',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2073,
            LIBELLEN3: 'CITE BOURGUIBA 1',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2068,
            LIBELLEN3: 'CITE BOURGUIBA 2',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2072,
            LIBELLEN3: 'CITE BOURGUIBA 3',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2064,
            LIBELLEN3: 'CITE CIOK',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2078,
            LIBELLEN3: 'CITE DE LA PALESTINE',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2079,
            LIBELLEN3: 'CITE DES MARTYRS',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2080,
            LIBELLEN3: 'CITE DU SOUK',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2081,
            LIBELLEN3: 'CITE DU STADE',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2082,
            LIBELLEN3: 'CITE EL AIN',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2084,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2052,
            LIBELLEN3: 'CITE EL BESMA 1',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2053,
            LIBELLEN3: 'CITE EL BESMA 2',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2054,
            LIBELLEN3: 'CITE EL FOUROUSSIA',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2055,
            LIBELLEN3: 'CITE ERRAHBA',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2057,
            LIBELLEN3: 'CITE ESSAKIA',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2058,
            LIBELLEN3: 'CITE ESSANAOUBER',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2059,
            LIBELLEN3: 'CITE EZZITOUNA',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2060,
            LIBELLEN3: 'CITE HABIB THAMEUR',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2062,
            LIBELLEN3: 'CITE HOPITAL',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2063,
            LIBELLEN3: 'CITE JEBEL',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2067,
            LIBELLEN3: 'CITE TAIEB MHIRI',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2086,
            LIBELLEN3: 'EL HODH',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2070,
            LIBELLEN3: 'GARN EL HALFAYA',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2077,
            LIBELLEN3: 'JAZZA',
            IDN2: 166,
            CODEP: '7153',
          },
          {
            IDN3: 2069,
            LIBELLEN3: 'LA GARE',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2065,
            LIBELLEN3: 'MAHJOUBA',
            IDN2: 166,
            CODEP: '7132',
          },
          {
            IDN3: 2076,
            LIBELLEN3: 'MENZEL SALEM',
            IDN2: 166,
            CODEP: '7151',
          },
          {
            IDN3: 2071,
            LIBELLEN3: 'SIDI ABDELBASSET',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2066,
            LIBELLEN3: 'SIDI MTIR',
            IDN2: 166,
            CODEP: '7133',
          },
          {
            IDN3: 2085,
            LIBELLEN3: 'TAJEROUINE',
            IDN2: 166,
            CODEP: '7150',
          },
          {
            IDN3: 2075,
            LIBELLEN3: 'TAJEROUINE GARE',
            IDN2: 166,
            CODEP: '7150',
          },
        ],
      },
      {
        ville: 167,
        villeName: 'TOUIREF',
        cites: [
          {
            IDN3: 2094,
            LIBELLEN3: 'BIR BEN CHERIFA',
            IDN2: 167,
            CODEP: '7112',
          },
          {
            IDN3: 2095,
            LIBELLEN3: 'CITE EL IZDIHAR',
            IDN2: 167,
            CODEP: '7112',
          },
          {
            IDN3: 2091,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 167,
            CODEP: '7112',
          },
          {
            IDN3: 2092,
            LIBELLEN3: 'CITE ESSAADA',
            IDN2: 167,
            CODEP: '7112',
          },
          {
            IDN3: 2093,
            LIBELLEN3: 'GARGOUR',
            IDN2: 167,
            CODEP: '7112',
          },
          {
            IDN3: 2087,
            LIBELLEN3: 'LADHIEB',
            IDN2: 167,
            CODEP: '7112',
          },
          {
            IDN3: 2088,
            LIBELLEN3: 'MELLALA',
            IDN2: 167,
            CODEP: '7112',
          },
          {
            IDN3: 2089,
            LIBELLEN3: 'OULJET ESSEDRA',
            IDN2: 167,
            CODEP: '7112',
          },
          {
            IDN3: 2090,
            LIBELLEN3: 'TOUIREF',
            IDN2: 167,
            CODEP: '7112',
          },
        ],
      },
    ],
  },
  {
    govLabel: 'MAHDIA',
    govId: 12,
    villes: [
      {
        ville: 183,
        villeName: 'BOU MERDES',
        cites: [
          {
            IDN3: 2402,
            LIBELLEN3: 'BOU HELAL EL ALI NORD',
            IDN2: 183,
            CODEP: '5112',
          },
          {
            IDN3: 2410,
            LIBELLEN3: 'BOU HELAL SUD',
            IDN2: 183,
            CODEP: '5125',
          },
          {
            IDN3: 2413,
            LIBELLEN3: 'BOU MERDES',
            IDN2: 183,
            CODEP: '5110',
          },
          {
            IDN3: 2414,
            LIBELLEN3: 'CHOUARIA',
            IDN2: 183,
            CODEP: '5110',
          },
          {
            IDN3: 2416,
            LIBELLEN3: 'CITE ADMINISTRATIVE',
            IDN2: 183,
            CODEP: '5112',
          },
          {
            IDN3: 2412,
            LIBELLEN3: 'CITE GHRISSINE',
            IDN2: 183,
            CODEP: '5112',
          },
          {
            IDN3: 2403,
            LIBELLEN3: 'CITE JEBEL',
            IDN2: 183,
            CODEP: '5112',
          },
          {
            IDN3: 2404,
            LIBELLEN3: 'CITE RURALE',
            IDN2: 183,
            CODEP: '5112',
          },
          {
            IDN3: 2405,
            LIBELLEN3: 'CITE SNIT',
            IDN2: 183,
            CODEP: '5112',
          },
          {
            IDN3: 2406,
            LIBELLEN3: 'EL AOUADHBIA',
            IDN2: 183,
            CODEP: '5112',
          },
          {
            IDN3: 2415,
            LIBELLEN3: 'EL HOUS',
            IDN2: 183,
            CODEP: '5110',
          },
          {
            IDN3: 2407,
            LIBELLEN3: 'EL MELAHMA',
            IDN2: 183,
            CODEP: '5112',
          },
          {
            IDN3: 2411,
            LIBELLEN3: 'ENNAIMA',
            IDN2: 183,
            CODEP: '5110',
          },
          {
            IDN3: 2408,
            LIBELLEN3: 'ERREBIBETTE',
            IDN2: 183,
            CODEP: '5112',
          },
          {
            IDN3: 2417,
            LIBELLEN3: 'ERROUADHI',
            IDN2: 183,
            CODEP: '5110',
          },
          {
            IDN3: 2409,
            LIBELLEN3: 'KERKER',
            IDN2: 183,
            CODEP: '5112',
          },
          {
            IDN3: 2399,
            LIBELLEN3: 'MENZEL HAMZA EST',
            IDN2: 183,
            CODEP: '5110',
          },
          {
            IDN3: 2400,
            LIBELLEN3: 'MENZEL HAMZA OUEST',
            IDN2: 183,
            CODEP: '5110',
          },
          {
            IDN3: 2401,
            LIBELLEN3: 'ZERATA',
            IDN2: 183,
            CODEP: '5110',
          },
        ],
      },
      {
        ville: 184,
        villeName: 'CHORBANE',
        cites: [
          {
            IDN3: 2435,
            LIBELLEN3: 'CHAHDA',
            IDN2: 184,
            CODEP: '5133',
          },
          {
            IDN3: 2420,
            LIBELLEN3: 'CHARAF',
            IDN2: 184,
            CODEP: '5130',
          },
          {
            IDN3: 2421,
            LIBELLEN3: 'CHORBANE',
            IDN2: 184,
            CODEP: '5130',
          },
          {
            IDN3: 2422,
            LIBELLEN3: 'CITE NOUVELLE',
            IDN2: 184,
            CODEP: '5130',
          },
          {
            IDN3: 2423,
            LIBELLEN3: 'EL BASSORA',
            IDN2: 184,
            CODEP: '5130',
          },
          {
            IDN3: 2419,
            LIBELLEN3: 'EL GOUASSEM',
            IDN2: 184,
            CODEP: '5130',
          },
          {
            IDN3: 2434,
            LIBELLEN3: 'EL GRADHA EST',
            IDN2: 184,
            CODEP: '5130',
          },
          {
            IDN3: 2424,
            LIBELLEN3: 'EL GRADHA OUEST',
            IDN2: 184,
            CODEP: '5130',
          },
          {
            IDN3: 2418,
            LIBELLEN3: 'EL KHIOUR',
            IDN2: 184,
            CODEP: '5130',
          },
          {
            IDN3: 2425,
            LIBELLEN3: 'HENCHIR BOUAZIZ',
            IDN2: 184,
            CODEP: '5130',
          },
          {
            IDN3: 2426,
            LIBELLEN3: 'KHERADNA',
            IDN2: 184,
            CODEP: '5130',
          },
          {
            IDN3: 2427,
            LIBELLEN3: 'MAATI CHERAF',
            IDN2: 184,
            CODEP: '5130',
          },
          {
            IDN3: 2428,
            LIBELLEN3: 'MAATI HENCHIR BOU AZIZ',
            IDN2: 184,
            CODEP: '5130',
          },
          {
            IDN3: 2436,
            LIBELLEN3: 'NEFFATIA',
            IDN2: 184,
            CODEP: '5135',
          },
          {
            IDN3: 2429,
            LIBELLEN3: 'OULED ABDENNEBI',
            IDN2: 184,
            CODEP: '5130',
          },
          {
            IDN3: 2430,
            LIBELLEN3: 'OULED AHMED',
            IDN2: 184,
            CODEP: '5130',
          },
          {
            IDN3: 2431,
            LIBELLEN3: 'OULED CHERIFA',
            IDN2: 184,
            CODEP: '5130',
          },
          {
            IDN3: 2432,
            LIBELLEN3: 'OULED EL HANNACHI',
            IDN2: 184,
            CODEP: '5130',
          },
          {
            IDN3: 2433,
            LIBELLEN3: 'OULED SGHAIER',
            IDN2: 184,
            CODEP: '5130',
          },
        ],
      },
      {
        ville: 188,
        villeName: 'EL JEM',
        cites: [
          {
            IDN3: 2477,
            LIBELLEN3: 'BENI THABET',
            IDN2: 188,
            CODEP: '5160',
          },
          {
            IDN3: 2472,
            LIBELLEN3: 'CITE IBN SINA',
            IDN2: 188,
            CODEP: '5160',
          },
          {
            IDN3: 2473,
            LIBELLEN3: 'CITE NOUVELLE',
            IDN2: 188,
            CODEP: '5160',
          },
          {
            IDN3: 2478,
            LIBELLEN3: 'EL ACHABA',
            IDN2: 188,
            CODEP: '5124',
          },
          {
            IDN3: 2483,
            LIBELLEN3: 'EL AITHA',
            IDN2: 188,
            CODEP: '5153',
          },
          {
            IDN3: 2474,
            LIBELLEN3: 'EL JEM',
            IDN2: 188,
            CODEP: '5160',
          },
          {
            IDN3: 2475,
            LIBELLEN3: 'EL MECHELETTE',
            IDN2: 188,
            CODEP: '5160',
          },
          {
            IDN3: 2484,
            LIBELLEN3: 'OULED EL HAJ',
            IDN2: 188,
            CODEP: '5153',
          },
          {
            IDN3: 2476,
            LIBELLEN3: 'OULED FARHAT',
            IDN2: 188,
            CODEP: '5160',
          },
          {
            IDN3: 2485,
            LIBELLEN3: 'RIADH BOU HELAL',
            IDN2: 188,
            CODEP: '5153',
          },
          {
            IDN3: 2479,
            LIBELLEN3: 'SIDI BOU HELAL',
            IDN2: 188,
            CODEP: '5160',
          },
          {
            IDN3: 2480,
            LIBELLEN3: 'TLELSA',
            IDN2: 188,
            CODEP: '5124',
          },
          {
            IDN3: 2482,
            LIBELLEN3: 'TOUAHRA',
            IDN2: 188,
            CODEP: '5124',
          },
          {
            IDN3: 2481,
            LIBELLEN3: 'ZEGHABNA',
            IDN2: 188,
            CODEP: '5160',
          },
        ],
      },
      {
        ville: 186,
        villeName: 'HBIRA',
        cites: [
          {
            IDN3: 2467,
            LIBELLEN3: 'AGBA',
            IDN2: 186,
            CODEP: '5113',
          },
          {
            IDN3: 2466,
            LIBELLEN3: 'CHAHDA OUEST',
            IDN2: 186,
            CODEP: '5113',
          },
          {
            IDN3: 2464,
            LIBELLEN3: 'CHATT OULED NCIB',
            IDN2: 186,
            CODEP: '5113',
          },
          {
            IDN3: 2465,
            LIBELLEN3: 'EL HAJJARA',
            IDN2: 186,
            CODEP: '5113',
          },
          {
            IDN3: 2463,
            LIBELLEN3: 'HBIRA',
            IDN2: 186,
            CODEP: '5113',
          },
          {
            IDN3: 2461,
            LIBELLEN3: 'MEHARZA 18',
            IDN2: 186,
            CODEP: '5154',
          },
          {
            IDN3: 2460,
            LIBELLEN3: 'MENZEL HACHED',
            IDN2: 186,
            CODEP: '5122',
          },
          {
            IDN3: 2462,
            LIBELLEN3: 'REGAIGA',
            IDN2: 186,
            CODEP: '5113',
          },
        ],
      },
      {
        ville: 185,
        villeName: 'KSOUR ESSAF',
        cites: [
          {
            IDN3: 2457,
            LIBELLEN3: 'CITE COMMERCIALE',
            IDN2: 185,
            CODEP: '5180',
          },
          {
            IDN3: 2458,
            LIBELLEN3: 'CITE DHAMENE',
            IDN2: 185,
            CODEP: '5180',
          },
          {
            IDN3: 2443,
            LIBELLEN3: 'CITE DOUIRA',
            IDN2: 185,
            CODEP: '5126',
          },
          {
            IDN3: 2459,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 185,
            CODEP: '5180',
          },
          {
            IDN3: 2453,
            LIBELLEN3: 'CITE EL FATIMI',
            IDN2: 185,
            CODEP: '5180',
          },
          {
            IDN3: 2444,
            LIBELLEN3: 'CITE EL HAJEB',
            IDN2: 185,
            CODEP: '5126',
          },
          {
            IDN3: 2445,
            LIBELLEN3: 'CITE EL IMARAT',
            IDN2: 185,
            CODEP: '5126',
          },
          {
            IDN3: 2437,
            LIBELLEN3: 'CITE EL MALAJI',
            IDN2: 185,
            CODEP: '5180',
          },
          {
            IDN3: 2446,
            LIBELLEN3: 'CITE EL MENAGUA',
            IDN2: 185,
            CODEP: '5126',
          },
          {
            IDN3: 2439,
            LIBELLEN3: 'CITE EL WAFA',
            IDN2: 185,
            CODEP: '5180',
          },
          {
            IDN3: 2448,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 185,
            CODEP: '5180',
          },
          {
            IDN3: 2447,
            LIBELLEN3: 'CITE POPULAIRE',
            IDN2: 185,
            CODEP: '5126',
          },
          {
            IDN3: 2455,
            LIBELLEN3: 'CITE SALAH BEN ALI',
            IDN2: 185,
            CODEP: '5180',
          },
          {
            IDN3: 2438,
            LIBELLEN3: 'EL BRADAA',
            IDN2: 185,
            CODEP: '5115',
          },
          {
            IDN3: 2450,
            LIBELLEN3: 'EL GHEDHABNA',
            IDN2: 185,
            CODEP: '5136',
          },
          {
            IDN3: 2440,
            LIBELLEN3: 'EL HESSINETTE',
            IDN2: 185,
            CODEP: '5115',
          },
          {
            IDN3: 2451,
            LIBELLEN3: 'EL KHMARA',
            IDN2: 185,
            CODEP: '5136',
          },
          {
            IDN3: 2452,
            LIBELLEN3: 'KSOUR ESSAF',
            IDN2: 185,
            CODEP: '5180',
          },
          {
            IDN3: 2454,
            LIBELLEN3: 'KSOUR ESSAF HACHED',
            IDN2: 185,
            CODEP: '5189',
          },
          {
            IDN3: 2441,
            LIBELLEN3: 'OULED SALAH',
            IDN2: 185,
            CODEP: '5116',
          },
          {
            IDN3: 2456,
            LIBELLEN3: 'RECHERCHA',
            IDN2: 185,
            CODEP: '5146',
          },
          {
            IDN3: 2449,
            LIBELLEN3: 'SALAKTA',
            IDN2: 185,
            CODEP: '5126',
          },
          {
            IDN3: 2442,
            LIBELLEN3: 'SIDI ASSAKER',
            IDN2: 185,
            CODEP: '5116',
          },
        ],
      },
      {
        ville: 187,
        villeName: 'LA CHEBBA',
        cites: [
          {
            IDN3: 2468,
            LIBELLEN3: 'CITE DE LA PLAGE',
            IDN2: 187,
            CODEP: '5170',
          },
          {
            IDN3: 2469,
            LIBELLEN3: 'FERAHTA',
            IDN2: 187,
            CODEP: '5170',
          },
          {
            IDN3: 2470,
            LIBELLEN3: 'LA CHEBBA',
            IDN2: 187,
            CODEP: '5170',
          },
          {
            IDN3: 2471,
            LIBELLEN3: 'MERKEZ CHAABNA',
            IDN2: 187,
            CODEP: '5170',
          },
        ],
      },
      {
        ville: 180,
        villeName: 'MAHDIA',
        cites: [
          {
            IDN3: 2348,
            LIBELLEN3: 'CHIBA',
            IDN2: 180,
            CODEP: '5141',
          },
          {
            IDN3: 2360,
            LIBELLEN3: 'CITE AFH',
            IDN2: 180,
            CODEP: '5100',
          },
          {
            IDN3: 2361,
            LIBELLEN3: 'CITE BOURGUIBA',
            IDN2: 180,
            CODEP: '5100',
          },
          {
            IDN3: 2342,
            LIBELLEN3: 'CITE DES INFIRMIERS',
            IDN2: 180,
            CODEP: '5111',
          },
          {
            IDN3: 2356,
            LIBELLEN3: 'CITE EL MOEZ',
            IDN2: 180,
            CODEP: '5100',
          },
          {
            IDN3: 2343,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 180,
            CODEP: '5111',
          },
          {
            IDN3: 2338,
            LIBELLEN3: 'CITE ESSAADA',
            IDN2: 180,
            CODEP: '5199',
          },
          {
            IDN3: 2339,
            LIBELLEN3: 'CITE ESSALEM',
            IDN2: 180,
            CODEP: '5199',
          },
          {
            IDN3: 2357,
            LIBELLEN3: 'CITE ETTAAMIR',
            IDN2: 180,
            CODEP: '5100',
          },
          {
            IDN3: 2362,
            LIBELLEN3: 'CITE HACHED',
            IDN2: 180,
            CODEP: '5100',
          },
          {
            IDN3: 2347,
            LIBELLEN3: 'CITE NOUVELLE',
            IDN2: 180,
            CODEP: '5121',
          },
          {
            IDN3: 2349,
            LIBELLEN3: 'CITE POPULAIRE',
            IDN2: 180,
            CODEP: '5121',
          },
          {
            IDN3: 2344,
            LIBELLEN3: 'CITE SIDI MESSAOUD',
            IDN2: 180,
            CODEP: '5111',
          },
          {
            IDN3: 2358,
            LIBELLEN3: 'CITE TAHER SFAR',
            IDN2: 180,
            CODEP: '5100',
          },
          {
            IDN3: 2345,
            LIBELLEN3: 'EDDIKHILA 3',
            IDN2: 180,
            CODEP: '5111',
          },
          {
            IDN3: 2341,
            LIBELLEN3: 'EL HEKAIMA',
            IDN2: 180,
            CODEP: '5131',
          },
          {
            IDN3: 2351,
            LIBELLEN3: 'ESSAAD',
            IDN2: 180,
            CODEP: '5127',
          },
          {
            IDN3: 2353,
            LIBELLEN3: 'JOUAOUDA',
            IDN2: 180,
            CODEP: '5131',
          },
          {
            IDN3: 2359,
            LIBELLEN3: 'MAHDIA',
            IDN2: 180,
            CODEP: '5100',
          },
          {
            IDN3: 2352,
            LIBELLEN3: 'MAHDIA ESSOUK',
            IDN2: 180,
            CODEP: '5129',
          },
          {
            IDN3: 2340,
            LIBELLEN3: 'MAHDIA EZZAHRA',
            IDN2: 180,
            CODEP: '5199',
          },
          {
            IDN3: 2346,
            LIBELLEN3: 'MAHDIA HIBOUN',
            IDN2: 180,
            CODEP: '5111',
          },
          {
            IDN3: 2337,
            LIBELLEN3: 'MAHDIA REPUBLIQUE',
            IDN2: 180,
            CODEP: '5150',
          },
          {
            IDN3: 2354,
            LIBELLEN3: 'MERKEZ OULED DHAOUADI',
            IDN2: 180,
            CODEP: '5131',
          },
          {
            IDN3: 2350,
            LIBELLEN3: 'REJICHE',
            IDN2: 180,
            CODEP: '5121',
          },
          {
            IDN3: 2355,
            LIBELLEN3: 'ZOUAOUINE',
            IDN2: 180,
            CODEP: '5131',
          },
        ],
      },
      {
        ville: 178,
        villeName: 'MELLOULECH',
        cites: [
          {
            IDN3: 2313,
            LIBELLEN3: 'BENI TOURCH',
            IDN2: 178,
            CODEP: '5114',
          },
          {
            IDN3: 2317,
            LIBELLEN3: 'EL HOUD',
            IDN2: 178,
            CODEP: '5117',
          },
          {
            IDN3: 2318,
            LIBELLEN3: 'EL MANSOURA',
            IDN2: 178,
            CODEP: '5117',
          },
          {
            IDN3: 2314,
            LIBELLEN3: 'MELLOULECH',
            IDN2: 178,
            CODEP: '5114',
          },
          {
            IDN3: 2315,
            LIBELLEN3: 'OULED ABDALLAH',
            IDN2: 178,
            CODEP: '5114',
          },
          {
            IDN3: 2312,
            LIBELLEN3: 'OULED JABALLAH',
            IDN2: 178,
            CODEP: '5117',
          },
          {
            IDN3: 2316,
            LIBELLEN3: 'OULED MABROUK',
            IDN2: 178,
            CODEP: '5114',
          },
        ],
      },
      {
        ville: 182,
        villeName: 'OULED CHAMAKH',
        cites: [
          {
            IDN3: 2390,
            LIBELLEN3: 'BOU SLIM',
            IDN2: 182,
            CODEP: '5123',
          },
          {
            IDN3: 2397,
            LIBELLEN3: 'CHEHIMET',
            IDN2: 182,
            CODEP: '5123',
          },
          {
            IDN3: 2391,
            LIBELLEN3: 'EL AJILETTE',
            IDN2: 182,
            CODEP: '5123',
          },
          {
            IDN3: 2393,
            LIBELLEN3: 'EL MANAA',
            IDN2: 182,
            CODEP: '5123',
          },
          {
            IDN3: 2392,
            LIBELLEN3: 'EL MHARZA EST',
            IDN2: 182,
            CODEP: '5123',
          },
          {
            IDN3: 2398,
            LIBELLEN3: 'OULED AMOR',
            IDN2: 182,
            CODEP: '5123',
          },
          {
            IDN3: 2394,
            LIBELLEN3: 'OULED BOUZID',
            IDN2: 182,
            CODEP: '5123',
          },
          {
            IDN3: 2395,
            LIBELLEN3: 'OULED CHAMAKH',
            IDN2: 182,
            CODEP: '5123',
          },
          {
            IDN3: 2396,
            LIBELLEN3: 'SOMRA',
            IDN2: 182,
            CODEP: '5123',
          },
        ],
      },
      {
        ville: 179,
        villeName: 'SIDI ALOUENE',
        cites: [
          {
            IDN3: 2323,
            LIBELLEN3: 'BAAJLA',
            IDN2: 179,
            CODEP: '5190',
          },
          {
            IDN3: 2324,
            LIBELLEN3: 'CITE DAR EL HADAD',
            IDN2: 179,
            CODEP: '5190',
          },
          {
            IDN3: 2336,
            LIBELLEN3: 'EL BASSATINE',
            IDN2: 179,
            CODEP: '5132',
          },
          {
            IDN3: 2321,
            LIBELLEN3: 'EL BRAGHTIA',
            IDN2: 179,
            CODEP: '5151',
          },
          {
            IDN3: 2335,
            LIBELLEN3: 'EL HENICHETTE',
            IDN2: 179,
            CODEP: '5193',
          },
          {
            IDN3: 2330,
            LIBELLEN3: 'EL MEJRIA',
            IDN2: 179,
            CODEP: '5193',
          },
          {
            IDN3: 2326,
            LIBELLEN3: 'ENNOUZHA',
            IDN2: 179,
            CODEP: '5192',
          },
          {
            IDN3: 2334,
            LIBELLEN3: 'ESSAADA',
            IDN2: 179,
            CODEP: '5132',
          },
          {
            IDN3: 2331,
            LIBELLEN3: 'MERKEZ OULED HAJ KHELIFA',
            IDN2: 179,
            CODEP: '5132',
          },
          {
            IDN3: 2333,
            LIBELLEN3: 'OUED BEJA',
            IDN2: 179,
            CODEP: '5193',
          },
          {
            IDN3: 2329,
            LIBELLEN3: 'OUED GUELAT',
            IDN2: 179,
            CODEP: '5132',
          },
          {
            IDN3: 2327,
            LIBELLEN3: 'OULED AICHA',
            IDN2: 179,
            CODEP: '5192',
          },
          {
            IDN3: 2319,
            LIBELLEN3: 'SAKIET EL KHADEM',
            IDN2: 179,
            CODEP: '5132',
          },
          {
            IDN3: 2320,
            LIBELLEN3: 'SIDI ALI GHEDIR',
            IDN2: 179,
            CODEP: '5132',
          },
          {
            IDN3: 2325,
            LIBELLEN3: 'SIDI ALOUENE',
            IDN2: 179,
            CODEP: '5190',
          },
          {
            IDN3: 2328,
            LIBELLEN3: 'ZELBA',
            IDN2: 179,
            CODEP: '5192',
          },
          {
            IDN3: 2332,
            LIBELLEN3: 'ZELBA EL HENCHIR',
            IDN2: 179,
            CODEP: '5192',
          },
          {
            IDN3: 2322,
            LIBELLEN3: 'ZORDA',
            IDN2: 179,
            CODEP: '5151',
          },
        ],
      },
      {
        ville: 181,
        villeName: 'SOUASSI',
        cites: [
          {
            IDN3: 2385,
            LIBELLEN3: 'CHAARA',
            IDN2: 181,
            CODEP: '5134',
          },
          {
            IDN3: 2384,
            LIBELLEN3: 'CITE 9 AVRIL',
            IDN2: 181,
            CODEP: '5140',
          },
          {
            IDN3: 2387,
            LIBELLEN3: 'CITE AFH',
            IDN2: 181,
            CODEP: '5140',
          },
          {
            IDN3: 2363,
            LIBELLEN3: 'CITE COMMERCIALE',
            IDN2: 181,
            CODEP: '5140',
          },
          {
            IDN3: 2364,
            LIBELLEN3: 'CITE ETTAAMIR',
            IDN2: 181,
            CODEP: '5140',
          },
          {
            IDN3: 2365,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 181,
            CODEP: '5140',
          },
          {
            IDN3: 2366,
            LIBELLEN3: 'CITE IBN KHALDOUN',
            IDN2: 181,
            CODEP: '5140',
          },
          {
            IDN3: 2367,
            LIBELLEN3: 'CITE INDUSTRIELLE',
            IDN2: 181,
            CODEP: '5140',
          },
          {
            IDN3: 2368,
            LIBELLEN3: 'CITE POPULAIRE',
            IDN2: 181,
            CODEP: '5140',
          },
          {
            IDN3: 2378,
            LIBELLEN3: 'EL MANSOURA SOUASSI',
            IDN2: 181,
            CODEP: '5145',
          },
          {
            IDN3: 2386,
            LIBELLEN3: 'ESSETOUTE',
            IDN2: 181,
            CODEP: '5140',
          },
          {
            IDN3: 2369,
            LIBELLEN3: 'ETHOUABTIA',
            IDN2: 181,
            CODEP: '5140',
          },
          {
            IDN3: 2370,
            LIBELLEN3: 'EZZEIRATE',
            IDN2: 181,
            CODEP: '5140',
          },
          {
            IDN3: 2371,
            LIBELLEN3: 'GDARAT',
            IDN2: 181,
            CODEP: '5140',
          },
          {
            IDN3: 2377,
            LIBELLEN3: 'JEMAMLA',
            IDN2: 181,
            CODEP: '5144',
          },
          {
            IDN3: 2372,
            LIBELLEN3: 'JEMIAAT',
            IDN2: 181,
            CODEP: '5140',
          },
          {
            IDN3: 2382,
            LIBELLEN3: 'KSESBA',
            IDN2: 181,
            CODEP: '5155',
          },
          {
            IDN3: 2373,
            LIBELLEN3: 'OULED AMOR',
            IDN2: 181,
            CODEP: '5140',
          },
          {
            IDN3: 2374,
            LIBELLEN3: 'OULED BOU HELAL',
            IDN2: 181,
            CODEP: '5140',
          },
          {
            IDN3: 2375,
            LIBELLEN3: 'OULED KHELIFA',
            IDN2: 181,
            CODEP: '5140',
          },
          {
            IDN3: 2376,
            LIBELLEN3: 'OULED MOULAHOUM',
            IDN2: 181,
            CODEP: '5140',
          },
          {
            IDN3: 2381,
            LIBELLEN3: 'OULED MOULAHOUM SUD',
            IDN2: 181,
            CODEP: '5140',
          },
          {
            IDN3: 2380,
            LIBELLEN3: 'REJIBET',
            IDN2: 181,
            CODEP: '5140',
          },
          {
            IDN3: 2379,
            LIBELLEN3: 'SIDI BOU HELAL',
            IDN2: 181,
            CODEP: '5140',
          },
          {
            IDN3: 2388,
            LIBELLEN3: 'SIDI NACEUR NORD',
            IDN2: 181,
            CODEP: '5140',
          },
          {
            IDN3: 2383,
            LIBELLEN3: 'SIDI ZID',
            IDN2: 181,
            CODEP: '5134',
          },
          {
            IDN3: 2389,
            LIBELLEN3: 'SOUASSI',
            IDN2: 181,
            CODEP: '5140',
          },
        ],
      },
    ],
  },
  {
    govLabel: 'MANOUBA',
    govId: 13,
    villes: [
      {
        ville: 189,
        villeName: 'BORJ EL AMRI',
        cites: [
          {
            IDN3: 2486,
            LIBELLEN3: 'AOUILIA (KM 37)',
            IDN2: 189,
            CODEP: null,
          },
          {
            IDN3: 2491,
            LIBELLEN3: 'BIR ETTOUIL',
            IDN2: 189,
            CODEP: '1113',
          },
          {
            IDN3: 2494,
            LIBELLEN3: 'BORJ EL AMRI',
            IDN2: 189,
            CODEP: '1142',
          },
          {
            IDN3: 2492,
            LIBELLEN3: 'BORJ ENNOUR',
            IDN2: 189,
            CODEP: '1113',
          },
          {
            IDN3: 2495,
            LIBELLEN3: 'CITE EL HAFSIA',
            IDN2: 189,
            CODEP: '1142',
          },
          {
            IDN3: 2496,
            LIBELLEN3: 'CITE EL INTILAKA',
            IDN2: 189,
            CODEP: '1142',
          },
          {
            IDN3: 2497,
            LIBELLEN3: 'CITE EL MANAI',
            IDN2: 189,
            CODEP: '1142',
          },
          {
            IDN3: 2498,
            LIBELLEN3: 'CITE ENNOUZHA 1',
            IDN2: 189,
            CODEP: '1142',
          },
          {
            IDN3: 2490,
            LIBELLEN3: 'CITE ENNOUZHA 2',
            IDN2: 189,
            CODEP: '1142',
          },
          {
            IDN3: 2499,
            LIBELLEN3: 'CITE TAOUFIK',
            IDN2: 189,
            CODEP: '1142',
          },
          {
            IDN3: 2500,
            LIBELLEN3: 'DRIJETTE',
            IDN2: 189,
            CODEP: '1142',
          },
          {
            IDN3: 2493,
            LIBELLEN3: 'EL MESSAIDINE',
            IDN2: 189,
            CODEP: '1113',
          },
          {
            IDN3: 2501,
            LIBELLEN3: 'ENFAIEDH',
            IDN2: 189,
            CODEP: '1142',
          },
          {
            IDN3: 2489,
            LIBELLEN3: 'KSAR HADID',
            IDN2: 189,
            CODEP: '1142',
          },
          {
            IDN3: 2487,
            LIBELLEN3: 'MENZEL HABIB',
            IDN2: 189,
            CODEP: '1142',
          },
          {
            IDN3: 2488,
            LIBELLEN3: 'TEBOLTECH',
            IDN2: 189,
            CODEP: '1142',
          },
        ],
      },
      {
        ville: 196,
        villeName: 'DOUAR HICHER',
        cites: [
          {
            IDN3: 2625,
            LIBELLEN3: 'CITE KHALED IBN EL WALID',
            IDN2: 196,
            CODEP: '2086',
          },
          {
            IDN3: 2626,
            LIBELLEN3: 'DOUAR HICHER',
            IDN2: 196,
            CODEP: '2086',
          },
        ],
      },
      {
        ville: 193,
        villeName: 'EL BATTAN',
        cites: [
          {
            IDN3: 2610,
            LIBELLEN3: 'CITE 7 NOVEMBRE',
            IDN2: 193,
            CODEP: '1114',
          },
          {
            IDN3: 2609,
            LIBELLEN3: 'CITE BEN GHENIA',
            IDN2: 193,
            CODEP: '1114',
          },
          {
            IDN3: 2608,
            LIBELLEN3: 'CITE BRIK',
            IDN2: 193,
            CODEP: '1114',
          },
          {
            IDN3: 2598,
            LIBELLEN3: 'CITE EL HOUDA',
            IDN2: 193,
            CODEP: '1114',
          },
          {
            IDN3: 2599,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 193,
            CODEP: '1114',
          },
          {
            IDN3: 2600,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 193,
            CODEP: '1114',
          },
          {
            IDN3: 2601,
            LIBELLEN3: 'CITE ENNOUZHA',
            IDN2: 193,
            CODEP: '1114',
          },
          {
            IDN3: 2602,
            LIBELLEN3: 'EL BATTAN',
            IDN2: 193,
            CODEP: '1114',
          },
          {
            IDN3: 2603,
            LIBELLEN3: 'EL MANSOURA 2',
            IDN2: 193,
            CODEP: '1114',
          },
          {
            IDN3: 2604,
            LIBELLEN3: 'EL MEHRINE',
            IDN2: 193,
            CODEP: '1114',
          },
          {
            IDN3: 2605,
            LIBELLEN3: 'EZZOUITINA',
            IDN2: 193,
            CODEP: '1114',
          },
          {
            IDN3: 2606,
            LIBELLEN3: 'FERME N',
            IDN2: 193,
            CODEP: '1114',
          },
          {
            IDN3: 2607,
            LIBELLEN3: 'SAINT JOSEPH',
            IDN2: 193,
            CODEP: '1114',
          },
        ],
      },
      {
        ville: 190,
        villeName: 'JEDAIDA',
        cites: [
          {
            IDN3: 2532,
            LIBELLEN3: 'CHAOUAT',
            IDN2: 190,
            CODEP: '1134',
          },
          {
            IDN3: 2512,
            LIBELLEN3: 'CITE ABOUBAKER ESSEDDIK',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2513,
            LIBELLEN3: 'CITE BEL HAY',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2514,
            LIBELLEN3: 'CITE BEN HASSINE',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2515,
            LIBELLEN3: 'CITE DE LA RTT',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2516,
            LIBELLEN3: 'CITE DES ANDALOUS',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2517,
            LIBELLEN3: 'CITE DHAMENE',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2518,
            LIBELLEN3: 'CITE DHNIBA',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2533,
            LIBELLEN3: 'CITE ECOLE PRIMAIRE',
            IDN2: 190,
            CODEP: '1134',
          },
          {
            IDN3: 2519,
            LIBELLEN3: 'CITE EL BARAKA',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2520,
            LIBELLEN3: 'CITE EL HIDAYA',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2521,
            LIBELLEN3: 'CITE ENNAJET',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2522,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2523,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2524,
            LIBELLEN3: 'CITE ESSAROULA',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2525,
            LIBELLEN3: 'CITE GUICHBA',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2526,
            LIBELLEN3: 'CITE HABIB EL AYARI',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2527,
            LIBELLEN3: 'CITE HACHED',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2528,
            LIBELLEN3: 'CITE HAMDI',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2529,
            LIBELLEN3: 'CITE HAMMED NOUVELLE',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2530,
            LIBELLEN3: 'CITE JEBEL',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2502,
            LIBELLEN3: 'CITE MOKHTAR',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2505,
            LIBELLEN3: 'CITE NAJIBA',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2506,
            LIBELLEN3: 'CITE RURALE',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2507,
            LIBELLEN3: 'CITE SIDI SALEM',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2508,
            LIBELLEN3: 'CITE TAREK IBN ZIED',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2503,
            LIBELLEN3: 'CITE TOUHAMI NEFZI',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2504,
            LIBELLEN3: 'CITE TRABELSI',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2509,
            LIBELLEN3: 'CITE YOUGHARTA',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2534,
            LIBELLEN3: 'EL HABIBIA',
            IDN2: 190,
            CODEP: '2012',
          },
          {
            IDN3: 2510,
            LIBELLEN3: 'EL HENNA',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2511,
            LIBELLEN3: 'EL MANSOURA',
            IDN2: 190,
            CODEP: '2075',
          },
          {
            IDN3: 2535,
            LIBELLEN3: 'EZZAHRA',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2536,
            LIBELLEN3: 'HENCHIR DHENIBA',
            IDN2: 190,
            CODEP: '1124',
          },
          {
            IDN3: 2531,
            LIBELLEN3: 'JEDAIDA',
            IDN2: 190,
            CODEP: '1124',
          },
        ],
      },
      {
        ville: 194,
        villeName: 'MANNOUBA',
        cites: [
          {
            IDN3: 2611,
            LIBELLEN3: 'DENDEN',
            IDN2: 194,
            CODEP: '2011',
          },
          {
            IDN3: 2612,
            LIBELLEN3: 'LA MANNOUBA',
            IDN2: 194,
            CODEP: '2010',
          },
        ],
      },
      {
        ville: 195,
        villeName: 'MORNAGUIA',
        cites: [
          {
            IDN3: 2622,
            LIBELLEN3: 'BOU HANECH',
            IDN2: 195,
            CODEP: '2028',
          },
          {
            IDN3: 2613,
            LIBELLEN3: 'BOU REGBA',
            IDN2: 195,
            CODEP: '1110',
          },
          {
            IDN3: 2615,
            LIBELLEN3: 'CITE BIR BEN NJIMA',
            IDN2: 195,
            CODEP: '1110',
          },
          {
            IDN3: 2614,
            LIBELLEN3: 'CITE DE LA RTT 3',
            IDN2: 195,
            CODEP: '1110',
          },
          {
            IDN3: 2616,
            LIBELLEN3: 'CITE TOUMIA',
            IDN2: 195,
            CODEP: '1110',
          },
          {
            IDN3: 2623,
            LIBELLEN3: 'EL BASSATINE',
            IDN2: 195,
            CODEP: '2028',
          },
          {
            IDN3: 2621,
            LIBELLEN3: 'EL FEJJA',
            IDN2: 195,
            CODEP: '1153',
          },
          {
            IDN3: 2617,
            LIBELLEN3: 'EL HAMAYEM',
            IDN2: 195,
            CODEP: '1110',
          },
          {
            IDN3: 2618,
            LIBELLEN3: 'GHEDAOUNA',
            IDN2: 195,
            CODEP: '1110',
          },
          {
            IDN3: 2619,
            LIBELLEN3: 'MORNAGUIA',
            IDN2: 195,
            CODEP: '1110',
          },
          {
            IDN3: 2620,
            LIBELLEN3: 'MORNAGUIA 20 MARS',
            IDN2: 195,
            CODEP: '1116',
          },
          {
            IDN3: 2624,
            LIBELLEN3: 'SIDI ALI EL HATTAB',
            IDN2: 195,
            CODEP: '2071',
          },
        ],
      },
      {
        ville: 191,
        villeName: 'OUED ELLIL',
        cites: [
          {
            IDN3: 2538,
            LIBELLEN3: 'CITE 7 NOVEMBRE',
            IDN2: 191,
            CODEP: '2021',
          },
          {
            IDN3: 2539,
            LIBELLEN3: 'CITE AFH',
            IDN2: 191,
            CODEP: '2021',
          },
          {
            IDN3: 2540,
            LIBELLEN3: 'CITE BEJOUA 2',
            IDN2: 191,
            CODEP: '2021',
          },
          {
            IDN3: 2541,
            LIBELLEN3: 'CITE BEN ARFA',
            IDN2: 191,
            CODEP: '2021',
          },
          {
            IDN3: 2542,
            LIBELLEN3: 'CITE BEN JEMAA',
            IDN2: 191,
            CODEP: '2021',
          },
          {
            IDN3: 2543,
            LIBELLEN3: 'CITE BENNOUR BEJOUA 1',
            IDN2: 191,
            CODEP: '2021',
          },
          {
            IDN3: 2544,
            LIBELLEN3: 'CITE BOUZID',
            IDN2: 191,
            CODEP: '2021',
          },
          {
            IDN3: 2537,
            LIBELLEN3: 'CITE DHAMENE 2',
            IDN2: 191,
            CODEP: '2021',
          },
          {
            IDN3: 2557,
            LIBELLEN3: 'CITE EL BOUSTENE 1',
            IDN2: 191,
            CODEP: '2021',
          },
          {
            IDN3: 2545,
            LIBELLEN3: 'CITE EL BOUSTENE 2',
            IDN2: 191,
            CODEP: '2021',
          },
          {
            IDN3: 2546,
            LIBELLEN3: 'CITE EL IZDIHAR BEJOUA',
            IDN2: 191,
            CODEP: '2021',
          },
          {
            IDN3: 2547,
            LIBELLEN3: 'CITE EL MOEZZIA',
            IDN2: 191,
            CODEP: '2021',
          },
          {
            IDN3: 2548,
            LIBELLEN3: 'CITE EL MOULTAKA 1',
            IDN2: 191,
            CODEP: '2021',
          },
          {
            IDN3: 2553,
            LIBELLEN3: 'CITE ENNACIM',
            IDN2: 191,
            CODEP: '2031',
          },
          {
            IDN3: 2554,
            LIBELLEN3: 'CITE ERRIADH ZONE C',
            IDN2: 191,
            CODEP: '2031',
          },
          {
            IDN3: 2555,
            LIBELLEN3: 'CITE ERRIADH ZONE F',
            IDN2: 191,
            CODEP: '2031',
          },
          {
            IDN3: 2549,
            LIBELLEN3: 'CITE ETTOURJMENE',
            IDN2: 191,
            CODEP: '2021',
          },
          {
            IDN3: 2550,
            LIBELLEN3: 'CITE MARSEILLE',
            IDN2: 191,
            CODEP: '2021',
          },
          {
            IDN3: 2551,
            LIBELLEN3: 'CITE MILITAIRE',
            IDN2: 191,
            CODEP: '2021',
          },
          {
            IDN3: 2556,
            LIBELLEN3: 'ESSAIDA',
            IDN2: 191,
            CODEP: '2031',
          },
          {
            IDN3: 2552,
            LIBELLEN3: 'OUED ELLIL',
            IDN2: 191,
            CODEP: '2021',
          },
        ],
      },
      {
        ville: 192,
        villeName: 'TEBOURBA',
        cites: [
          {
            IDN3: 2580,
            LIBELLEN3: 'AIN EL KARMA',
            IDN2: 192,
            CODEP: '1144',
          },
          {
            IDN3: 2581,
            LIBELLEN3: 'AIN ZAMMIT',
            IDN2: 192,
            CODEP: '1144',
          },
          {
            IDN3: 2592,
            LIBELLEN3: 'ARGOUB ERROUMI',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2558,
            LIBELLEN3: 'BIR EZZITOUN',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2577,
            LIBELLEN3: 'BORJ ETTOUMI',
            IDN2: 192,
            CODEP: '1143',
          },
          {
            IDN3: 2576,
            LIBELLEN3: 'CHOUIGUI',
            IDN2: 192,
            CODEP: '1133',
          },
          {
            IDN3: 2593,
            LIBELLEN3: 'CITE 7 NOVEMBRE',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2594,
            LIBELLEN3: 'CITE CHEBBI',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2597,
            LIBELLEN3: 'CITE DE FRANCE',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2590,
            LIBELLEN3: 'CITE DE LA PALESTINE',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2591,
            LIBELLEN3: 'CITE EL ARGOUB',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2559,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2574,
            LIBELLEN3: 'CITE EL KEF LAHMAR',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2596,
            LIBELLEN3: 'CITE EL KHARROUBA',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2595,
            LIBELLEN3: 'CITE EL MALJA',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2562,
            LIBELLEN3: 'CITE EL MALJA HAMRA',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2563,
            LIBELLEN3: 'CITE EL MANDRA',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2564,
            LIBELLEN3: 'CITE EL MELLASSINE',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2565,
            LIBELLEN3: 'CITE ENNAJAH',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2566,
            LIBELLEN3: 'CITE ERRAJA',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2567,
            LIBELLEN3: 'CITE ERRIMEL',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2568,
            LIBELLEN3: 'CITE ESSAADA',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2569,
            LIBELLEN3: 'CITE ESSAADA 2',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2570,
            LIBELLEN3: 'CITE KHELIF JALLED',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2571,
            LIBELLEN3: 'CITE LAROUSSIA',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2572,
            LIBELLEN3: 'CITE POPULAIRE',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2573,
            LIBELLEN3: 'CITE ROUTE CHOUIGUI',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2589,
            LIBELLEN3: 'CITE SOUYAH',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2582,
            LIBELLEN3: 'EDDIKHILA',
            IDN2: 192,
            CODEP: '1144',
          },
          {
            IDN3: 2583,
            LIBELLEN3: 'EL ANSARINE',
            IDN2: 192,
            CODEP: '1144',
          },
          {
            IDN3: 2560,
            LIBELLEN3: 'EL AROUSSIA',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2584,
            LIBELLEN3: 'EL HAOUARIA',
            IDN2: 192,
            CODEP: '1144',
          },
          {
            IDN3: 2578,
            LIBELLEN3: 'EL MAHFOURA',
            IDN2: 192,
            CODEP: '1143',
          },
          {
            IDN3: 2585,
            LIBELLEN3: 'EL MELLAHA',
            IDN2: 192,
            CODEP: '1144',
          },
          {
            IDN3: 2586,
            LIBELLEN3: 'GOSSET EL BEY',
            IDN2: 192,
            CODEP: '1144',
          },
          {
            IDN3: 2561,
            LIBELLEN3: 'GOUMRIANE',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2587,
            LIBELLEN3: 'GUEFFAYA',
            IDN2: 192,
            CODEP: '1144',
          },
          {
            IDN3: 2588,
            LIBELLEN3: 'SIDI ABDELBASSET',
            IDN2: 192,
            CODEP: '1144',
          },
          {
            IDN3: 2575,
            LIBELLEN3: 'TEBOURBA',
            IDN2: 192,
            CODEP: '1130',
          },
          {
            IDN3: 2579,
            LIBELLEN3: 'TOUNGAR',
            IDN2: 192,
            CODEP: '1143',
          },
        ],
      },
    ],
  },
  {
    govLabel: 'MEDENINE',
    govId: 14,
    villes: [
      {
        ville: 205,
        villeName: 'AJIM',
        cites: [
          {
            IDN3: 2797,
            LIBELLEN3: 'AJIM',
            IDN2: 205,
            CODEP: '4135',
          },
          {
            IDN3: 2809,
            LIBELLEN3: 'BOU SMAIL',
            IDN2: 205,
            CODEP: '4135',
          },
          {
            IDN3: 2810,
            LIBELLEN3: 'CHAOUCH',
            IDN2: 205,
            CODEP: '4135',
          },
          {
            IDN3: 2811,
            LIBELLEN3: 'CITE POPULAIRE',
            IDN2: 205,
            CODEP: '4135',
          },
          {
            IDN3: 2812,
            LIBELLEN3: 'EL GROO',
            IDN2: 205,
            CODEP: '4125',
          },
          {
            IDN3: 2804,
            LIBELLEN3: 'EL GUEBLINE',
            IDN2: 205,
            CODEP: '4155',
          },
          {
            IDN3: 2814,
            LIBELLEN3: 'GHANDRI',
            IDN2: 205,
            CODEP: '4135',
          },
          {
            IDN3: 2805,
            LIBELLEN3: 'GUELLALA',
            IDN2: 205,
            CODEP: '4155',
          },
          {
            IDN3: 2798,
            LIBELLEN3: 'HOUMET BEN HARZALLH',
            IDN2: 205,
            CODEP: '4135',
          },
          {
            IDN3: 2799,
            LIBELLEN3: 'HOUMET BOU HASTINE',
            IDN2: 205,
            CODEP: '4135',
          },
          {
            IDN3: 2806,
            LIBELLEN3: 'HOUMET EL FAHMINE',
            IDN2: 205,
            CODEP: '4155',
          },
          {
            IDN3: 2800,
            LIBELLEN3: 'HOUMET ISSA',
            IDN2: 205,
            CODEP: '4135',
          },
          {
            IDN3: 2813,
            LIBELLEN3: 'JABIRA',
            IDN2: 205,
            CODEP: '4125',
          },
          {
            IDN3: 2801,
            LIBELLEN3: 'KHENENSA',
            IDN2: 205,
            CODEP: '4135',
          },
          {
            IDN3: 2803,
            LIBELLEN3: 'METHANIA',
            IDN2: 205,
            CODEP: '4150',
          },
          {
            IDN3: 2802,
            LIBELLEN3: 'MEZRANE',
            IDN2: 205,
            CODEP: '4135',
          },
          {
            IDN3: 2808,
            LIBELLEN3: 'OUED ZBIB',
            IDN2: 205,
            CODEP: '4166',
          },
          {
            IDN3: 2807,
            LIBELLEN3: 'TLET',
            IDN2: 205,
            CODEP: '4155',
          },
        ],
      },
      {
        ville: 204,
        villeName: 'BEN GUERDANE',
        cites: [
          {
            IDN3: 2784,
            LIBELLEN3: 'BEN GUERDANE',
            IDN2: 204,
            CODEP: '4160',
          },
          {
            IDN3: 2785,
            LIBELLEN3: 'BOU HAMED',
            IDN2: 204,
            CODEP: '4160',
          },
          {
            IDN3: 2786,
            LIBELLEN3: 'CHAREB ERRAJEL',
            IDN2: 204,
            CODEP: '4160',
          },
          {
            IDN3: 2796,
            LIBELLEN3: 'CHEHBANIA',
            IDN2: 204,
            CODEP: '4163',
          },
          {
            IDN3: 2787,
            LIBELLEN3: 'CITE EL BAATH',
            IDN2: 204,
            CODEP: '4160',
          },
          {
            IDN3: 2788,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 204,
            CODEP: '4160',
          },
          {
            IDN3: 2789,
            LIBELLEN3: 'CITE ESSOUROUR',
            IDN2: 204,
            CODEP: '4160',
          },
          {
            IDN3: 2790,
            LIBELLEN3: 'CITE POPULAIRE',
            IDN2: 204,
            CODEP: '4160',
          },
          {
            IDN3: 2791,
            LIBELLEN3: 'EL MAAMRATE',
            IDN2: 204,
            CODEP: '4160',
          },
          {
            IDN3: 2780,
            LIBELLEN3: 'JALEL',
            IDN2: 204,
            CODEP: '4192',
          },
          {
            IDN3: 2781,
            LIBELLEN3: 'JEMILA',
            IDN2: 204,
            CODEP: '4193',
          },
          {
            IDN3: 2782,
            LIBELLEN3: 'NEFFATIA',
            IDN2: 204,
            CODEP: '4160',
          },
          {
            IDN3: 2793,
            LIBELLEN3: 'OUED ERRABAYA',
            IDN2: 204,
            CODEP: '4160',
          },
          {
            IDN3: 2792,
            LIBELLEN3: 'OUERSNIA',
            IDN2: 204,
            CODEP: '4183',
          },
          {
            IDN3: 2783,
            LIBELLEN3: 'RAS JEDIR',
            IDN2: 204,
            CODEP: '4153',
          },
          {
            IDN3: 2794,
            LIBELLEN3: 'SAYAH',
            IDN2: 204,
            CODEP: '4160',
          },
          {
            IDN3: 2795,
            LIBELLEN3: 'TABAI',
            IDN2: 204,
            CODEP: '4160',
          },
        ],
      },
      {
        ville: 199,
        villeName: 'BENI KHEDACHE',
        cites: [
          {
            IDN3: 2679,
            LIBELLEN3: 'BATTOUMA',
            IDN2: 199,
            CODEP: '4151',
          },
          {
            IDN3: 2686,
            LIBELLEN3: 'BENI KHEDACHE',
            IDN2: 199,
            CODEP: '4110',
          },
          {
            IDN3: 2677,
            LIBELLEN3: 'CHOUAMEKH',
            IDN2: 199,
            CODEP: '4159',
          },
          {
            IDN3: 2687,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 199,
            CODEP: '4110',
          },
          {
            IDN3: 2688,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 199,
            CODEP: '4110',
          },
          {
            IDN3: 2690,
            LIBELLEN3: 'CITE ETTAHRIR',
            IDN2: 199,
            CODEP: '4110',
          },
          {
            IDN3: 2691,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 199,
            CODEP: '4110',
          },
          {
            IDN3: 2692,
            LIBELLEN3: 'CITE IBN KHALDOUN',
            IDN2: 199,
            CODEP: '4110',
          },
          {
            IDN3: 2693,
            LIBELLEN3: 'CITE SIDI MANSOUR',
            IDN2: 199,
            CODEP: '4110',
          },
          {
            IDN3: 2680,
            LIBELLEN3: 'EDDIKHILA',
            IDN2: 199,
            CODEP: '4151',
          },
          {
            IDN3: 2681,
            LIBELLEN3: 'EL BEHIRA',
            IDN2: 199,
            CODEP: '4151',
          },
          {
            IDN3: 2682,
            LIBELLEN3: 'FOUM ENNAGUEB',
            IDN2: 199,
            CODEP: '4151',
          },
          {
            IDN3: 2678,
            LIBELLEN3: 'HALG JEMAL',
            IDN2: 199,
            CODEP: '4132',
          },
          {
            IDN3: 2694,
            LIBELLEN3: 'KSAR EL HALLOUF',
            IDN2: 199,
            CODEP: '4110',
          },
          {
            IDN3: 2697,
            LIBELLEN3: 'KSAR EL JIRA',
            IDN2: 199,
            CODEP: '4122',
          },
          {
            IDN3: 2685,
            LIBELLEN3: 'KSAR EL KHERACHFA',
            IDN2: 199,
            CODEP: '4110',
          },
          {
            IDN3: 2683,
            LIBELLEN3: 'KSAR JEDID',
            IDN2: 199,
            CODEP: '4151',
          },
          {
            IDN3: 2689,
            LIBELLEN3: 'KSAR JOUAMAA',
            IDN2: 199,
            CODEP: '4110',
          },
          {
            IDN3: 2684,
            LIBELLEN3: 'KSAR KRIKRIA',
            IDN2: 199,
            CODEP: '4151',
          },
          {
            IDN3: 2696,
            LIBELLEN3: 'OUERJIJENE',
            IDN2: 199,
            CODEP: '4112',
          },
          {
            IDN3: 2695,
            LIBELLEN3: 'ZAMMOUR',
            IDN2: 199,
            CODEP: '4110',
          },
        ],
      },
      {
        ville: 197,
        villeName: 'HOUMET ESSOUK',
        cites: [
          {
            IDN3: 2652,
            LIBELLEN3: 'BAZIM',
            IDN2: 197,
            CODEP: '4180',
          },
          {
            IDN3: 2653,
            LIBELLEN3: 'CITE BEN ISSA',
            IDN2: 197,
            CODEP: '4180',
          },
          {
            IDN3: 2654,
            LIBELLEN3: 'CITE BOU OKKAZINE',
            IDN2: 197,
            CODEP: '4180',
          },
          {
            IDN3: 2646,
            LIBELLEN3: 'CITE DE LA DOUANE',
            IDN2: 197,
            CODEP: '4185',
          },
          {
            IDN3: 2627,
            LIBELLEN3: 'CITE DE LA POLICE',
            IDN2: 197,
            CODEP: '4180',
          },
          {
            IDN3: 2629,
            LIBELLEN3: 'CITE DES ENSEIGNANTS',
            IDN2: 197,
            CODEP: '4180',
          },
          {
            IDN3: 2630,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 197,
            CODEP: '4180',
          },
          {
            IDN3: 2631,
            LIBELLEN3: 'CITE JOUAMAA',
            IDN2: 197,
            CODEP: '4180',
          },
          {
            IDN3: 2632,
            LIBELLEN3: 'CITE OUVRIERE 1',
            IDN2: 197,
            CODEP: '4180',
          },
          {
            IDN3: 2633,
            LIBELLEN3: 'CITE OUVRIERE 2',
            IDN2: 197,
            CODEP: '4180',
          },
          {
            IDN3: 2634,
            LIBELLEN3: 'CITE POPULAIRE',
            IDN2: 197,
            CODEP: '4180',
          },
          {
            IDN3: 2636,
            LIBELLEN3: 'CITE SIDI EL BAHRI',
            IDN2: 197,
            CODEP: '4180',
          },
          {
            IDN3: 2647,
            LIBELLEN3: 'CITE SIDI ZAIED',
            IDN2: 197,
            CODEP: '4185',
          },
          {
            IDN3: 2641,
            LIBELLEN3: 'CITE SNIT',
            IDN2: 197,
            CODEP: '4146',
          },
          {
            IDN3: 2642,
            LIBELLEN3: 'ERRIADH',
            IDN2: 197,
            CODEP: '4146',
          },
          {
            IDN3: 2638,
            LIBELLEN3: 'FATOU',
            IDN2: 197,
            CODEP: '4180',
          },
          {
            IDN3: 2650,
            LIBELLEN3: 'GHIZEN',
            IDN2: 197,
            CODEP: '4156',
          },
          {
            IDN3: 2656,
            LIBELLEN3: 'GUECHIINE',
            IDN2: 197,
            CODEP: '4195',
          },
          {
            IDN3: 2640,
            LIBELLEN3: 'HACHENE',
            IDN2: 197,
            CODEP: '4180',
          },
          {
            IDN3: 2643,
            LIBELLEN3: 'HOUMET LARBEH',
            IDN2: 197,
            CODEP: '4146',
          },
          {
            IDN3: 2644,
            LIBELLEN3: 'JERBA',
            IDN2: 197,
            CODEP: '4180',
          },
          {
            IDN3: 2635,
            LIBELLEN3: 'JERBA AEROPORT',
            IDN2: 197,
            CODEP: '4120',
          },
          {
            IDN3: 2628,
            LIBELLEN3: 'MELLITA JERBA',
            IDN2: 197,
            CODEP: '4115',
          },
          {
            IDN3: 2649,
            LIBELLEN3: 'MEZRAYA',
            IDN2: 197,
            CODEP: '4186',
          },
          {
            IDN3: 2637,
            LIBELLEN3: 'OUALEGH',
            IDN2: 197,
            CODEP: '4123',
          },
          {
            IDN3: 2645,
            LIBELLEN3: 'SARANDI',
            IDN2: 197,
            CODEP: '4182',
          },
          {
            IDN3: 2639,
            LIBELLEN3: 'SEDGHIANE',
            IDN2: 197,
            CODEP: '4136',
          },
          {
            IDN3: 2651,
            LIBELLEN3: 'SIDI MEHREZ',
            IDN2: 197,
            CODEP: '4179',
          },
          {
            IDN3: 2655,
            LIBELLEN3: 'SIDI ZAIED',
            IDN2: 197,
            CODEP: '4190',
          },
          {
            IDN3: 2648,
            LIBELLEN3: 'SOUANI',
            IDN2: 197,
            CODEP: '4185',
          },
        ],
      },
      {
        ville: 203,
        villeName: 'MEDENINE NORD',
        cites: [
          {
            IDN3: 2775,
            LIBELLEN3: 'CITE EL KHRACHFA',
            IDN2: 203,
            CODEP: '4100',
          },
          {
            IDN3: 2777,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 203,
            CODEP: '4100',
          },
          {
            IDN3: 2769,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 203,
            CODEP: '4100',
          },
          {
            IDN3: 2770,
            LIBELLEN3: 'CITE HADDADA',
            IDN2: 203,
            CODEP: '4100',
          },
          {
            IDN3: 2771,
            LIBELLEN3: 'CITE JOUAMAA',
            IDN2: 203,
            CODEP: '4100',
          },
          {
            IDN3: 2772,
            LIBELLEN3: 'CITE NOUVELLE',
            IDN2: 203,
            CODEP: '4100',
          },
          {
            IDN3: 2773,
            LIBELLEN3: 'CITE SIDI EZZITOUNI',
            IDN2: 203,
            CODEP: '4100',
          },
          {
            IDN3: 2776,
            LIBELLEN3: 'KOUTINE',
            IDN2: 203,
            CODEP: '4121',
          },
          {
            IDN3: 2778,
            LIBELLEN3: 'MEDENINE EL JEDIDA',
            IDN2: 203,
            CODEP: '4130',
          },
          {
            IDN3: 2779,
            LIBELLEN3: 'MEDENINE EL MAARIFA',
            IDN2: 203,
            CODEP: '4142',
          },
          {
            IDN3: 2774,
            LIBELLEN3: 'OUM ETTAMAR',
            IDN2: 203,
            CODEP: '4111',
          },
        ],
      },
      {
        ville: 198,
        villeName: 'MEDENINE SUD',
        cites: [
          {
            IDN3: 2671,
            LIBELLEN3: 'AMRA NOUVELLE',
            IDN2: 198,
            CODEP: '4100',
          },
          {
            IDN3: 2672,
            LIBELLEN3: 'BENI GHEZEYEL',
            IDN2: 198,
            CODEP: '4100',
          },
          {
            IDN3: 2665,
            LIBELLEN3: 'BOU GHRARA',
            IDN2: 198,
            CODEP: '4141',
          },
          {
            IDN3: 2674,
            LIBELLEN3: 'CITE CHERAIHA',
            IDN2: 198,
            CODEP: '4100',
          },
          {
            IDN3: 2675,
            LIBELLEN3: 'CITE DES ABRICOTS',
            IDN2: 198,
            CODEP: '4100',
          },
          {
            IDN3: 2676,
            LIBELLEN3: 'CITE EL AHRAS',
            IDN2: 198,
            CODEP: '4100',
          },
          {
            IDN3: 2667,
            LIBELLEN3: 'CITE ENNACIM',
            IDN2: 198,
            CODEP: '4100',
          },
          {
            IDN3: 2668,
            LIBELLEN3: 'CITE ERRAJA',
            IDN2: 198,
            CODEP: '4100',
          },
          {
            IDN3: 2669,
            LIBELLEN3: 'CITE GAMMOUDI CHEREF',
            IDN2: 198,
            CODEP: '4100',
          },
          {
            IDN3: 2661,
            LIBELLEN3: 'CITE TOUTA',
            IDN2: 198,
            CODEP: '4100',
          },
          {
            IDN3: 2670,
            LIBELLEN3: 'EL AMRA',
            IDN2: 198,
            CODEP: '4100',
          },
          {
            IDN3: 2666,
            LIBELLEN3: 'EL MAGHRAOUIA',
            IDN2: 198,
            CODEP: '4143',
          },
          {
            IDN3: 2673,
            LIBELLEN3: 'ESSAADANE',
            IDN2: 198,
            CODEP: '4100',
          },
          {
            IDN3: 2664,
            LIBELLEN3: 'HASSI AMOR',
            IDN2: 198,
            CODEP: '4131',
          },
          {
            IDN3: 2658,
            LIBELLEN3: 'MEDENINE',
            IDN2: 198,
            CODEP: '4100',
          },
          {
            IDN3: 2663,
            LIBELLEN3: 'MEDENINE PERSEVERANCE',
            IDN2: 198,
            CODEP: '4127',
          },
          {
            IDN3: 2659,
            LIBELLEN3: 'OUED ESSEDER',
            IDN2: 198,
            CODEP: '4100',
          },
          {
            IDN3: 2660,
            LIBELLEN3: 'SOUITIR',
            IDN2: 198,
            CODEP: '4100',
          },
          {
            IDN3: 2657,
            LIBELLEN3: 'TAJERJIMET',
            IDN2: 198,
            CODEP: '4100',
          },
          {
            IDN3: 2662,
            LIBELLEN3: 'TOUICHA',
            IDN2: 198,
            CODEP: '4100',
          },
        ],
      },
      {
        ville: 201,
        villeName: 'MIDOUN',
        cites: [
          {
            IDN3: 2728,
            LIBELLEN3: 'AGHIR',
            IDN2: 201,
            CODEP: '4116',
          },
          {
            IDN3: 2725,
            LIBELLEN3: 'ARKOU',
            IDN2: 201,
            CODEP: '4176',
          },
          {
            IDN3: 2715,
            LIBELLEN3: 'BENI MAAGUEL',
            IDN2: 201,
            CODEP: '4126',
          },
          {
            IDN3: 2717,
            LIBELLEN3: 'CEDOUIKECHE',
            IDN2: 201,
            CODEP: '4145',
          },
          {
            IDN3: 2727,
            LIBELLEN3: 'DAR JERBA',
            IDN2: 201,
            CODEP: '4199',
          },
          {
            IDN3: 2729,
            LIBELLEN3: 'EL HADADDA',
            IDN2: 201,
            CODEP: '4116',
          },
          {
            IDN3: 2718,
            LIBELLEN3: 'EL KRACHOUA',
            IDN2: 201,
            CODEP: '4145',
          },
          {
            IDN3: 2723,
            LIBELLEN3: 'EL MAY',
            IDN2: 201,
            CODEP: '4175',
          },
          {
            IDN3: 2724,
            LIBELLEN3: 'HOUMET HAZEM',
            IDN2: 201,
            CODEP: '4175',
          },
          {
            IDN3: 2709,
            LIBELLEN3: 'JAMAA EL GAIED',
            IDN2: 201,
            CODEP: '4116',
          },
          {
            IDN3: 2710,
            LIBELLEN3: 'KHAZROUN',
            IDN2: 201,
            CODEP: '4116',
          },
          {
            IDN3: 2722,
            LIBELLEN3: 'MAHBOUBINE',
            IDN2: 201,
            CODEP: '4165',
          },
          {
            IDN3: 2711,
            LIBELLEN3: 'MIDOUN',
            IDN2: 201,
            CODEP: '4116',
          },
          {
            IDN3: 2719,
            LIBELLEN3: 'OUERSIGHEN',
            IDN2: 201,
            CODEP: '4145',
          },
          {
            IDN3: 2726,
            LIBELLEN3: 'OULED AMOR',
            IDN2: 201,
            CODEP: '4113',
          },
          {
            IDN3: 2716,
            LIBELLEN3: 'ROBBANA',
            IDN2: 201,
            CODEP: '4133',
          },
          {
            IDN3: 2712,
            LIBELLEN3: 'SAYAGH',
            IDN2: 201,
            CODEP: '4116',
          },
          {
            IDN3: 2713,
            LIBELLEN3: 'SIDI YATI',
            IDN2: 201,
            CODEP: '4116',
          },
          {
            IDN3: 2720,
            LIBELLEN3: 'TAFARTAST',
            IDN2: 201,
            CODEP: '4145',
          },
          {
            IDN3: 2721,
            LIBELLEN3: 'TOMOGRET',
            IDN2: 201,
            CODEP: '4145',
          },
          {
            IDN3: 2714,
            LIBELLEN3: 'ZONE HOTELIERE',
            IDN2: 201,
            CODEP: '4116',
          },
        ],
      },
      {
        ville: 200,
        villeName: 'SIDI MAKHLOUF',
        cites: [
          {
            IDN3: 2704,
            LIBELLEN3: 'BEDOUI',
            IDN2: 200,
            CODEP: '4191',
          },
          {
            IDN3: 2705,
            LIBELLEN3: 'CITE 9 AVRIL',
            IDN2: 200,
            CODEP: '4191',
          },
          {
            IDN3: 2700,
            LIBELLEN3: 'EL BAYAZ',
            IDN2: 200,
            CODEP: '4181',
          },
          {
            IDN3: 2701,
            LIBELLEN3: 'EL GHABBAY',
            IDN2: 200,
            CODEP: '4181',
          },
          {
            IDN3: 2706,
            LIBELLEN3: 'EL GOSBA',
            IDN2: 200,
            CODEP: '4191',
          },
          {
            IDN3: 2707,
            LIBELLEN3: 'EL GRINE',
            IDN2: 200,
            CODEP: '4191',
          },
          {
            IDN3: 2698,
            LIBELLEN3: 'EL MORRA',
            IDN2: 200,
            CODEP: '4191',
          },
          {
            IDN3: 2702,
            LIBELLEN3: 'ERRAGOUBA',
            IDN2: 200,
            CODEP: '4181',
          },
          {
            IDN3: 2703,
            LIBELLEN3: 'ERRAGOUBA EL GHARBIA',
            IDN2: 200,
            CODEP: '4181',
          },
          {
            IDN3: 2699,
            LIBELLEN3: 'JORF',
            IDN2: 200,
            CODEP: '4117',
          },
          {
            IDN3: 2708,
            LIBELLEN3: 'SIDI MAKHLOUF',
            IDN2: 200,
            CODEP: '4191',
          },
        ],
      },
      {
        ville: 202,
        villeName: 'ZARZIS',
        cites: [
          {
            IDN3: 2750,
            LIBELLEN3: 'BENI FETAIEL',
            IDN2: 202,
            CODEP: '4170',
          },
          {
            IDN3: 2752,
            LIBELLEN3: 'BOU JLIDA',
            IDN2: 202,
            CODEP: '4170',
          },
          {
            IDN3: 2753,
            LIBELLEN3: 'BOU TEFFAHA',
            IDN2: 202,
            CODEP: '4170',
          },
          {
            IDN3: 2736,
            LIBELLEN3: 'CHAMMAKH',
            IDN2: 202,
            CODEP: '4134',
          },
          {
            IDN3: 2738,
            LIBELLEN3: 'CITE BENI FETAIEL 1',
            IDN2: 202,
            CODEP: '4173',
          },
          {
            IDN3: 2740,
            LIBELLEN3: 'CITE BENI FETAIEL 2',
            IDN2: 202,
            CODEP: '4173',
          },
          {
            IDN3: 2754,
            LIBELLEN3: 'CITE CHOKRBANE',
            IDN2: 202,
            CODEP: '4170',
          },
          {
            IDN3: 2755,
            LIBELLEN3: 'CITE DARB EL BAB',
            IDN2: 202,
            CODEP: '4170',
          },
          {
            IDN3: 2737,
            LIBELLEN3: 'CITE EL KRIBA',
            IDN2: 202,
            CODEP: '4134',
          },
          {
            IDN3: 2741,
            LIBELLEN3: 'CITE EL MANSOURA',
            IDN2: 202,
            CODEP: '4173',
          },
          {
            IDN3: 2757,
            LIBELLEN3: 'CITE KSAR OULED MHAMED',
            IDN2: 202,
            CODEP: '4170',
          },
          {
            IDN3: 2759,
            LIBELLEN3: 'CITE KSAR OULED SAID',
            IDN2: 202,
            CODEP: '4170',
          },
          {
            IDN3: 2743,
            LIBELLEN3: 'CITE POPULAIRE',
            IDN2: 202,
            CODEP: '4134',
          },
          {
            IDN3: 2742,
            LIBELLEN3: 'CITE SANGHO',
            IDN2: 202,
            CODEP: '4173',
          },
          {
            IDN3: 2760,
            LIBELLEN3: 'CITE SIDI EL KEBIR',
            IDN2: 202,
            CODEP: '4170',
          },
          {
            IDN3: 2732,
            LIBELLEN3: 'CITE SIDI SAAD',
            IDN2: 202,
            CODEP: '4173',
          },
          {
            IDN3: 2744,
            LIBELLEN3: 'CITE ZRIG',
            IDN2: 202,
            CODEP: '4134',
          },
          {
            IDN3: 2748,
            LIBELLEN3: 'EL GHRABATE',
            IDN2: 202,
            CODEP: '4154',
          },
          {
            IDN3: 2730,
            LIBELLEN3: 'EL HICHEM',
            IDN2: 202,
            CODEP: '4124',
          },
          {
            IDN3: 2745,
            LIBELLEN3: 'EL KHRIBA',
            IDN2: 202,
            CODEP: '4134',
          },
          {
            IDN3: 2761,
            LIBELLEN3: 'EL MARATHIA',
            IDN2: 202,
            CODEP: '4170',
          },
          {
            IDN3: 2747,
            LIBELLEN3: 'EL MOUENSA',
            IDN2: 202,
            CODEP: '4144',
          },
          {
            IDN3: 2767,
            LIBELLEN3: 'ERRAJA',
            IDN2: 202,
            CODEP: '4114',
          },
          {
            IDN3: 2749,
            LIBELLEN3: 'GRIBIS',
            IDN2: 202,
            CODEP: '4164',
          },
          {
            IDN3: 2731,
            LIBELLEN3: 'HAMADI EL GUEBLI',
            IDN2: 202,
            CODEP: '4194',
          },
          {
            IDN3: 2758,
            LIBELLEN3: 'HASSI JERBI',
            IDN2: 202,
            CODEP: '4174',
          },
          {
            IDN3: 2751,
            LIBELLEN3: 'JEDARIA',
            IDN2: 202,
            CODEP: '4172',
          },
          {
            IDN3: 2762,
            LIBELLEN3: 'KAOUI EL KADIR',
            IDN2: 202,
            CODEP: '4170',
          },
          {
            IDN3: 2763,
            LIBELLEN3: 'KHAOUI EL GHEDIR',
            IDN2: 202,
            CODEP: '4170',
          },
          {
            IDN3: 2764,
            LIBELLEN3: 'KSAR EZZAOUIA',
            IDN2: 202,
            CODEP: '4170',
          },
          {
            IDN3: 2733,
            LIBELLEN3: 'LELLA MARIEM',
            IDN2: 202,
            CODEP: '4173',
          },
          {
            IDN3: 2765,
            LIBELLEN3: 'OUGLET SOUIHEL',
            IDN2: 202,
            CODEP: '4170',
          },
          {
            IDN3: 2734,
            LIBELLEN3: 'OULED ABDENNEBI',
            IDN2: 202,
            CODEP: '4173',
          },
          {
            IDN3: 2766,
            LIBELLEN3: 'RAS EDHAHRA',
            IDN2: 202,
            CODEP: '4170',
          },
          {
            IDN3: 2768,
            LIBELLEN3: 'SAHBI',
            IDN2: 202,
            CODEP: '4170',
          },
          {
            IDN3: 2739,
            LIBELLEN3: 'SANCHOU',
            IDN2: 202,
            CODEP: '4170',
          },
          {
            IDN3: 2735,
            LIBELLEN3: 'SOUIHEL',
            IDN2: 202,
            CODEP: '4173',
          },
          {
            IDN3: 2756,
            LIBELLEN3: 'ZARZIS',
            IDN2: 202,
            CODEP: '4170',
          },
          {
            IDN3: 2746,
            LIBELLEN3: 'ZARZIS ZONE FRANCHE',
            IDN2: 202,
            CODEP: '4137',
          },
        ],
      },
    ],
  },
  {
    govLabel: 'MONASTIR',
    govId: 15,
    villes: [
      {
        ville: 216,
        villeName: 'BEKALTA',
        cites: [
          {
            IDN3: 2975,
            LIBELLEN3: 'BAGHDADI',
            IDN2: 216,
            CODEP: '5090',
          },
          {
            IDN3: 2976,
            LIBELLEN3: 'BEKALTA',
            IDN2: 216,
            CODEP: '5090',
          },
          {
            IDN3: 2986,
            LIBELLEN3: 'CHARAF',
            IDN2: 216,
            CODEP: '5091',
          },
          {
            IDN3: 2977,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 216,
            CODEP: '5090',
          },
          {
            IDN3: 2978,
            LIBELLEN3: 'CITE EL IZDIHAR',
            IDN2: 216,
            CODEP: '5090',
          },
          {
            IDN3: 2979,
            LIBELLEN3: 'CITE NOUVELLE',
            IDN2: 216,
            CODEP: '5090',
          },
          {
            IDN3: 2982,
            LIBELLEN3: 'EL BEHIRA',
            IDN2: 216,
            CODEP: '5092',
          },
          {
            IDN3: 2980,
            LIBELLEN3: 'GUEALLA',
            IDN2: 216,
            CODEP: '5090',
          },
          {
            IDN3: 2981,
            LIBELLEN3: 'OULED HEDDA',
            IDN2: 216,
            CODEP: '5090',
          },
          {
            IDN3: 2983,
            LIBELLEN3: 'OULED ISSA',
            IDN2: 216,
            CODEP: '5090',
          },
          {
            IDN3: 2984,
            LIBELLEN3: 'OULED TOUIBI',
            IDN2: 216,
            CODEP: '5090',
          },
          {
            IDN3: 2985,
            LIBELLEN3: 'ZEBID',
            IDN2: 216,
            CODEP: '5090',
          },
        ],
      },
      {
        ville: 217,
        villeName: 'BEMBLA',
        cites: [
          {
            IDN3: 2987,
            LIBELLEN3: 'BEMBLA',
            IDN2: 217,
            CODEP: '5021',
          },
          {
            IDN3: 2992,
            LIBELLEN3: 'CITE 2 MARS',
            IDN2: 217,
            CODEP: '5022',
          },
          {
            IDN3: 2993,
            LIBELLEN3: 'CITE EL JAZIA',
            IDN2: 217,
            CODEP: '5022',
          },
          {
            IDN3: 2988,
            LIBELLEN3: 'CITE EL KHADHRA',
            IDN2: 217,
            CODEP: '5021',
          },
          {
            IDN3: 2989,
            LIBELLEN3: 'CITE EL MANDRA',
            IDN2: 217,
            CODEP: '5021',
          },
          {
            IDN3: 2990,
            LIBELLEN3: 'CITE ENNABKA',
            IDN2: 217,
            CODEP: '5021',
          },
          {
            IDN3: 2991,
            LIBELLEN3: 'CITE POPULAIRE',
            IDN2: 217,
            CODEP: '5021',
          },
          {
            IDN3: 2994,
            LIBELLEN3: 'CITE SOUIHRIA',
            IDN2: 217,
            CODEP: '5022',
          },
          {
            IDN3: 2996,
            LIBELLEN3: 'MAZDOUR',
            IDN2: 217,
            CODEP: '5032',
          },
          {
            IDN3: 2998,
            LIBELLEN3: 'MENARA',
            IDN2: 217,
            CODEP: '5076',
          },
          {
            IDN3: 2995,
            LIBELLEN3: 'MENZEL ENNOUR',
            IDN2: 217,
            CODEP: '5022',
          },
          {
            IDN3: 2997,
            LIBELLEN3: 'MENZEL HARB',
            IDN2: 217,
            CODEP: '5036',
          },
        ],
      },
      {
        ville: 218,
        villeName: 'BENI HASSEN',
        cites: [
          {
            IDN3: 3002,
            LIBELLEN3: 'AMIRAT HATEM',
            IDN2: 218,
            CODEP: '5062',
          },
          {
            IDN3: 2999,
            LIBELLEN3: 'BENI HASSEN',
            IDN2: 218,
            CODEP: '5014',
          },
          {
            IDN3: 3000,
            LIBELLEN3: 'CITE ETTAHRIR',
            IDN2: 218,
            CODEP: '5014',
          },
          {
            IDN3: 3001,
            LIBELLEN3: 'GHENADA',
            IDN2: 218,
            CODEP: '5026',
          },
        ],
      },
      {
        ville: 209,
        villeName: 'JEMMAL',
        cites: [
          {
            IDN3: 2894,
            LIBELLEN3: 'BIR TAIEB',
            IDN2: 209,
            CODEP: '5043',
          },
          {
            IDN3: 2904,
            LIBELLEN3: 'CITE COMMERCIALE 1',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2899,
            LIBELLEN3: 'CITE COMMERCIALE 2',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2900,
            LIBELLEN3: 'CITE EL AMEN',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2901,
            LIBELLEN3: 'CITE EL BASSATINE1',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2878,
            LIBELLEN3: 'CITE EL BASSATINE2',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2879,
            LIBELLEN3: 'CITE EL FATEH',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2880,
            LIBELLEN3: 'CITE EL INTILAKA',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2881,
            LIBELLEN3: 'CITE EL IZDIHAR',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2882,
            LIBELLEN3: 'CITE EL KHADHRA',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2896,
            LIBELLEN3: 'CITE EL KHADHRA',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2883,
            LIBELLEN3: 'CITE EL MANAR 1',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2884,
            LIBELLEN3: 'CITE EL MANAR 2',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2885,
            LIBELLEN3: 'CITE EL WIFAK',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2886,
            LIBELLEN3: 'CITE EL YASMINE',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2887,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2888,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2890,
            LIBELLEN3: 'CITE ENNOUZHA',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2891,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2892,
            LIBELLEN3: 'CITE ESSALEM',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2889,
            LIBELLEN3: 'CITE ETTAAMIR',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2868,
            LIBELLEN3: 'CITE ETTADHAMEN',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2897,
            LIBELLEN3: 'CITE ETTAHRIR',
            IDN2: 209,
            CODEP: '5028',
          },
          {
            IDN3: 2869,
            LIBELLEN3: 'CITE EZZAHRA',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2870,
            LIBELLEN3: 'CITE EZZOUHOUR 1',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2871,
            LIBELLEN3: 'CITE EZZOUHOUR 2',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2872,
            LIBELLEN3: 'CITE INDEPENDANCE',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2873,
            LIBELLEN3: 'CITE JAOUHARA',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2874,
            LIBELLEN3: 'CITE JARDINS',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2875,
            LIBELLEN3: 'CITE OPERATIONS DU SAHEL',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2876,
            LIBELLEN3: 'CITE SIDI MESSAOUD',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2903,
            LIBELLEN3: 'EL HEDADRA',
            IDN2: 209,
            CODEP: '5017',
          },
          {
            IDN3: 2895,
            LIBELLEN3: 'ETTIAYRA',
            IDN2: 209,
            CODEP: '5027',
          },
          {
            IDN3: 2877,
            LIBELLEN3: 'JEMMAL',
            IDN2: 209,
            CODEP: '5020',
          },
          {
            IDN3: 2893,
            LIBELLEN3: 'JEMMAL KHEIREDDINE',
            IDN2: 209,
            CODEP: '5030',
          },
          {
            IDN3: 2902,
            LIBELLEN3: 'MENZEL KAMEL',
            IDN2: 209,
            CODEP: '5013',
          },
          {
            IDN3: 2898,
            LIBELLEN3: 'ZAOUIET KONTECH',
            IDN2: 209,
            CODEP: '5028',
          },
        ],
      },
      {
        ville: 208,
        villeName: 'KSAR HELAL',
        cites: [
          {
            IDN3: 2854,
            LIBELLEN3: 'CITE BIR ALI HELLAL',
            IDN2: 208,
            CODEP: '5070',
          },
          {
            IDN3: 2855,
            LIBELLEN3: 'CITE BIR SOUKRA',
            IDN2: 208,
            CODEP: '5070',
          },
          {
            IDN3: 2865,
            LIBELLEN3: 'CITE BIT MEKKA',
            IDN2: 208,
            CODEP: '5070',
          },
          {
            IDN3: 2866,
            LIBELLEN3: 'CITE CHOUGAR',
            IDN2: 208,
            CODEP: '5070',
          },
          {
            IDN3: 2867,
            LIBELLEN3: 'CITE COMMERCIALE 1',
            IDN2: 208,
            CODEP: '5070',
          },
          {
            IDN3: 2848,
            LIBELLEN3: 'CITE COMMERCIALE 2',
            IDN2: 208,
            CODEP: '5070',
          },
          {
            IDN3: 2849,
            LIBELLEN3: 'CITE DAR BUMBLA',
            IDN2: 208,
            CODEP: '5070',
          },
          {
            IDN3: 2851,
            LIBELLEN3: 'CITE DE LA MUNICIPALITE',
            IDN2: 208,
            CODEP: '5070',
          },
          {
            IDN3: 2852,
            LIBELLEN3: 'CITE EJJEBSA',
            IDN2: 208,
            CODEP: '5070',
          },
          {
            IDN3: 2853,
            LIBELLEN3: 'CITE EL GARIAA',
            IDN2: 208,
            CODEP: '5070',
          },
          {
            IDN3: 2856,
            LIBELLEN3: 'CITE EL MAGLOUBA',
            IDN2: 208,
            CODEP: '5070',
          },
          {
            IDN3: 2857,
            LIBELLEN3: 'CITE ERRIADH 1',
            IDN2: 208,
            CODEP: '5070',
          },
          {
            IDN3: 2858,
            LIBELLEN3: 'CITE ERRIADH 2',
            IDN2: 208,
            CODEP: '5070',
          },
          {
            IDN3: 2859,
            LIBELLEN3: 'CITE ERRIADH 3',
            IDN2: 208,
            CODEP: '5070',
          },
          {
            IDN3: 2860,
            LIBELLEN3: 'CITE INDUSTRIELLE',
            IDN2: 208,
            CODEP: '5070',
          },
          {
            IDN3: 2861,
            LIBELLEN3: 'CITE MOUEHEDDINE',
            IDN2: 208,
            CODEP: '5070',
          },
          {
            IDN3: 2862,
            LIBELLEN3: 'CITE OUED KACEM ET EL OURAMI',
            IDN2: 208,
            CODEP: '5070',
          },
          {
            IDN3: 2863,
            LIBELLEN3: 'CITE SANIT ZAAG ET CHAREF',
            IDN2: 208,
            CODEP: '5070',
          },
          {
            IDN3: 2864,
            LIBELLEN3: 'KSAR HELAL',
            IDN2: 208,
            CODEP: '5070',
          },
          {
            IDN3: 2850,
            LIBELLEN3: 'KSAR HELAL RIADH',
            IDN2: 208,
            CODEP: '5016',
          },
        ],
      },
      {
        ville: 207,
        villeName: 'KSIBET EL MEDIOUNI',
        cites: [
          {
            IDN3: 2835,
            LIBELLEN3: 'BENNANE',
            IDN2: 207,
            CODEP: '5025',
          },
          {
            IDN3: 2840,
            LIBELLEN3: 'BOU DHER',
            IDN2: 207,
            CODEP: '5063',
          },
          {
            IDN3: 2831,
            LIBELLEN3: 'CITE 18 JANVIER',
            IDN2: 207,
            CODEP: '5031',
          },
          {
            IDN3: 2834,
            LIBELLEN3: 'CITE AIR NOUVELLE',
            IDN2: 207,
            CODEP: '5025',
          },
          {
            IDN3: 2832,
            LIBELLEN3: 'CITE COMMERCIALE',
            IDN2: 207,
            CODEP: '5031',
          },
          {
            IDN3: 2833,
            LIBELLEN3: 'CITE DE LA LIBERTE',
            IDN2: 207,
            CODEP: '5031',
          },
          {
            IDN3: 2827,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 207,
            CODEP: '5025',
          },
          {
            IDN3: 2828,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 207,
            CODEP: '5025',
          },
          {
            IDN3: 2830,
            LIBELLEN3: 'CITE EL INTILAKA',
            IDN2: 207,
            CODEP: '5025',
          },
          {
            IDN3: 2836,
            LIBELLEN3: 'CITE ENNADHOUR 1',
            IDN2: 207,
            CODEP: '5031',
          },
          {
            IDN3: 2841,
            LIBELLEN3: 'CITE ENNADHOUR 2',
            IDN2: 207,
            CODEP: '5031',
          },
          {
            IDN3: 2846,
            LIBELLEN3: 'CITE ENNADHOUR 3',
            IDN2: 207,
            CODEP: '5031',
          },
          {
            IDN3: 2847,
            LIBELLEN3: 'CITE ESSAKIA',
            IDN2: 207,
            CODEP: '5031',
          },
          {
            IDN3: 2842,
            LIBELLEN3: 'CITE EZZAYATINE',
            IDN2: 207,
            CODEP: '5031',
          },
          {
            IDN3: 2843,
            LIBELLEN3: 'CITE OUED SEKKAL',
            IDN2: 207,
            CODEP: '5031',
          },
          {
            IDN3: 2844,
            LIBELLEN3: 'CITE POPULAIRE',
            IDN2: 207,
            CODEP: '5031',
          },
          {
            IDN3: 2845,
            LIBELLEN3: 'CITE RABBAH',
            IDN2: 207,
            CODEP: '5031',
          },
          {
            IDN3: 2837,
            LIBELLEN3: 'CITE SIDI EL MEDIOUNI',
            IDN2: 207,
            CODEP: '5031',
          },
          {
            IDN3: 2826,
            LIBELLEN3: 'CITE SNIT',
            IDN2: 207,
            CODEP: '5031',
          },
          {
            IDN3: 2838,
            LIBELLEN3: 'CITE TAREK IBN ZIED',
            IDN2: 207,
            CODEP: '5031',
          },
          {
            IDN3: 2839,
            LIBELLEN3: 'KSIBET EL MEDIOUNI',
            IDN2: 207,
            CODEP: '5031',
          },
          {
            IDN3: 2829,
            LIBELLEN3: 'TOUZA',
            IDN2: 207,
            CODEP: '5023',
          },
        ],
      },
      {
        ville: 212,
        villeName: 'MOKNINE',
        cites: [
          {
            IDN3: 2945,
            LIBELLEN3: 'AMIRAT EL FEHOUL',
            IDN2: 212,
            CODEP: '5054',
          },
          {
            IDN3: 2949,
            LIBELLEN3: 'AMIRAT EL HOJJEJ',
            IDN2: 212,
            CODEP: '5071',
          },
          {
            IDN3: 2946,
            LIBELLEN3: 'AMIRAT TOUAZRA',
            IDN2: 212,
            CODEP: '5053',
          },
          {
            IDN3: 2951,
            LIBELLEN3: 'CHERAHIL',
            IDN2: 212,
            CODEP: '5034',
          },
          {
            IDN3: 2935,
            LIBELLEN3: 'CITE 7 NOVEMBRE',
            IDN2: 212,
            CODEP: '5050',
          },
          {
            IDN3: 2944,
            LIBELLEN3: 'CITE CHAHED 1',
            IDN2: 212,
            CODEP: '5050',
          },
          {
            IDN3: 2947,
            LIBELLEN3: 'CITE CHAHED 2',
            IDN2: 212,
            CODEP: '5050',
          },
          {
            IDN3: 2948,
            LIBELLEN3: 'CITE CHAHED 3',
            IDN2: 212,
            CODEP: '5050',
          },
          {
            IDN3: 2952,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 212,
            CODEP: '5034',
          },
          {
            IDN3: 2953,
            LIBELLEN3: 'CITE EL OMRANE',
            IDN2: 212,
            CODEP: '5034',
          },
          {
            IDN3: 2954,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 212,
            CODEP: '5034',
          },
          {
            IDN3: 2936,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 212,
            CODEP: '5050',
          },
          {
            IDN3: 2955,
            LIBELLEN3: 'CITE EZZAYATINE',
            IDN2: 212,
            CODEP: '5034',
          },
          {
            IDN3: 2937,
            LIBELLEN3: 'CITE GRIBAA',
            IDN2: 212,
            CODEP: '5050',
          },
          {
            IDN3: 2938,
            LIBELLEN3: 'CITE HMADA BACHA',
            IDN2: 212,
            CODEP: '5050',
          },
          {
            IDN3: 2939,
            LIBELLEN3: 'CITE MESADA',
            IDN2: 212,
            CODEP: '5050',
          },
          {
            IDN3: 2931,
            LIBELLEN3: 'CITE POPULAIRE',
            IDN2: 212,
            CODEP: '5034',
          },
          {
            IDN3: 2940,
            LIBELLEN3: 'CITE RAS EL OUED',
            IDN2: 212,
            CODEP: '5050',
          },
          {
            IDN3: 2941,
            LIBELLEN3: 'CITE SOUASSI',
            IDN2: 212,
            CODEP: '5050',
          },
          {
            IDN3: 2932,
            LIBELLEN3: 'CITE TRABELSIA',
            IDN2: 212,
            CODEP: '5034',
          },
          {
            IDN3: 2933,
            LIBELLEN3: 'CITE ZRARAA',
            IDN2: 212,
            CODEP: '5034',
          },
          {
            IDN3: 2950,
            LIBELLEN3: 'MENZEL FERSI',
            IDN2: 212,
            CODEP: '5024',
          },
          {
            IDN3: 2942,
            LIBELLEN3: 'MOKNINE',
            IDN2: 212,
            CODEP: '5050',
          },
          {
            IDN3: 2943,
            LIBELLEN3: 'MOKNINE EL JADIDA',
            IDN2: 212,
            CODEP: '5051',
          },
          {
            IDN3: 2934,
            LIBELLEN3: 'SIDI BANNOUR',
            IDN2: 212,
            CODEP: '5044',
          },
        ],
      },
      {
        ville: 211,
        villeName: 'MONASTIR',
        cites: [
          {
            IDN3: 2919,
            LIBELLEN3: 'CITE BIR HLOU',
            IDN2: 211,
            CODEP: '5060',
          },
          {
            IDN3: 2920,
            LIBELLEN3: 'CITE CHERAKA',
            IDN2: 211,
            CODEP: '5060',
          },
          {
            IDN3: 2921,
            LIBELLEN3: 'CITE CNRPS',
            IDN2: 211,
            CODEP: '5060',
          },
          {
            IDN3: 2922,
            LIBELLEN3: 'CITE EL AMEL',
            IDN2: 211,
            CODEP: '5060',
          },
          {
            IDN3: 2926,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 211,
            CODEP: '5089',
          },
          {
            IDN3: 2923,
            LIBELLEN3: 'CITE EL FAOUZ',
            IDN2: 211,
            CODEP: '5060',
          },
          {
            IDN3: 2913,
            LIBELLEN3: 'CITE EL FARIK',
            IDN2: 211,
            CODEP: '5060',
          },
          {
            IDN3: 2914,
            LIBELLEN3: 'CITE EL MZALI',
            IDN2: 211,
            CODEP: '5060',
          },
          {
            IDN3: 2915,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 211,
            CODEP: '5060',
          },
          {
            IDN3: 2916,
            LIBELLEN3: 'CITE ESSAADA',
            IDN2: 211,
            CODEP: '5060',
          },
          {
            IDN3: 2911,
            LIBELLEN3: 'CITE ESSALEM',
            IDN2: 211,
            CODEP: '5060',
          },
          {
            IDN3: 2912,
            LIBELLEN3: 'CITE SIDI NASSER',
            IDN2: 211,
            CODEP: '5060',
          },
          {
            IDN3: 2927,
            LIBELLEN3: 'CITE TRABELSIA',
            IDN2: 211,
            CODEP: '5060',
          },
          {
            IDN3: 2918,
            LIBELLEN3: 'KHENIS',
            IDN2: 211,
            CODEP: '5011',
          },
          {
            IDN3: 2917,
            LIBELLEN3: 'MONASTIR',
            IDN2: 211,
            CODEP: '5000',
          },
          {
            IDN3: 2930,
            LIBELLEN3: 'MONASTIR AEROPORT',
            IDN2: 211,
            CODEP: '5065',
          },
          {
            IDN3: 2925,
            LIBELLEN3: 'MONASTIR GARE',
            IDN2: 211,
            CODEP: '5079',
          },
          {
            IDN3: 2928,
            LIBELLEN3: 'MONASTIR REPUBLIQUE',
            IDN2: 211,
            CODEP: '5060',
          },
          {
            IDN3: 2924,
            LIBELLEN3: 'ZONE HOTELIERE',
            IDN2: 211,
            CODEP: '5065',
          },
          {
            IDN3: 2929,
            LIBELLEN3: 'ZONE SKANES',
            IDN2: 211,
            CODEP: '5060',
          },
        ],
      },
      {
        ville: 213,
        villeName: 'OUERDANINE',
        cites: [
          {
            IDN3: 2956,
            LIBELLEN3: 'BOU OTHMANE',
            IDN2: 213,
            CODEP: '5052',
          },
          {
            IDN3: 2959,
            LIBELLEN3: 'CITE 22 JANVIER',
            IDN2: 213,
            CODEP: '5010',
          },
          {
            IDN3: 2960,
            LIBELLEN3: 'CITE 7 NOVEMBRE',
            IDN2: 213,
            CODEP: '5010',
          },
          {
            IDN3: 2961,
            LIBELLEN3: 'CITE ABDELAZIZ THAALBI',
            IDN2: 213,
            CODEP: '5010',
          },
          {
            IDN3: 2962,
            LIBELLEN3: 'CITE ENNOUHOUDH',
            IDN2: 213,
            CODEP: '5010',
          },
          {
            IDN3: 2963,
            LIBELLEN3: 'CITE ENNOUZHA',
            IDN2: 213,
            CODEP: '5010',
          },
          {
            IDN3: 2964,
            LIBELLEN3: 'CITE MOHAMED ALI',
            IDN2: 213,
            CODEP: '5010',
          },
          {
            IDN3: 2957,
            LIBELLEN3: 'MENZEL KHIR',
            IDN2: 213,
            CODEP: '5041',
          },
          {
            IDN3: 2958,
            LIBELLEN3: 'OUERDANINE',
            IDN2: 213,
            CODEP: '5010',
          },
        ],
      },
      {
        ville: 210,
        villeName: 'SAHLINE',
        cites: [
          {
            IDN3: 2905,
            LIBELLEN3: 'CITE ECHAABIA NLLE',
            IDN2: 210,
            CODEP: '5012',
          },
          {
            IDN3: 2909,
            LIBELLEN3: 'CITE NOUVELLE',
            IDN2: 210,
            CODEP: '5061',
          },
          {
            IDN3: 2908,
            LIBELLEN3: 'MESJED ISSA',
            IDN2: 210,
            CODEP: '5042',
          },
          {
            IDN3: 2906,
            LIBELLEN3: 'MOOTMAR',
            IDN2: 210,
            CODEP: '5012',
          },
          {
            IDN3: 2907,
            LIBELLEN3: 'SAHLINE',
            IDN2: 210,
            CODEP: '5012',
          },
          {
            IDN3: 2910,
            LIBELLEN3: 'SIDI AMEUR',
            IDN2: 210,
            CODEP: '5061',
          },
        ],
      },
      {
        ville: 206,
        villeName: 'SAYADA LAMTA BOU HAJAR',
        cites: [
          {
            IDN3: 2825,
            LIBELLEN3: 'BOU HAJAR',
            IDN2: 206,
            CODEP: '5015',
          },
          {
            IDN3: 2815,
            LIBELLEN3: 'CITE BIR EL AYEB',
            IDN2: 206,
            CODEP: '5099',
          },
          {
            IDN3: 2821,
            LIBELLEN3: 'CITE CHOOBET ROMMAN',
            IDN2: 206,
            CODEP: '5015',
          },
          {
            IDN3: 2823,
            LIBELLEN3: 'CITE ENNADHOUR',
            IDN2: 206,
            CODEP: '5099',
          },
          {
            IDN3: 2816,
            LIBELLEN3: 'CITE GOURRAIA',
            IDN2: 206,
            CODEP: '5035',
          },
          {
            IDN3: 2817,
            LIBELLEN3: 'CITE NOUVELLE',
            IDN2: 206,
            CODEP: '5035',
          },
          {
            IDN3: 2822,
            LIBELLEN3: 'CITE NOUVELLE',
            IDN2: 206,
            CODEP: '5035',
          },
          {
            IDN3: 2818,
            LIBELLEN3: 'CITE SIDI AMMAR',
            IDN2: 206,
            CODEP: '5035',
          },
          {
            IDN3: 2824,
            LIBELLEN3: 'LAMTA',
            IDN2: 206,
            CODEP: '5099',
          },
          {
            IDN3: 2819,
            LIBELLEN3: 'SAYADA',
            IDN2: 206,
            CODEP: '5015',
          },
          {
            IDN3: 2820,
            LIBELLEN3: 'SIDI ABDESSALEM',
            IDN2: 206,
            CODEP: '5035',
          },
        ],
      },
      {
        ville: 214,
        villeName: 'TEBOULBA',
        cites: [
          {
            IDN3: 2966,
            LIBELLEN3: 'CITE BOU DRISSE',
            IDN2: 214,
            CODEP: '5080',
          },
          {
            IDN3: 2967,
            LIBELLEN3: 'CITE EL FADHLINE',
            IDN2: 214,
            CODEP: '5080',
          },
          {
            IDN3: 2965,
            LIBELLEN3: 'SOUKRINE',
            IDN2: 214,
            CODEP: '5066',
          },
          {
            IDN3: 2968,
            LIBELLEN3: 'TEBOULBA',
            IDN2: 214,
            CODEP: '5080',
          },
        ],
      },
      {
        ville: 215,
        villeName: 'ZERAMDINE',
        cites: [
          {
            IDN3: 2970,
            LIBELLEN3: 'CITE EL KODS',
            IDN2: 215,
            CODEP: '5040',
          },
          {
            IDN3: 2971,
            LIBELLEN3: 'CITE EL MANAR',
            IDN2: 215,
            CODEP: '5040',
          },
          {
            IDN3: 2969,
            LIBELLEN3: 'MENZEL HAYET',
            IDN2: 215,
            CODEP: '5033',
          },
          {
            IDN3: 2974,
            LIBELLEN3: 'MLICHETTE',
            IDN2: 215,
            CODEP: '5046',
          },
          {
            IDN3: 2973,
            LIBELLEN3: 'MZAOUGHA',
            IDN2: 215,
            CODEP: '5045',
          },
          {
            IDN3: 2972,
            LIBELLEN3: 'ZERAMDINE',
            IDN2: 215,
            CODEP: '5040',
          },
        ],
      },
    ],
  },
  {
    govLabel: 'NABEUL',
    govId: 16,
    villes: [
      {
        ville: 226,
        villeName: 'BENI KHALLED',
        cites: [
          {
            IDN3: 3183,
            LIBELLEN3: 'BENI KHALLED',
            IDN2: 226,
            CODEP: '8021',
          },
          {
            IDN3: 3182,
            LIBELLEN3: 'BIR DRASSEN',
            IDN2: 226,
            CODEP: '8014',
          },
          {
            IDN3: 3157,
            LIBELLEN3: 'CITE ACHIBET AMOR',
            IDN2: 226,
            CODEP: '8021',
          },
          {
            IDN3: 3158,
            LIBELLEN3: 'CITE AIR NOUVELLE',
            IDN2: 226,
            CODEP: '8021',
          },
          {
            IDN3: 3159,
            LIBELLEN3: 'CITE ALAYA YAKOUBI',
            IDN2: 226,
            CODEP: '8021',
          },
          {
            IDN3: 3160,
            LIBELLEN3: 'CITE BEN ROMDHANE',
            IDN2: 226,
            CODEP: '8021',
          },
          {
            IDN3: 3161,
            LIBELLEN3: 'CITE CHELBIA OUMAYA',
            IDN2: 226,
            CODEP: '8021',
          },
          {
            IDN3: 3162,
            LIBELLEN3: 'CITE DAGHROUR',
            IDN2: 226,
            CODEP: '8021',
          },
          {
            IDN3: 3163,
            LIBELLEN3: 'CITE DES JEUNES',
            IDN2: 226,
            CODEP: '8021',
          },
          {
            IDN3: 3164,
            LIBELLEN3: 'CITE DU PRINTEMPS',
            IDN2: 226,
            CODEP: '8021',
          },
          {
            IDN3: 3165,
            LIBELLEN3: 'CITE EL GAIED',
            IDN2: 226,
            CODEP: '8021',
          },
          {
            IDN3: 3175,
            LIBELLEN3: 'CITE EL HOUDA',
            IDN2: 226,
            CODEP: '8099',
          },
          {
            IDN3: 3176,
            LIBELLEN3: 'CITE ERRIADH 1',
            IDN2: 226,
            CODEP: '8099',
          },
          {
            IDN3: 3177,
            LIBELLEN3: 'CITE ERRIADH 2',
            IDN2: 226,
            CODEP: '8099',
          },
          {
            IDN3: 3166,
            LIBELLEN3: 'CITE ESSALEM',
            IDN2: 226,
            CODEP: '8021',
          },
          {
            IDN3: 3167,
            LIBELLEN3: 'CITE ETTAAMIR',
            IDN2: 226,
            CODEP: '8021',
          },
          {
            IDN3: 3168,
            LIBELLEN3: 'CITE EZZAOUCH',
            IDN2: 226,
            CODEP: '8021',
          },
          {
            IDN3: 3169,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 226,
            CODEP: '8021',
          },
          {
            IDN3: 3178,
            LIBELLEN3: 'CITE JARDINS 1',
            IDN2: 226,
            CODEP: '8099',
          },
          {
            IDN3: 3179,
            LIBELLEN3: 'CITE JARDINS 2',
            IDN2: 226,
            CODEP: '8099',
          },
          {
            IDN3: 3170,
            LIBELLEN3: 'CITE LATRECH',
            IDN2: 226,
            CODEP: '8021',
          },
          {
            IDN3: 3171,
            LIBELLEN3: 'CITE MEKKI',
            IDN2: 226,
            CODEP: '8021',
          },
          {
            IDN3: 3172,
            LIBELLEN3: 'CITE MOKHTAR RHOUMA',
            IDN2: 226,
            CODEP: '8021',
          },
          {
            IDN3: 3180,
            LIBELLEN3: 'CITE SIDI BOU SAID',
            IDN2: 226,
            CODEP: '8099',
          },
          {
            IDN3: 3173,
            LIBELLEN3: 'CITE SIDI BOU YAHIA',
            IDN2: 226,
            CODEP: '8021',
          },
          {
            IDN3: 3174,
            LIBELLEN3: 'CITE SOUISSI',
            IDN2: 226,
            CODEP: '8021',
          },
          {
            IDN3: 3181,
            LIBELLEN3: 'ZAOUIET JEDIDI',
            IDN2: 226,
            CODEP: '8099',
          },
        ],
      },
      {
        ville: 225,
        villeName: 'BENI KHIAR',
        cites: [
          {
            IDN3: 3140,
            LIBELLEN3: 'BENI KHIAR',
            IDN2: 225,
            CODEP: '8060',
          },
          {
            IDN3: 3146,
            LIBELLEN3: 'CITE BOU JAAFAR',
            IDN2: 225,
            CODEP: '8023',
          },
          {
            IDN3: 3147,
            LIBELLEN3: 'CITE CHAFRADA 1',
            IDN2: 225,
            CODEP: '8023',
          },
          {
            IDN3: 3148,
            LIBELLEN3: 'CITE CHAFRADA 2',
            IDN2: 225,
            CODEP: '8023',
          },
          {
            IDN3: 3149,
            LIBELLEN3: 'CITE DE LA LIBERTE',
            IDN2: 225,
            CODEP: '8023',
          },
          {
            IDN3: 3150,
            LIBELLEN3: 'CITE DES JEUNES',
            IDN2: 225,
            CODEP: '8023',
          },
          {
            IDN3: 3151,
            LIBELLEN3: 'CITE DU JARDIN',
            IDN2: 225,
            CODEP: '8023',
          },
          {
            IDN3: 3152,
            LIBELLEN3: 'CITE EL MANAR',
            IDN2: 225,
            CODEP: '8023',
          },
          {
            IDN3: 3141,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 225,
            CODEP: '8060',
          },
          {
            IDN3: 3153,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 225,
            CODEP: '8023',
          },
          {
            IDN3: 3154,
            LIBELLEN3: 'CITE MANSOUR',
            IDN2: 225,
            CODEP: '8023',
          },
          {
            IDN3: 3155,
            LIBELLEN3: 'CITE POPULAIRE 1',
            IDN2: 225,
            CODEP: '8023',
          },
          {
            IDN3: 3143,
            LIBELLEN3: 'DIAR BEN SALEM',
            IDN2: 225,
            CODEP: '8060',
          },
          {
            IDN3: 3144,
            LIBELLEN3: 'EL HALFA',
            IDN2: 225,
            CODEP: '8023',
          },
          {
            IDN3: 3145,
            LIBELLEN3: 'EL MAAMOURA',
            IDN2: 225,
            CODEP: '8013',
          },
          {
            IDN3: 3156,
            LIBELLEN3: 'GHARDAYA',
            IDN2: 225,
            CODEP: '8066',
          },
          {
            IDN3: 3142,
            LIBELLEN3: 'SOMAA',
            IDN2: 225,
            CODEP: '8023',
          },
        ],
      },
      {
        ville: 232,
        villeName: 'BOU ARGOUB',
        cites: [
          {
            IDN3: 3336,
            LIBELLEN3: 'BELLI',
            IDN2: 232,
            CODEP: '8022',
          },
          {
            IDN3: 3334,
            LIBELLEN3: 'BORJ EL GRAIS',
            IDN2: 232,
            CODEP: '8061',
          },
          {
            IDN3: 3337,
            LIBELLEN3: 'BORJ HAFAIEDH',
            IDN2: 232,
            CODEP: '8040',
          },
          {
            IDN3: 3338,
            LIBELLEN3: 'BOU ALI',
            IDN2: 232,
            CODEP: '8040',
          },
          {
            IDN3: 3339,
            LIBELLEN3: 'BOU ARGOUB',
            IDN2: 232,
            CODEP: '8040',
          },
          {
            IDN3: 3340,
            LIBELLEN3: 'BOU RBII',
            IDN2: 232,
            CODEP: '8040',
          },
          {
            IDN3: 3341,
            LIBELLEN3: 'BOUSEHEM',
            IDN2: 232,
            CODEP: '8040',
          },
          {
            IDN3: 3342,
            LIBELLEN3: 'CHAGLEB',
            IDN2: 232,
            CODEP: '8040',
          },
          {
            IDN3: 3343,
            LIBELLEN3: 'CITE CNEL',
            IDN2: 232,
            CODEP: '8040',
          },
          {
            IDN3: 3344,
            LIBELLEN3: 'CITE COMMERCIALE',
            IDN2: 232,
            CODEP: '8040',
          },
          {
            IDN3: 3345,
            LIBELLEN3: 'CITE DE LA CARRIERE',
            IDN2: 232,
            CODEP: '8040',
          },
          {
            IDN3: 3346,
            LIBELLEN3: 'CITE EL HENCHIR',
            IDN2: 232,
            CODEP: '8040',
          },
          {
            IDN3: 3347,
            LIBELLEN3: 'CITE EL HOUANI',
            IDN2: 232,
            CODEP: '8040',
          },
          {
            IDN3: 3348,
            LIBELLEN3: 'CITE EL HOURRIA',
            IDN2: 232,
            CODEP: '8040',
          },
          {
            IDN3: 3349,
            LIBELLEN3: 'CITE EZZAOUIA',
            IDN2: 232,
            CODEP: '8040',
          },
          {
            IDN3: 3350,
            LIBELLEN3: 'CITE JADIDA',
            IDN2: 232,
            CODEP: '8040',
          },
          {
            IDN3: 3351,
            LIBELLEN3: 'CITE MONGI SLIM',
            IDN2: 232,
            CODEP: '8040',
          },
          {
            IDN3: 3352,
            LIBELLEN3: 'CITE SPROLS',
            IDN2: 232,
            CODEP: '8040',
          },
          {
            IDN3: 3353,
            LIBELLEN3: 'DOUALI',
            IDN2: 232,
            CODEP: '8040',
          },
          {
            IDN3: 3354,
            LIBELLEN3: 'DOUKHANIA',
            IDN2: 232,
            CODEP: '8040',
          },
          {
            IDN3: 3355,
            LIBELLEN3: 'EL GRABCHA',
            IDN2: 232,
            CODEP: '8040',
          },
          {
            IDN3: 3356,
            LIBELLEN3: 'EL KHARROUBA',
            IDN2: 232,
            CODEP: '8040',
          },
          {
            IDN3: 3357,
            LIBELLEN3: 'EL MACHROUHA',
            IDN2: 232,
            CODEP: '8040',
          },
          {
            IDN3: 3358,
            LIBELLEN3: 'EL MHEDHBA',
            IDN2: 232,
            CODEP: '8040',
          },
          {
            IDN3: 3359,
            LIBELLEN3: 'HARITHINE',
            IDN2: 232,
            CODEP: '8040',
          },
          {
            IDN3: 3360,
            LIBELLEN3: 'OULED ABDALLAH',
            IDN2: 232,
            CODEP: '8040',
          },
          {
            IDN3: 3335,
            LIBELLEN3: 'SIDI DHAHER',
            IDN2: 232,
            CODEP: '8061',
          },
          {
            IDN3: 3361,
            LIBELLEN3: 'ZONE INDUSTRIELLE',
            IDN2: 232,
            CODEP: '8040',
          },
        ],
      },
      {
        ville: 233,
        villeName: 'DAR CHAABANE ELFEHRI',
        cites: [
          {
            IDN3: 3362,
            LIBELLEN3: 'BAYOUB',
            IDN2: 233,
            CODEP: '8011',
          },
          {
            IDN3: 3363,
            LIBELLEN3: 'CITE BARNOUSSA',
            IDN2: 233,
            CODEP: '8011',
          },
          {
            IDN3: 3364,
            LIBELLEN3: 'CITE EL FERDAOUS',
            IDN2: 233,
            CODEP: '8011',
          },
          {
            IDN3: 3365,
            LIBELLEN3: 'CITE ENNOUZHA',
            IDN2: 233,
            CODEP: '8011',
          },
          {
            IDN3: 3366,
            LIBELLEN3: 'CITE ETTAKADDOUM',
            IDN2: 233,
            CODEP: '8011',
          },
          {
            IDN3: 3367,
            LIBELLEN3: 'CITE OUED EL GHOULA',
            IDN2: 233,
            CODEP: '8011',
          },
          {
            IDN3: 3368,
            LIBELLEN3: 'DAR CHAABANE ELFEHRI',
            IDN2: 233,
            CODEP: '8011',
          },
          {
            IDN3: 3370,
            LIBELLEN3: 'DAR CHAABANE PLAGE',
            IDN2: 233,
            CODEP: '8075',
          },
          {
            IDN3: 3369,
            LIBELLEN3: 'EL FRININE',
            IDN2: 233,
            CODEP: '8011',
          },
        ],
      },
      {
        ville: 228,
        villeName: 'EL HAOUARIA',
        cites: [
          {
            IDN3: 3238,
            LIBELLEN3: 'BENI KHIRA',
            IDN2: 228,
            CODEP: '8026',
          },
          {
            IDN3: 3230,
            LIBELLEN3: 'BIR EL JEDEY',
            IDN2: 228,
            CODEP: '8046',
          },
          {
            IDN3: 3233,
            LIBELLEN3: 'BOU HBIB',
            IDN2: 228,
            CODEP: '8074',
          },
          {
            IDN3: 3237,
            LIBELLEN3: 'BOU KRIM',
            IDN2: 228,
            CODEP: '8036',
          },
          {
            IDN3: 3255,
            LIBELLEN3: 'CHARAF',
            IDN2: 228,
            CODEP: '8045',
          },
          {
            IDN3: 3248,
            LIBELLEN3: 'CITE DES JEUNES',
            IDN2: 228,
            CODEP: '8045',
          },
          {
            IDN3: 3249,
            LIBELLEN3: 'CITE DES ROUINES',
            IDN2: 228,
            CODEP: '8045',
          },
          {
            IDN3: 3250,
            LIBELLEN3: 'CITE EL AMEL',
            IDN2: 228,
            CODEP: '8045',
          },
          {
            IDN3: 3251,
            LIBELLEN3: 'CITE EL INTILAKA',
            IDN2: 228,
            CODEP: '8045',
          },
          {
            IDN3: 3252,
            LIBELLEN3: 'CITE EL IZDIHAR',
            IDN2: 228,
            CODEP: '8045',
          },
          {
            IDN3: 3253,
            LIBELLEN3: 'CITE EL KHADHRA',
            IDN2: 228,
            CODEP: '8045',
          },
          {
            IDN3: 3254,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 228,
            CODEP: '8045',
          },
          {
            IDN3: 3256,
            LIBELLEN3: 'CITE ESSALAMA',
            IDN2: 228,
            CODEP: '8045',
          },
          {
            IDN3: 3239,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 228,
            CODEP: '8045',
          },
          {
            IDN3: 3240,
            LIBELLEN3: 'CITE SPROLS',
            IDN2: 228,
            CODEP: '8045',
          },
          {
            IDN3: 3241,
            LIBELLEN3: 'CITE TOURISTIQUE',
            IDN2: 228,
            CODEP: '8045',
          },
          {
            IDN3: 3234,
            LIBELLEN3: 'EL GORFA',
            IDN2: 228,
            CODEP: '8026',
          },
          {
            IDN3: 3242,
            LIBELLEN3: 'EL HAOUARIA',
            IDN2: 228,
            CODEP: '8045',
          },
          {
            IDN3: 3243,
            LIBELLEN3: 'EL KEDOUA',
            IDN2: 228,
            CODEP: '8045',
          },
          {
            IDN3: 3231,
            LIBELLEN3: 'EL KERMANIA',
            IDN2: 228,
            CODEP: '8046',
          },
          {
            IDN3: 3244,
            LIBELLEN3: 'FERJOUN',
            IDN2: 228,
            CODEP: '8045',
          },
          {
            IDN3: 3245,
            LIBELLEN3: 'MENZEL SALEM',
            IDN2: 228,
            CODEP: '8045',
          },
          {
            IDN3: 3235,
            LIBELLEN3: 'SAHEB JEBEL',
            IDN2: 228,
            CODEP: '8026',
          },
          {
            IDN3: 3236,
            LIBELLEN3: 'SIDI DAOUD',
            IDN2: 228,
            CODEP: '8016',
          },
          {
            IDN3: 3229,
            LIBELLEN3: 'SIDI HASSOUN',
            IDN2: 228,
            CODEP: '8045',
          },
          {
            IDN3: 3246,
            LIBELLEN3: 'TAZOUGRANE',
            IDN2: 228,
            CODEP: '8074',
          },
          {
            IDN3: 3232,
            LIBELLEN3: 'ZAOUIET EL MGAIES',
            IDN2: 228,
            CODEP: '8046',
          },
          {
            IDN3: 3247,
            LIBELLEN3: 'ZOUGAG',
            IDN2: 228,
            CODEP: '8074',
          },
        ],
      },
      {
        ville: 231,
        villeName: 'EL MIDA',
        cites: [
          {
            IDN3: 3321,
            LIBELLEN3: 'AIN EL GHRAB',
            IDN2: 231,
            CODEP: '8044',
          },
          {
            IDN3: 3322,
            LIBELLEN3: 'CITE DHAHRET EZZAOUIA',
            IDN2: 231,
            CODEP: '8044',
          },
          {
            IDN3: 3323,
            LIBELLEN3: 'CITE ESSOUALMIA',
            IDN2: 231,
            CODEP: '8044',
          },
          {
            IDN3: 3324,
            LIBELLEN3: 'EL KHELAIFA',
            IDN2: 231,
            CODEP: '8044',
          },
          {
            IDN3: 3325,
            LIBELLEN3: 'EL MAISRA',
            IDN2: 231,
            CODEP: '8044',
          },
          {
            IDN3: 3326,
            LIBELLEN3: 'EL MIDA',
            IDN2: 231,
            CODEP: '8044',
          },
          {
            IDN3: 3327,
            LIBELLEN3: 'ERREGHINE',
            IDN2: 231,
            CODEP: '8044',
          },
          {
            IDN3: 3328,
            LIBELLEN3: 'ERROUIGUETTE',
            IDN2: 231,
            CODEP: '8044',
          },
          {
            IDN3: 3320,
            LIBELLEN3: 'FARTOUNA',
            IDN2: 231,
            CODEP: '8089',
          },
          {
            IDN3: 3329,
            LIBELLEN3: 'MENZEL TOUHAMI',
            IDN2: 231,
            CODEP: '8044',
          },
          {
            IDN3: 3330,
            LIBELLEN3: 'OUM DHOUIL VILLAGE',
            IDN2: 231,
            CODEP: '8044',
          },
          {
            IDN3: 3331,
            LIBELLEN3: 'OUM DHOUIL ZAOUIA',
            IDN2: 231,
            CODEP: '8044',
          },
          {
            IDN3: 3332,
            LIBELLEN3: 'SIDI BOU ALI',
            IDN2: 231,
            CODEP: '8044',
          },
          {
            IDN3: 3333,
            LIBELLEN3: 'TEFELOUN',
            IDN2: 231,
            CODEP: '8071',
          },
        ],
      },
      {
        ville: 230,
        villeName: 'GROMBALIA',
        cites: [
          {
            IDN3: 3282,
            LIBELLEN3: 'AIN TEBOURNOK',
            IDN2: 230,
            CODEP: '8093',
          },
          {
            IDN3: 3279,
            LIBELLEN3: 'BATROU',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3289,
            LIBELLEN3: 'BELLI HALTE',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3315,
            LIBELLEN3: 'BENI AYECH',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3317,
            LIBELLEN3: 'CITE 7 NOVEMBRE',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3319,
            LIBELLEN3: 'CITE AFH',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3316,
            LIBELLEN3: 'CITE ANTAR',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3318,
            LIBELLEN3: 'CITE BEN ATTAYA',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3288,
            LIBELLEN3: 'CITE BEN ZAIED',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3278,
            LIBELLEN3: 'CITE CEBALA',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3280,
            LIBELLEN3: 'CITE DE LA POLICE',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3313,
            LIBELLEN3: 'CITE DES JUGES',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3314,
            LIBELLEN3: 'CITE DHAMENE',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3281,
            LIBELLEN3: 'CITE EDDAMOUS',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3286,
            LIBELLEN3: 'CITE EL MANDRA',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3287,
            LIBELLEN3: 'CITE EL OUERDIA',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3290,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3291,
            LIBELLEN3: 'CITE ESSOMBOULA',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3285,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3311,
            LIBELLEN3: 'CITE IBN KHALDOUN',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3312,
            LIBELLEN3: 'CITE JARDINS',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3274,
            LIBELLEN3: 'CITE MOHAMED ALI',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3292,
            LIBELLEN3: 'CITE MONGI SLIM',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3293,
            LIBELLEN3: 'CITE SNIT',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3294,
            LIBELLEN3: 'CITE TAIEB MHIRI',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3295,
            LIBELLEN3: 'CITE TAREK IBN ZIED',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3296,
            LIBELLEN3: 'CITE VALENZA',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3297,
            LIBELLEN3: 'EL ARGOUB JEDID',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3298,
            LIBELLEN3: 'EL KARMIA',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3299,
            LIBELLEN3: 'EL KHOUINE',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3300,
            LIBELLEN3: 'EL KOBBA EL KEBIRA',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3301,
            LIBELLEN3: 'EL MHEDHBA',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3275,
            LIBELLEN3: 'FONDOUK JEDID',
            IDN2: 230,
            CODEP: '8012',
          },
          {
            IDN3: 3302,
            LIBELLEN3: 'GROMBALIA',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3310,
            LIBELLEN3: 'GROMBALIA EZZOUHOUR',
            IDN2: 230,
            CODEP: '8092',
          },
          {
            IDN3: 3303,
            LIBELLEN3: 'HENCHIR ETTOUTA',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3283,
            LIBELLEN3: 'JEBEL TRIF',
            IDN2: 230,
            CODEP: '8093',
          },
          {
            IDN3: 3308,
            LIBELLEN3: 'KHANGUET EL HOJJAJ',
            IDN2: 230,
            CODEP: '8082',
          },
          {
            IDN3: 3304,
            LIBELLEN3: 'KHANGUET GARE',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3277,
            LIBELLEN3: 'KHOUS',
            IDN2: 230,
            CODEP: '8014',
          },
          {
            IDN3: 3307,
            LIBELLEN3: 'NIANOU',
            IDN2: 230,
            CODEP: '8052',
          },
          {
            IDN3: 3305,
            LIBELLEN3: 'NOUEL',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3306,
            LIBELLEN3: 'SAMMACH',
            IDN2: 230,
            CODEP: '8030',
          },
          {
            IDN3: 3276,
            LIBELLEN3: 'SELTENE',
            IDN2: 230,
            CODEP: '8012',
          },
          {
            IDN3: 3309,
            LIBELLEN3: 'TURKI',
            IDN2: 230,
            CODEP: '8084',
          },
          {
            IDN3: 3284,
            LIBELLEN3: 'ZEMNIT',
            IDN2: 230,
            CODEP: '8093',
          },
        ],
      },
      {
        ville: 234,
        villeName: 'HAMMAM EL GHEZAZ',
        cites: [
          {
            IDN3: 3371,
            LIBELLEN3: 'CITE JARDINS',
            IDN2: 234,
            CODEP: '8025',
          },
          {
            IDN3: 3373,
            LIBELLEN3: 'EZZAHRA',
            IDN2: 234,
            CODEP: '8096',
          },
          {
            IDN3: 3372,
            LIBELLEN3: 'HAMMAM EL GHEZAZ',
            IDN2: 234,
            CODEP: '8025',
          },
          {
            IDN3: 3374,
            LIBELLEN3: 'HAMMAM JEBLI',
            IDN2: 234,
            CODEP: '8096',
          },
          {
            IDN3: 3375,
            LIBELLEN3: 'TAMAZRAT',
            IDN2: 234,
            CODEP: '8096',
          },
        ],
      },
      {
        ville: 227,
        villeName: 'HAMMAMET',
        cites: [
          {
            IDN3: 3208,
            LIBELLEN3: 'BARRAKET ESSAHEL',
            IDN2: 227,
            CODEP: '8056',
          },
          {
            IDN3: 3214,
            LIBELLEN3: 'BIR BOUREGBA',
            IDN2: 227,
            CODEP: '8042',
          },
          {
            IDN3: 3227,
            LIBELLEN3: 'CHAABET EL MREZGA',
            IDN2: 227,
            CODEP: '8050',
          },
          {
            IDN3: 3184,
            LIBELLEN3: 'CHATT EL KHALIJ',
            IDN2: 227,
            CODEP: '8050',
          },
          {
            IDN3: 3209,
            LIBELLEN3: 'CITE AFH',
            IDN2: 227,
            CODEP: '8056',
          },
          {
            IDN3: 3215,
            LIBELLEN3: 'CITE BIR CHAABA',
            IDN2: 227,
            CODEP: '8042',
          },
          {
            IDN3: 3216,
            LIBELLEN3: 'CITE BOUSLAMA',
            IDN2: 227,
            CODEP: '8042',
          },
          {
            IDN3: 3210,
            LIBELLEN3: 'CITE CHATTELES',
            IDN2: 227,
            CODEP: '8056',
          },
          {
            IDN3: 3217,
            LIBELLEN3: 'CITE CHRAF',
            IDN2: 227,
            CODEP: '8042',
          },
          {
            IDN3: 3218,
            LIBELLEN3: 'CITE ECOLE',
            IDN2: 227,
            CODEP: '8042',
          },
          {
            IDN3: 3219,
            LIBELLEN3: 'CITE EL BEKKEY',
            IDN2: 227,
            CODEP: '8042',
          },
          {
            IDN3: 3220,
            LIBELLEN3: 'CITE EL FAWARA',
            IDN2: 227,
            CODEP: '8042',
          },
          {
            IDN3: 3221,
            LIBELLEN3: 'CITE EL INTILAKA',
            IDN2: 227,
            CODEP: '8042',
          },
          {
            IDN3: 3194,
            LIBELLEN3: 'CITE EL IZDIHAR',
            IDN2: 227,
            CODEP: '8056',
          },
          {
            IDN3: 3199,
            LIBELLEN3: 'CITE EL KHARROUB',
            IDN2: 227,
            CODEP: '8056',
          },
          {
            IDN3: 3200,
            LIBELLEN3: 'CITE EL KSOUR',
            IDN2: 227,
            CODEP: '8056',
          },
          {
            IDN3: 3222,
            LIBELLEN3: 'CITE EL MELLASSINE',
            IDN2: 227,
            CODEP: '8042',
          },
          {
            IDN3: 3201,
            LIBELLEN3: 'CITE EL YASMINE',
            IDN2: 227,
            CODEP: '8056',
          },
          {
            IDN3: 3185,
            LIBELLEN3: 'CITE ENNADHOUR',
            IDN2: 227,
            CODEP: '8050',
          },
          {
            IDN3: 3223,
            LIBELLEN3: 'CITE ENNAIM',
            IDN2: 227,
            CODEP: '8042',
          },
          {
            IDN3: 3186,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 227,
            CODEP: '8050',
          },
          {
            IDN3: 3224,
            LIBELLEN3: 'CITE ESSAFSAF',
            IDN2: 227,
            CODEP: '8042',
          },
          {
            IDN3: 3202,
            LIBELLEN3: 'CITE ESTAH',
            IDN2: 227,
            CODEP: '8056',
          },
          {
            IDN3: 3226,
            LIBELLEN3: 'CITE GARDE NATIONALE',
            IDN2: 227,
            CODEP: '8042',
          },
          {
            IDN3: 3228,
            LIBELLEN3: 'CITE HARBOUN 1',
            IDN2: 227,
            CODEP: '8042',
          },
          {
            IDN3: 3187,
            LIBELLEN3: 'CITE IBN BADIS',
            IDN2: 227,
            CODEP: '8050',
          },
          {
            IDN3: 3203,
            LIBELLEN3: 'CITE JAWAHER1',
            IDN2: 227,
            CODEP: '8056',
          },
          {
            IDN3: 3204,
            LIBELLEN3: 'CITE JAWAHER2',
            IDN2: 227,
            CODEP: '8056',
          },
          {
            IDN3: 3188,
            LIBELLEN3: 'CITE KHEIREDDINE PACHA',
            IDN2: 227,
            CODEP: '8050',
          },
          {
            IDN3: 3205,
            LIBELLEN3: 'CITE MERZGUI',
            IDN2: 227,
            CODEP: '8056',
          },
          {
            IDN3: 3189,
            LIBELLEN3: 'CITE MILITAIRE',
            IDN2: 227,
            CODEP: '8050',
          },
          {
            IDN3: 3190,
            LIBELLEN3: 'CITE MOUSSA IBN NOUSSAIER',
            IDN2: 227,
            CODEP: '8050',
          },
          {
            IDN3: 3191,
            LIBELLEN3: 'CITE OKBA IBN NAAFA',
            IDN2: 227,
            CODEP: '8050',
          },
          {
            IDN3: 3192,
            LIBELLEN3: 'CITE SANIT SHEIKH',
            IDN2: 227,
            CODEP: '8050',
          },
          {
            IDN3: 3211,
            LIBELLEN3: 'CITE SIDI ALI',
            IDN2: 227,
            CODEP: '8042',
          },
          {
            IDN3: 3206,
            LIBELLEN3: 'CITE SNIT',
            IDN2: 227,
            CODEP: '8056',
          },
          {
            IDN3: 3212,
            LIBELLEN3: 'CITE SNIT 1',
            IDN2: 227,
            CODEP: '8042',
          },
          {
            IDN3: 3225,
            LIBELLEN3: 'CITE SNIT 2',
            IDN2: 227,
            CODEP: '8042',
          },
          {
            IDN3: 3193,
            LIBELLEN3: 'ECHAK',
            IDN2: 227,
            CODEP: '8050',
          },
          {
            IDN3: 3195,
            LIBELLEN3: 'HAMMAMET',
            IDN2: 227,
            CODEP: '8050',
          },
          {
            IDN3: 3196,
            LIBELLEN3: 'RESIDENCE ELLISSA',
            IDN2: 227,
            CODEP: '8050',
          },
          {
            IDN3: 3197,
            LIBELLEN3: 'RESIDENCE ENNACIM',
            IDN2: 227,
            CODEP: '8050',
          },
          {
            IDN3: 3198,
            LIBELLEN3: 'SIDI HMED',
            IDN2: 227,
            CODEP: '8050',
          },
          {
            IDN3: 3213,
            LIBELLEN3: 'SIDI JEDIDI',
            IDN2: 227,
            CODEP: '8032',
          },
          {
            IDN3: 3207,
            LIBELLEN3: 'ZONE HOTELIERE',
            IDN2: 227,
            CODEP: '8050',
          },
        ],
      },
      {
        ville: 229,
        villeName: 'KELIBIA',
        cites: [
          {
            IDN3: 3262,
            LIBELLEN3: 'ABENE',
            IDN2: 229,
            CODEP: '8090',
          },
          {
            IDN3: 3257,
            LIBELLEN3: 'AZMOUR',
            IDN2: 229,
            CODEP: '8035',
          },
          {
            IDN3: 3258,
            LIBELLEN3: 'BENI AYECH',
            IDN2: 229,
            CODEP: '8035',
          },
          {
            IDN3: 3263,
            LIBELLEN3: 'CHARAF',
            IDN2: 229,
            CODEP: '8090',
          },
          {
            IDN3: 3264,
            LIBELLEN3: 'CITE AFH',
            IDN2: 229,
            CODEP: '8090',
          },
          {
            IDN3: 3265,
            LIBELLEN3: 'CITE ASSEMMER',
            IDN2: 229,
            CODEP: '8090',
          },
          {
            IDN3: 3266,
            LIBELLEN3: 'CITE KSIBA',
            IDN2: 229,
            CODEP: '8090',
          },
          {
            IDN3: 3267,
            LIBELLEN3: 'CITE RIADH',
            IDN2: 229,
            CODEP: '8090',
          },
          {
            IDN3: 3259,
            LIBELLEN3: 'DAR ALLOUCHE',
            IDN2: 229,
            CODEP: '8055',
          },
          {
            IDN3: 3268,
            LIBELLEN3: 'DAR CHICHOU',
            IDN2: 229,
            CODEP: '8090',
          },
          {
            IDN3: 3269,
            LIBELLEN3: 'DAR DABOUS',
            IDN2: 229,
            CODEP: '8090',
          },
          {
            IDN3: 3270,
            LIBELLEN3: 'KELIBIA',
            IDN2: 229,
            CODEP: '8090',
          },
          {
            IDN3: 3261,
            LIBELLEN3: 'KELIBIA CHARGUIA',
            IDN2: 229,
            CODEP: '8069',
          },
          {
            IDN3: 3271,
            LIBELLEN3: 'KERKOUANE',
            IDN2: 229,
            CODEP: '8090',
          },
          {
            IDN3: 3273,
            LIBELLEN3: 'MELLOUL',
            IDN2: 229,
            CODEP: '8094',
          },
          {
            IDN3: 3260,
            LIBELLEN3: 'OUED EL KHATEF',
            IDN2: 229,
            CODEP: '8065',
          },
          {
            IDN3: 3272,
            LIBELLEN3: 'SIDI MADHKOUR',
            IDN2: 229,
            CODEP: '8090',
          },
        ],
      },
      {
        ville: 219,
        villeName: 'KORBA',
        cites: [
          {
            IDN3: 3026,
            LIBELLEN3: 'BENI AICHOUN',
            IDN2: 219,
            CODEP: '8070',
          },
          {
            IDN3: 3027,
            LIBELLEN3: 'BENI MECHKEL',
            IDN2: 219,
            CODEP: '8070',
          },
          {
            IDN3: 3024,
            LIBELLEN3: 'BOU JERIDA',
            IDN2: 219,
            CODEP: '8043',
          },
          {
            IDN3: 3028,
            LIBELLEN3: 'BOU LAZHAR',
            IDN2: 219,
            CODEP: '8070',
          },
          {
            IDN3: 3003,
            LIBELLEN3: 'BOU LIDINE',
            IDN2: 219,
            CODEP: '8070',
          },
          {
            IDN3: 3004,
            LIBELLEN3: 'CITE BIR EZZITOUN',
            IDN2: 219,
            CODEP: '8070',
          },
          {
            IDN3: 3005,
            LIBELLEN3: 'CITE COMMERCIALE',
            IDN2: 219,
            CODEP: '8070',
          },
          {
            IDN3: 3006,
            LIBELLEN3: 'CITE EL KHIRI',
            IDN2: 219,
            CODEP: '8070',
          },
          {
            IDN3: 3008,
            LIBELLEN3: 'CITE EL MAJREDI',
            IDN2: 219,
            CODEP: '8070',
          },
          {
            IDN3: 3014,
            LIBELLEN3: 'CITE ETTAAMIR',
            IDN2: 219,
            CODEP: '8070',
          },
          {
            IDN3: 3015,
            LIBELLEN3: 'CITE JAMAA ERRAHMA',
            IDN2: 219,
            CODEP: '8070',
          },
          {
            IDN3: 3017,
            LIBELLEN3: 'CITE JERAD',
            IDN2: 219,
            CODEP: '8070',
          },
          {
            IDN3: 3018,
            LIBELLEN3: 'CITE JERBI',
            IDN2: 219,
            CODEP: '8070',
          },
          {
            IDN3: 3016,
            LIBELLEN3: 'CITE SADOK JERBI',
            IDN2: 219,
            CODEP: '8070',
          },
          {
            IDN3: 3021,
            LIBELLEN3: 'CITE SIDI AMOR',
            IDN2: 219,
            CODEP: '8024',
          },
          {
            IDN3: 3007,
            LIBELLEN3: 'CITE SIDI SALEM',
            IDN2: 219,
            CODEP: '8070',
          },
          {
            IDN3: 3023,
            LIBELLEN3: 'DIAR EL HOJJEJ',
            IDN2: 219,
            CODEP: '8033',
          },
          {
            IDN3: 3025,
            LIBELLEN3: 'GARAAT SASSI',
            IDN2: 219,
            CODEP: '8053',
          },
          {
            IDN3: 3009,
            LIBELLEN3: 'JEBEL HADDAD',
            IDN2: 219,
            CODEP: '8070',
          },
          {
            IDN3: 3010,
            LIBELLEN3: 'KORBA',
            IDN2: 219,
            CODEP: '8070',
          },
          {
            IDN3: 3020,
            LIBELLEN3: 'KORBA HACHED',
            IDN2: 219,
            CODEP: '8076',
          },
          {
            IDN3: 3011,
            LIBELLEN3: 'KORCHINE',
            IDN2: 219,
            CODEP: '8070',
          },
          {
            IDN3: 3012,
            LIBELLEN3: 'KSAR SAAD',
            IDN2: 219,
            CODEP: '8070',
          },
          {
            IDN3: 3013,
            LIBELLEN3: 'LATHLETH',
            IDN2: 219,
            CODEP: '8070',
          },
          {
            IDN3: 3022,
            LIBELLEN3: 'TAZARKA',
            IDN2: 219,
            CODEP: '8024',
          },
          {
            IDN3: 3019,
            LIBELLEN3: 'TEBAK',
            IDN2: 219,
            CODEP: '8070',
          },
        ],
      },
      {
        ville: 222,
        villeName: 'MENZEL BOUZELFA',
        cites: [
          {
            IDN3: 3059,
            LIBELLEN3: 'BEDAR',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3060,
            LIBELLEN3: 'BENI GHANEM',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3061,
            LIBELLEN3: 'BENI JANNET',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3062,
            LIBELLEN3: 'BOUCHRIK',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3063,
            LIBELLEN3: 'CHARFINE',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3064,
            LIBELLEN3: 'CITE AHMED OTHMAN',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3065,
            LIBELLEN3: 'CITE AZAIEZ',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3066,
            LIBELLEN3: 'CITE BAHROUN',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3067,
            LIBELLEN3: 'CITE BEN HJEL',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3068,
            LIBELLEN3: 'CITE CHAAFOURI',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3069,
            LIBELLEN3: 'CITE CHEIKH',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3070,
            LIBELLEN3: 'CITE CHERIF',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3071,
            LIBELLEN3: 'CITE DAGHBAGI',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3072,
            LIBELLEN3: 'CITE ENNOUZHA',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3073,
            LIBELLEN3: 'CITE ESSAHLI',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3074,
            LIBELLEN3: 'CITE ETTAHRIR',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3075,
            LIBELLEN3: 'CITE JEMMALI',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3076,
            LIBELLEN3: 'CITE LAROUI',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3077,
            LIBELLEN3: 'CITE MUNICIPALE BARKET',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3078,
            LIBELLEN3: 'CITE NEGUIRA',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3079,
            LIBELLEN3: 'CITE SOUIHLI',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3080,
            LIBELLEN3: 'CITE SOUISSI',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3081,
            LIBELLEN3: 'DAMOUS EL HAJJA',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3082,
            LIBELLEN3: 'DAR JOUNDI',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3083,
            LIBELLEN3: 'DAR NADER',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3055,
            LIBELLEN3: 'DAR TAKELSA',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3056,
            LIBELLEN3: 'EL ARIMA',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3088,
            LIBELLEN3: 'ERRAHMA',
            IDN2: 222,
            CODEP: '8091',
          },
          {
            IDN3: 3057,
            LIBELLEN3: 'KALBOUSI',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3058,
            LIBELLEN3: 'KSIRAT',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3084,
            LIBELLEN3: 'MENZEL BOUZELFA',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3085,
            LIBELLEN3: 'OUED SIDI SAID',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3086,
            LIBELLEN3: 'ZENGOU',
            IDN2: 222,
            CODEP: '8010',
          },
          {
            IDN3: 3087,
            LIBELLEN3: 'ZENICH',
            IDN2: 222,
            CODEP: '8010',
          },
        ],
      },
      {
        ville: 223,
        villeName: 'MENZEL TEMIME',
        cites: [
          {
            IDN3: 3096,
            LIBELLEN3: 'BENI ABDELAZIZ',
            IDN2: 223,
            CODEP: '8080',
          },
          {
            IDN3: 3097,
            LIBELLEN3: 'CHATT EZZOUHOUR',
            IDN2: 223,
            CODEP: '8080',
          },
          {
            IDN3: 3098,
            LIBELLEN3: 'CITE ETTAAMIR',
            IDN2: 223,
            CODEP: '8080',
          },
          {
            IDN3: 3099,
            LIBELLEN3: 'CITE POPULAIRE',
            IDN2: 223,
            CODEP: '8080',
          },
          {
            IDN3: 3106,
            LIBELLEN3: 'CITE SIDI SALEM',
            IDN2: 223,
            CODEP: '8080',
          },
          {
            IDN3: 3107,
            LIBELLEN3: 'DAMOUS',
            IDN2: 223,
            CODEP: '8080',
          },
          {
            IDN3: 3108,
            LIBELLEN3: 'ECHAIBNI',
            IDN2: 223,
            CODEP: '8080',
          },
          {
            IDN3: 3089,
            LIBELLEN3: 'EL GHERIS',
            IDN2: 223,
            CODEP: '8080',
          },
          {
            IDN3: 3090,
            LIBELLEN3: 'EL GOBBA',
            IDN2: 223,
            CODEP: '8080',
          },
          {
            IDN3: 3094,
            LIBELLEN3: 'EL OUEDIANE',
            IDN2: 223,
            CODEP: '8054',
          },
          {
            IDN3: 3091,
            LIBELLEN3: 'GARSOLINE',
            IDN2: 223,
            CODEP: '8080',
          },
          {
            IDN3: 3092,
            LIBELLEN3: 'KEF CHAMI',
            IDN2: 223,
            CODEP: '8080',
          },
          {
            IDN3: 3103,
            LIBELLEN3: 'LAZIDINE',
            IDN2: 223,
            CODEP: '8080',
          },
          {
            IDN3: 3093,
            LIBELLEN3: 'LEBNA',
            IDN2: 223,
            CODEP: '8034',
          },
          {
            IDN3: 3109,
            LIBELLEN3: 'MENZEL HORR',
            IDN2: 223,
            CODEP: '8015',
          },
          {
            IDN3: 3104,
            LIBELLEN3: 'MENZEL ISSA',
            IDN2: 223,
            CODEP: '8080',
          },
          {
            IDN3: 3105,
            LIBELLEN3: 'MENZEL TEMIME',
            IDN2: 223,
            CODEP: '8080',
          },
          {
            IDN3: 3102,
            LIBELLEN3: 'RAININE',
            IDN2: 223,
            CODEP: '8083',
          },
          {
            IDN3: 3100,
            LIBELLEN3: 'SIDI ABDELMONEM',
            IDN2: 223,
            CODEP: '8080',
          },
          {
            IDN3: 3101,
            LIBELLEN3: 'SIDI JAMELEDDINE',
            IDN2: 223,
            CODEP: '8080',
          },
          {
            IDN3: 3095,
            LIBELLEN3: 'SKALBA',
            IDN2: 223,
            CODEP: '8064',
          },
        ],
      },
      {
        ville: 224,
        villeName: 'NABEUL',
        cites: [
          {
            IDN3: 3112,
            LIBELLEN3: 'AIN KEMICHA',
            IDN2: 224,
            CODEP: '8000',
          },
          {
            IDN3: 3113,
            LIBELLEN3: 'CITE ABDESSALEM EL BEHI',
            IDN2: 224,
            CODEP: '8000',
          },
          {
            IDN3: 3114,
            LIBELLEN3: 'CITE BAHROUN',
            IDN2: 224,
            CODEP: '8000',
          },
          {
            IDN3: 3115,
            LIBELLEN3: 'CITE CNRPS',
            IDN2: 224,
            CODEP: '8000',
          },
          {
            IDN3: 3116,
            LIBELLEN3: 'CITE COTE OR 1',
            IDN2: 224,
            CODEP: '8000',
          },
          {
            IDN3: 3117,
            LIBELLEN3: 'CITE COTE OR 2',
            IDN2: 224,
            CODEP: '8000',
          },
          {
            IDN3: 3118,
            LIBELLEN3: 'CITE DES ORANGES',
            IDN2: 224,
            CODEP: '8000',
          },
          {
            IDN3: 3119,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 224,
            CODEP: '8000',
          },
          {
            IDN3: 3120,
            LIBELLEN3: 'CITE EL BORJ 1',
            IDN2: 224,
            CODEP: '8000',
          },
          {
            IDN3: 3121,
            LIBELLEN3: 'CITE EL BORJ 2',
            IDN2: 224,
            CODEP: '8000',
          },
          {
            IDN3: 3135,
            LIBELLEN3: 'CITE EL MAHRSI 1',
            IDN2: 224,
            CODEP: '8062',
          },
          {
            IDN3: 3136,
            LIBELLEN3: 'CITE EL MAHRSI 2',
            IDN2: 224,
            CODEP: '8062',
          },
          {
            IDN3: 3137,
            LIBELLEN3: 'CITE EL MAHRSI 3',
            IDN2: 224,
            CODEP: '8062',
          },
          {
            IDN3: 3122,
            LIBELLEN3: 'CITE ENNACIM',
            IDN2: 224,
            CODEP: '8000',
          },
          {
            IDN3: 3123,
            LIBELLEN3: 'CITE ENNAJEH',
            IDN2: 224,
            CODEP: '8000',
          },
          {
            IDN3: 3124,
            LIBELLEN3: 'CITE ETTAAMIR',
            IDN2: 224,
            CODEP: '8000',
          },
          {
            IDN3: 3125,
            LIBELLEN3: 'CITE HALI',
            IDN2: 224,
            CODEP: '8000',
          },
          {
            IDN3: 3126,
            LIBELLEN3: 'CITE INDUSTRIELLE',
            IDN2: 224,
            CODEP: '8000',
          },
          {
            IDN3: 3127,
            LIBELLEN3: 'CITE JASMINS SIMPAR',
            IDN2: 224,
            CODEP: '8000',
          },
          {
            IDN3: 3128,
            LIBELLEN3: 'CITE MOHSEN LIMAM',
            IDN2: 224,
            CODEP: '8000',
          },
          {
            IDN3: 3129,
            LIBELLEN3: 'CITE SIDI AMOR',
            IDN2: 224,
            CODEP: '8000',
          },
          {
            IDN3: 3130,
            LIBELLEN3: 'CITE SIDI MOUSSA',
            IDN2: 224,
            CODEP: '8000',
          },
          {
            IDN3: 3131,
            LIBELLEN3: 'CITE UNIVERSITAIRE',
            IDN2: 224,
            CODEP: '8000',
          },
          {
            IDN3: 3138,
            LIBELLEN3: 'HENCHIR EL HAOUARIA',
            IDN2: 224,
            CODEP: '8062',
          },
          {
            IDN3: 3132,
            LIBELLEN3: 'HENCHIR GORT',
            IDN2: 224,
            CODEP: '8000',
          },
          {
            IDN3: 3133,
            LIBELLEN3: 'MIMOSAS VILLAS ET BUNGALOWS',
            IDN2: 224,
            CODEP: '8000',
          },
          {
            IDN3: 3134,
            LIBELLEN3: 'NABEUL',
            IDN2: 224,
            CODEP: '8000',
          },
          {
            IDN3: 3139,
            LIBELLEN3: 'NABEUL THAMEUR',
            IDN2: 224,
            CODEP: '8062',
          },
          {
            IDN3: 3110,
            LIBELLEN3: 'RESIDENCE DENENE',
            IDN2: 224,
            CODEP: '8062',
          },
          {
            IDN3: 3111,
            LIBELLEN3: 'RESIDENCE OASIS BLEU',
            IDN2: 224,
            CODEP: '8062',
          },
        ],
      },
      {
        ville: 220,
        villeName: 'SOLIMAN',
        cites: [
          {
            IDN3: 3029,
            LIBELLEN3: 'BOU CHARRAY',
            IDN2: 220,
            CODEP: '8063',
          },
          {
            IDN3: 3031,
            LIBELLEN3: 'CHERIFATE',
            IDN2: 220,
            CODEP: '8051',
          },
          {
            IDN3: 3040,
            LIBELLEN3: 'CITE GHARNATA',
            IDN2: 220,
            CODEP: '8020',
          },
          {
            IDN3: 3032,
            LIBELLEN3: 'CITE HOTEL DES ANDALOUS',
            IDN2: 220,
            CODEP: '8020',
          },
          {
            IDN3: 3033,
            LIBELLEN3: 'CITE NOUVELLE',
            IDN2: 220,
            CODEP: '8020',
          },
          {
            IDN3: 3034,
            LIBELLEN3: 'CITE SPROLS',
            IDN2: 220,
            CODEP: '8020',
          },
          {
            IDN3: 3035,
            LIBELLEN3: 'DHAHRI',
            IDN2: 220,
            CODEP: '8020',
          },
          {
            IDN3: 3036,
            LIBELLEN3: 'EL ABEBSA',
            IDN2: 220,
            CODEP: '8020',
          },
          {
            IDN3: 3037,
            LIBELLEN3: 'EL MARJA',
            IDN2: 220,
            CODEP: '8020',
          },
          {
            IDN3: 3039,
            LIBELLEN3: 'KORBOUS',
            IDN2: 220,
            CODEP: '8041',
          },
          {
            IDN3: 3030,
            LIBELLEN3: 'MERAISSA',
            IDN2: 220,
            CODEP: '8073',
          },
          {
            IDN3: 3038,
            LIBELLEN3: 'SOLIMAN',
            IDN2: 220,
            CODEP: '8020',
          },
        ],
      },
      {
        ville: 221,
        villeName: 'TAKELSA',
        cites: [
          {
            IDN3: 3041,
            LIBELLEN3: 'BIR MROUA',
            IDN2: 221,
            CODEP: '8031',
          },
          {
            IDN3: 3042,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 221,
            CODEP: '8031',
          },
          {
            IDN3: 3043,
            LIBELLEN3: 'CITE POPULAIRE',
            IDN2: 221,
            CODEP: '8031',
          },
          {
            IDN3: 3044,
            LIBELLEN3: 'DOUALA',
            IDN2: 221,
            CODEP: '8031',
          },
          {
            IDN3: 3045,
            LIBELLEN3: 'EL BEKEKCHA',
            IDN2: 221,
            CODEP: '8031',
          },
          {
            IDN3: 3046,
            LIBELLEN3: 'EL BRIJ',
            IDN2: 221,
            CODEP: '8031',
          },
          {
            IDN3: 3047,
            LIBELLEN3: 'EL MAISRA',
            IDN2: 221,
            CODEP: '8031',
          },
          {
            IDN3: 3048,
            LIBELLEN3: 'GHARS  MRAD',
            IDN2: 221,
            CODEP: '8031',
          },
          {
            IDN3: 3049,
            LIBELLEN3: 'HENCHIR CHEDLI',
            IDN2: 221,
            CODEP: '8031',
          },
          {
            IDN3: 3050,
            LIBELLEN3: 'OUED EL ABID',
            IDN2: 221,
            CODEP: '8031',
          },
          {
            IDN3: 3051,
            LIBELLEN3: 'RAMEL',
            IDN2: 221,
            CODEP: '8031',
          },
          {
            IDN3: 3052,
            LIBELLEN3: 'RTIBA',
            IDN2: 221,
            CODEP: '8031',
          },
          {
            IDN3: 3053,
            LIBELLEN3: 'SIDI ISSA',
            IDN2: 221,
            CODEP: '8031',
          },
          {
            IDN3: 3054,
            LIBELLEN3: 'TAKELSA',
            IDN2: 221,
            CODEP: '8031',
          },
        ],
      },
    ],
  },
  {
    govLabel: 'SFAX',
    govId: 17,
    villes: [
      {
        ville: 240,
        villeName: 'AGAREB',
        cites: [
          {
            IDN3: 3480,
            LIBELLEN3: 'AGAREB',
            IDN2: 240,
            CODEP: '3030',
          },
          {
            IDN3: 3481,
            LIBELLEN3: 'BEN SAHLOUN',
            IDN2: 240,
            CODEP: '3030',
          },
          {
            IDN3: 3482,
            LIBELLEN3: 'CITE 2 MARS',
            IDN2: 240,
            CODEP: '3030',
          },
          {
            IDN3: 3483,
            LIBELLEN3: 'CITE 2 MARS 1',
            IDN2: 240,
            CODEP: '3030',
          },
          {
            IDN3: 3484,
            LIBELLEN3: 'CITE 2 MARS 2',
            IDN2: 240,
            CODEP: '3030',
          },
          {
            IDN3: 3485,
            LIBELLEN3: 'CITE CNEL',
            IDN2: 240,
            CODEP: '3030',
          },
          {
            IDN3: 3486,
            LIBELLEN3: 'CITE EL IZDIHAR',
            IDN2: 240,
            CODEP: '3030',
          },
          {
            IDN3: 3487,
            LIBELLEN3: 'CITE EL MENZAH',
            IDN2: 240,
            CODEP: '3030',
          },
          {
            IDN3: 3488,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 240,
            CODEP: '3030',
          },
          {
            IDN3: 3489,
            LIBELLEN3: 'CITE SNIT',
            IDN2: 240,
            CODEP: '3030',
          },
          {
            IDN3: 3490,
            LIBELLEN3: 'EL MAHROUGA',
            IDN2: 240,
            CODEP: '3030',
          },
          {
            IDN3: 3491,
            LIBELLEN3: 'EL MARAANIA',
            IDN2: 240,
            CODEP: '3030',
          },
          {
            IDN3: 3492,
            LIBELLEN3: 'EL MSADGA',
            IDN2: 240,
            CODEP: '3030',
          },
          {
            IDN3: 3499,
            LIBELLEN3: 'GARGOUR',
            IDN2: 240,
            CODEP: '3037',
          },
          {
            IDN3: 3500,
            LIBELLEN3: 'JEBERNA',
            IDN2: 240,
            CODEP: '3057',
          },
          {
            IDN3: 3493,
            LIBELLEN3: 'MERKEZ ATTIA',
            IDN2: 240,
            CODEP: '3030',
          },
          {
            IDN3: 3494,
            LIBELLEN3: 'MERKEZ BOU LEDHIEB',
            IDN2: 240,
            CODEP: '3030',
          },
          {
            IDN3: 3495,
            LIBELLEN3: 'MERKEZ JOUAOUDA',
            IDN2: 240,
            CODEP: '3030',
          },
          {
            IDN3: 3501,
            LIBELLEN3: 'MERKEZ SGHAR',
            IDN2: 240,
            CODEP: '3075',
          },
          {
            IDN3: 3496,
            LIBELLEN3: 'MERKEZ TIJANI',
            IDN2: 240,
            CODEP: '3030',
          },
          {
            IDN3: 3497,
            LIBELLEN3: 'TRAKA',
            IDN2: 240,
            CODEP: '3030',
          },
          {
            IDN3: 3498,
            LIBELLEN3: 'ZELIANA',
            IDN2: 240,
            CODEP: '3030',
          },
        ],
      },
      {
        ville: 239,
        villeName: 'BIR ALI BEN KHELIFA',
        cites: [
          {
            IDN3: 3472,
            LIBELLEN3: 'BIR ALI BEN KHELIFA',
            IDN2: 239,
            CODEP: '3040',
          },
          {
            IDN3: 3473,
            LIBELLEN3: 'BIR OULED MAHMOUD',
            IDN2: 239,
            CODEP: '3040',
          },
          {
            IDN3: 3474,
            LIBELLEN3: 'CHERAIET',
            IDN2: 239,
            CODEP: '3040',
          },
          {
            IDN3: 3475,
            LIBELLEN3: 'CITE DES ROSES 1',
            IDN2: 239,
            CODEP: '3040',
          },
          {
            IDN3: 3476,
            LIBELLEN3: 'CITE DES ROSES 2',
            IDN2: 239,
            CODEP: '3040',
          },
          {
            IDN3: 3477,
            LIBELLEN3: 'CITE EL FATEH',
            IDN2: 239,
            CODEP: '3040',
          },
          {
            IDN3: 3478,
            LIBELLEN3: 'CITE EL MENZEL',
            IDN2: 239,
            CODEP: '3040',
          },
          {
            IDN3: 3479,
            LIBELLEN3: 'EL AOUYED',
            IDN2: 239,
            CODEP: '3040',
          },
          {
            IDN3: 3468,
            LIBELLEN3: 'EL GUENDOUL',
            IDN2: 239,
            CODEP: '3082',
          },
          {
            IDN3: 3459,
            LIBELLEN3: 'EL OUDRANE',
            IDN2: 239,
            CODEP: '3040',
          },
          {
            IDN3: 3460,
            LIBELLEN3: 'LETAIFA',
            IDN2: 239,
            CODEP: '3040',
          },
          {
            IDN3: 3470,
            LIBELLEN3: 'MERKEZ EL HAMROUNI',
            IDN2: 239,
            CODEP: '3040',
          },
          {
            IDN3: 3471,
            LIBELLEN3: 'OUED REKHAM',
            IDN2: 239,
            CODEP: '3040',
          },
          {
            IDN3: 3469,
            LIBELLEN3: 'OUM CHOUCHA',
            IDN2: 239,
            CODEP: '3085',
          },
          {
            IDN3: 3461,
            LIBELLEN3: 'RAS FARTOUT',
            IDN2: 239,
            CODEP: '3040',
          },
          {
            IDN3: 3462,
            LIBELLEN3: 'RAS SOUINIA',
            IDN2: 239,
            CODEP: '3040',
          },
          {
            IDN3: 3463,
            LIBELLEN3: 'REBAYA',
            IDN2: 239,
            CODEP: '3040',
          },
          {
            IDN3: 3458,
            LIBELLEN3: 'REBAYA SIDI DHAHER',
            IDN2: 239,
            CODEP: '3097',
          },
          {
            IDN3: 3464,
            LIBELLEN3: 'SIDI DHAHER',
            IDN2: 239,
            CODEP: '3040',
          },
          {
            IDN3: 3465,
            LIBELLEN3: 'SIDI MANSOUR',
            IDN2: 239,
            CODEP: '3040',
          },
          {
            IDN3: 3466,
            LIBELLEN3: 'SOUINIA',
            IDN2: 239,
            CODEP: '3040',
          },
          {
            IDN3: 3467,
            LIBELLEN3: 'TOULB',
            IDN2: 239,
            CODEP: '3040',
          },
        ],
      },
      {
        ville: 238,
        villeName: 'EL AMRA',
        cites: [
          {
            IDN3: 3440,
            LIBELLEN3: 'BELIANA',
            IDN2: 238,
            CODEP: '3087',
          },
          {
            IDN3: 3441,
            LIBELLEN3: 'CITE 7 NOVEMBRE',
            IDN2: 238,
            CODEP: '3036',
          },
          {
            IDN3: 3442,
            LIBELLEN3: 'CITE EL HMERNA',
            IDN2: 238,
            CODEP: '3036',
          },
          {
            IDN3: 3443,
            LIBELLEN3: 'CITE EZZAYATINE',
            IDN2: 238,
            CODEP: '3036',
          },
          {
            IDN3: 3444,
            LIBELLEN3: 'CITE HOUMA CHARGUIA',
            IDN2: 238,
            CODEP: '3036',
          },
          {
            IDN3: 3445,
            LIBELLEN3: 'CITE POPULAIRE 1',
            IDN2: 238,
            CODEP: '3036',
          },
          {
            IDN3: 3446,
            LIBELLEN3: 'CITE POPULAIRE 2',
            IDN2: 238,
            CODEP: '3036',
          },
          {
            IDN3: 3447,
            LIBELLEN3: 'CITE POPULAIRE 3',
            IDN2: 238,
            CODEP: '3036',
          },
          {
            IDN3: 3456,
            LIBELLEN3: 'DHRAA BEN ZIAD',
            IDN2: 238,
            CODEP: '3095',
          },
          {
            IDN3: 3448,
            LIBELLEN3: 'EL AMRA',
            IDN2: 238,
            CODEP: '3036',
          },
          {
            IDN3: 3449,
            LIBELLEN3: 'EL FIDH',
            IDN2: 238,
            CODEP: '3036',
          },
          {
            IDN3: 3457,
            LIBELLEN3: 'EL KETATNA',
            IDN2: 238,
            CODEP: '3095',
          },
          {
            IDN3: 3451,
            LIBELLEN3: 'EL MHARA',
            IDN2: 238,
            CODEP: '3046',
          },
          {
            IDN3: 3453,
            LIBELLEN3: 'ESSAADI',
            IDN2: 238,
            CODEP: '3066',
          },
          {
            IDN3: 3452,
            LIBELLEN3: 'ESSALEM',
            IDN2: 238,
            CODEP: '3046',
          },
          {
            IDN3: 3450,
            LIBELLEN3: 'IFFI',
            IDN2: 238,
            CODEP: '3036',
          },
          {
            IDN3: 3454,
            LIBELLEN3: 'MERKEZ EL KHEMIRI',
            IDN2: 238,
            CODEP: '3066',
          },
          {
            IDN3: 3455,
            LIBELLEN3: 'OUED LAACHECH',
            IDN2: 238,
            CODEP: '3066',
          },
        ],
      },
      {
        ville: 235,
        villeName: 'EL HENCHA',
        cites: [
          {
            IDN3: 3402,
            LIBELLEN3: 'BIR CHAABA',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3410,
            LIBELLEN3: 'BIR LAHMAR',
            IDN2: 235,
            CODEP: '3043',
          },
          {
            IDN3: 3409,
            LIBELLEN3: 'BIR SALAH',
            IDN2: 235,
            CODEP: '3033',
          },
          {
            IDN3: 3390,
            LIBELLEN3: 'CITE ALGERIE',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3391,
            LIBELLEN3: 'CITE CHERIF',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3392,
            LIBELLEN3: 'CITE COMMERCIALE 1',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3393,
            LIBELLEN3: 'CITE COMMERCIALE 2',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3394,
            LIBELLEN3: 'CITE EL FATEH',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3395,
            LIBELLEN3: 'CITE EL FHEIL',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3396,
            LIBELLEN3: 'CITE EL HANCHA EST',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3377,
            LIBELLEN3: 'CITE EL HANCHA OUEST',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3378,
            LIBELLEN3: 'CITE EL HANCHA SUD',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3379,
            LIBELLEN3: 'CITE EL IFA',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3380,
            LIBELLEN3: 'CITE EL KOUIFLA',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3381,
            LIBELLEN3: 'CITE EL OJNA',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3382,
            LIBELLEN3: 'CITE ENVIRONNEMENT',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3383,
            LIBELLEN3: 'CITE OULED AICHA',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3384,
            LIBELLEN3: 'CITE OUM SALAH',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3411,
            LIBELLEN3: 'CITE POPULAIRE',
            IDN2: 235,
            CODEP: '3043',
          },
          {
            IDN3: 3412,
            LIBELLEN3: 'DOUKHANE',
            IDN2: 235,
            CODEP: '3043',
          },
          {
            IDN3: 3385,
            LIBELLEN3: 'EL GHIEB',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3376,
            LIBELLEN3: 'EL GHRABA',
            IDN2: 235,
            CODEP: '3043',
          },
          {
            IDN3: 3386,
            LIBELLEN3: 'EL GUETATSA',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3387,
            LIBELLEN3: 'EL HACHANA',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3388,
            LIBELLEN3: 'EL HAJJARA',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3403,
            LIBELLEN3: 'EL HELALFA',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3404,
            LIBELLEN3: 'EL HENCHA',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3413,
            LIBELLEN3: 'EL KHECHERMA',
            IDN2: 235,
            CODEP: '3043',
          },
          {
            IDN3: 3389,
            LIBELLEN3: 'ESSEGHARA',
            IDN2: 235,
            CODEP: '3043',
          },
          {
            IDN3: 3397,
            LIBELLEN3: 'HENCHIR EL JOUB',
            IDN2: 235,
            CODEP: '3043',
          },
          {
            IDN3: 3405,
            LIBELLEN3: 'HENCHIR EL MOSBAH',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3406,
            LIBELLEN3: 'JALAILA',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3398,
            LIBELLEN3: 'MERKEZ CHOUCHA',
            IDN2: 235,
            CODEP: '3043',
          },
          {
            IDN3: 3407,
            LIBELLEN3: 'OULED AMOR',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3408,
            LIBELLEN3: 'OULED TAHAR',
            IDN2: 235,
            CODEP: '3010',
          },
          {
            IDN3: 3401,
            LIBELLEN3: 'SIDI HASSEN',
            IDN2: 235,
            CODEP: '3053',
          },
          {
            IDN3: 3399,
            LIBELLEN3: 'SIDI MHAMED BEN AMOR',
            IDN2: 235,
            CODEP: '3043',
          },
          {
            IDN3: 3400,
            LIBELLEN3: 'SOUALMIA',
            IDN2: 235,
            CODEP: '3043',
          },
        ],
      },
      {
        ville: 236,
        villeName: 'ESSKHIRA',
        cites: [
          {
            IDN3: 3429,
            LIBELLEN3: 'BOU SAID',
            IDN2: 236,
            CODEP: '3050',
          },
          {
            IDN3: 3414,
            LIBELLEN3: 'CHERAFRA',
            IDN2: 236,
            CODEP: '3050',
          },
          {
            IDN3: 3415,
            LIBELLEN3: 'EL GHERAIRIA',
            IDN2: 236,
            CODEP: '3050',
          },
          {
            IDN3: 3428,
            LIBELLEN3: 'EL HICHA',
            IDN2: 236,
            CODEP: '3050',
          },
          {
            IDN3: 3416,
            LIBELLEN3: 'EL MAGHDHIA',
            IDN2: 236,
            CODEP: '3050',
          },
          {
            IDN3: 3417,
            LIBELLEN3: 'EL MELHA',
            IDN2: 236,
            CODEP: '3050',
          },
          {
            IDN3: 3418,
            LIBELLEN3: 'ESSKHIRA',
            IDN2: 236,
            CODEP: '3050',
          },
          {
            IDN3: 3419,
            LIBELLEN3: 'FONDOUK CHIBANI',
            IDN2: 236,
            CODEP: '3050',
          },
          {
            IDN3: 3420,
            LIBELLEN3: 'FRICHETTE',
            IDN2: 236,
            CODEP: '3050',
          },
          {
            IDN3: 3421,
            LIBELLEN3: 'JEROUALA',
            IDN2: 236,
            CODEP: '3050',
          },
          {
            IDN3: 3422,
            LIBELLEN3: 'KENITRA',
            IDN2: 236,
            CODEP: '3050',
          },
          {
            IDN3: 3423,
            LIBELLEN3: 'NAOUAL',
            IDN2: 236,
            CODEP: '3050',
          },
          {
            IDN3: 3424,
            LIBELLEN3: 'OULED HAJ MOUSSA',
            IDN2: 236,
            CODEP: '3050',
          },
          {
            IDN3: 3427,
            LIBELLEN3: 'SBIH',
            IDN2: 236,
            CODEP: '3073',
          },
          {
            IDN3: 3425,
            LIBELLEN3: 'SIDI MHAMED NOUIGUEZ',
            IDN2: 236,
            CODEP: '3050',
          },
          {
            IDN3: 3426,
            LIBELLEN3: 'SOUANI',
            IDN2: 236,
            CODEP: '3050',
          },
        ],
      },
      {
        ville: 237,
        villeName: 'GHRAIBA',
        cites: [
          {
            IDN3: 3431,
            LIBELLEN3: 'BELHOUICHETTE',
            IDN2: 237,
            CODEP: '3024',
          },
          {
            IDN3: 3432,
            LIBELLEN3: 'CHAAL',
            IDN2: 237,
            CODEP: '3024',
          },
          {
            IDN3: 3437,
            LIBELLEN3: 'EL HAMMAM',
            IDN2: 237,
            CODEP: '3034',
          },
          {
            IDN3: 3438,
            LIBELLEN3: 'ESSOUDANE',
            IDN2: 237,
            CODEP: '3034',
          },
          {
            IDN3: 3433,
            LIBELLEN3: 'ETTAKADDOUM',
            IDN2: 237,
            CODEP: '3024',
          },
          {
            IDN3: 3439,
            LIBELLEN3: 'GHRAIBA',
            IDN2: 237,
            CODEP: '3034',
          },
          {
            IDN3: 3430,
            LIBELLEN3: 'RABTA',
            IDN2: 237,
            CODEP: '3024',
          },
          {
            IDN3: 3435,
            LIBELLEN3: 'SKAINA',
            IDN2: 237,
            CODEP: '3024',
          },
          {
            IDN3: 3434,
            LIBELLEN3: 'SMARA',
            IDN2: 237,
            CODEP: '3024',
          },
          {
            IDN3: 3436,
            LIBELLEN3: 'TARFAOUI',
            IDN2: 237,
            CODEP: '3024',
          },
        ],
      },
      {
        ville: 247,
        villeName: 'JEBENIANA',
        cites: [
          {
            IDN3: 3653,
            LIBELLEN3: 'AGUEGCHA',
            IDN2: 247,
            CODEP: '3026',
          },
          {
            IDN3: 3658,
            LIBELLEN3: 'AIN EL GHEZAL',
            IDN2: 247,
            CODEP: '3080',
          },
          {
            IDN3: 3672,
            LIBELLEN3: 'AJENGA',
            IDN2: 247,
            CODEP: '3086',
          },
          {
            IDN3: 3659,
            LIBELLEN3: 'BLITECH',
            IDN2: 247,
            CODEP: '3080',
          },
          {
            IDN3: 3660,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 247,
            CODEP: '3080',
          },
          {
            IDN3: 3661,
            LIBELLEN3: 'CITE EL FATEH',
            IDN2: 247,
            CODEP: '3080',
          },
          {
            IDN3: 3662,
            LIBELLEN3: 'CITE JARDINS',
            IDN2: 247,
            CODEP: '3080',
          },
          {
            IDN3: 3657,
            LIBELLEN3: 'DOUAR LOUATA',
            IDN2: 247,
            CODEP: '3077',
          },
          {
            IDN3: 3663,
            LIBELLEN3: 'EL BOTRIA',
            IDN2: 247,
            CODEP: '3080',
          },
          {
            IDN3: 3664,
            LIBELLEN3: 'EL GHERASA',
            IDN2: 247,
            CODEP: '3080',
          },
          {
            IDN3: 3665,
            LIBELLEN3: 'EL GUELALJA',
            IDN2: 247,
            CODEP: '3080',
          },
          {
            IDN3: 3652,
            LIBELLEN3: 'EL LOUZA',
            IDN2: 247,
            CODEP: '3016',
          },
          {
            IDN3: 3654,
            LIBELLEN3: 'HAZEG',
            IDN2: 247,
            CODEP: '3026',
          },
          {
            IDN3: 3666,
            LIBELLEN3: 'HENCHIR EL EUCH',
            IDN2: 247,
            CODEP: '3080',
          },
          {
            IDN3: 3667,
            LIBELLEN3: 'JEBENIANA',
            IDN2: 247,
            CODEP: '3080',
          },
          {
            IDN3: 3668,
            LIBELLEN3: 'OULED AHMED',
            IDN2: 247,
            CODEP: '3080',
          },
          {
            IDN3: 3656,
            LIBELLEN3: 'OULED BOUSMIR',
            IDN2: 247,
            CODEP: '3056',
          },
          {
            IDN3: 3655,
            LIBELLEN3: 'OULED MNASSER',
            IDN2: 247,
            CODEP: '3026',
          },
          {
            IDN3: 3669,
            LIBELLEN3: 'OULED MSALLEM EDKHAL',
            IDN2: 247,
            CODEP: '3080',
          },
          {
            IDN3: 3670,
            LIBELLEN3: 'OULED YOUSSEF',
            IDN2: 247,
            CODEP: '3080',
          },
          {
            IDN3: 3671,
            LIBELLEN3: 'SIDI MSARRA',
            IDN2: 247,
            CODEP: '3080',
          },
        ],
      },
      {
        ville: 248,
        villeName: 'KERKENAH',
        cites: [
          {
            IDN3: 3680,
            LIBELLEN3: 'CHERGUI',
            IDN2: 248,
            CODEP: '3070',
          },
          {
            IDN3: 3681,
            LIBELLEN3: 'CITE ANCIENNE KELLALINE',
            IDN2: 248,
            CODEP: '3070',
          },
          {
            IDN3: 3682,
            LIBELLEN3: 'CITE BOUAID KELLALINE',
            IDN2: 248,
            CODEP: '3070',
          },
          {
            IDN3: 3683,
            LIBELLEN3: 'CITE CNEL',
            IDN2: 248,
            CODEP: '3070',
          },
          {
            IDN3: 3684,
            LIBELLEN3: 'CITE DAR OUM DLEL',
            IDN2: 248,
            CODEP: '3070',
          },
          {
            IDN3: 3685,
            LIBELLEN3: 'CITE DOUIRETTE',
            IDN2: 248,
            CODEP: '3070',
          },
          {
            IDN3: 3686,
            LIBELLEN3: 'CITE EL AZEZBA',
            IDN2: 248,
            CODEP: '3070',
          },
          {
            IDN3: 3687,
            LIBELLEN3: 'CITE EL MAHJAR',
            IDN2: 248,
            CODEP: '3070',
          },
          {
            IDN3: 3673,
            LIBELLEN3: 'CITE GROUPE 1',
            IDN2: 248,
            CODEP: '3015',
          },
          {
            IDN3: 3674,
            LIBELLEN3: 'CITE GROUPE 2',
            IDN2: 248,
            CODEP: '3015',
          },
          {
            IDN3: 3688,
            LIBELLEN3: 'CITE HOUMET GLACE',
            IDN2: 248,
            CODEP: '3070',
          },
          {
            IDN3: 3689,
            LIBELLEN3: 'CITE NOUVELLE KELLALINE',
            IDN2: 248,
            CODEP: '3070',
          },
          {
            IDN3: 3690,
            LIBELLEN3: 'CITE OURAND',
            IDN2: 248,
            CODEP: '3070',
          },
          {
            IDN3: 3691,
            LIBELLEN3: 'CITE POPULAIRE',
            IDN2: 248,
            CODEP: '3070',
          },
          {
            IDN3: 3692,
            LIBELLEN3: 'CITE RAKHANI',
            IDN2: 248,
            CODEP: '3070',
          },
          {
            IDN3: 3693,
            LIBELLEN3: 'EL ABBASSIA',
            IDN2: 248,
            CODEP: '3070',
          },
          {
            IDN3: 3677,
            LIBELLEN3: 'EL ATTAYA',
            IDN2: 248,
            CODEP: '3035',
          },
          {
            IDN3: 3679,
            LIBELLEN3: 'EL KRATEN',
            IDN2: 248,
            CODEP: '3055',
          },
          {
            IDN3: 3678,
            LIBELLEN3: 'ENNAJET',
            IDN2: 248,
            CODEP: '3045',
          },
          {
            IDN3: 3694,
            LIBELLEN3: 'JOUABER',
            IDN2: 248,
            CODEP: '3070',
          },
          {
            IDN3: 3695,
            LIBELLEN3: 'KELLABINE',
            IDN2: 248,
            CODEP: '3070',
          },
          {
            IDN3: 3696,
            LIBELLEN3: 'KERKENAH',
            IDN2: 248,
            CODEP: '3070',
          },
          {
            IDN3: 3675,
            LIBELLEN3: 'MELLITA',
            IDN2: 248,
            CODEP: '3015',
          },
          {
            IDN3: 3697,
            LIBELLEN3: 'MELLITA OULED BOU  ALI',
            IDN2: 248,
            CODEP: '3070',
          },
          {
            IDN3: 3698,
            LIBELLEN3: 'OULED BOU ALI',
            IDN2: 248,
            CODEP: '3070',
          },
          {
            IDN3: 3676,
            LIBELLEN3: 'OULED KACEM',
            IDN2: 248,
            CODEP: '3025',
          },
          {
            IDN3: 3699,
            LIBELLEN3: 'OULED YANEG',
            IDN2: 248,
            CODEP: '3070',
          },
        ],
      },
      {
        ville: 243,
        villeName: 'MAHRAS',
        cites: [
          {
            IDN3: 3578,
            LIBELLEN3: 'BELHOUCHETTE',
            IDN2: 243,
            CODEP: '3060',
          },
          {
            IDN3: 3579,
            LIBELLEN3: 'CHAAL GARE',
            IDN2: 243,
            CODEP: '3060',
          },
          {
            IDN3: 3580,
            LIBELLEN3: 'CHAFFAR',
            IDN2: 243,
            CODEP: '3060',
          },
          {
            IDN3: 3581,
            LIBELLEN3: 'CITE BOURGUIBA',
            IDN2: 243,
            CODEP: '3060',
          },
          {
            IDN3: 3582,
            LIBELLEN3: 'CITE EL BOUHALI',
            IDN2: 243,
            CODEP: '3060',
          },
          {
            IDN3: 3583,
            LIBELLEN3: 'CITE EL GUIDIRA',
            IDN2: 243,
            CODEP: '3060',
          },
          {
            IDN3: 3584,
            LIBELLEN3: 'CITE ENNAJAH',
            IDN2: 243,
            CODEP: '3060',
          },
          {
            IDN3: 3585,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 243,
            CODEP: '3060',
          },
          {
            IDN3: 3586,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 243,
            CODEP: '3060',
          },
          {
            IDN3: 3572,
            LIBELLEN3: 'CITE FLEMECH',
            IDN2: 243,
            CODEP: '3060',
          },
          {
            IDN3: 3573,
            LIBELLEN3: 'CITE HUILLERIE',
            IDN2: 243,
            CODEP: '3060',
          },
          {
            IDN3: 3574,
            LIBELLEN3: 'EL HACHICHINIA',
            IDN2: 243,
            CODEP: '3060',
          },
          {
            IDN3: 3575,
            LIBELLEN3: 'EL MOUESSETTE',
            IDN2: 243,
            CODEP: '3060',
          },
          {
            IDN3: 3577,
            LIBELLEN3: 'EL MOUROUJ',
            IDN2: 243,
            CODEP: '3044',
          },
          {
            IDN3: 3570,
            LIBELLEN3: 'MAHRAS',
            IDN2: 243,
            CODEP: '3060',
          },
          {
            IDN3: 3576,
            LIBELLEN3: 'NEKTA',
            IDN2: 243,
            CODEP: '3044',
          },
          {
            IDN3: 3571,
            LIBELLEN3: 'SMARA',
            IDN2: 243,
            CODEP: '3060',
          },
        ],
      },
      {
        ville: 244,
        villeName: 'MENZEL CHAKER',
        cites: [
          {
            IDN3: 3588,
            LIBELLEN3: 'BECHKA',
            IDN2: 244,
            CODEP: '3020',
          },
          {
            IDN3: 3609,
            LIBELLEN3: 'BOU JARBOU',
            IDN2: 244,
            CODEP: '3092',
          },
          {
            IDN3: 3587,
            LIBELLEN3: 'BOU THADI',
            IDN2: 244,
            CODEP: '3014',
          },
          {
            IDN3: 3589,
            LIBELLEN3: 'CHAALEB',
            IDN2: 244,
            CODEP: '3020',
          },
          {
            IDN3: 3590,
            LIBELLEN3: 'CITE 7 NOVEMBRE',
            IDN2: 244,
            CODEP: '3020',
          },
          {
            IDN3: 3591,
            LIBELLEN3: 'CITE DE LA GARE',
            IDN2: 244,
            CODEP: '3020',
          },
          {
            IDN3: 3592,
            LIBELLEN3: 'CITE EL HABIB',
            IDN2: 244,
            CODEP: '3020',
          },
          {
            IDN3: 3593,
            LIBELLEN3: 'CITE ERRAOUDHA',
            IDN2: 244,
            CODEP: '3020',
          },
          {
            IDN3: 3594,
            LIBELLEN3: 'CITE ETTAHRIR',
            IDN2: 244,
            CODEP: '3020',
          },
          {
            IDN3: 3595,
            LIBELLEN3: 'CITE HABIB BOURGUIBA',
            IDN2: 244,
            CODEP: '3020',
          },
          {
            IDN3: 3596,
            LIBELLEN3: 'CITE HABIB THAMEUR',
            IDN2: 244,
            CODEP: '3020',
          },
          {
            IDN3: 3597,
            LIBELLEN3: 'CITE MERKEZ SELLAMI',
            IDN2: 244,
            CODEP: '3020',
          },
          {
            IDN3: 3598,
            LIBELLEN3: 'CITE TAIEB MHIRI',
            IDN2: 244,
            CODEP: '3020',
          },
          {
            IDN3: 3599,
            LIBELLEN3: 'EL AITHA',
            IDN2: 244,
            CODEP: '3020',
          },
          {
            IDN3: 3600,
            LIBELLEN3: 'HAI KACEM',
            IDN2: 244,
            CODEP: '3020',
          },
          {
            IDN3: 3601,
            LIBELLEN3: 'HAJ KACEM 1',
            IDN2: 244,
            CODEP: '3020',
          },
          {
            IDN3: 3602,
            LIBELLEN3: 'HAJ KACEM 2',
            IDN2: 244,
            CODEP: '3020',
          },
          {
            IDN3: 3603,
            LIBELLEN3: 'KSAR ERRIH',
            IDN2: 244,
            CODEP: '3020',
          },
          {
            IDN3: 3604,
            LIBELLEN3: 'LIMAYA',
            IDN2: 244,
            CODEP: '3020',
          },
          {
            IDN3: 3605,
            LIBELLEN3: 'MENZEL HEDI CHAKER',
            IDN2: 244,
            CODEP: '3020',
          },
          {
            IDN3: 3606,
            LIBELLEN3: 'OUED LAZRAG',
            IDN2: 244,
            CODEP: '3020',
          },
          {
            IDN3: 3607,
            LIBELLEN3: 'TLIL EL AJLA',
            IDN2: 244,
            CODEP: '3020',
          },
          {
            IDN3: 3608,
            LIBELLEN3: 'ZAIBET',
            IDN2: 244,
            CODEP: '3020',
          },
        ],
      },
      {
        ville: 242,
        villeName: 'SAKIET EDDAIER',
        cites: [
          {
            IDN3: 3569,
            LIBELLEN3: 'CITE BOURGUIBA',
            IDN2: 242,
            CODEP: '3094',
          },
          {
            IDN3: 3554,
            LIBELLEN3: 'CITE DE LE LIBERTE',
            IDN2: 242,
            CODEP: '3011',
          },
          {
            IDN3: 3559,
            LIBELLEN3: 'CITE EL AFRANE',
            IDN2: 242,
            CODEP: '3054',
          },
          {
            IDN3: 3555,
            LIBELLEN3: 'CITE EL AMEL',
            IDN2: 242,
            CODEP: '3011',
          },
          {
            IDN3: 3556,
            LIBELLEN3: 'CITE EL HAFFARA',
            IDN2: 242,
            CODEP: '3011',
          },
          {
            IDN3: 3566,
            LIBELLEN3: 'CITE EL MALLEK',
            IDN2: 242,
            CODEP: '3081',
          },
          {
            IDN3: 3561,
            LIBELLEN3: 'CITE ENNADHOUR',
            IDN2: 242,
            CODEP: '3061',
          },
          {
            IDN3: 3562,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 242,
            CODEP: '3061',
          },
          {
            IDN3: 3563,
            LIBELLEN3: 'CITE NOUVELLE',
            IDN2: 242,
            CODEP: '3061',
          },
          {
            IDN3: 3565,
            LIBELLEN3: 'EL KHALIJ',
            IDN2: 242,
            CODEP: '3063',
          },
          {
            IDN3: 3567,
            LIBELLEN3: 'ESSAADI',
            IDN2: 242,
            CODEP: '3081',
          },
          {
            IDN3: 3568,
            LIBELLEN3: 'ESSALTANIA',
            IDN2: 242,
            CODEP: '3081',
          },
          {
            IDN3: 3557,
            LIBELLEN3: 'MERKEZ KAANICHE',
            IDN2: 242,
            CODEP: '3011',
          },
          {
            IDN3: 3553,
            LIBELLEN3: 'MERKEZ LAJMI',
            IDN2: 242,
            CODEP: '3067',
          },
          {
            IDN3: 3560,
            LIBELLEN3: 'MERKEZ SEBAI',
            IDN2: 242,
            CODEP: '3054',
          },
          {
            IDN3: 3558,
            LIBELLEN3: 'SAKIET EDDAIER',
            IDN2: 242,
            CODEP: '3011',
          },
          {
            IDN3: 3564,
            LIBELLEN3: 'SIDI MANSOUR',
            IDN2: 242,
            CODEP: '3061',
          },
        ],
      },
      {
        ville: 249,
        villeName: 'SAKIET EZZIT',
        cites: [
          {
            IDN3: 3702,
            LIBELLEN3: 'CITE 20 MARS',
            IDN2: 249,
            CODEP: '3041',
          },
          {
            IDN3: 3703,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 249,
            CODEP: '3041',
          },
          {
            IDN3: 3704,
            LIBELLEN3: 'CITE ENNOUZHA',
            IDN2: 249,
            CODEP: '3041',
          },
          {
            IDN3: 3705,
            LIBELLEN3: 'EL MNASRIA',
            IDN2: 249,
            CODEP: '3041',
          },
          {
            IDN3: 3701,
            LIBELLEN3: 'MERKEZ BOUACIDA',
            IDN2: 249,
            CODEP: '3031',
          },
          {
            IDN3: 3706,
            LIBELLEN3: 'MERKEZ CHIHYA',
            IDN2: 249,
            CODEP: '3041',
          },
          {
            IDN3: 3700,
            LIBELLEN3: 'SAKIET EZZIT',
            IDN2: 249,
            CODEP: '3021',
          },
          {
            IDN3: 3707,
            LIBELLEN3: 'SIDI SALAH',
            IDN2: 249,
            CODEP: '3091',
          },
        ],
      },
      {
        ville: 245,
        villeName: 'SFAX EST',
        cites: [
          {
            IDN3: 3617,
            LIBELLEN3: 'CITE DE LA SANTE',
            IDN2: 245,
            CODEP: '3052',
          },
          {
            IDN3: 3618,
            LIBELLEN3: 'CITE DOUNIA',
            IDN2: 245,
            CODEP: '3052',
          },
          {
            IDN3: 3624,
            LIBELLEN3: 'CITE EL BAHRI',
            IDN2: 245,
            CODEP: '3064',
          },
          {
            IDN3: 3614,
            LIBELLEN3: 'CITE EL FOLL',
            IDN2: 245,
            CODEP: '3064',
          },
          {
            IDN3: 3619,
            LIBELLEN3: 'CITE EL HABIB',
            IDN2: 245,
            CODEP: '3052',
          },
          {
            IDN3: 3620,
            LIBELLEN3: 'CITE EL MENZAH',
            IDN2: 245,
            CODEP: '3052',
          },
          {
            IDN3: 3621,
            LIBELLEN3: 'CITE EL MESSAADINE',
            IDN2: 245,
            CODEP: '3052',
          },
          {
            IDN3: 3610,
            LIBELLEN3: 'CITE EL WAFA',
            IDN2: 245,
            CODEP: '3064',
          },
          {
            IDN3: 3622,
            LIBELLEN3: 'CITE EL YASMINE',
            IDN2: 245,
            CODEP: '3052',
          },
          {
            IDN3: 3623,
            LIBELLEN3: 'CITE ESSAADA',
            IDN2: 245,
            CODEP: '3052',
          },
          {
            IDN3: 3615,
            LIBELLEN3: 'CITE ESSALEM',
            IDN2: 245,
            CODEP: '3064',
          },
          {
            IDN3: 3611,
            LIBELLEN3: 'CITE JAOUHARA',
            IDN2: 245,
            CODEP: '3064',
          },
          {
            IDN3: 3613,
            LIBELLEN3: 'MERKEZ CHAKER',
            IDN2: 245,
            CODEP: '3072',
          },
          {
            IDN3: 3612,
            LIBELLEN3: 'OUED CHAABOUNI',
            IDN2: 245,
            CODEP: '3071',
          },
          {
            IDN3: 3616,
            LIBELLEN3: 'OUED RMAL',
            IDN2: 245,
            CODEP: '3023',
          },
        ],
      },
      {
        ville: 246,
        villeName: 'SFAX SUD',
        cites: [
          {
            IDN3: 3628,
            LIBELLEN3: 'CITE 2000',
            IDN2: 246,
            CODEP: '3013',
          },
          {
            IDN3: 3639,
            LIBELLEN3: 'CITE BADRANI',
            IDN2: 246,
            CODEP: '3083',
          },
          {
            IDN3: 3625,
            LIBELLEN3: 'CITE EL MGHAIER',
            IDN2: 246,
            CODEP: '3083',
          },
          {
            IDN3: 3626,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 246,
            CODEP: '3083',
          },
          {
            IDN3: 3641,
            LIBELLEN3: 'CITE MOEZ 1',
            IDN2: 246,
            CODEP: '3083',
          },
          {
            IDN3: 3642,
            LIBELLEN3: 'CITE MOEZ 2',
            IDN2: 246,
            CODEP: '3083',
          },
          {
            IDN3: 3643,
            LIBELLEN3: 'CITE MOEZ 4',
            IDN2: 246,
            CODEP: '3083',
          },
          {
            IDN3: 3644,
            LIBELLEN3: 'CITE SIDI ABID',
            IDN2: 246,
            CODEP: '3083',
          },
          {
            IDN3: 3646,
            LIBELLEN3: 'CITE TYNA 1',
            IDN2: 246,
            CODEP: '3084',
          },
          {
            IDN3: 3647,
            LIBELLEN3: 'CITE TYNA 2',
            IDN2: 246,
            CODEP: '3084',
          },
          {
            IDN3: 3633,
            LIBELLEN3: 'EL AIN',
            IDN2: 246,
            CODEP: '3042',
          },
          {
            IDN3: 3638,
            LIBELLEN3: 'EL AOUABED',
            IDN2: 246,
            CODEP: '3074',
          },
          {
            IDN3: 3640,
            LIBELLEN3: 'EL AOUYET',
            IDN2: 246,
            CODEP: '3076',
          },
          {
            IDN3: 3651,
            LIBELLEN3: 'EL HAJEB',
            IDN2: 246,
            CODEP: '3078',
          },
          {
            IDN3: 3636,
            LIBELLEN3: 'EL KHAZZANETTE',
            IDN2: 246,
            CODEP: '3059',
          },
          {
            IDN3: 3650,
            LIBELLEN3: 'MERKEZ ALOUI',
            IDN2: 246,
            CODEP: '3076',
          },
          {
            IDN3: 3634,
            LIBELLEN3: 'MERKEZ ALOULOU',
            IDN2: 246,
            CODEP: '3042',
          },
          {
            IDN3: 3629,
            LIBELLEN3: 'MERKEZ CHAABOUNI',
            IDN2: 246,
            CODEP: '3013',
          },
          {
            IDN3: 3630,
            LIBELLEN3: 'MERKEZ DAMMAK',
            IDN2: 246,
            CODEP: '3013',
          },
          {
            IDN3: 3635,
            LIBELLEN3: 'MERKEZ JILANI',
            IDN2: 246,
            CODEP: '3042',
          },
          {
            IDN3: 3632,
            LIBELLEN3: 'MERKEZ KAMOUN',
            IDN2: 246,
            CODEP: '3022',
          },
          {
            IDN3: 3631,
            LIBELLEN3: 'MERKEZ KASSAS',
            IDN2: 246,
            CODEP: '3013',
          },
          {
            IDN3: 3649,
            LIBELLEN3: 'MERKEZ OUALI',
            IDN2: 246,
            CODEP: '3093',
          },
          {
            IDN3: 3627,
            LIBELLEN3: 'MERKEZ SAHNOUN',
            IDN2: 246,
            CODEP: '3012',
          },
          {
            IDN3: 3637,
            LIBELLEN3: 'SIDI ABBES',
            IDN2: 246,
            CODEP: '3062',
          },
          {
            IDN3: 3645,
            LIBELLEN3: 'TYNA',
            IDN2: 246,
            CODEP: '3083',
          },
          {
            IDN3: 3648,
            LIBELLEN3: 'TYNA EL JADIDA',
            IDN2: 246,
            CODEP: '3084',
          },
        ],
      },
      {
        ville: 241,
        villeName: 'SFAX VILLE',
        cites: [
          {
            IDN3: 3528,
            LIBELLEN3: 'AIN CHARFI',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3529,
            LIBELLEN3: 'AIN EL FELLAT',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3530,
            LIBELLEN3: 'AIN EL MAYEL',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3531,
            LIBELLEN3: 'AIN TOURKIA',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3532,
            LIBELLEN3: 'AIN TURKI',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3520,
            LIBELLEN3: 'BAB EL JEBLI',
            IDN2: 241,
            CODEP: '3047',
          },
          {
            IDN3: 3533,
            LIBELLEN3: 'BIR BEN AYED',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3534,
            LIBELLEN3: 'BIR EL MELLOULI',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3535,
            LIBELLEN3: 'BIR TEBAK',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3519,
            LIBELLEN3: 'CAID MHAMED',
            IDN2: 241,
            CODEP: '3039',
          },
          {
            IDN3: 3536,
            LIBELLEN3: 'CITE BOURGUIBA 2',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3537,
            LIBELLEN3: 'CITE BOUZID',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3538,
            LIBELLEN3: 'CITE CNRPS',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3539,
            LIBELLEN3: 'CITE COMMERCIALE',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3540,
            LIBELLEN3: 'CITE COMPENSATION',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3541,
            LIBELLEN3: 'CITE DE LE LIBERTE',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3542,
            LIBELLEN3: 'CITE DU JARDIN 1',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3543,
            LIBELLEN3: 'CITE EL AMEL',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3544,
            LIBELLEN3: 'CITE EL MAAREF',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3545,
            LIBELLEN3: 'CITE EL MOUROUJ',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3546,
            LIBELLEN3: 'CITE ESSOUROUR',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3548,
            LIBELLEN3: 'CITE EZZITOUNA',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3550,
            LIBELLEN3: 'CITE JOUA',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3551,
            LIBELLEN3: 'CITE NOUVELLE KERKOUAN',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3552,
            LIBELLEN3: 'CITE SIAPE',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3549,
            LIBELLEN3: 'EL ACHACH',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3502,
            LIBELLEN3: 'EL BOUSTEN',
            IDN2: 241,
            CODEP: '3099',
          },
          {
            IDN3: 3527,
            LIBELLEN3: 'ENNIGROU',
            IDN2: 241,
            CODEP: '3089',
          },
          {
            IDN3: 3547,
            LIBELLEN3: 'HABBANA 2',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3526,
            LIBELLEN3: 'HAI EL KHIRI',
            IDN2: 241,
            CODEP: '3079',
          },
          {
            IDN3: 3503,
            LIBELLEN3: 'MAJEN EDDROJ',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3504,
            LIBELLEN3: 'MERKEZ BEN AMEUR',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3505,
            LIBELLEN3: 'MERKEZ BERMAKI',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3506,
            LIBELLEN3: 'MERKEZ BOU KHEDIR',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3518,
            LIBELLEN3: 'MERKEZ DEROUICHE',
            IDN2: 241,
            CODEP: '3032',
          },
          {
            IDN3: 3523,
            LIBELLEN3: 'MERKEZ EL ALIA',
            IDN2: 241,
            CODEP: '3051',
          },
          {
            IDN3: 3507,
            LIBELLEN3: 'MERKEZ GUIRAT',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3508,
            LIBELLEN3: 'MERKEZ JELLOULI',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3509,
            LIBELLEN3: 'MERKEZ ZOUAYED',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3510,
            LIBELLEN3: 'OUED LAACHECH',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3511,
            LIBELLEN3: 'ROUADHI',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3512,
            LIBELLEN3: 'SFAX',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3517,
            LIBELLEN3: 'SFAX EL JADIDA',
            IDN2: 241,
            CODEP: '3027',
          },
          {
            IDN3: 3525,
            LIBELLEN3: 'SFAX HACHED',
            IDN2: 241,
            CODEP: '3069',
          },
          {
            IDN3: 3522,
            LIBELLEN3: 'SFAX MAGREB ARABE',
            IDN2: 241,
            CODEP: '3049',
          },
          {
            IDN3: 3524,
            LIBELLEN3: 'SFAX PORT',
            IDN2: 241,
            CODEP: '3065',
          },
          {
            IDN3: 3513,
            LIBELLEN3: 'SIDI ABDELKEFI',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3514,
            LIBELLEN3: 'SIDI JILANI',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3515,
            LIBELLEN3: 'SIDI LITEYEM',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3516,
            LIBELLEN3: 'SOUALMIA',
            IDN2: 241,
            CODEP: '3000',
          },
          {
            IDN3: 3521,
            LIBELLEN3: 'SOUK EL FERIANI',
            IDN2: 241,
            CODEP: '3048',
          },
        ],
      },
    ],
  },
  {
    govLabel: 'SIDI BOUZID',
    govId: 18,
    villes: [
      {
        ville: 250,
        villeName: 'BEN OUN',
        cites: [
          {
            IDN3: 3708,
            LIBELLEN3: 'BATEN EL AGAAG',
            IDN2: 250,
            CODEP: '9120',
          },
          {
            IDN3: 3709,
            LIBELLEN3: 'BEN BECHIR',
            IDN2: 250,
            CODEP: '9120',
          },
          {
            IDN3: 3710,
            LIBELLEN3: 'BEN OUN',
            IDN2: 250,
            CODEP: '9120',
          },
          {
            IDN3: 3711,
            LIBELLEN3: 'BEN OUN NORD',
            IDN2: 250,
            CODEP: '9120',
          },
          {
            IDN3: 3712,
            LIBELLEN3: 'BIR IDRISS',
            IDN2: 250,
            CODEP: '9120',
          },
          {
            IDN3: 3713,
            LIBELLEN3: 'CITE EL ILM',
            IDN2: 250,
            CODEP: '9120',
          },
          {
            IDN3: 3714,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 250,
            CODEP: '9120',
          },
          {
            IDN3: 3715,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 250,
            CODEP: '9120',
          },
          {
            IDN3: 3716,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 250,
            CODEP: '9120',
          },
          {
            IDN3: 3717,
            LIBELLEN3: 'CITE ETTAHRIR',
            IDN2: 250,
            CODEP: '9120',
          },
          {
            IDN3: 3718,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 250,
            CODEP: '9120',
          },
          {
            IDN3: 3719,
            LIBELLEN3: 'DAMDOUM',
            IDN2: 250,
            CODEP: '9120',
          },
          {
            IDN3: 3727,
            LIBELLEN3: 'EL FEJ',
            IDN2: 250,
            CODEP: '9169',
          },
          {
            IDN3: 3728,
            LIBELLEN3: 'EL GOUEDRIA',
            IDN2: 250,
            CODEP: '9169',
          },
          {
            IDN3: 3720,
            LIBELLEN3: 'EL GRAOUA',
            IDN2: 250,
            CODEP: '9120',
          },
          {
            IDN3: 3721,
            LIBELLEN3: 'EL KHALIJ',
            IDN2: 250,
            CODEP: '9120',
          },
          {
            IDN3: 3722,
            LIBELLEN3: 'EL OUAARA',
            IDN2: 250,
            CODEP: '9120',
          },
          {
            IDN3: 3723,
            LIBELLEN3: 'EL OUNEYSIA',
            IDN2: 250,
            CODEP: '9120',
          },
          {
            IDN3: 3729,
            LIBELLEN3: 'ERRAGBA',
            IDN2: 250,
            CODEP: '9169',
          },
          {
            IDN3: 3724,
            LIBELLEN3: 'MARKEZ BEN BECHIR',
            IDN2: 250,
            CODEP: '9120',
          },
          {
            IDN3: 3725,
            LIBELLEN3: 'OULED BRAHIM',
            IDN2: 250,
            CODEP: '9120',
          },
          {
            IDN3: 3730,
            LIBELLEN3: 'OULED EL ACHI',
            IDN2: 250,
            CODEP: '9169',
          },
          {
            IDN3: 3731,
            LIBELLEN3: 'OULED MNASSER',
            IDN2: 250,
            CODEP: '9169',
          },
          {
            IDN3: 3726,
            LIBELLEN3: 'OULED NEJI',
            IDN2: 250,
            CODEP: '9120',
          },
        ],
      },
      {
        ville: 251,
        villeName: 'BIR EL HAFFEY',
        cites: [
          {
            IDN3: 3732,
            LIBELLEN3: 'BIR AMEMA',
            IDN2: 251,
            CODEP: '9113',
          },
          {
            IDN3: 3733,
            LIBELLEN3: 'BIR BOUSBI',
            IDN2: 251,
            CODEP: '9113',
          },
          {
            IDN3: 3734,
            LIBELLEN3: 'BIR EL HAFFEY',
            IDN2: 251,
            CODEP: '9113',
          },
          {
            IDN3: 3735,
            LIBELLEN3: 'CHAKHAR',
            IDN2: 251,
            CODEP: '9113',
          },
          {
            IDN3: 3736,
            LIBELLEN3: 'EDDACHRA',
            IDN2: 251,
            CODEP: '9113',
          },
          {
            IDN3: 3746,
            LIBELLEN3: 'EL ADHLA',
            IDN2: 251,
            CODEP: '9126',
          },
          {
            IDN3: 3737,
            LIBELLEN3: 'EL HANANCHA',
            IDN2: 251,
            CODEP: '9113',
          },
          {
            IDN3: 3738,
            LIBELLEN3: 'EL MHAMDIA',
            IDN2: 251,
            CODEP: '9113',
          },
          {
            IDN3: 3747,
            LIBELLEN3: 'ENNOUAIEL',
            IDN2: 251,
            CODEP: '9126',
          },
          {
            IDN3: 3748,
            LIBELLEN3: 'ESSALAMA',
            IDN2: 251,
            CODEP: '9159',
          },
          {
            IDN3: 3741,
            LIBELLEN3: 'HENCHIR HAFFOUZ',
            IDN2: 251,
            CODEP: '9123',
          },
          {
            IDN3: 3742,
            LIBELLEN3: 'HOUCH BEN NECIB',
            IDN2: 251,
            CODEP: '9123',
          },
          {
            IDN3: 3743,
            LIBELLEN3: 'KSAR EL MZARA',
            IDN2: 251,
            CODEP: '9123',
          },
          {
            IDN3: 3744,
            LIBELLEN3: 'OULED BOUAZIZ',
            IDN2: 251,
            CODEP: '9123',
          },
          {
            IDN3: 3739,
            LIBELLEN3: 'OULED KHELIFA',
            IDN2: 251,
            CODEP: '9113',
          },
          {
            IDN3: 3740,
            LIBELLEN3: 'OURGHA',
            IDN2: 251,
            CODEP: '9113',
          },
          {
            IDN3: 3745,
            LIBELLEN3: 'RAHAL',
            IDN2: 251,
            CODEP: '9123',
          },
        ],
      },
      {
        ville: 253,
        villeName: 'CEBBALA',
        cites: [
          {
            IDN3: 3763,
            LIBELLEN3: 'CEBBALA',
            IDN2: 253,
            CODEP: '9122',
          },
          {
            IDN3: 3764,
            LIBELLEN3: 'CHARAA',
            IDN2: 253,
            CODEP: '9122',
          },
          {
            IDN3: 3765,
            LIBELLEN3: 'CITE EL FATEH 1',
            IDN2: 253,
            CODEP: '9122',
          },
          {
            IDN3: 3766,
            LIBELLEN3: 'CITE EL FATEH 2',
            IDN2: 253,
            CODEP: '9122',
          },
          {
            IDN3: 3767,
            LIBELLEN3: 'CITE ENNAJAH',
            IDN2: 253,
            CODEP: '9122',
          },
          {
            IDN3: 3768,
            LIBELLEN3: 'CITE ENNOUZHA',
            IDN2: 253,
            CODEP: '9122',
          },
          {
            IDN3: 3769,
            LIBELLEN3: 'EL GHEMAMRIA',
            IDN2: 253,
            CODEP: '9122',
          },
        ],
      },
      {
        ville: 252,
        villeName: 'JILMA',
        cites: [
          {
            IDN3: 3750,
            LIBELLEN3: 'AIN JAFFEL',
            IDN2: 252,
            CODEP: '9110',
          },
          {
            IDN3: 3749,
            LIBELLEN3: 'CITE CHARGUI',
            IDN2: 252,
            CODEP: '9110',
          },
          {
            IDN3: 3751,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 252,
            CODEP: '9110',
          },
          {
            IDN3: 3752,
            LIBELLEN3: 'CITE OULED ZAIED',
            IDN2: 252,
            CODEP: '9110',
          },
          {
            IDN3: 3753,
            LIBELLEN3: 'CITE POPULAIRE',
            IDN2: 252,
            CODEP: '9110',
          },
          {
            IDN3: 3754,
            LIBELLEN3: 'EL FENIDEG',
            IDN2: 252,
            CODEP: '9110',
          },
          {
            IDN3: 3755,
            LIBELLEN3: 'EL HAOUIA',
            IDN2: 252,
            CODEP: '9110',
          },
          {
            IDN3: 3756,
            LIBELLEN3: 'GUELLEL',
            IDN2: 252,
            CODEP: '9110',
          },
          {
            IDN3: 3757,
            LIBELLEN3: 'JILMA',
            IDN2: 252,
            CODEP: '9110',
          },
          {
            IDN3: 3758,
            LIBELLEN3: 'LABAYEDH',
            IDN2: 252,
            CODEP: '9110',
          },
          {
            IDN3: 3759,
            LIBELLEN3: 'SED EL YAHOUDI',
            IDN2: 252,
            CODEP: '9110',
          },
          {
            IDN3: 3760,
            LIBELLEN3: 'SELTA',
            IDN2: 252,
            CODEP: '9110',
          },
          {
            IDN3: 3761,
            LIBELLEN3: 'SIDI ALI EBN JABALLAH',
            IDN2: 252,
            CODEP: '9110',
          },
          {
            IDN3: 3762,
            LIBELLEN3: 'ZOGHMAR',
            IDN2: 252,
            CODEP: '9110',
          },
        ],
      },
      {
        ville: 260,
        villeName: 'MAKNASSY',
        cites: [
          {
            IDN3: 3944,
            LIBELLEN3: 'BORJ EL KARMA',
            IDN2: 260,
            CODEP: '9143',
          },
          {
            IDN3: 3948,
            LIBELLEN3: 'CITE DES ABRICOTS',
            IDN2: 260,
            CODEP: '9140',
          },
          {
            IDN3: 3949,
            LIBELLEN3: 'CITE DES MARTYRS',
            IDN2: 260,
            CODEP: '9140',
          },
          {
            IDN3: 3950,
            LIBELLEN3: 'CITE EL FATEH 1',
            IDN2: 260,
            CODEP: '9140',
          },
          {
            IDN3: 3951,
            LIBELLEN3: 'CITE EL FATEH 2',
            IDN2: 260,
            CODEP: '9140',
          },
          {
            IDN3: 3952,
            LIBELLEN3: 'CITE EL FATEH 3',
            IDN2: 260,
            CODEP: '9140',
          },
          {
            IDN3: 3953,
            LIBELLEN3: 'CITE EL INTILAKA',
            IDN2: 260,
            CODEP: '9140',
          },
          {
            IDN3: 3954,
            LIBELLEN3: 'CITE ENNAJAH 1',
            IDN2: 260,
            CODEP: '9140',
          },
          {
            IDN3: 3955,
            LIBELLEN3: 'CITE ENNAJAH 2',
            IDN2: 260,
            CODEP: '9140',
          },
          {
            IDN3: 3956,
            LIBELLEN3: 'CITE EZZAYATINE',
            IDN2: 260,
            CODEP: '9140',
          },
          {
            IDN3: 3957,
            LIBELLEN3: 'CITE MILITAIRE',
            IDN2: 260,
            CODEP: '9140',
          },
          {
            IDN3: 3958,
            LIBELLEN3: 'CITE MOHAMED ALI',
            IDN2: 260,
            CODEP: '9140',
          },
          {
            IDN3: 3933,
            LIBELLEN3: 'CITE TAIEB MHIRI',
            IDN2: 260,
            CODEP: '9140',
          },
          {
            IDN3: 3934,
            LIBELLEN3: 'CITE UNITE ENNAJAH',
            IDN2: 260,
            CODEP: '9140',
          },
          {
            IDN3: 3935,
            LIBELLEN3: 'DAKHLET EL ALENDA',
            IDN2: 260,
            CODEP: '9140',
          },
          {
            IDN3: 3936,
            LIBELLEN3: 'EL AYOUN',
            IDN2: 260,
            CODEP: '9140',
          },
          {
            IDN3: 3937,
            LIBELLEN3: 'EL HOUAYEZ',
            IDN2: 260,
            CODEP: '9140',
          },
          {
            IDN3: 3947,
            LIBELLEN3: 'EL MECHE',
            IDN2: 260,
            CODEP: '9158',
          },
          {
            IDN3: 3946,
            LIBELLEN3: 'ENNASR',
            IDN2: 260,
            CODEP: '9149',
          },
          {
            IDN3: 3938,
            LIBELLEN3: 'EZZOUARAA',
            IDN2: 260,
            CODEP: '9140',
          },
          {
            IDN3: 3939,
            LIBELLEN3: 'JABBES',
            IDN2: 260,
            CODEP: '9140',
          },
          {
            IDN3: 3940,
            LIBELLEN3: 'KSAR KHELIFA ZENATI',
            IDN2: 260,
            CODEP: '9140',
          },
          {
            IDN3: 3945,
            LIBELLEN3: 'MABROUKA',
            IDN2: 260,
            CODEP: '9144',
          },
          {
            IDN3: 3941,
            LIBELLEN3: 'MAKNASSY',
            IDN2: 260,
            CODEP: '9140',
          },
          {
            IDN3: 3942,
            LIBELLEN3: 'MARCHE MUNICIPAL',
            IDN2: 260,
            CODEP: '9140',
          },
          {
            IDN3: 3943,
            LIBELLEN3: 'TOUAHRIA 2',
            IDN2: 260,
            CODEP: '9140',
          },
        ],
      },
      {
        ville: 261,
        villeName: 'MENZEL BOUZAIENE',
        cites: [
          {
            IDN3: 3959,
            LIBELLEN3: 'BIR EL ARARIA',
            IDN2: 261,
            CODEP: '9114',
          },
          {
            IDN3: 3960,
            LIBELLEN3: 'DAKHLET HADDEJ',
            IDN2: 261,
            CODEP: '9114',
          },
          {
            IDN3: 3961,
            LIBELLEN3: 'EL AATIZEZ',
            IDN2: 261,
            CODEP: '9114',
          },
          {
            IDN3: 3962,
            LIBELLEN3: 'EL GARAA',
            IDN2: 261,
            CODEP: '9114',
          },
          {
            IDN3: 3973,
            LIBELLEN3: 'EL GUELLEL',
            IDN2: 261,
            CODEP: '9139',
          },
          {
            IDN3: 3963,
            LIBELLEN3: 'EL MAADHER',
            IDN2: 261,
            CODEP: '9114',
          },
          {
            IDN3: 3964,
            LIBELLEN3: 'EL MALOUSSI',
            IDN2: 261,
            CODEP: '9114',
          },
          {
            IDN3: 3965,
            LIBELLEN3: 'EL MHAMDIA',
            IDN2: 261,
            CODEP: '9114',
          },
          {
            IDN3: 3966,
            LIBELLEN3: 'EL OMRANE',
            IDN2: 261,
            CODEP: '9114',
          },
          {
            IDN3: 3967,
            LIBELLEN3: 'ESSAADA',
            IDN2: 261,
            CODEP: '9114',
          },
          {
            IDN3: 3972,
            LIBELLEN3: 'KHORCHEF',
            IDN2: 261,
            CODEP: '9124',
          },
          {
            IDN3: 3968,
            LIBELLEN3: 'MENZEL BOUZAIENE',
            IDN2: 261,
            CODEP: '9114',
          },
          {
            IDN3: 3969,
            LIBELLEN3: 'MENZEL SAADA',
            IDN2: 261,
            CODEP: '9114',
          },
          {
            IDN3: 3970,
            LIBELLEN3: 'OULED ZOUID',
            IDN2: 261,
            CODEP: '9114',
          },
          {
            IDN3: 3971,
            LIBELLEN3: 'RBEAYIA',
            IDN2: 261,
            CODEP: '9114',
          },
        ],
      },
      {
        ville: 255,
        villeName: 'MEZZOUNA',
        cites: [
          {
            IDN3: 3807,
            LIBELLEN3: 'CITE DE LA POSTE',
            IDN2: 255,
            CODEP: '9150',
          },
          {
            IDN3: 3808,
            LIBELLEN3: 'CITE DES MARTYRS',
            IDN2: 255,
            CODEP: '9150',
          },
          {
            IDN3: 3809,
            LIBELLEN3: 'CITE EL AMEL',
            IDN2: 255,
            CODEP: '9150',
          },
          {
            IDN3: 3796,
            LIBELLEN3: 'CITE INDEPENDANCE',
            IDN2: 255,
            CODEP: '9150',
          },
          {
            IDN3: 3810,
            LIBELLEN3: 'EL BOUAA',
            IDN2: 255,
            CODEP: '9154',
          },
          {
            IDN3: 3805,
            LIBELLEN3: 'EL FOUNI',
            IDN2: 255,
            CODEP: '9151',
          },
          {
            IDN3: 3797,
            LIBELLEN3: 'EL GHERIS',
            IDN2: 255,
            CODEP: '9150',
          },
          {
            IDN3: 3798,
            LIBELLEN3: 'EL GUEZGAZIA',
            IDN2: 255,
            CODEP: '9150',
          },
          {
            IDN3: 3799,
            LIBELLEN3: 'EL KHIMA',
            IDN2: 255,
            CODEP: '9150',
          },
          {
            IDN3: 3800,
            LIBELLEN3: 'EL KHOBNA',
            IDN2: 255,
            CODEP: '9150',
          },
          {
            IDN3: 3801,
            LIBELLEN3: 'EL KHOUI',
            IDN2: 255,
            CODEP: '9150',
          },
          {
            IDN3: 3802,
            LIBELLEN3: 'FERJEN',
            IDN2: 255,
            CODEP: '9150',
          },
          {
            IDN3: 3803,
            LIBELLEN3: 'GHEDIR REBAIA',
            IDN2: 255,
            CODEP: '9150',
          },
          {
            IDN3: 3804,
            LIBELLEN3: 'HADDEJ',
            IDN2: 255,
            CODEP: '9150',
          },
          {
            IDN3: 3806,
            LIBELLEN3: 'MEZZOUNA',
            IDN2: 255,
            CODEP: '9150',
          },
          {
            IDN3: 3795,
            LIBELLEN3: 'OUED EDDAM',
            IDN2: 255,
            CODEP: '9150',
          },
          {
            IDN3: 3794,
            LIBELLEN3: 'OULED DLALA',
            IDN2: 255,
            CODEP: '9150',
          },
        ],
      },
      {
        ville: 254,
        villeName: 'OULED HAFFOUZ',
        cites: [
          {
            IDN3: 3786,
            LIBELLEN3: 'BOUTIQUE ISSA',
            IDN2: 254,
            CODEP: '9180',
          },
          {
            IDN3: 3787,
            LIBELLEN3: 'CHOUACHNIA',
            IDN2: 254,
            CODEP: '9180',
          },
          {
            IDN3: 3788,
            LIBELLEN3: 'CITE ALI BEN HAMID',
            IDN2: 254,
            CODEP: '9180',
          },
          {
            IDN3: 3789,
            LIBELLEN3: 'CITE COMMERCIALE',
            IDN2: 254,
            CODEP: '9180',
          },
          {
            IDN3: 3790,
            LIBELLEN3: 'CITE EL MAARIFA',
            IDN2: 254,
            CODEP: '9180',
          },
          {
            IDN3: 3791,
            LIBELLEN3: 'CITE EL OMRANE',
            IDN2: 254,
            CODEP: '9180',
          },
          {
            IDN3: 3792,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 254,
            CODEP: '9180',
          },
          {
            IDN3: 3793,
            LIBELLEN3: 'CITE EZZAYATINE',
            IDN2: 254,
            CODEP: '9180',
          },
          {
            IDN3: 3785,
            LIBELLEN3: 'DHOUIBETTE',
            IDN2: 254,
            CODEP: '9116',
          },
          {
            IDN3: 3770,
            LIBELLEN3: 'DHOUIBETTE 2',
            IDN2: 254,
            CODEP: '9180',
          },
          {
            IDN3: 3784,
            LIBELLEN3: 'EL ABBADETTE',
            IDN2: 254,
            CODEP: '9183',
          },
          {
            IDN3: 3771,
            LIBELLEN3: 'EL ARAOUA',
            IDN2: 254,
            CODEP: '9180',
          },
          {
            IDN3: 3772,
            LIBELLEN3: 'EL GOUAIBIA',
            IDN2: 254,
            CODEP: '9180',
          },
          {
            IDN3: 3773,
            LIBELLEN3: 'EL KHARWAA',
            IDN2: 254,
            CODEP: '9180',
          },
          {
            IDN3: 3774,
            LIBELLEN3: 'EL MBARKIA',
            IDN2: 254,
            CODEP: '9180',
          },
          {
            IDN3: 3775,
            LIBELLEN3: 'ENNAJAH',
            IDN2: 254,
            CODEP: '9180',
          },
          {
            IDN3: 3776,
            LIBELLEN3: 'ERRANZEZ',
            IDN2: 254,
            CODEP: '9180',
          },
          {
            IDN3: 3777,
            LIBELLEN3: 'HANIA  102',
            IDN2: 254,
            CODEP: '9180',
          },
          {
            IDN3: 3778,
            LIBELLEN3: 'HORBIT',
            IDN2: 254,
            CODEP: '9180',
          },
          {
            IDN3: 3779,
            LIBELLEN3: 'KHEBINA',
            IDN2: 254,
            CODEP: '9180',
          },
          {
            IDN3: 3780,
            LIBELLEN3: 'OULED HAFFOUZ',
            IDN2: 254,
            CODEP: '9180',
          },
          {
            IDN3: 3781,
            LIBELLEN3: 'SIDI ELLAFI 1',
            IDN2: 254,
            CODEP: '9180',
          },
          {
            IDN3: 3782,
            LIBELLEN3: 'SIDI ELLAFI 2',
            IDN2: 254,
            CODEP: '9180',
          },
          {
            IDN3: 3783,
            LIBELLEN3: 'SIDI KHLIF',
            IDN2: 254,
            CODEP: '9180',
          },
        ],
      },
      {
        ville: 256,
        villeName: 'REGUEB',
        cites: [
          {
            IDN3: 3816,
            LIBELLEN3: 'BIR CHAREF',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3811,
            LIBELLEN3: 'BIR EL AKERMA',
            IDN2: 256,
            CODEP: '9115',
          },
          {
            IDN3: 3817,
            LIBELLEN3: 'BIR GZAIEL',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3818,
            LIBELLEN3: 'BIR KHELIFA',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3819,
            LIBELLEN3: 'BIR NSIB',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3820,
            LIBELLEN3: 'BOU ALLEGUE',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3812,
            LIBELLEN3: 'BOU CHIHA',
            IDN2: 256,
            CODEP: '9115',
          },
          {
            IDN3: 3821,
            LIBELLEN3: 'BOU DINAR',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3822,
            LIBELLEN3: 'CITE DE LA REPUBLIQUE',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3823,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3824,
            LIBELLEN3: 'CITE EL INTILAKA',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3825,
            LIBELLEN3: 'CITE EL KHADHRA',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3826,
            LIBELLEN3: 'CITE EL MANAR',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3856,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3857,
            LIBELLEN3: 'CITE ENNOUZHA',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3854,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3855,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3828,
            LIBELLEN3: 'CITE IBN KHALDOUN',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3813,
            LIBELLEN3: 'EL GHABA ESSOUDA',
            IDN2: 256,
            CODEP: '9115',
          },
          {
            IDN3: 3829,
            LIBELLEN3: 'EL GOUADRIA',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3830,
            LIBELLEN3: 'EL HOUDA',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3851,
            LIBELLEN3: 'EL KHECHEM',
            IDN2: 256,
            CODEP: '9173',
          },
          {
            IDN3: 3831,
            LIBELLEN3: 'EL MHAFDHIA',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3853,
            LIBELLEN3: 'ERRADHAA',
            IDN2: 256,
            CODEP: '9174',
          },
          {
            IDN3: 3832,
            LIBELLEN3: 'ESSAKBA',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3814,
            LIBELLEN3: 'ESSFAYA',
            IDN2: 256,
            CODEP: '9115',
          },
          {
            IDN3: 3833,
            LIBELLEN3: 'EZZADAM',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3834,
            LIBELLEN3: 'EZZITOUNA',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3835,
            LIBELLEN3: 'FARCH GUERIB',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3852,
            LIBELLEN3: 'FATNASSA',
            IDN2: 256,
            CODEP: '9173',
          },
          {
            IDN3: 3850,
            LIBELLEN3: 'GOUBRAR',
            IDN2: 256,
            CODEP: '9172',
          },
          {
            IDN3: 3836,
            LIBELLEN3: 'GUENIFIDA',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3837,
            LIBELLEN3: 'HENCHIR KHDAM',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3838,
            LIBELLEN3: 'KSAR EL HAMMAM',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3839,
            LIBELLEN3: 'KSAR SAI',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3840,
            LIBELLEN3: 'MATLAG',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3841,
            LIBELLEN3: 'OULED ABDALLAH',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3842,
            LIBELLEN3: 'OULED AYOUNI',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3843,
            LIBELLEN3: 'OULED CHABOU',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3827,
            LIBELLEN3: 'OULED EL HANI',
            IDN2: 256,
            CODEP: '9115',
          },
          {
            IDN3: 3844,
            LIBELLEN3: 'REGUEB',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3845,
            LIBELLEN3: 'RIHANA NORD',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3846,
            LIBELLEN3: 'RIHANA SUD',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3847,
            LIBELLEN3: 'SABRA',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3815,
            LIBELLEN3: 'SAIDA',
            IDN2: 256,
            CODEP: '9115',
          },
          {
            IDN3: 3848,
            LIBELLEN3: 'SARAYRIA',
            IDN2: 256,
            CODEP: '9170',
          },
          {
            IDN3: 3849,
            LIBELLEN3: 'SIDI AMEUR',
            IDN2: 256,
            CODEP: '9170',
          },
        ],
      },
      {
        ville: 259,
        villeName: 'SIDI BOUZID EST',
        cites: [
          {
            IDN3: 3916,
            LIBELLEN3: 'AIN BOUZER',
            IDN2: 259,
            CODEP: '9112',
          },
          {
            IDN3: 3917,
            LIBELLEN3: 'AIN RABBAOU',
            IDN2: 259,
            CODEP: '9112',
          },
          {
            IDN3: 3918,
            LIBELLEN3: 'BAZID',
            IDN2: 259,
            CODEP: '9112',
          },
          {
            IDN3: 3911,
            LIBELLEN3: 'CITE AGRICOLE',
            IDN2: 259,
            CODEP: '9100',
          },
          {
            IDN3: 3912,
            LIBELLEN3: 'DHAYAA',
            IDN2: 259,
            CODEP: '9100',
          },
          {
            IDN3: 3913,
            LIBELLEN3: 'DHRAA',
            IDN2: 259,
            CODEP: '9100',
          },
          {
            IDN3: 3926,
            LIBELLEN3: 'EL HAOUAMED',
            IDN2: 259,
            CODEP: '9133',
          },
          {
            IDN3: 3930,
            LIBELLEN3: 'EL HMAIMA',
            IDN2: 259,
            CODEP: '9171',
          },
          {
            IDN3: 3928,
            LIBELLEN3: 'EL MAKAREM',
            IDN2: 259,
            CODEP: '9141',
          },
          {
            IDN3: 3919,
            LIBELLEN3: 'EL OGLA',
            IDN2: 259,
            CODEP: '9112',
          },
          {
            IDN3: 3914,
            LIBELLEN3: 'EZZITOUNE',
            IDN2: 259,
            CODEP: '9100',
          },
          {
            IDN3: 3920,
            LIBELLEN3: 'FAYEDH',
            IDN2: 259,
            CODEP: '9112',
          },
          {
            IDN3: 3915,
            LIBELLEN3: 'GARET HADID',
            IDN2: 259,
            CODEP: '9100',
          },
          {
            IDN3: 3921,
            LIBELLEN3: 'GATRANA',
            IDN2: 259,
            CODEP: '9112',
          },
          {
            IDN3: 3931,
            LIBELLEN3: 'GRAA BENNOUR',
            IDN2: 259,
            CODEP: '9171',
          },
          {
            IDN3: 3922,
            LIBELLEN3: 'HANIA BAZID',
            IDN2: 259,
            CODEP: '9112',
          },
          {
            IDN3: 3923,
            LIBELLEN3: 'JEMEL',
            IDN2: 259,
            CODEP: '9112',
          },
          {
            IDN3: 3932,
            LIBELLEN3: 'LESSOUDA',
            IDN2: 259,
            CODEP: '9171',
          },
          {
            IDN3: 3924,
            LIBELLEN3: 'OULED BOUDHIAF',
            IDN2: 259,
            CODEP: '9112',
          },
          {
            IDN3: 3929,
            LIBELLEN3: 'OULED FARHAN',
            IDN2: 259,
            CODEP: '9141',
          },
          {
            IDN3: 3925,
            LIBELLEN3: 'OULED YOUSSEF',
            IDN2: 259,
            CODEP: '9112',
          },
          {
            IDN3: 3927,
            LIBELLEN3: 'REMAIDIA',
            IDN2: 259,
            CODEP: '9133',
          },
        ],
      },
      {
        ville: 257,
        villeName: 'SIDI BOUZID OUEST',
        cites: [
          {
            IDN3: 3869,
            LIBELLEN3: 'BIR BADRA',
            IDN2: 257,
            CODEP: '9131',
          },
          {
            IDN3: 3883,
            LIBELLEN3: 'BIR BOUZID',
            IDN2: 257,
            CODEP: '9100',
          },
          {
            IDN3: 3859,
            LIBELLEN3: 'BIR EL BEY',
            IDN2: 257,
            CODEP: '9111',
          },
          {
            IDN3: 3884,
            LIBELLEN3: 'CITE EL BRAHMIA',
            IDN2: 257,
            CODEP: '9100',
          },
          {
            IDN3: 3885,
            LIBELLEN3: 'CITE EL FRAIJIA',
            IDN2: 257,
            CODEP: '9100',
          },
          {
            IDN3: 3876,
            LIBELLEN3: 'CITE EL KAOUAFEL',
            IDN2: 257,
            CODEP: '9132',
          },
          {
            IDN3: 3866,
            LIBELLEN3: 'CITE OULED BEL HEDI',
            IDN2: 257,
            CODEP: '9125',
          },
          {
            IDN3: 3870,
            LIBELLEN3: 'EDDAGHER',
            IDN2: 257,
            CODEP: '9131',
          },
          {
            IDN3: 3860,
            LIBELLEN3: 'EL ADHLA',
            IDN2: 257,
            CODEP: '9111',
          },
          {
            IDN3: 3871,
            LIBELLEN3: 'EL FATEH',
            IDN2: 257,
            CODEP: '9131',
          },
          {
            IDN3: 3861,
            LIBELLEN3: 'EL GHEMAMRIA',
            IDN2: 257,
            CODEP: '9111',
          },
          {
            IDN3: 3862,
            LIBELLEN3: 'EL GUETAYFIA',
            IDN2: 257,
            CODEP: '9111',
          },
          {
            IDN3: 3886,
            LIBELLEN3: 'EL HAWAJBIA',
            IDN2: 257,
            CODEP: '9100',
          },
          {
            IDN3: 3872,
            LIBELLEN3: 'EL HICHRIA',
            IDN2: 257,
            CODEP: '9131',
          },
          {
            IDN3: 3863,
            LIBELLEN3: 'EL MLIKETTE',
            IDN2: 257,
            CODEP: '9111',
          },
          {
            IDN3: 3887,
            LIBELLEN3: 'ESSADAGUIA',
            IDN2: 257,
            CODEP: '9100',
          },
          {
            IDN3: 3878,
            LIBELLEN3: 'ESSANDOUG',
            IDN2: 257,
            CODEP: '9100',
          },
          {
            IDN3: 3867,
            LIBELLEN3: 'ETTOUANSIA',
            IDN2: 257,
            CODEP: '9127',
          },
          {
            IDN3: 3873,
            LIBELLEN3: 'HENCHIR ESNAB',
            IDN2: 257,
            CODEP: '9131',
          },
          {
            IDN3: 3868,
            LIBELLEN3: 'MEFREG EL FRAYOU',
            IDN2: 257,
            CODEP: '9127',
          },
          {
            IDN3: 3881,
            LIBELLEN3: 'MELIKETTE',
            IDN2: 257,
            CODEP: '9100',
          },
          {
            IDN3: 3864,
            LIBELLEN3: 'OUED EL BRIJ',
            IDN2: 257,
            CODEP: '9111',
          },
          {
            IDN3: 3882,
            LIBELLEN3: 'OUITHET GAZAL',
            IDN2: 257,
            CODEP: '9100',
          },
          {
            IDN3: 3874,
            LIBELLEN3: 'OULED JALAL',
            IDN2: 257,
            CODEP: '9131',
          },
          {
            IDN3: 3865,
            LIBELLEN3: 'OUM EL ADHAM',
            IDN2: 257,
            CODEP: '9111',
          },
          {
            IDN3: 3875,
            LIBELLEN3: 'OUM ETTOUBOUL',
            IDN2: 257,
            CODEP: '9131',
          },
          {
            IDN3: 3879,
            LIBELLEN3: 'SIDI BOUZID',
            IDN2: 257,
            CODEP: '9100',
          },
          {
            IDN3: 3880,
            LIBELLEN3: 'SIDI SALEM',
            IDN2: 257,
            CODEP: '9100',
          },
          {
            IDN3: 3877,
            LIBELLEN3: 'TOUILA',
            IDN2: 257,
            CODEP: '9100',
          },
          {
            IDN3: 3858,
            LIBELLEN3: 'ZAAFRIA',
            IDN2: 257,
            CODEP: '9100',
          },
        ],
      },
      {
        ville: 258,
        villeName: 'SOUK JEDID',
        cites: [
          {
            IDN3: 3888,
            LIBELLEN3: 'BOU ATTOUCH',
            IDN2: 258,
            CODEP: '9121',
          },
          {
            IDN3: 3903,
            LIBELLEN3: 'BOU CHMEL',
            IDN2: 258,
            CODEP: '9142',
          },
          {
            IDN3: 3889,
            LIBELLEN3: 'CEGDEL',
            IDN2: 258,
            CODEP: '9121',
          },
          {
            IDN3: 3890,
            LIBELLEN3: 'DOUADNIA',
            IDN2: 258,
            CODEP: '9121',
          },
          {
            IDN3: 3891,
            LIBELLEN3: 'EL AZARA',
            IDN2: 258,
            CODEP: '9121',
          },
          {
            IDN3: 3904,
            LIBELLEN3: 'EL BEKAKRIA',
            IDN2: 258,
            CODEP: '9142',
          },
          {
            IDN3: 3905,
            LIBELLEN3: 'EL HACHANA',
            IDN2: 258,
            CODEP: '9142',
          },
          {
            IDN3: 3892,
            LIBELLEN3: 'EL HESSINETTE',
            IDN2: 258,
            CODEP: '9121',
          },
          {
            IDN3: 3893,
            LIBELLEN3: 'EL KESSAYRA',
            IDN2: 258,
            CODEP: '9121',
          },
          {
            IDN3: 3910,
            LIBELLEN3: 'EL MESATRIA',
            IDN2: 258,
            CODEP: '9121',
          },
          {
            IDN3: 3894,
            LIBELLEN3: 'EL MSABHIA',
            IDN2: 258,
            CODEP: '9121',
          },
          {
            IDN3: 3895,
            LIBELLEN3: 'ENNOUHOUDH',
            IDN2: 258,
            CODEP: '9121',
          },
          {
            IDN3: 3896,
            LIBELLEN3: 'ESSADLIA',
            IDN2: 258,
            CODEP: '9121',
          },
          {
            IDN3: 3906,
            LIBELLEN3: 'GOULEB',
            IDN2: 258,
            CODEP: '9142',
          },
          {
            IDN3: 3907,
            LIBELLEN3: 'OULED  DHAHER',
            IDN2: 258,
            CODEP: '9142',
          },
          {
            IDN3: 3897,
            LIBELLEN3: 'OULED ALAYA',
            IDN2: 258,
            CODEP: '9121',
          },
          {
            IDN3: 3898,
            LIBELLEN3: 'OULED AMMAR',
            IDN2: 258,
            CODEP: '9121',
          },
          {
            IDN3: 3899,
            LIBELLEN3: 'OULED AMOR',
            IDN2: 258,
            CODEP: '9121',
          },
          {
            IDN3: 3900,
            LIBELLEN3: 'OULED KHEDHER',
            IDN2: 258,
            CODEP: '9121',
          },
          {
            IDN3: 3901,
            LIBELLEN3: 'OULED MHAMED',
            IDN2: 258,
            CODEP: '9121',
          },
          {
            IDN3: 3908,
            LIBELLEN3: 'REMILA',
            IDN2: 258,
            CODEP: '9142',
          },
          {
            IDN3: 3902,
            LIBELLEN3: 'SOUK JEDID',
            IDN2: 258,
            CODEP: '9121',
          },
          {
            IDN3: 3909,
            LIBELLEN3: 'ZEFZEF',
            IDN2: 258,
            CODEP: '9142',
          },
        ],
      },
    ],
  },
  {
    govLabel: 'SILIANA',
    govId: 19,
    villes: [
      {
        ville: 28,
        villeName: 'BARGOU',
        cites: [
          {
            IDN3: 4119,
            LIBELLEN3: 'AIN EL FOURNA',
            IDN2: 28,
            CODEP: '6170',
          },
          {
            IDN3: 4120,
            LIBELLEN3: 'AIN ZAKKAR',
            IDN2: 28,
            CODEP: '6170',
          },
          {
            IDN3: 4121,
            LIBELLEN3: 'BARGOU',
            IDN2: 28,
            CODEP: '6170',
          },
          {
            IDN3: 4131,
            LIBELLEN3: 'BOU SAADIA',
            IDN2: 28,
            CODEP: '6115',
          },
          {
            IDN3: 4122,
            LIBELLEN3: 'CITE ABOUL KACEM CHEBBI',
            IDN2: 28,
            CODEP: '6170',
          },
          {
            IDN3: 4123,
            LIBELLEN3: 'CITE AIR NOUVELLE',
            IDN2: 28,
            CODEP: '6170',
          },
          {
            IDN3: 4124,
            LIBELLEN3: 'CITE EL KADIM',
            IDN2: 28,
            CODEP: '6170',
          },
          {
            IDN3: 4125,
            LIBELLEN3: 'CITE ESSAADA',
            IDN2: 28,
            CODEP: '6170',
          },
          {
            IDN3: 4126,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 28,
            CODEP: '6170',
          },
          {
            IDN3: 4127,
            LIBELLEN3: 'CITE IBN KHALDOUN',
            IDN2: 28,
            CODEP: '6170',
          },
          {
            IDN3: 4128,
            LIBELLEN3: 'DRIJA',
            IDN2: 28,
            CODEP: '6170',
          },
          {
            IDN3: 4117,
            LIBELLEN3: 'EL BHIRINE',
            IDN2: 28,
            CODEP: '6173',
          },
          {
            IDN3: 4129,
            LIBELLEN3: 'MARJ AOUAM',
            IDN2: 28,
            CODEP: '6170',
          },
          {
            IDN3: 4130,
            LIBELLEN3: 'SIDI AMARA',
            IDN2: 28,
            CODEP: '6170',
          },
          {
            IDN3: 4115,
            LIBELLEN3: 'SIDI SAID',
            IDN2: 28,
            CODEP: '6170',
          },
          {
            IDN3: 4116,
            LIBELLEN3: 'SIDI ZID',
            IDN2: 28,
            CODEP: '6170',
          },
          {
            IDN3: 4118,
            LIBELLEN3: 'SODGA',
            IDN2: 28,
            CODEP: '6173',
          },
        ],
      },
      {
        ville: 27,
        villeName: 'BOU ARADA',
        cites: [
          {
            IDN3: 4090,
            LIBELLEN3: 'AIN EL GHESSIL',
            IDN2: 27,
            CODEP: '6180',
          },
          {
            IDN3: 4091,
            LIBELLEN3: 'BATEN ZRAIEB',
            IDN2: 27,
            CODEP: '6180',
          },
          {
            IDN3: 4092,
            LIBELLEN3: 'BOU ARADA',
            IDN2: 27,
            CODEP: '6180',
          },
          {
            IDN3: 4093,
            LIBELLEN3: 'CITE ALI BEN MBAREK 1',
            IDN2: 27,
            CODEP: '6180',
          },
          {
            IDN3: 4094,
            LIBELLEN3: 'CITE ALI BEN MBAREK 2',
            IDN2: 27,
            CODEP: '6180',
          },
          {
            IDN3: 4095,
            LIBELLEN3: 'CITE ALI BEN MBAREK 3',
            IDN2: 27,
            CODEP: '6180',
          },
          {
            IDN3: 4096,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 27,
            CODEP: '6180',
          },
          {
            IDN3: 4097,
            LIBELLEN3: 'CITE EL MELLASSINE',
            IDN2: 27,
            CODEP: '6180',
          },
          {
            IDN3: 4098,
            LIBELLEN3: 'CITE ENNAKHIL',
            IDN2: 27,
            CODEP: '6180',
          },
          {
            IDN3: 4099,
            LIBELLEN3: 'CITE ENNOUR 1',
            IDN2: 27,
            CODEP: '6180',
          },
          {
            IDN3: 4101,
            LIBELLEN3: 'CITE ENNOUR 2',
            IDN2: 27,
            CODEP: '6180',
          },
          {
            IDN3: 4102,
            LIBELLEN3: 'CITE ERRIADH 1',
            IDN2: 27,
            CODEP: '6180',
          },
          {
            IDN3: 4103,
            LIBELLEN3: 'CITE ERRIADH 2',
            IDN2: 27,
            CODEP: '6180',
          },
          {
            IDN3: 4089,
            LIBELLEN3: 'CITE ERRIADH 3',
            IDN2: 27,
            CODEP: '6180',
          },
          {
            IDN3: 4100,
            LIBELLEN3: 'CITE ESSAADA',
            IDN2: 27,
            CODEP: '6180',
          },
          {
            IDN3: 4104,
            LIBELLEN3: 'CITE ESSAFA',
            IDN2: 27,
            CODEP: '6180',
          },
          {
            IDN3: 4105,
            LIBELLEN3: 'CITE ESSALEM',
            IDN2: 27,
            CODEP: '6180',
          },
          {
            IDN3: 4106,
            LIBELLEN3: 'CITE EZZAYATINE EST',
            IDN2: 27,
            CODEP: '6180',
          },
          {
            IDN3: 4107,
            LIBELLEN3: 'CITE EZZAYATINE OUEST',
            IDN2: 27,
            CODEP: '6180',
          },
          {
            IDN3: 4108,
            LIBELLEN3: 'EL FETISSE',
            IDN2: 27,
            CODEP: '6180',
          },
          {
            IDN3: 4109,
            LIBELLEN3: 'EL MEJENINE',
            IDN2: 27,
            CODEP: '6180',
          },
          {
            IDN3: 4110,
            LIBELLEN3: 'HENCHIR ROUMENE',
            IDN2: 27,
            CODEP: '6180',
          },
          {
            IDN3: 4111,
            LIBELLEN3: 'JELIDI GARE',
            IDN2: 27,
            CODEP: '6180',
          },
          {
            IDN3: 4112,
            LIBELLEN3: 'KHAMSA THENAYA',
            IDN2: 27,
            CODEP: '6180',
          },
          {
            IDN3: 4113,
            LIBELLEN3: 'KSAR BOUKHRIS',
            IDN2: 27,
            CODEP: '6180',
          },
          {
            IDN3: 4114,
            LIBELLEN3: 'SIDI ABDENNOUR',
            IDN2: 27,
            CODEP: '6180',
          },
        ],
      },
      {
        ville: 31,
        villeName: 'EL AROUSSA',
        cites: [
          {
            IDN3: 4174,
            LIBELLEN3: 'BOU JLIDA',
            IDN2: 31,
            CODEP: '6135',
          },
          {
            IDN3: 4170,
            LIBELLEN3: 'EL AROUSSA',
            IDN2: 31,
            CODEP: '6116',
          },
          {
            IDN3: 4171,
            LIBELLEN3: 'EL HAJJEJ',
            IDN2: 31,
            CODEP: '6116',
          },
          {
            IDN3: 4172,
            LIBELLEN3: 'MOSRATA',
            IDN2: 31,
            CODEP: '6116',
          },
          {
            IDN3: 4173,
            LIBELLEN3: 'OUED EL ARAAR',
            IDN2: 31,
            CODEP: '6116',
          },
        ],
      },
      {
        ville: 24,
        villeName: 'GAAFOUR',
        cites: [
          {
            IDN3: 4048,
            LIBELLEN3: 'AIN ZRIG',
            IDN2: 24,
            CODEP: '6172',
          },
          {
            IDN3: 4039,
            LIBELLEN3: 'AKHOUAT MINE',
            IDN2: 24,
            CODEP: '6111',
          },
          {
            IDN3: 4042,
            LIBELLEN3: 'BENNOURIA',
            IDN2: 24,
            CODEP: '6121',
          },
          {
            IDN3: 4044,
            LIBELLEN3: 'CITE DU BATTOIRE',
            IDN2: 24,
            CODEP: '6110',
          },
          {
            IDN3: 4052,
            LIBELLEN3: 'CITE DU LYCEE',
            IDN2: 24,
            CODEP: '6110',
          },
          {
            IDN3: 4031,
            LIBELLEN3: 'CITE EL GUEBLI',
            IDN2: 24,
            CODEP: '6110',
          },
          {
            IDN3: 4046,
            LIBELLEN3: 'CITE EL WAFA',
            IDN2: 24,
            CODEP: '6110',
          },
          {
            IDN3: 4047,
            LIBELLEN3: 'CITE EZZOUHOUR 1',
            IDN2: 24,
            CODEP: '6110',
          },
          {
            IDN3: 4045,
            LIBELLEN3: 'CITE EZZOUHOUR 2',
            IDN2: 24,
            CODEP: '6110',
          },
          {
            IDN3: 4032,
            LIBELLEN3: 'CITE EZZOUHOUR 3',
            IDN2: 24,
            CODEP: '6110',
          },
          {
            IDN3: 4033,
            LIBELLEN3: 'CITE TOUENSA',
            IDN2: 24,
            CODEP: '6110',
          },
          {
            IDN3: 4043,
            LIBELLEN3: 'EL ABARGUIA',
            IDN2: 24,
            CODEP: '6121',
          },
          {
            IDN3: 4040,
            LIBELLEN3: 'EL AKHOUAT',
            IDN2: 24,
            CODEP: '6111',
          },
          {
            IDN3: 4026,
            LIBELLEN3: 'EL AKSAB',
            IDN2: 24,
            CODEP: '6121',
          },
          {
            IDN3: 4027,
            LIBELLEN3: 'EL AMEL',
            IDN2: 24,
            CODEP: '6121',
          },
          {
            IDN3: 4049,
            LIBELLEN3: 'EL BARRAMA',
            IDN2: 24,
            CODEP: '6172',
          },
          {
            IDN3: 4029,
            LIBELLEN3: 'EL FRACHICH',
            IDN2: 24,
            CODEP: '6126',
          },
          {
            IDN3: 4050,
            LIBELLEN3: 'EL HAOUAM',
            IDN2: 24,
            CODEP: '6172',
          },
          {
            IDN3: 4034,
            LIBELLEN3: 'EL KHARROUBA',
            IDN2: 24,
            CODEP: '6110',
          },
          {
            IDN3: 4028,
            LIBELLEN3: 'ENNAJAH',
            IDN2: 24,
            CODEP: '6121',
          },
          {
            IDN3: 4035,
            LIBELLEN3: 'GAAFOUR',
            IDN2: 24,
            CODEP: '6110',
          },
          {
            IDN3: 4036,
            LIBELLEN3: 'GAAFOUR CHERGUI',
            IDN2: 24,
            CODEP: '6110',
          },
          {
            IDN3: 4037,
            LIBELLEN3: 'GAAFOUR GHARBI',
            IDN2: 24,
            CODEP: '6110',
          },
          {
            IDN3: 4051,
            LIBELLEN3: 'MEDIOUNA',
            IDN2: 24,
            CODEP: '6172',
          },
          {
            IDN3: 4041,
            LIBELLEN3: 'MELLITA',
            IDN2: 24,
            CODEP: '6111',
          },
          {
            IDN3: 4038,
            LIBELLEN3: 'NOUISSER',
            IDN2: 24,
            CODEP: '6110',
          },
          {
            IDN3: 4030,
            LIBELLEN3: 'SIDI AYED',
            IDN2: 24,
            CODEP: '6126',
          },
        ],
      },
      {
        ville: 25,
        villeName: 'KESRA',
        cites: [
          {
            IDN3: 4054,
            LIBELLEN3: 'BENI ABDALLAH',
            IDN2: 25,
            CODEP: '6131',
          },
          {
            IDN3: 4059,
            LIBELLEN3: 'BOU ABDALLAH',
            IDN2: 25,
            CODEP: '6141',
          },
          {
            IDN3: 4066,
            LIBELLEN3: 'CITE 20 MARS',
            IDN2: 25,
            CODEP: '6114',
          },
          {
            IDN3: 4060,
            LIBELLEN3: 'CITE BENI SAYOUR',
            IDN2: 25,
            CODEP: '6141',
          },
          {
            IDN3: 4067,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 25,
            CODEP: '6114',
          },
          {
            IDN3: 4061,
            LIBELLEN3: 'CITE EL BHIRA',
            IDN2: 25,
            CODEP: '6141',
          },
          {
            IDN3: 4062,
            LIBELLEN3: 'CITE EL GHROB',
            IDN2: 25,
            CODEP: '6141',
          },
          {
            IDN3: 4068,
            LIBELLEN3: 'CITE EL INTILAKA',
            IDN2: 25,
            CODEP: '6114',
          },
          {
            IDN3: 4063,
            LIBELLEN3: 'CITE EL KSAR',
            IDN2: 25,
            CODEP: '6141',
          },
          {
            IDN3: 4058,
            LIBELLEN3: 'EL GARIA',
            IDN2: 25,
            CODEP: '6133',
          },
          {
            IDN3: 4069,
            LIBELLEN3: 'EL GARIA SUD',
            IDN2: 25,
            CODEP: '6114',
          },
          {
            IDN3: 4056,
            LIBELLEN3: 'EL MANSOURA',
            IDN2: 25,
            CODEP: '6131',
          },
          {
            IDN3: 4057,
            LIBELLEN3: 'EL MANSOURA NORD',
            IDN2: 25,
            CODEP: '6131',
          },
          {
            IDN3: 4065,
            LIBELLEN3: 'EZZAOUIA',
            IDN2: 25,
            CODEP: '6114',
          },
          {
            IDN3: 4055,
            LIBELLEN3: 'HAMMAM KESRA',
            IDN2: 25,
            CODEP: '6114',
          },
          {
            IDN3: 4053,
            LIBELLEN3: 'KESRA',
            IDN2: 25,
            CODEP: '6114',
          },
          {
            IDN3: 4064,
            LIBELLEN3: 'KESRA SUPERIEUR',
            IDN2: 25,
            CODEP: '6141',
          },
        ],
      },
      {
        ville: 26,
        villeName: 'LE KRIB',
        cites: [
          {
            IDN3: 4070,
            LIBELLEN3: 'BORJ MESSAOUDI',
            IDN2: 26,
            CODEP: '6125',
          },
          {
            IDN3: 4075,
            LIBELLEN3: 'CITE ABDRABBAH 1',
            IDN2: 26,
            CODEP: '6120',
          },
          {
            IDN3: 4076,
            LIBELLEN3: 'CITE ABDRABBAH 2',
            IDN2: 26,
            CODEP: '6120',
          },
          {
            IDN3: 4077,
            LIBELLEN3: 'CITE COMMERCIALE',
            IDN2: 26,
            CODEP: '6120',
          },
          {
            IDN3: 4078,
            LIBELLEN3: 'CITE DE LA CELLULE',
            IDN2: 26,
            CODEP: '6120',
          },
          {
            IDN3: 4079,
            LIBELLEN3: 'CITE DU BATTOIRE',
            IDN2: 26,
            CODEP: '6120',
          },
          {
            IDN3: 4080,
            LIBELLEN3: 'CITE EJJAMAA',
            IDN2: 26,
            CODEP: '6120',
          },
          {
            IDN3: 4081,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 26,
            CODEP: '6120',
          },
          {
            IDN3: 4083,
            LIBELLEN3: 'CITE ESSANAOUBER',
            IDN2: 26,
            CODEP: '6120',
          },
          {
            IDN3: 4084,
            LIBELLEN3: 'CITE ETTAHRIR',
            IDN2: 26,
            CODEP: '6120',
          },
          {
            IDN3: 4085,
            LIBELLEN3: 'CITE INDEPENDANCE',
            IDN2: 26,
            CODEP: '6120',
          },
          {
            IDN3: 4073,
            LIBELLEN3: 'DOUKHANIA',
            IDN2: 26,
            CODEP: '6122',
          },
          {
            IDN3: 4086,
            LIBELLEN3: 'FEJ EL HADOUM',
            IDN2: 26,
            CODEP: '6120',
          },
          {
            IDN3: 4082,
            LIBELLEN3: 'HAMMAM BIADHA',
            IDN2: 26,
            CODEP: '6132',
          },
          {
            IDN3: 4074,
            LIBELLEN3: 'LE KRIB',
            IDN2: 26,
            CODEP: '6120',
          },
          {
            IDN3: 4088,
            LIBELLEN3: 'MERKEZ BEN NJAH',
            IDN2: 26,
            CODEP: '6120',
          },
          {
            IDN3: 4071,
            LIBELLEN3: 'OUED TESSA EL MORR',
            IDN2: 26,
            CODEP: '6120',
          },
          {
            IDN3: 4072,
            LIBELLEN3: 'OULED BOUZID',
            IDN2: 26,
            CODEP: '6120',
          },
          {
            IDN3: 4087,
            LIBELLEN3: 'SOUK EL JOMAA',
            IDN2: 26,
            CODEP: '6132',
          },
        ],
      },
      {
        ville: 29,
        villeName: 'MAKTHAR',
        cites: [
          {
            IDN3: 4137,
            LIBELLEN3: 'BENI HAZAM',
            IDN2: 29,
            CODEP: '6140',
          },
          {
            IDN3: 4138,
            LIBELLEN3: 'CHOUARNIA',
            IDN2: 29,
            CODEP: '6140',
          },
          {
            IDN3: 4139,
            LIBELLEN3: 'CITE DEBBICHE',
            IDN2: 29,
            CODEP: '6140',
          },
          {
            IDN3: 4140,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 29,
            CODEP: '6140',
          },
          {
            IDN3: 4141,
            LIBELLEN3: 'CITE EL OUNS 1',
            IDN2: 29,
            CODEP: '6140',
          },
          {
            IDN3: 4142,
            LIBELLEN3: 'CITE EL OUNS 2',
            IDN2: 29,
            CODEP: '6140',
          },
          {
            IDN3: 4143,
            LIBELLEN3: 'CITE ENNAHALA',
            IDN2: 29,
            CODEP: '6140',
          },
          {
            IDN3: 4144,
            LIBELLEN3: 'CITE ESSIADA',
            IDN2: 29,
            CODEP: '6140',
          },
          {
            IDN3: 4145,
            LIBELLEN3: 'CITE KSIBA',
            IDN2: 29,
            CODEP: '6140',
          },
          {
            IDN3: 4146,
            LIBELLEN3: 'CITE MONGI SLIM 1',
            IDN2: 29,
            CODEP: '6140',
          },
          {
            IDN3: 4147,
            LIBELLEN3: 'CITE MONGI SLIM 2',
            IDN2: 29,
            CODEP: '6140',
          },
          {
            IDN3: 4148,
            LIBELLEN3: 'CITE NOUVELLE',
            IDN2: 29,
            CODEP: '6140',
          },
          {
            IDN3: 4149,
            LIBELLEN3: 'EL GARAA',
            IDN2: 29,
            CODEP: '6140',
          },
          {
            IDN3: 4136,
            LIBELLEN3: 'EL KHALSA',
            IDN2: 29,
            CODEP: '6151',
          },
          {
            IDN3: 4151,
            LIBELLEN3: 'HOUCH SFAYA',
            IDN2: 29,
            CODEP: '6140',
          },
          {
            IDN3: 4152,
            LIBELLEN3: 'KHIZRANE',
            IDN2: 29,
            CODEP: '6140',
          },
          {
            IDN3: 4133,
            LIBELLEN3: 'MAKTHAR',
            IDN2: 29,
            CODEP: '6140',
          },
          {
            IDN3: 4150,
            LIBELLEN3: 'RAS EL OUED',
            IDN2: 29,
            CODEP: '6140',
          },
          {
            IDN3: 4135,
            LIBELLEN3: 'SNED HADDAD',
            IDN2: 29,
            CODEP: '6142',
          },
          {
            IDN3: 4134,
            LIBELLEN3: 'SOUK JOMAA',
            IDN2: 29,
            CODEP: '6140',
          },
          {
            IDN3: 4132,
            LIBELLEN3: 'ZOUAKRA',
            IDN2: 29,
            CODEP: '6140',
          },
        ],
      },
      {
        ville: 30,
        villeName: 'ROHIA',
        cites: [
          {
            IDN3: 4167,
            LIBELLEN3: '18 JANVIER',
            IDN2: 30,
            CODEP: '6150',
          },
          {
            IDN3: 4159,
            LIBELLEN3: 'CITE EL OUNS 1',
            IDN2: 30,
            CODEP: '6150',
          },
          {
            IDN3: 4160,
            LIBELLEN3: 'CITE EL OUNS 2',
            IDN2: 30,
            CODEP: '6150',
          },
          {
            IDN3: 4161,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 30,
            CODEP: '6150',
          },
          {
            IDN3: 4162,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 30,
            CODEP: '6152',
          },
          {
            IDN3: 4163,
            LIBELLEN3: 'CITE ESSAADA',
            IDN2: 30,
            CODEP: '6150',
          },
          {
            IDN3: 4164,
            LIBELLEN3: 'CITE ETTAHRIR',
            IDN2: 30,
            CODEP: '6150',
          },
          {
            IDN3: 4165,
            LIBELLEN3: 'CITE HEDI KHFACHA',
            IDN2: 30,
            CODEP: '6150',
          },
          {
            IDN3: 4166,
            LIBELLEN3: 'EL HARIA',
            IDN2: 30,
            CODEP: '6150',
          },
          {
            IDN3: 4168,
            LIBELLEN3: 'EL HMAIMA',
            IDN2: 30,
            CODEP: '6150',
          },
          {
            IDN3: 4169,
            LIBELLEN3: 'EL MSAHLA',
            IDN2: 30,
            CODEP: '6150',
          },
          {
            IDN3: 4154,
            LIBELLEN3: 'FIDH HAMMED',
            IDN2: 30,
            CODEP: '6150',
          },
          {
            IDN3: 4153,
            LIBELLEN3: 'FOUNDOUK DEBBICH',
            IDN2: 30,
            CODEP: '6150',
          },
          {
            IDN3: 4157,
            LIBELLEN3: 'HEBABSA SUD',
            IDN2: 30,
            CODEP: '6152',
          },
          {
            IDN3: 4155,
            LIBELLEN3: 'JEMILETTE',
            IDN2: 30,
            CODEP: '6150',
          },
          {
            IDN3: 4158,
            LIBELLEN3: 'MAGROUNA',
            IDN2: 30,
            CODEP: '6152',
          },
          {
            IDN3: 4156,
            LIBELLEN3: 'ROHIA',
            IDN2: 30,
            CODEP: '6150',
          },
        ],
      },
      {
        ville: 328,
        villeName: 'SIDI BOU ROUIS',
        cites: [
          {
            IDN3: 4004,
            LIBELLEN3: 'AIN ACHOUR',
            IDN2: 328,
            CODEP: '6113',
          },
          {
            IDN3: 4005,
            LIBELLEN3: 'BOU ROUIS',
            IDN2: 328,
            CODEP: '6113',
          },
          {
            IDN3: 4002,
            LIBELLEN3: 'BOU ROUIS FILAHI',
            IDN2: 328,
            CODEP: '6134',
          },
          {
            IDN3: 4006,
            LIBELLEN3: 'CITE 7 NOVEMBRE',
            IDN2: 328,
            CODEP: '6113',
          },
          {
            IDN3: 4007,
            LIBELLEN3: 'CITE CHEMINOTS',
            IDN2: 328,
            CODEP: '6113',
          },
          {
            IDN3: 4008,
            LIBELLEN3: 'CITE DU BATTOIRE',
            IDN2: 328,
            CODEP: '6113',
          },
          {
            IDN3: 4009,
            LIBELLEN3: 'CITE DU LYCEE',
            IDN2: 328,
            CODEP: '6113',
          },
          {
            IDN3: 4010,
            LIBELLEN3: 'CITE EJJAMAA',
            IDN2: 328,
            CODEP: '6113',
          },
          {
            IDN3: 4011,
            LIBELLEN3: 'KRIB GARE',
            IDN2: 328,
            CODEP: '6112',
          },
          {
            IDN3: 4001,
            LIBELLEN3: 'NECHEM',
            IDN2: 328,
            CODEP: '6113',
          },
          {
            IDN3: 4003,
            LIBELLEN3: 'OULED SELIT',
            IDN2: 328,
            CODEP: '6134',
          },
        ],
      },
      {
        ville: 329,
        villeName: 'SILIANA NORD',
        cites: [
          {
            IDN3: 4012,
            LIBELLEN3: 'CITE CNRPS',
            IDN2: 329,
            CODEP: '6100',
          },
          {
            IDN3: 4013,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 329,
            CODEP: '6100',
          },
          {
            IDN3: 4014,
            LIBELLEN3: 'CITE EL YASMINE',
            IDN2: 329,
            CODEP: '6100',
          },
          {
            IDN3: 4015,
            LIBELLEN3: 'CITE ENNOUR 1',
            IDN2: 329,
            CODEP: '6100',
          },
          {
            IDN3: 4016,
            LIBELLEN3: 'CITE ENNOUR 2',
            IDN2: 329,
            CODEP: '6100',
          },
          {
            IDN3: 4017,
            LIBELLEN3: 'CITE ENNOUZHA',
            IDN2: 329,
            CODEP: '6100',
          },
          {
            IDN3: 4024,
            LIBELLEN3: 'CITE ESSALAH',
            IDN2: 329,
            CODEP: '6130',
          },
          {
            IDN3: 4025,
            LIBELLEN3: 'CITE ESSALAH 2',
            IDN2: 329,
            CODEP: '6130',
          },
          {
            IDN3: 4018,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 329,
            CODEP: '6100',
          },
          {
            IDN3: 4019,
            LIBELLEN3: 'CITE MISKIA',
            IDN2: 329,
            CODEP: '6100',
          },
          {
            IDN3: 4020,
            LIBELLEN3: 'CITE OFFICE CEREALE',
            IDN2: 329,
            CODEP: '6100',
          },
          {
            IDN3: 4021,
            LIBELLEN3: 'CITE TAIEB MHIRI',
            IDN2: 329,
            CODEP: '6100',
          },
          {
            IDN3: 4022,
            LIBELLEN3: 'JAMA',
            IDN2: 329,
            CODEP: '6100',
          },
          {
            IDN3: 4023,
            LIBELLEN3: 'SIDI JABEUR',
            IDN2: 329,
            CODEP: '6100',
          },
        ],
      },
      {
        ville: 327,
        villeName: 'SILIANA SUD',
        cites: [
          {
            IDN3: 3992,
            LIBELLEN3: 'AIN DISSA',
            IDN2: 327,
            CODEP: '6100',
          },
          {
            IDN3: 4000,
            LIBELLEN3: 'AIN EL JOUZA',
            IDN2: 327,
            CODEP: '6123',
          },
          {
            IDN3: 3994,
            LIBELLEN3: 'CITE DE LA REPUBLIQUE',
            IDN2: 327,
            CODEP: '6100',
          },
          {
            IDN3: 3998,
            LIBELLEN3: 'CITE DU LYCEE',
            IDN2: 327,
            CODEP: '6100',
          },
          {
            IDN3: 3999,
            LIBELLEN3: 'CITE EL AIDA',
            IDN2: 327,
            CODEP: '6100',
          },
          {
            IDN3: 3981,
            LIBELLEN3: 'CITE ERRIADH 1',
            IDN2: 327,
            CODEP: '6100',
          },
          {
            IDN3: 3984,
            LIBELLEN3: 'CITE ERRIADH 2',
            IDN2: 327,
            CODEP: '6100',
          },
          {
            IDN3: 3987,
            LIBELLEN3: 'CITE HACHED',
            IDN2: 327,
            CODEP: '6100',
          },
          {
            IDN3: 3988,
            LIBELLEN3: 'CITE MONGI SLIM',
            IDN2: 327,
            CODEP: '6100',
          },
          {
            IDN3: 3976,
            LIBELLEN3: 'DOUAR EZZRIBA',
            IDN2: 327,
            CODEP: '6196',
          },
          {
            IDN3: 3989,
            LIBELLEN3: 'EL GABEL',
            IDN2: 327,
            CODEP: '6100',
          },
          {
            IDN3: 3985,
            LIBELLEN3: 'EL GANARA',
            IDN2: 327,
            CODEP: '6124',
          },
          {
            IDN3: 3977,
            LIBELLEN3: 'EL GHEMALIA',
            IDN2: 327,
            CODEP: '6196',
          },
          {
            IDN3: 3978,
            LIBELLEN3: 'EL KANTRA',
            IDN2: 327,
            CODEP: '6123',
          },
          {
            IDN3: 3990,
            LIBELLEN3: 'EL MEGARBA',
            IDN2: 327,
            CODEP: '6100',
          },
          {
            IDN3: 3975,
            LIBELLEN3: 'ESSAFINA',
            IDN2: 327,
            CODEP: '6143',
          },
          {
            IDN3: 3991,
            LIBELLEN3: 'FIDH AROUS',
            IDN2: 327,
            CODEP: '6100',
          },
          {
            IDN3: 3993,
            LIBELLEN3: 'GABR EL GHOUL',
            IDN2: 327,
            CODEP: '6100',
          },
          {
            IDN3: 3983,
            LIBELLEN3: 'GHARS  EZZRIBA',
            IDN2: 327,
            CODEP: '6196',
          },
          {
            IDN3: 3995,
            LIBELLEN3: 'KSAR HADID',
            IDN2: 327,
            CODEP: '6100',
          },
          {
            IDN3: 3974,
            LIBELLEN3: 'RAS EL MA',
            IDN2: 327,
            CODEP: '6196',
          },
          {
            IDN3: 3980,
            LIBELLEN3: 'SIDI AMEUR',
            IDN2: 327,
            CODEP: '6196',
          },
          {
            IDN3: 3986,
            LIBELLEN3: 'SIDI HAMADA',
            IDN2: 327,
            CODEP: '6124',
          },
          {
            IDN3: 3996,
            LIBELLEN3: 'SIDI MANSOUR',
            IDN2: 327,
            CODEP: '6100',
          },
          {
            IDN3: 3982,
            LIBELLEN3: 'SIDI MORCHED',
            IDN2: 327,
            CODEP: '6196',
          },
          {
            IDN3: 3997,
            LIBELLEN3: 'SILIANA',
            IDN2: 327,
            CODEP: '6100',
          },
          {
            IDN3: 3979,
            LIBELLEN3: 'ZAOUIET SIDI ABDELMALEK',
            IDN2: 327,
            CODEP: '6123',
          },
        ],
      },
    ],
  },
  {
    govLabel: 'SOUSSE',
    govId: 20,
    villes: [
      {
        ville: 38,
        villeName: 'AKOUDA',
        cites: [
          {
            IDN3: 4250,
            LIBELLEN3: 'AKOUDA',
            IDN2: 38,
            CODEP: '4022',
          },
          {
            IDN3: 4249,
            LIBELLEN3: 'CHATT MERIEM',
            IDN2: 38,
            CODEP: '4042',
          },
          {
            IDN3: 4251,
            LIBELLEN3: 'CITE DU DISPENSAIRE',
            IDN2: 38,
            CODEP: '4042',
          },
          {
            IDN3: 4252,
            LIBELLEN3: 'CITE ECOLE SUPERIEURE',
            IDN2: 38,
            CODEP: '4042',
          },
          {
            IDN3: 4253,
            LIBELLEN3: 'CITE EJJAMAA',
            IDN2: 38,
            CODEP: '4042',
          },
          {
            IDN3: 4247,
            LIBELLEN3: 'HALG EL MENJEL',
            IDN2: 38,
            CODEP: '4042',
          },
          {
            IDN3: 4248,
            LIBELLEN3: 'TANTANA',
            IDN2: 38,
            CODEP: '4042',
          },
        ],
      },
      {
        ville: 37,
        villeName: 'BOU FICHA',
        cites: [
          {
            IDN3: 4244,
            LIBELLEN3: 'AIN ERRAHMA',
            IDN2: 37,
            CODEP: '4092',
          },
          {
            IDN3: 4240,
            LIBELLEN3: 'BOU FICHA',
            IDN2: 37,
            CODEP: '4010',
          },
          {
            IDN3: 4241,
            LIBELLEN3: 'CITE 20 MARS',
            IDN2: 37,
            CODEP: '4010',
          },
          {
            IDN3: 4245,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 37,
            CODEP: '4010',
          },
          {
            IDN3: 4246,
            LIBELLEN3: 'CITE ETTAHRIR',
            IDN2: 37,
            CODEP: '4010',
          },
          {
            IDN3: 4233,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 37,
            CODEP: '4010',
          },
          {
            IDN3: 4234,
            LIBELLEN3: 'CITE HACHED',
            IDN2: 37,
            CODEP: '4010',
          },
          {
            IDN3: 4237,
            LIBELLEN3: 'CITE MILITAIRE',
            IDN2: 37,
            CODEP: '4010',
          },
          {
            IDN3: 4242,
            LIBELLEN3: 'CITE SNIT',
            IDN2: 37,
            CODEP: '4010',
          },
          {
            IDN3: 4235,
            LIBELLEN3: 'ESSALLOUM',
            IDN2: 37,
            CODEP: '4010',
          },
          {
            IDN3: 4238,
            LIBELLEN3: 'LENDERIA',
            IDN2: 37,
            CODEP: '4010',
          },
          {
            IDN3: 4232,
            LIBELLEN3: 'OUED EL KHARROUB',
            IDN2: 37,
            CODEP: '4010',
          },
          {
            IDN3: 4243,
            LIBELLEN3: 'SIDI KHELIFA',
            IDN2: 37,
            CODEP: '4082',
          },
          {
            IDN3: 4236,
            LIBELLEN3: 'SIDI MTIR',
            IDN2: 37,
            CODEP: '4010',
          },
          {
            IDN3: 4239,
            LIBELLEN3: 'SIDI SAID',
            IDN2: 37,
            CODEP: '4010',
          },
        ],
      },
      {
        ville: 43,
        villeName: 'ENFIDHA',
        cites: [
          {
            IDN3: 4330,
            LIBELLEN3: 'AIN GARCI',
            IDN2: 43,
            CODEP: '4035',
          },
          {
            IDN3: 4298,
            LIBELLEN3: 'AIN MEDHEKER',
            IDN2: 43,
            CODEP: '4095',
          },
          {
            IDN3: 4329,
            LIBELLEN3: 'CHEGARNIA',
            IDN2: 43,
            CODEP: '4034',
          },
          {
            IDN3: 4297,
            LIBELLEN3: 'CITE 7 NOVEMBRE',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4299,
            LIBELLEN3: 'CITE CIMENTERIE',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4300,
            LIBELLEN3: 'CITE DE L ACTION',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4301,
            LIBELLEN3: 'CITE EL BIDHANE',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4302,
            LIBELLEN3: 'CITE EL YASMINE',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4303,
            LIBELLEN3: 'CITE ENVIRONNEMENT',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4304,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4305,
            LIBELLEN3: 'CITE ESSALEM',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4306,
            LIBELLEN3: 'CITE ETTAHRIR',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4307,
            LIBELLEN3: 'CITE EZZAYATINE',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4308,
            LIBELLEN3: 'CITE HACHED',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4309,
            LIBELLEN3: 'CITE MOHAMED ALI',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4310,
            LIBELLEN3: 'DHEHAIBIA',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4311,
            LIBELLEN3: 'EL FRADA',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4312,
            LIBELLEN3: 'EL GHOUALIF',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4313,
            LIBELLEN3: 'EL GHOUILET',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4314,
            LIBELLEN3: 'ENFIDHA',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4315,
            LIBELLEN3: 'ESSAFHA',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4316,
            LIBELLEN3: 'ESSMAIDIA',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4317,
            LIBELLEN3: 'GRIMIT EST',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4318,
            LIBELLEN3: 'GRIMIT OUEST',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4319,
            LIBELLEN3: 'HICHER',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4328,
            LIBELLEN3: 'MENZEL BEL OUAER',
            IDN2: 43,
            CODEP: '4032',
          },
          {
            IDN3: 4320,
            LIBELLEN3: 'MENZEL FATEH',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4321,
            LIBELLEN3: 'METHALITHE',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4322,
            LIBELLEN3: 'MRABET HACHED',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4323,
            LIBELLEN3: 'OULED ABDALLAH',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4324,
            LIBELLEN3: 'OULED BELLIL',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4325,
            LIBELLEN3: 'OULED MOHAMED',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4326,
            LIBELLEN3: 'SIDI SAIDANE',
            IDN2: 43,
            CODEP: '4030',
          },
          {
            IDN3: 4327,
            LIBELLEN3: 'TAKROUNA',
            IDN2: 43,
            CODEP: '4030',
          },
        ],
      },
      {
        ville: 39,
        villeName: 'HAMMAM SOUSSE',
        cites: [
          {
            IDN3: 4255,
            LIBELLEN3: 'CITE DE LA PLAGE 1',
            IDN2: 39,
            CODEP: '4017',
          },
          {
            IDN3: 4256,
            LIBELLEN3: 'CITE DE LA PLAGE 2',
            IDN2: 39,
            CODEP: '4017',
          },
          {
            IDN3: 4257,
            LIBELLEN3: 'CITE ENNARJES 1',
            IDN2: 39,
            CODEP: '4017',
          },
          {
            IDN3: 4258,
            LIBELLEN3: 'CITE ENNARJES 2',
            IDN2: 39,
            CODEP: '4017',
          },
          {
            IDN3: 4259,
            LIBELLEN3: 'CITE EZZITOUNA 1',
            IDN2: 39,
            CODEP: '4089',
          },
          {
            IDN3: 4263,
            LIBELLEN3: 'CITE EZZITOUNA 2',
            IDN2: 39,
            CODEP: '4089',
          },
          {
            IDN3: 4261,
            LIBELLEN3: 'CITE JAOUHARA',
            IDN2: 39,
            CODEP: '4017',
          },
          {
            IDN3: 4264,
            LIBELLEN3: 'CITE PRESIDENTIELLE',
            IDN2: 39,
            CODEP: '4089',
          },
          {
            IDN3: 4262,
            LIBELLEN3: 'EL KANTAOUI',
            IDN2: 39,
            CODEP: '4089',
          },
          {
            IDN3: 4260,
            LIBELLEN3: 'HAMMAM SOUSSE',
            IDN2: 39,
            CODEP: '4011',
          },
          {
            IDN3: 4254,
            LIBELLEN3: 'HAMMAM SOUSSE GHARBI',
            IDN2: 39,
            CODEP: '4017',
          },
        ],
      },
      {
        ville: 44,
        villeName: 'HERGLA',
        cites: [
          {
            IDN3: 4331,
            LIBELLEN3: 'EL ARIBAT',
            IDN2: 44,
            CODEP: '4012',
          },
          {
            IDN3: 4332,
            LIBELLEN3: 'HERGLA',
            IDN2: 44,
            CODEP: '4012',
          },
          {
            IDN3: 4333,
            LIBELLEN3: 'SOUAYEH',
            IDN2: 44,
            CODEP: '4012',
          },
        ],
      },
      {
        ville: 45,
        villeName: 'KALAA EL KEBIRA',
        cites: [
          {
            IDN3: 4334,
            LIBELLEN3: 'AHEL  JEMIAA',
            IDN2: 45,
            CODEP: '4060',
          },
          {
            IDN3: 4335,
            LIBELLEN3: 'BELAOUM',
            IDN2: 45,
            CODEP: '4060',
          },
          {
            IDN3: 4353,
            LIBELLEN3: 'CHIAB',
            IDN2: 45,
            CODEP: '4063',
          },
          {
            IDN3: 4336,
            LIBELLEN3: 'CITE BIR CHEMSI',
            IDN2: 45,
            CODEP: '4060',
          },
          {
            IDN3: 4337,
            LIBELLEN3: 'CITE BIR HLAOUA',
            IDN2: 45,
            CODEP: '4060',
          },
          {
            IDN3: 4338,
            LIBELLEN3: 'CITE CHARGUI',
            IDN2: 45,
            CODEP: '4060',
          },
          {
            IDN3: 4339,
            LIBELLEN3: 'CITE COMMERCIALE',
            IDN2: 45,
            CODEP: '4060',
          },
          {
            IDN3: 4340,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 45,
            CODEP: '4060',
          },
          {
            IDN3: 4341,
            LIBELLEN3: 'CITE EL INTILAKA',
            IDN2: 45,
            CODEP: '4060',
          },
          {
            IDN3: 4342,
            LIBELLEN3: 'CITE EL JEBS',
            IDN2: 45,
            CODEP: '4060',
          },
          {
            IDN3: 4343,
            LIBELLEN3: 'CITE EL MANSOURA',
            IDN2: 45,
            CODEP: '4060',
          },
          {
            IDN3: 4344,
            LIBELLEN3: 'CITE ENNOUZHA 1',
            IDN2: 45,
            CODEP: '4060',
          },
          {
            IDN3: 4345,
            LIBELLEN3: 'CITE ENNOUZHA 2',
            IDN2: 45,
            CODEP: '4060',
          },
          {
            IDN3: 4346,
            LIBELLEN3: 'CITE FADDEN AOUN',
            IDN2: 45,
            CODEP: '4060',
          },
          {
            IDN3: 4347,
            LIBELLEN3: 'CITE NOUVELLE',
            IDN2: 45,
            CODEP: '4060',
          },
          {
            IDN3: 4348,
            LIBELLEN3: 'CITE SIDI ZAIED',
            IDN2: 45,
            CODEP: '4060',
          },
          {
            IDN3: 4349,
            LIBELLEN3: 'CITE ZONE DES METIERS',
            IDN2: 45,
            CODEP: '4060',
          },
          {
            IDN3: 4350,
            LIBELLEN3: 'EL KRARIA',
            IDN2: 45,
            CODEP: '4060',
          },
          {
            IDN3: 4351,
            LIBELLEN3: 'KALAA EL KEBIRA',
            IDN2: 45,
            CODEP: '4060',
          },
          {
            IDN3: 4354,
            LIBELLEN3: 'KALAA EL KEBIRA KSAR',
            IDN2: 45,
            CODEP: '4062',
          },
          {
            IDN3: 4352,
            LIBELLEN3: 'OULED LESSEOUD',
            IDN2: 45,
            CODEP: '4060',
          },
        ],
      },
      {
        ville: 40,
        villeName: 'KALAA ESSGHIRA',
        cites: [
          {
            IDN3: 4271,
            LIBELLEN3: 'CITE CHABBETT EZZAOUIA',
            IDN2: 40,
            CODEP: '4021',
          },
          {
            IDN3: 4265,
            LIBELLEN3: 'CITE CHRAGUI',
            IDN2: 40,
            CODEP: '4021',
          },
          {
            IDN3: 4266,
            LIBELLEN3: 'CITE EL MANEZEH',
            IDN2: 40,
            CODEP: '4021',
          },
          {
            IDN3: 4267,
            LIBELLEN3: 'CITE KHAZZEN EL MA',
            IDN2: 40,
            CODEP: '4021',
          },
          {
            IDN3: 4268,
            LIBELLEN3: 'ENNAGR',
            IDN2: 40,
            CODEP: '4021',
          },
          {
            IDN3: 4269,
            LIBELLEN3: 'KALAA ESSGHIRA',
            IDN2: 40,
            CODEP: '4021',
          },
          {
            IDN3: 4270,
            LIBELLEN3: 'OUED LAYA',
            IDN2: 40,
            CODEP: '4021',
          },
        ],
      },
      {
        ville: 41,
        villeName: 'KONDAR',
        cites: [
          {
            IDN3: 4272,
            LIBELLEN3: 'BIR JEDID',
            IDN2: 41,
            CODEP: '4020',
          },
          {
            IDN3: 4273,
            LIBELLEN3: 'BLELMA',
            IDN2: 41,
            CODEP: '4020',
          },
          {
            IDN3: 4274,
            LIBELLEN3: 'EL BECHACHMA',
            IDN2: 41,
            CODEP: '4020',
          },
          {
            IDN3: 4275,
            LIBELLEN3: 'KONDAR',
            IDN2: 41,
            CODEP: '4020',
          },
          {
            IDN3: 4276,
            LIBELLEN3: 'OULED AMEUR',
            IDN2: 41,
            CODEP: '4020',
          },
          {
            IDN3: 4277,
            LIBELLEN3: 'OULED EL ABED',
            IDN2: 41,
            CODEP: '4020',
          },
          {
            IDN3: 4278,
            LIBELLEN3: 'TARHOUNA',
            IDN2: 41,
            CODEP: '4020',
          },
        ],
      },
      {
        ville: 42,
        villeName: 'MSAKEN',
        cites: [
          {
            IDN3: 4292,
            LIBELLEN3: 'BENI KALTHOUM',
            IDN2: 42,
            CODEP: '4016',
          },
          {
            IDN3: 4282,
            LIBELLEN3: 'BENI RABIA',
            IDN2: 42,
            CODEP: '4015',
          },
          {
            IDN3: 4280,
            LIBELLEN3: 'CHIHIA',
            IDN2: 42,
            CODEP: '4014',
          },
          {
            IDN3: 4283,
            LIBELLEN3: 'CITE CIMETIERE',
            IDN2: 42,
            CODEP: '4015',
          },
          {
            IDN3: 4284,
            LIBELLEN3: 'CITE DAR ESSEGHAIR',
            IDN2: 42,
            CODEP: '4015',
          },
          {
            IDN3: 4285,
            LIBELLEN3: 'CITE ECOLE PRIMAIRE',
            IDN2: 42,
            CODEP: '4015',
          },
          {
            IDN3: 4286,
            LIBELLEN3: 'CITE EL MANSOUR',
            IDN2: 42,
            CODEP: '4015',
          },
          {
            IDN3: 4287,
            LIBELLEN3: 'CITE HEDI GACHACHA',
            IDN2: 42,
            CODEP: '4015',
          },
          {
            IDN3: 4288,
            LIBELLEN3: 'CITE RACHED EL OUERTANI',
            IDN2: 42,
            CODEP: '4015',
          },
          {
            IDN3: 4289,
            LIBELLEN3: 'CITE SLAMA',
            IDN2: 42,
            CODEP: '4015',
          },
          {
            IDN3: 4290,
            LIBELLEN3: 'EL BORJINE',
            IDN2: 42,
            CODEP: '4015',
          },
          {
            IDN3: 4291,
            LIBELLEN3: 'EL FRADA',
            IDN2: 42,
            CODEP: '4015',
          },
          {
            IDN3: 4281,
            LIBELLEN3: 'EL KNAIES',
            IDN2: 42,
            CODEP: '4014',
          },
          {
            IDN3: 4279,
            LIBELLEN3: 'MESSADINE',
            IDN2: 42,
            CODEP: '4013',
          },
          {
            IDN3: 4295,
            LIBELLEN3: 'MOUREDDINE',
            IDN2: 42,
            CODEP: '4033',
          },
          {
            IDN3: 4293,
            LIBELLEN3: 'MSAKEN',
            IDN2: 42,
            CODEP: '4070',
          },
          {
            IDN3: 4294,
            LIBELLEN3: 'MSAKEN EL GUEBLIA',
            IDN2: 42,
            CODEP: '4099',
          },
          {
            IDN3: 4296,
            LIBELLEN3: 'MSAKEN HAI JEDID',
            IDN2: 42,
            CODEP: '4024',
          },
        ],
      },
      {
        ville: 32,
        villeName: 'SIDI BOU ALI',
        cites: [
          {
            IDN3: 4182,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 32,
            CODEP: '4040',
          },
          {
            IDN3: 4183,
            LIBELLEN3: 'CITE IBN KHALDOUN',
            IDN2: 32,
            CODEP: '4040',
          },
          {
            IDN3: 4186,
            LIBELLEN3: 'EL AITHA',
            IDN2: 32,
            CODEP: '4040',
          },
          {
            IDN3: 4185,
            LIBELLEN3: 'EL ALOUJ',
            IDN2: 32,
            CODEP: '4040',
          },
          {
            IDN3: 4184,
            LIBELLEN3: 'EL ARAYDHIA',
            IDN2: 32,
            CODEP: '4040',
          },
          {
            IDN3: 4180,
            LIBELLEN3: 'EL MHEDHBA',
            IDN2: 32,
            CODEP: '4040',
          },
          {
            IDN3: 4178,
            LIBELLEN3: 'ESSAD NORD',
            IDN2: 32,
            CODEP: '4045',
          },
          {
            IDN3: 4181,
            LIBELLEN3: 'ESSAD SUD',
            IDN2: 32,
            CODEP: '4040',
          },
          {
            IDN3: 4179,
            LIBELLEN3: 'KENANA',
            IDN2: 32,
            CODEP: '4040',
          },
          {
            IDN3: 4177,
            LIBELLEN3: 'MENZEL GARE',
            IDN2: 32,
            CODEP: '4043',
          },
          {
            IDN3: 4175,
            LIBELLEN3: 'OURIEMMA',
            IDN2: 32,
            CODEP: '4040',
          },
          {
            IDN3: 4176,
            LIBELLEN3: 'SIDI BOU ALI',
            IDN2: 32,
            CODEP: '4040',
          },
        ],
      },
      {
        ville: 33,
        villeName: 'SIDI EL HENI',
        cites: [
          {
            IDN3: 4197,
            LIBELLEN3: 'CHERACHIR',
            IDN2: 33,
            CODEP: '4026',
          },
          {
            IDN3: 4187,
            LIBELLEN3: 'EL MOUISSETTE',
            IDN2: 33,
            CODEP: '4025',
          },
          {
            IDN3: 4188,
            LIBELLEN3: 'GHABGHOUB',
            IDN2: 33,
            CODEP: '4025',
          },
          {
            IDN3: 4198,
            LIBELLEN3: 'KROUSSIA',
            IDN2: 33,
            CODEP: '4026',
          },
          {
            IDN3: 4189,
            LIBELLEN3: 'OULED ALI B HANI',
            IDN2: 33,
            CODEP: '4025',
          },
          {
            IDN3: 4190,
            LIBELLEN3: 'OULED ALOUENE',
            IDN2: 33,
            CODEP: '4025',
          },
          {
            IDN3: 4191,
            LIBELLEN3: 'OULED AMOR',
            IDN2: 33,
            CODEP: '4025',
          },
          {
            IDN3: 4192,
            LIBELLEN3: 'OULED BOUBAKER',
            IDN2: 33,
            CODEP: '4025',
          },
          {
            IDN3: 4193,
            LIBELLEN3: 'OULED EL KBAIER',
            IDN2: 33,
            CODEP: '4025',
          },
          {
            IDN3: 4194,
            LIBELLEN3: 'OULED EL KHECHINE',
            IDN2: 33,
            CODEP: '4025',
          },
          {
            IDN3: 4195,
            LIBELLEN3: 'SIDI EL HENI',
            IDN2: 33,
            CODEP: '4025',
          },
          {
            IDN3: 4196,
            LIBELLEN3: 'ZERDOUB',
            IDN2: 33,
            CODEP: '4025',
          },
        ],
      },
      {
        ville: 46,
        villeName: 'SOUSSE',
        cites: [
          {
            IDN3: 4355,
            LIBELLEN3: 'SOUSSE',
            IDN2: 46,
            CODEP: '4004',
          },
        ],
      },
      {
        ville: 34,
        villeName: 'SOUSSE JAOUHARA',
        cites: [
          {
            IDN3: 4204,
            LIBELLEN3: 'CITE AEROPORT',
            IDN2: 34,
            CODEP: '4061',
          },
          {
            IDN3: 4205,
            LIBELLEN3: 'CITE BATIMENTS',
            IDN2: 34,
            CODEP: '4061',
          },
          {
            IDN3: 4206,
            LIBELLEN3: 'CITE BEN ALEYA',
            IDN2: 34,
            CODEP: '4061',
          },
          {
            IDN3: 4199,
            LIBELLEN3: 'CITE DE LA STEG',
            IDN2: 34,
            CODEP: '4051',
          },
          {
            IDN3: 4207,
            LIBELLEN3: 'CITE EL GHOUDRANE',
            IDN2: 34,
            CODEP: '4061',
          },
          {
            IDN3: 4202,
            LIBELLEN3: 'CITE SAHLOUL',
            IDN2: 34,
            CODEP: '4054',
          },
          {
            IDN3: 4208,
            LIBELLEN3: 'CITE SIDI ABDELHAMID',
            IDN2: 34,
            CODEP: '4061',
          },
          {
            IDN3: 4200,
            LIBELLEN3: 'CITE SIDI DAHER',
            IDN2: 34,
            CODEP: '4051',
          },
          {
            IDN3: 4210,
            LIBELLEN3: 'KHEZAMA OUEST',
            IDN2: 34,
            CODEP: '4071',
          },
          {
            IDN3: 4203,
            LIBELLEN3: 'SAHLOUL',
            IDN2: 34,
            CODEP: '4054',
          },
          {
            IDN3: 4209,
            LIBELLEN3: 'SOUSSE IBN KHALDOUN',
            IDN2: 34,
            CODEP: '4061',
          },
          {
            IDN3: 4201,
            LIBELLEN3: 'SOUSSE KHEZAMA',
            IDN2: 34,
            CODEP: '4051',
          },
        ],
      },
      {
        ville: 35,
        villeName: 'SOUSSE RIADH',
        cites: [
          {
            IDN3: 4213,
            LIBELLEN3: 'CITE 7 NOVEMBRE',
            IDN2: 35,
            CODEP: '4041',
          },
          {
            IDN3: 4214,
            LIBELLEN3: 'CITE 7 NOVEMBRE 3',
            IDN2: 35,
            CODEP: '4041',
          },
          {
            IDN3: 4215,
            LIBELLEN3: 'CITE SINAN PACHA',
            IDN2: 35,
            CODEP: '4041',
          },
          {
            IDN3: 4216,
            LIBELLEN3: 'KSIBET SOUSSE',
            IDN2: 35,
            CODEP: '4041',
          },
          {
            IDN3: 4212,
            LIBELLEN3: 'SOUSSE EZZOUHOUR',
            IDN2: 35,
            CODEP: '4031',
          },
          {
            IDN3: 4211,
            LIBELLEN3: 'SOUSSE RIADH',
            IDN2: 35,
            CODEP: '4023',
          },
          {
            IDN3: 4217,
            LIBELLEN3: 'THRAYETTE',
            IDN2: 35,
            CODEP: '4041',
          },
          {
            IDN3: 4218,
            LIBELLEN3: 'ZAOUIET SOUSSE',
            IDN2: 35,
            CODEP: '4081',
          },
        ],
      },
      {
        ville: 36,
        villeName: 'SOUSSE VILLE',
        cites: [
          {
            IDN3: 4219,
            LIBELLEN3: 'CITE BOUKHZAR',
            IDN2: 36,
            CODEP: '4000',
          },
          {
            IDN3: 4220,
            LIBELLEN3: 'CITE CHEMINOTS',
            IDN2: 36,
            CODEP: '4000',
          },
          {
            IDN3: 4221,
            LIBELLEN3: 'CITE CNRPS',
            IDN2: 36,
            CODEP: '4000',
          },
          {
            IDN3: 4222,
            LIBELLEN3: 'CITE GARDE NATIONALE',
            IDN2: 36,
            CODEP: '4000',
          },
          {
            IDN3: 4223,
            LIBELLEN3: 'CITE HACHED',
            IDN2: 36,
            CODEP: '4000',
          },
          {
            IDN3: 4224,
            LIBELLEN3: 'CITE HADRUMETTE',
            IDN2: 36,
            CODEP: '4000',
          },
          {
            IDN3: 4225,
            LIBELLEN3: 'CITE JAOUHARA',
            IDN2: 36,
            CODEP: '4000',
          },
          {
            IDN3: 4226,
            LIBELLEN3: 'CITE KHEDHER',
            IDN2: 36,
            CODEP: '4000',
          },
          {
            IDN3: 4227,
            LIBELLEN3: 'CITE MILITAIRE',
            IDN2: 36,
            CODEP: '4000',
          },
          {
            IDN3: 4228,
            LIBELLEN3: 'CITE RIZZI',
            IDN2: 36,
            CODEP: '4000',
          },
          {
            IDN3: 4229,
            LIBELLEN3: 'CITE SPROLS',
            IDN2: 36,
            CODEP: '4000',
          },
          {
            IDN3: 4230,
            LIBELLEN3: 'SOUSSE',
            IDN2: 36,
            CODEP: '4000',
          },
          {
            IDN3: 4231,
            LIBELLEN3: 'SOUSSE CORNICHE',
            IDN2: 36,
            CODEP: '4059',
          },
        ],
      },
    ],
  },
  {
    govLabel: 'TATAOUINE',
    govId: 21,
    villes: [
      {
        ville: 48,
        villeName: 'BIR LAHMAR',
        cites: [
          {
            IDN3: 4377,
            LIBELLEN3: 'BIR LAHMAR',
            IDN2: 48,
            CODEP: '3212',
          },
          {
            IDN3: 4378,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 48,
            CODEP: '3212',
          },
          {
            IDN3: 4379,
            LIBELLEN3: 'CITE EL GRAR',
            IDN2: 48,
            CODEP: '3212',
          },
          {
            IDN3: 4380,
            LIBELLEN3: 'CITE EL MENZAH',
            IDN2: 48,
            CODEP: '3212',
          },
          {
            IDN3: 4381,
            LIBELLEN3: 'CITE ESNED',
            IDN2: 48,
            CODEP: '3212',
          },
          {
            IDN3: 4382,
            LIBELLEN3: 'EL ARGOUB',
            IDN2: 48,
            CODEP: '3212',
          },
          {
            IDN3: 4383,
            LIBELLEN3: 'GRAGAR',
            IDN2: 48,
            CODEP: '3212',
          },
          {
            IDN3: 4384,
            LIBELLEN3: 'HABHAB',
            IDN2: 48,
            CODEP: '3212',
          },
          {
            IDN3: 4385,
            LIBELLEN3: 'KSAR OULED BOUBAKER',
            IDN2: 48,
            CODEP: '3212',
          },
          {
            IDN3: 4386,
            LIBELLEN3: 'OUED EL ARFEJ',
            IDN2: 48,
            CODEP: '3212',
          },
          {
            IDN3: 4387,
            LIBELLEN3: 'OULED YAHYA',
            IDN2: 48,
            CODEP: '3212',
          },
        ],
      },
      {
        ville: 49,
        villeName: 'DHEHIBA',
        cites: [
          {
            IDN3: 4388,
            LIBELLEN3: 'CITE 7 NOVEMBRE',
            IDN2: 49,
            CODEP: '3253',
          },
          {
            IDN3: 4389,
            LIBELLEN3: 'CITE DU BAIN MAURE',
            IDN2: 49,
            CODEP: '3253',
          },
          {
            IDN3: 4390,
            LIBELLEN3: 'CITE EL AIDI',
            IDN2: 49,
            CODEP: '3253',
          },
          {
            IDN3: 4391,
            LIBELLEN3: 'CITE EL AMEL',
            IDN2: 49,
            CODEP: '3253',
          },
          {
            IDN3: 4392,
            LIBELLEN3: 'CITE ETTADHAMEN',
            IDN2: 49,
            CODEP: '3253',
          },
          {
            IDN3: 4393,
            LIBELLEN3: 'CITE ETTADHAMEN 1',
            IDN2: 49,
            CODEP: '3253',
          },
          {
            IDN3: 4394,
            LIBELLEN3: 'CITE HAFTALI',
            IDN2: 49,
            CODEP: '3253',
          },
          {
            IDN3: 4395,
            LIBELLEN3: 'CITE KSAR GUEDIM',
            IDN2: 49,
            CODEP: '3253',
          },
          {
            IDN3: 4396,
            LIBELLEN3: 'CITE OLYMPIQUE',
            IDN2: 49,
            CODEP: '3253',
          },
          {
            IDN3: 4397,
            LIBELLEN3: 'DHEHIBA',
            IDN2: 49,
            CODEP: '3253',
          },
          {
            IDN3: 4398,
            LIBELLEN3: 'OUM ZOGGAR',
            IDN2: 49,
            CODEP: '3253',
          },
          {
            IDN3: 4399,
            LIBELLEN3: 'OUNI',
            IDN2: 49,
            CODEP: '3253',
          },
        ],
      },
      {
        ville: 50,
        villeName: 'GHOMRASSEN',
        cites: [
          {
            IDN3: 4400,
            LIBELLEN3: 'CITE BAKHTIT',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4401,
            LIBELLEN3: 'CITE BIR KARMA',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4403,
            LIBELLEN3: 'CITE COMMERCIALE',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4405,
            LIBELLEN3: 'CITE DES MARTYRS',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4406,
            LIBELLEN3: 'CITE DES OASIS',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4407,
            LIBELLEN3: 'CITE DU STADE',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4408,
            LIBELLEN3: 'CITE EL AIN',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4409,
            LIBELLEN3: 'CITE EL ANOUAR',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4410,
            LIBELLEN3: 'CITE EL BICHER',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4411,
            LIBELLEN3: 'CITE EL MAARAKA',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4412,
            LIBELLEN3: 'CITE ENNAKHIL',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4413,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4414,
            LIBELLEN3: 'CITE ETTAHRIR 1',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4415,
            LIBELLEN3: 'CITE ETTAHRIR 2',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4416,
            LIBELLEN3: 'CITE FOUNESSE',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4417,
            LIBELLEN3: 'CITE IBN ARAFA',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4418,
            LIBELLEN3: 'CITE MOUZDELIFA',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4419,
            LIBELLEN3: 'CITE NOUVELLE',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4420,
            LIBELLEN3: 'CITE ROMANA',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4421,
            LIBELLEN3: 'CITE ROSFA',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4422,
            LIBELLEN3: 'CITE SIFRI',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4423,
            LIBELLEN3: 'CITE TAOUSSE',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4424,
            LIBELLEN3: 'CITE TATAOUINE',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4430,
            LIBELLEN3: 'EL FARECH',
            IDN2: 50,
            CODEP: '3241',
          },
          {
            IDN3: 4425,
            LIBELLEN3: 'EL HENCHA',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4428,
            LIBELLEN3: 'EL HORRIA',
            IDN2: 50,
            CODEP: '3224',
          },
          {
            IDN3: 4426,
            LIBELLEN3: 'GHOMRASSEN',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4404,
            LIBELLEN3: 'GUERMESSA',
            IDN2: 50,
            CODEP: '3271',
          },
          {
            IDN3: 4402,
            LIBELLEN3: 'KSAR EL HEDADA',
            IDN2: 50,
            CODEP: '3261',
          },
          {
            IDN3: 4431,
            LIBELLEN3: 'KSAR EL MOURABTINE',
            IDN2: 50,
            CODEP: '3251',
          },
          {
            IDN3: 4427,
            LIBELLEN3: 'MDHILLA',
            IDN2: 50,
            CODEP: '3220',
          },
          {
            IDN3: 4429,
            LIBELLEN3: 'OUED EL KHIL',
            IDN2: 50,
            CODEP: '3235',
          },
        ],
      },
      {
        ville: 52,
        villeName: 'REMADA',
        cites: [
          {
            IDN3: 4460,
            LIBELLEN3: 'BIR AMIR',
            IDN2: 52,
            CODEP: '3244',
          },
          {
            IDN3: 4453,
            LIBELLEN3: 'BORJ BOURGUIBA',
            IDN2: 52,
            CODEP: '3240',
          },
          {
            IDN3: 4454,
            LIBELLEN3: 'BORJ EL KHADRA',
            IDN2: 52,
            CODEP: '3240',
          },
          {
            IDN3: 4455,
            LIBELLEN3: 'EL MAGHNI',
            IDN2: 52,
            CODEP: '3240',
          },
          {
            IDN3: 4456,
            LIBELLEN3: 'EL MORRA',
            IDN2: 52,
            CODEP: '3240',
          },
          {
            IDN3: 4457,
            LIBELLEN3: 'EL OUCHOUCH',
            IDN2: 52,
            CODEP: '3240',
          },
          {
            IDN3: 4451,
            LIBELLEN3: 'KAMBOUT',
            IDN2: 52,
            CODEP: '3245',
          },
          {
            IDN3: 4452,
            LIBELLEN3: 'NEKRIF',
            IDN2: 52,
            CODEP: '3286',
          },
          {
            IDN3: 4458,
            LIBELLEN3: 'OUM SOUIGH',
            IDN2: 52,
            CODEP: '3240',
          },
          {
            IDN3: 4459,
            LIBELLEN3: 'REMADA',
            IDN2: 52,
            CODEP: '3240',
          },
        ],
      },
      {
        ville: 53,
        villeName: 'SMAR',
        cites: [
          {
            IDN3: 4477,
            LIBELLEN3: 'BENI MEHIRA',
            IDN2: 53,
            CODEP: '3262',
          },
          {
            IDN3: 4463,
            LIBELLEN3: 'CITE CASERNE',
            IDN2: 53,
            CODEP: '3223',
          },
          {
            IDN3: 4464,
            LIBELLEN3: 'CITE DU STADE',
            IDN2: 53,
            CODEP: '3223',
          },
          {
            IDN3: 4465,
            LIBELLEN3: 'EL GHARIANI',
            IDN2: 53,
            CODEP: '3223',
          },
          {
            IDN3: 4466,
            LIBELLEN3: 'EL GUETAYA',
            IDN2: 53,
            CODEP: '3223',
          },
          {
            IDN3: 4461,
            LIBELLEN3: 'EL MAOUNA',
            IDN2: 53,
            CODEP: '3200',
          },
          {
            IDN3: 4467,
            LIBELLEN3: 'EL MORRA',
            IDN2: 53,
            CODEP: '3223',
          },
          {
            IDN3: 4468,
            LIBELLEN3: 'GUALEB ERRAKHAM',
            IDN2: 53,
            CODEP: '3223',
          },
          {
            IDN3: 4469,
            LIBELLEN3: 'GUEARAAT HELAL',
            IDN2: 53,
            CODEP: '3223',
          },
          {
            IDN3: 4476,
            LIBELLEN3: 'KIRCHAOU',
            IDN2: 53,
            CODEP: '3225',
          },
          {
            IDN3: 4470,
            LIBELLEN3: 'KSAR EL GAA',
            IDN2: 53,
            CODEP: '3223',
          },
          {
            IDN3: 4462,
            LIBELLEN3: 'KSAR OUN',
            IDN2: 53,
            CODEP: '3213',
          },
          {
            IDN3: 4471,
            LIBELLEN3: 'OUDIETE ABDELOUNIS',
            IDN2: 53,
            CODEP: '3223',
          },
          {
            IDN3: 4472,
            LIBELLEN3: 'RAS EL OUED',
            IDN2: 53,
            CODEP: '3223',
          },
          {
            IDN3: 4473,
            LIBELLEN3: 'REHACH',
            IDN2: 53,
            CODEP: '3223',
          },
          {
            IDN3: 4474,
            LIBELLEN3: 'SABEG',
            IDN2: 53,
            CODEP: '3223',
          },
          {
            IDN3: 4475,
            LIBELLEN3: 'SMAR',
            IDN2: 53,
            CODEP: '3223',
          },
        ],
      },
      {
        ville: 51,
        villeName: 'TATAOUINE NORD',
        cites: [
          {
            IDN3: 4436,
            LIBELLEN3: 'CITE ABBES',
            IDN2: 51,
            CODEP: '3200',
          },
          {
            IDN3: 4437,
            LIBELLEN3: 'CITE EL MAHRAJENE',
            IDN2: 51,
            CODEP: '3200',
          },
          {
            IDN3: 4438,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 51,
            CODEP: '3200',
          },
          {
            IDN3: 4439,
            LIBELLEN3: 'CITE ENNOUZHA',
            IDN2: 51,
            CODEP: '3200',
          },
          {
            IDN3: 4434,
            LIBELLEN3: 'EL HACHANA',
            IDN2: 51,
            CODEP: '3217',
          },
          {
            IDN3: 4444,
            LIBELLEN3: 'ESSAADA',
            IDN2: 51,
            CODEP: '3217',
          },
          {
            IDN3: 4449,
            LIBELLEN3: 'EZZAHRA TATAOUINE',
            IDN2: 51,
            CODEP: '3272',
          },
          {
            IDN3: 4445,
            LIBELLEN3: 'GATTOUFA',
            IDN2: 51,
            CODEP: '3233',
          },
          {
            IDN3: 4440,
            LIBELLEN3: 'KHETMA',
            IDN2: 51,
            CODEP: '3200',
          },
          {
            IDN3: 4441,
            LIBELLEN3: 'KSAR BHIR',
            IDN2: 51,
            CODEP: '3200',
          },
          {
            IDN3: 4442,
            LIBELLEN3: 'KSAR EL GALAA',
            IDN2: 51,
            CODEP: '3200',
          },
          {
            IDN3: 4450,
            LIBELLEN3: 'KSAR OULED SOLTAN',
            IDN2: 51,
            CODEP: '3282',
          },
          {
            IDN3: 4446,
            LIBELLEN3: 'OUED EL GHAR',
            IDN2: 51,
            CODEP: '3243',
          },
          {
            IDN3: 4443,
            LIBELLEN3: 'REMTHA',
            IDN2: 51,
            CODEP: '3200',
          },
          {
            IDN3: 4435,
            LIBELLEN3: 'TAMAZOUT',
            IDN2: 51,
            CODEP: '3200',
          },
          {
            IDN3: 4432,
            LIBELLEN3: 'TAMELEST',
            IDN2: 51,
            CODEP: '3200',
          },
          {
            IDN3: 4448,
            LIBELLEN3: 'TATAOUINE Ettahrir',
            IDN2: 51,
            CODEP: '3263',
          },
          {
            IDN3: 4433,
            LIBELLEN3: 'TLELET',
            IDN2: 51,
            CODEP: '3214',
          },
          {
            IDN3: 4447,
            LIBELLEN3: 'ZMILET SABER',
            IDN2: 51,
            CODEP: '3243',
          },
        ],
      },
      {
        ville: 47,
        villeName: 'TATAOUINE SUD',
        cites: [
          {
            IDN3: 4360,
            LIBELLEN3: 'BENI BARKA',
            IDN2: 47,
            CODEP: '3215',
          },
          {
            IDN3: 4368,
            LIBELLEN3: 'BIR THLATHINE',
            IDN2: 47,
            CODEP: '3284',
          },
          {
            IDN3: 4362,
            LIBELLEN3: 'CHENINI',
            IDN2: 47,
            CODEP: '3222',
          },
          {
            IDN3: 4367,
            LIBELLEN3: 'CHENINI NOUVELLE',
            IDN2: 47,
            CODEP: '3274',
          },
          {
            IDN3: 4374,
            LIBELLEN3: 'CITE COMMERCIALE',
            IDN2: 47,
            CODEP: '3200',
          },
          {
            IDN3: 4375,
            LIBELLEN3: 'CITE DES ROSES',
            IDN2: 47,
            CODEP: '3200',
          },
          {
            IDN3: 4376,
            LIBELLEN3: 'CITE DU PRESIDENT',
            IDN2: 47,
            CODEP: '3200',
          },
          {
            IDN3: 4370,
            LIBELLEN3: 'CITE EL HEDADDA',
            IDN2: 47,
            CODEP: '3200',
          },
          {
            IDN3: 4371,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 47,
            CODEP: '3200',
          },
          {
            IDN3: 4373,
            LIBELLEN3: 'CITE NOUVELLE',
            IDN2: 47,
            CODEP: '3200',
          },
          {
            IDN3: 4372,
            LIBELLEN3: 'CITE SENEGAL',
            IDN2: 47,
            CODEP: '3200',
          },
          {
            IDN3: 4363,
            LIBELLEN3: 'DOUIRET',
            IDN2: 47,
            CODEP: '3232',
          },
          {
            IDN3: 4356,
            LIBELLEN3: 'GHORGHAR',
            IDN2: 47,
            CODEP: '3200',
          },
          {
            IDN3: 4364,
            LIBELLEN3: 'KSAR DEBAB',
            IDN2: 47,
            CODEP: '3242',
          },
          {
            IDN3: 4361,
            LIBELLEN3: 'KSAR MGUEBLA',
            IDN2: 47,
            CODEP: '3221',
          },
          {
            IDN3: 4365,
            LIBELLEN3: 'MAZTOURIA',
            IDN2: 47,
            CODEP: '3252',
          },
          {
            IDN3: 4366,
            LIBELLEN3: 'RAS EL OUED',
            IDN2: 47,
            CODEP: '3264',
          },
          {
            IDN3: 4369,
            LIBELLEN3: 'ROGBA',
            IDN2: 47,
            CODEP: '3293',
          },
          {
            IDN3: 4357,
            LIBELLEN3: 'SEDRA',
            IDN2: 47,
            CODEP: '3200',
          },
          {
            IDN3: 4358,
            LIBELLEN3: 'TATAOUINE',
            IDN2: 47,
            CODEP: '3200',
          },
          {
            IDN3: 4359,
            LIBELLEN3: 'TOUNKET',
            IDN2: 47,
            CODEP: '3211',
          },
        ],
      },
    ],
  },
  {
    govLabel: 'TOZEUR',
    govId: 22,
    villes: [
      {
        ville: 54,
        villeName: 'DEGUECHE',
        cites: [
          {
            IDN3: 4491,
            LIBELLEN3: 'BOU HELAL',
            IDN2: 54,
            CODEP: '2263',
          },
          {
            IDN3: 4492,
            LIBELLEN3: 'CEDDADA',
            IDN2: 54,
            CODEP: '2263',
          },
          {
            IDN3: 4478,
            LIBELLEN3: 'CHAKMOU',
            IDN2: 54,
            CODEP: '2214',
          },
          {
            IDN3: 4481,
            LIBELLEN3: 'CITE DE LA GARE',
            IDN2: 54,
            CODEP: '2260',
          },
          {
            IDN3: 4482,
            LIBELLEN3: 'CITE DU STADE',
            IDN2: 54,
            CODEP: '2260',
          },
          {
            IDN3: 4483,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 54,
            CODEP: '2260',
          },
          {
            IDN3: 4484,
            LIBELLEN3: 'CITE EL HASNAOUI',
            IDN2: 54,
            CODEP: '2260',
          },
          {
            IDN3: 4485,
            LIBELLEN3: 'CITE LOGEMENT PRESIDENTIEL',
            IDN2: 54,
            CODEP: '2260',
          },
          {
            IDN3: 4486,
            LIBELLEN3: 'CITE NOUVEAU STADE',
            IDN2: 54,
            CODEP: '2260',
          },
          {
            IDN3: 4487,
            LIBELLEN3: 'CITE OULED EL GHARBI',
            IDN2: 54,
            CODEP: '2260',
          },
          {
            IDN3: 4488,
            LIBELLEN3: 'CITE OULED HMIDA',
            IDN2: 54,
            CODEP: '2260',
          },
          {
            IDN3: 4489,
            LIBELLEN3: 'DEGUECHE',
            IDN2: 54,
            CODEP: '2260',
          },
          {
            IDN3: 4495,
            LIBELLEN3: 'DGHOUMES',
            IDN2: 54,
            CODEP: '2262',
          },
          {
            IDN3: 4479,
            LIBELLEN3: 'EL HAMMA DU JERID',
            IDN2: 54,
            CODEP: '2214',
          },
          {
            IDN3: 4480,
            LIBELLEN3: 'EL MAHASSEN',
            IDN2: 54,
            CODEP: '2224',
          },
          {
            IDN3: 4490,
            LIBELLEN3: 'OULED MAJED',
            IDN2: 54,
            CODEP: '2261',
          },
          {
            IDN3: 4493,
            LIBELLEN3: 'SABAA OBBAT',
            IDN2: 54,
            CODEP: '2261',
          },
          {
            IDN3: 4494,
            LIBELLEN3: 'ZAOUIET EL ARAB',
            IDN2: 54,
            CODEP: '2261',
          },
        ],
      },
      {
        ville: 56,
        villeName: 'HEZOUA',
        cites: [
          {
            IDN3: 4525,
            LIBELLEN3: 'HEZOUA',
            IDN2: 56,
            CODEP: '2223',
          },
        ],
      },
      {
        ville: 57,
        villeName: 'NEFTA',
        cites: [
          {
            IDN3: 4530,
            LIBELLEN3: 'BEN FARJALLAH',
            IDN2: 57,
            CODEP: '2245',
          },
          {
            IDN3: 4526,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 57,
            CODEP: '2240',
          },
          {
            IDN3: 4527,
            LIBELLEN3: 'CITE KHZAMA',
            IDN2: 57,
            CODEP: '2245',
          },
          {
            IDN3: 4528,
            LIBELLEN3: 'CITE NOUVELLE',
            IDN2: 57,
            CODEP: '2245',
          },
          {
            IDN3: 4529,
            LIBELLEN3: 'NEFTA',
            IDN2: 57,
            CODEP: '2245',
          },
        ],
      },
      {
        ville: 58,
        villeName: 'TAMEGHZA',
        cites: [
          {
            IDN3: 4532,
            LIBELLEN3: 'AIN EL KARMA',
            IDN2: 58,
            CODEP: '2212',
          },
          {
            IDN3: 4541,
            LIBELLEN3: 'CHEBIKA DU JERID',
            IDN2: 58,
            CODEP: '2253',
          },
          {
            IDN3: 4533,
            LIBELLEN3: 'CITE ADMINISTRATIVE',
            IDN2: 58,
            CODEP: '2212',
          },
          {
            IDN3: 4542,
            LIBELLEN3: 'CITE BOU CHAGRA',
            IDN2: 58,
            CODEP: '2253',
          },
          {
            IDN3: 4543,
            LIBELLEN3: 'CITE DE L ECOLE',
            IDN2: 58,
            CODEP: '2253',
          },
          {
            IDN3: 4534,
            LIBELLEN3: 'CITE EL AMEL',
            IDN2: 58,
            CODEP: '2212',
          },
          {
            IDN3: 4544,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 58,
            CODEP: '2253',
          },
          {
            IDN3: 4545,
            LIBELLEN3: 'CITE EL FRADA',
            IDN2: 58,
            CODEP: '2253',
          },
          {
            IDN3: 4531,
            LIBELLEN3: 'DHAFRIA',
            IDN2: 58,
            CODEP: '2211',
          },
          {
            IDN3: 4535,
            LIBELLEN3: 'EL FRID',
            IDN2: 58,
            CODEP: '2212',
          },
          {
            IDN3: 4536,
            LIBELLEN3: 'LOGEMENT POPULAIRE',
            IDN2: 58,
            CODEP: '2212',
          },
          {
            IDN3: 4537,
            LIBELLEN3: 'MIDES',
            IDN2: 58,
            CODEP: '2212',
          },
          {
            IDN3: 4538,
            LIBELLEN3: 'REMITHA',
            IDN2: 58,
            CODEP: '2212',
          },
          {
            IDN3: 4539,
            LIBELLEN3: 'SONDES',
            IDN2: 58,
            CODEP: '2212',
          },
          {
            IDN3: 4540,
            LIBELLEN3: 'TAMEGHZA',
            IDN2: 58,
            CODEP: '2212',
          },
        ],
      },
      {
        ville: 55,
        villeName: 'TOZEUR',
        cites: [
          {
            IDN3: 4496,
            LIBELLEN3: 'ABBES',
            IDN2: 55,
            CODEP: '2233',
          },
          {
            IDN3: 4513,
            LIBELLEN3: 'BLED EL HADHAR',
            IDN2: 55,
            CODEP: '2233',
          },
          {
            IDN3: 4514,
            LIBELLEN3: 'BOU LIFA',
            IDN2: 55,
            CODEP: '2233',
          },
          {
            IDN3: 4520,
            LIBELLEN3: 'CHABBIA',
            IDN2: 55,
            CODEP: '2243',
          },
          {
            IDN3: 4517,
            LIBELLEN3: 'CHETAOUA SAHRAOUI',
            IDN2: 55,
            CODEP: '2239',
          },
          {
            IDN3: 4521,
            LIBELLEN3: 'CHORFA',
            IDN2: 55,
            CODEP: '2243',
          },
          {
            IDN3: 4500,
            LIBELLEN3: 'CITE AFH',
            IDN2: 55,
            CODEP: '2210',
          },
          {
            IDN3: 4501,
            LIBELLEN3: 'CITE CNRPS',
            IDN2: 55,
            CODEP: '2210',
          },
          {
            IDN3: 4498,
            LIBELLEN3: 'CITE COMMERCIALE',
            IDN2: 55,
            CODEP: '2200',
          },
          {
            IDN3: 4502,
            LIBELLEN3: 'CITE DE LA GARE',
            IDN2: 55,
            CODEP: '2210',
          },
          {
            IDN3: 4503,
            LIBELLEN3: 'CITE EL GUITNA',
            IDN2: 55,
            CODEP: '2210',
          },
          {
            IDN3: 4518,
            LIBELLEN3: 'CITE EL IZDIHAR',
            IDN2: 55,
            CODEP: '2241',
          },
          {
            IDN3: 4504,
            LIBELLEN3: 'CITE EL MAHRAJENE',
            IDN2: 55,
            CODEP: '2210',
          },
          {
            IDN3: 4505,
            LIBELLEN3: 'CITE HELBA',
            IDN2: 55,
            CODEP: '2210',
          },
          {
            IDN3: 4506,
            LIBELLEN3: 'CITE HOPITAL',
            IDN2: 55,
            CODEP: '2210',
          },
          {
            IDN3: 4507,
            LIBELLEN3: 'CITE MAISON POPULAIRE',
            IDN2: 55,
            CODEP: '2210',
          },
          {
            IDN3: 4509,
            LIBELLEN3: 'CITE ROUTE DE DEGUECHE',
            IDN2: 55,
            CODEP: '2210',
          },
          {
            IDN3: 4510,
            LIBELLEN3: 'CITE ROUTE EL HAJIJ',
            IDN2: 55,
            CODEP: '2210',
          },
          {
            IDN3: 4511,
            LIBELLEN3: 'CITE ROUTE EL HAMMA',
            IDN2: 55,
            CODEP: '2210',
          },
          {
            IDN3: 4512,
            LIBELLEN3: 'CITE ROUTE ENNAFLIETTE',
            IDN2: 55,
            CODEP: '2210',
          },
          {
            IDN3: 4522,
            LIBELLEN3: 'CITE SIDI EL HAFNAOUI',
            IDN2: 55,
            CODEP: '2210',
          },
          {
            IDN3: 4523,
            LIBELLEN3: 'CITE TEBEBSA',
            IDN2: 55,
            CODEP: '2210',
          },
          {
            IDN3: 4515,
            LIBELLEN3: 'ESSOUALMIA',
            IDN2: 55,
            CODEP: '2233',
          },
          {
            IDN3: 4516,
            LIBELLEN3: 'JAHIM',
            IDN2: 55,
            CODEP: '2233',
          },
          {
            IDN3: 4519,
            LIBELLEN3: 'RAS DHRAA',
            IDN2: 55,
            CODEP: '2241',
          },
          {
            IDN3: 4499,
            LIBELLEN3: 'TOZEUR',
            IDN2: 55,
            CODEP: '2200',
          },
          {
            IDN3: 4508,
            LIBELLEN3: 'TOZEUR AEROPORT',
            IDN2: 55,
            CODEP: '2213',
          },
          {
            IDN3: 4524,
            LIBELLEN3: 'TOZEUR CHOKRATSI',
            IDN2: 55,
            CODEP: '2210',
          },
          {
            IDN3: 4497,
            LIBELLEN3: 'ZONE TOURISTIQUE',
            IDN2: 55,
            CODEP: '2210',
          },
        ],
      },
    ],
  },
  {
    govLabel: 'TUNIS',
    govId: 23,
    villes: [
      {
        ville: 66,
        villeName: 'BAB BHAR',
        cites: [
          {
            IDN3: 4618,
            LIBELLEN3: 'BAB BHAR',
            IDN2: 66,
            CODEP: '1000',
          },
          {
            IDN3: 4619,
            LIBELLEN3: 'BAB EL JAZIRA',
            IDN2: 66,
            CODEP: '1000',
          },
          {
            IDN3: 4624,
            LIBELLEN3: 'HABIB THAMEUR',
            IDN2: 66,
            CODEP: '1069',
          },
          {
            IDN3: 4622,
            LIBELLEN3: 'HEDI CHAKER',
            IDN2: 66,
            CODEP: '1002',
          },
          {
            IDN3: 4625,
            LIBELLEN3: 'MONTPLAISIR',
            IDN2: 66,
            CODEP: '1073',
          },
          {
            IDN3: 4621,
            LIBELLEN3: 'REPUBLIQUE',
            IDN2: 66,
            CODEP: '1001',
          },
          {
            IDN3: 4620,
            LIBELLEN3: 'SIDI BAHRI',
            IDN2: 66,
            CODEP: '1000',
          },
          {
            IDN3: 4623,
            LIBELLEN3: 'TAIEB EL M HIRI',
            IDN2: 66,
            CODEP: '1002',
          },
        ],
      },
      {
        ville: 63,
        villeName: 'BAB SOUIKA',
        cites: [
          {
            IDN3: 4597,
            LIBELLEN3: 'BAB EL AKOUES',
            IDN2: 63,
            CODEP: '1006',
          },
          {
            IDN3: 4598,
            LIBELLEN3: 'BAB EL ALOUJ',
            IDN2: 63,
            CODEP: '1006',
          },
          {
            IDN3: 4599,
            LIBELLEN3: 'BAB EL ASSEL',
            IDN2: 63,
            CODEP: '1006',
          },
          {
            IDN3: 4593,
            LIBELLEN3: 'BAB EL KHADRA',
            IDN2: 63,
            CODEP: '1075',
          },
          {
            IDN3: 4592,
            LIBELLEN3: 'BAB SAADOUN',
            IDN2: 63,
            CODEP: '1029',
          },
          {
            IDN3: 4600,
            LIBELLEN3: 'BAB SIDI ABDESSALEM',
            IDN2: 63,
            CODEP: '1006',
          },
          {
            IDN3: 4595,
            LIBELLEN3: 'BAB SOUIKA',
            IDN2: 63,
            CODEP: '1006',
          },
          {
            IDN3: 4596,
            LIBELLEN3: 'BORJ ZOUARA',
            IDN2: 63,
            CODEP: '1006',
          },
          {
            IDN3: 4594,
            LIBELLEN3: 'EL HALFAOUINE',
            IDN2: 63,
            CODEP: '1006',
          },
          {
            IDN3: 4588,
            LIBELLEN3: 'HAMMAM EL REMIMI',
            IDN2: 63,
            CODEP: '1006',
          },
          {
            IDN3: 4589,
            LIBELLEN3: 'SIDI DJEBELI',
            IDN2: 63,
            CODEP: '1006',
          },
          {
            IDN3: 4590,
            LIBELLEN3: 'TRONJA',
            IDN2: 63,
            CODEP: '1006',
          },
          {
            IDN3: 4591,
            LIBELLEN3: 'ZAOUIET EL BAKRIA',
            IDN2: 63,
            CODEP: '1006',
          },
        ],
      },
      {
        ville: 64,
        villeName: 'CARTHAGE',
        cites: [
          {
            IDN3: 4601,
            LIBELLEN3: 'AMILCAR',
            IDN2: 64,
            CODEP: '1054',
          },
          {
            IDN3: 4602,
            LIBELLEN3: 'CARTAGE BYRSA',
            IDN2: 64,
            CODEP: '2016',
          },
          {
            IDN3: 4603,
            LIBELLEN3: 'CARTAGE PLAGE',
            IDN2: 64,
            CODEP: '2016',
          },
          {
            IDN3: 4604,
            LIBELLEN3: 'CARTHAGE',
            IDN2: 64,
            CODEP: '2016',
          },
          {
            IDN3: 4605,
            LIBELLEN3: 'CITE DE LA STEG',
            IDN2: 64,
            CODEP: '2016',
          },
          {
            IDN3: 4606,
            LIBELLEN3: 'CITE MOHAMED ALI',
            IDN2: 64,
            CODEP: '2016',
          },
          {
            IDN3: 4609,
            LIBELLEN3: 'EL YASMINA',
            IDN2: 64,
            CODEP: '2085',
          },
          {
            IDN3: 4607,
            LIBELLEN3: 'SALAMBO',
            IDN2: 64,
            CODEP: '2025',
          },
          {
            IDN3: 4608,
            LIBELLEN3: 'SIDI BOUSAID',
            IDN2: 64,
            CODEP: '2026',
          },
        ],
      },
      {
        ville: 65,
        villeName: 'CITE EL KHADRA',
        cites: [
          {
            IDN3: 4612,
            LIBELLEN3: 'CITE EL KHADRA',
            IDN2: 65,
            CODEP: '1003',
          },
          {
            IDN3: 4613,
            LIBELLEN3: 'CITE EL WAFA',
            IDN2: 65,
            CODEP: '1003',
          },
          {
            IDN3: 4610,
            LIBELLEN3: 'CITE JARDINS',
            IDN2: 65,
            CODEP: '1002',
          },
          {
            IDN3: 4614,
            LIBELLEN3: 'CITE OLYMPEADE',
            IDN2: 65,
            CODEP: '1003',
          },
          {
            IDN3: 4615,
            LIBELLEN3: 'CITE OPLYMPIQUE',
            IDN2: 65,
            CODEP: '1003',
          },
          {
            IDN3: 4616,
            LIBELLEN3: 'CITE STAR',
            IDN2: 65,
            CODEP: '1003',
          },
          {
            IDN3: 4617,
            LIBELLEN3: 'EL CHARGUIA',
            IDN2: 65,
            CODEP: '2035',
          },
          {
            IDN3: 4611,
            LIBELLEN3: 'KHEIREDDINE PACHA',
            IDN2: 65,
            CODEP: '1002',
          },
        ],
      },
      {
        ville: 60,
        villeName: 'EL HRAIRIA',
        cites: [
          {
            IDN3: 4569,
            LIBELLEN3: 'CITE BOUGATFA 1',
            IDN2: 60,
            CODEP: '2051',
          },
          {
            IDN3: 4568,
            LIBELLEN3: 'CITE BOUZAIENE',
            IDN2: 60,
            CODEP: '2052',
          },
          {
            IDN3: 4562,
            LIBELLEN3: 'CITE EL MECHTEL',
            IDN2: 60,
            CODEP: '2087',
          },
          {
            IDN3: 4570,
            LIBELLEN3: 'CITE ERRACHIDIA',
            IDN2: 60,
            CODEP: '2051',
          },
          {
            IDN3: 4571,
            LIBELLEN3: 'CITE ESSALAMA',
            IDN2: 60,
            CODEP: '2051',
          },
          {
            IDN3: 4572,
            LIBELLEN3: 'CITE ESSOLTANI',
            IDN2: 60,
            CODEP: '2051',
          },
          {
            IDN3: 4563,
            LIBELLEN3: 'CITE HWAS',
            IDN2: 60,
            CODEP: '2087',
          },
          {
            IDN3: 4566,
            LIBELLEN3: 'CITE JLAS',
            IDN2: 60,
            CODEP: '2051',
          },
          {
            IDN3: 4567,
            LIBELLEN3: 'CITE MEDJERDA',
            IDN2: 60,
            CODEP: '2051',
          },
          {
            IDN3: 4564,
            LIBELLEN3: 'EL HRAIRIA',
            IDN2: 60,
            CODEP: '2087',
          },
          {
            IDN3: 4565,
            LIBELLEN3: 'EZZAHROUNI',
            IDN2: 60,
            CODEP: '2051',
          },
          {
            IDN3: 4561,
            LIBELLEN3: 'EZZOUHOUR 5',
            IDN2: 60,
            CODEP: '2052',
          },
        ],
      },
      {
        ville: 61,
        villeName: 'EL KABBARIA',
        cites: [
          {
            IDN3: 4574,
            LIBELLEN3: 'CITE BOU HJAR',
            IDN2: 61,
            CODEP: '2053',
          },
          {
            IDN3: 4575,
            LIBELLEN3: 'CITE ENNOUR',
            IDN2: 61,
            CODEP: '2053',
          },
          {
            IDN3: 4576,
            LIBELLEN3: 'EL KABBARIA 1',
            IDN2: 61,
            CODEP: '2053',
          },
          {
            IDN3: 4577,
            LIBELLEN3: 'EL KABBARIA 2',
            IDN2: 61,
            CODEP: '2053',
          },
          {
            IDN3: 4578,
            LIBELLEN3: 'EL KABBARIA 3',
            IDN2: 61,
            CODEP: '2053',
          },
          {
            IDN3: 4579,
            LIBELLEN3: 'EL KABBARIA 4',
            IDN2: 61,
            CODEP: '2053',
          },
          {
            IDN3: 4573,
            LIBELLEN3: 'EL MOUROUJ 2',
            IDN2: 61,
            CODEP: '1074',
          },
          {
            IDN3: 4580,
            LIBELLEN3: 'EL OUERDIA 4',
            IDN2: 61,
            CODEP: '2053',
          },
          {
            IDN3: 4581,
            LIBELLEN3: 'IBN SINA',
            IDN2: 61,
            CODEP: '2066',
          },
        ],
      },
      {
        ville: 62,
        villeName: 'EL KRAM',
        cites: [
          {
            IDN3: 4585,
            LIBELLEN3: 'BIR EL HLOU',
            IDN2: 62,
            CODEP: '2089',
          },
          {
            IDN3: 4582,
            LIBELLEN3: 'ERRIADH',
            IDN2: 62,
            CODEP: '2015',
          },
          {
            IDN3: 4583,
            LIBELLEN3: 'LE KRAM',
            IDN2: 62,
            CODEP: '2015',
          },
          {
            IDN3: 4584,
            LIBELLEN3: 'LE KRAM EST',
            IDN2: 62,
            CODEP: '2015',
          },
          {
            IDN3: 4586,
            LIBELLEN3: 'LE KRAM OUEST',
            IDN2: 62,
            CODEP: '2089',
          },
          {
            IDN3: 4587,
            LIBELLEN3: 'SIDI AMOR',
            IDN2: 62,
            CODEP: '2089',
          },
        ],
      },
      {
        ville: 59,
        villeName: 'EL MENZAH',
        cites: [
          {
            IDN3: 4546,
            LIBELLEN3: 'CITE CARAVELLES',
            IDN2: 59,
            CODEP: '1082',
          },
          {
            IDN3: 4556,
            LIBELLEN3: 'CITE DES MEDECINS',
            IDN2: 59,
            CODEP: '2092',
          },
          {
            IDN3: 4557,
            LIBELLEN3: 'CITE DU PARADIS',
            IDN2: 59,
            CODEP: '2092',
          },
          {
            IDN3: 4547,
            LIBELLEN3: 'CITE EL MAHRAJENE',
            IDN2: 59,
            CODEP: '1082',
          },
          {
            IDN3: 4558,
            LIBELLEN3: 'CITE FAIZA',
            IDN2: 59,
            CODEP: '2092',
          },
          {
            IDN3: 4559,
            LIBELLEN3: 'CITE SABA',
            IDN2: 59,
            CODEP: '2092',
          },
          {
            IDN3: 4548,
            LIBELLEN3: 'CITE SNIT',
            IDN2: 59,
            CODEP: '1082',
          },
          {
            IDN3: 4560,
            LIBELLEN3: 'EL MANAR 2',
            IDN2: 59,
            CODEP: '2092',
          },
          {
            IDN3: 4551,
            LIBELLEN3: 'EL MENZAH 1',
            IDN2: 59,
            CODEP: '1004',
          },
          {
            IDN3: 4552,
            LIBELLEN3: 'EL MENZAH 2',
            IDN2: 59,
            CODEP: '1082',
          },
          {
            IDN3: 4553,
            LIBELLEN3: 'EL MENZAH 3',
            IDN2: 59,
            CODEP: '1082',
          },
          {
            IDN3: 4554,
            LIBELLEN3: 'EL MENZAH 4',
            IDN2: 59,
            CODEP: '1082',
          },
          {
            IDN3: 4549,
            LIBELLEN3: 'EL MENZAH 9',
            IDN2: 59,
            CODEP: '1013',
          },
          {
            IDN3: 4555,
            LIBELLEN3: 'MUTUELLE VILLE',
            IDN2: 59,
            CODEP: '1082',
          },
          {
            IDN3: 4550,
            LIBELLEN3: 'TUNIS BELVEDERE',
            IDN2: 59,
            CODEP: '1002',
          },
        ],
      },
      {
        ville: 73,
        villeName: 'EL OMRANE',
        cites: [
          {
            IDN3: 4697,
            LIBELLEN3: 'BAB SAADOUN GARE',
            IDN2: 73,
            CODEP: '1029',
          },
          {
            IDN3: 4699,
            LIBELLEN3: 'BIR ATIG',
            IDN2: 73,
            CODEP: '1005',
          },
          {
            IDN3: 4700,
            LIBELLEN3: 'CITE CENTRALE',
            IDN2: 73,
            CODEP: '1005',
          },
          {
            IDN3: 4701,
            LIBELLEN3: 'CITE DES OLIVIERS',
            IDN2: 73,
            CODEP: '1005',
          },
          {
            IDN3: 4702,
            LIBELLEN3: 'CITE EL HABIB',
            IDN2: 73,
            CODEP: '1005',
          },
          {
            IDN3: 4703,
            LIBELLEN3: 'CITE MILITAIRE',
            IDN2: 73,
            CODEP: '1005',
          },
          {
            IDN3: 4704,
            LIBELLEN3: 'DJBAL EL AHMAR',
            IDN2: 73,
            CODEP: '1005',
          },
          {
            IDN3: 4705,
            LIBELLEN3: 'EL OMRANE',
            IDN2: 73,
            CODEP: '1005',
          },
          {
            IDN3: 4706,
            LIBELLEN3: 'OUED EL SEBAI',
            IDN2: 73,
            CODEP: '1005',
          },
          {
            IDN3: 4698,
            LIBELLEN3: 'RAS TABIA',
            IDN2: 73,
            CODEP: '2000',
          },
        ],
      },
      {
        ville: 74,
        villeName: 'EL OMRANE SUPERIEUR',
        cites: [
          {
            IDN3: 4707,
            LIBELLEN3: 'CITE EL INTILAKA',
            IDN2: 74,
            CODEP: '1064',
          },
          {
            IDN3: 4711,
            LIBELLEN3: 'CITE IBN KHALDOUN I',
            IDN2: 74,
            CODEP: '2062',
          },
          {
            IDN3: 4712,
            LIBELLEN3: 'CITE IBN KHALDOUN VI',
            IDN2: 74,
            CODEP: '2062',
          },
          {
            IDN3: 4708,
            LIBELLEN3: 'EL NASSIM',
            IDN2: 74,
            CODEP: '1064',
          },
          {
            IDN3: 4710,
            LIBELLEN3: 'EL OMRANE SUPERIEUR',
            IDN2: 74,
            CODEP: '1091',
          },
          {
            IDN3: 4709,
            LIBELLEN3: 'ROMMANA',
            IDN2: 74,
            CODEP: '1068',
          },
        ],
      },
      {
        ville: 75,
        villeName: 'EL OUERDIA',
        cites: [
          {
            IDN3: 4713,
            LIBELLEN3: 'BELLEVUE',
            IDN2: 75,
            CODEP: '1009',
          },
          {
            IDN3: 4714,
            LIBELLEN3: 'BORJ ALI ERRAIS',
            IDN2: 75,
            CODEP: '1009',
          },
          {
            IDN3: 4715,
            LIBELLEN3: 'CITE EL IZDIHAR',
            IDN2: 75,
            CODEP: '1009',
          },
          {
            IDN3: 4716,
            LIBELLEN3: 'CITE MOHAMED ALI',
            IDN2: 75,
            CODEP: '1009',
          },
          {
            IDN3: 4717,
            LIBELLEN3: 'DIBOUZVILLE',
            IDN2: 75,
            CODEP: '1009',
          },
          {
            IDN3: 4718,
            LIBELLEN3: 'EL OUERDIA',
            IDN2: 75,
            CODEP: '1009',
          },
          {
            IDN3: 4719,
            LIBELLEN3: 'LAKAGNIA',
            IDN2: 75,
            CODEP: '1009',
          },
          {
            IDN3: 4720,
            LIBELLEN3: 'LES MARTYRS',
            IDN2: 75,
            CODEP: '1009',
          },
          {
            IDN3: 4721,
            LIBELLEN3: 'MATHUL DE VILLE',
            IDN2: 75,
            CODEP: '1009',
          },
          {
            IDN3: 4722,
            LIBELLEN3: 'MONHOMME',
            IDN2: 75,
            CODEP: '1009',
          },
        ],
      },
      {
        ville: 78,
        villeName: 'ESSIJOUMI',
        cites: [
          {
            IDN3: 4743,
            LIBELLEN3: 'CITE HELAL',
            IDN2: 78,
            CODEP: '2072',
          },
          {
            IDN3: 4742,
            LIBELLEN3: 'EL MELLASSINE',
            IDN2: 78,
            CODEP: '1007',
          },
        ],
      },
      {
        ville: 76,
        villeName: 'ETTAHRIR',
        cites: [
          {
            IDN3: 4729,
            LIBELLEN3: 'CITE DU JARDIN',
            IDN2: 76,
            CODEP: '2042',
          },
          {
            IDN3: 4723,
            LIBELLEN3: 'CITE EL FERDAOUS',
            IDN2: 76,
            CODEP: '2042',
          },
          {
            IDN3: 4724,
            LIBELLEN3: 'CITE ESSADIK',
            IDN2: 76,
            CODEP: '2042',
          },
          {
            IDN3: 4725,
            LIBELLEN3: 'CITE ETTAHRIR SUP.',
            IDN2: 76,
            CODEP: '2042',
          },
          {
            IDN3: 4726,
            LIBELLEN3: 'ETTAHRIR 1',
            IDN2: 76,
            CODEP: '2042',
          },
          {
            IDN3: 4727,
            LIBELLEN3: 'ETTAHRIR 2',
            IDN2: 76,
            CODEP: '2042',
          },
          {
            IDN3: 4728,
            LIBELLEN3: 'ETTAHRIR 3',
            IDN2: 76,
            CODEP: '2042',
          },
        ],
      },
      {
        ville: 68,
        villeName: 'EZZOUHOUR',
        cites: [
          {
            IDN3: 4660,
            LIBELLEN3: 'CITE DES OFFICIERS',
            IDN2: 68,
            CODEP: '2052',
          },
          {
            IDN3: 4661,
            LIBELLEN3: 'CITE ESSAADA',
            IDN2: 68,
            CODEP: '2052',
          },
          {
            IDN3: 4662,
            LIBELLEN3: 'CITE ESSOMRANE',
            IDN2: 68,
            CODEP: '2052',
          },
          {
            IDN3: 4663,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 68,
            CODEP: '2052',
          },
          {
            IDN3: 4659,
            LIBELLEN3: 'EZZOUHOUR 4',
            IDN2: 68,
            CODEP: '2052',
          },
        ],
      },
      {
        ville: 69,
        villeName: 'JEBEL JELLOUD',
        cites: [
          {
            IDN3: 4664,
            LIBELLEN3: 'ALI BACH-HAMBA',
            IDN2: 69,
            CODEP: '1046',
          },
          {
            IDN3: 4668,
            LIBELLEN3: 'CITE THAMEUR',
            IDN2: 69,
            CODEP: '2023',
          },
          {
            IDN3: 4669,
            LIBELLEN3: 'EL AFRANE',
            IDN2: 69,
            CODEP: '2023',
          },
          {
            IDN3: 4665,
            LIBELLEN3: 'EL GARJOUMA',
            IDN2: 69,
            CODEP: '1046',
          },
          {
            IDN3: 4666,
            LIBELLEN3: 'EL SEBKHA',
            IDN2: 69,
            CODEP: '1046',
          },
          {
            IDN3: 4667,
            LIBELLEN3: 'JEBEL JELLOUD',
            IDN2: 69,
            CODEP: '1046',
          },
          {
            IDN3: 4670,
            LIBELLEN3: 'SIDI FATHALLAH',
            IDN2: 69,
            CODEP: '2023',
          },
        ],
      },
      {
        ville: 71,
        villeName: 'LA GOULETTE',
        cites: [
          {
            IDN3: 4681,
            LIBELLEN3: 'CASINO LA GOULETTE',
            IDN2: 71,
            CODEP: '2060',
          },
          {
            IDN3: 4687,
            LIBELLEN3: 'CITE EL MECHTEL',
            IDN2: 71,
            CODEP: '2089',
          },
          {
            IDN3: 4682,
            LIBELLEN3: 'CITE ENNACIM LA BRISE',
            IDN2: 71,
            CODEP: '2060',
          },
          {
            IDN3: 4683,
            LIBELLEN3: 'EL BATTAH',
            IDN2: 71,
            CODEP: '2060',
          },
          {
            IDN3: 4684,
            LIBELLEN3: 'KHEIREDDINE',
            IDN2: 71,
            CODEP: '2060',
          },
          {
            IDN3: 4685,
            LIBELLEN3: 'LA GOULETTE',
            IDN2: 71,
            CODEP: '2060',
          },
          {
            IDN3: 4686,
            LIBELLEN3: 'LA GOULETTE NOUVELLE',
            IDN2: 71,
            CODEP: '2060',
          },
          {
            IDN3: 4680,
            LIBELLEN3: 'LA GOULETTE PORT',
            IDN2: 71,
            CODEP: '1067',
          },
        ],
      },
      {
        ville: 67,
        villeName: 'LA MARSA',
        cites: [
          {
            IDN3: 4645,
            LIBELLEN3: 'BERGE DU LAC',
            IDN2: 67,
            CODEP: '1053',
          },
          {
            IDN3: 4633,
            LIBELLEN3: 'CITE AFH',
            IDN2: 67,
            CODEP: '2078',
          },
          {
            IDN3: 4649,
            LIBELLEN3: 'CITE AZIZA',
            IDN2: 67,
            CODEP: '2046',
          },
          {
            IDN3: 4634,
            LIBELLEN3: 'CITE BEN CHAABANE',
            IDN2: 67,
            CODEP: '2078',
          },
          {
            IDN3: 4650,
            LIBELLEN3: 'CITE BHAR LAZREG',
            IDN2: 67,
            CODEP: '2046',
          },
          {
            IDN3: 4654,
            LIBELLEN3: 'CITE BORJ EL KOUKI',
            IDN2: 67,
            CODEP: '2076',
          },
          {
            IDN3: 4655,
            LIBELLEN3: 'CITE DE LA TELEDIFFUSION 1',
            IDN2: 67,
            CODEP: '2076',
          },
          {
            IDN3: 4656,
            LIBELLEN3: 'CITE DE LA TELEDIFFUSION 2',
            IDN2: 67,
            CODEP: '2076',
          },
          {
            IDN3: 4657,
            LIBELLEN3: 'CITE DES JUGES 2',
            IDN2: 67,
            CODEP: '2076',
          },
          {
            IDN3: 4651,
            LIBELLEN3: 'CITE DES MIMOSAS',
            IDN2: 67,
            CODEP: '2076',
          },
          {
            IDN3: 4635,
            LIBELLEN3: 'CITE DES VERGERS',
            IDN2: 67,
            CODEP: '2078',
          },
          {
            IDN3: 4636,
            LIBELLEN3: 'CITE DU STADE',
            IDN2: 67,
            CODEP: '2078',
          },
          {
            IDN3: 4637,
            LIBELLEN3: 'CITE EL AHMADI',
            IDN2: 67,
            CODEP: '2078',
          },
          {
            IDN3: 4626,
            LIBELLEN3: 'CITE EL FATEH',
            IDN2: 67,
            CODEP: '2076',
          },
          {
            IDN3: 4638,
            LIBELLEN3: 'CITE EL HANA',
            IDN2: 67,
            CODEP: '2078',
          },
          {
            IDN3: 4627,
            LIBELLEN3: 'CITE EL KHALIL',
            IDN2: 67,
            CODEP: '2076',
          },
          {
            IDN3: 4647,
            LIBELLEN3: 'CITE EL MHIRI',
            IDN2: 67,
            CODEP: '2045',
          },
          {
            IDN3: 4639,
            LIBELLEN3: 'CITE EL MOEZ',
            IDN2: 67,
            CODEP: '2078',
          },
          {
            IDN3: 4640,
            LIBELLEN3: 'CITE EL MOUSTAKBEL (LA MARSA)',
            IDN2: 67,
            CODEP: null,
          },
          {
            IDN3: 4641,
            LIBELLEN3: 'CITE EL MOUSTAKBEL (SIDI DAOUD)',
            IDN2: 67,
            CODEP: null,
          },
          {
            IDN3: 4628,
            LIBELLEN3: 'CITE EL OUNS',
            IDN2: 67,
            CODEP: '2076',
          },
          {
            IDN3: 4629,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 67,
            CODEP: '2076',
          },
          {
            IDN3: 4630,
            LIBELLEN3: 'CITE ESSALAMA',
            IDN2: 67,
            CODEP: '2076',
          },
          {
            IDN3: 4631,
            LIBELLEN3: 'CITE ESSIRENE',
            IDN2: 67,
            CODEP: '2076',
          },
          {
            IDN3: 4642,
            LIBELLEN3: 'CITE MALHATLI',
            IDN2: 67,
            CODEP: '2078',
          },
          {
            IDN3: 4652,
            LIBELLEN3: 'CITE MONGI SLIM',
            IDN2: 67,
            CODEP: '2046',
          },
          {
            IDN3: 4643,
            LIBELLEN3: 'CITE RIMILA 1',
            IDN2: 67,
            CODEP: '2078',
          },
          {
            IDN3: 4644,
            LIBELLEN3: 'CITE RIMILA 2',
            IDN2: 67,
            CODEP: '2078',
          },
          {
            IDN3: 4648,
            LIBELLEN3: 'CITE SPROLS',
            IDN2: 67,
            CODEP: '2045',
          },
          {
            IDN3: 4646,
            LIBELLEN3: 'GAMMART',
            IDN2: 67,
            CODEP: '1057',
          },
          {
            IDN3: 4632,
            LIBELLEN3: 'MARSA ERRIADH',
            IDN2: 67,
            CODEP: '2076',
          },
          {
            IDN3: 4658,
            LIBELLEN3: 'MARSA SAFSAF',
            IDN2: 67,
            CODEP: '2078',
          },
          {
            IDN3: 4653,
            LIBELLEN3: 'SIDI DAOUD',
            IDN2: 67,
            CODEP: '2046',
          },
        ],
      },
      {
        ville: 79,
        villeName: 'LA MEDINA',
        cites: [
          {
            IDN3: 4754,
            LIBELLEN3: 'BAB BNET',
            IDN2: 79,
            CODEP: '1019',
          },
          {
            IDN3: 4749,
            LIBELLEN3: 'BAB DJEDID',
            IDN2: 79,
            CODEP: '1008',
          },
          {
            IDN3: 4750,
            LIBELLEN3: 'BAB MENARA',
            IDN2: 79,
            CODEP: '1008',
          },
          {
            IDN3: 4755,
            LIBELLEN3: 'EL HAFSIA',
            IDN2: 79,
            CODEP: '1059',
          },
          {
            IDN3: 4744,
            LIBELLEN3: 'EL MEDINA',
            IDN2: 79,
            CODEP: '1000',
          },
          {
            IDN3: 4745,
            LIBELLEN3: 'EL SABAGHINE',
            IDN2: 79,
            CODEP: '1000',
          },
          {
            IDN3: 4747,
            LIBELLEN3: 'EL TAOUFIK',
            IDN2: 79,
            CODEP: '1006',
          },
          {
            IDN3: 4751,
            LIBELLEN3: 'EL ZRARIA',
            IDN2: 79,
            CODEP: '1008',
          },
          {
            IDN3: 4752,
            LIBELLEN3: 'SIDI ALI AZOUZ',
            IDN2: 79,
            CODEP: '1008',
          },
          {
            IDN3: 4746,
            LIBELLEN3: 'SIDI BOUMENDIL',
            IDN2: 79,
            CODEP: '1000',
          },
          {
            IDN3: 4748,
            LIBELLEN3: 'SOUK NEL NHES',
            IDN2: 79,
            CODEP: '1006',
          },
          {
            IDN3: 4753,
            LIBELLEN3: 'TOURBET EL BEY',
            IDN2: 79,
            CODEP: '1008',
          },
        ],
      },
      {
        ville: 72,
        villeName: 'LE BARDO',
        cites: [
          {
            IDN3: 4689,
            LIBELLEN3: 'BORTAL HAYDER',
            IDN2: 72,
            CODEP: '2009',
          },
          {
            IDN3: 4694,
            LIBELLEN3: 'BOUCHOUCHA',
            IDN2: 72,
            CODEP: '2000',
          },
          {
            IDN3: 4695,
            LIBELLEN3: 'CITE ALTEN',
            IDN2: 72,
            CODEP: '2000',
          },
          {
            IDN3: 4691,
            LIBELLEN3: 'CITE DES ENSEIGNANTS',
            IDN2: 72,
            CODEP: '2017',
          },
          {
            IDN3: 4696,
            LIBELLEN3: 'CITE DU STADE',
            IDN2: 72,
            CODEP: '2000',
          },
          {
            IDN3: 4692,
            LIBELLEN3: 'CITE STAR',
            IDN2: 72,
            CODEP: '2017',
          },
          {
            IDN3: 4693,
            LIBELLEN3: 'KHAZNADAR',
            IDN2: 72,
            CODEP: '2017',
          },
          {
            IDN3: 4690,
            LIBELLEN3: 'KSAR SAID',
            IDN2: 72,
            CODEP: '2009',
          },
          {
            IDN3: 4688,
            LIBELLEN3: 'LE BARDO',
            IDN2: 72,
            CODEP: '2000',
          },
        ],
      },
      {
        ville: 70,
        villeName: 'SIDI EL BECHIR',
        cites: [
          {
            IDN3: 4674,
            LIBELLEN3: 'ABOU EL KACEM CHEBBI',
            IDN2: 70,
            CODEP: '1089',
          },
          {
            IDN3: 4675,
            LIBELLEN3: 'BAB ALIOUA',
            IDN2: 70,
            CODEP: '1089',
          },
          {
            IDN3: 4673,
            LIBELLEN3: 'BAB EL FALLA',
            IDN2: 70,
            CODEP: '1027',
          },
          {
            IDN3: 4676,
            LIBELLEN3: 'EL GOURJANI',
            IDN2: 70,
            CODEP: '1089',
          },
          {
            IDN3: 4677,
            LIBELLEN3: 'ESSAIDA MANNOUBIA',
            IDN2: 70,
            CODEP: '1089',
          },
          {
            IDN3: 4678,
            LIBELLEN3: 'MAAKEL EZZA',
            IDN2: 70,
            CODEP: '1008',
          },
          {
            IDN3: 4679,
            LIBELLEN3: 'MONFLEURY',
            IDN2: 70,
            CODEP: '1089',
          },
          {
            IDN3: 4671,
            LIBELLEN3: 'SIDI EL BECHIR',
            IDN2: 70,
            CODEP: '1089',
          },
          {
            IDN3: 4672,
            LIBELLEN3: 'SIDI MANSOUR',
            IDN2: 70,
            CODEP: '1089',
          },
        ],
      },
      {
        ville: 77,
        villeName: 'SIDI HASSINE',
        cites: [
          {
            IDN3: 4737,
            LIBELLEN3: 'BIRINE',
            IDN2: 77,
            CODEP: '1095',
          },
          {
            IDN3: 4739,
            LIBELLEN3: 'BORJ CHAKIR',
            IDN2: 77,
            CODEP: '1095',
          },
          {
            IDN3: 4732,
            LIBELLEN3: 'CITE 25 JUILLET',
            IDN2: 77,
            CODEP: '1095',
          },
          {
            IDN3: 4734,
            LIBELLEN3: 'CITE 7 NOVEMBRE',
            IDN2: 77,
            CODEP: '1095',
          },
          {
            IDN3: 4735,
            LIBELLEN3: 'CITE EL BETTOUMI',
            IDN2: 77,
            CODEP: '1095',
          },
          {
            IDN3: 4736,
            LIBELLEN3: 'CITE EL GAAFOURI',
            IDN2: 77,
            CODEP: '1095',
          },
          {
            IDN3: 4738,
            LIBELLEN3: 'CITE EL MESTIRI',
            IDN2: 77,
            CODEP: '1095',
          },
          {
            IDN3: 4740,
            LIBELLEN3: 'CITE MOHAMED ALI',
            IDN2: 77,
            CODEP: '1095',
          },
          {
            IDN3: 4741,
            LIBELLEN3: 'CITE MRAD 1',
            IDN2: 77,
            CODEP: '1095',
          },
          {
            IDN3: 4733,
            LIBELLEN3: 'CITE MRAD 2',
            IDN2: 77,
            CODEP: '1095',
          },
          {
            IDN3: 4730,
            LIBELLEN3: 'JAYARA',
            IDN2: 77,
            CODEP: '1095',
          },
          {
            IDN3: 4731,
            LIBELLEN3: 'SIDI HASSINE',
            IDN2: 77,
            CODEP: '1095',
          },
        ],
      },
    ],
  },
  {
    govLabel: 'ZAGHOUAN',
    govId: 24,
    villes: [
      {
        ville: 82,
        villeName: 'BIR MCHERGA',
        cites: [
          {
            IDN3: 4818,
            LIBELLEN3: 'AIN EL ASKER',
            IDN2: 82,
            CODEP: '1123',
          },
          {
            IDN3: 4820,
            LIBELLEN3: 'BIR MCHERGA',
            IDN2: 82,
            CODEP: '1141',
          },
          {
            IDN3: 4824,
            LIBELLEN3: 'BIR MCHERGA GARE',
            IDN2: 82,
            CODEP: '1193',
          },
          {
            IDN3: 4821,
            LIBELLEN3: 'BOUCHA',
            IDN2: 82,
            CODEP: '1141',
          },
          {
            IDN3: 4822,
            LIBELLEN3: 'DELAIEL EL AROUSS',
            IDN2: 82,
            CODEP: '1141',
          },
          {
            IDN3: 4825,
            LIBELLEN3: 'JEBEL EL OUST',
            IDN2: 82,
            CODEP: '1111',
          },
          {
            IDN3: 4823,
            LIBELLEN3: 'OULED HELEL',
            IDN2: 82,
            CODEP: '1141',
          },
          {
            IDN3: 4819,
            LIBELLEN3: 'SMINJA',
            IDN2: 82,
            CODEP: '1131',
          },
        ],
      },
      {
        ville: 81,
        villeName: 'EL FAHS',
        cites: [
          {
            IDN3: 4807,
            LIBELLEN3: 'BENT SAIDANE',
            IDN2: 81,
            CODEP: '1162',
          },
          {
            IDN3: 4779,
            LIBELLEN3: 'BIR MOUKRA',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4780,
            LIBELLEN3: 'BORJ ABDELJALIL',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4781,
            LIBELLEN3: 'BOU GARNINE',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4782,
            LIBELLEN3: 'CHENANFA',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4783,
            LIBELLEN3: 'CITE 20 MARS',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4784,
            LIBELLEN3: 'CITE BOU HMIDA',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4785,
            LIBELLEN3: 'CITE DES ENSEIGNANTS',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4786,
            LIBELLEN3: 'CITE EL AMEL',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4787,
            LIBELLEN3: 'CITE ENNASR',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4789,
            LIBELLEN3: 'CITE ENNISMA',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4790,
            LIBELLEN3: 'CITE ERRIADH',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4791,
            LIBELLEN3: 'CITE ESSAADA 1',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4793,
            LIBELLEN3: 'CITE ESSAADA 2',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4794,
            LIBELLEN3: 'CITE ESSALEM',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4795,
            LIBELLEN3: 'CITE INDUSTRILLE',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4809,
            LIBELLEN3: 'CITE SNIT NOUVELLE',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4808,
            LIBELLEN3: 'DHRAA BEN JOUDER',
            IDN2: 81,
            CODEP: '1194',
          },
          {
            IDN3: 4810,
            LIBELLEN3: 'EL AMAIEM',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4811,
            LIBELLEN3: 'EL FAHS',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4812,
            LIBELLEN3: 'EL KNAZIZ',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4813,
            LIBELLEN3: 'GHERIFAT',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4814,
            LIBELLEN3: 'GLIB JEMAL',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4815,
            LIBELLEN3: 'HENCHIR BROUTA',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4816,
            LIBELLEN3: 'HENCHIR DHOMDA',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4817,
            LIBELLEN3: 'JABBES',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4778,
            LIBELLEN3: 'JEBEL MANSOUR',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4792,
            LIBELLEN3: 'JOUGAR',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4788,
            LIBELLEN3: 'KEF EZZROUG',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4796,
            LIBELLEN3: 'KHEMAYSSIA',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4797,
            LIBELLEN3: 'KOUDOUAT CHAIR',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4798,
            LIBELLEN3: 'OUED EL KHADHRA',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4799,
            LIBELLEN3: 'OUM EL ABOUAB',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4800,
            LIBELLEN3: 'REHAHLA',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4801,
            LIBELLEN3: 'SED OUED EL KEBIR',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4802,
            LIBELLEN3: 'SIDI AMARA',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4806,
            LIBELLEN3: 'SIDI AOUIDETTE',
            IDN2: 81,
            CODEP: '1146',
          },
          {
            IDN3: 4803,
            LIBELLEN3: 'SIDI NAOUI',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4804,
            LIBELLEN3: 'TEBIKA',
            IDN2: 81,
            CODEP: '1140',
          },
          {
            IDN3: 4805,
            LIBELLEN3: 'TLIL ESSALHI',
            IDN2: 81,
            CODEP: '1140',
          },
        ],
      },
      {
        ville: 80,
        villeName: 'ENNADHOUR',
        cites: [
          {
            IDN3: 4765,
            LIBELLEN3: 'AIN EL BATTOUM',
            IDN2: 80,
            CODEP: '1154',
          },
          {
            IDN3: 4757,
            LIBELLEN3: 'BEN HALAOUA',
            IDN2: 80,
            CODEP: '1160',
          },
          {
            IDN3: 4773,
            LIBELLEN3: 'BIR CHAOUCH',
            IDN2: 80,
            CODEP: '1163',
          },
          {
            IDN3: 4766,
            LIBELLEN3: 'BOU ARAARA',
            IDN2: 80,
            CODEP: '1154',
          },
          {
            IDN3: 4758,
            LIBELLEN3: 'CHAALIL NORD',
            IDN2: 80,
            CODEP: '1160',
          },
          {
            IDN3: 4759,
            LIBELLEN3: 'CHAALIL SUD',
            IDN2: 80,
            CODEP: '1160',
          },
          {
            IDN3: 4774,
            LIBELLEN3: 'CHEBAANA',
            IDN2: 80,
            CODEP: '1163',
          },
          {
            IDN3: 4760,
            LIBELLEN3: 'DHORBANIA',
            IDN2: 80,
            CODEP: '1160',
          },
          {
            IDN3: 4764,
            LIBELLEN3: 'EL HNAINIA',
            IDN2: 80,
            CODEP: '1154',
          },
          {
            IDN3: 4767,
            LIBELLEN3: 'ENNADHOUR',
            IDN2: 80,
            CODEP: '1160',
          },
          {
            IDN3: 4763,
            LIBELLEN3: 'ERRAGBA',
            IDN2: 80,
            CODEP: '1154',
          },
          {
            IDN3: 4775,
            LIBELLEN3: 'KEF AGUEB',
            IDN2: 80,
            CODEP: '1163',
          },
          {
            IDN3: 4776,
            LIBELLEN3: 'MEIDHER',
            IDN2: 80,
            CODEP: '1163',
          },
          {
            IDN3: 4777,
            LIBELLEN3: 'MRIGUEB',
            IDN2: 80,
            CODEP: '1163',
          },
          {
            IDN3: 4762,
            LIBELLEN3: 'OULED JABALLAH',
            IDN2: 80,
            CODEP: '1154',
          },
          {
            IDN3: 4768,
            LIBELLEN3: 'SIDI BANNOUR',
            IDN2: 80,
            CODEP: '1160',
          },
          {
            IDN3: 4769,
            LIBELLEN3: 'SIDI NAJI',
            IDN2: 80,
            CODEP: '1160',
          },
          {
            IDN3: 4770,
            LIBELLEN3: 'SOUAR',
            IDN2: 80,
            CODEP: '1160',
          },
          {
            IDN3: 4761,
            LIBELLEN3: 'SOUGHAS NORD',
            IDN2: 80,
            CODEP: '1154',
          },
          {
            IDN3: 4756,
            LIBELLEN3: 'SOUGHAS SUD',
            IDN2: 80,
            CODEP: '1154',
          },
          {
            IDN3: 4771,
            LIBELLEN3: 'ZBIDINE',
            IDN2: 80,
            CODEP: '1160',
          },
          {
            IDN3: 4772,
            LIBELLEN3: 'ZOUAGHA',
            IDN2: 80,
            CODEP: '1160',
          },
        ],
      },
      {
        ville: 84,
        villeName: 'HAMMAM ZRIBA',
        cites: [
          {
            IDN3: 4862,
            LIBELLEN3: 'AIN BATRIA',
            IDN2: 84,
            CODEP: '1122',
          },
          {
            IDN3: 4863,
            LIBELLEN3: 'BOU ACHIR',
            IDN2: 84,
            CODEP: '1122',
          },
          {
            IDN3: 4866,
            LIBELLEN3: 'CITE DU LYCEE',
            IDN2: 84,
            CODEP: '1152',
          },
          {
            IDN3: 4867,
            LIBELLEN3: 'CITE EL HOUDA',
            IDN2: 84,
            CODEP: '1152',
          },
          {
            IDN3: 4868,
            LIBELLEN3: 'HAMMAM ZRIBA',
            IDN2: 84,
            CODEP: '1152',
          },
          {
            IDN3: 4861,
            LIBELLEN3: 'JERADOU',
            IDN2: 84,
            CODEP: '1112',
          },
          {
            IDN3: 4864,
            LIBELLEN3: 'OUED EL KENZ',
            IDN2: 84,
            CODEP: '1122',
          },
          {
            IDN3: 4865,
            LIBELLEN3: 'ZRIBA',
            IDN2: 84,
            CODEP: '1122',
          },
        ],
      },
      {
        ville: 85,
        villeName: 'SAOUEF',
        cites: [
          {
            IDN3: 4869,
            LIBELLEN3: 'CHARCHARA',
            IDN2: 85,
            CODEP: '1115',
          },
          {
            IDN3: 4870,
            LIBELLEN3: 'DGHAFLA',
            IDN2: 85,
            CODEP: '1115',
          },
          {
            IDN3: 4871,
            LIBELLEN3: 'DOUAR EL HAJ AMOR',
            IDN2: 85,
            CODEP: '1115',
          },
          {
            IDN3: 4872,
            LIBELLEN3: 'EL HMIRA SUD',
            IDN2: 85,
            CODEP: '1115',
          },
          {
            IDN3: 4873,
            LIBELLEN3: 'EL KHADHRA',
            IDN2: 85,
            CODEP: '1115',
          },
          {
            IDN3: 4874,
            LIBELLEN3: 'HALG ENNAB',
            IDN2: 85,
            CODEP: '1115',
          },
          {
            IDN3: 4875,
            LIBELLEN3: 'HENCHIR EL HAMIRA',
            IDN2: 85,
            CODEP: '1115',
          },
          {
            IDN3: 4876,
            LIBELLEN3: 'OUED TOUIL',
            IDN2: 85,
            CODEP: '1115',
          },
          {
            IDN3: 4877,
            LIBELLEN3: 'SAOUEF',
            IDN2: 85,
            CODEP: '1115',
          },
          {
            IDN3: 4878,
            LIBELLEN3: 'SIDI DGHIM',
            IDN2: 85,
            CODEP: '1115',
          },
          {
            IDN3: 4879,
            LIBELLEN3: 'SIDI FARJALLAH',
            IDN2: 85,
            CODEP: '1115',
          },
          {
            IDN3: 4880,
            LIBELLEN3: 'SIDI MANSOUR',
            IDN2: 85,
            CODEP: '1115',
          },
          {
            IDN3: 4881,
            LIBELLEN3: 'ZAGTOUN',
            IDN2: 85,
            CODEP: '1115',
          },
          {
            IDN3: 4882,
            LIBELLEN3: 'ZEGUIDANE',
            IDN2: 85,
            CODEP: '1115',
          },
        ],
      },
      {
        ville: 83,
        villeName: 'ZAGHOUAN',
        cites: [
          {
            IDN3: 4853,
            LIBELLEN3: 'AIN ESSABOUNE',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4854,
            LIBELLEN3: 'AIN LANSARINE',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4855,
            LIBELLEN3: 'AIN LEDHIEB',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4856,
            LIBELLEN3: 'AIN SAFSAF',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4860,
            LIBELLEN3: 'BIR HALIMA',
            IDN2: 83,
            CODEP: '1155',
          },
          {
            IDN3: 4857,
            LIBELLEN3: 'BNI DARRAJ',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4858,
            LIBELLEN3: 'BNI MAR',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4847,
            LIBELLEN3: 'BOU SLIM',
            IDN2: 83,
            CODEP: '1132',
          },
          {
            IDN3: 4859,
            LIBELLEN3: 'CITE 20 MARS',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4852,
            LIBELLEN3: 'CITE ADMINISTRATIVE',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4849,
            LIBELLEN3: 'CITE BOURGUIBA',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4851,
            LIBELLEN3: 'CITE DE LA MUNICIPALITE',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4850,
            LIBELLEN3: 'CITE DE LA SONEDE',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4826,
            LIBELLEN3: 'CITE DES NYMPHES',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4827,
            LIBELLEN3: 'CITE DU LYCEE',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4828,
            LIBELLEN3: 'CITE EL BASSATINE',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4829,
            LIBELLEN3: 'CITE EL MENZAH',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4830,
            LIBELLEN3: 'CITE ENNOUZHA',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4831,
            LIBELLEN3: 'CITE EQUIPEMENT',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4832,
            LIBELLEN3: 'CITE EZZOUHOUR',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4833,
            LIBELLEN3: 'CITE INDUSTRIELLE 1',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4834,
            LIBELLEN3: 'CITE INDUSTRIELLE 2',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4836,
            LIBELLEN3: 'CITE NESRINE',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4846,
            LIBELLEN3: 'EL MAGREN',
            IDN2: 83,
            CODEP: '1121',
          },
          {
            IDN3: 4837,
            LIBELLEN3: 'EL MHEDHBA',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4848,
            LIBELLEN3: 'HAMMAM JEDIDI',
            IDN2: 83,
            CODEP: '1132',
          },
          {
            IDN3: 4838,
            LIBELLEN3: 'HEMIANE',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4839,
            LIBELLEN3: 'JIMLA',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4840,
            LIBELLEN3: 'KANTRA EL KAHLA',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4841,
            LIBELLEN3: 'MERKEZ ALI BESSEGHAIER',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4842,
            LIBELLEN3: 'MHIRIS',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4835,
            LIBELLEN3: 'OUED EZZIT',
            IDN2: 83,
            CODEP: '1132',
          },
          {
            IDN3: 4843,
            LIBELLEN3: 'OUED SBAIHIA',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4844,
            LIBELLEN3: 'SIDI MRAIEH',
            IDN2: 83,
            CODEP: '1100',
          },
          {
            IDN3: 4845,
            LIBELLEN3: 'ZAGHOUAN',
            IDN2: 83,
            CODEP: '1100',
          },
        ],
      },
    ],
  },
];
